import React from 'react';
import { Layout } from '../../../features/common/components/Layout';
import { FlightExplorerContainer } from '../../../features/flightsExplorer/components/FlightExplorerContainer';

export const FightExplorer = (): JSX.Element => {
	return (
		<Layout>
			<FlightExplorerContainer />
		</Layout>
	);
};
