import React, { useEffect } from 'react';

import { Box, Slider, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { IDurationRange } from '../../types';

interface Props {
	title: string;
	handleChangeFilter: (range: IDurationRange | null) => void;
}

export const FlightExplorerScheduleItemFilter = ({ title, handleChangeFilter }: Props): JSX.Element => {
	const theme = useTheme();
	const minHour = 0;
	const maxHour = 24;
	const [rangeHours, setRangeHours] = React.useState<number[]>([minHour, maxHour]);

	const handleChangeHour = (event: Event, newValue: number | number[]) => {
		const newArray = newValue as number[];
		if (newArray[0] != rangeHours[0] || newArray[1] != rangeHours[1]) setRangeHours(newArray);
	};

	useEffect(() => {
		if (rangeHours[0] === minHour && rangeHours[1] === maxHour) {
			handleChangeFilter(null);
		} else {
			handleChangeFilter({ from: rangeHours[0], to: rangeHours[1] });
		}
	}, [rangeHours]);

	const formatHour = (hour: number): string => {
		if (hour < 10) {
			return '0' + hour + ':00';
		}
		if (hour === maxHour) {
			return '23:59';
		}
		return hour.toString() + ':00';
	};

	return (
		<Box sx={{ 'padding-top': '15px' }}>
			<Typography
				variant="subtitle1"
				mt={2}
				component="span"
				fontSize={14}
				color={theme.palette.primary.main}
				fontWeight={'bold'}
			>
				{' '}
				{title}{' '}
			</Typography>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography
					variant="subtitle1"
					component="span"
					fontSize={12}
					color={theme.palette.secondary.main}
					fontWeight={'bold'}
				>
					{' '}
					De {formatHour(rangeHours[0])} a {formatHour(rangeHours[1])} horas{' '}
				</Typography>
			</div>

			<Slider
				sx={{ width: '90%' }}
				size={'medium'}
				value={rangeHours}
				color={'secondary'}
				onChange={handleChangeHour}
				valueLabelDisplay="off"
				min={0}
				max={24}
			/>

			<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
				<Typography
					variant="subtitle1"
					component="span"
					fontSize={12}
					color={theme.palette.secondary.main}
					fontWeight={'bold'}
				>
					{' '}
					00:00{' '}
				</Typography>
				<Typography
					variant="subtitle1"
					component="span"
					fontSize={12}
					color={theme.palette.secondary.main}
					fontWeight={'bold'}
				>
					{' '}
					23:59
				</Typography>
			</div>
		</Box>
	);
};
