import { ShowAlertState } from '../../common/types';
import { useCallback, useState } from 'react';
import { GDSEnum } from '../../salesOrder/types';

export interface IGDS {
	code: string;
	name: string;
}

interface UseGDSReturn {
	gds: IGDS[];
	loadGDS: () => void;
}

interface UseGDSParams {
	setAlert: React.Dispatch<React.SetStateAction<ShowAlertState>>;
}

const useGDS = ({ setAlert }: UseGDSParams): UseGDSReturn => {
	const [gds, setGds] = useState<IGDS[]>([]);

	const loadGDS = useCallback(async () => {
		try {
			const gdsValues = Object.values(GDSEnum).map((value) => ({
				code: value,
				name: value,
			}));
			setGds(gdsValues);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: 'Ocurrió un error al cargar los gds',
			});
			console.error(error);
		}
	}, []);

	return { gds: gds, loadGDS: loadGDS };
};

export default useGDS;
