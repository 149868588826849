import React, { useEffect, useState } from 'react';
import { Alert, AlertColor, Backdrop, CircularProgress, Container, Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { TransactionSummary } from 'features/transactions/components/TransactionSummary';
import { TransactionList as Component } from 'features/transactions/components/TransactionList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FilterModel, ISort } from '../../../features/common/types';
import { getFilterFromProfile } from '../../../features/common/services';
import { extractErrorMessage } from '../../../features/quotation/helpers';
import { CountryCodeEnum } from '../../../features/common/types';
import { PageNotAllowedForMarket } from '../../../features/common/components/PageNotAllowedForMarket';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));
interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

function TransactionList(): JSX.Element {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(true);
	const { auth } = useSelector((state: RootState) => state);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'createdAt', order: 'desc' }]);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const userId = auth.user?.id;
	const apiKey = auth.apiKey;
	const countryCode = auth.country;
	const profileTag = 'transaction-list-tag';

	const getInitialProfileFilters = async () => {
		try {
			const { profileSorts, profileFilters } = await getFilterFromProfile(
				profileTag,
				apiKey,
				countryCode,
				userId?.toString(),
			);
			profileSorts && setSortFields([...profileSorts]);
			profileFilters && setFilterApplied([...profileFilters]);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir los filtros del perfil'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getInitialProfileFilters();
	}, []);

	const isUruguay = countryCode === CountryCodeEnum.URUGUAY;

	return (
		<Layout>
			<div>
				<Container component="main" maxWidth="xl">
					{isUruguay ? (
						<>
							<Backdrop className={classes.backdrop} open={loading}>
								<CircularProgress color="inherit" />
							</Backdrop>
							<Typography variant="h5" className={classes.headerTitle}>
								Transacciones de órdenes simples
							</Typography>
							<TransactionSummary />
							{!loading && <Component profileFilters={filterApplied} profileSorts={sortFields} />}
							<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
								<Alert variant="filled" severity={alert.severity}>
									{alert.message}
								</Alert>
							</Snackbar>
						</>
					) : (
						<PageNotAllowedForMarket />
					)}
				</Container>
			</div>
		</Layout>
	);
}

export default TransactionList;
