import { ShowAlertState } from '../../common/types';
import { useCallback, useState } from 'react';
import { CostByTicketOperatorEnum } from '../types';

export interface ICostByTicketOperator {
	code: string;
	name: string;
}

interface UseCostByTicketOperatorsReturn {
	costByTicketOperators: ICostByTicketOperator[];
	loadCostByTicketOperators: () => void;
}

interface UseCostByTicketOperatorsParams {
	setAlert: React.Dispatch<React.SetStateAction<ShowAlertState>>;
}

const useCostByTicketOperators = ({ setAlert }: UseCostByTicketOperatorsParams): UseCostByTicketOperatorsReturn => {
	const [costByTicketOperators, setCostByTicketOperators] = useState<ICostByTicketOperator[]>([]);

	const loadCostByTicketOperators = useCallback(async () => {
		try {
			const values = Object.values(CostByTicketOperatorEnum).map((value) => ({
				code: value,
				name: value,
			}));
			setCostByTicketOperators(values);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: 'Ocurrió un error al cargar los operators',
			});
			console.error(error);
		}
	}, []);

	return { costByTicketOperators: costByTicketOperators, loadCostByTicketOperators: loadCostByTicketOperators };
};

export default useCostByTicketOperators;
