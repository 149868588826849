import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import 'react-credit-cards/es/styles-compiled.css';
import { IBranchOfficeSimple, ICoin, ICreateOrderCash, IFilledOrderCashForm } from 'features/payment/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import { findContactDataByDeal } from 'services';
import { AutocompleteContactData } from 'features/quotation/types';
import {
	createCashOrder,
	getBranchOfficesAllowedToCreateCashOrder,
	getCoinsAllowedToCreateCashOrder,
} from 'features/payment/services';
import { ConfirmationModal } from 'features/common/components/ConfirmationModal/ConfirmationModal';
import { checkShowErrorMessage } from '../../../common/helpers';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

interface IDealContactCheck {
	message: string[] | string;
	success: boolean;
}

export const CashOrderForm = (): JSX.Element => {
	const formDefaultValues = {
		branchOfficeId: '',
		crmTicket: 1,
		coinId: '',
		amount: 1,
		observations: '',
	} as IFilledOrderCashForm;

	const {
		control,
		reset,
		getValues,
		formState: { errors, isValid },
	} = useForm({ mode: 'all', defaultValues: formDefaultValues });
	const [coinsList, setCoinsList] = useState<ICoin[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [branchOfficesList, setBranchOfficesList] = useState<IBranchOfficeSimple[]>([]);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [open, setOpen] = useState(false);
	const [contactDealCheck, setContactDealCheck] = useState<IDealContactCheck | null>(null);
	const handleOpen = () => setOpen(true);
	//Obtengo la lista de Sucursales
	const loadBranchOffices = async () => {
		try {
			setLoading(true);
			const branchOffices = (await getBranchOfficesAllowedToCreateCashOrder()).data;
			setBranchOfficesList(branchOffices);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setAlert({ show: true, severity: 'success', message: 'No se pudo cargar las sucursales' });
		}
	};
	const parseInputToNumber = (input: string): number => {
		return parseInt(input);
	};

	const isValidContactDealCheck = (): boolean => contactDealCheck != null && contactDealCheck.success;

	const canProcessOrder = (): boolean => !loading && isValid && isValidContactDealCheck();

	//obtengo los datos del Deal ID
	const findContactOfDeal = async () => {
		const crmTicket = getValues('crmTicket');
		if (crmTicket) {
			try {
				setLoading(true);
				const response = await findContactDataByDeal(crmTicket);
				setContactDealCheck({ message: convertContactDataToMessage(response.data), success: true });
				setLoading(false);
			} catch (error: any) {
				if (error.response.status === 404) {
					setContactDealCheck({ message: 'El deal no tiene contacto asociado', success: true });
					setLoading(false);
				} else {
					setContactDealCheck({ message: 'No existe un deal con ese ID', success: false });
					setLoading(false);
				}
			}
		}
	};
	const handleDealId = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			findContactOfDeal();
		}
	};
	//Obtengo el codigo de la Moneda
	const loadCoins = async () => {
		try {
			setLoading(true);
			const coins = (await getCoinsAllowedToCreateCashOrder()).data;
			setCoinsList(coins);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setAlert({ show: true, severity: 'success', message: 'No se pudo cargar las monedas' });
		}
	};

	const onSubmit = async () => {
		try {
			setLoading(true);
			const values = getValues();
			const orderToSend = {
				branchOfficeId: Number(values.branchOfficeId),
				crmTicket: Number(values.crmTicket),
				coinId: Number(values.coinId),
				amount: Number(values.amount),
				...(values.observations && values.observations != '' && { observations: values.observations }),
			} as ICreateOrderCash;
			await createCashOrder(orderToSend);
			setLoading(false);
			setOpen(false);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Orden de pago cash creada exitosamente',
			});
			reset();
			setContactDealCheck(null);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al crear la orden'),
			});
			setLoading(false);
		}
	};

	const convertContactDataToMessage = (contact: AutocompleteContactData): string[] => {
		const stringMessageChunk = [];
		stringMessageChunk.push(`Nombre: ${contact.name}`);
		if (contact.email) stringMessageChunk.push(`Correo: ${contact.email}`);
		if (contact.phone) stringMessageChunk.push(`Teléfono: ${contact.phone}`);
		return stringMessageChunk;
	};

	useEffect(() => {
		loadBranchOffices();
	}, []);

	useEffect(() => {
		loadCoins();
	}, []);

	return (
		<>
			<form>
				<Grid container gap={2}>
					<Grid item xs={12}>
						<Controller
							name="branchOfficeId"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Por favor seleccione sucursal',
								},
							}}
							render={({ field, fieldState: { error } }) => (
								<FormControl required={true} sx={{ width: '100%', height: '100%' }}>
									<InputLabel>Sucursal</InputLabel>
									<Select
										labelId="branchOfficeId"
										label="Sucursal"
										required
										disabled={loading}
										sx={{ width: '100%' }}
										{...field}
										onChange={(event) => field.onChange(parseInputToNumber(event.target.value))}
										value={field.value || ''}
									>
										{branchOfficesList.map((branchOffice) => (
											<MenuItem value={branchOffice.id} key={branchOffice.id}>
												{branchOffice.name}
											</MenuItem>
										))}
									</Select>
									{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="crmTicket"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								min: {
									value: 1,
									message: 'El deal id debe ser mayor a 0',
								},
								pattern: {
									value: /^(0|[1-9]\d*)(\.\d+)?$/,
									message: 'El valor debe ser numérico y mayor que 0',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Deal ID"
									type="number"
									onKeyPress={handleDealId}
									helperText={checkShowErrorMessage(Boolean(errors.crmTicket), errors.crmTicket?.message)}
									error={Boolean(errors.crmTicket)}
									disabled={loading}
									{...field}
									onChange={(event) => {
										field.onChange(event.target.value);
										if (contactDealCheck != null) setContactDealCheck(null);
									}}
								/>
							)}
						/>
					</Grid>
					{contactDealCheck && (
						<Typography color={contactDealCheck.success ? 'green' : 'red'} fontSize={12}>
							{' '}
							{Array.isArray(contactDealCheck.message)
								? contactDealCheck.message.map((message) => {
										return <p key={message}>{message}</p>;
								  })
								: contactDealCheck.message}{' '}
						</Typography>
					)}
					<Grid item xs={12}>
						<Controller
							name="coinId"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Por favor seleccione una moneda',
								},
							}}
							render={({ field, fieldState: { error } }) => (
								<FormControl required={true} sx={{ width: '100%', height: '100%' }}>
									<InputLabel id="currency-label">Moneda</InputLabel>
									<Select
										labelId="currency-label"
										id="coinId-select"
										label="Moneda"
										disabled={!coinsList.length || loading}
										required
										sx={{ width: '100%' }}
										{...field}
									>
										{coinsList.map((currency) => (
											<MenuItem key={currency.id} value={currency.id}>
												{currency.code}
											</MenuItem>
										))}
									</Select>
									{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="amount"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								validate: {
									notZero: (value) => value !== 0 || 'El monto debe ser distinto de 0',
								},
								pattern: {
									value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
									message: 'El valor debe ser numérico',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Monto"
									type="number"
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.amount), errors.amount?.message)}
									error={Boolean(errors.amount)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="observations"
							control={control}
							render={({ field }) => (
								<TextField
									fullWidth
									label="Observaciones"
									variant="outlined"
									multiline
									rows={5}
									disabled={loading}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} display={'flex'} justifyContent="flex-end">
						<Button variant="contained" onClick={handleOpen} disabled={!canProcessOrder()} color="success" fullWidth>
							Agregar orden de Pago
						</Button>
						<ConfirmationModal
							open={open}
							handleClose={() => setOpen(false)}
							handleAccept={onSubmit}
							message="Confirmar orden de Pago Cash"
						/>
					</Grid>
				</Grid>
			</form>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
