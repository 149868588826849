import {
	FilterType,
	IHeadCell,
	OperatorFilterEnum,
	SelectFilterModelMultiple,
	SelectFilterModelSimple,
} from '../common/types';
import { costByTicketOperatorToFilter, gdsEnumListToFilter } from './types';

export const newRuleId = 'new';
export const newDefaultRuleId = 'new-default';

export const flightIssueRuleHeaderCells: IHeadCell[] = [
	{
		field: 'priority',
		sortable: true,
		headerName: 'Prioridad',
	},
	{
		field: 'ruleComment',
		sortable: true,
		headerName: 'Texto para la regla',
	},
	{
		field: 'created',
		sortable: true,
		headerName: 'Fecha de creación',
	},
	{
		field: 'createdBy',
		sortable: false,
		headerName: 'Creada por',
	},
	{
		field: 'isDefaultRule',
		sortable: true,
		headerName: 'Regla por defecto',
	},
];

export const flightIssueRuleFilters = [
	{
		label: 'Prioridad',
		type: FilterType.NUMERIC,
		key: 'priority',
		operator: OperatorFilterEnum.EQUALS,
	},
	{
		label: 'Es regla por defecto',
		type: FilterType.BOOLEAN,
		key: 'isDefaultRule',
	},
	{
		label: 'Texto para la regla',
		type: FilterType.STRING,
		key: 'ruleComment',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Validating Carrier Incluye',
		type: FilterType.STRING,
		key: 'vcIncludes',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Validating Carrier Excluye',
		type: FilterType.STRING,
		key: 'vcExcludes',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Origen Incluye',
		type: FilterType.STRING,
		key: 'originIncludes',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Origen Excluye',
		type: FilterType.STRING,
		key: 'originExcludes',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Escalas Incluye',
		type: FilterType.STRING,
		key: 'layoverIncludes',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Escalas Excluye',
		type: FilterType.STRING,
		key: 'layoverExcludes',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Destino Incluye',
		type: FilterType.STRING,
		key: 'stopoverIncludes',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Incluir otros destinos',
		type: FilterType.BOOLEAN,
		key: 'mustContainOtherStopover',
	},
	{
		label: 'Destino Excluye',
		type: FilterType.STRING,
		key: 'stopoverExcludes',
		operator: OperatorFilterEnum.CONTAINS,
	},
	new SelectFilterModelSimple('Operador de tarifa', 'costByTicketOperator', costByTicketOperatorToFilter),
	new SelectFilterModelMultiple('GDS', 'gds', gdsEnumListToFilter),
	{
		label: 'Tarifa x pax',
		type: FilterType.NUMERIC,
		key: 'costByTicket',
		operator: OperatorFilterEnum.EQUALS,
	},
	{ label: 'Fecha de creación', type: FilterType.DATE, key: 'created' },
	{
		label: 'Creada por',
		type: FilterType.STRING,
		key: 'createdBy',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{ label: 'Fecha actualización', type: FilterType.DATE, key: 'updated' },
	{
		label: 'Actualizada por',
		type: FilterType.STRING,
		key: 'createdBy',
		operator: OperatorFilterEnum.CONTAINS,
	},
];
