import {
	Alert,
	Backdrop,
	CircularProgress,
	Grid,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { extractErrorMessage } from 'features/quotation/helpers';
import { getBranchOfficesBalance } from 'features/transactions/services';
import { ICurrentBalanceByBranchOffice } from 'features/transactions/types';
import React, { useEffect, useMemo, useState } from 'react';
import { ShowAlertState } from '../../../common/types';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

interface Props {
	changeCashMovement: boolean;
}

export const CashTransactionBranchOfficeBalance = ({ changeCashMovement }: Props): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [currentBalanceByBranchOffices, setCurrentBalanceByBranchOffices] = useState<ICurrentBalanceByBranchOffice[]>(
		[],
	);

	const getCurrentBalanceByBranchOfficesAtLoad = async () => {
		try {
			setLoading(true);
			const balanceByBranchOffices = (await getBranchOfficesBalance()).data;
			setCurrentBalanceByBranchOffices(balanceByBranchOffices);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar el balance por paises'),
			});
			setLoading(false);
		}
	};

	const maxCellCoinToRender = useMemo(() => {
		const arrayDetailLength = currentBalanceByBranchOffices.map((x) => x.details.length);
		return arrayDetailLength.length == 0 ? 0 : Math.max(...arrayDetailLength);
	}, [currentBalanceByBranchOffices]);

	const totalInUSD = useMemo(() => {
		const arrayToTalInUSD = currentBalanceByBranchOffices.map((x) => x.totalUSD);
		return arrayToTalInUSD.reduce((a, b) => a + b, 0).toFixed(2);
	}, [currentBalanceByBranchOffices]);

	useEffect(() => {
		getCurrentBalanceByBranchOfficesAtLoad();
	}, [changeCashMovement]);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container paddingY={5} paddingRight={5}>
				{currentBalanceByBranchOffices.length > 0 && (
					<Grid item xs={6}>
						<Grid container spacing={2} paddingY={5} paddingLeft={5} paddingRight={5}>
							<Grid item xs={12} display="flex" justifyContent={'center'}>
								<Typography variant="h6">Saldo actual por sucursal</Typography>
							</Grid>
							<Table stickyHeader size="small">
								<TableBody>
									{currentBalanceByBranchOffices.map((branchOfficeBalance, index) => {
										return (
											<>
												<TableRow key={`${index}`} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell>
														<Typography fontWeight={'bold'} variant="body1">
															{branchOfficeBalance.branchOfficeName}
														</Typography>
													</TableCell>
													{maxCellCoinToRender - branchOfficeBalance.details.length > 0 &&
														Array(maxCellCoinToRender - branchOfficeBalance.details.length)
															.fill(0)
															.map((_, indexEmpty) => {
																return <TableCell key={`${index}- ${indexEmpty}-empty-cell`}> </TableCell>;
															})}
													{branchOfficeBalance.details.map((detail, indexDetail) => {
														return (
															<TableCell
																component={'div'}
																sx={{ minHeight: '150px', verticalAlign: 'top' }}
																key={`${index}- ${indexDetail}`}
															>
																<Typography variant="h6">{detail.coinCode} </Typography>
																<Typography variant="body1">{detail.amount}</Typography>
																<Typography variant="body2" fontSize={12}>
																	{detail.arbitration}
																</Typography>
															</TableCell>
														);
													})}
													<TableCell component={'div'} sx={{ minHeight: '150px', verticalAlign: 'top' }}>
														<Typography variant="h6">Total USD</Typography>
														<Typography fontWeight={'bold'} variant="body1">
															{branchOfficeBalance.totalUSD}
														</Typography>
													</TableCell>
												</TableRow>
											</>
										);
									})}
									<TableRow>
										{Array(maxCellCoinToRender + 1)
											.fill(0)
											.map((_, indexEmpty) => {
												return <TableCell key={`${indexEmpty}-empty-cell-summary`}> </TableCell>;
											})}
										<TableCell sx={{ minWidht: '600px' }}>
											<Typography variant="h6">Saldo actual total USD</Typography>
											<Typography fontWeight={'bold'} variant="body1">
												{totalInUSD}
											</Typography>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
