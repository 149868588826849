import React from 'react';
import { IDestinationPackageCompositeDetailWithHotelDetail } from '../../types';
import { Card, Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PackageCompositeHotelDetail } from './PackageCompositeHotelDetail';

interface Props {
	destinations: IDestinationPackageCompositeDetailWithHotelDetail[];
}

export const PackageCompositeDestinationsDetails = ({ destinations }: Props): JSX.Element => {
	return (
		<Card sx={{ 'margin-top': '10px' }}>
			<Typography color="primary" variant={'h6'} fontWeight="bold" py={1} px={1}>
				Hoteles por {destinations.length > 1 ? 'destinos' : 'destino'}
			</Typography>
			{destinations.map((destination) => (
				<>
					<Typography color="secondary" fontWeight="bold" py={1} px={1}>
						Hoteles en {destination.name} ({destination.hotels[0].totalNights} noches)
					</Typography>
					<Container disableGutters key={destination.name} sx={{ overflow: 'auto', paddingBottom: 1 }}>
						<Grid spacing={1} wrap="nowrap" p={2} container>
							{destination.hotels.map((hotelOption, index) => (
								<PackageCompositeHotelDetail
									key={`hotel-card-package-${index}-${hotelOption.externalId}-${hotelOption.provider}`}
									hotelOption={hotelOption}
								/>
							))}
						</Grid>
					</Container>
				</>
			))}
		</Card>
	);
};
