import React, { useEffect } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { ShowAlertState } from '../../../common/types';
import useValidatingCarriers from '../../hooks/useValidatingCarriers';
import { FormProvider, useForm } from 'react-hook-form';
import { ControlledAutoComplete } from '../ControlledAutoComplete';
import useAirports from '../../hooks/useAirports';
import useGDS from '../../hooks/useGDS';
import useCostByTicketOperators from '../../hooks/useCostByTicketOperators';
import { ControlledTextField } from '../ControlledTextField';
import { ControlledCheckBox } from '../../../common/components/ControlledCheckBox';
import { FlightIssueNotDefaultRuleFormValues } from '../../types';

export interface IFlightIssueNotDefaultRuleFormProps {
	isToCreate: boolean;
	values: FlightIssueNotDefaultRuleFormValues;
	handleSubmitForm: (value: FlightIssueNotDefaultRuleFormValues) => void;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setAlert: React.Dispatch<React.SetStateAction<ShowAlertState>>;
}
interface IIncludeExcludeFormValues {
	vcIncludes: string[];
	vcExcludes: string[];
	originIncludes: string[];
	originExcludes: string[];
	layoverIncludes: string[];
	layoverExcludes: string[];
	stopoverIncludes: string[];
	stopoverExcludes: string[];
}

export const FlightIssueNotDefaultRuleForm = ({
	isToCreate,
	loading,
	values,
	handleSubmitForm,
	setLoading,
	setAlert,
}: IFlightIssueNotDefaultRuleFormProps): JSX.Element => {
	const { validatingCarriers, loadValidatingCarriers } = useValidatingCarriers({ setLoading, setAlert });
	const { airports, loadAirports } = useAirports({ setLoading, setAlert });
	const { gds, loadGDS } = useGDS({ setAlert });
	const { costByTicketOperators, loadCostByTicketOperators } = useCostByTicketOperators({ setAlert });
	const methods = useForm<FlightIssueNotDefaultRuleFormValues>({ mode: 'all', defaultValues: values });

	const {
		trigger,
		watch,
		formState: { isValid },
		getValues,
	} = methods;

	useEffect(() => {
		loadValidatingCarriers();
	}, [loadValidatingCarriers]);

	useEffect(() => {
		loadAirports();
	}, [loadAirports]);

	useEffect(() => {
		loadGDS();
	}, [loadGDS]);

	useEffect(() => {
		loadCostByTicketOperators();
	}, [loadCostByTicketOperators]);

	const costByTicketOperator = watch('costByTicketOperator');
	const costByTicket = watch('costByTicket');
	const stopoverIncludes = watch('stopoverIncludes');
	useEffect(() => {
		trigger('costByTicketOperator');
		trigger('costByTicket');
	}, [costByTicketOperator, costByTicket, trigger]);

	const validateNoOverlap =
		(includesName: keyof IIncludeExcludeFormValues, excludesName: keyof IIncludeExcludeFormValues) => () => {
			const includesValues = getValues(includesName) as string[];
			const excludesValues = getValues(excludesName) as string[];

			if (includesValues && excludesValues) {
				for (const include of includesValues) {
					if (excludesValues.includes(include)) {
						return 'Un valor no puede estar incluido y excluido al mismo tiempo.';
					}
				}
			}

			return true;
		};

	return (
		<>
			<Card>
				<CardContent>
					{isToCreate && <Typography variant="h6">Crear nueva regla:</Typography>}
					{!isToCreate && <Typography variant="h6">Editar regla:</Typography>}
					<br />
					<FormProvider {...methods}>
						<Box component="form" display="flex" flexDirection="column" gap={1}>
							<Box mt={1} display="flex" flexDirection="row" justifyContent="space-around">
								<Box display="flex" alignItems="center" gap={2} flex={1}>
									<ControlledAutoComplete
										name={'vcIncludes'}
										label={'Validating carrier incluye'}
										options={validatingCarriers}
										isMultiple={true}
										rules={{ validate: validateNoOverlap('vcIncludes', 'vcExcludes') }}
									/>
								</Box>
								<Box display="flex" alignItems="center" gap={2} flex={1} marginLeft={2}>
									<ControlledAutoComplete
										name={'vcExcludes'}
										label={'Validating carrier excluye'}
										options={validatingCarriers}
										isMultiple={true}
										rules={{ validate: validateNoOverlap('vcIncludes', 'vcExcludes') }}
									/>
								</Box>
							</Box>

							<Box display="flex" flexDirection="row" justifyContent="space-around">
								<Box display="flex" alignItems="center" flex={1}>
									<ControlledAutoComplete
										name={'originIncludes'}
										label={'Origen incluye'}
										options={airports}
										isMultiple={true}
										rules={{ validate: validateNoOverlap('originIncludes', 'originExcludes') }}
									/>
								</Box>
								<Box display="flex" alignItems="center" flex={1} marginLeft={2}>
									<ControlledAutoComplete
										name={'originExcludes'}
										label={'Origen excluye'}
										options={airports}
										isMultiple={true}
										rules={{ validate: validateNoOverlap('originIncludes', 'originExcludes') }}
									/>
								</Box>
							</Box>

							<Box display="flex" flexDirection="row" justifyContent="space-around">
								<Box display="flex" alignItems="center" gap={2} flex={1}>
									<ControlledAutoComplete
										name={'layoverIncludes'}
										label={'Escalas incluye'}
										options={airports}
										isMultiple={true}
										rules={{ validate: validateNoOverlap('layoverIncludes', 'layoverExcludes') }}
									/>
								</Box>
								<Box display="flex" alignItems="center" gap={2} flex={1} marginLeft={2}>
									<ControlledAutoComplete
										name={'layoverExcludes'}
										label={'Escalas excluye'}
										options={airports}
										isMultiple={true}
										rules={{ validate: validateNoOverlap('layoverIncludes', 'layoverExcludes') }}
									/>
								</Box>
							</Box>
							<Box display="flex" flexDirection="row" justifyContent="space-around">
								<Box display="flex" alignItems="center" gap={2} flex={3} marginRight={2}>
									<ControlledAutoComplete
										name={'stopoverIncludes'}
										label={'Destino incluye'}
										options={airports}
										isMultiple={true}
										rules={{ validate: validateNoOverlap('stopoverIncludes', 'stopoverExcludes') }}
									/>
								</Box>
								{stopoverIncludes.length == 1 && (
									<Box display="flex" alignItems="center" gap={2} flex={1}>
										<ControlledCheckBox name={'mustContainOtherStopover'} label={'Incluir cualquier otro'} />
									</Box>
								)}
								<Box display="flex" alignItems="center" gap={2} flex={3}>
									<ControlledAutoComplete
										name={'stopoverExcludes'}
										label={'Destino excluye'}
										options={airports}
										isMultiple={true}
										rules={{ validate: validateNoOverlap('stopoverIncludes', 'stopoverExcludes') }}
									/>
								</Box>
							</Box>

							<Box display="flex" flexDirection="row" justifyContent="space-around">
								<Box display="flex" alignItems="center" gap={2} flex={1} marginRight={2}>
									<ControlledAutoComplete
										name={'costByTicketOperator'}
										label={'Operador'}
										rules={{
											required: { value: !!costByTicket, message: 'Este campo es requerido' },
										}}
										options={costByTicketOperators}
										isMultiple={false}
									/>
								</Box>

								<Box display="flex" alignItems="center" gap={2} flex={1} marginRight={2}>
									<ControlledTextField
										name={'costByTicket'}
										label={'Tarifa x pax'}
										required={costByTicketOperator != undefined && costByTicketOperator != ''}
										rules={{
											required: {
												value: costByTicketOperator != undefined && costByTicketOperator != '',
												message: 'Este campo es requerido',
											},
											validate: {
												isNumber: (value: number) => {
													if (costByTicketOperator != undefined && costByTicketOperator != '') {
														return !isNaN(value) || 'Debe ser un número válido';
													}
												},
												isPositive: (value: any) => {
													if (costByTicketOperator != undefined && costByTicketOperator != '') {
														return Number(value) >= 0 || 'Debe ser un número mayor a 0';
													}
												},
											},
										}}
										isNumber={true}
									/>
								</Box>

								<Box display="flex" alignItems="center" gap={2} flex={3} marginRight={2}>
									<ControlledAutoComplete name={'gds'} label={'GDS'} options={gds} isMultiple={true} />
								</Box>

								<Box display="flex" alignItems="center" gap={2} flex={1}>
									<ControlledTextField
										name={'priority'}
										label={'Prioridad'}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
											validate: {
												isNumber: (value: number) => !isNaN(value) || 'Debe ser un número válido',
												isPositive: (value: any) => Number(value) > 0 || 'Debe ser un número mayor a 0',
												isInteger: (value: any) => Number.isInteger(Number(value)) || 'Debe ser un número entero',
											},
										}}
										isNumber={true}
									/>
								</Box>
							</Box>
							<Box display="flex" flexDirection="row" justifyContent="space-around">
								<Box display="flex" alignItems="center" gap={2} flex={1}>
									<ControlledTextField
										name={'ruleComment'}
										label={'Texto para la regla'}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
											maxLength: { value: 500, message: 'Máximo 500 caracteres' },
										}}
										isNumber={false}
									/>
								</Box>
							</Box>
							<Box display={'flex'} width="100%">
								<Button
									variant="contained"
									disabled={loading || !isValid}
									color="success"
									sx={{ marginLeft: 'auto' }}
									onClick={() => handleSubmitForm(getValues())}
								>
									Guardar
								</Button>
							</Box>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
};
