import { AxiosResponse } from 'axios';
import endpoints from 'constants/endpoints';
import { OrderTypeEnum } from 'features/common/types';
import { ApiService } from 'services';
import {
	BancardPaymentCheckResponse,
	IAddReferenceToWireTransferInInfimia,
	IAtcCheckPaymentResponse,
	IBancardFormData,
	IBanksByCountry,
	IBranchOfficeSimple,
	ICheckOutAtcResponse,
	IClientTransactionForm,
	ICoin,
	ICreateOrderCash,
	ICurrenciesByCountry,
	IFilledOrderForm,
	ISendClientLinkToPayOrder,
	ISiemprePagoFormData,
	ITransactionInGatewayAtcRequest,
	ITransactionInGatewayBancardResponse,
	ITransactionInGatewaySPResponse,
	IWireTransferCheckFilter,
	OrderCreatedResult,
	OrderDataToClient,
	PaymentOrderForm,
	IFilledOrderFormVN,
	OrderCreatedResultVN,
	PaymentOrderFormVN,
	IWompiFormData,
	ITransactionInGatewayWompiResponse,
	WompiCheckPaymentResponse,
} from './types';

export const getFileByDealId = (dealId: number): Promise<AxiosResponse<string[]>> =>
	ApiService.get(`api/quotations/crm/${dealId}/filesassociated`);
export const getCoinsByOrderType = (id: OrderTypeEnum): Promise<AxiosResponse<ICoin[]>> =>
	ApiService.get(`api/quotations/orders/ordertypes/${id}/coins`);
export const getOrderDataByOrderId = (orderId: string): Promise<AxiosResponse<OrderDataToClient>> =>
	ApiService.get(`api/quotations/orders/${orderId}/infotoclient`);

export const postFormData = (orderForm: IFilledOrderForm): Promise<AxiosResponse<OrderCreatedResult>> => {
	const newOrderWithId = new PaymentOrderForm(orderForm);
	return ApiService.post(endpoints.paymentOrders, newOrderWithId);
};

export const postFormDataVN = (orderFormVN: IFilledOrderFormVN): Promise<AxiosResponse<OrderCreatedResultVN>> => {
	const newOrderWithId = new PaymentOrderFormVN(orderFormVN);
	return ApiService.post(endpoints.paymentOrdersVN, newOrderWithId);
};

export const getCoinsByOrderTypeVN = (): Promise<AxiosResponse<ICoin[]>> =>
	ApiService.get('/api/viaje-naranja/orders/coins');

export const postLinkToClientVN = (linkToClient: ISendClientLinkToPayOrder): any =>
	ApiService.post(endpoints.linkToClientVN, linkToClient);

export const createSPTransaction = (
	spFormData: ISiemprePagoFormData,
): Promise<AxiosResponse<ITransactionInGatewaySPResponse>> =>
	ApiService.post(endpoints.transactionInGatewaySiemprePago, spFormData);

export const createBancardTransaction = (
	bcFormData: IBancardFormData,
): Promise<AxiosResponse<ITransactionInGatewayBancardResponse>> =>
	ApiService.post(endpoints.transactionInGatewayBancard, bcFormData);

export const createWompiTransaction = (
	bcFormData: IWompiFormData,
): Promise<AxiosResponse<ITransactionInGatewayWompiResponse>> =>
	ApiService.post(endpoints.transactionWompi, bcFormData);

export const cancelBancardTransaction = (shopProcessId: number): Promise<AxiosResponse> =>
	ApiService.patch(`/api/quotations/transactionsingateway/bancard/${shopProcessId}`);

export const postLinkToClient = (linkToClient: ISendClientLinkToPayOrder): any =>
	ApiService.post(endpoints.linkToClient, linkToClient);

export const createSimpleOrderTransaction = (transactionForm: IClientTransactionForm): Promise<AxiosResponse> =>
	ApiService.post(endpoints.performTransaction, transactionForm);

export const checkBancardPaymentConfirmation = (
	shopProcessId: number,
): Promise<AxiosResponse<BancardPaymentCheckResponse>> =>
	ApiService.get(endpoints.checkConfirmationPaymentBancard.replace('{shopProcessId}', `${shopProcessId}`));

export const checkWompiPaymentConfirmation = (reference: string): Promise<AxiosResponse<WompiCheckPaymentResponse>> =>
	ApiService.get(endpoints.checkConfirmationPaymentWompi.replace(':reference', `${reference}`));

export const checkWireTransfer = (filter: IWireTransferCheckFilter): any =>
	ApiService.post(endpoints.wireTransferCheck, filter);

export const getBanksByCountry = (): Promise<AxiosResponse<IBanksByCountry[]>> =>
	ApiService.get(endpoints.wireTransferBanks);

export const getCurrenciesByCountry = (): Promise<AxiosResponse<ICurrenciesByCountry[]>> =>
	ApiService.get(endpoints.wireTransferCurrencies);
export const getWireTransferProviders = (): Promise<AxiosResponse<string[]>> =>
	ApiService.get(endpoints.wireTransferProviders);

export const addReferenceToWireTransferInInfimia = (
	data: IAddReferenceToWireTransferInInfimia,
): Promise<AxiosResponse> => ApiService.patch(endpoints.wireTransferAddReference, data);

export const createAtcTransaction = (
	bcFormData: ITransactionInGatewayAtcRequest,
): Promise<AxiosResponse<ICheckOutAtcResponse>> => ApiService.post(endpoints.transactionInGatewayAtc, bcFormData);

export const checkAtcPaymentConfirmation = (reference: string): Promise<AxiosResponse<IAtcCheckPaymentResponse>> =>
	ApiService.get(endpoints.checkConfirmationPaymentAtc.replace('{reference}', `${reference}`));

//GET listado de sucursales:
export const getBranchOfficesAllowedToCreateCashOrder = (): Promise<AxiosResponse<IBranchOfficeSimple[]>> =>
	ApiService.get('/api/quotations/cashtransactions/branchoffices/allowed');
//GET listado de las Monedas:
export const getCoinsAllowedToCreateCashOrder = (): Promise<AxiosResponse<ICoin[]>> =>
	ApiService.get('/api/quotations/cashtransactions/coins/allowed');
//POST orden de pago Cash
export const createCashOrder = (orderCash: ICreateOrderCash): Promise<AxiosResponse<ICreateOrderCash>> => {
	return ApiService.post('/api/quotations/cashtransactions', orderCash);
};
