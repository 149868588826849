import React from 'react';
import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Layout } from 'features/common/components/Layout';
import { ReservationSearchCard as Component } from 'features/reservation/components/ReservationSearchCard';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
}));

function ReservationSearch(): JSX.Element {
	const classes = useStyles();

	return (
		<Layout>
			<Container component="main" maxWidth="xl">
				<div className={classes.paper}>
					<Component />
				</div>
			</Container>
		</Layout>
	);
}

export default ReservationSearch;
