import {
	IPackageDeparture,
	IPackageExplorerDestination,
	IPackageExplorerDestinationFilterReq,
	IPackageExplorerReq,
	IPackageExplorerRes,
} from './types';
import { AxiosResponse } from 'axios';
import { ApiPackageService } from '../../services';

const endpoints = {
	packagesExplorer: '/explorer',
	packagesExplorerDestinationsFiltered: '/explorer/destinations-filtered',
	packagesExplorerDestinationFiltered: '/explorer/destination-filtered',
	packagesDepartures: '/departures',
};

export const getPackageExplorerData = (filter: IPackageExplorerReq): Promise<AxiosResponse<IPackageExplorerRes>> => {
	return ApiPackageService.post(endpoints.packagesExplorer, filter);
};
export const getPackageExplorerDestinationsFiltered = (
	filter: IPackageExplorerDestinationFilterReq,
): Promise<AxiosResponse<IPackageExplorerDestination[]>> => {
	return ApiPackageService.post(endpoints.packagesExplorerDestinationsFiltered, filter);
};
export const getPackageExplorerDestinationFiltered = (
	filter: IPackageExplorerDestinationFilterReq,
	destinationCode: string,
): Promise<AxiosResponse<IPackageExplorerDestination>> => {
	return ApiPackageService.post(endpoints.packagesExplorerDestinationFiltered, filter, {
		params: { destinationCode: destinationCode },
	});
};

export const getPackageDepartures = (): Promise<AxiosResponse<IPackageDeparture[]>> => {
	return ApiPackageService.get(endpoints.packagesDepartures);
};
