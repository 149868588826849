import makeStyles from '@mui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: theme.palette.common.white,
	},
	container: {
		maxWidth: 650,
		width: '100%',
	},
	form: {
		paddingTop: theme.spacing(4),
	},
	containerSelect: {
		display: 'flex',
		paddingLeft: theme.spacing(2),
		paddingTop: theme.spacing(1),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	textField: {
		width: '100%',
		backgroundColor: theme.palette.common.white,
	},
	select: {
		backgroundColor: theme.palette.common.white,
		'& .MuiSelect-select': {
			display: 'flex',
			alignItems: 'center',
		},
		'& .MuiListItemIcon-root': {
			minWidth: 'auto',
			marginRight: theme.spacing(1),
		},
	},
	buttonAdd: {
		marginTop: theme.spacing(3),
		border: theme.spacing(0),
		color: theme.palette.warning.light,
	},
	buttonDeleter: {
		marginTop: theme.spacing(4),
		border: theme.spacing(0),
		color: theme.palette.error.main,
	},
	selected: {
		backgroundColor: theme.palette.common.white,
		width: '100%',
	},
}));
