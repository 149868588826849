import React from 'react';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { QuotationSaleReport } from 'features/quotation/components/QuotationSaleReport';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));

function SaleReportPage(): JSX.Element {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Layout>
			<Container component="main" maxWidth="xl" sx={{ marginBottom: theme.spacing(3) }}>
				<Grid item xs={12}>
					<Typography variant="h5" className={classes.headerTitle}>
						Reporte de ventas
					</Typography>
				</Grid>
				<QuotationSaleReport />
			</Container>
		</Layout>
	);
}

export default SaleReportPage;
