import React from 'react';
import { useParams } from 'react-router-dom';
import { BancardCheckConfirmation as Component } from 'features/payment/components/BancardCheckConfirmation';
import { Container } from '@mui/material';
import { Header } from '../../../features/quotation/components/Header';

export const BancardCheckConfirmation = (): JSX.Element => {
	const { shopProcessId } = useParams<{ shopProcessId: string }>();

	return (
		<>
			<Header />
			<Container component="main">
				<Component shopProcessId={+shopProcessId} />
			</Container>
		</>
	);
};
