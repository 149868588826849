import React, { useState } from 'react';
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import carriers from '../../../../constants/carriers';
import ImageUploader from '../../../common/components/ImageUploader/ImageUploader';
import makeStyles from '@mui/styles/makeStyles';
import { Air, QuotationFlightImage } from '../../types';
import { parseGDSAirRawData } from '../../../../services';
import { changeOrderBack, changeOrderForward, extractBase64FromFiles, reOrderFlightImages } from '../../helpers';
import { nanoidGenerator } from '../../../common/helpers';
import { IDefaultPriceConfig } from './AirConfigCompleteQuotation';
import FlightImageConfigList from './FlightImageConfigList';
import FlightConfigTypeSwitch, { ConfigType } from './FlightConfigTypeSwitch';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	formControl: {
		width: '100%', // Fix IE 11 issue.
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	previewTitle: {
		marginBottom: theme.spacing(3),
	},
	configureFlight: {
		float: 'right',
	},
}));
export interface FlightFormConfigProps {
	handleAddAir: (air: Air) => void;
	defaultPriceConfig?: IDefaultPriceConfig;
}
export const FlightFormConfig: React.FC<FlightFormConfigProps> = ({
	defaultPriceConfig,
	handleAddAir,
}: FlightFormConfigProps) => {
	const classes = useStyles();
	const [validatingCarrier, setValidatingCarrier] = useState('');
	const [priceADT, setPriceADT] = useState(defaultPriceConfig?.adtPrice || 0);
	const [priceCHD, setPriceCHD] = useState(defaultPriceConfig?.chdPrice || 0);
	const [priceINF, setPriceINF] = useState(defaultPriceConfig?.infPrice || 0);
	const [gdsRawData, setGdsRawData] = useState('');
	const [flightImages, setFlightImages] = useState<QuotationFlightImage[]>([]);
	const [baggageType, setBaggageType] = useState<string>('none');
	const [carryOn, setCarryOn] = useState<boolean>(false);
	const [configType, setConfigType] = useState<ConfigType>('GDS');

	const handleChange = () => {
		if (configType === 'GDS' && flightImages.length > 0) {
			setFlightImages([]);
		} else {
			setGdsRawData('');
		}
		setConfigType((prevConfig) => (prevConfig === 'GDS' ? 'images' : 'GDS'));
	};

	const handleCarrierChange = (event: SelectChangeEvent) => {
		setValidatingCarrier(event.target.value as string);
	};

	const handlePriceADTChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPriceADT(Number(e.target.value));
	};

	const handlePriceCHDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPriceCHD(Number(e.target.value));
	};

	const handlePriceINFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPriceINF(Number(e.target.value));
	};

	const handleBaggageTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBaggageType((event.target as HTMLInputElement).value);
	};

	const handleGdsRawDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setGdsRawData(e.target.value);
	};

	const handleToggleCarryOn = () => {
		setCarryOn((prevState) => !prevState);
	};

	const resetFormAddFlight = () => {
		setPriceADT(0);
		setPriceCHD(0);
		setGdsRawData('');
		setPriceINF(0);
		setBaggageType('none');
		setValidatingCarrier('');
		setCarryOn(false);
		setFlightImages([]);
	};

	const isValidToAddFlight = () => {
		return (
			validatingCarrier !== '' && priceADT > 0 && (configType === 'GDS' ? gdsRawData !== '' : flightImages.length > 0)
		);
	};

	const handleOnImagesUpload = async (imagesFiles: File[]) => {
		try {
			const imagesBase64 = await extractBase64FromFiles(imagesFiles);
			if (flightImages.length === 0) {
				setFlightImages(
					imagesBase64.map((imageBase64, index) => ({ image: imageBase64, order: index + 1, id: nanoidGenerator() })),
				);
			} else {
				setFlightImages([
					...flightImages,
					...imagesBase64.map((imageBase64, index) => ({
						image: imageBase64,
						order: index + 1 + flightImages.length,
						id: nanoidGenerator(),
					})),
				]);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleOnRemoveImageFromForm = (id: string) => {
		const updatedImages = reOrderFlightImages(flightImages.filter((image) => image.id !== id));
		setFlightImages(updatedImages);
	};
	const handleOnMoveForwardInForm = (id: string) => {
		setFlightImages(changeOrderForward(id, flightImages));
	};

	const handleOnMoveBackInForm = (id: string) => {
		setFlightImages(changeOrderBack(id, flightImages));
	};

	const handleSubmit = async () => {
		let flightInfos = [];
		if (configType === 'GDS') {
			const result = await parseGDSAirRawData(gdsRawData);
			flightInfos = result.data;
		}
		const toDispatch: Air = {
			fareADT: priceADT,
			fareCHD: priceCHD,
			fareINF: priceINF,
			validatingCarrier,
			baggage: baggageType,
			tst: gdsRawData,
			carryOn,
			data: flightInfos,
			images: flightImages,
		};
		handleAddAir(toDispatch);
		resetFormAddFlight();
	};

	return (
		<form className={classes.form} noValidate>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<FormControl variant="outlined" className={classes.formControl}>
						<InputLabel id="demo-simple-select-outlined-label">Carrier</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="carrier"
							value={validatingCarrier}
							onChange={handleCarrierChange}
							label="Carrier"
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{carriers.map((item) => (
								<MenuItem key={item.code} value={item.code}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						variant="outlined"
						required
						fullWidth
						id="adtPrice"
						label="ADT Price"
						name="adtPrice"
						autoComplete="adtPrice"
						value={priceADT}
						onChange={handlePriceADTChange}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						variant="outlined"
						required
						fullWidth
						id="crmTicket"
						label="CHD Price"
						name="chdPrice"
						type="number"
						autoComplete="chdPrice"
						value={priceCHD}
						onChange={handlePriceCHDChange}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						variant="outlined"
						required
						fullWidth
						name="infPrice"
						label="INF Price"
						id="infPrice"
						autoComplete="infPrice"
						value={priceINF}
						onChange={handlePriceINFChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControl component="fieldset">
						<FormLabel component="legend">Tipo de equipaje</FormLabel>
						<RadioGroup
							aria-label="baggageTyoe"
							name="baggageType"
							value={baggageType}
							onChange={handleBaggageTypeChange}
						>
							<FormControlLabel value="none" control={<Radio />} label="No aplica" />
							<FormControlLabel value="baggage1x23k" control={<Radio />} label="1 x 23kg" />
							<FormControlLabel value="baggage2x23k" control={<Radio />} label="2 x 23kg" />
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormGroup row>
						<FormControlLabel
							control={<Checkbox checked={carryOn} onChange={handleToggleCarryOn} name="carryOn" color="primary" />}
							label="Carry on"
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={6}>
					<FlightConfigTypeSwitch configType={configType} onConfigChange={handleChange} />
				</Grid>
				{configType == 'GDS' && (
					<Grid item xs={12}>
						<TextField
							id="outlined-multiline-static"
							label="GDS Raw Data"
							multiline
							rows={8}
							variant="outlined"
							value={gdsRawData}
							onChange={handleGdsRawDataChange}
							fullWidth
						/>
					</Grid>
				)}
				{configType == 'images' && (
					<Grid item xs={12}>
						<ImageUploader onImageUpload={handleOnImagesUpload}></ImageUploader>
						{flightImages.length > 0 && (
							<FlightImageConfigList
								images={flightImages}
								onRemove={handleOnRemoveImageFromForm}
								onMoveBackward={handleOnMoveBackInForm}
								onMoveForward={handleOnMoveForwardInForm}
							/>
						)}
					</Grid>
				)}
			</Grid>
			<Button
				type="button"
				fullWidth
				disabled={!isValidToAddFlight()}
				variant="contained"
				color="primary"
				className={classes.submit}
				onClick={handleSubmit}
			>
				Agregar Aire
			</Button>
		</form>
	);
};
