import { CountryCodeEnum, ICountryAgencyConfig, RolesEnum } from '../common/types';
import { countriesWithAgencyConfig } from './constants';
import { User } from './types';

export function getApiKey(countryCode: CountryCodeEnum): string {
	return countriesWithAgencyConfig.find((x) => x.id == countryCode)?.apiKey || '';
}

export function getCountriesWithAgencyConfigForUser(agencyIds: number[]): ICountryAgencyConfig[] {
	return countriesWithAgencyConfig.filter((x) => agencyIds.indexOf(x.agencyId) > -1);
}

export function findCountryWithAgencyConfigForUser(agencyId: number): ICountryAgencyConfig | undefined {
	return countriesWithAgencyConfig.find((x) => x.agencyId == agencyId);
}

export function findCountryWithAgencyConfigByApiKey(apiKey: string): ICountryAgencyConfig | undefined {
	return countriesWithAgencyConfig.find((x) => x.apiKey == apiKey);
}

export function isAdminOrSuperAdmin(user: User | null): boolean {
	return user?.role === RolesEnum.SUPERADMIN || user?.role === RolesEnum.ADMIN;
}

export function isOperatorOrHigher(user: User | null): boolean {
	return user?.role === RolesEnum.SUPERADMIN || user?.role === RolesEnum.ADMIN || user?.role === RolesEnum.OPERATOR;
}
