import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { NotIncludes } from './NotIncludes';
import { addNotIncludeQuotationOnlyFlights, deleteNotIncludeQuotationOnlyFlights } from '../../sliceOnlyFlights';

export const NotIncludesQuotationOnlyFlights = (): JSX.Element => {
	const dispatch = useDispatch();
	const { notIncludes } = useSelector((state: RootState) => state.quotationOnlyFlights);

	const handleDeleteNotInclude = (index: number) => {
		dispatch(deleteNotIncludeQuotationOnlyFlights(index));
	};
	const handleAddNotInclude = (notIncludeText: string) => {
		dispatch(addNotIncludeQuotationOnlyFlights(notIncludeText));
	};

	return (
		<NotIncludes notIncludes={notIncludes} handleDelete={handleDeleteNotInclude} handleAdd={handleAddNotInclude} />
	);
};
