import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

import { Layout } from 'features/common/components/Layout';
import routes from 'constants/routes';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	paginationHolder: {
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

function UserList(): JSX.Element {
	const classes = useStyles();
	const history = useHistory();

	const handleCreateUser = () => history.push(routes.user.new);

	return (
		<Layout>
			<Container component="main" maxWidth="md">
				<div className={classes.paper}>
					<Button variant="contained" color="primary" onClick={handleCreateUser}>
						Create User
					</Button>
				</div>
			</Container>
		</Layout>
	);
}

export default UserList;
