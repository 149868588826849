import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

interface Props {
	name: string;
	rules?: Record<string, any>;
	inputFormat?: string;
	size?: 'small' | 'medium';
	variant?: 'outlined' | 'standard';
	required?: boolean;
}

export const ControlledDatePicker = ({
	name,
	rules,
	variant = 'outlined',
	size = 'small',
	required = true,
}: Props): JSX.Element => {
	const { control, formState } = useFormContext();
	const error = formState.errors[name]?.message;

	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<TextField
					required={required}
					type="date"
					variant={variant}
					size={size}
					sx={{ width: '100%', backgroundColor: '#FFFFFF' }}
					error={!!error}
					{...field}
					value={field.value || null}
				/>
			)}
		/>
	);
};
