import { BankAccountCountry } from '../payment/types';

export interface ITransactionSummary {
	totals: {
		totalPending: number;
		totalPendingAuthorization: number;
		totalPendingAuthorizationMore24Hours: number;
		totalPendingToCross: number;
		totalPendingToCrossDueNext10Days: number;
	};
	pendingByValidatingCarriers: [
		{
			validatingCarrierName: string;
			totalPendingToCross: number;
			totalPendingToCrossDueNext10Days: number;
		},
	];
}

export interface ITransactionList {
	id: number;
	orderId: string;
	crmTicket: number;
	file?: string;
	cardholder: string;
	cardStamp: string;
	cardNumberFormatted: string;
	cardExpiration: string;
	cardSecurityCodeFormatted: string;
	fee: number;
	coinCode: string;
	amount: number;
	pendingAuthorization: number;
	pendingToCross: number;
	createdAt: string;
}

export enum ChargedAgainstOptionTypeEnum {
	VC = 'VC',
	PaymentGateway = 'PaymentGateway',
}

export interface IChargedAgainstOption {
	code: string;
	name: string;
	optionType: ChargedAgainstOptionTypeEnum;
}

export interface IGatewayTransaction {
	orderId: string;
	crmTicket: number;
	file: string;
	id: number;
	createdAt: string;
	invoiceNumber: number;
	fee?: number;
	amount: number;
	coinCode: string;
	name: string;
	lastName: string;
	email?: string;
	document?: string;
	documentType?: number;
	phone?: string;
	status: number;
	approvalCode?: string;
	cardLastFourDigit?: string;
	cardExpiration?: string;
	changeAt: string;
}

export interface ITransactionLineToDetail {
	id: number;
	crmTicket: number;
	file?: string;
	cardholder: string;
	cardStamp: string;
	cardNumberFormatted: string;
	cardExpiration: string;
	cardSecurityCodeFormatted: string;
	fee: number;
	coinCode: string;
	amount: number;
	pendingAuthorization: number;
	pendingToCross: number;
}

export interface IValidatingCarrier {
	code: string;
	name: string;
}

export interface IAuthorizationLine {
	id: number;
	chargedAgainstOptionName: string;
	chargedAgainstOptionType: ChargedAgainstOptionTypeEnum;
	amountAuthorized: number;
	coinCode: string;
	authorizationCode: string;
	paymentDate: string;
	paymentDueDate: string;
	pendingToCross: string;
	state: AuthorizationStateEnum;
	createdAt: string;
}

export interface IAuthorizationLineRefund {
	amountRefunded: number;
	coinCode: string;
	refundedAt: string;
	refundedBy: string;
}

export interface IAuthorizationLineWithRefunded {
	id: number;
	chargedAgainstOptionName: string;
	amountAuthorized: number;
	coinCode: string;
	authorizationCode: string;
	paymentDate: string;
	paymentDueDate: string;
	pendingToCross: string;
	state: AuthorizationStateEnum;
	createdAt: string;
	refunded: IAuthorizationLineRefund[];
}

export interface IFlagShowCardInfo {
	showCreditCardNumber: boolean;
	showSecurityCode: boolean;
}
export interface ITransactionDetails {
	transactionData: ITransactionLineToDetail;
	authorizationsData: IAuthorizationLineWithRefunded[];
	flagShowCardInfo: IFlagShowCardInfo;
}

export interface IOperators {
	value: string;
	description: string;
}

export interface IAuthorization {
	transactionId: number;
	chargedAgainstOptionCode: string;
	chargedAgainstOptionType: ChargedAgainstOptionTypeEnum;
	amountToAuthorize: number;
	authorizationCode: string;
}

export interface ICardInfoResult {
	cardNumber: number;
	cardSecurityCode: number;
	flagShowCardInfo: IFlagShowCardInfo;
}

export enum AuthorizationStateEnum {
	AUTHORIZED = 1,
	PARTIALLYCROSSED,
	TOTALLYCROSSED,
}

export interface AuthorizationStateInterface {
	id: AuthorizationStateEnum;
	name: string;
}

export const authorizationStateList: AuthorizationStateInterface[] = [
	{
		id: AuthorizationStateEnum.AUTHORIZED,
		name: 'Autorizado',
	},
	{
		id: AuthorizationStateEnum.PARTIALLYCROSSED,
		name: 'Parcialmente Cruzado',
	},
	{
		id: AuthorizationStateEnum.TOTALLYCROSSED,
		name: 'Cruzado',
	},
];

export enum GatewayDocumentTypeEnum {
	RUT = 1,
	CEDULA,
	PASSPORT,
	DNI,
}

export interface GatewayDocumentTypeInterface {
	id: GatewayDocumentTypeEnum;
	name: string;
}

export const gatewayDocumentTypeList: GatewayDocumentTypeInterface[] = [
	{
		id: GatewayDocumentTypeEnum.RUT,
		name: 'RUC/RUT',
	},
	{
		id: GatewayDocumentTypeEnum.CEDULA,
		name: 'Cédula',
	},
	{
		id: GatewayDocumentTypeEnum.PASSPORT,
		name: 'Pasaporte',
	},
	{
		id: GatewayDocumentTypeEnum.DNI,
		name: 'DNI',
	},
];
export enum GatewayStatusEnum {
	CHARGED = 1,
	PENDINGPAYMENTGATEWAY,
	REJECT,
	GATEWAYERROR,
	NOT_PROCESSED,
	VOIDED,
}

export interface GatewayStatusInterface {
	id: GatewayStatusEnum;
	name: string;
}

export const gatewayStatusList: GatewayStatusInterface[] = [
	{
		id: GatewayStatusEnum.CHARGED,
		name: 'Cobrado',
	},
	{
		id: GatewayStatusEnum.PENDINGPAYMENTGATEWAY,
		name: 'Pendiente a Pasarela de Pago',
	},
	{
		id: GatewayStatusEnum.REJECT,
		name: 'Rechazado',
	},
	{
		id: GatewayStatusEnum.GATEWAYERROR,
		name: 'Error en Plataforma de Pago',
	},
	{
		id: GatewayStatusEnum.NOT_PROCESSED,
		name: 'No procesada',
	},
	{
		id: GatewayStatusEnum.VOIDED,
		name: 'Anulado',
	},
];

export interface IWireTransferTransactionList {
	id: string;
	provider: string;
	country: BankAccountCountry;
	bank: string;
	account: string;
	bankDate: string;
	bankReference: string;
	companyReference: string | undefined;
	credit: number;
	debit: number;
	currency: string;
	creditInUSD: number;
	debitInUSD: number;
	arbitration: number;
	observations: string | undefined;
	createdAt: string;
	changedAt: string;
}

export interface ICurrentBalanceByCountryContainer {
	details: ICurrentBalanceByCountry[];
	totalInUSD: number;
}

export interface ICurrentBalanceByCountry {
	country: BankAccountCountry;
	details: ICurrentBalanceByCountryDetail[];
	totalUSD: number;
}

export interface ICurrentBalanceByCountryDetail {
	currency: string;
	amount: number;
	arbitration: number;
}

export interface IContainerCurrentBalanceInBankByCountry {
	details: ICurrentBalanceInBankByCountry[];
	countries: BankAccountCountry[];
}

export interface ICurrentBalanceInBankByCountry {
	country: BankAccountCountry;
	details: ICurrentBalanceInBankDetail[];
	currenciesAllowed: string[];
}

export interface ICurrentBalanceInBankDetail {
	bank: string;
	currency: string;
	amount: number;
}

export interface IWireTransferChangeObservation {
	id: string;
	provider: string;
	observations: string;
}

export interface IAuthorizationAllowedToRefund {
	id: number;
	authorizationCode: string;
	maxAllowedToRefund: number;
}

export interface ICashTransactionList {
	id: number;
	crmTicket: number;
	coinCode: string;
	amount: number;
	branchOfficeName?: string;
	bankAccountName?: string;
	amountInUSD: number;
	arbitration: number;
	observations?: string;
	createdAt: string;
	createdBy: string;
}

export interface IBankAccountsList {
	id: string;
	name: string;
}

export interface IBranchOfficesList {
	id: number;
	name: string;
}

export interface ICashTransactionLog {
	originName: string;
	destinationName: string;
	userName: string;
	moveAt: string;
}

export interface ICurrentBalanceByBranchOfficeDetail {
	coinCode: string;
	amount: number;
	arbitration: number;
}

export interface ICurrentBalanceByBranchOffice {
	branchOfficeName: string;
	details: ICurrentBalanceByBranchOfficeDetail[];
	totalUSD: number;
}

export interface IDestinationsToMoveCash {
	branchOffices: IBranchOfficesList[];
	bankAccounts: IBankAccountsList[];
}

export interface IDestinationToMoveCashList {
	id: number | string;
	name: string;
}

export interface ICashTransactionMoveCash {
	cashTransactionIds: number[];
	branchOfficeId?: number;
	bankAccountId?: string;
}

export interface IVNBancardTransactionList {
	orderId?: string;
	relationId: number;
	lineType: number;
	crmTicket?: number;
	file?: string;
	reserveCode?: string;
	invoiceNumber: number;
	amount: number;
	personType: number;
	firstName: string;
	lastName: string;
	email: string;
	document: string;
	documentType: number;
	phone: string;
	bancardPaymentState: number;
	approvalCode?: string;
	createdAt: string;
}

export enum VNTransactionLineTypeEnum {
	RESERVE = 1,
	ORDERTRANSACTION,
}

export interface ITransactionListTypeVN {
	id: VNTransactionLineTypeEnum;
	name: string;
}

export const lineTypeVN: ITransactionListTypeVN[] = [
	{
		id: VNTransactionLineTypeEnum.RESERVE,
		name: 'Reserva',
	},
	{
		id: VNTransactionLineTypeEnum.ORDERTRANSACTION,
		name: 'Transacción',
	},
];

export enum PersonTypeEnumVN {
	Persona = 1,
	Empresa,
}

export interface IPersonTypeVN {
	id: PersonTypeEnumVN;
	name: string;
}

export const personTypeVN: IPersonTypeVN[] = [
	{
		id: PersonTypeEnumVN.Persona,
		name: 'Persona',
	},
	{
		id: PersonTypeEnumVN.Empresa,
		name: 'Empersa',
	},
];

export enum BancardPaymentStateEnum {
	OK = 1,
	PENDING,
	ERROR,
	NOT_PROCESSED,
}

export interface IBancardPaymentState {
	id: BancardPaymentStateEnum;
	name: string;
}

export const bancardPaymentState: IBancardPaymentState[] = [
	{
		id: BancardPaymentStateEnum.OK,
		name: 'Ok',
	},
	{
		id: BancardPaymentStateEnum.PENDING,
		name: 'Pendiente',
	},
	{
		id: BancardPaymentStateEnum.ERROR,
		name: 'Error',
	},
	{
		id: BancardPaymentStateEnum.NOT_PROCESSED,
		name: 'No Procesado',
	},
];

export interface VNItauPointTransactionList {
	orderId?: string;
	relationId: number;
	lineType: number;
	crmTicket?: number;
	file?: string;
	reserveCode?: string;
	programMemberShipPoints?: number;
	programMemberShipTransactionId?: string;
	programInterventajasPoints?: number;
	programInterventajasTransactionId?: string;
	totalItauPoints: number;
	amount: number;
	rate: number;
	personType: number;
	firstName: string;
	lastName: string;
	email: string;
	document: string;
	documentType: number;
	phone: string;
	itauPaymentState: number;
	createdAt: string;
	updatedAt?: string;
	updatedBy?: string;
}

export enum ItauPointsPaymentStateEnum {
	OK = 1,
	ERROR,
	RETURNED,
	ERROR_NOT_RETURNED,
	NOT_PROCESSED,
}

export interface IItauPointsPaymentStateEnum {
	id: ItauPointsPaymentStateEnum;
	name: string;
}

export const itauPointsPaymentState: IItauPointsPaymentStateEnum[] = [
	{
		id: ItauPointsPaymentStateEnum.OK,
		name: 'Canjeados',
	},
	{
		id: ItauPointsPaymentStateEnum.ERROR,
		name: 'Error',
	},
	{
		id: ItauPointsPaymentStateEnum.RETURNED,
		name: 'Extornados',
	},
	{
		id: ItauPointsPaymentStateEnum.ERROR_NOT_RETURNED,
		name: 'Error al extornar',
	},
	{
		id: ItauPointsPaymentStateEnum.NOT_PROCESSED,
		name: 'No procesado',
	},
];
