import React from 'react';
import { Layout } from 'features/common/components/Layout';
import Component from 'features/agency/components/AgencyForm';

function AgencyNew(): JSX.Element {
	return (
		<Layout>
			<Component />
		</Layout>
	);
}

export default AgencyNew;
