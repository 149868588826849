import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { postSendQuotationToClient } from '../../../services';
import { convertQuotationToSnapShotToClient, extractErrorMessage } from '../helpers';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { nanoidGenerator } from '../../common/helpers';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

interface Props {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	onError: (error: string) => void;
}

export const SendQuotationToClient = (props: Props): JSX.Element => {
	const { quotation, auth } = useSelector((state: RootState) => state);
	const [message, setMessage] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const classes = useStyles();
	const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(e.target.value);
	};
	const quotationToClient = convertQuotationToSnapShotToClient(quotation, auth.country);

	const handleSendQuotationToClient = async () => {
		if (quotationToClient != null) {
			try {
				const body = {
					id: nanoidGenerator(),
					message: message,
					quotationSnapShotToClient: quotationToClient,
					quotationType: quotationToClient.quotationType,
					sendLink: true,
				};
				setLoading(true);
				await postSendQuotationToClient(body);
				setLoading(false);
				setMessage('');
				props.onSuccess();
			} catch (error) {
				const errorMessage = extractErrorMessage(error, 'Ocurrió un error enviar la cotización al cliente');
				setLoading(false);
				setMessage('');
				props.onError(errorMessage);
			}
		} else props.onError('La cotización no es válida para enviarla al cliente');
	};

	const isValidMessage = () =>
		message && message.length > 0 && message.length <= 2000 && message.replace(/ /g, '').length > 0;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog
				open={props.open}
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">{'Enviar cotización al cliente'}</DialogTitle>
				<DialogContent>
					<div>
						<TextField
							id="outlined-multiline-static"
							label="Mensaje para el cliente"
							multiline
							rows={5}
							required
							variant="outlined"
							value={message}
							onChange={handleMessageChange}
							fullWidth
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={props.onClose}>
						Cancelar
					</Button>
					{quotationToClient != null && (
						<Button
							variant="contained"
							color="primary"
							disabled={!isValidMessage()}
							onClick={handleSendQuotationToClient}
							autoFocus
						>
							Enviar
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};
