import React, { useEffect, useState } from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	Container,
	Grid,
	Snackbar,
	Typography,
	useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { GatewayTransactionList as Component } from 'features/transactions/components/GatewayTransactionList';
import { useParams } from 'react-router-dom';
import { orderTypes } from 'features/common/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FilterModel, ISort } from '../../../features/common/types';
import { getFilterFromProfile } from '../../../features/common/services';
import { extractErrorMessage } from '../../../features/quotation/helpers';
import { PageNotAllowedForMarket } from '../../../features/common/components/PageNotAllowedForMarket';
import { isAllowOrderTypeInCountry } from 'features/common/helpers';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

function GatewayTransactionList(): JSX.Element {
	const classes = useStyles();
	const theme = useTheme();
	const { orderTypeId } = useParams<{ orderTypeId: string }>();
	const [loading, setLoading] = useState<boolean>(true);
	const { auth } = useSelector((state: RootState) => state);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'createdAt', order: 'desc' }]);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const userId = auth.user?.id;
	const apiKey = auth.apiKey;
	const countryCode = auth.country;
	const profileTag = 'gateway-transaction-list-tag';

	const getInitialProfileFilters = async () => {
		try {
			const { profileSorts, profileFilters } = await getFilterFromProfile(
				profileTag,
				apiKey,
				countryCode,
				userId?.toString(),
			);
			profileSorts && setSortFields([...profileSorts]);
			profileFilters && setFilterApplied([...profileFilters]);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir los filtros del perfil'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getInitialProfileFilters();
	}, []);

	const nameOfOrderType = orderTypes.find((orderType) => orderType.id === +orderTypeId)?.name || '';

	const isAllowedOrderType = isAllowOrderTypeInCountry(+orderTypeId, countryCode);

	return (
		<Layout>
			<div>
				<Container component="main" maxWidth="xl" sx={{ marginBottom: theme.spacing(3) }}>
					{!isAllowedOrderType ? (
						<PageNotAllowedForMarket />
					) : (
						<>
							<Backdrop className={classes.backdrop} open={loading}>
								<CircularProgress color="inherit" />
							</Backdrop>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="h5" className={classes.headerTitle}>
										Lista de Transacciones en pasarela de pago {nameOfOrderType}
									</Typography>
								</Grid>
							</Grid>
							{!loading && (
								<Component orderTypeId={+orderTypeId} profileFilters={filterApplied} profileSorts={sortFields} />
							)}
							<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
								<Alert variant="filled" severity={alert.severity}>
									{alert.message}
								</Alert>
							</Snackbar>
						</>
					)}
				</Container>
			</div>
		</Layout>
	);
}

export default GatewayTransactionList;
