import React, { useEffect, useState } from 'react';
import { Checkbox, Grid, Paper, Typography, FormControlLabel } from '@mui/material';
import { IlodgingType } from '../../types';

export interface LodgingTypeFilterProps {
	data: IlodgingType[];
	onChange: (value: number[]) => void;
}

export const LodgingTypeFilter = ({ data, onChange }: LodgingTypeFilterProps): JSX.Element => {
	const [selected, setSelected] = useState<number[]>([]);

	useEffect(() => onChange(selected), [setSelected]);

	return (
		<Paper>
			<Grid container sx={{ display: 'grid', marginLeft: 2, marginTop: 4 }}>
				<Grid item xs={12}>
					<Typography variant="h6" fontWeight="bold">
						Tipo de alojamiento
					</Typography>
				</Grid>
				{data.map((item) => (
					<FormControlLabel key={item.id} control={<Checkbox key={item.id} color="secondary" />} label={item.name} />
				))}
			</Grid>
		</Paper>
	);
};
