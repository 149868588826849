import React from 'react';
import { IPackageExplorerDestination } from '../../types';
import { Container, Grid, Typography } from '@mui/material';
import { PackageExplorerDestinationCard } from '../PackageExplorerDestinationCard';
import { useTheme } from '@mui/styles';
import { PageNoResult } from '../../../common/components/PageNoResults';

interface Props {
	departureName: string;
	destinations: IPackageExplorerDestination[];
	handleDestinationCardClick: (destinationCode: string) => void;
}

export const PackageExplorerDestinationList = ({
	departureName,
	destinations,
	handleDestinationCardClick,
}: Props): JSX.Element => {
	const theme = useTheme();
	return (
		<Container component="main" maxWidth={'lg'} sx={{ 'padding-top': '15px', marginBottom: '15px' }}>
			<Typography component="h1" fontWeight={'bold'} fontSize={'22px'} color={theme.palette.primary.main}>
				Explora destinos
			</Typography>

			<Typography variant="body2" color={theme.palette.primary.main}>
				Saliendo desde {departureName}
			</Typography>
			<Grid container spacing={2} pt={2}>
				{destinations.length === 0 ? (
					<PageNoResult />
				) : (
					destinations.map((destination) => (
						<Grid
							item
							xs={4}
							md={4}
							key={`package-explorer-destination-${destination.code}`}
							onClick={() => handleDestinationCardClick(destination.code)}
						>
							<PackageExplorerDestinationCard destination={destination} />
						</Grid>
					))
				)}
			</Grid>
		</Container>
	);
};
