export const getBasePriceString = (basePrice: number): string => {
	switch (basePrice) {
		case 1:
			return 'en base single';
		case 2:
			return 'en base doble';
		case 3:
			return 'en base triple';
		case 4:
			return 'en base cuádruple';
		case 5:
			return 'en base quíntuple';
		case 6:
			return 'en base séxtuple';
		case 7:
			return 'en base Family Plan';
		default:
			return '';
	}
};
