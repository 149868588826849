import React, { useEffect, useState } from 'react';
import { Alert, AlertColor, Backdrop, CircularProgress, Snackbar, useTheme } from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { automaticRouteTariffPricesForGraphic } from '../../../services';
import { DataToChart } from '../../../types';
import { formatDateToShowUser, getMonthName } from '../../../../common/helpers';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	containerPriceByDate: {
		height: '200 px',
	},
}));

export interface AutomaticRouteGraphicPricesProps {
	automaticRouteId: number;
}

export const AutomaticRouteGraphicPrices = ({ automaticRouteId }: AutomaticRouteGraphicPricesProps): JSX.Element => {
	const theme = useTheme();
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [dataToChartMinPriceByMonth, setDataToChartMinPriceByMonth] = useState<DataToChart | null>(null);
	const [dataToChartPricesByDate, setDataToChartPricesByDate] = useState<DataToChart | null>(null);

	const getInfoForGraphic = async () => {
		try {
			setLoading(true);
			setDataToChartPricesByDate(null);
			setDataToChartMinPriceByMonth(null);
			const infoPrice = (await automaticRouteTariffPricesForGraphic(automaticRouteId)).data;
			const labelsPriceByDate: string[] = [];
			const pricesByDate: number[] = [];
			const labelsPriceByMonth: string[] = [];
			const pricesByMonth: number[] = [];
			infoPrice.pricesByDate.forEach((value) => {
				labelsPriceByDate.push(formatDateToShowUser(value.travelDate));
				pricesByDate.push(value.tariffPriceAdult);
			});

			infoPrice.minPricesByMonth.forEach((value) => {
				labelsPriceByMonth.push(getMonthName(value.month));
				pricesByMonth.push(value.tariffPriceAdult);
			});
			setDataToChartPricesByDate({ labels: labelsPriceByDate, prices: pricesByDate });
			setDataToChartMinPriceByMonth({ labels: labelsPriceByMonth, prices: pricesByMonth });
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir las tarifas de la ruta'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getInfoForGraphic();
	}, [automaticRouteId]);

	const optionsPriceByDate = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: true,
				text: 'Precios por dia para los 12 meses',
			},
		},
	};

	const optionsMinByMonth = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: true,
				text: 'El menor precio de cada mes:',
			},
		},
		maintainAspectRatio: false,
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{dataToChartMinPriceByMonth && dataToChartMinPriceByMonth.labels.length > 0 ? (
				<div className={classes.containerPriceByDate}>
					{' '}
					<Bar
						options={optionsMinByMonth}
						data={{
							labels: dataToChartMinPriceByMonth?.labels,
							datasets: [
								{
									label: 'Tarifas por Adulto',
									data: dataToChartMinPriceByMonth?.prices,
									hoverBorderWidth: 5,
									backgroundColor: theme.palette.primary.main,
								},
							],
						}}
					/>{' '}
				</div>
			) : (
				'No tenemos datos de esta ruta'
			)}
			{dataToChartPricesByDate && dataToChartPricesByDate.labels.length > 0 ? (
				<Bar
					options={optionsPriceByDate}
					data={{
						labels: dataToChartPricesByDate?.labels,
						datasets: [
							{
								label: 'Tarifas por Adulto',
								data: dataToChartPricesByDate?.prices,
								minBarLength: 50,
								backgroundColor: theme.palette.primary.main,
							},
						],
					}}
				/>
			) : automaticRouteId == -1 ? (
				'Selecciona una ruta para ver el comportamiento de las tarifas'
			) : (
				'Esta ruta no cuenta con tarifas por lo que no podemos mostrarte la estadística de precios'
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
