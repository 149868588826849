import React from 'react';
import { Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationFieldsMaxLength } from '../../types';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

interface Props {
	onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void | null;
	onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onPhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onCRMTicketChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onConfirmAutoCompleteDataByDeal: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	crmTicket: number;
	name: string;
	email: string | null;
	phone: string;
	descripcion: string;
	showDescriptionField: boolean;
}

export default function GeneralConfigForm(props: Props): JSX.Element {
	const classes = useStyles();
	return (
		<form className={classes.form} noValidate>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						required
						fullWidth
						type="tel"
						value={props.crmTicket}
						id="crmTicket"
						label="Bitrix Deal #"
						name="crmTicket"
						autoComplete="crmTicket"
						autoFocus
						onKeyDown={props.onConfirmAutoCompleteDataByDeal}
						onChange={props.onCRMTicketChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						autoComplete="name"
						name="adults"
						variant="outlined"
						value={props.name}
						required
						fullWidth
						id="name"
						label="Nombre"
						onChange={props.onNameChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						variant="outlined"
						fullWidth
						id="email"
						label="Email"
						name="email"
						value={props.email === '' ? null : props.email}
						autoComplete="email"
						onChange={props.onEmailChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						required
						fullWidth
						name="phone"
						label="Telefono"
						value={props.phone}
						id="phone"
						autoComplete="phone"
						onChange={props.onPhoneChange}
					/>
				</Grid>
				{props.showDescriptionField && (
					<Grid item xs={12}>
						<TextField
							id="outlined-multiline-static"
							label="Descripcion"
							multiline
							rows={8}
							variant="outlined"
							value={props.descripcion}
							onChange={props.onDescriptionChange}
							fullWidth
						/>
						{QuotationFieldsMaxLength.DESCRIPTION - props.descripcion.length >= 0 && (
							<Typography variant="body2" sx={{ color: 'green' }}>
								{'Total de caracteres para alcanzar el máximo permitido: ' +
									(QuotationFieldsMaxLength.DESCRIPTION - props.descripcion.length)}
							</Typography>
						)}
						{QuotationFieldsMaxLength.DESCRIPTION - props.descripcion.length < 0 && (
							<Typography variant="body2" sx={{ color: 'red' }}>
								{'La cantidad de caracteres supera el máximo permitido de ' +
									QuotationFieldsMaxLength.DESCRIPTION +
									' caracteres'}
							</Typography>
						)}
					</Grid>
				)}
			</Grid>
		</form>
	);
}
