import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { OptionalService } from '../../types';
import { GetPAXTotalsResponse } from '../../helpers';
import { Link } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		media: {
			height: 0,
			paddingTop: '56.25%', // 16:9
		},
		title: {
			color: theme.palette.primary.main,
			fontWeight: 'normal',
			paddingBottom: '10px',
			fontSize: '1.25rem',
		},
		prices: {
			display: 'flex',
			alignItems: 'left',
			paddingLeft: theme.spacing(2),
			paddingBottom: theme.spacing(1),
			placeContent: 'space-evenly',
		},
		pricesText: {
			color: '#F84932',
			fontWeight: 'bold',
		},
		flex: {
			display: 'flex',
		},
	}),
);
interface Props {
	optionalService: OptionalService;
	paxTotals: GetPAXTotalsResponse;
}

export const OptionalServiceCardMobile = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { optionalService, paxTotals } = props;
	return (
		<Card>
			<CardMedia className={classes.media} image={optionalService.imageUrl} title={optionalService.name} />
			<div>
				<CardContent>
					{optionalService?.url == undefined && (
						<Typography component="h1" variant="h5" color="primary" className={classes.title}>
							{optionalService.name}
						</Typography>
					)}
					{optionalService?.url !== undefined && (
						<Link
							href={optionalService?.url}
							color="inherit"
							target="_blank"
							rel="noopener"
							variant="h5"
							className={classes.title}
						>
							{optionalService.name}
						</Link>
					)}
					<div className={classes.flex}>
						<Typography style={{ whiteSpace: 'pre-line' }} component={'span'} variant={'body2'}>
							{optionalService.description}
						</Typography>
					</div>
				</CardContent>

				<div className={classes.prices}>
					<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
						{'Precio por adulto  USD ' + optionalService.adultPrice}
					</Typography>
					<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
						{paxTotals.child > 0 &&
							optionalService.childPrice != undefined &&
							' Precio por niño  USD ' + optionalService.childPrice}
					</Typography>
					<Typography component={'span'} variant={'body2'} className={classes.pricesText}>
						{paxTotals.infants > 0 &&
							optionalService.infantPrice != undefined &&
							' Precio por bebé USD ' + optionalService.infantPrice}
					</Typography>
				</div>
			</div>
		</Card>
	);
};
