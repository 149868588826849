import React, { useState } from 'react';

import { Box, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FlightExplorerOrderBy, IOrderInfo } from '../../types';

interface Props {
	data: IOrderInfo;
	handleChangeOrderBy: (value: FlightExplorerOrderBy) => void;
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		color: 'white',
	},
	orderSelected: {
		backgroundColor: theme.palette.secondary.main,
	},
	orderUnSelected: {
		backgroundColor: '#8167A3',
	},
	orderItemContainer: {
		textAlign: 'center',
		boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 3px 0px',
		cursor: 'pointer',
		borderRadius: '14px',
		flex: '1 1 0%',
		transition: 'all 0.6s ease 0s',
		padding: '4px',
	},
}));

export const FlightExplorerItineraryHeaderSort = ({ data, handleChangeOrderBy }: Props): JSX.Element => {
	const classes = useStyles();
	const [orderValue, setOrderValue] = useState<FlightExplorerOrderBy>(FlightExplorerOrderBy.BEST_PRICE);

	const handleChangeOrderByState = (order: FlightExplorerOrderBy) => {
		setOrderValue(order);
		handleChangeOrderBy(order);
	};

	return (
		<Container
			component="main"
			maxWidth={'xl'}
			sx={{
				'margin-bottom': '15px',
				marginLeft: '0px',
				display: 'flex',
				gap: 2,
				justifyContent: 'space-between',
				backgroundColor: '#8167A3',
				borderRadius: '14px',
				transition: 'all 0.6s ease 0s',
			}}
		>
			<Box
				className={`${classes.orderItemContainer} ${
					orderValue == FlightExplorerOrderBy.RECOMMENDED ? classes.orderSelected : classes.orderUnSelected
				}`}
				onClick={() => handleChangeOrderByState(FlightExplorerOrderBy.RECOMMENDED)}
			>
				<Typography variant="subtitle1" component="p" fontSize={15} color={'white'} fontWeight={'bold'}>
					{' '}
					Más conveniente
				</Typography>
				<Typography variant="subtitle1" component="span" fontSize={13} color={'white'} marginRight={'10px'}>
					{data.recommended.priceForOneAdult.currency} {data.recommended.priceForOneAdult.amount}
				</Typography>
				<Typography variant="subtitle1" component="span" fontSize={13} color={'white'}>
					{data.recommended.duration.totalHours} h {data.recommended.duration.totalMinutes} m{' '}
				</Typography>
			</Box>

			<Box
				className={`${classes.orderItemContainer} ${
					orderValue == FlightExplorerOrderBy.BEST_PRICE ? classes.orderSelected : classes.orderUnSelected
				}`}
				onClick={() => handleChangeOrderByState(FlightExplorerOrderBy.BEST_PRICE)}
			>
				<Typography variant="subtitle1" component="p" fontSize={15} color={'white'} fontWeight={'bold'}>
					Más económico
				</Typography>
				<Typography variant="subtitle1" component="span" fontSize={13} color={'white'} marginRight={'10px'}>
					{data.bestPrice.priceForOneAdult.currency} {data.bestPrice.priceForOneAdult.amount}
				</Typography>
				<Typography variant="subtitle1" component="span" fontSize={13} color={'white'}>
					{data.bestPrice.duration.totalHours} h {data.bestPrice.duration.totalMinutes} m{' '}
				</Typography>
			</Box>

			<Box
				className={`${classes.orderItemContainer} ${
					orderValue == FlightExplorerOrderBy.BEST_DURATION ? classes.orderSelected : classes.orderUnSelected
				}`}
				onClick={() => handleChangeOrderByState(FlightExplorerOrderBy.BEST_DURATION)}
			>
				<Typography variant="subtitle1" component="p" fontSize={15} color={'white'} fontWeight={'bold'}>
					Más rápido
				</Typography>
				<Typography variant="subtitle1" component="span" fontSize={13} color={'white'} marginRight={'10px'}>
					{data.bestDuration.priceForOneAdult.currency} {data.bestDuration.priceForOneAdult.amount}
				</Typography>
				<Typography variant="subtitle1" component="span" fontSize={13} color={'white'}>
					{data.bestDuration.duration.totalHours} h {data.bestDuration.duration.totalMinutes} m{' '}
				</Typography>
			</Box>
		</Container>
	);
};
