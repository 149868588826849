import React from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { extractErrorMessage } from 'features/quotation/helpers';
import { getTransactionSummary } from 'features/transactions/services';
import { ITransactionSummary } from 'features/transactions/types';
import { useEffect, useState } from 'react';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	cardItem: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		justifyContent: 'center',
	},
	specialCardItem: {
		backgroundColor: theme.palette.secondary.main,
		color: '#310774',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		justifyContent: 'center',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
}));

export const TransactionSummary = (): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [transactionsSummary, setTransactionsSummary] = useState<ITransactionSummary | null>(null);

	const getSummary = async () => {
		try {
			setLoading(true);
			setTransactionsSummary((await getTransactionSummary()).data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el resumen de transacciones'),
			});
		}
	};

	useEffect(() => {
		getSummary();
	}, []);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			{transactionsSummary && (
				<Grid container gap={2}>
					<Grid item xs={12}>
						<Grid container gap={2}>
							<Grid item xs={12}>
								<Grid container justifyContent="center">
									<Grid item xs={12}>
										<Card className={classes.specialCardItem} sx={{ minWidth: 100 }}>
											<CardContent>
												<Typography variant="body1" component="p">
													Total de tarjetas en cartera
												</Typography>
												<Typography variant="h6">USD {transactionsSummary?.totals.totalPending}</Typography>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
								<Grid container marginTop={1} spacing={2}>
									<Grid item xs={12} md={3}>
										<Card className={classes.cardItem} sx={{ minWidth: 100, minHeight: 120 }}>
											<CardContent>
												<Typography variant="body1" component="p">
													Pendiente a autorizar
												</Typography>
												<Typography variant="h6">
													USD {transactionsSummary?.totals.totalPendingAuthorization}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
									<Grid item xs={12} md={3}>
										<Card className={classes.cardItem} sx={{ minWidth: 100, minHeight: 120 }}>
											<CardContent>
												<Typography variant="body1" component="p">
													Pendiente a autorizar con más de 24h
												</Typography>
												<Typography variant="h6">
													USD {transactionsSummary?.totals.totalPendingAuthorizationMore24Hours}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
									<Grid item xs={12} md={3}>
										<Card className={classes.cardItem} sx={{ minWidth: 100, minHeight: 120 }}>
											<CardContent>
												<Typography variant="body1" component="p">
													Pendiente a cruzar
												</Typography>
												<Typography variant="h6">USD {transactionsSummary?.totals.totalPendingToCross}</Typography>
											</CardContent>
										</Card>
									</Grid>
									<Grid item xs={12} md={3}>
										<Card className={classes.cardItem} sx={{ minWidth: 100, minHeight: 120 }}>
											<CardContent>
												<Typography variant="body1" component="p">
													Pendiente a cruzar con vencimiento próximos 10 días
												</Typography>
												<Typography variant="h6">
													USD {transactionsSummary?.totals.totalPendingToCrossDueNext10Days}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: 440 }}>
									<div>
										<Table stickyHeader size="small">
											<TableHead>
												<TableRow>
													<TableCell className={classes.tableHeader} align="right">
														Validating Carrier
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														Total de Pendientes a Cruzar
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														Pendiente a Cruzar con Vto aprox. 10 días
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{transactionsSummary?.pendingByValidatingCarriers.map((transaction) => (
													<TableRow
														key={transaction.validatingCarrierName}
														hover
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
													>
														<TableCell align="justify" component="p">
															{transaction.validatingCarrierName}
														</TableCell>
														<TableCell align="right" component="p">
															{transaction.totalPendingToCross} USD
														</TableCell>
														<TableCell align="right" component="p">
															{transaction.totalPendingToCrossDueNext10Days} USD
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</div>
								</TableContainer>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
