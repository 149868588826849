import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Snackbar,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import { checkShowErrorMessage } from '../../../common/helpers';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { IAuthorizationAllowedToRefund } from 'features/transactions/types';
import { getAuthorizationAllowedToRefundByTransactionId } from '../../services';
import { refundAuthorization } from '../../../authorization/services';
import { IRefundAuthorization, IRefundAuthorizationForm } from '../../../authorization/types';

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export interface CreateRefundModalProps {
	transactionId: number;
	open: boolean;
	handleClose: () => void;
	reloadList: () => void;
}

export function CreateRefundAuthorizationModal({
	transactionId,
	open,
	handleClose,
	reloadList,
}: CreateRefundModalProps): JSX.Element {
	const postRefundDefaultValues = {
		authorizationId: 0,
		amountToRefund: 0,
	};
	const classes = useStyles();

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		reset,
	} = useForm({ mode: 'all', defaultValues: postRefundDefaultValues });
	const [loading, setLoading] = useState<boolean>(false);
	const [maxRefund, setMaxRefund] = useState<number>(0);
	const [authorizationsAllowedToRefund, setAuthorizationsAllowedToRefund] = useState<IAuthorizationAllowedToRefund[]>(
		[],
	);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const getAuthorizationAllowedToRefund = async () => {
		try {
			setLoading(true);
			const authorizationList = (await getAuthorizationAllowedToRefundByTransactionId(transactionId)).data;
			setAuthorizationsAllowedToRefund(authorizationList);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(
					error,
					'Ocurrió un error al recibir el listado de autorizaciones permitidas para devolución',
				),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getAuthorizationAllowedToRefund();
	}, []);

	const onSubmit = async (data: IRefundAuthorizationForm) => {
		try {
			setLoading(true);
			await refundAuthorization(data.authorizationId, {
				amountToRefund: data.amountToRefund,
			} as IRefundAuthorization);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Devolución realizada satisfactoriamente',
			});
			reloadList();
			setLoading(false);
			setMaxRefund(0);
			reset();
			getAuthorizationAllowedToRefund();
			handleClose();
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al realizar devolución'),
			});
			setLoading(false);
		}
	};

	const handleSelectAuthorization = (event: SelectChangeEvent<number>, onChange: (...event: any[]) => void) => {
		setLoading(true);
		const maybeElement = authorizationsAllowedToRefund.find((authorization) => authorization.id === event.target.value);
		setMaxRefund(maybeElement ? maybeElement.maxAllowedToRefund : 0);
		onChange(event.target.value);
		setLoading(false);
	};

	const onClose = () => {
		reset();
		setMaxRefund(0);
		getAuthorizationAllowedToRefund();
		handleClose();
	};

	return (
		<div>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="h5" color="primary">
						Realizar Devolución
					</Typography>
					<IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container gap={2}>
							<Grid item xs={12}>
								<Controller
									name="authorizationId"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Por favor seleccione el código de la autorización',
										},
									}}
									render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
										<FormControl color={invalid ? 'error' : undefined} sx={{ width: '100%', height: '100%' }}>
											<InputLabel id="authorization-select-label">Código de autorización</InputLabel>
											<Select
												labelId="authorization-select-label"
												id="e-ticket-select"
												label="Código de autorización"
												value={value}
												disabled={loading || authorizationsAllowedToRefund.length == 0}
												onChange={(event) => handleSelectAuthorization(event, onChange)}
												error={Boolean(errors.authorizationId)}
											>
												{authorizationsAllowedToRefund.length > 0 &&
													authorizationsAllowedToRefund.map((authorization) => (
														<MenuItem key={authorization.id} value={authorization.id}>
															{authorization.authorizationCode}
														</MenuItem>
													))}
											</Select>
											{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
											{!loading && authorizationsAllowedToRefund.length == 0 && (
												<FormHelperText sx={{ color: 'red' }}>
													NO existen códigos de autorización para realizar devoluciones
												</FormHelperText>
											)}
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="amountToRefund"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										min: {
											value: 1,
											message: 'El monto debe ser mayor que 0',
										},
										max: {
											value: maxRefund,
											message: 'La autorización no permite una devolución de más de ' + maxRefund,
										},
										pattern: {
											value: /^(0|[1-9]\d*)(\.\d+)?$/,
											message: 'El valor debe ser numérico',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Monto"
											type="number"
											disabled={loading || authorizationsAllowedToRefund.length == 0}
											helperText={checkShowErrorMessage(Boolean(errors.amountToRefund), errors.amountToRefund?.message)}
											error={Boolean(errors.amountToRefund)}
											{...field}
										/>
									)}
								/>
								{maxRefund > 0 && (
									<Typography variant="body2" sx={{ color: 'green' }}>
										{' '}
										El monto máximo a devolver es de {maxRefund} USD
									</Typography>
								)}
							</Grid>

							<Grid item xs={12} display={'flex'} gap={'10px'} justifyContent="flex-end">
								<Button variant="contained" disabled={loading || !isValid} color="warning" fullWidth type="submit">
									Confirmar Devolución
								</Button>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</div>
	);
}
