import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import {
	DateToFromFilterModel,
	DateType,
	FilterModel,
	FilterType,
	SelectFilterModelMultiple,
	SelectFilterModelSimple,
} from '../../types';
import {
	Checkbox,
	FormControl,
	Grid,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { isValidDate } from '../../../quotation/helpers';
import moment from 'moment';
import { TimePicker } from '@mui/lab';
import { getFilterModalTitle } from 'features/common/helpers';

interface FilterModalProps {
	filter: FilterModel;
	open: boolean;
	addFilterXPosition: string;
	addFilterYPosition: string;
	handleClose: () => void;
	handleApplyFilter: (filter: FilterModel) => void;
}

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

export const FilterModal = ({
	filter,
	open,
	addFilterXPosition,
	addFilterYPosition,
	handleClose,
	handleApplyFilter,
}: FilterModalProps): JSX.Element => {
	const [text, setText] = React.useState<string>(
		filter.type == FilterType.STRING && filter.value ? (filter.value as string) : '',
	);
	const [numeric, setNumeric] = React.useState<string | number>(
		filter.type == FilterType.NUMERIC && filter.value != undefined ? (filter.value as number) : '',
	);
	const [bool, setBool] = React.useState<boolean | undefined>(
		filter.type == FilterType.BOOLEAN && filter.value != undefined ? (filter.value as boolean) : undefined,
	);
	const [valid, setValid] = React.useState<boolean>(filter.value != null);
	const [date, setDate] = React.useState<string>(
		filter.type == FilterType.DATE && filter.value ? (filter.value as string) : '',
	);
	const [dateFrom, setDateFrom] = React.useState<string>(
		filter.type == FilterType.DATEFROMTO && filter instanceof DateToFromFilterModel && filter.value
			? (filter.value as string)
			: '',
	);
	const [dateTo, setDateTo] = React.useState<string>(
		filter.type == FilterType.DATEFROMTO && filter instanceof DateToFromFilterModel && filter.secondValue
			? (filter.secondValue as string)
			: '',
	);
	const [time, setTime] = React.useState<string>(
		filter.type == FilterType.TIME && filter.value ? (filter.value as string) : '',
	);
	const [multipleSelect, setMultipleSelect] = React.useState<string[] | number[]>(
		filter.type == FilterType.LIST && filter instanceof SelectFilterModelMultiple && Array.isArray(filter.value)
			? filter.value
			: ([] as string[]),
	);
	const [simpleSelect, setSimpleSelect] = React.useState<string | number>(
		filter.type == FilterType.LIST && filter instanceof SelectFilterModelSimple && filter.value != undefined
			? filter.value
			: '',
	);

	const handleApply = () => {
		switch (filter.type) {
			case FilterType.NUMERIC:
				handleApplyFilter({ ...filter, value: Number(numeric) });
				break;
			case FilterType.DATE:
				handleApplyFilter({ ...filter, value: date });
				break;
			case FilterType.DATEFROMTO:
				if (filter instanceof DateToFromFilterModel) {
					filter.value = dateFrom;
					filter.secondValue = dateTo;
					handleApplyFilter(filter);
				}
				break;
			case FilterType.LIST:
				if (filter instanceof SelectFilterModelMultiple) {
					filter.value = multipleSelect;
					handleApplyFilter(filter);
				} else {
					filter.value = simpleSelect;
					handleApplyFilter(filter);
				}
				break;
			case FilterType.TIME:
				handleApplyFilter({ ...filter, value: time });
				break;
			case FilterType.STRING:
				handleApplyFilter({ ...filter, value: text });
				break;
			case FilterType.BOOLEAN:
				handleApplyFilter({ ...filter, value: bool });
				break;
		}
	};
	const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValid(event.target.value != null && event.target.value != '');
		setText(event.target.value);
	};
	const handleChangeBool = (value: boolean) => {
		setValid(true);
		setBool(value);
	};
	const handleChangeNumeric = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValid(event.target.value != '');
		setNumeric(event.target.value);
	};

	const handleDateChange = (dateValue: unknown, option: DateType) => {
		const value =
			dateValue && isValidDate(dateValue as Date)
				? (dateValue as Date).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0]
				: '';

		if (value) {
			switch (option) {
				case DateType.DATE:
					setDate(value);
					break;
				case DateType.DATEFROM:
					setDateFrom(value);
					break;
				case DateType.DATETO:
					setDateTo(value);
					break;
				default:
					break;
			}
		}
		setValid(value != '');
	};

	const handleTimeChange = (time: unknown) => {
		const isValid = time != null && isValidDate(time as Date);

		if (isValid) {
			const timeStr = (time as Date).toLocaleTimeString('en-GB', {
				hour: '2-digit',
				minute: '2-digit',
			});
			setTime(timeStr);
		}
		setValid(isValid);
	};

	const handleMultipleSelectChange = (event: SelectChangeEvent<typeof multipleSelect>) => {
		const {
			target: { value },
		} = event;
		const valueToSet = typeof value === 'string' ? value.split(',') : value;
		setValid(valueToSet.length > 0);
		setMultipleSelect(valueToSet);
	};

	const handleSimpleSelectChange = (event: SelectChangeEvent<typeof simpleSelect>) => {
		setValid(event.target.value != null);
		setSimpleSelect(event.target.value);
	};

	const getDate = () => {
		if (date) {
			const momentObj = moment(date, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};
	const getDateFrom = () => {
		if (dateFrom) {
			const momentObj = moment(dateFrom, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};
	const getDateTo = () => {
		if (dateTo) {
			const momentObj = moment(dateTo, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};
	const getTime = () => {
		if (time) {
			const momentObj = moment(time, 'HH:mm');
			return momentObj.toDate();
		} else {
			return null;
		}
	};

	const dialogTitle = getFilterModalTitle(filter);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{
				sx: {
					position: 'fixed',
					left: addFilterXPosition,
					top: addFilterYPosition,
					maxWidth: '350px',
				},
			}}
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
		>
			<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
				{dialogTitle}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{(() => {
						switch (filter.type) {
							case FilterType.DATE:
								return (
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											inputFormat="dd/MM/yyyy"
											renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} variant="standard" />}
											value={getDate()}
											onChange={(date) => handleDateChange(date, DateType.DATE)}
											OpenPickerButtonProps={{
												'aria-label': 'cambiar fecha',
											}}
										/>
									</LocalizationProvider>
								);
							case FilterType.DATEFROMTO:
								return (
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											inputFormat="dd/MM/yyyy"
											renderInput={(params) => (
												<TextField {...params} sx={{ width: '100%' }} label="Desde" variant="standard" />
											)}
											value={getDateFrom()}
											onChange={(date) => handleDateChange(date, DateType.DATEFROM)}
											OpenPickerButtonProps={{
												'aria-label': 'cambiar fecha',
											}}
										/>
										<div></div>
										<DatePicker
											inputFormat="dd/MM/yyyy"
											renderInput={(params) => (
												<TextField {...params} sx={{ width: '100%' }} label="Hasta" variant="standard" />
											)}
											value={getDateTo()}
											onChange={(date) => handleDateChange(date, DateType.DATETO)}
											OpenPickerButtonProps={{
												'aria-label': 'cambiar fecha',
											}}
										/>
									</LocalizationProvider>
								);
							case FilterType.LIST:
								return (
									<div>
										<FormControl sx={{ m: '2px', width: '100%' }}>
											{filter instanceof SelectFilterModelMultiple ? (
												<Select
													multiple
													value={multipleSelect}
													onChange={handleMultipleSelectChange}
													input={<OutlinedInput label="Tag" />}
													renderValue={() =>
														[...multipleSelect].map((value) => filter.data.find((x) => x.id == value)?.name).join(',')
													}
													//MenuProps={MenuProps}
												>
													{filter.data.map((item) => {
														return (
															<MenuItem key={`${item.id}`} value={item.id}>
																<Checkbox checked={[...multipleSelect].indexOf(item.id) > -1} />
																<ListItemText primary={item.name} />
															</MenuItem>
														);
													})}
												</Select>
											) : filter instanceof SelectFilterModelSimple ? (
												<>
													<Select value={simpleSelect} onChange={handleSimpleSelectChange}>
														{filter.data.map((item) => {
															return <MenuItem key={`${item.id}`} value={item.id}>{`${item.name}`}</MenuItem>;
														})}
													</Select>
												</>
											) : (
												<></>
											)}
										</FormControl>
									</div>
								);
							case FilterType.TIME:
								return (
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											ampm={false}
											value={getTime()}
											onChange={handleTimeChange}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								);
							case FilterType.STRING:
								return <TextField fullWidth value={text || ''} onChange={handleChangeText} variant="outlined" />;
							case FilterType.NUMERIC:
								return (
									<TextField
										type="number"
										value={numeric}
										onChange={handleChangeNumeric}
										sx={{ width: '100%' }}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								);
							case FilterType.BOOLEAN:
								return (
									<Grid container justifyContent="center">
										<Grid item xs={6}>
											<Button variant={bool === true ? 'contained' : 'outlined'} onClick={() => handleChangeBool(true)}>
												Sí
											</Button>
										</Grid>
										<Grid item xs={6}>
											<Button
												variant={bool === false ? 'contained' : 'outlined'}
												onClick={() => handleChangeBool(false)}
											>
												No
											</Button>
										</Grid>
									</Grid>
								);
						}
					})()}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleClose}>
					Cancelar
				</Button>
				<Button onClick={handleApply} disabled={!valid}>
					Aplicar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
