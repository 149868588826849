import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';
import { CashTransactionList as Component } from 'features/transactions/components/CashTransactionList';
import { Layout } from '../../../features/common/components/Layout';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { isAdminOrSuperAdmin } from '../../../features/auth/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { CashTransactionBranchOfficeBalance } from '../../../features/transactions/components/CashTransactionBranchOfficeBalance';
import { PageNotAllowedForMarket } from '../../../features/common/components/PageNotAllowedForMarket';
import { isItauVN } from 'features/common/helpers';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));

export const CashTransactionPage = (): JSX.Element => {
	const { auth } = useSelector((state: RootState) => state);
	if (!isAdminOrSuperAdmin(auth.user)) return <></>;

	const [changeCashMovement, setChangeCashMovement] = useState<boolean>(false);

	const handleMakeMoveCash = () => setChangeCashMovement(!changeCashMovement);

	const classes = useStyles();

	const countryCode = auth.country;
	const ItauVN = isItauVN(countryCode);

	return (
		<Layout>
			<div>
				<Container component="main" maxWidth="xl">
					{ItauVN ? (
						<PageNotAllowedForMarket />
					) : (
						<>
							<Typography variant="h5" className={classes.headerTitle}>
								Transacciones Cash
							</Typography>
							<CashTransactionBranchOfficeBalance changeCashMovement={changeCashMovement} />
							<Component handleMakeMoveCash={handleMakeMoveCash} />
						</>
					)}
				</Container>
			</div>
		</Layout>
	);
};
