import {
	CostByTicketOperatorEnum,
	FlightIssueDefaultRuleFormValues,
	FlightIssueNotDefaultRuleFormValues,
	IFlightIssueRule,
	IFlightIssueRuleUpsert,
} from './types';
import { GDSEnum } from '../salesOrder/types';
import { newDefaultRuleId, newRuleId } from './constants';
import { formatDateTimeToShowUser } from '../common/helpers';

export const convertFromFormValuesDefaultRuleToIFlightIssueRuleUpsert = (
	id: string,
	values: FlightIssueDefaultRuleFormValues,
): IFlightIssueRuleUpsert => {
	const { ruleComment } = values;
	return {
		id,
		ruleComment,
		isDefaultRule: true,
		vcIncludes: [] as string[],
		vcExcludes: [] as string[],
		originIncludes: [] as string[],
		originExcludes: [] as string[],
		stopoverIncludes: [] as string[],
		stopoverExcludes: [] as string[],
		layoverIncludes: [] as string[],
		layoverExcludes: [] as string[],
		gds: [] as GDSEnum[],
	} as IFlightIssueRuleUpsert;
};

export const convertFromFormValuesNotDefaultRuleToIFlightIssueRuleUpsert = (
	id: string,
	values: FlightIssueNotDefaultRuleFormValues,
): IFlightIssueRuleUpsert => {
	const {
		ruleComment,
		vcIncludes,
		vcExcludes,
		originIncludes,
		originExcludes,
		stopoverIncludes,
		stopoverExcludes,
		layoverIncludes,
		layoverExcludes,
		gds,
		priority,
		mustContainOtherStopover,
		costByTicket,
		costByTicketOperator,
	} = values;
	return {
		id,
		isDefaultRule: false,
		ruleComment,
		vcIncludes,
		vcExcludes,
		originIncludes,
		originExcludes,
		stopoverIncludes,
		stopoverExcludes,
		layoverIncludes,
		layoverExcludes,
		gds,
		priority: Number(priority),
		costByTicket: costByTicket != '' ? Number(costByTicket) : undefined,
		costByTicketOperator:
			costByTicketOperator && costByTicketOperator != ''
				? (costByTicketOperator as CostByTicketOperatorEnum)
				: undefined,
		mustContainOtherStopover: stopoverIncludes.length == 1 ? mustContainOtherStopover : undefined,
	} as IFlightIssueRuleUpsert;
};

export const isToCreate = (id: string): boolean => id === newRuleId || id === newDefaultRuleId;
export const isToCreateDefaultRule = (id: string): boolean => id === newDefaultRuleId;
export const maybeIdToEdit = (id: string): string | undefined =>
	id === newRuleId || id === newDefaultRuleId ? undefined : id;

export const convertFromIFlightIssueRuleToFormValues = (
	rule: IFlightIssueRule,
): FlightIssueNotDefaultRuleFormValues | FlightIssueDefaultRuleFormValues => {
	if (rule.isDefaultRule) {
		return {
			isDefaultRule: true,
			ruleComment: rule.ruleComment,
		} as FlightIssueDefaultRuleFormValues;
	} else {
		const {
			vcIncludes,
			vcExcludes,
			originIncludes,
			originExcludes,
			stopoverIncludes,
			stopoverExcludes,
			layoverIncludes,
			layoverExcludes,
			gds,
			priority,
			mustContainOtherStopover,
			costByTicket,
			costByTicketOperator,
		} = rule;
		return {
			isDefaultRule: false,
			ruleComment: rule.ruleComment,
			vcIncludes,
			vcExcludes,
			originIncludes,
			originExcludes,
			stopoverIncludes,
			stopoverExcludes,
			layoverIncludes,
			layoverExcludes,
			gds,
			priority: (priority || '').toString(),
			mustContainOtherStopover: mustContainOtherStopover || false,
			costByTicket: costByTicket ? costByTicket.toString() : '',
			costByTicketOperator: costByTicketOperator ? costByTicketOperator : '',
		} as FlightIssueNotDefaultRuleFormValues;
	}
};

export const transformValue = (
	key: string,
	value: string | number | boolean | any[] | undefined,
	row: IFlightIssueRule,
): string | number | undefined => {
	switch (key) {
		case 'created':
			return formatDateTimeToShowUser(row.created).replace(/\.\d+$/, '');
		case 'mustContainOtherStopover':
			if (value != undefined) return value ? 'Si' : 'No';
			else return '-';
		case 'updated':
			return value ? formatDateTimeToShowUser(value as string).replace(/\.\d+$/, '') : '-';
		default:
			if (Array.isArray(value)) {
				return value && value.length > 0 ? value.join(', ') : '-';
			} else if (typeof value === 'boolean') {
				return value ? 'Si' : 'No';
			} else {
				return value ? value : '-';
			}
	}
};
