import React, { useEffect, useState } from 'react';
import { Alert, Backdrop, Box, CircularProgress, Link, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { defaultAlertState } from '../../../common/constants';
import { ShowAlertState } from '../../../common/types';
import {
	FlightIssueDefaultRuleFormValues,
	FlightIssueNotDefaultRuleFormValues,
	IFlightIssueRuleUpsert,
} from '../../types';
import { FlightIssueNotDefaultRuleForm } from '../FlightIssueNotDefaultRuleForm';
import { FlightIssueDefaultRuleForm } from '../FlightIssueDefaultRuleForm';
import {
	convertFromFormValuesDefaultRuleToIFlightIssueRuleUpsert,
	convertFromFormValuesNotDefaultRuleToIFlightIssueRuleUpsert,
	convertFromIFlightIssueRuleToFormValues,
} from '../../helpers';
import { nanoidGenerator } from '../../../common/helpers';
import { getFlightIssueRule, upsertFlightIssueRule } from '../../services';
import { extractErrorMessage } from '../../../quotation/helpers';
import { useHistory } from 'react-router-dom';
import routes from '../../../../constants/routes';

export const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: theme.palette.common.white,
	},
}));
export interface FlightIssueRuleContainerFormProps {
	isToCreate: boolean;
	isToCreateDefault: boolean;
	idToEdit?: string;
}
export const FlightIssueRuleContainerForm = ({
	isToCreate,
	isToCreateDefault,
	idToEdit,
}: FlightIssueRuleContainerFormProps): JSX.Element => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [valueToSetFormNotDefaultRule, setValueToSetFormNotDefaultRule] =
		useState<FlightIssueNotDefaultRuleFormValues | null>(null);
	const [valueToSetFormDefaultRule, setValueToSetFormDefaultRule] = useState<FlightIssueDefaultRuleFormValues | null>(
		null,
	);

	const handleSnackbarClose = () => {
		setAlert(defaultAlertState);
	};
	useEffect(() => {
		if (isToCreate) {
			if (isToCreateDefault) {
				setValueToSetFormDefaultRule({ isDefaultRule: true, ruleComment: '' });
			} else {
				setValueToSetFormNotDefaultRule({
					isDefaultRule: false,
					vcIncludes: [],
					vcExcludes: [],
					originIncludes: [],
					originExcludes: [],
					layoverIncludes: [],
					layoverExcludes: [],
					stopoverIncludes: [],
					mustContainOtherStopover: false,
					stopoverExcludes: [],
					gds: [],
					costByTicketOperator: '',
					costByTicket: '',
					priority: '1',
					ruleComment: '',
				});
			}
		} else {
			if (idToEdit) {
				loadFlightIssueRule(idToEdit);
			} else {
				setAlert({
					show: true,
					severity: 'error',
					message: 'El id de la regla es incorrecto',
				});
			}
		}
	}, [isToCreate, idToEdit]);

	const loadFlightIssueRule = async (id: string) => {
		try {
			setLoading(true);
			const rule = (await getFlightIssueRule(id)).data;
			if (rule.isDefaultRule) {
				setValueToSetFormDefaultRule(convertFromIFlightIssueRuleToFormValues(rule) as FlightIssueDefaultRuleFormValues);
			} else {
				setValueToSetFormNotDefaultRule(
					convertFromIFlightIssueRuleToFormValues(rule) as FlightIssueNotDefaultRuleFormValues,
				);
			}
		} catch (e: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: 'No se pudo cargar la regla de emisión de vuelo',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async (value: IFlightIssueRuleUpsert) => {
		try {
			setLoading(true);
			await upsertFlightIssueRule(value);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Regla guardada correctamente',
			});
			setTimeout(() => {
				history.push('/flight-issue-rules');
			}, 1500);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al guardar la regla'),
			});
		} finally {
			setLoading(false);
		}
	};

	const handleSubmitFormNotDefaultRule = async (values: FlightIssueNotDefaultRuleFormValues) => {
		const idToSend = isToCreate ? nanoidGenerator() : idToEdit || '';
		const valueToSend = convertFromFormValuesNotDefaultRuleToIFlightIssueRuleUpsert(idToSend, values);
		await handleSubmit(valueToSend);
	};

	const handleSubmitFormDefaultRule = async (values: FlightIssueDefaultRuleFormValues) => {
		const idToSend = isToCreate ? nanoidGenerator() : idToEdit || '';
		const valueToSend = convertFromFormValuesDefaultRuleToIFlightIssueRuleUpsert(idToSend, values);
		await handleSubmit(valueToSend);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box>
				<Link
					sx={{ cursor: 'pointer' }}
					variant="body1"
					color="secondary"
					onClick={() => history.push(`${routes.flightIssueRule.list}`)}
				>
					Volver al listado
				</Link>
			</Box>

			{valueToSetFormNotDefaultRule && (
				<FlightIssueNotDefaultRuleForm
					isToCreate={isToCreate}
					loading={loading}
					values={valueToSetFormNotDefaultRule}
					setLoading={setLoading}
					setAlert={setAlert}
					handleSubmitForm={handleSubmitFormNotDefaultRule}
				/>
			)}
			{valueToSetFormDefaultRule && (
				<FlightIssueDefaultRuleForm
					isToCreate={isToCreate}
					values={valueToSetFormDefaultRule}
					handleSubmitForm={handleSubmitFormDefaultRule}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={2000} onClose={handleSnackbarClose}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
