import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { Box, Button, AlertColor, Alert, Snackbar, TextField, Backdrop, CircularProgress, Grid } from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getTransactionAuthorizedReport } from 'features/transactions/services';
import { exportToExcel, ExportExcelOptions } from 'features/common/helpers';
import { IHeadCell } from 'features/common/types';

interface Props {
	open: boolean;
	handleClose: () => void;
}
interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

const headerCells: IHeadCell[] = [
	{
		field: 'transactionId',
		sortable: false,
		headerName: '#',
	},
	{
		field: 'cardStamp',
		sortable: false,
		headerName: 'Sello',
	},
	{
		field: 'cardNumber',
		sortable: false,
		headerName: 'Número de Tarjeta',
	},
	{
		field: 'cardExpiration',
		sortable: false,
		headerName: 'Vencimiento',
	},
	{
		field: 'fee',
		sortable: false,
		headerName: 'Cuotas',
	},
	{
		field: 'authorizationCode',
		sortable: false,
		headerName: 'Autorización',
	},
	{
		field: 'validatingCarrierName',
		sortable: false,
		headerName: 'Validating Carrier',
	},
	{
		field: 'amountAuthorized',
		sortable: false,
		headerName: 'Monto Autorizado',
	},
];

export function TransactionAuthorizedReportModal({ open, handleClose }: Props): JSX.Element {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [toDate, setToDate] = useState<Date | null>(null);
	const [fromDate, setFromDate] = useState<Date | null>(null);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const style = {
		position: 'absolute' as const,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,

		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const handleFromDateChange = (date: Date | null) => {
		setFromDate(date);
	};

	const handleToDateChange = (date: Date | null) => {
		setToDate(date);
	};
	const transformValue = (key: string, value: any) => value;

	const handleButtonClick = async () => {
		try {
			if (fromDate && toDate) {
				setLoading(true);
				const response = await getTransactionAuthorizedReport(fromDate, toDate);
				const transactionReport = response.data;
				const startDateString = fromDate.toLocaleDateString('en-US');
				const endDateString = toDate.toLocaleDateString('en-US');

				const title = `Listado de Transacciones autorizadas con monto autorizado igual a pendiente a cruzar (${startDateString} - ${endDateString})`;

				const optionsToExport = {
					title: title,
					headers: headerCells,
					widthColumns: [5, 10, 30, 15, 15, 20, 20, 20],
					filename: 'Listado de Transacciones autorizadas con monto autorizado igual a pendiente a cruzar',
				} as ExportExcelOptions;
				exportToExcel(transactionReport, optionsToExport, transformValue);
				setLoading(false);
			}
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
				<Box sx={{ ...style, width: 400, textAlign: 'center' }}>
					<Button sx={{ ml: 45, p: 0, width: 5 }} onClick={handleClose}>
						X
					</Button>
					<p id="child-modal-title"> Elige un rango de fechas:</p>
					<Grid container sx={{ justifyContent: 'center' }}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								inputFormat="dd/MM/yyyy"
								renderInput={(params) => (
									<TextField required {...params} variant="standard" helperText={params?.inputProps?.placeholder} />
								)}
								label="Desde"
								value={fromDate}
								onChange={handleFromDateChange}
								OpenPickerButtonProps={{
									'aria-label': 'cambiar fecha',
								}}
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								inputFormat="dd/MM/yyyy"
								renderInput={(params) => (
									<TextField required {...params} variant="standard" helperText={params?.inputProps?.placeholder} />
								)}
								label="Hasta"
								value={toDate}
								onChange={handleToDateChange}
								OpenPickerButtonProps={{
									'aria-label': 'cambiar fecha',
								}}
							/>
						</LocalizationProvider>
						<Button sx={{ mt: 5 }} variant="contained" disabled={!fromDate || !toDate} onClick={handleButtonClick}>
							Realizar solicitud
						</Button>
					</Grid>
				</Box>
			</Modal>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
