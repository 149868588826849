import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export default function IFrameSellerReport(): JSX.Element | null {
	const { auth } = useSelector((state: RootState) => state);
	if (auth.user?.dataStudioUrl) {
		return (
			<iframe
				style={{ position: 'absolute', height: '90%' }}
				allowFullScreen
				width="100%"
				frameBorder="0"
				src={auth.user?.dataStudioUrl}
			/>
		);
	}
	return null;
}
