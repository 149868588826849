import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationAirportList, QuotationFlightInfo } from '../../types';
import { dayNumber, monthNumber } from 'features/quotation/helpers';
import logo from '../../../../assets/logo.svg';

const useStyles = makeStyles(() =>
	createStyles({
		logo: {
			height: '1rem',
		},
		paddingLeft: {
			paddingLeft: '4px',
		},
	}),
);

interface FlightsTableProps {
	flightInfo: QuotationFlightInfo[];
}

export const FlightsTableDesktop = ({ flightInfo }: FlightsTableProps): JSX.Element => {
	const classes = useStyles();

	function completeAirportName(airport: QuotationAirportList) {
		return (
			<>
				{airport.name}, {airport.cityName}
			</>
		);
	}

	return (
		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="center" colSpan={1}>
							<img src={logo} alt="logo" className={classes.logo} />
						</TableCell>
						<TableCell align="center" colSpan={3}>
							Partida
						</TableCell>
						<TableCell align="center" colSpan={3}>
							Arribo
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell align="center" colSpan={1}>
							Vuelo
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Aeropuerto
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Fecha
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Hora
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Aeropuerto
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Fecha
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Hora
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{flightInfo.map((info) => (
						<TableRow key={info.flightNumber}>
							<TableCell padding="none" className={classes.paddingLeft}>
								<img src={info.airline.image} alt={info.airline.name} className={classes.logo} /> {info.flightNumber}
							</TableCell>
							<TableCell>{completeAirportName(info.departure.airport)}</TableCell>
							<TableCell align="center" padding="none">
								{dayNumber(info.departure.date)} {monthNumber(info.departure.date)}
							</TableCell>
							<TableCell align="center">{info.departure.hour.substring(0, 5)}</TableCell>
							<TableCell>{completeAirportName(info.arrival.airport)}</TableCell>
							<TableCell align="center" padding="none">
								{dayNumber(info.arrival.date)} {monthNumber(info.arrival.date)}
							</TableCell>
							<TableCell align="center">{info.arrival.hour.substring(0, 5)}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
