import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { PackagePrices } from '../../../features/stats/components/PackageStats/PackagePrices';
import { PackageData } from '../../../features/stats/components/PackageStats/PackageData';
import { PackagesSearch } from '../../../features/stats/components/PackageStats/PackagesSearch';
import { PackageCompositeDetailByTravelDate } from '../../../features/stats/components/PackageStats/PackageCompositeDetailByTravelDate';
import { INomenclator, IPackagePriceByTravelDate } from '../../../features/stats/types';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));

function PackageStats(): JSX.Element {
	const classes = useStyles();
	const [packageSelected, setPackageSelected] = useState<INomenclator | null>(null);
	const [packagePriceByTravelDate, setPackagePriceByPackagePriceByTravelDate] =
		useState<IPackagePriceByTravelDate | null>(null);
	const handleSelectPackage = (packageSelected: INomenclator | null) => setPackageSelected(packageSelected);

	return (
		<Layout>
			<div>
				<Container component="main">
					<Typography variant="h5" className={classes.headerTitle}>
						Estadística de precios de paquetes
					</Typography>
					<PackagesSearch handleSelectPackage={handleSelectPackage} />
					{packageSelected != null && <PackageData packageId={packageSelected.id} />}
					{packageSelected != null && (
						<PackagePrices
							packageId={packageSelected.id}
							handleSelectTravelDate={setPackagePriceByPackagePriceByTravelDate}
						/>
					)}
					{packageSelected != null && packagePriceByTravelDate && (
						<PackageCompositeDetailByTravelDate
							packageSelected={packageSelected}
							packagePriceByTravelDate={packagePriceByTravelDate}
						/>
					)}
				</Container>
			</div>
		</Layout>
	);
}

export default PackageStats;
