import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, Select, FormHelperText } from '@mui/material';
import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/components/InsuranceServiceForm/style';

export interface Option {
	id?: string;
	code?: string;
	name: string;
}

interface Props {
	name: string;
	rules?: Record<string, any>;
	required?: boolean;
	size?: 'small' | 'medium';
	options: Option[];
	iconTypeTrip?: Record<string, JSX.Element>;
}

export const ControlledSelect = ({ name, rules, required = true, size = 'small', options }: Props): JSX.Element => {
	const classes = useStyles();
	const { control, formState } = useFormContext();
	const error = formState.errors[name]?.message;

	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<FormControl sx={{ width: '100%' }}>
					<Select
						{...field}
						native
						required={required}
						size={size}
						className={classes.select}
						value={String(field.value)}
						onChange={(e) => {
							const selectedValue = e.target.value;
							const selectedOption = options.find(
								(option) =>
									option.id === selectedValue ||
									`${option.code}-${option.name}` === selectedValue ||
									`${option.id}-${option.name}` === selectedValue,
							);
							if (selectedOption) {
								if (name.includes('oidIssueId')) {
									const idAsNumber = parseInt(selectedOption.id || '', 10);
									field.onChange(isNaN(idAsNumber) ? 'n/a' : idAsNumber);
								} else {
									if (selectedOption.id && !selectedOption.code) {
										field.onChange(selectedOption.id);
									} else {
										field.onChange(selectedOption);
									}
								}
							} else {
								field.onChange(selectedValue);
							}
						}}
					>
						<option value="">Seleccione una opción</option>
						{options.map((option) => (
							<option key={option.id || option.code} value={option.id || option.code}>
								{option.name}
							</option>
						))}
					</Select>
					{error && <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>}
				</FormControl>
			)}
		/>
	);
};
