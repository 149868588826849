import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LocationOn from '@mui/icons-material/LocationOn';
import Rating from '@mui/material/Rating';
import Chip from '@mui/material/Chip';
import PoolIcon from '@mui/icons-material/Pool';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WifiIcon from '@mui/icons-material/Wifi';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PoweredByGoogle from 'assets/poweredByGoogle.png';
import { HotelOptionsPricePreviewMobile } from '../../../quotation/components/QuotationPreview/HotelOptionsPricePreviewMobile';
import { HotelOptionsSimple } from '../../../quotation/types';
import { GetPAXTotalsResponse, openInNewTabHotelUrl } from '../../../quotation/helpers';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			color: theme.palette.primary.main,
			fontWeight: 'normal',
		},
		media: {
			height: 0,
			paddingTop: '56.25%', // 16:9
		},
		opinions: {
			fontSize: '14px',
			fontStyle: 'italic',
			fontWeight: 400,
			letterSpacing: '0em',
		},
		facilitiesIcon: {
			marginLeft: '20px',
			fontWeight: 'bold',
			fontSize: '20px',
		},
		flex: {
			display: 'flex',
		},
		ratingMolecule: {
			display: 'flex',
			alignItems: 'center',
			paddingTop: theme.spacing(1),
		},
		evaluation: {
			color: '#71BCED',
		},
		ratingText: {
			paddingLeft: theme.spacing(2),
		},
		powered: {
			paddingLeft: theme.spacing(2),
			width: '115px',
		},
		hotelStars: {
			display: 'flex',
			paddingTop: theme.spacing(1),
		},
		webSiteContainer: {
			display: 'flex',
			justifyContent: 'center',
		},
	}),
);
interface Props {
	hotelOption: HotelOptionsSimple;
	paxTotals: GetPAXTotalsResponse;
	showUtility: boolean;
	markup: number | null;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

export const HotelCardMobile = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { hotelOption, markup, paxTotals, showUtility } = props;
	return (
		<Card>
			<CardMedia className={classes.media} image={hotelOption.details?.mainImage} title={hotelOption.details?.name} />
			<div>
				<CardContent>
					<Typography component="h5" variant="h6" color="primary" className={classes.title}>
						{hotelOption.details?.name}
					</Typography>
					<div className={classes.flex}>
						<LocationOn color="secondary" />
						<Typography variant="subtitle1" color="textSecondary">
							{hotelOption.details?.address}
						</Typography>
					</div>
					<div className={classes.hotelStars}>
						<Rating name="read-only" value={hotelOption.details?.accommodationCategory} readOnly />
					</div>
					{hotelOption.details?.rating && (
						<div className={classes.ratingMolecule}>
							<Chip label={hotelOption.details?.rating} color="secondary" />
							<div className={classes.ratingText}>
								<Typography variant="subtitle1" className={classes.evaluation}>
									{hotelOption.details?.ratingText}
								</Typography>
								<Typography variant="subtitle2" className={classes.opinions}>
									{hotelOption.details?.amountReviews} opiniones
								</Typography>
							</div>
							<img alt={hotelOption.details?.name} className={classes.powered} src={PoweredByGoogle} />
						</div>
					)}

					{hotelOption.details?.link != null && hotelOption.details?.link != '' && (
						<div className={classes.webSiteContainer}>
							<Button
								variant="contained"
								color="primary"
								size="small"
								style={{ fontSize: '12px', fontStyle: 'normal', textTransform: 'none' }}
								onClick={() => openInNewTabHotelUrl(hotelOption.details?.link || '')}
							>
								Sitio web
							</Button>
						</div>
					)}
				</CardContent>
				<div>
					{hotelOption.details?.pool && <PoolIcon color="secondary" className={classes.facilitiesIcon} />}
					{hotelOption.details?.wifi && <WifiIcon color="secondary" className={classes.facilitiesIcon} />}
					{hotelOption.details?.airConditioning && <AcUnitIcon color="secondary" className={classes.facilitiesIcon} />}
					{hotelOption.details?.parking && <LocalParkingIcon color="secondary" className={classes.facilitiesIcon} />}
					{hotelOption.details?.gym && <FitnessCenterIcon color="secondary" className={classes.facilitiesIcon} />}
				</div>
				<HotelOptionsPricePreviewMobile
					exchangeRate={props.exchangeRate}
					localCurrency={props.localCurrency}
					showLocalCurrency={props.showLocalCurrency}
					hotelOption={hotelOption}
					markup={markup}
					showUtility={showUtility}
					paxTotals={paxTotals}
				/>
			</div>
		</Card>
	);
};
