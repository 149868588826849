export interface AgencyList {
	page: number;
	size: number;
	total: number;
	elements: Agency[];
}
export interface AgencyListSnapshotToClient extends AgencyList {
	pageTotal: number;
}

export interface Agency {
	id: number;
	name: string;
	email: string;
	address: string;
	active: boolean;
}

export interface InputAgency extends Agency {
	rut: string;
	country: string;
	phone: string;
	city: string;
	twitter?: string;
	facebook?: string;
	instagram?: string;
	logo?: File;
}

export interface OutputAgency extends InputAgency {
	logoUrl: string;
}

export enum AgencyFieldsMaxLength {
	NAME = 255,
	RUT = 25,
	EMAIL = 255,
	PHONE = 50,
	COUNTRY = 100,
	CITY = 100,
	ADDRESS = 500,
	FACEBOOK = 500,
	TWITTER = 500,
	INSTAGRAM = 500,
	LOGOURL = 1000,
}
