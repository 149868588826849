import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { isoDateToFullDate } from '../../../quotation/helpers';
import warningImage from '../../../../assets/warning.png';
import { IScale, ITravelSegment } from '../../types';
import { getCabinName } from '../../helpers';
import { AirLineLogo } from '../AirlineLogo';
const useStyles = makeStyles(() => ({
	segmentHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		minHeight: '49px',
		alignItems: 'center',
	},
	logo: {
		height: '1rem',
	},
	segmentItineraryHolder: {
		display: 'flex',
		justifyContent: 'space-evenly',
		borderTop: '1px solid #7b7b7b',
		marginBottom: '18px',
	},
	segmentItinerary: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flex: 1,
		textAlign: 'center',
	},
	text: {
		fontWeight: 'normal',
		fontStyle: 'normal',
		fontStretch: 'normal',
		textAlign: 'left',
	},
	defaultText: {
		color: 'rgb(123, 123, 123)',
	},
	segmentDuration: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderLeft: '1px solid rgba(0, 0, 0, 0.35)',
	},
	segmentFooter: {
		padding: '4px 10px',
		backgroundColor: '#eaeceb',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
}));
interface Props {
	segment: ITravelSegment;
	scales: IScale[];
	parentIndex: number;
	showWaitBar: boolean;
}

export const FlightItineraryDetailsSegmentsBlock = ({
	segment,
	scales,
	parentIndex,
	showWaitBar,
}: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<div>
			<div className={classes.segmentHeader}>
				<div>
					<Typography variant="subtitle1" component="span" fontSize={16} fontStyle={'normal'} fontWeight={'600'}>
						{' '}
						Tramo {parentIndex + 1} - Vuelo {segment.flightInformation.airline.code}
						{segment.flightInformation.flightNumber}
					</Typography>
				</div>
				<div>
					<div style={{ backgroundColor: 'white', padding: '5px' }}>
						<AirLineLogo
							code={segment.flightInformation.airline.code}
							showName={true}
							name={segment.flightInformation.airline.name}
						/>
					</div>
				</div>
			</div>
			<div className={classes.segmentItineraryHolder}>
				<div className={classes.segmentItinerary}>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'14px'}
							component="span"
						>
							{isoDateToFullDate(segment.departureData.flightDate)}
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							style={{ fontWeight: 'bold' }}
							fontSize={'24px'}
							component="span"
						>
							{' '}
							{segment.departureData.flightTime}
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'24px'}
							component="span"
						>
							{' '}
							{segment.departureData.airport.code}
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'14px'}
							style={{ fontWeight: 'bold' }}
							component="span"
						>
							{segment.departureData.airport.city.name}
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'14px'}
							style={{ fontWeight: 'bold' }}
							component="span"
						>
							{segment.departureData.airport.name}
						</Typography>
					</div>
				</div>
				<div className={classes.segmentItinerary}>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'14px'}
							component="span"
						>
							{isoDateToFullDate(segment.arrivalData.flightDate)}
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							style={{ fontWeight: 'bold' }}
							fontSize={'24px'}
							component="span"
						>
							{' '}
							{segment.arrivalData.flightTime}
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'24px'}
							component="span"
						>
							{' '}
							{segment.arrivalData.airport.code}
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'14px'}
							style={{ fontWeight: 'bold' }}
							component="span"
						>
							{segment.arrivalData.airport.city.name}
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'14px'}
							style={{ fontWeight: 'bold' }}
							component="span"
						>
							{segment.arrivalData.airport.name}
						</Typography>
					</div>
				</div>

				<div className={classes.segmentDuration}>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'14px'}
							component="span"
						>
							Duraci&oacute;n
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							style={{ fontWeight: 'bold' }}
							fontSize={'24px'}
							component="span"
						>
							{' '}
							{segment.duration.totalHours}h {segment.duration.totalMinutes}m
						</Typography>
					</div>
					<div>
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							fontSize={'14px'}
							style={{ fontWeight: 'bold' }}
							component="span"
						>
							{' '}
							Cabina: {getCabinName(segment.cabin.code)}
						</Typography>
					</div>
				</div>
			</div>
			{showWaitBar && (
				<div className={classes.segmentFooter}>
					<Typography
						variant="subtitle1"
						className={`${classes.text} ${classes.defaultText}`}
						fontSize={'14px'}
						style={{ fontWeight: 'bold' }}
						component="span"
					>
						Espera de {scales[parentIndex].duration.totalHours}h {scales[parentIndex].duration.totalMinutes}min en{' '}
						{scales[parentIndex].airport.city.name}
						{scales[parentIndex].changeInfo.changeOfPlane &&
							!scales[parentIndex].changeInfo.changeOfAirport &&
							'(Cambio de avión)'}
					</Typography>

					{scales[parentIndex].changeInfo.changeOfAirport && (
						<Typography
							variant="subtitle1"
							className={`${classes.text} ${classes.defaultText}`}
							style={{ fontWeight: 'bold', color: '#71bced' }}
							fontSize={'14px'}
							component="span"
						>
							(Cambio de aeropuerto)
						</Typography>
					)}

					{scales[parentIndex].changeInfo.changeOfAirport && <img src={warningImage} alt="Cambio de aeropuerto" />}
				</div>
			)}
		</div>
	);
};
