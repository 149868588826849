import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { Box, Button, AlertColor, Alert, Snackbar, Backdrop, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { extractErrorMessage } from 'features/quotation/helpers';
import { transactionAuthorization } from 'features/transactions/services';

interface Props {
	open: boolean;
	handleClose: () => void;
	authorizationId: number | undefined;
}
interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

export function AuthorizationAmadeusFPCashCommandModal({ open, handleClose, authorizationId }: Props): JSX.Element {
	const classes = useStyles();
	const [selectedPassengers, setSelectedPassengers] = useState<number[]>([]);

	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [command, setCommand] = useState('');
	const [loading, setLoading] = useState<boolean>(false);

	const style = {
		position: 'absolute' as const,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,

		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const handleCopyCommand = async () => {
		try {
			await navigator.clipboard.writeText(command);
		} catch (error: any) {
			setAlert({ show: true, severity: 'error', message: 'No se pudo copiar el commando' });
		}
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, number: number) => {
		if (event.target.checked) {
			setSelectedPassengers([...selectedPassengers, number]);
		} else {
			setSelectedPassengers(selectedPassengers.filter((n) => n !== number));
		}
	};

	const checkboxItems = Array.from({ length: 9 }, (_, index) => {
		const passengerNumber = index + 1;
		return (
			<label key={passengerNumber} style={{ margin: 4 }}>
				<input
					type="checkbox"
					value={passengerNumber}
					checked={selectedPassengers.includes(passengerNumber)}
					onChange={(event) => handleCheckboxChange(event, passengerNumber)}
				/>
				{passengerNumber}
			</label>
		);
	});

	const generateCommand = async () => {
		try {
			if (authorizationId) {
				setLoading(true);
				const response = await transactionAuthorization(authorizationId, selectedPassengers);

				const generateCommand = response.data.command;
				setCommand(generateCommand);
				setLoading(false);
			}
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir los detalles del comando'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		setCommand('');
	}, [selectedPassengers]);

	const isButtonDisabled = selectedPassengers.length === 0;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
				<Box sx={{ ...style, width: 400, textAlign: 'center' }}>
					<Button sx={{ ml: 45, p: 0, width: 5 }} onClick={handleClose}>
						X
					</Button>
					<p id="child-modal-title"> A qué pasajeros vamos asignar este cobro ?</p>
					{checkboxItems}
					<Button sx={{ mt: 5 }} variant="contained" onClick={generateCommand} disabled={isButtonDisabled}>
						Generar comando
					</Button>
					<input type="text" value={command} style={{ width: 300, marginTop: 10, height: '40px', marginBottom: 20 }} />
					<Button onClick={handleCopyCommand} sx={{ ml: 1 }}>
						<ContentCopyIcon />
					</Button>
				</Box>
			</Modal>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
