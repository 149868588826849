import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, useTheme } from '@mui/material';
import { Apartment, Circle, EmojiFlags, Wifi } from '@mui/icons-material';

interface Props {
	description: {
		mainText: string;
		internet?: {
			components: string[];
		};
		services?: {
			components: string[];
		};
		entertainment?: {
			components: string[];
		};
	};
}

export const HotelFareDescriptionPanel = (props: Props): JSX.Element => {
	const [showMore, setShowMore] = useState<boolean>(false);
	const [description, setDescription] = useState<string>(props.description.mainText);

	const theme = useTheme();

	useEffect(() => {
		!showMore && props.description.mainText.length > 400
			? setDescription(props.description.mainText.slice(0, 400))
			: setDescription(props.description.mainText);
	}, [showMore]);

	return (
		<div>
			<Grid container flexDirection="column" spacing={1} p={1}>
				<Grid item display="flex" xs={12} gap={1}>
					<Typography component="span" variant="body2">
						{description + ' '}
						<Typography
							component="span"
							variant="body2"
							color={theme.palette.info.main}
							onClick={() => setShowMore(!showMore)}
							sx={{ ':hover': { textDecoration: 'underline' } }}
						>
							{!showMore ? '...Ver Más' : 'Ver Menos'}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<hr />
				</Grid>
				<Grid item xs={12}>
					<Grid
						container
						display="grid"
						overflow-x="scroll"
						gap="10px"
						gridAutoFlow="column"
						gridTemplateRows="repeat(7, 30px)"
					>
						{props.description.internet && props.description.internet.components.length > 0 && (
							<>
								<Grid item display="flex">
									<Wifi color="secondary" />
									<Typography component="h6" variant="h6">
										Accesso a Internet
									</Typography>
								</Grid>
								{props.description.internet.components.map((component, index) => (
									<Grid item key={`internet-component-${index}`} display="flex" alignItems="center" gap={1}>
										<Circle fontSize="inherit" color="secondary" />
										<Typography>{component}</Typography>
									</Grid>
								))}
							</>
						)}
						{props.description.entertainment && props.description.entertainment.components.length > 0 && (
							<>
								<Grid item display="flex">
									<EmojiFlags color="secondary" />
									<Typography component="h6" variant="h6">
										Entretenimiento
									</Typography>
								</Grid>
								{props.description.entertainment.components.map((component, index) => (
									<Grid item key={`entertainment-component-${index}`} display="flex" alignItems="center" gap={1}>
										<Circle fontSize="inherit" color="secondary" />
										<Typography>{component}</Typography>
									</Grid>
								))}
							</>
						)}
						{props.description.services && props.description.services.components.length > 0 && (
							<>
								<Grid item display="flex">
									<Apartment color="secondary" />
									<Typography component="h6" variant="h6">
										Instalaciones y Servicios
									</Typography>
								</Grid>
								{props.description.services.components.map((component, index) => (
									<Grid item key={`services-component-${index}`} display="flex" alignItems="center" gap={1}>
										<Circle fontSize="inherit" color="secondary" />
										<Typography>{component}</Typography>
									</Grid>
								))}
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
