import React from 'react';
import { useParams } from 'react-router-dom';
import { WompiCheckConfirmation as Component } from 'features/payment/components/WompiCheckConfirmation';
import { Container } from '@mui/material';
import { Header } from '../../../features/quotation/components/Header';

export const WompiCheckConfirmation = (): JSX.Element => {
	const { reference } = useParams<{ reference: string }>();
	return (
		<>
			<Header />
			<Container component="main">
				<Component reference={reference} />
			</Container>
		</>
	);
};
