import React, { useEffect, useState } from 'react';
import { Checkbox, Grid, Paper, Typography, FormControlLabel } from '@mui/material';
import { CancelationTypeInterface } from '../../types';

export interface CancelationTypeFilterProps {
	data: CancelationTypeInterface[];
	onChange: (value: number[]) => void;
}

export const CancelationTypeFilter = ({ data, onChange }: CancelationTypeFilterProps): JSX.Element => {
	const [selected, setSelected] = useState<number[]>([]);

	useEffect(() => onChange(selected), [selected]);

	const handleSelected = (value: number) => {
		if (selected.includes(value)) {
			const newSelected = [...selected];
			const index = selected.indexOf(value);
			index > -1 && newSelected.splice(index, index);
			setSelected([...newSelected]);
		} else {
			setSelected([...selected, value]);
		}
	};

	return (
		<Paper>
			<Grid container sx={{ display: 'grid', marginLeft: 2, marginTop: 4 }}>
				<Grid item xs={12}>
					<Typography variant="h6" fontWeight="bold">
						Gastos de cancelacion
					</Typography>
				</Grid>
				{data.map((item) => (
					<FormControlLabel
						key={item.id}
						control={
							<Checkbox
								checked={selected.includes(item.id)}
								key={item.id}
								onClick={() => handleSelected(item.id)}
								color="secondary"
							/>
						}
						label={item.name}
					/>
				))}
			</Grid>
		</Paper>
	);
};
