import React from 'react';
import { Container, Grid, Typography } from '@mui/material';

import successIcon from '../../../../assets/thankyou-icon.png';

export const GatewayCancelTransaction = (): JSX.Element => {
	return (
		<Container sx={{ marginY: 2 }} component="main" maxWidth="md">
			<Grid container spacing={2}>
				<Grid item textAlign="center" xs={12}>
					<img src={successIcon} width="25%" height="auto" alt="cancel-transaction-icon" />
				</Grid>
				<Grid item xs={12} marginLeft={{ xs: 2, sm: 10 }}>
					<Typography>¡Su transacción fue cancelada!</Typography>
				</Grid>
			</Grid>
		</Container>
	);
};
