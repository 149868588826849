import { ApiHotelProxyService } from 'services';
import { AxiosResponse } from 'axios';
import { HotelDetail } from '../types';

const endpoints = {
	detail: 'hotels/detailforquotation/[id]/providers/[provider]',
};

export const getHotelDetail = (id: number, provider: string): Promise<AxiosResponse<HotelDetail>> => {
	return ApiHotelProxyService.get(endpoints.detail.replace('[id]', String(id)).replace('[provider]', provider));
};
