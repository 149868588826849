import React, { useEffect, useState } from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Snackbar,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { extractErrorMessage } from 'features/quotation/helpers';
import { getCurrentBalanceByCountries, getCurrentBalanceInBankByCountries } from 'features/transactions/services';
import {
	IContainerCurrentBalanceInBankByCountry,
	ICurrentBalanceByCountryContainer,
	ICurrentBalanceInBankDetail,
} from 'features/transactions/types';

import { BankAccountCountry, BankAccountCountryCode } from '../../../payment/types';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	graph: {
		maxWidth: 220,
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export interface WireTransferTransactionDashBoardProps {
	refreshedInformation: boolean;
}

export const WireTransferTransactionDashBoard = ({
	refreshedInformation,
}: WireTransferTransactionDashBoardProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [currentBalanceByCountryContainer, setCurrentBalanceByCountryContainer] =
		useState<ICurrentBalanceByCountryContainer | null>(null);
	const [currentBalanceInBankByCountryContainer, setCurrentBalanceInBankByCountryContainer] =
		useState<IContainerCurrentBalanceInBankByCountry | null>(null);
	const [dataToGraph, setDataToGraph] = useState<any | null>(null);
	const [countriesToShow, setCountriesToShow] = useState<BankAccountCountry[]>([]);
	const [currenciesToShow, setCurrenciesToShow] = useState<string[]>([]);
	const [currencySelected, setCurrencySelected] = useState<string>('');
	const [countrySelected, setCountrySelected] = useState<BankAccountCountry | string>('');

	ChartJS.register(ArcElement, Tooltip, Legend);

	const getCurrentBalanceByCountryAtLoad = async () => {
		try {
			setLoading(true);
			const balanceByCountryContainer = (await getCurrentBalanceByCountries()).data;
			setCurrentBalanceByCountryContainer(balanceByCountryContainer);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar el balance por paises'),
			});
			setLoading(false);
		}
	};

	const getCurrentBalanceInBankByCountryAtLoad = async () => {
		try {
			setLoading(true);
			const banksByCountry = (await getCurrentBalanceInBankByCountries()).data;
			setCurrentBalanceInBankByCountryContainer(banksByCountry);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar el balance por bancos'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getCurrentBalanceByCountryAtLoad();
	}, [refreshedInformation]);

	useEffect(() => {
		getCurrentBalanceInBankByCountryAtLoad();
	}, [refreshedInformation]);

	useEffect(() => {
		if (currentBalanceInBankByCountryContainer && currentBalanceInBankByCountryContainer.details.length > 0) {
			setCountriesToShow(currentBalanceInBankByCountryContainer.countries);
			const country = currentBalanceInBankByCountryContainer.details[0].country;
			const currencies = getAllowedCurrenciesByCountry(country);
			setCountrySelected(country);
			if (currencies.length > 0) {
				setCurrenciesToShow(currencies);
				setCurrencySelected(currencies[0]);
				updateDataToGraphic(country, currencies[0]);
			}
		}
	}, [currentBalanceInBankByCountryContainer]);

	const getFlagByCountry = (country: BankAccountCountry): string => {
		const initialUrl = window.location.origin + '/flags/';
		const code = BankAccountCountryCode[country].toLowerCase();
		return initialUrl + code + '.svg';
	};

	const getAllowedCurrenciesByCountry = (country: BankAccountCountry): string[] => {
		const detail = currentBalanceInBankByCountryContainer?.details.find((x) => x.country == country);
		return detail?.currenciesAllowed || [];
	};

	const handleSelectedCountry = (event: SelectChangeEvent<unknown>) => {
		const {
			target: { value },
		} = event;

		const currenciesDetail = getAllowedCurrenciesByCountry(value as BankAccountCountry);

		setCountrySelected(value as BankAccountCountry);
		setCurrencySelected('');
		setCurrenciesToShow(currenciesDetail);
		setDataToGraph(null);
	};

	const handleSelectedCurrency = (value: string) => {
		setCurrencySelected(value);
		updateDataToGraphic(countrySelected, value);
	};

	const updateDataToGraphic = (country: BankAccountCountry | string, currency: string) => {
		const detail = currentBalanceInBankByCountryContainer?.details.find((x) => x.country === country);
		const currenciesDetail: ICurrentBalanceInBankDetail[] = detail?.details || [];
		const dataToSetGraphic = currenciesDetail.filter((x) => x.currency === currency);
		if (dataToSetGraphic.length > 0) {
			setDataToGraph({
				labels: dataToSetGraphic.map((x) => x.bank),
				datasets: [
					{
						label: 'Balance en bancos',
						data: dataToSetGraphic.map((x) => x.amount),
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
							'rgba(255, 159, 64, 0.2)',
						].slice(0, dataToSetGraphic.length),
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
							'rgba(255, 159, 64, 1)',
						].slice(0, dataToSetGraphic.length),
						borderWidth: 1,
					},
				],
			});
		} else {
			setDataToGraph(null);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container spacing={2} paddingY={5} paddingLeft={5} paddingRight={5}>
				{currentBalanceByCountryContainer && currentBalanceByCountryContainer?.details.length > 0 && (
					<>
						{' '}
						<Grid item xs={12} display="flex" justifyContent={'center'}>
							<Typography variant="h6" fontWeight={'bold'}>
								Saldo actual total en USD {currentBalanceByCountryContainer.totalInUSD}{' '}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Grid container spacing={2} paddingY={5} paddingLeft={5} paddingRight={5}>
								<Grid item xs={12} display="flex" justifyContent={'center'}>
									<Typography variant="h6">Saldo actual por país</Typography>
								</Grid>
								{currentBalanceByCountryContainer.details.map((countryBalance) => {
									return (
										<>
											<Grid key={countryBalance.country} item xs={3}>
												{' '}
												<img
													alt={countryBalance.country}
													title={countryBalance.country}
													height={30}
													width={30}
													src={getFlagByCountry(countryBalance.country)}
												></img>{' '}
												<Typography variant="body2" fontSize={12} paddingTop={'20px'}>
													Arbitraje
												</Typography>
											</Grid>
											{countryBalance.country == BankAccountCountry.US &&
												Array(2 - countryBalance.details.length)
													.fill(0)
													.map((_, index) => {
														return (
															<Grid key={index} item xs={3}>
																{' '}
															</Grid>
														);
													})}
											{countryBalance.details.map((countryDetail) => (
												<Grid key={countryDetail.currency} item xs={3}>
													{' '}
													<Typography variant="h6">{countryDetail.currency} </Typography>
													<Typography variant="body1">{countryDetail.amount}</Typography>
													<Typography variant="body2" fontSize={12}>
														{countryDetail.arbitration}
													</Typography>
												</Grid>
											))}
											{countryBalance.country != BankAccountCountry.US &&
												Array(2 - countryBalance.details.length)
													.fill(0)
													.map((_, index) => {
														return (
															<Grid key={index} item xs={3}>
																{' '}
															</Grid>
														);
													})}
											<Grid key={`${countryBalance.country}-summary-usd`} item xs={3}>
												{' '}
												<Typography variant="h6">Total USD</Typography>
												<Typography fontWeight={'bold'} variant="body1">
													{countryBalance.totalUSD}
												</Typography>
											</Grid>
										</>
									);
								})}
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid container spacing={2} paddingY={5} paddingLeft={5} paddingRight={5} textAlign={'center'}>
								<Grid item xs={12} display="flex" justifyContent={'center'}>
									<Typography variant="h6">Saldo actual por Institución/moneda </Typography>
								</Grid>
								<Grid item xs={12} display="flex" justifyContent={'center'}>
									<FormControl sx={{ width: '50%', height: '80%' }}>
										<InputLabel id="country-label">País</InputLabel>
										<Select
											labelId="country-label"
											label={'País'}
											required
											disabled={loading}
											value={countrySelected}
											sx={{ width: '100%' }}
											onChange={(event) => handleSelectedCountry(event)}
										>
											{countriesToShow.map((bankAccountCountry) => {
												return (
													<MenuItem key={bankAccountCountry} value={bankAccountCountry}>
														{bankAccountCountry}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} display="flex" justifyContent={'center'}>
									<FormControl sx={{ width: '50%', height: '80%' }}>
										<InputLabel id="currency-label">Moneda</InputLabel>
										<Select
											labelId="currency-label"
											label={'Moneda'}
											disabled={loading}
											value={currencySelected}
											required
											sx={{ width: '100%' }}
											onChange={(event) => handleSelectedCurrency(event.target.value)}
										>
											{currenciesToShow.map((currency) => {
												return (
													<MenuItem key={currency} value={currency}>
														{currency}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
								{dataToGraph && (
									<Grid item xs={12} display="flex" justifyContent={'center'}>
										<div className={classes.graph}>
											<Doughnut data={dataToGraph} />
										</div>
									</Grid>
								)}
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
