import React, { useEffect, useState } from 'react';

import {
	Box,
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { IFlightExplorerFilterStopInfo, IStopFilter } from '../../types';
import Typography from '@mui/material/Typography';
import { BpCheckedIcon, BpIcon } from '../../constants';

interface Props {
	data: IFlightExplorerFilterStopInfo;
	handleChangeFilter: (data: IStopFilter | null) => void;
}

const useStyles = makeStyles(() => ({
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		color: 'white',
	},
	filterContent: {
		backgroundColor: 'rgb(241, 242, 241)',
		borderBottomRightRadius: '14px',
		borderBottomLeftRadius: '14px',
		padding: '0px',
		transition: 'max-height 0.26s ease 0s, padding 0.26s ease 0s',
		overflowY: 'auto',
		maxHeight: '450px',
		paddingLeft: '15px',
	},
	totalInfo: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 'auto',
		display: 'inline-flex',
		fontSize: '14px',
		fontWeight: '500',
		height: '15px',
		marginTop: '10px',
		textAlign: 'center',
		borderRadius: '14px',
		padding: '4px 8px',
		boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 3px 0px',
		backgroundColor: 'rgb(255, 103, 0)',
		color: 'rgb(255, 255, 255)',
	},
}));

export const FlightExplorerStopFilter = ({ data, handleChangeFilter }: Props): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const [stopOption, setStopOption] = useState<string>('allOptions');
	const [withoutAirportChangeChecked, setWithoutAirportChangeChecked] = React.useState(false);

	const handleChangeAirportChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWithoutAirportChangeChecked(event.target.checked);
	};

	useEffect(() => {
		const baseFilter = withoutAirportChangeChecked ? { withoutAirportChange: true } : {};
		switch (stopOption) {
			case 'allOptions':
				handleChangeFilter(withoutAirportChangeChecked ? baseFilter : null);
				break;
			case 'withoutStop':
				handleChangeFilter(Object.assign(baseFilter, { withoutStop: true }));
				break;
			case 'withOneStop':
				handleChangeFilter(Object.assign(baseFilter, { withOneStop: true }));
				break;
			case 'withTwoStops':
				handleChangeFilter(Object.assign(baseFilter, { withTwoStops: true }));
				break;
		}
	}, [stopOption, withoutAirportChangeChecked]);

	return (
		<Container sx={{ 'margin-bottom': '15px' }}>
			<Box sx={{ 'padding-left': '15px', backgroundColor: '#8167A3', height: '25px' }}>
				<FormLabel className={classes.title}>Escalas</FormLabel>
			</Box>
			<Box className={classes.filterContent}>
				<FormControl>
					<RadioGroup name="radio-buttons-group" value={stopOption}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<FormControlLabel
								value={'allOptions'}
								control={
									<Radio
										sx={{ color: 'red' }}
										checkedIcon={<BpCheckedIcon />}
										icon={<BpIcon />}
										onClick={() => setStopOption('allOptions')}
									/>
								}
								label={
									<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Todas las opciones </span>
								}
							/>
							<Typography variant="subtitle1" component="span" className={`${classes.totalInfo}`}>
								{' '}
								{data.allOptions}
							</Typography>
						</div>

						{data.withoutStop > 0 && (
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<FormControlLabel
									value={'withoutStop'}
									control={
										<Radio
											checkedIcon={<BpCheckedIcon />}
											icon={<BpIcon />}
											onClick={() => setStopOption('withoutStop')}
										/>
									}
									label={
										<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Sin escalas (directo)</span>
									}
								/>
								<Typography variant="subtitle1" component="span" className={`${classes.totalInfo}`}>
									{' '}
									{data.withoutStop}
								</Typography>
							</div>
						)}

						{data.withOneStop > 0 && (
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<FormControlLabel
									value={'withOneStop'}
									control={
										<Radio
											checkedIcon={<BpCheckedIcon />}
											icon={<BpIcon />}
											onClick={() => setStopOption('withOneStop')}
										/>
									}
									label={<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Con 1 escala</span>}
								/>
								<Typography variant="subtitle1" component="span" className={`${classes.totalInfo}`}>
									{' '}
									{data.withOneStop}
								</Typography>
							</div>
						)}

						{data.withTwoStops > 0 && (
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<FormControlLabel
									value={'withTwoStops'}
									control={
										<Radio
											checkedIcon={<BpCheckedIcon />}
											icon={<BpIcon />}
											onClick={() => setStopOption('withTwoStops')}
										/>
									}
									label={<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Con 2 escala</span>}
								/>
								<Typography variant="subtitle1" component="span" className={`${classes.totalInfo}`}>
									{' '}
									{data.withTwoStops}
								</Typography>
							</div>
						)}
					</RadioGroup>
				</FormControl>
				<FormGroup style={{ color: theme.palette.primary.main, fontSize: '13px' }}>
					<FormControlLabel
						control={
							<Checkbox
								checked={withoutAirportChangeChecked}
								onChange={handleChangeAirportChangeCheckBox}
								color={'secondary'}
							/>
						}
						label={
							<Typography variant="subtitle1" component="span" fontSize={13} color={theme.palette.primary.main}>
								Sin cambio de aeropuerto{' '}
							</Typography>
						}
					/>
				</FormGroup>
			</Box>
		</Container>
	);
};
