import { AxiosResponse } from 'axios';
import { ApiService, axiosDestinations } from '../../services';
import {
	IDestinationImageResultContainer,
	IFlightExplorerByDestinationFilteredAndOrderedResponse,
	IFlightExplorerByDestinationResponse,
	IFlightExplorerDepartureDestination,
	IFlightExplorerDestination,
	IFlightExplorerDestinationFilter,
	IFlightExplorerReq,
} from './types';
import { getDestinationQuery } from './constants';

const endpoints = {
	flightExplorer: 'api/quotations/automaticroutes/flightexplorer',
	flightExplorerByDestination: 'api/quotations/automaticroutes/flightexplorer/bydestination/{destinationCode}',
	flightExplorerByDestinationFiltered: 'api/quotations/automaticroutes/flightexplorer/destinationfiltered',
	flightDepartures: 'api/quotations/automaticroutes/flightexplorer/departures',
};
export const getFlightExplorerData = (
	payload: IFlightExplorerReq,
): Promise<AxiosResponse<IFlightExplorerDestination[]>> => ApiService.post(endpoints.flightExplorer, payload);

export const getFlightExplorerDepartureDestinations = (): Promise<
	AxiosResponse<IFlightExplorerDepartureDestination[]>
> => ApiService.get(endpoints.flightDepartures);

export const getDestinationImage = (
	destinationSlugName: string,
): Promise<AxiosResponse<IDestinationImageResultContainer>> =>
	axiosDestinations.post('api', getDestinationQuery(destinationSlugName));

export const getFlightExplorerDataByDestination = (
	destinationCode: string,
	payload: IFlightExplorerReq,
): Promise<AxiosResponse<IFlightExplorerByDestinationResponse>> =>
	ApiService.post(endpoints.flightExplorerByDestination.replace('{destinationCode}', destinationCode), payload);

export const getFlightExplorerDataByDestinationFilteredAndOrdered = (
	filter: IFlightExplorerDestinationFilter,
): Promise<AxiosResponse<IFlightExplorerByDestinationFilteredAndOrderedResponse>> =>
	ApiService.post(endpoints.flightExplorerByDestinationFiltered, filter);
