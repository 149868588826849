import { ICountryAgencyConfig, CountryCodeEnum } from '../common/types';
import Uruguay from '../../assets/country-flags/uruguay_flag.svg';
import Paraguay from '../../assets/country-flags/paraguay_flag.svg';
import Bolivia from '../../assets/country-flags/bolivia_flag.svg';
import Colombia from '../../assets/country-flags/colombia_flag.svg';
import ItauPy from '../../assets/country-flags/itau_flag.svg';
//import Usa from '../../assets/country-flags/usa_flag.svg';

export const defaultCountryWithAgencyConfig = {
	id: CountryCodeEnum.URUGUAY,
	countryName: 'Uruguay',
	agencyId: 1,
	apiKey: '60f8b35a-2e1e-48d3-939f-41dece9aa5d4',
	agencyIcon: Uruguay,
};

export const countriesWithAgencyConfig: ICountryAgencyConfig[] = [
	defaultCountryWithAgencyConfig,
	{
		id: CountryCodeEnum.PARAGUAY,
		countryName: 'Paraguay',
		agencyId: 3,
		apiKey: 'ebfc5bcd-c798-4a53-a45a-5118f8a1e377',
		agencyIcon: Paraguay,
	},
	{
		id: CountryCodeEnum.COLOMBIA,
		countryName: 'Colombia',
		agencyId: 2,
		apiKey: 'fc3394e4-1c2d-11ed-861d-0242ac120002',
		agencyIcon: Colombia,
	},
	{
		id: CountryCodeEnum.BOLIVIA,
		countryName: 'Bolivia',
		agencyId: 4,
		apiKey: '8f41e23a-b78f-4222-83f6-803eb132ad33',
		agencyIcon: Bolivia,
	},
	{
		id: CountryCodeEnum.TN_ITAU_PY,
		countryName: 'Itaú Paraguay',
		agencyId: 5,
		apiKey: '6ff34ba1-d58b-4dfe-af2e-cc234c832ad0',
		agencyIcon: ItauPy,
	},
];
