import { Grid, Typography } from '@mui/material';
import React from 'react';
import { QuotationItemRoomDistribution } from '../../types';

export interface QuotationListItemRoomDistributionProps {
	roomDistributions: QuotationItemRoomDistribution[];
	quotationId: number;
}

export const QuotationListItemRoomDistribution = ({
	roomDistributions,
	quotationId,
}: QuotationListItemRoomDistributionProps): JSX.Element => (
	<Grid container gap={2} m={2} mb={0}>
		{roomDistributions.length > 0 &&
			roomDistributions.map((room, index) => (
				<Grid
					item
					key={`quotation-${quotationId}-room-${index}`}
					sx={{ border: '1px solid gray', p: 2, width: 'auto' }}
				>
					<Typography fontWeight="fontWeightBold" variant="body2" component="span" color="primary">
						Habitación {index + 1}
					</Typography>
					<Typography variant="body2" component="p">
						Adultos x {room.totalAdults}{' '}
					</Typography>
					<Typography variant="body2" component="span">
						Niños x {room.totalChildren}{' '}
					</Typography>
					{room.childrenAges.length > 0 && (
						<Typography variant="body2" component="span">
							|| Edades: ({room.childrenAges.map((age, childIndex) => `${childIndex > 0 ? ',' : ''}${age}`)}){' '}
						</Typography>
					)}
					<br />
					<Typography variant="body2" component="span">
						Infantes x {room.totalInfants}{' '}
					</Typography>
					{room.infantsAges.length > 0 && (
						<Typography variant="body2" component="span">
							|| Edades: ({room.infantsAges.map((age, infantIndex) => `${infantIndex > 0 ? ',' : ''}${age}`)}){' '}
						</Typography>
					)}
				</Grid>
			))}
	</Grid>
);
