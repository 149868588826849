// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { styled } from '@mui/material/styles';
import { IQueryGraphQL } from './types';

export const getDestinationQuery = (cityKeyword: string): IQueryGraphQL => ({
	query: ` {
  allCities( where: { sluggedName: "${cityKeyword}" }) {
   background{
      publicUrl
    }
  }
}
`,
});

export const monthsAbbrName = {
	0: 'Ene',
	1: 'Feb',
	2: 'Mar',
	3: 'Abr',
	4: 'May',
	5: 'Jun',
	6: 'Jul',
	7: 'Ago',
	8: 'Sep',
	9: 'Oct',
	10: 'Nov',
	11: 'Dec',
};

export const weekDaysNamesAbbr = {
	0: 'Dom',
	1: 'Lun',
	2: 'Mar',
	3: 'Mie',
	4: 'Jue',
	5: 'Vie',
	6: 'Sab',
};

export const classItems = [
	{ id: 'Y', name: 'Económica', toSelect: true },
	{ id: 'M', name: 'Económica', toSelect: false },
	{ id: 'W', name: 'Económica Premium', toSelect: true },
	{ id: 'C', name: 'Ejecutiva', toSelect: true },
	{ id: 'F', name: 'Primera Clase', toSelect: true },
	{ id: 'MX', name: 'Combinada', toSelect: false },
];

export const excludeAirlineBaggage = ['AV', 'LA', 'JA'];

export const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 20,
	height: 20,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.main,
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 20,
		height: 20,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.secondary.main,
	},
}));

export const weekDaysNamesAbbrForFilters = [
	{ id: 1, name: 'Lunes' },
	{ id: 2, name: 'Martes' },
	{ id: 3, name: 'Miércoles' },
	{ id: 4, name: 'Jueves' },
	{ id: 5, name: 'Viernes' },
	{ id: 6, name: 'Sábado' },
	{ id: 7, name: 'Domingo' },
];
