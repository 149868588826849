import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import { IClientTransactionForm, OrderDataToClient, SimplePaymentOrderEnum } from '../../types';
import { checkShowErrorMessage } from '../../../common/helpers';
import Cards, { Focused } from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import InputMask from 'react-input-mask';
import { extractErrorMessage } from 'features/quotation/helpers';
import { createSimpleOrderTransaction } from '../../services';
import makeStyles from '@mui/styles/makeStyles';
import pciLogo from '../../../../assets/pci-compliant.png';
import { useHistory } from 'react-router-dom';
import routes from '../../../../constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export interface SimplePaymentOrderProps {
	orderData: OrderDataToClient;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

const defaultTransactionValues = {
	orderId: '',
	cardholder: '',
	document: '',
	cardStamp: '',
	cardNumber: '',
	cardExpiration: '',
	cardSecurityCode: 0,
	fee: '',
};

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export const ClientPaymentTransaction = (): JSX.Element | null => {
	const classes = useStyles();
	const history = useHistory();
	const { payment } = useSelector((state: RootState) => state);

	if (payment.orderDataToClient == null) {
		return null;
	}

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
		setValue,
	} = useForm({
		mode: 'all',
		defaultValues: { ...defaultTransactionValues, orderId: payment.orderDataToClient.orderId },
	});
	const [cardCvc, setCardCvc] = useState<string>('');
	const [cardExpiry, setCardExpiry] = useState<string>('');
	const [cardFocus, setCardFocus] = useState<Focused | undefined>(undefined);
	const [cardHolder, setCardHolder] = useState<string>('');
	const [cardNumber, setCardNumber] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [cardMask, setCardMask] = useState<string>('9999 9999 9999 9999');
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	const onSubmit = async (data: any) => {
		try {
			setLoading(true);
			await createSimpleOrderTransaction({
				...data,
				orderId: payment.orderDataToClient?.orderId,
				cardNumber: (data['cardNumber'] as string).replace(/\s/g, '').replace('_', ''),
			} as IClientTransactionForm);
			setLoading(false);
			history.push(routes.payment.thankYouTransaction);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al realizar la transacción'),
			});
			setLoading(false);
		}
	};

	const handleCvcInput = (e: any) => setCardCvc(e.target.value);
	const handleNameInput = (e: any) => setCardHolder(e.target.value);
	const handleCardFocus = (e: any) => setCardFocus(e.target.name !== 'cardSecurityCode' ? e.target.name : 'cvc');

	const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
		const {
			target: { value },
		} = event;
		onChange(value);
		setCardNumber(value);
	};

	const handleExpiryInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
		const {
			target: { value },
		} = event;
		onChange(value);
		setCardExpiry(value);
	};

	const handleCallback = ({ issuer }: any) => {
		if (issuer !== 'amex') {
			setCardMask('9999 9999 9999 9999');
		} else {
			setCardMask('999 999999 999999');
		}
		setValue('cardStamp', issuer);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Typography variant="h6">Orden de pago por:</Typography>
			<Typography variant="h6">
				{payment.orderDataToClient?.coinCode} {payment.orderDataToClient?.amount}
			</Typography>
			<Typography fontWeight={100} fontSize="0.75rem" paddingBottom={2}>
				Deal ID: {payment.orderDataToClient?.crmTicket}
			</Typography>
			<form noValidate onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={4} justifyContent="center">
					<Grid item xs={12} md={6}>
						<Grid container justifyContent="center" marginTop={3} spacing={2}>
							<Grid item maxWidth={'100%'}>
								<Cards
									cvc={cardCvc}
									expiry={cardExpiry}
									focused={cardFocus}
									name={cardHolder}
									number={cardNumber}
									callback={handleCallback}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Controller
									name="cardholder"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Este campo es requerido',
										},
										maxLength: {
											value: SimplePaymentOrderEnum.NAME,
											message: 'El nombre excede la cantidad de caracteres permitida de ' + SimplePaymentOrderEnum.NAME,
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											type="text"
											id="name"
											autoComplete="cc-name"
											onInput={handleNameInput}
											onFocus={handleCardFocus}
											label="Nombre de Titular de la Tarjeta"
											helperText={checkShowErrorMessage(Boolean(errors.cardholder), errors.cardholder?.message)}
											error={Boolean(errors.cardholder)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="cardNumber"
									control={control}
									defaultValue=""
									rules={{
										required: {
											value: true,
											message: 'Este campo es requerido',
										},
										validate: {
											//have to account for spaces made by the mask
											checkLength: (value) =>
												value.replace(/\s/g, '').replace('_', '').length <= SimplePaymentOrderEnum.CARD ||
												'El numero excede la cantidad de caracteres permitida de ' + SimplePaymentOrderEnum.CARD,
										},
									}}
									render={({ field: { value, onChange } }) => (
										<InputMask
											mask={cardMask}
											onFocus={handleCardFocus}
											value={value}
											onChange={(event) => handleNumberInput(event, onChange)}
										>
											{(inputProps: any) => (
												<TextField
													variant="outlined"
													id="card-number"
													name="card-number"
													inputMode="numeric"
													autoComplete="cc-number"
													pattern="[0-9]+"
													required
													fullWidth
													type="text"
													label="No. de Tarjeta"
													helperText={checkShowErrorMessage(Boolean(errors.cardNumber), errors.cardNumber?.message)}
													error={Boolean(errors.cardNumber)}
													{...inputProps}
												/>
											)}
										</InputMask>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<Controller
									name="cardExpiration"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Este campo es requerido',
										},
										validate: (value) =>
											(+value.split('/')[0] <= 12 &&
												+value.split('/')[0] > 0 &&
												(+('20' + value.split('/')[1]) > new Date().getFullYear() ||
													(+('20' + value.split('/')[1]) == new Date().getFullYear() &&
														+value.split('/')[0] >= new Date().getMonth() + 1))) ||
											'La fecha de expiración de la tarjeta no es válida',
									}}
									render={({ field: { value, onChange } }) => (
										<InputMask
											mask="99/99"
											onFocus={handleCardFocus}
											value={value}
											onChange={(event) => handleExpiryInput(event, onChange)}
										>
											{(inputProps: any) => (
												<TextField
													variant="outlined"
													required
													fullWidth
													id="expiry-date"
													name="expiry-date"
													autoComplete="cc-exp"
													type="text"
													label="Fecha de Expiración"
													helperText={checkShowErrorMessage(
														Boolean(errors.cardExpiration),
														errors.cardExpiration?.message,
													)}
													error={Boolean(errors.cardExpiration)}
													{...inputProps}
												/>
											)}
										</InputMask>
									)}
								/>
							</Grid>
							<Grid item xs={6} md={4}>
								<Controller
									name="cardSecurityCode"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Este campo es requerido',
										},
										maxLength: {
											value: SimplePaymentOrderEnum.CARDCODE,
											message:
												'El código cvc excede la cantidad de caracteres permitida de ' +
												SimplePaymentOrderEnum.CARDCODE,
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											type="text"
											id="security-code"
											inputMode="numeric"
											onInput={handleCvcInput}
											onFocus={handleCardFocus}
											label="CVC"
											helperText={checkShowErrorMessage(
												Boolean(errors.cardSecurityCode),
												errors.cardSecurityCode?.message,
											)}
											error={Boolean(errors.cardSecurityCode)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} md={4}>
								<Controller
									name="fee"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Este campo es requerido',
										},
									}}
									render={({ field }) => (
										<FormControl sx={{ width: '100%' }}>
											<InputLabel id="fee-label">Cuotas</InputLabel>
											<Select label="Cuotas" {...field}>
												{payment.orderDataToClient?.feeAllow?.map((fee) => {
													return (
														<MenuItem key={fee} value={fee}>
															{fee}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="document"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Este campo es requerido',
										},
										maxLength: {
											value: SimplePaymentOrderEnum.DOCUMENT,
											message:
												'El documento excede la cantidad de caracteres permitida de ' + SimplePaymentOrderEnum.DOCUMENT,
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Documento de identidad"
											helperText={checkShowErrorMessage(Boolean(errors.document), errors.document?.message)}
											error={Boolean(errors.document)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography fontStyle="italic" variant="body2" component="span" color="primary">
									En el estado de cuenta de tu tarjeta podrá llegar más de un cobro y en distintas fechas; la suma de
									esos cobros será {payment.orderDataToClient.coinCode} {payment.orderDataToClient.amount}.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									sx={{ height: '100%', width: '100%' }}
									disabled={!isValid}
									color="success"
									type="submit"
								>
									Pagar
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container alignItems="center">
							<Grid xs={6}>
								<img src={pciLogo} alt="pci-logo" />
							</Grid>
							<Grid justifyContent="center" xs={6}>
								<Typography textAlign="center">Pago Seguro</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
