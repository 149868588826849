export enum ActivityLogModuleEnum {
	Users = 'Users',
	Packages = 'Packages',
	Flights = 'Flights',
	Hotels = 'Hotels',
	CreditCard = 'CreditCard',
}
export enum ActivityLogActionEnum {
	Read = 'Read',
	Edit = 'Edit',
	Delete = 'Delete',
	Login = 'Login',
	Logout = 'Logout',
}

export interface IActivityLogList {
	username: string;
	fullName: string;
	module: ActivityLogModuleEnum;
	action: ActivityLogActionEnum;
	remarks: string;
	created: string;
}

export interface IActivityLogModuleInfo {
	id: ActivityLogModuleEnum;
	name: string;
}

export const activityLogModuleList: IActivityLogModuleInfo[] = [
	{
		id: ActivityLogModuleEnum.Users,
		name: 'Users',
	},
	{ id: ActivityLogModuleEnum.Packages, name: 'Packages' },
	{ id: ActivityLogModuleEnum.Flights, name: 'Flights' },
	{ id: ActivityLogModuleEnum.Hotels, name: 'Hotels' },
	{ id: ActivityLogModuleEnum.CreditCard, name: 'CreditCard' },
];

export interface ActivityLogActionInfo {
	id: ActivityLogActionEnum;
	name: string;
}

export const activityLogActionList: ActivityLogActionInfo[] = [
	{ id: ActivityLogActionEnum.Read, name: 'Read' },
	{ id: ActivityLogActionEnum.Edit, name: 'Edit' },
	{ id: ActivityLogActionEnum.Delete, name: 'Delete' },
	{ id: ActivityLogActionEnum.Login, name: 'Login' },
	{ id: ActivityLogActionEnum.Logout, name: 'Logout' },
];
