import { hotelProviderType, roomServiceType } from '../quotation/types';
import { HotelDetail } from '../hotels/types';

export interface INomenclator {
	id: number;
	name: string;
}

export interface IPackagePriceByTravelDate {
	travelDate: string;
	priceInUSD: number;
}

export interface IPackagePriceContainer {
	items: IPackagePriceByTravelDate[];
	minPriceInfo?: IPackagePriceByTravelDate;
}

export interface IPackageDetail {
	name: string;
	basePrice: number;
	benefitType: number;
	compositeIcons: [];
	currency: string;
	destinations: IPackageDestinations[];
	discountType: number;
	duration: number;
	expiration: string;
	lastUpdatePrice: string;
	packageServicesNames: [];
	pricesByMonth: IPricesByMonth[];
	routeName: string;
	tripSeasons: [];
	tripStyles: [];
	value: number;
}

export interface IPricesByMonth {
	month: number;
	price: IMonthPrice;
}

export interface IMonthPrice {
	amount: number;
	currency: string;
}

export interface IPackageDestinations {
	hotels: IPackageHotels[];
	name: string;
	totalNights: number;
}

export interface IPackageHotels {
	name: string;
	provider: string;
	externalId: number;
}

export interface IRateValue {
	coinCode: string;
	fee: number;
}

export interface IRate {
	date: string;
	values: IRateValue[];
}

export interface Rates {
	ratesList: IRate[];
	feeConfig: number;
}

export interface IAutomaticRouteSuggestion {
	id: number;
	name: string;
	origen: string;
	destination: string;
	lastTrackUpdate?: string;
	goingFlightTravelMaxDuration?: number;
	returnFlightTravelMaxDuration?: number;
}

export interface IAutomaticRouteTariffPrice {
	travelDate: string;
	adultTariff?: number;
	childTariff?: number;

	infantTariff?: number;
	originTariffType?: OriginTariffTypeEnum;
	applyDiscount?: boolean;
	observations?: string;
	discountApplyInAdult?: number;
	discountApplyInChild?: number;

	discountApplyInInfant?: number;
	validatingCarrierCode?: string;
	isOriginal?: boolean;
	related?: string;
}

export enum OriginTariffTypeEnum {
	TRACK = 1,
	MANUAL,
}

export interface IOriginTariffTypeInterface {
	id: OriginTariffTypeEnum;
	name: string;
}

export const origenTariffTypes: IOriginTariffTypeInterface[] = [
	{
		id: OriginTariffTypeEnum.TRACK,
		name: 'Track',
	},
	{
		id: OriginTariffTypeEnum.MANUAL,
		name: 'Manual',
	},
];

export interface IAutomaticRouteTariffPriceSegment {
	travelDate: string;
	segmentType: number;
	flightNumber: string;
	flightDepartureDate: string;
	flightDepartureTime: string;
	airportDepartureCode: string;
	cityDepartureCode: string;
	flightArrivalDate: string;
	flightArrivalTime: string;
	airportArrivalCode: string;
	cityArrivalCode: string;
	fareFamily: string;
	cabinClass: string;
}

export interface IAutomaticRouteTariffPriceContainerSegment {
	goingTravelSegments: IAutomaticRouteTariffPriceSegment[];
	returnTravelSegments: IAutomaticRouteTariffPriceSegment[];
}

export interface IAutomaticRouteTariffPriceAmadeusCommand {
	fxd: string;
	snGoing: string;
	snReturn: string;
}

export interface IAutomaticRouteTariffPriceAmadeusCommandContainer {
	travelDate: string;
	commandInfo: IAutomaticRouteTariffPriceAmadeusCommand;
}

export interface DataToChart {
	labels: string[];
	prices: number[];
}

export interface IAutomaticRuteTariffPriceByDate {
	travelDate: string;
	tariffPriceAdult: number;
}

export interface IAutomaticRuteTariffPriceByMonth {
	month: number;
	tariffPriceAdult: number;
}

export interface IAutomaticRuteTariffPriceInfoForGraphic {
	pricesByDate: IAutomaticRuteTariffPriceByDate[];
	minPricesByMonth: IAutomaticRuteTariffPriceByMonth[];
}

export interface IManualRouteFarePrice {
	priceAdult: number;
	priceChild?: number;
}

export interface IRouteInfoForPackageCompositeDetailPower {
	id: number;
	name: string;
	isAutomaticRoute: boolean;
	price: number;
	manualRouteFarePrice?: IManualRouteFarePrice;
	lastUpdatePrices?: string;
	originTariffType?: number;
	related?: string;
	isOriginal?: boolean;
	goingFlightTravelMaxDuration?: number;
	returnFlightTravelMaxDuration?: number;
	observations?: string;
	containerSegments?: IAutomaticRouteTariffPriceContainerSegment;
	amadeusCommandInfo?: IAutomaticRouteTariffPriceAmadeusCommand;
}

export interface IServiceInfoForPackageCompositeDetailPower {
	name: string;
	forAllDestinations: boolean;
	serviceType: string;
	destinationCodes: string[];
	observations?: string;
	priceSingle?: number;
	priceDbl?: number;
	priceTpl?: number;
	priceCpl?: number;
	priceChild?: number;
}

export interface ManualHotelFareTotalPriceForAllNights {
	priceSingle?: number;
	priceDbl?: number;
	priceTpl?: number;
	priceCpl?: number;
	priceFP?: number;
}

export interface IHotelsInfoForPackageCompositeDetailPower {
	name: string;
	provider: hotelProviderType;
	externalId: number;
	totalNights: number;
	totalCost: number;
	costByNight: number;
	board: roomServiceType;
	manualHotelFareTotalPriceForAllNights?: ManualHotelFareTotalPriceForAllNights;
	lastUpdatePrices?: string;
}

export interface IDestinationInfoForPackageCompositeDetailPower {
	code: string;
	name: string;
	hotels: IHotelsInfoForPackageCompositeDetailPower[];
}

export interface IHotelsInfoForPackageCompositeDetailPowerWithDetail extends IHotelsInfoForPackageCompositeDetailPower {
	details: HotelDetail;
}

export interface IDestinationPackageCompositeDetailWithHotelDetail {
	code: string;
	name: string;
	hotels: IHotelsInfoForPackageCompositeDetailPowerWithDetail[];
}

export interface IPackageCompositeDetailForPower {
	route: IRouteInfoForPackageCompositeDetailPower;
	destinations: IDestinationInfoForPackageCompositeDetailPower[];
	services: IServiceInfoForPackageCompositeDetailPower[];
	basePrice: number;
	cost: number;
	pvp: number;
	markup: number;
	privateNotes?: string;
	lastUpdatePrices?: string;
}

export enum PackageBasePriceEnum {
	SGL = 1 /*1*/,
	DBL /*2*/,
	TPL /*3*/,
	CPL /*4*/,
	QPL /*5*/,
	SEX /*6*/,
	FAMPLAN /*7*/,
}

export interface IPackageBasePriceEnum {
	id: PackageBasePriceEnum;
	name: string;
}

export const packageBasePrices: IPackageBasePriceEnum[] = [
	{
		id: PackageBasePriceEnum.SGL,
		name: 'SGL',
	},
	{
		id: PackageBasePriceEnum.DBL,
		name: 'DBL',
	},
	{
		id: PackageBasePriceEnum.TPL,
		name: 'TPL',
	},
	{
		id: PackageBasePriceEnum.CPL,
		name: 'CPL',
	},
	{
		id: PackageBasePriceEnum.QPL,
		name: 'QPL',
	},
	{
		id: PackageBasePriceEnum.SEX,
		name: 'SEX',
	},
	{
		id: PackageBasePriceEnum.FAMPLAN,
		name: 'FAMPLAN',
	},
];
export interface IHotelDetail {
	provider: hotelProviderType;
	externalId: number;
	details: HotelDetail;
}
