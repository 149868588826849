import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertColor, Box, Fab, Snackbar } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SaveIcon from '@mui/icons-material/Save';
import makeStyles from '@mui/styles/makeStyles';
import { useReactToPrint } from 'react-to-print';
import RoomBaseConfig from 'features/quotation/components/RoomBaseConfig';
import HotelOptionsConfig from 'features/quotation/components/HotelOptionsConfig';
import { QuotationResult } from 'features/quotation/components/QuotationResult';
import { ServicesConfig } from 'features/quotation/components/ServicesConfig';
import { Header } from 'features/quotation/components/Header';
import { RootState } from 'store';
import {
	clearQuotation,
	refreshAirConfig,
	saveQuotation,
	setDescription,
	setLoading,
	setNotIncludes,
	updateQuotation,
} from 'features/quotation/slice';
import { Layout } from 'features/common/components/Layout';
import { useHistory, useLocation } from 'react-router-dom';
import routes from 'constants/routes';
import { ManualServicesConfig } from '../../features/quotation/components/ManualServicesConfig';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { SendQuotationToClient } from '../../features/quotation/components/SendQuotationToClient';
import {
	convertToDestinationsSimple,
	convertToQuotationDestinationMultipleContainer,
	extractErrorMessage,
	isNotFoundError,
	isValidQuotationToSendClient,
	needRefreshAirs,
} from '../../features/quotation/helpers';
import QuotationPreview from '../../features/quotation/components/QuotationPreview';
import { getPAXTotals } from '../../features/quotation/selectors';
import { DestinationOptionalServiceList } from '../../features/quotation/components/DestinationOptionalServiceList';
import { DestinationSimple, Quotation, QuotationDestinationMultipleContainer } from '../../features/quotation/types';
import { ContentCopy } from '@mui/icons-material';
import { CopyQuotationDialog } from '../../features/quotation/components/CopyQuotationDialog';
import { getQuotationDefaultDescription, getQuotationDefaultNotIncludesList } from '../../features/quotation/services';
import DestinationConfigCompleteQuotation from '../../features/quotation/components/DestinationConfig/DestinationConfigCompleteQuotation';
import GeneralConfigCompleteQuotation from '../../features/quotation/components/GeneralConfig/GeneralConfigCompleteQuotation';
import AirConfigCompleteQuotation from '../../features/quotation/components/AirConfig/AirConfigCompleteQuotation';
import { NotIncludesCompleteQuotation } from '../../features/quotation/components/NotIncludes/NotIncludesCompleteQuotation';

const useStyles = makeStyles((theme) => ({
	root: {
		lexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		lexGrow: 1,
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	countrySelector: {
		marginLeft: theme.spacing(3),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

function QuotationsNew(): JSX.Element {
	const classes = useStyles();
	const [previewMode, setPreviewMode] = React.useState(false);
	const [openSendToClient, setOpenSendToClient] = React.useState(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const componentRef = useRef(null);
	const [isValidQuotationToClient, setIsValidQuotationToClient] = useState(false);
	const [quotationMultiDestinationContainer, setQuotationMultiDestinationContainer] =
		useState<QuotationDestinationMultipleContainer | null>(null);
	const [destinationsSimple, setDestinationsSimple] = useState<DestinationSimple[] | null>(null);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { quotation, auth } = useSelector((state: RootState) => state);
	const { localCurrency, exchangeRate } = quotation;
	const paxTotals = useSelector(getPAXTotals);
	const [openDialogCopyQuotation, setOpenDialogCopyQuotation] = React.useState(false);
	const [idToCopy, setIdToCopy] = useState(-1);
	const [idQuotationToShowDetailOnCopy, setIdQuotationToShowDetailOnCopy] = useState(-1);
	const [flightIndexSelected, setFlightIndexSelected] = useState<number | null>(0);
	const handleClear = () => {
		dispatch(clearQuotation());
		getDefaultDescription();
		getDefaultNotIncludes();
	};

	const getDefaultDescription = async () => {
		try {
			dispatch(setLoading(true));
			const defaultDescription = await getQuotationDefaultDescription();
			dispatch(setDescription(defaultDescription.data.description));
			setDescription(defaultDescription.data.description);
			dispatch(setLoading(false));
		} catch (error) {
			if (!isNotFoundError(error)) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al cargar la descripción por defecto'),
				});
			}
			dispatch(setLoading(false));
		}
	};

	const getDefaultNotIncludes = async () => {
		try {
			dispatch(setLoading(true));
			const defaultNotIncludes = (await getQuotationDefaultNotIncludesList()).data;
			dispatch(setNotIncludes(defaultNotIncludes.map((x) => x.notInclude)));
			dispatch(setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los no incluye por defecto'),
			});
			dispatch(setLoading(false));
		}
	};

	useEffect(() => {
		if (location.pathname == routes.quotations.new) handleClear();
	}, []);

	const redirectToEdit = (id: number) => {
		history.push(routes.quotations.edit.replace(':id', String(id)));
	};

	useEffect(() => {
		setIsValidQuotationToClient(isValidQuotationToSendClient(quotation));
	}, [quotation.id, quotation.airs, quotation.destination, quotation.roomBaseConfig]);

	useEffect(() => {
		if (idQuotationToShowDetailOnCopy > 0) {
			const timer = setTimeout(() => {
				history.push(routes.quotations.edit.replace(':id', String(idQuotationToShowDetailOnCopy)));
			}, 1200);
			return () => clearTimeout(timer);
		}
	}, [idQuotationToShowDetailOnCopy]);

	const handleConvertToDestinationMultipleOrSimple = () => {
		if (quotation.destination.length > 1) {
			const newToSet = convertToQuotationDestinationMultipleContainer(quotation);
			setQuotationMultiDestinationContainer(newToSet);
			setDestinationsSimple(null);
		} else {
			setQuotationMultiDestinationContainer(null);
			setDestinationsSimple(convertToDestinationsSimple(quotation));
		}
	};

	useMemo(() => {
		handleConvertToDestinationMultipleOrSimple();
	}, [
		quotation.destination,
		quotation.manualServices,
		quotation.sign,
		quotation.markup,
		quotation.roomBaseConfig,
		flightIndexSelected,
	]);

	useMemo(() => {
		if (quotation.airs.length > 0) {
			if (flightIndexSelected == null) {
				setFlightIndexSelected(0);
			} else if (flightIndexSelected == quotation.airs.length) {
				setFlightIndexSelected(quotation.airs.length - 1);
			}
		} else {
			setFlightIndexSelected(null);
		}

		handleConvertToDestinationMultipleOrSimple();
	}, [quotation.airs]);

	const handleTogglePreview = () => setPreviewMode((prevState) => !prevState);

	const handleShowSendToClient = () => setOpenSendToClient((prevState) => !prevState);

	const handleCloseSendToClient = () => setOpenSendToClient(false);

	const handleOnSuccessSendToClient = () => {
		handleCloseSendToClient();
		setAlert({ show: true, severity: 'success', message: 'Cotización enviada satisfactoriamente' });
	};

	const handleOnErrorSendToClient = (error: string) => {
		handleCloseSendToClient();
		setAlert({ show: true, severity: 'error', message: error });
	};

	const handleOnError = (error: string) => {
		setAlert({ show: true, severity: 'error', message: error });
	};

	const showSuccessAlertOnSaveQuotation = () => {
		setAlert({
			show: true,
			severity: 'success',
			message: 'Cotización guardada satisfactoriamente',
		});
	};

	const handleSave = async () => {
		const agentId = auth.user?.id;
		const finalQuotation = {
			...quotation,
			email: quotation.email !== '' ? quotation.email : null,
			agentId,
		};

		const result =
			quotation.id == 0
				? await dispatch(saveQuotation(finalQuotation))
				: await dispatch(updateQuotation(finalQuotation));
		// @ts-ignore
		if (result['error']) {
			// @ts-ignore
			if (result['payload'] && result['payload']['message']) {
				// @ts-ignore
				handleOnError(result['payload']['message']);
			} else {
				handleOnError('Ocurrió un error al guardar la cotización');
			}
		} else {
			showSuccessAlertOnSaveQuotation();
			if (quotation.id == 0) {
				// @ts-ignore
				redirectToEdit(result.payload);
			} else {
				// @ts-ignore
				const quotationFromServer = result.payload as unknown as Quotation;
				if (needRefreshAirs(quotation.airs)) dispatch(refreshAirConfig(quotationFromServer.airs));
			}
		}
	};

	const handleCloseCopyQuotationDialog = () => setOpenDialogCopyQuotation(false);

	const handleSuccessCopyQuotation = (id: number) => {
		handleCloseCopyQuotationDialog();
		setAlert({ show: true, severity: 'success', message: 'Cotización copiada satisfactoriamente' });
		setIdQuotationToShowDetailOnCopy(id);
	};

	const handleClickOnCopyQuotation = () => {
		setIdToCopy(quotation.id);
		setOpenDialogCopyQuotation(true);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	return (
		<Layout>
			<>
				<Backdrop className={classes.backdrop} open={quotation.loading}>
					<CircularProgress color="inherit" />
				</Backdrop>

				{openDialogCopyQuotation && (
					<CopyQuotationDialog
						quotationIdToCopy={idToCopy}
						isOnlyFlights={false}
						open={openDialogCopyQuotation && idToCopy > 0}
						onClose={handleCloseCopyQuotationDialog}
						onSuccessCopyQuotation={handleSuccessCopyQuotation}
					/>
				)}

				{quotation.id > 0 && (
					<Box zIndex="tooltip" position="fixed" bottom={280} right={30}>
						<Fab
							size="small"
							color="secondary"
							aria-label="Copiar cotización"
							title={'Copiar cotización '}
							onClick={handleClickOnCopyQuotation}
						>
							<ContentCopy />
						</Fab>
					</Box>
				)}
				<Box zIndex="tooltip" position="fixed" bottom={230} right={30}>
					<Fab
						size="small"
						color="secondary"
						aria-label="Guardar cotización"
						title={'Guardar cotización'}
						onClick={handleSave}
					>
						<SaveIcon />
					</Fab>
				</Box>
				<Box zIndex="tooltip" position="fixed" bottom={180} right={30}>
					<Fab
						size="small"
						color="secondary"
						aria-label="Borrar datos de cotización"
						title={'Borrar datos de cotización'}
						onClick={handleClear}
					>
						<BackspaceIcon />
					</Fab>
				</Box>
				<Box zIndex="tooltip" position="fixed" bottom={70} right={30}>
					<Fab
						size="small"
						color="secondary"
						aria-label="Previsualizar cotización"
						title={'Previsualizar cotización'}
						onClick={handleTogglePreview}
					>
						<VisibilityIcon />
					</Fab>
				</Box>

				<Box zIndex="tooltip" position="fixed" bottom={130} right={30}>
					<Fab
						size="small"
						color="secondary"
						aria-label="Imprimir cotización"
						title={'Imprimir cotización'}
						onClick={handlePrint}
					>
						<PictureAsPdfIcon />
					</Fab>
				</Box>

				{isValidQuotationToClient && (
					<Box zIndex="tooltip" position="fixed" bottom={20} right={30}>
						<Fab
							size="small"
							color="secondary"
							aria-label="Enviar al cliente"
							title={'Enviar al cliente'}
							onClick={handleShowSendToClient}
						>
							<WhatsAppIcon />
						</Fab>
					</Box>
				)}
				<div ref={componentRef}>
					<Header />
					{previewMode ? (
						<QuotationPreview
							exchangeRate={exchangeRate}
							localCurency={localCurrency}
							isMultiDestination={quotation.destination.length > 1}
							containerMultiDestination={quotationMultiDestinationContainer}
							description={quotation.description}
							destinationsForQuotationSimpleDestination={destinationsSimple}
							manualServices={quotation.manualServices}
							notIncludes={quotation.notIncludes}
							paxTotals={paxTotals}
							countryCode={auth.country}
							flightIndexSelected={flightIndexSelected}
							showFlightSelectionControl={quotation.airs.length > 1}
							handleFlightSelectionChange={setFlightIndexSelected}
							airs={quotation.airs}
						/>
					) : (
						<>
							<GeneralConfigCompleteQuotation
								onErrorOnAutocompleteContact={handleOnError}
								showDescriptionField={true}
							/>
							<DestinationConfigCompleteQuotation onError={handleOnError} />
							<AirConfigCompleteQuotation
								showNotConfigureFlightControl={true}
								indexSelected={flightIndexSelected}
								showSelectionControl={quotation.airs.length > 1}
								handleSelectionChange={setFlightIndexSelected}
							/>
							<RoomBaseConfig />
							<HotelOptionsConfig onError={handleOnError} />
							<ServicesConfig />
							<NotIncludesCompleteQuotation />
							<ManualServicesConfig />
							<QuotationResult
								localCurrency={localCurrency}
								containerMultiDestination={quotationMultiDestinationContainer}
								isMultiDestination={quotation.destination.length > 1}
								destinationsForSimpleDestination={destinationsSimple}
								paxTotals={paxTotals}
								manualServices={quotation.manualServices}
								showUtility={true}
								flightIndexSelected={flightIndexSelected}
								handleFlightSelectionChange={setFlightIndexSelected}
							/>
							{quotation.destination.length > 1 && quotationMultiDestinationContainer && (
								<DestinationOptionalServiceList
									destinations={quotationMultiDestinationContainer.destinations}
									paxTotals={paxTotals}
									isPreview={false}
								/>
							)}
							{quotation.destination.length == 1 && destinationsSimple && (
								<DestinationOptionalServiceList
									destinations={destinationsSimple}
									paxTotals={paxTotals}
									isPreview={false}
								/>
							)}
						</>
					)}

					<Snackbar open={alert.show} autoHideDuration={1000} onClose={() => setAlert(defaultAlertState)}>
						<Alert variant="filled" severity={alert.severity}>
							{alert.message}
						</Alert>
					</Snackbar>

					{isValidQuotationToClient && (
						<SendQuotationToClient
							open={openSendToClient}
							onClose={handleCloseSendToClient}
							onSuccess={handleOnSuccessSendToClient}
							onError={handleOnErrorSendToClient}
						/>
					)}
				</div>
			</>
		</Layout>
	);
}

export default QuotationsNew;
