import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AirDisplay } from './AirDisplay';
import { addAirConfig, deleteAirConfig, deleteAllAirsConfig } from '../../slice';

import { Air } from '../../types';
import { RootState } from '../../../../store';
import { TstDialog } from './TstDialog';
import { FlightFormConfig } from './FlightFormConfig';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	formControl: {
		width: '100%', // Fix IE 11 issue.
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	previewTitle: {
		marginBottom: theme.spacing(3),
	},
	configureFlight: {
		float: 'right',
	},
}));

export interface IDefaultPriceConfig {
	adtPrice: number;
	chdPrice: number;
	infPrice: number;
}

export interface Props {
	showNotConfigureFlightControl: boolean;
	showSelectionControl?: boolean;
	indexSelected?: number | null;
	handleSelectionChange?: (index: number | null) => void;
	maxFlightsAllowed?: number;
	defaultPriceConfig?: IDefaultPriceConfig;
	handleChangeConfigureFlights?: (value: boolean) => void;
}
export default function AirConfigCompleteQuotation({
	showNotConfigureFlightControl,
	showSelectionControl,
	indexSelected,
	handleSelectionChange,
	maxFlightsAllowed,
	defaultPriceConfig,
	handleChangeConfigureFlights,
}: Props): JSX.Element {
	const classes = useStyles();

	const [showTstDialog, setShowTstDialog] = useState<boolean[]>([]);
	const { airs, id } = useSelector((state: RootState) => state.quotation);

	const [configureFlight, setConfigureFlight] = useState<boolean>((airs.length > 0 && id > 0) || id == 0);
	const dispatch = useDispatch();

	const handleDeleteAirConfig = (index: number) => {
		dispatch(deleteAirConfig(index));
	};

	const handleAddAir = (air: Air) => {
		dispatch(addAirConfig(air));
	};

	const handleConfigureFlight = () => {
		setConfigureFlight((prevState) => !prevState);
	};

	useEffect(() => {
		if (!configureFlight) {
			dispatch(deleteAllAirsConfig());
		}
		handleChangeConfigureFlights && handleChangeConfigureFlights(configureFlight);
	}, [configureFlight]);

	const handleShowTstDialog = (index: number) => {
		setShowTstDialog((prevState) => {
			const newState = [...prevState];
			newState[index] = !newState[index];
			return newState;
		});
	};

	return useMemo(() => {
		return (
			<Container component="main" maxWidth="md">
				<div className={classes.paper}>
					<Typography component="h1" variant="h5">
						Vuelos {configureFlight && '*'}{' '}
						{showNotConfigureFlightControl && (
							<FormControlLabel
								className={classes.configureFlight}
								control={<Switch checked={configureFlight} name="sign" onChange={handleConfigureFlight} />}
								label="Configurar vuelos"
							/>
						)}
					</Typography>

					{configureFlight &&
						airs &&
						airs.map((air, index) => {
							return (
								<>
									{air.tst && (
										<Grid container direction="row-reverse">
											<Button
												variant="contained"
												color="primary"
												onClick={() => handleShowTstDialog(index)}
												style={{ margin: 8 }}
											>
												Ver TST
											</Button>
										</Grid>
									)}
									{(showTstDialog[index] || false) && air.tst && (
										<TstDialog
											open={showTstDialog[index] || false}
											tst={air?.tst}
											onClose={() => handleShowTstDialog(index)}
										/>
									)}

									<AirDisplay
										data={air}
										previewMode={false}
										index={index}
										handleDeleteFlight={handleDeleteAirConfig}
										handleSelectionChange={handleSelectionChange}
										showSelectionControl={showSelectionControl}
										indexSelected={indexSelected}
									/>
								</>
							);
						})}

					{configureFlight && airs.length < (maxFlightsAllowed || 3) && (
						<FlightFormConfig defaultPriceConfig={defaultPriceConfig} handleAddAir={handleAddAir} />
					)}
				</div>
			</Container>
		);
	}, [
		airs,
		showTstDialog,
		configureFlight,
		id,
		indexSelected,
		showSelectionControl,
		maxFlightsAllowed,
		defaultPriceConfig,
	]);
}
