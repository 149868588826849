import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { GOOGLE_MAPS_KEY } from '../../../../constants/constants';
import globe from 'assets/pngHotelMap.png';

interface Props {
	map: {
		lat: number;
		lng: number;
	};
}

export const HotelMap = (props: Props): JSX.Element => {
	return (
		<>
			<div style={{ height: '65vh', width: '100%' }}>
				<LoadScript id="script-loader" googleMapsApiKey={GOOGLE_MAPS_KEY}>
					<GoogleMap
						id="availability-map"
						mapContainerStyle={{
							height: '100%',
							width: '100%',
							borderRadius: '14px',
						}}
						zoom={17}
						clickableIcons={false}
						options={{
							zoomControl: true,
							mapTypeControl: false,
							scaleControl: false,
							streetViewControl: false,
							rotateControl: false,
							fullscreenControl: false,
							gestureHandling: 'greedy',
						}}
						center={{
							lat: props.map.lat,
							lng: props.map.lng,
						}}
					>
						<Marker
							position={{
								lat: props.map.lat,
								lng: props.map.lng,
							}}
							icon={{
								url: globe,
							}}
						></Marker>
					</GoogleMap>
				</LoadScript>
			</div>
		</>
	);
};
