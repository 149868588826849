import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { postSendQuotationToClient } from '../../../services';
import { convertQuotationOnlyFlightToSnapShotToClient, extractErrorMessage } from '../helpers';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { nanoidGenerator } from '../../common/helpers';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

interface Props {
	open: boolean;
	onClose: () => void;
	onSuccess: (message: string) => void;
	onError: (error: string) => void;
}

export const SendQuotationOnlyFlightToClient = (props: Props): JSX.Element => {
	const { quotationOnlyFlights, auth } = useSelector((state: RootState) => state);
	const [message, setMessage] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const classes = useStyles();
	const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(e.target.value);
	};
	const quotationToClient = convertQuotationOnlyFlightToSnapShotToClient(quotationOnlyFlights, auth.country);

	const handleCopyURLToClipBoard = async (urlToClient: string) => {
		try {
			await navigator.clipboard.writeText(urlToClient);
			props.onSuccess('URL copiada satisfactoriamente');
		} catch (error) {
			props.onError('Ocurrió un error al copiar la URL');
		}
	};

	const handleSendQuotationToClient = async (sendLink: boolean) => {
		if (quotationToClient != null) {
			try {
				const body = {
					id: nanoidGenerator(),
					message: sendLink ? message : 'Solo copia de URL',
					quotationSnapShotToClient: quotationToClient,
					quotationType: quotationToClient.quotationType,
					sendLink: sendLink,
				};
				setLoading(true);
				const response = await postSendQuotationToClient(body);
				setLoading(false);
				setMessage('');
				if (!sendLink) {
					await handleCopyURLToClipBoard(response.data.urlToClient);
				} else {
					props.onSuccess('Cotización enviada satisfactoriamente');
				}
			} catch (error) {
				const errorMessage = extractErrorMessage(error, 'Ocurrió un error enviar la cotización al cliente');
				setLoading(false);
				setMessage('');
				props.onError(errorMessage);
			}
		} else props.onError('La cotización no es válida para enviarla al cliente');
	};

	const isValidMessage = () =>
		message && message.length > 0 && message.length <= 2000 && message.replace(/ /g, '').length > 0;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog
				open={props.open}
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">{'Enviar cotización al cliente'}</DialogTitle>
				<DialogContent>
					<div>
						<TextField
							id="outlined-multiline-static"
							label="Mensaje para el cliente"
							helperText="Complete este campo solo si va a enviar la cotización al cliente, si solo va a copiar la url no es necesario."
							multiline
							rows={5}
							required
							variant="outlined"
							value={message}
							onChange={handleMessageChange}
							fullWidth
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={props.onClose}>
						Cancelar
					</Button>
					{quotationToClient != null && (
						<>
							<Button
								variant="outlined"
								color="secondary"
								disabled={message.length > 0}
								onClick={() => handleSendQuotationToClient(false)}
							>
								Copiar URL
							</Button>
							<Button
								variant="contained"
								color="primary"
								disabled={!isValidMessage()}
								onClick={() => handleSendQuotationToClient(true)}
								autoFocus
							>
								Enviar
							</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};
