import React, { useEffect } from 'react';
import {
	Alert,
	AlertColor,
	Button,
	ButtonGroup,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Snackbar,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import {
	IHotelsInfoForPackageCompositeDetailPower,
	INomenclator,
	IPackageCompositeDetailForPower,
	OriginTariffTypeEnum,
} from '../../stats/types';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircle from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HotelIcon from '@mui/icons-material/Hotel';
import {
	getFlightInfoFromAutomaticRoute,
	getFlightInfoFromGDS,
	getHotelFares,
	getHotelMostQuotedByDestination,
	postQuotation,
} from '../services';
import {
	AccommodationFaresByRoom,
	AccommodationFaresRoomPrice,
	Air,
	Destination,
	HotelOptions,
	IHotelMostQuoted,
	OptionalService,
	QuotationFlightInfoFromAutomaticRoute,
	QuotationRoomDistributionFaresRequest,
	RoomFare,
	roomRateType,
	roomServiceType,
	Service,
} from '../types';
import {
	addAirConfig,
	clearQuotation,
	deleteAirConfig,
	removeAllDestinations,
	removeHotelOptionsFromDestinations,
	setDestinations,
	setHotelOptionsToDestination,
	setMarkup,
} from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { AirDisplay } from './AirConfig/AirDisplay';
import { AirConfigModal } from './AirConfigModal';
import RoomBaseConfig from 'features/quotation/components/RoomBaseConfig';
import { formatDateToShowUser } from '../../common/helpers';
import moment from 'moment/moment';
import {
	compareDistributionAndRoomDistribution,
	createHotelFareRequestBase,
	customBoards,
	customRates,
	findCustomBoard,
	findCustomRate,
	getAccommodationFaresByDistribution,
} from '../helpers';
import { getHotelDetail } from '../../hotels/components/services';
import { SelectMenuProps } from '../../../constants/constants';
import { useTheme } from '@mui/styles';
import GeneralConfigCompleteQuotation from './GeneralConfig/GeneralConfigCompleteQuotation';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	containerComponent: {
		marginTop: theme.spacing(2),
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

interface Props {
	packageSelected: INomenclator;
	travelDate: string;
	packageDetail: IPackageCompositeDetailForPower;
	open: boolean;
	onClose: () => void;
	onSuccessCreateQuotation: (newId: number) => void;
}

type actionGenerateFlight = 'automaticRoute' | 'hitgds' | 'tst';
const maxHotelOptionByDestination = 8;
type actionGenerateHotels = 'saveHotelFares' | 'hitBrokers';

export const CreateQuotationFromPackageDialog = (props: Props): JSX.Element => {
	const allowSelectOptionTariffFromAutomaticRoute =
		props.packageDetail.route.isOriginal && props.packageDetail.route.originTariffType == OriginTariffTypeEnum.TRACK;
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [loading, setLoading] = React.useState(false);
	const [notFoundFlightInfoFromGDS, setNotFoundFlightInfoFromGDS] = React.useState(false);
	const [allowUseTariffHotelSaved, setAllowUseTariffHotelSaved] = React.useState(false);
	const [showModalFlightConfigFromTST, setShowModalFlightConfigFromTST] = React.useState(false);
	const [hotelsMostQuotedByDestination, setHotelsMostQuotedByDestination] = React.useState<
		Map<string, IHotelMostQuoted[]>
	>(new Map<string, IHotelMostQuoted[]>());
	const classes = useStyles();
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const dispatch = useDispatch();
	const theme = useTheme();
	const { quotation } = useSelector((state: RootState) => state);
	const { airs, destination, roomBaseConfig } = useSelector((state: RootState) => state.quotation);
	const [customBoardsSelected, setCustomBoardsSelected] = React.useState<string[]>([]);
	const [customRateSelected, setCustomRateSelected] = React.useState<string>('');
	const handleOnError = (error: string) => {
		setAlert({ show: true, severity: 'error', message: error });
	};

	const handleClearAirConfig = (index: number) => {
		dispatch(deleteAirConfig(index));
	};

	const [typeActionGenerateFlight, setTypeActionGenerateFlight] = React.useState<actionGenerateFlight>(
		allowSelectOptionTariffFromAutomaticRoute ? 'automaticRoute' : 'hitgds',
	);
	const [typeActionGenerateHotels, setTypeActionGenerateHotels] =
		React.useState<actionGenerateHotels>('saveHotelFares');

	const handleChangeFlightActionType = (
		event: React.MouseEvent<HTMLElement>,
		newTypeActionToGenerateFlight: string,
	) => {
		if (newTypeActionToGenerateFlight) {
			setTypeActionGenerateFlight(newTypeActionToGenerateFlight as actionGenerateFlight);
		}
	};

	const handleChangeHotelActionType = (event: React.MouseEvent<HTMLElement>, newTypeActionToGenerateHotel: string) => {
		if (newTypeActionToGenerateHotel) {
			setTypeActionGenerateHotels(newTypeActionToGenerateHotel as actionGenerateHotels);
		}
	};

	const handleFetchFlightInfo = (flightInfo: QuotationFlightInfoFromAutomaticRoute) => {
		const toDispatch: Air = {
			fareADT: flightInfo.fareADT,
			fareCHD: flightInfo.fareCHD,
			fareINF: flightInfo.fareINF,
			validatingCarrier: flightInfo.validatingCarrier,
			baggage: flightInfo.baggage,
			tst: '',
			carryOn: flightInfo.carryOn,
			data: flightInfo.flightInfos,
			images: [],
		};
		dispatch(addAirConfig(toDispatch));
	};

	const handleApplyFlightConfigAutomaticRoute = async () => {
		try {
			setLoading(true);
			const flightInfo = (await getFlightInfoFromAutomaticRoute(props.packageDetail.route.id, props.travelDate)).data;
			handleFetchFlightInfo(flightInfo);
			setLoading(false);
		} catch (e) {
			if (e.response.status !== 404) {
				handleOnError('Ocurrió un error al obtener la información de vuelos');
			} else {
				setLoading(false);
				handleOnError('No se encontraron vuelos acordes a las condiciones de la ruta');
			}
		}
	};
	const handleApplyFlightConfigHitGDS = async () => {
		try {
			setLoading(true);
			const flightInfo = (await getFlightInfoFromGDS(props.packageDetail.route.id, props.travelDate)).data;
			handleFetchFlightInfo(flightInfo);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			if (e.response.status !== 404) {
				handleOnError('Ocurrió un error al obtener la información de vuelos');
			} else {
				handleOnError('No se encontraron vuelos acordes a las condiciones de la ruta');
				setNotFoundFlightInfoFromGDS(true);
			}
		}
	};

	const handleApplyFlightConfigFromTST = () => {
		if (!showModalFlightConfigFromTST) {
			setShowModalFlightConfigFromTST(true);
		}
	};

	const handleApplyFlightConfig = () => {
		dispatch(deleteAirConfig(0));
		switch (typeActionGenerateFlight) {
			case 'automaticRoute':
				handleApplyFlightConfigAutomaticRoute();
				break;
			case 'hitgds':
				handleApplyFlightConfigHitGDS();
				break;
			case 'tst':
				handleApplyFlightConfigFromTST();
				break;
		}
	};

	const loadHotelsMostQuotedByDestination = async () => {
		try {
			const mapDestinationCodeWithHotel = new Map<string, IHotelMostQuoted[]>();

			await Promise.all(
				props.packageDetail.destinations.map(async (x) => {
					const hotelsMostQuoted = (await getHotelMostQuotedByDestination(x.code)).data;
					mapDestinationCodeWithHotel.set(x.code, hotelsMostQuoted);
				}),
			);

			setHotelsMostQuotedByDestination(mapDestinationCodeWithHotel);
		} catch (e) {
			handleOnError('Ocurrió un error al obtener la información de hoteles más cotizados');
		}
	};

	const isValidAccommodationFaresRoomPriceByCustomRateSelected = (
		accommodationFaresRoomPrice: AccommodationFaresRoomPrice,
	): boolean => {
		if (customRateSelected === '' || typeActionGenerateHotels === 'saveHotelFares') {
			return true;
		}
		const customRateFromCancellationPolice = findCustomRate(accommodationFaresRoomPrice.cancellationPolicy);
		return customRateFromCancellationPolice?.code == customRateSelected;
	};

	const createHotelOptionFromHotelBedRequest = async (
		destination: Destination,
		hotelId: number,
	): Promise<HotelOptions | null> => {
		try {
			const requestHotelFare = createHotelFareRequestBase(roomBaseConfig, hotelId, destination);
			const hotelFaresResponse = (await getHotelFares(requestHotelFare)).data;

			const roomFares: RoomFare[] = [];
			roomBaseConfig.forEach((room) => {
				const quotationRoomDistributionFaresForRoom = {
					adults: room.adults,
					childrenAges: (room.childs || []).concat(room.infants || []),
				} as QuotationRoomDistributionFaresRequest;
				const faresByDistribution = getAccommodationFaresByDistribution(
					hotelFaresResponse.faresByDistribution,
					requestHotelFare.distributions,
				);
				const faresByRoom: AccommodationFaresByRoom[] =
					faresByDistribution.find((fareByDistribution) =>
						compareDistributionAndRoomDistribution(quotationRoomDistributionFaresForRoom, fareByDistribution),
					)?.faresByRoom || [];
				let allFaresByRoom: AccommodationFaresRoomPrice[] = [];
				faresByRoom.forEach((fareByRoom) => {
					allFaresByRoom = allFaresByRoom.concat(fareByRoom.fares);
				});

				const allFareByRoomsApplyBoards = allFaresByRoom.filter(
					(fareByRoom) =>
						customBoardsSelected.some((boardCode) => boardCode == findCustomBoard(fareByRoom.board)?.code) &&
						isValidAccommodationFaresRoomPriceByCustomRateSelected(fareByRoom),
				);

				const faresByRoomApplyBoardsSortedByPrice = allFareByRoomsApplyBoards.sort((n1, n2) => n1.fare - n2.fare);
				if (faresByRoomApplyBoardsSortedByPrice.length > 0) {
					const fareForRoom = faresByRoomApplyBoardsSortedByPrice[0];
					const customBoard = findCustomBoard(fareForRoom.board);
					const customRate = findCustomRate(fareForRoom.cancellationPolicy);
					roomFares.push({
						price: fareForRoom.fare,
						board: (customBoard ? customBoard.code : '') as roomServiceType,
						rateType: (customRate ? customRate.code : '') as roomRateType,
					} as RoomFare as RoomFare);
				}
			});

			if (roomFares.length === roomBaseConfig.length) {
				const hotelDetail = (await getHotelDetail(hotelId, 'Hotelbeds')).data;
				return {
					hotelId: hotelId,
					hotelName: hotelDetail.name,
					chdCharged: 0,
					infCharged: 0,
					provider: 'Hotelbeds',
					rooms: roomFares,
					details: hotelDetail,
				} as HotelOptions;
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	};

	const createHotelOptionFromSavedTariff = async (
		hotel: IHotelsInfoForPackageCompositeDetailPower,
	): Promise<HotelOptions | null> => {
		try {
			// se multiplica por roomConfig.adults el precio porque el precio de la tarifa es por persona en base doble solo se va a usar las tarifas almacenadas si la room tiene 2 personas
			const roomFares: RoomFare[] = roomBaseConfig.map((roomConfig) => {
				return {
					price: hotel.totalCost * roomConfig.adults,
					board: hotel.board,
					rateType: 'nonRefundable' as roomRateType,
				} as RoomFare;
			});
			const hotelDetail = (await getHotelDetail(hotel.externalId, hotel.provider)).data;
			return {
				hotelId: hotel.externalId,
				hotelName: hotelDetail.name,
				chdCharged: 0,
				infCharged: 0,
				provider: hotel.provider,
				rooms: roomFares,
				details: hotelDetail,
			} as HotelOptions;
		} catch (e) {
			return null;
		}
	};

	const minPriceInRooms = (rooms: RoomFare[]): number => {
		if (rooms.length === 0) return 0;
		return rooms.reduce((min, room) => (room.price < min ? room.price : min), rooms[0].price);
	};

	const handleApplyHotelHitBroker = async () => {
		setLoading(true);
		for (const destinationItem of destination) {
			const indexDest = destination.indexOf(destinationItem);
			const hotelsToGetPrice = new Set<number>();
			props.packageDetail.destinations[indexDest].hotels.forEach((hotel) => {
				if (hotel.provider === 'Hotelbeds') hotelsToGetPrice.add(hotel.externalId);
			});
			const maybeHotelsByDestinations = hotelsMostQuotedByDestination.get(destinationItem.cityCode);
			if (maybeHotelsByDestinations) {
				const hotelToGetFares = maybeHotelsByDestinations.filter(
					(hotelMostQuoted) => hotelMostQuoted.hotelProvider === 'Hotelbeds',
				);
				hotelToGetFares.forEach((hotelMostQuoted) => {
					hotelsToGetPrice.add(hotelMostQuoted.hotelId);
				});
			}

			const hotelOptions = (
				await Promise.all(
					Array.from(hotelsToGetPrice).map(async (hotelId) => {
						return await createHotelOptionFromHotelBedRequest(destinationItem, hotelId);
					}),
				)
			).filter((hotelOption) => hotelOption !== null);

			const hotelsOptionsSorted = hotelOptions
				.slice(0, maxHotelOptionByDestination)
				// @ts-ignore
				.sort((a, b) => minPriceInRooms(a.rooms) - minPriceInRooms(b.rooms));

			dispatch(
				setHotelOptionsToDestination({
					destinationIndex: indexDest,
					data: hotelsOptionsSorted as HotelOptions[],
				}),
			);
		}
		setLoading(false);
	};

	const handleApplySaveHotelFare = async () => {
		setLoading(true);
		for (const destinationItem of destination) {
			const indexDest = destination.indexOf(destinationItem);
			let hotelOptions: HotelOptions[] = [];
			const hotelForDestinationWithValidBoards = props.packageDetail.destinations[indexDest].hotels.filter((x) =>
				customBoardsSelected.some((boardCode) => boardCode == x.board),
			);
			const hotelOptionsFromPackageHotels: (HotelOptions | null)[] = (
				await Promise.all(
					hotelForDestinationWithValidBoards.map(async (hotel) => {
						return await createHotelOptionFromSavedTariff(hotel);
					}),
				)
			).filter((hotelOption) => hotelOption !== null);
			// @ts-ignore
			hotelOptions = hotelOptions.concat(hotelOptionsFromPackageHotels);

			const maybeHotelsByDestinations = hotelsMostQuotedByDestination.get(destinationItem.cityCode);
			if (maybeHotelsByDestinations) {
				const hotelToGetFares = maybeHotelsByDestinations.filter(
					(hotelMostQuoted) =>
						hotelMostQuoted.hotelProvider === 'Hotelbeds' &&
						!hotelOptions.some(
							(x) => x.hotelId == hotelMostQuoted.hotelId && x.provider == hotelMostQuoted.hotelProvider,
						),
				);
				const hotelOptionsFromRequestHotelBed: (HotelOptions | null)[] = (
					await Promise.all(
						hotelToGetFares.map(async (hotel) => {
							return await createHotelOptionFromHotelBedRequest(destinationItem, hotel.hotelId);
						}),
					)
				).filter((hotelOption) => hotelOption !== null);
				// @ts-ignore
				hotelOptions = hotelOptions.concat(hotelOptionsFromRequestHotelBed);
			}
			const hotelsOptionsSorted = hotelOptions
				.slice(0, maxHotelOptionByDestination)
				.sort((a, b) => minPriceInRooms(a.rooms) - minPriceInRooms(b.rooms));

			dispatch(
				setHotelOptionsToDestination({
					destinationIndex: indexDest,
					data: hotelsOptionsSorted as HotelOptions[],
				}),
			);
		}
		setLoading(false);
	};

	const handleApplyHotelConfig = async () => {
		switch (typeActionGenerateHotels) {
			case 'hitBrokers':
				await handleApplyHotelHitBroker();
				break;
			case 'saveHotelFares':
				await handleApplySaveHotelFare();
				break;
		}
	};

	const createDestinations = () => {
		const destinationsToAdd: Destination[] = [];
		const totalNightsFirstDestinations = props.packageDetail.destinations[0].hotels[0].totalNights;
		props.packageDetail.destinations.forEach((destinationInfo, index) => {
			const servicesInDestination = props.packageDetail.services.filter(
				(service) =>
					(service.forAllDestinations || service.destinationCodes.some((x) => x == destinationInfo.code)) &&
					service.priceDbl != null,
			);
			const quotationServiceForDestination: Service[] = servicesInDestination.map((service) => {
				return {
					fareCost: service.priceDbl,
					serviceType: 'perPassenger',
					name: service.name,
					description: service.observations || '',
					chdCharged: 1,
					infCharged: 1,
				} as Service;
			});
			if (index === 0) {
				destinationsToAdd.push({
					checking: props.travelDate,
					cityCode: destinationInfo.code,
					cityName: `${destinationInfo.code}-${destinationInfo.name}`,
					nights: totalNightsFirstDestinations,
					services: quotationServiceForDestination,
					hotelOptions: [] as HotelOptions[],
					optionalServices: [] as OptionalService[],
				});
			} else {
				const checkingDateInBeforeDestination = destinationsToAdd[index - 1].checking;
				const nightsInDestination = destinationInfo.hotels[0].totalNights;
				const nightsInBeforeDestination = destinationsToAdd[index - 1].nights;
				const checkInDateInDestination = moment(checkingDateInBeforeDestination, 'YYYY-MM-DD')
					.add(nightsInBeforeDestination, 'days')
					.format('YYYY-MM-DD');

				destinationsToAdd.push({
					checking: checkInDateInDestination,
					cityCode: destinationInfo.code,
					cityName: `${destinationInfo.code}-${destinationInfo.name}`,
					nights: nightsInDestination,
					services: quotationServiceForDestination,
					hotelOptions: [] as HotelOptions[],
					optionalServices: [] as OptionalService[],
				});
			}
		});

		return destinationsToAdd;
	};

	const allRoomsAreDouble = () =>
		roomBaseConfig.every((room) => room.adults == 2 && room.childs.length === 0 && room.infants.length === 0);

	const existOneDestinationWithHotels = () =>
		quotation.destination.some((destination) => destination.hotelOptions.length > 0);
	const allDestinationHasHotels = () =>
		quotation.destination.every((destination) => destination.hotelOptions.length > 0);

	const isValidQuotationData = () => {
		const isDestinationCreated = quotation.destination.length == props.packageDetail.destinations.length;
		const hasAirData = airs.length > 0 && airs[0].data && airs[0].data.length > 0;
		return isDestinationCreated && hasAirData && existOneDestinationWithHotels();
	};
	const destinationNameWithoutHotels = (): string[] => {
		return quotation.destination.filter((destination) => destination.hotelOptions.length == 0).map((x) => x.cityName);
	};

	const handleCancel = () => {
		dispatch(clearQuotation());
		props.onClose();
	};

	const handleCloseModalWithCreateQuotationSuccess = (quotationId: number) => {
		dispatch(clearQuotation());
		props.onSuccessCreateQuotation(quotationId);
	};

	const handleSaveQuotation = async () => {
		try {
			setLoading(true);
			const finalQuotation = {
				...quotation,
				email: quotation.email !== '' ? quotation.email : null,
			};
			const response = await postQuotation(finalQuotation);
			setLoading(false);
			handleCloseModalWithCreateQuotationSuccess(+`${response.data}`);
		} catch (e) {
			handleOnError('Ocurrió un error al crear la cotización');
			setLoading(false);
		}
	};

	const handleChangeRooms = () => {
		dispatch(removeHotelOptionsFromDestinations());
		const allRoomsAreDoubleValue = allRoomsAreDouble();
		setAllowUseTariffHotelSaved(allRoomsAreDoubleValue);
		setTypeActionGenerateHotels(allRoomsAreDoubleValue ? 'saveHotelFares' : 'hitBrokers');
	};

	const handleClose = (event: any, reason: string) => {
		if (reason !== 'backdropClick') {
			handleCancel();
		}
	};

	const handleAddOrRemoveFlights = () => {
		if (airs.length > 0 && airs[0].data) {
			dispatch(setDestinations(createDestinations()));
		} else {
			dispatch(removeAllDestinations());
		}
	};

	useEffect(() => {
		handleAddOrRemoveFlights();
	}, [airs]);

	useEffect(() => {
		loadHotelsMostQuotedByDestination();
		dispatch(setMarkup(`${props.packageDetail.markup}`));
	}, []);

	useEffect(() => {
		handleChangeRooms();
	}, [roomBaseConfig]);

	const initBoardSelectedFromBoardsFromPackage = () => {
		const boardsToSets: string[] = [];
		props.packageDetail.destinations.forEach((destination) => {
			destination.hotels.forEach((hotel) => {
				if (boardsToSets.indexOf(hotel.board) === -1) {
					boardsToSets.push(hotel.board);
				}
			});
		});
		setCustomBoardsSelected(boardsToSets);
	};

	useEffect(() => {
		initBoardSelectedFromBoardsFromPackage();
	}, []);

	const handleChangeBoards = (event: SelectChangeEvent<typeof customBoardsSelected>) => {
		const {
			target: { value },
		} = event;
		setCustomBoardsSelected(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	function getBoardSelectStyle(boardCode: string) {
		return {
			fontWeight:
				customBoardsSelected.indexOf(boardCode) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	}

	const handleChangeRates = (event: SelectChangeEvent) => {
		setCustomRateSelected(event.target.value);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Snackbar open={alert.show} autoHideDuration={3000} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
			<Dialog
				open={props.open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
				fullWidth
				maxWidth="md"
			>
				<DialogTitle id="alert-dialog-title">
					{'Crear nueva cotización a partir del paquete: ' +
						props.packageSelected.name +
						' saliendo el ' +
						formatDateToShowUser(props.travelDate)}
				</DialogTitle>
				<DialogContent>
					<GeneralConfigCompleteQuotation onErrorOnAutocompleteContact={handleOnError} showDescriptionField={false} />

					<RoomBaseConfig />

					<Container component="main" maxWidth="md" className={classes.containerComponent}>
						<Typography component="h1" variant="h5">
							Configuración de vuelos {airs.length > 0 && airs[0].data && <CheckCircle sx={{ color: 'green' }} />}
							{(airs.length == 0 || (airs.length > 0 && !airs[0].data)) && (
								<ErrorIcon
									titleAccess={
										'No se ha cargado el vuelo. Debe seleccionar una de las formas para agregar el vuelo (Usar tarifas alojadas, Hit GDS o Cargar desde TST ) y dar clic en el botón aplicar '
									}
									sx={{ color: 'red', cursor: 'pointer' }}
								/>
							)}
						</Typography>
						<div className={classes.containerComponent}>
							<ToggleButtonGroup
								color="primary"
								size={'small'}
								value={typeActionGenerateFlight}
								exclusive
								onChange={handleChangeFlightActionType}
								aria-label="Opciones de configuración de vuelos"
							>
								<ToggleButton value="automaticRoute" disabled={!allowSelectOptionTariffFromAutomaticRoute}>
									<AirplanemodeActiveIcon /> Usar tarifas alojadas
								</ToggleButton>
								<ToggleButton value="hitgds" disabled={notFoundFlightInfoFromGDS}>
									<TravelExploreIcon /> Hit GDS
								</ToggleButton>
								<ToggleButton value="tst">
									<AssignmentIcon />
									Cargar desde TST
								</ToggleButton>
							</ToggleButtonGroup>
							<ButtonGroup size={'small'}>
								<Button
									sx={{ ml: 2, height: '40px' }}
									title={'Aplicar'}
									variant="outlined"
									startIcon={<CheckIcon />}
									onClick={handleApplyFlightConfig}
								>
									{' '}
									Aplicar
								</Button>
							</ButtonGroup>
							{showModalFlightConfigFromTST && (
								<AirConfigModal
									open={showModalFlightConfigFromTST}
									handleClose={() => setShowModalFlightConfigFromTST(false)}
								/>
							)}

							{airs.length > 0 && airs[0].data && (
								<AirDisplay
									data={airs[0]}
									previewMode={false}
									index={0}
									handleDeleteFlight={() => handleClearAirConfig(0)}
								/>
							)}
						</div>
					</Container>

					{airs.length > 0 && airs[0].data && destination.length > 0 && roomBaseConfig.length > 0 && (
						<Container component="main" maxWidth="md" className={classes.containerComponent}>
							<Typography component="h1" variant="h5">
								Configuración de hoteles {allDestinationHasHotels() && <CheckCircle sx={{ color: 'green' }} />}
								{!allDestinationHasHotels() && (
									<ErrorIcon
										titleAccess={
											'No se han cargado los hoteles. Debe seleccionar una de las formas para agregar los hoteles (USAR TARIFAS ALOJADAS O HIT BROKERS) y dar clic en el botón aplicar '
										}
										sx={{ color: 'red', cursor: 'pointer' }}
									/>
								)}
								{existOneDestinationWithHotels() && destinationNameWithoutHotels().length > 0 && (
									<WarningIcon
										titleAccess={`Existen destinos que no se le pudieron cargar hotels: [${destinationNameWithoutHotels().join(
											',',
										)}]. Cuando se adicione la cotización en la vista de detalle de la misma debe de adicionarle hoteles manualmente`}
										sx={{ color: 'yellow', cursor: 'pointer' }}
									/>
								)}
							</Typography>
							<div className={classes.containerComponent}>
								<ToggleButtonGroup
									color="primary"
									size={'small'}
									value={typeActionGenerateHotels}
									exclusive
									onChange={handleChangeHotelActionType}
									aria-label="Opciones de configuración de hoteles"
								>
									<ToggleButton value="saveHotelFares" disabled={!allowUseTariffHotelSaved}>
										<HotelIcon /> Usar tarifas alojadas
									</ToggleButton>
									<ToggleButton value="hitBrokers">
										<TravelExploreIcon /> Hit Brokers
									</ToggleButton>
								</ToggleButtonGroup>
							</div>
							<div className={classes.containerComponent}>
								<FormControl required={true} sx={{ m: 1, mt: 0, width: 300 }}>
									<InputLabel>Regímenes</InputLabel>
									<Select
										multiple
										value={customBoardsSelected}
										onChange={handleChangeBoards}
										input={<OutlinedInput label="Regímenes" />}
										sx={{ height: '40px' }}
										MenuProps={SelectMenuProps}
									>
										{customBoards.map((customBoard) => (
											<MenuItem
												key={customBoard.code}
												value={customBoard.code}
												style={getBoardSelectStyle(customBoard.code)}
											>
												{customBoard.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								{typeActionGenerateHotels == 'hitBrokers' && (
									<FormControl sx={{ m: 1, mt: 0, width: 300 }}>
										<InputLabel>Tipo de tarifa</InputLabel>
										<Select
											value={customRateSelected}
											onChange={handleChangeRates}
											sx={{ height: '40px' }}
											label="Tipo de tarifa"
											MenuProps={SelectMenuProps}
										>
											<MenuItem value="">
												<em>No aplicar ninguna</em>
											</MenuItem>
											{customRates.map((customRates) => (
												<MenuItem key={customRates.code} value={customRates.code}>
													{customRates.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
								<ButtonGroup size={'small'}>
									<Button
										disabled={customBoardsSelected.length == 0}
										sx={{ ml: 2, height: '40px' }}
										title={'Aplicar'}
										variant="outlined"
										startIcon={<CheckIcon />}
										onClick={handleApplyHotelConfig}
									>
										{' '}
										Aplicar
									</Button>
								</ButtonGroup>
							</div>
						</Container>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleCancel}>
						Cancelar
					</Button>
					<Button
						variant="contained"
						color="primary"
						disabled={!isValidQuotationData()}
						onClick={handleSaveQuotation}
						autoFocus
					>
						Crear cotización
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
