import { classItems, monthsAbbrName, weekDaysNamesAbbr } from './constants';

export const isoDateToString = (date: string): string | null => {
	if (date) {
		const arrayDate = date.split('-');
		const objDate = new Date(Number(arrayDate[0]), Number(arrayDate[1]) - 1, Number(arrayDate[2]));
		// @ts-ignore
		return `${objDate.getDate()} ${monthsAbbrName[objDate.getMonth()]} ${objDate.getFullYear()}`;
	}
	return null;
};

export const timeStringWithoutSeconds = (timeString: string): string => {
	const returnTime = timeString.split(':');
	return `${returnTime[0]}:${returnTime[1]}`;
};

export const scalesToString = (totalStops: number): string => {
	if (totalStops === 0) {
		return 'Directo';
	}
	return `${totalStops} escala${totalStops > 1 ? 's' : ''}`;
};

export const isoDateToFullDate = (date: string): string | null => {
	if (date) {
		const arrayDate = date.split('-');
		const objDate = new Date(Number(arrayDate[0]), Number(arrayDate[1]) - 1, Number(arrayDate[2]));

		// @ts-ignore
		return `${weekDaysNamesAbbr[objDate.getDay()]} ${objDate.getDate()} de ${monthsAbbrName[objDate.getMonth()]}`;
	}
	return null;
};

export const getCabinName = (cabinCode: string): string => {
	const cabinItem = classItems.filter(({ id }) => id === cabinCode)[0];
	return cabinItem ? cabinItem.name : cabinCode;
};

export const getAirLineLogo = (airlineCode: string): string => `${window.location.origin}/airlines/${airlineCode}.png`;

export const getFlagsLogo = (flagsCode: string): string => `${window.location.origin}/flags/${flagsCode}.png`;
