import React from 'react';
import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Layout } from 'features/common/components/Layout';
import { HotelFareCard as Component } from 'features/hotels/components/HotelFareCard';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	paginationHolder: {
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

const cardProps = {
	title: 'Radisson Rio de Janeiro Barra',
	address: 'Barra de Tijuca, Rio de Janeiro',
	rating: 4.5,
	wifi: true,
	airConditioning: true,
	parking: true,
	gym: true,
	category: 4,
	ratingText: 'Muy Recomendado',
	link: 'about/blank',
	commentsCount: 3,
	mainImage: 'https://pix10.agoda.net/hotelImages/124/1246280/1246280_16061017110043391702.jpg?ca=6&ce=1&s=1024x768',
	pool: false,
	roomType: 'Doble Superior, AD',
	cancelation: {
		cancelable: true,
		cancelationMarkup: 35,
	},
	farePrice: {
		coinCode: 'USD',
		originalPrice: 214.26,
		promotionalPrice: 173.56,
	},
	packagingFares: true,
	description: {
		mainText:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non risus dui. Nam nulla ipsum, ullamcorper non dui non, semper mollis quam. Nullam dapibus congue nibh pellentesque tempor. Vestibulum consectetur malesuada erat, non sagittis sem gravida id. Pellentesque eget ex est. Praesent hendrerit ac mi ut lacinia. Sed vel nisi et felis imperdiet mattis. Nam non erat ac eros ultrices maximus at id neque. Proin pellentesque, lacus mollis facilisis rhoncus, felis nulla ullamcorper tortor, ac bibendum sem elit vel nisl. In varius, lacus et mattis mattis, diam nibh pellentesque nunc, non efficitur nibh orci quis ex. Nam ut quam ut est porttitor convallis.\n' +
			'\n' +
			'Etiam non fermentum neque. Ut iaculis vel ante ut dapibus. Vivamus blandit dui in cursus tempus. Suspendisse et sollicitudin risus. Aenean justo tortor, facilisis et tempor ut, dignissim vel odio. Integer iaculis massa et arcu porta pulvinar. Aenean vitae semper diam, eget gravida turpis. Praesent molestie, magna at scelerisque mattis, mi velit elementum erat, eget pharetra dui turpis ac metus. Integer in porta risus, vel egestas dolor. Proin eu hendrerit velit. Nam vulputate at massa nec hendrerit. Phasellus augue massa, facilisis vitae nibh quis, sagittis aliquam lacus. Suspendisse tincidunt purus vitae ipsum tincidunt, a elementum arcu sagittis. Nam tristique libero enim, nec dapibus magna rutrum id. Aliquam erat volutpat. Maecenas turpis mauris, tempus in accumsan eu, condimentum porta ex.',
		internet: {
			components: ['Lorem ipsum dolor sit wifi', 'Lorem ipsum dolor sit ethernet'],
		},
		services: {
			components: ['Lorem ipsum dolor sit baños'],
		},
		entertainment: {
			components: [
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
				'Lorem ipsum dolor sit tv',
			],
		},
	},
	photos: [
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/guestroom/20828-141031-f73397415_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
		'https://media.radissonhotelsamericas.com/image/radisson-hotel-barra-rio-de-janeiro/pooloutdoor/20828-141031-f63616003_3xl.jpg',
	],
	map: {
		lat: -22.906847,
		lng: -43.172897,
	},
	opinions: {
		summaryOpinion: {
			point: 3.6,
			note: 'Muy Bueno',
		},

		opinions: [
			{
				icon: 4,
				info: 'Bueno',
				user: 'Estela Novoa',
				date: 'hace 2 semanas',
				opinion: 'Desguste 8 platos uno mas rico que el otro, lo recomiendo ampliamente ',
			},
			{
				icon: 5,
				info: 'Excelente',
				user: 'JUan Perez',
				date: 'hace 3 semanas',
				opinion: 'Desguste 8 platos uno mas rico que el otro, lo recomiendo ampliamente ',
			},
			{
				icon: 5,
				info: 'Excelente',
				user: 'Ana Maria',
				date: 'hace 2 semanas',
				opinion: 'Desguste 8 platos uno mas rico que el otro, lo recomiendo ampliamente ',
			},
			{
				icon: 6,
				info: 'Muy Excelente',
				user: 'RIcardo Perez',
				date: 'hace 1 semanas',
				opinion: 'Desguste 8 platos uno mas rico que el otro, lo recomiendo ampliamente ',
			},
		],
	},
};

function HotelFareCard(): JSX.Element {
	const classes = useStyles();

	return (
		<Layout>
			<Container component="main" maxWidth="xl">
				<div className={classes.paper}>
					<Component {...cardProps} />
				</div>
			</Container>
		</Layout>
	);
}

export default HotelFareCard;
