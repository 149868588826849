import React, { useCallback, useEffect, useRef } from 'react';

interface ImageUploaderProps {
	onImageUpload: (files: File[]) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload }: ImageUploaderProps) => {
	const uploadRef = useRef<HTMLDivElement>(null);
	const onPaste = useCallback(
		(event: ClipboardEvent) => {
			if (
				(uploadRef.current && event.target === uploadRef.current) ||
				uploadRef?.current?.contains(event.target as Node)
			) {
				const items = (event.clipboardData || (event as any).originalEvent.clipboardData).items;
				const files: File[] = [];
				for (const index in items) {
					const item = items[index];
					if (item.kind === 'file') {
						const blob = item.getAsFile();
						if (blob && blob.type.startsWith('image/')) {
							files.push(new File([blob], 'clipboard-image', { type: blob.type }));
						}
					}
				}
				if (files.length > 0) {
					onImageUpload(files);
				}
			}
		},
		[onImageUpload],
	);

	useEffect(() => {
		document.addEventListener('paste', onPaste);
		return () => {
			document.removeEventListener('paste', onPaste);
		};
	}, [onPaste]);

	const handleClick = () => {
		if (uploadRef.current) {
			uploadRef.current.focus();
		}
	};

	return (
		<div ref={uploadRef} style={uploadStyles} onClick={handleClick} tabIndex={0}>
			<p>Click acá y CTRL + V para pegar las imágenes</p>
		</div>
	);
};

const uploadStyles: React.CSSProperties = {
	border: '2px dashed #cccccc',
	borderRadius: '4px',
	padding: '20px',
	textAlign: 'center',
	cursor: 'pointer',
};

export default ImageUploader;
