import { RootState } from '../../store';
import { DestinationSimple, QuotationDestinationMultipleContainer } from './types';
import {
	convertToDestinationsSimple,
	convertToQuotationDestinationMultipleContainer,
	getPAXTotalsByRoomConfig,
	GetPAXTotalsResponse,
} from './helpers';

export const getPAXTotals = (state: RootState): GetPAXTotalsResponse => {
	const {
		quotation: { roomBaseConfig },
	} = state;
	return getPAXTotalsByRoomConfig(roomBaseConfig);
};

export const getDestinationSimple = (state: RootState): DestinationSimple[] => {
	const { quotation } = state;
	return convertToDestinationsSimple(quotation);
};

export const getQuotationMultiDestinationContainer = (state: RootState): QuotationDestinationMultipleContainer => {
	const { quotation } = state;
	return convertToQuotationDestinationMultipleContainer(quotation);
};
