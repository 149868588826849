import {
	AccommodationFaresByDistribution,
	AccommodationFaresRequest,
	Air,
	AirSimple,
	Board,
	CancellationPolicy,
	CustomBoard,
	Destination,
	DestinationForCombine,
	DestinationNameAndNights,
	DestinationSimple,
	DestinationSummaryForDestinationMultiple,
	HotelOptionDetailDestinationMultiple,
	HotelOptions,
	HotelOptionsContainerForCombine,
	HotelOptionsSimple,
	IFlightFinalPriceSummary,
	IPassengerConfig,
	ManualService,
	Quotation,
	QuotationDestinationMultipleContainer,
	QuotationDestinationMultipleRoomWithPrice,
	QuotationDestinationMultipleSummaryPriceCombination,
	QuotationDestinationMultipleSummaryPriceCombinationKey,
	QuotationFlightInfo,
	QuotationOnlyFlights,
	QuotationRoomDistributionFaresRequest,
	QuotationRoomDistributionFilter,
	QuotationRoomSign,
	QuotationRoomWithPrice,
	QuotationSnapShotToClient,
	QuotationSnapShotToClientDestinationMultiple,
	QuotationSnapShotToClientOnlyFlights,
	QuotationType,
	RoomBaseConfig,
	RoomFare,
	Service,
	quotationSaleReportServiceTypes,
	QuotationSaleReportServiceTypeEnum,
	QuotationFlightImage,
} from './types';
import moment from 'moment/moment';
import routes from '../../constants/routes';
import { ManualHotelFareTotalPriceForAllNights } from '../stats/types';

export interface ServicePriceResponse {
	adtPrice: number;
	chdPrice: number;
	infPrice: number;
}

export const calculateServicePrice = (
	adtQty: number,
	chdQty: number,
	infQty: number,
	service: Service | ManualService,
): ServicePriceResponse => {
	const chdCharged = service.chdCharged;
	const infCharged = service.infCharged;
	if (service.serviceType === 'perGroup') {
		const paxToCharge: number = adtQty + chdQty * chdCharged + infQty * infCharged;
		const adtCost = Number((service.fareCost / paxToCharge).toFixed(2));
		const chdCost = Number((adtCost * chdCharged).toFixed(2));
		const infCost = Number((adtCost * infCharged).toFixed(2));
		return {
			adtPrice: adtCost,
			chdPrice: chdQty ? chdCost : 0,
			infPrice: infQty ? infCost : 0,
		};
	} else {
		return {
			adtPrice: Number(service.fareCost),
			chdPrice: chdQty ? Number(service.fareCost * chdCharged) : 0,
			infPrice: infQty ? Number(service.fareCost * infCharged) : 0,
		};
	}
};

export const cleanRoomDistributionFilters = (
	filters: QuotationRoomDistributionFilter[],
): QuotationRoomDistributionFilter[] => {
	return filters.map((filter) => {
		filter.totalChildren == null && delete filter.totalChildren;
		filter.totalInfants == null && delete filter.totalInfants;
		return filter;
	});
};

export interface TotalPerRoom {
	total: number;
	totalAdults: number;
	totalChild: number;
	totalInfant: number;
	hasChild: boolean;
	hasInfant: boolean;
}

export interface GetPAXTotalsResponse {
	adults: number;
	child: number;
	infants: number;
	totalPaxPerRoom: TotalPerRoom[];
}

export const getPAXTotalsByRoomConfig = (roomBaseConfig: RoomBaseConfig[]): GetPAXTotalsResponse => {
	let totalAdults = 0;
	let totalInfants = 0;
	let totalChilds = 0;
	const totalPaxPerRoom: TotalPerRoom[] = [];

	roomBaseConfig.forEach((room) => {
		totalAdults = totalAdults + room.adults;
		totalChilds = totalChilds + room.childs.length;
		totalInfants = totalInfants + room.infants.length;

		const totalPerRoom = {
			totalAdults: room.adults,
			totalChild: room.childs.length,
			totalInfant: room.infants.length,
			total: room.adults + room.infants.length + room.childs.length,
			hasChild: !!room.childs.length,
			hasInfant: !!room.infants.length,
		};
		totalPaxPerRoom.push(totalPerRoom);
	});

	return {
		adults: totalAdults,
		child: totalChilds,
		infants: totalInfants,
		totalPaxPerRoom,
	};
};

interface RoomCostResponse {
	adtCost: number;
	chdCost: number;
	infCost: number;
}

export const calculateRoomCostByPax = (
	roomCost: number,
	adtQty: number,
	chdQty: number,
	infQty: number,
	chdCharged: number,
	infCharged: number,
): RoomCostResponse => {
	const paxToCharge: number = adtQty + chdQty * chdCharged + infQty * infCharged;
	const adtCost = Number((roomCost / paxToCharge).toFixed(2));
	const chdCost = Number((adtCost * chdCharged).toFixed(2));
	const infCost = Number((adtCost * infCharged).toFixed(2));
	return {
		adtCost,
		chdCost,
		infCost,
	};
};

export const totalServicePricePerPassengerType = (
	adtQty: number,
	chdQty: number,
	infQty: number,
	services: Service[] | ManualService[],
): ServicePriceResponse => {
	const toReturn = {
		adtPrice: 0,
		chdPrice: 0,
		infPrice: 0,
	};

	services.forEach((serviceRow) => {
		const result = calculateServicePrice(adtQty, chdQty, infQty, serviceRow);
		toReturn.adtPrice = toReturn.adtPrice + Number(result.adtPrice);
		toReturn.chdPrice = toReturn.chdPrice + Number(result.chdPrice);
		toReturn.infPrice = toReturn.infPrice + Number(result.infPrice);
	});

	return toReturn;
};

export const hasCHD = (quotation: Quotation, roomIndex: number): boolean => {
	const result = quotation.roomBaseConfig[roomIndex];
	if (!result) return false;
	return result.childs.length > 0;
};

export const hasINF = (quotation: Quotation, roomIndex: number): boolean => {
	const result = quotation.roomBaseConfig[roomIndex];
	if (!result) return false;
	return result.infants.length > 0;
};

export const passengersPerRoom = (
	quotation: Quotation,
	roomIndex: number,
): {
	countAdt: number;
	countChd: number;
	countInf: number;
} => {
	const result = quotation.roomBaseConfig[roomIndex];
	if (!result)
		return {
			countAdt: 0,
			countChd: 0,
			countInf: 0,
		};
	return {
		countAdt: result.adults,
		countChd: result.childs.length,
		countInf: result.infants.length,
	};
};

export const applyMarkup = (value: number, markup: number): number => Math.ceil(value / (1 - markup * 0.01));

//export const monthNumber = (value: string): string => value.substring(5, 7);
export const dayNumber = (value: string): string => value.substring(8, 10);

export const monthNumber = (value: string): string | ErrorConstructor => {
	if (value.substring(5, 7) === '01') return 'ENE';
	else if (value.substring(5, 7) === '02') return 'FEB';
	else if (value.substring(5, 7) === '03') return 'MAR';
	else if (value.substring(5, 7) === '04') return 'ABR';
	else if (value.substring(5, 7) === '05') return 'MAY';
	else if (value.substring(5, 7) === '06') return 'JUN';
	else if (value.substring(5, 7) === '07') return 'JUL';
	else if (value.substring(5, 7) === '08') return 'AGO';
	else if (value.substring(5, 7) === '09') return 'SET';
	else if (value.substring(5, 7) === '10') return 'OCT';
	else if (value.substring(5, 7) === '11') return 'NOV';
	else if (value.substring(5, 7) === '12') return 'DIC';
	else return Error;
};

export const roomToString = (room: QuotationRoomDistributionFaresRequest): string => {
	const adults = `${room.adults} ${room.adults > 1 ? 'Adultos' : 'Adulto'}`;

	const children = room.childrenAges.length
		? `, ${room.childrenAges.length} ${room.childrenAges.length > 1 ? 'Menores' : 'Menor'} (${room.childrenAges.join(
				', ',
		  )})`
		: '';

	return `${adults}${children}`;
};

export const secondsToMilis = (seconds: number): number => seconds * 1000;
export const minutesToMilis = (minutes: number): number => secondsToMilis(minutes * 60);
export const hoursToMilis = (hours: number): number => minutesToMilis(hours * 60);
export const daysToMilis = (days: number): number => hoursToMilis(days * 24);

export const cacheTimes = {
	'5min': minutesToMilis(5),
	'30m': minutesToMilis(30),
	'1h': hoursToMilis(1),
	'6h': hoursToMilis(6),
	'1d': daysToMilis(1),
	'7d': daysToMilis(7),
} as const;

const onlyRoomBoard: CustomBoard = { code: 'onlyRoom', name: 'Solo habitación' };
const breakfastBoard: CustomBoard = { code: 'breakfast', name: 'Desayuno' };
const allInclusiveBoard: CustomBoard = { code: 'allInclusive', name: 'Todo incluido' };
const fullPensionBoard: CustomBoard = { code: 'fullPension', name: 'Pensión completa' };
const halfPensionBoard: CustomBoard = { code: 'halfPension', name: 'Media pensión' };

export const customBoards: CustomBoard[] = [
	onlyRoomBoard,
	breakfastBoard,
	allInclusiveBoard,
	fullPensionBoard,
	halfPensionBoard,
];

interface BoardsRelated {
	hotelBedsBoards: Board[];
	customBoard: CustomBoard;
}

export const getBoardName = (boardCode: string): string => {
	const customBoard = customBoards.find((x) => x.code === boardCode);
	return customBoard ? customBoard.name : '';
};

const boardsRelated: BoardsRelated[] = [
	{
		hotelBedsBoards: [
			{
				code: 'RO',
				name: 'SOLO HABITACIÓN',
			},
			{
				code: 'SC',
				name: 'SOLO ALOJAMIENTO',
			},
		],
		customBoard: onlyRoomBoard,
	},
	{
		hotelBedsBoards: [
			{
				code: 'AB',
				name: 'DESAYUNO AMERICANO',
			},
			{
				code: 'B1',
				name: 'Desayuno 1 pax',
			},
			{
				code: 'B2',
				name: 'Desayuno 2 pax',
			},
			{
				code: 'BB',
				name: 'ALOJAMIENTO Y DESAYUNO',
			},
			{
				code: 'CB',
				name: 'DESAYUNO CONTINENTAL',
			},
			{
				code: 'DB',
				name: 'DESAYUNO BUFFET',
			},
			{
				code: 'GB',
				name: 'DESAYUNO INGLES',
			},
			{
				code: 'IB',
				name: 'DESAYUNO IRLANDES',
			},
			{
				code: 'LB',
				name: 'DESAYUNO LIGERO',
			},
			{
				code: 'QB',
				name: 'DISNEY QUICK SERVICE BREAKFAST',
			},
			{
				code: 'SB',
				name: 'DESAYUNO ESCOCES',
			},
		],
		customBoard: breakfastBoard,
	},
	{
		hotelBedsBoards: [
			{
				code: 'AI',
				name: 'TODO INCLUIDO',
			},
			{
				code: 'AS',
				name: 'Todo incluido premium',
			},
			{
				code: 'TL',
				name: 'TODO INCLUIDO SOFT',
			},
		],
		customBoard: allInclusiveBoard,
	},
	{
		hotelBedsBoards: [
			{
				code: 'FB',
				name: 'PENSION COMPLETA',
			},
			{
				code: 'FL',
				name: 'DISNEY PENSIÓN COMPLETA PLUS',
			},
			{
				code: 'FR',
				name: 'DISNEY PENSIÓN COMPLETA PREMIUM: + 20 RESTAURANTES',
			},
			{
				code: 'FS',
				name: 'DISNEY PENSIÓN COMPLETA STANDARD',
			},
			{
				code: 'PB',
				name: 'PENSION COMPLETA CON BEBIDAS INCLUIDAS',
			},
		],
		customBoard: fullPensionBoard,
	},
	{
		hotelBedsBoards: [
			{
				code: 'BH',
				name: '1 ALOJAMIENTO Y DESAYUNO + 1 MEDIA PENSION',
			},
			{
				code: 'HB',
				name: 'MEDIA PENSION',
			},
			{
				code: 'HL',
				name: 'DISNEY MEDIA PENSIÓN PLUS',
			},
			{
				code: 'HR',
				name: 'DISNEY MEDIA PENSIÓN PREMIUM: + 20 RESTAURANTES',
			},
			{
				code: 'HS',
				name: 'DISNEY MEDIA PENSIÓN STANDARD',
			},
			{
				code: 'MB',
				name: 'MEDIA PENSION CON BEBIDAS INCLUIDAS',
			},
			{
				code: 'CE',
				name: 'CENA INCLUIDA',
			},
			{
				code: 'DD',
				name: 'DISNEY DINING',
			},
			{
				code: 'DX',
				name: 'DISNEY DELUXE DINING',
			},
			{
				code: 'FH',
				name: '1 MEDIA PENSION + 1 PENSION COMPLETA',
			},
			{
				code: 'HH',
				name: 'DISNEY MP HOTEL: CENA EN HOTEL',
			},
			{
				code: 'QM',
				name: 'DISNEY QUICK SERVICE MEAL',
			},
			{
				code: 'QS',
				name: 'DISNEY QUICK SERVICE',
			},
		],
		customBoard: halfPensionBoard,
	},
];

export interface CustomRate {
	code: string;
	name: string;
}

const notRefundableRate: CustomRate = { code: 'nonRefundable', name: 'No reembolsable' };
const refundableRate: CustomRate = { code: 'refundable', name: 'Reembolsable' };

export const customRates: CustomRate[] = [notRefundableRate, refundableRate];

export const findCustomBoard = (hotelBedsBoard: Board): CustomBoard | null => {
	const boardRelated = boardsRelated.find(
		(x) => x.hotelBedsBoards.find((b) => b.code === hotelBedsBoard.code) != undefined,
	);
	return boardRelated ? boardRelated.customBoard : null;
};

export const findCustomRate = (hotelBedsCancellationPolicy: CancellationPolicy): CustomRate | null => {
	switch (hotelBedsCancellationPolicy) {
		case 'CLX':
			return refundableRate;
		case 'NRF':
			return notRefundableRate;
		default:
			return null;
	}
};

export const isValidDate = (d: Date): boolean =>
	Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d.getTime());
export const isValidDateAndYearMajorOrEqual1900 = (d: Date): boolean => {
	const valid = isValidDate(d);
	if (valid) {
		const year = d.toISOString().split('T')[0].substring(0, 4);
		return Number(year) >= 1900;
	}
	return false;
};

const convertToServicesSimple = (value: ManualService[] | Service[]) =>
	value.map((val) => ({
		name: val.name,
		description: val.description ? val.description : '',
	}));

const createFlightSummaryPriceForQuotationOnlyFlights = (
	air: Air,
	passengerConfig: IPassengerConfig,
	markup: number,
): IFlightFinalPriceSummary => {
	const flightAdtPrice = Number(air.fareADT);
	const flightChdPrice = Number(air.fareCHD);
	const flightInfPrice = Number(air.fareINF);
	const totalChild = passengerConfig.childrenAges.length;
	const totalInfants = passengerConfig.infantAges.length;
	const adtPrice = applyMarkup(flightAdtPrice, markup);
	const chdPrice = totalChild > 0 ? applyMarkup(flightChdPrice, markup) : 0;
	const infPrice = totalInfants > 0 ? applyMarkup(flightInfPrice, markup) : 0;
	const totalPriceWithMarkup =
		Number(adtPrice) * passengerConfig.adults + Number(chdPrice) * totalChild + Number(infPrice) * totalInfants;

	return {
		adtPrice: adtPrice,
		chdPrice: chdPrice,
		infPrice: infPrice,
		totalPrice: totalPriceWithMarkup,
	} as IFlightFinalPriceSummary;
};

export const createFlightSummariesPriceForQuotationOnlyFlights = (
	q: QuotationOnlyFlights,
): IFlightFinalPriceSummary[] => {
	if (q.passengerConfig != null && q.airs.length > 0) {
		/* eslint-disable  @typescript-eslint/no-non-null-assertion */
		return q.airs.map((air: Air) => createFlightSummaryPriceForQuotationOnlyFlights(air, q.passengerConfig!, q.markup));
	}
	return [];
};

export const convertToDestinationsSimple = (q: Quotation): DestinationSimple[] => {
	const paxTotals = getPAXTotalsByRoomConfig(q.roomBaseConfig);
	const manualServicePrices = totalServicePricePerPassengerType(
		paxTotals.adults,
		paxTotals.child,
		paxTotals.infants,
		q.manualServices,
	);
	const convertToHotelOptionSimple = (
		option: HotelOptions,
		servicePrices: ServicePriceResponse,
		airIndex: number | null,
	): HotelOptionsSimple => {
		return {
			hotelId: option.hotelId,
			provider: option.provider,
			hotelName: option.hotelName,
			rooms: option.rooms.map((roomFare, index) =>
				convertRoomFareToQuotationRoomWithPrice(option, index, servicePrices, airIndex),
			),
			details: option.details,
			...(airIndex != null && { flightIndex: airIndex }),
		};
	};

	const convertRoomFareToQuotationRoomWithPrice = (
		option: HotelOptions,
		optionItemIndex: number,
		servicePrices: ServicePriceResponse,
		airIndex: number | null,
	): QuotationRoomWithPrice => {
		const roomFare = option.rooms[optionItemIndex];
		const paxCountInRoom = passengersPerRoom(q, optionItemIndex);
		const totalAdult = paxCountInRoom.countAdt;
		const totalChild = paxCountInRoom.countChd;
		const totalInfant = paxCountInRoom.countInf;
		const roomCostByPax = calculateRoomCostByPax(
			roomFare.price,
			totalAdult,
			totalChild,
			totalInfant,
			option.chdCharged,
			option.infCharged,
		);

		const hotelAdtPrice = roomCostByPax.adtCost;
		const hotelChdPrice = paxTotals.totalPaxPerRoom[optionItemIndex].hasChild && roomCostByPax.chdCost;
		const hotelInfPrice = paxTotals.totalPaxPerRoom[optionItemIndex].hasInfant && roomCostByPax.infCost;
		const air = airIndex != null ? q.airs[airIndex] : null;
		const flightAdtPrice = air?.fareADT ? Number(air?.fareADT) : 0;
		const flightChdPrice = air?.fareCHD ? Number(air?.fareCHD) : 0;
		const flightInfPrice = air?.fareINF ? Number(air?.fareINF) : 0;

		const adtPrice = applyMarkup(
			Number(hotelAdtPrice) + Number(servicePrices.adtPrice) + Number(manualServicePrices.adtPrice) + flightAdtPrice,
			q.markup,
		);
		const chdPrice = hasCHD(q, optionItemIndex)
			? applyMarkup(
					Number(hotelChdPrice) +
						Number(servicePrices.chdPrice) +
						Number(manualServicePrices.chdPrice) +
						flightChdPrice,
					q.markup,
			  )
			: 0;
		const infPrice = hasINF(q, optionItemIndex)
			? applyMarkup(
					Number(hotelInfPrice) +
						Number(servicePrices.infPrice) +
						Number(manualServicePrices.infPrice) +
						flightInfPrice,
					q.markup,
			  )
			: 0;
		const total =
			Number(adtPrice) * paxCountInRoom.countAdt +
			Number(chdPrice) * paxCountInRoom.countChd +
			Number(infPrice) * paxCountInRoom.countInf;

		const getRoomSign = (): QuotationRoomSign | null => {
			if (q.sign) {
				const adtSign = flightAdtPrice + calculateUtility(adtPrice, q.markup);
				const chdSign = hasCHD(q, optionItemIndex) ? flightChdPrice + calculateUtility(Number(chdPrice), q.markup) : 0;
				const infSign = hasINF(q, optionItemIndex) ? flightInfPrice + calculateUtility(Number(infPrice), q.markup) : 0;
				const totalSign =
					flightAdtPrice * paxCountInRoom.countAdt +
					flightChdPrice * paxCountInRoom.countChd +
					flightInfPrice * paxCountInRoom.countInf +
					calculateUtility(total, q.markup);
				return {
					adtSign: adtSign,
					chdSign: chdSign,
					infSign: infSign,
					totalSign: totalSign,
				};
			} else return null;
		};

		return {
			board: roomFare.board,
			rateType: roomFare.rateType,
			adtPrice: adtPrice,
			chdPrice: chdPrice,
			infPrice: infPrice,
			totalPrice: total,
			description: roomFare.description,
			sign: getRoomSign(),
		};
	};

	return q.destination.map((dest) => {
		const servicesSimples = convertToServicesSimple(dest.services);
		const servicePrices = totalServicePricePerPassengerType(
			paxTotals.adults,
			paxTotals.child,
			paxTotals.infants,
			dest.services,
		);
		const hotelOptions = () => {
			if (q.airs.length > 0) {
				let toReturn: HotelOptionsSimple[] = [];
				q.airs.forEach(
					(air, index) =>
						(toReturn = toReturn.concat(
							dest.hotelOptions.map((hotelOption) => convertToHotelOptionSimple(hotelOption, servicePrices, index)),
						)),
				);
				return toReturn;
			} else {
				return dest.hotelOptions.map((hotelOption) => convertToHotelOptionSimple(hotelOption, servicePrices, null));
			}
		};
		return {
			cityCode: dest.cityCode,
			cityName: dest.cityName,
			nights: dest.nights,
			checking: String(dest.checking),
			services: servicesSimples,
			hotelOptions: hotelOptions(),
			optionalServices: dest.optionalServices,
		};
	});
};

const allHotelContainerOptionCombinations = (
	arrayData: HotelOptionsContainerForCombine[][],
): HotelOptionsContainerForCombine[][] => {
	const r: HotelOptionsContainerForCombine[][] = [],
		max = arrayData.length - 1;

	function helper(arr: HotelOptionsContainerForCombine[], i: number) {
		for (let j = 0, l = arrayData[i].length; j < l; j++) {
			const a = arr.slice(0); // clone arr
			a.push(arrayData[i][j]);
			if (i == max) r.push(a);
			else helper(a, i + 1);
		}
	}

	helper([], 0);
	return r;
};

export const createBoardsKey = (rooms: RoomFare[]): string => rooms.map((x) => x.board).join(',');

export const convertToQuotationDestinationMultipleContainer = (q: Quotation): QuotationDestinationMultipleContainer => {
	const paxTotals = getPAXTotalsByRoomConfig(q.roomBaseConfig);
	const manualServicePrices = totalServicePricePerPassengerType(
		paxTotals.adults,
		paxTotals.child,
		paxTotals.infants,
		q.manualServices,
	);

	const getQuotationMultipleRoomWithPrice = (
		combination: HotelOptionsContainerForCombine[],
		airIndex: number | null,
	): QuotationDestinationMultipleRoomWithPrice[] => {
		const totalRoom = combination[0].hotelOption.rooms.length;
		const result = [];
		const air = airIndex != null ? q.airs[airIndex] : null;
		const flightAdtPrice = air?.fareADT ? Number(air?.fareADT) : 0;
		const flightChdPrice = air?.fareCHD ? Number(air?.fareCHD) : 0;
		const flightInfPrice = air?.fareINF ? Number(air?.fareINF) : 0;

		const servicePrices = combination.map((x) => x.destination.servicesPrice);
		const totalServicePriceAdt = servicePrices.map((x) => x.adtPrice).reduce((a, b) => a + b, 0);
		const totalServicePriceChild = servicePrices.map((x) => x.chdPrice).reduce((a, b) => a + b, 0);
		const totalServicePriceInfant = servicePrices.map((x) => x.infPrice).reduce((a, b) => a + b, 0);

		for (let indexRoom = 0; indexRoom < totalRoom; indexRoom++) {
			let totalHotelAdtPrice = 0;
			let totalHotelChildPrice = 0;
			let totalHotelInfantPrice = 0;
			const paxCountInRoom = passengersPerRoom(q, indexRoom);
			const roomServiceTypes = new Set();
			const rateTypes = new Set();

			for (let indexHotel = 0; indexHotel < combination.length; indexHotel++) {
				const hotelOption = combination[indexHotel].hotelOption;
				const roomFare = hotelOption.rooms[indexRoom];
				const paxCountInRoom = passengersPerRoom(q, indexRoom);
				const totalAdult = paxCountInRoom.countAdt;
				const totalChild = paxCountInRoom.countChd;
				const totalInfant = paxCountInRoom.countInf;
				const roomCostByPax = calculateRoomCostByPax(
					roomFare.price,
					totalAdult,
					totalChild,
					totalInfant,
					hotelOption.chdCharged,
					hotelOption.infCharged,
				);

				totalHotelAdtPrice = totalHotelAdtPrice + roomCostByPax.adtCost;
				if (paxTotals.totalPaxPerRoom[indexRoom].hasChild)
					totalHotelChildPrice = totalHotelChildPrice + roomCostByPax.chdCost;
				if (paxTotals.totalPaxPerRoom[indexRoom].hasInfant)
					totalHotelInfantPrice = totalHotelInfantPrice + roomCostByPax.infCost;
				roomServiceTypes.add(roomFare.board);
				rateTypes.add(roomFare.rateType);
			}

			const adtPrice = applyMarkup(
				Number(totalHotelAdtPrice) +
					Number(totalServicePriceAdt) +
					Number(manualServicePrices.adtPrice) +
					flightAdtPrice,
				q.markup,
			);

			const chdPrice = hasCHD(q, indexRoom)
				? applyMarkup(
						Number(totalHotelChildPrice) +
							Number(totalServicePriceChild) +
							Number(manualServicePrices.chdPrice) +
							flightChdPrice,
						q.markup,
				  )
				: 0;
			const infPrice = hasINF(q, indexRoom)
				? applyMarkup(
						Number(totalHotelInfantPrice) +
							Number(totalServicePriceInfant) +
							Number(manualServicePrices.infPrice) +
							flightInfPrice,
						q.markup,
				  )
				: 0;
			const total =
				Number(adtPrice) * paxCountInRoom.countAdt +
				Number(chdPrice) * paxCountInRoom.countChd +
				Number(infPrice) * paxCountInRoom.countInf;
			const getRoomSign = (): QuotationRoomSign | null => {
				if (q.sign) {
					const adtSign = flightAdtPrice + calculateUtility(adtPrice, q.markup);
					const chdSign = hasCHD(q, indexRoom) ? flightChdPrice + calculateUtility(Number(chdPrice), q.markup) : 0;
					const infSign = hasINF(q, indexRoom) ? flightInfPrice + calculateUtility(Number(infPrice), q.markup) : 0;
					const totalSign =
						flightAdtPrice * paxCountInRoom.countAdt +
						flightChdPrice * paxCountInRoom.countChd +
						flightInfPrice * paxCountInRoom.countInf +
						calculateUtility(total, q.markup);
					return {
						adtSign: adtSign,
						chdSign: chdSign,
						infSign: infSign,
						totalSign: totalSign,
					};
				} else return null;
			};

			const priceSummaryForRooms = {
				boards: Array.from(roomServiceTypes),
				rateTypes: Array.from(rateTypes),
				adtPrice: adtPrice,
				chdPrice: chdPrice,
				infPrice: infPrice,
				totalPrice: total,
				sign: getRoomSign(),
			} as QuotationDestinationMultipleRoomWithPrice;
			result.push(priceSummaryForRooms);
		}

		return result;
	};

	const hotelOptionsContainerToCombine = q.destination.map((dest, destinationIndex) => {
		const servicesSimples = convertToServicesSimple(dest.services);
		const servicePrices = totalServicePricePerPassengerType(
			paxTotals.adults,
			paxTotals.child,
			paxTotals.infants,
			dest.services,
		);

		const destinationToCombine = {
			cityCode: dest.cityCode,
			cityName: dest.cityName,
			nights: dest.nights,
			checking: String(dest.checking),
			services: servicesSimples,
			optionalServices: dest.optionalServices,
			servicesPrice: servicePrices,
		} as DestinationForCombine;

		return dest.hotelOptions.map((hotelOption) => {
			return {
				hotelOption: hotelOption,
				destination: destinationToCombine,
				destinationIndex: destinationIndex,
			} as HotelOptionsContainerForCombine;
		});
	});

	const resultOfCombine = allHotelContainerOptionCombinations(hotelOptionsContainerToCombine);

	const getCombinationsSummaryPriceByFlightIndex = (
		flightIndex: number | null,
	): QuotationDestinationMultipleSummaryPriceCombination[] => {
		return resultOfCombine.map((combination) => {
			const roomsSummaryPrice = getQuotationMultipleRoomWithPrice(combination, flightIndex);
			const totalPriceForAllRooms = roomsSummaryPrice.map((x) => x.adtPrice).reduce((a, b) => a + b, 0);
			const keys = combination.map((x) => {
				return {
					destinationIndex: x.destinationIndex,
					destinationCode: x.destination.cityCode,
					hotelId: x.hotelOption.hotelId,
					hotelProvider: x.hotelOption.provider,
					boardsKey: createBoardsKey(x.hotelOption.rooms),
					...(flightIndex != null && { flightIndex: flightIndex }),
				} as QuotationDestinationMultipleSummaryPriceCombinationKey;
			});

			return {
				keys: keys,
				roomsSummaryPrice: roomsSummaryPrice,
				summaryPriceForOneAdult: Math.ceil(totalPriceForAllRooms / roomsSummaryPrice.length),
			} as QuotationDestinationMultipleSummaryPriceCombination;
		});
	};

	const destinationsMultipleSummaryPriceCombination = (): QuotationDestinationMultipleSummaryPriceCombination[] => {
		if (q.airs.length > 0) {
			const toReturn: QuotationDestinationMultipleSummaryPriceCombination[] = [];
			q.airs.forEach((air, index) => {
				const combination = getCombinationsSummaryPriceByFlightIndex(index);
				toReturn.push(...combination);
			});
			return toReturn;
		} else {
			return getCombinationsSummaryPriceByFlightIndex(null);
		}
	};

	const destinationsConvertedToContainer = q.destination.map((x) => {
		const hotelOptionsConverted = x.hotelOptions.map((hotelOption) => {
			return {
				hotelId: hotelOption.hotelId,
				provider: hotelOption.provider,
				hotelName: hotelOption.hotelName,
				chdCharged: hotelOption.chdCharged,
				infCharged: hotelOption.infCharged,
				rooms: hotelOption.rooms,
				details: hotelOption.details,
				boardsKey: createBoardsKey(hotelOption.rooms),
			} as HotelOptionDetailDestinationMultiple;
		});

		return {
			cityCode: x.cityCode,
			cityName: x.cityName,
			nights: x.nights,
			checking: String(x.checking),
			services: convertToServicesSimple(x.services),
			hotelOptions: hotelOptionsConverted,
			optionalServices: x.optionalServices,
		} as DestinationSummaryForDestinationMultiple;
	});

	return {
		priceCombinations: destinationsMultipleSummaryPriceCombination(),
		destinations: destinationsConvertedToContainer,
	} as QuotationDestinationMultipleContainer;
};

export const hasErrorInDestinationMultipleParams = (
	priceCombinations: QuotationDestinationMultipleSummaryPriceCombination[],
	destinations: DestinationSummaryForDestinationMultiple[],
	paxTotals: GetPAXTotalsResponse,
): boolean =>
	destinations.length == 0 ||
	priceCombinations.length == 0 ||
	priceCombinations.some((x) => x.keys.length != destinations.length) ||
	priceCombinations.some((x) => x.roomsSummaryPrice.length != paxTotals.totalPaxPerRoom.length);

const isValidRoomDistribution = (q: Quotation) =>
	q.destination.every(
		(d) => d.hotelOptions.length > 0 && d.hotelOptions.every((h) => h.rooms.length === q.roomBaseConfig.length),
	);

const isValidAirsToSendToClient = (airs: Air[]): boolean =>
	airs.length > 0 &&
	airs.every((x) => (Array.isArray(x.data) && x.data.length > 0) || x.images.length > 0) &&
	!needRefreshAirs(airs);

export const isValidQuotationToSendClient = (q: Quotation): boolean =>
	q.id > 0 &&
	(q.airs.length == 0 || isValidAirsToSendToClient(q.airs)) &&
	q.destination.length > 0 &&
	q.roomBaseConfig.length > 0 &&
	isValidRoomDistribution(q);
export const isValidQuotationOnlyFlightsToSendClient = (q: QuotationOnlyFlights): boolean =>
	q.id > 0 && isValidAirsToSendToClient(q.airs) && q.destination.length > 0 && q.passengerConfig != null;

export const convertQuotationToSnapShotToClient = (
	q: Quotation,
	countryCode: string,
): QuotationSnapShotToClient | QuotationSnapShotToClientDestinationMultiple | null => {
	if (isValidQuotationToSendClient(q)) {
		const paxTotals = getPAXTotalsByRoomConfig(q.roomBaseConfig);
		const airSimples: AirSimple[] = q.airs.map((air) => {
			return {
				validatingCarrier: air?.validatingCarrier as string,
				baggage: air?.baggage as string,
				carryOn: air?.carryOn as boolean,
				data: air?.data as QuotationFlightInfo[],
				images: air.images,
			};
		});
		const manualServicesSimple = convertToServicesSimple(q.manualServices);
		const baseSnapShot = {
			id: q.id,
			crmTicket: q.crmTicket,
			description: q.description,
			name: q.name,
			email: q.email,
			phone: q.phone,
			localCurrency: q.localCurrency,
			exchangeRate: q.exchangeRate,
			showLocalCurrency: q.showLocalCurrency,
			countryCode: countryCode,
			notIncludes: q.notIncludes,
			roomBaseConfig: q.roomBaseConfig,
			manualServices: manualServicesSimple,
			airs: airSimples,
			paxTotal: paxTotals,
		};

		if (q.destination.length == 1) {
			const quotationToClient = Object.assign(baseSnapShot, {
				quotationType: QuotationType.SIMPLEDESTINATION,
				destination: convertToDestinationsSimple(q),
			}) as QuotationSnapShotToClient;
			const newDestination = [...quotationToClient.destination];
			newDestination.forEach(
				(d, index) =>
					(newDestination[index].optionalServices = newDestination[index].optionalServices.filter((x) => x.show)),
			);
			quotationToClient.destination = newDestination;
			return quotationToClient;
		} else if (q.destination.length > 1) {
			const quotationToClient = Object.assign(baseSnapShot, {
				quotationType: QuotationType.MULTIDESTINATION,
				containerDestinationMultiple: convertToQuotationDestinationMultipleContainer(q),
			}) as QuotationSnapShotToClientDestinationMultiple;
			const hasError = hasErrorInDestinationMultipleParams(
				quotationToClient.containerDestinationMultiple.priceCombinations,
				quotationToClient.containerDestinationMultiple.destinations,
				paxTotals,
			);
			if (hasError) return null;
			const newDestination = [...quotationToClient.containerDestinationMultiple.destinations];
			newDestination.forEach(
				(d, index) =>
					(newDestination[index].optionalServices = newDestination[index].optionalServices.filter((x) => x.show)),
			);
			quotationToClient.containerDestinationMultiple.destinations = newDestination;
			return quotationToClient;
		} else return null;
	}
	return null;
};

export const convertQuotationOnlyFlightToSnapShotToClient = (
	q: QuotationOnlyFlights,
	countryCode: string,
): QuotationSnapShotToClientOnlyFlights | null => {
	if (isValidQuotationOnlyFlightsToSendClient(q)) {
		const airSimples: AirSimple[] = q.airs.map((air) => {
			return {
				validatingCarrier: air?.validatingCarrier as string,
				baggage: air?.baggage as string,
				carryOn: air?.carryOn as boolean,
				data: air?.data as QuotationFlightInfo[],
				images: air.images,
			};
		});
		return {
			id: q.id,
			crmTicket: q.crmTicket,
			description: q.description,
			name: q.name,
			email: q.email,
			phone: q.phone,
			countryCode: countryCode,
			notIncludes: q.notIncludes,
			airs: airSimples,
			passengerConfig: q.passengerConfig,
			destination: q.destination,
			flightFinalPriceSummaries: createFlightSummariesPriceForQuotationOnlyFlights(q),
			showLocalCurrency: q.showLocalCurrency,
			quotationType: QuotationType.ONLYFLIGHTS,
		} as QuotationSnapShotToClientOnlyFlights;
	}
	return null;
};

export const calculateUtility = (value: number, markup: number | null): number =>
	markup != null ? (value * markup) / 100 : 0;

const regexOnlyDigit = /^\d+$/;

export const isOnlyDigit = (value: string): boolean => regexOnlyDigit.test(value);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const extractErrorMessage = (error: any, defaultMessage: string): string => {
	return error['response'] && error['response']['data'] && error['response']['data']['message']
		? error['response']['data']['message']
		: defaultMessage;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isNotFoundError = (error: any): boolean => {
	return error['response']['data']['code'] == 130;
};

export const daysWeek = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];

export const monthsInitials = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

export const isoDateToFullDate = (date: string): string | null => {
	if (date) {
		const arrayDate = date.split('-');
		const objDate = new Date(Number(arrayDate[0]), Number(arrayDate[1]) - 1, Number(arrayDate[2]));

		return `${daysWeek[objDate.getDay()]}. ${objDate.getDate()} ${
			monthsInitials[objDate.getMonth()]
		}. ${objDate.getFullYear()}`;
	}
	return null;
};

export const formatDestinationAndNights = (values: DestinationNameAndNights[]): string => {
	return values
		.map((x) => {
			const destinationNameFormatted = x.destinationName.split('-')[1].toUpperCase();
			const nights = 'NOCHE' + (x.nights > 1 ? 'S' : '');
			return `${destinationNameFormatted} (${x.nights} ${nights})`;
		})
		.join(' | ');
};

export const regexOnlyNumberInt = /^\d+$/;

export const getCityNameCleaned = (cityCodeWithCityName: string): string => {
	const existSeparator = cityCodeWithCityName.indexOf('-') > -1;
	return existSeparator ? cityCodeWithCityName.split('-')[1] : cityCodeWithCityName;
};

export const openInNewTabHotelUrl = (url: string): void => {
	if (url != '') {
		const newUrl = url.startsWith('http://') || url.startsWith('https://') ? url : 'https://' + url;
		window.open(newUrl, '_blank');
	}
};

export const createHotelFareRequestBase = (
	roomBaseConfig: RoomBaseConfig[],
	hotelId: number,
	destination: Destination,
): AccommodationFaresRequest => {
	const checkIn = destination.checking;
	const checkOutMoment = moment(checkIn, 'YYYY-MM-DD').add('days', destination.nights);
	const checkOut =
		checkOutMoment.format('YYYY') + '-' + checkOutMoment.format('MM') + '-' + checkOutMoment.format('DD');
	const distributions = roomBaseConfig.map((config) => {
		const allAges = config.infants.concat(config.childs);
		return {
			adults: config.adults,
			childrenAges: allAges,
		};
	});

	return {
		hotelId: hotelId,
		provider: 'Hotelbeds',
		distributions: distributions,
		checkIn: String(checkIn),
		checkOut: checkOut,
	};
};

export const compareDistributionAndRoomDistribution = (
	distributionFromRequest: QuotationRoomDistributionFaresRequest,
	distributionFromResponse: AccommodationFaresByDistribution,
): boolean => {
	if (distributionFromRequest.adults === distributionFromResponse.distribution.adults) {
		const agesFromRequest = [...distributionFromRequest.childrenAges];
		const agesFromResponse = [...distributionFromResponse.distribution.childrenAges];
		agesFromRequest.sort((n1, n2) => n1 - n2);
		agesFromResponse.sort((n1, n2) => n1 - n2);
		return (
			agesFromRequest.length === agesFromResponse.length &&
			agesFromRequest.every((val, index) => val === agesFromResponse[index])
		);
	}
	return false;
};

export const getAccommodationFaresByDistribution = (
	faresByDistribution: AccommodationFaresByDistribution[],
	distributions: QuotationRoomDistributionFaresRequest[],
): AccommodationFaresByDistribution[] => {
	const result: AccommodationFaresByDistribution[] = [];
	distributions.forEach((distFromRequest) => {
		const fareDistribution = faresByDistribution.find((distFromResponse) =>
			compareDistributionAndRoomDistribution(distFromRequest, distFromResponse),
		);
		if (fareDistribution) result.push(fareDistribution);
		else throw Error('Distribution not found');
	});
	return result;
};

export const handleViewQuotation = (id: number, isOnlyFlights: boolean) => (): void => {
	const destination = isOnlyFlights ? routes.quotations.editOnlyFlights : routes.quotations.edit;
	const completeUrl = `${window.location.origin}${destination.replace(':id', id.toString())}`;
	window.open(completeUrl, '_blank');
};

export function getQuotationSaleReportServiceTypeName(serviceType: QuotationSaleReportServiceTypeEnum): string {
	const maybeElement = quotationSaleReportServiceTypes.find((element) => element.id === serviceType);
	return maybeElement ? maybeElement.name : '';
}

export const findPriceInManualRoute = (
	totalAdults: number,
	fare: ManualHotelFareTotalPriceForAllNights,
): number | undefined => {
	switch (totalAdults) {
		case 1:
			return fare.priceSingle;
		case 2:
			return fare.priceDbl;
		case 3:
			return fare.priceTpl;
		case 4:
			return fare.priceCpl;
		default:
			return undefined;
	}
};

export const minPriceInRooms = (rooms: RoomFare[]): number => {
	if (rooms.length === 0) return 0;
	return rooms.reduce((min, room) => (room.price < min ? room.price : min), rooms[0].price);
};

export const isURL = (stringToCheck: string): boolean => {
	const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
	return pattern.test(stringToCheck);
};

export const needRefreshAirs = (airs: Air[]): boolean => {
	return airs.some((air) => !('id' in air) || air.images.some((image) => !isURL(image.image)));
};

export const extractBase64FromFiles = async (files: File[]): Promise<string[]> => {
	const filePromises = files.map((file) => {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const base64 = reader.result as string;
				resolve(base64);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	});

	return Promise.all(filePromises);
};

export const reOrderFlightImages = (images: QuotationFlightImage[]): QuotationFlightImage[] => {
	return images.map((image, index) => ({ ...image, order: index + 1 }));
};

export const changeOrderForward = (id: string, flightImages: QuotationFlightImage[]): QuotationFlightImage[] => {
	const index = flightImages.findIndex((image) => image.id === id);
	const updatedImages = [...flightImages];
	const temp = updatedImages[index];
	updatedImages[index] = updatedImages[index + 1];
	updatedImages[index + 1] = temp;
	return reOrderFlightImages(updatedImages);
};
export const changeOrderBack = (id: string, flightImages: QuotationFlightImage[]): QuotationFlightImage[] => {
	const index = flightImages.findIndex((image) => image.id === id);
	const updatedImages = [...flightImages];
	const temp = updatedImages[index];
	updatedImages[index] = updatedImages[index - 1];
	updatedImages[index - 1] = temp;
	return reOrderFlightImages(updatedImages);
};
