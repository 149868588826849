import React, { useState } from 'react';
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	Box,
} from '@mui/material';
import { IServiceCostVariationLogs } from 'features/salesOrder/types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

interface Props {
	filteredArray: IServiceCostVariationLogs[];
}

const formatDateTime = (dateTimeString: string) => {
	const formattedDateTime = new Date(dateTimeString).toLocaleDateString('es-ES', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: 'numeric',
	});
	return formattedDateTime;
};

export const ServiceCostVariationLogs = ({ filteredArray }: Props): JSX.Element => {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleTable = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<Box width="100%">
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography color={'Grey'} variant="h6" mt={2} ml={2}>
					Log de Modificaciones
				</Typography>
				<IconButton onClick={toggleTable}>{isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
			</Box>
			{isExpanded && (
				<TableContainer sx={{ p: 2, width: '1000px' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Usuario</TableCell>
								<TableCell>Fecha y hora</TableCell>
								<TableCell>Venta</TableCell>
								<TableCell>modi (V)</TableCell>
								<TableCell>Costo</TableCell>
								<TableCell>modi (C)</TableCell>
								<TableCell>Margen</TableCell>
								<TableCell>modi (M)</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredArray.map((log) => (
								<TableRow key={log.id}>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
											{log.createdBy}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
											{formatDateTime(log.created)}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
											USD {log.saleAmount}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
											USD {log.saleAmountVariation}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
											USD {log.totalCost}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
											USD {log.totalCostVariation}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
											USD {log.grossProfit}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
											USD {log.grossProfitVariation}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	);
};
