import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Button,
	Chip,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IPassengerConfig } from '../../types';
import { RootState } from '../../../../store';
import { setPassengerConfig } from '../../sliceOnlyFlights';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	table: {
		minWidth: 650,
	},
}));

export default function PassengerConfig(): JSX.Element {
	const classes = useStyles();
	const dispatch = useDispatch();
	const initialPassengerConfig: IPassengerConfig = {
		adults: 0,
		childrenAges: [],
		infantAges: [],
	};

	const [passengerConfigLocal, setPassengerConfigLocal] = useState<IPassengerConfig>(initialPassengerConfig);
	const [childrenAge, setChildrenAge] = useState<number>(0);
	const [infantAge, setInfantAge] = useState<number>(0);
	const { passengerConfig } = useSelector((state: RootState) => state.quotationOnlyFlights);

	const handleClickResetPassengerConfig = () => {
		dispatch(setPassengerConfig(null));
	};

	const handleAdultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassengerConfigLocal((prevState) => {
			return { ...prevState, adults: parseInt(e.target.value) };
		});
	};

	const handleChildAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChildrenAge(parseInt(e.target.value));
	};

	const handleChildAdd = () => {
		setPassengerConfigLocal((prevState) => {
			const newState = { ...prevState };
			newState.childrenAges.push(childrenAge);
			return newState;
		});
		setChildrenAge(0);
	};

	const handleChildRemove = (index: number) => () => {
		setPassengerConfigLocal((prevState) => {
			const newState = { ...prevState };
			newState.childrenAges.splice(index, 1);
			return newState;
		});
	};

	const handleInfantAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInfantAge(parseInt(e.target.value));
	};

	const handleInfantAdd = () => {
		setPassengerConfigLocal((prevState) => {
			const newState = { ...prevState };
			newState.infantAges.push(infantAge);
			return newState;
		});
		setInfantAge(0);
	};

	const handleInfantRemove = (index: number) => () => {
		setPassengerConfigLocal((prevState) => {
			const newState = { ...prevState };
			newState.infantAges.splice(index, 1);
			return newState;
		});
	};

	const handleRoomBaseConfigAdd = () => {
		dispatch(setPassengerConfig(passengerConfigLocal));
		setPassengerConfigLocal({
			adults: 0,
			childrenAges: [],
			infantAges: [],
		});
	};

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Configuración de pasajeros
				</Typography>
				{passengerConfig && (
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell align="right">ADT</TableCell>
									<TableCell align="right">CHD</TableCell>
									<TableCell align="right">INF</TableCell>
									<TableCell align="right" />
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell component="th" scope="row"></TableCell>
									<TableCell align="right">{passengerConfig.adults}</TableCell>
									<TableCell align="right">{passengerConfig.childrenAges.join()}</TableCell>
									<TableCell align="right">{passengerConfig.infantAges.join()}</TableCell>
									<TableCell align="right">
										<IconButton
											onClick={handleClickResetPassengerConfig}
											color="secondary"
											component="span"
											size="large"
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell component="th" scope="row">
										Total
									</TableCell>
									<TableCell align="right">{passengerConfig.adults}</TableCell>
									<TableCell align="right">{passengerConfig.childrenAges.length}</TableCell>
									<TableCell align="right">{passengerConfig.infantAges.length}</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				)}

				{passengerConfig == null && (
					<form className={classes.form} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12}>
								<Typography component="span" variant="subtitle1">
									Adultos:
								</Typography>
								<TextField
									name="adults"
									variant="outlined"
									value={passengerConfigLocal.adults}
									required
									id="adults"
									label="Cantidad de Adultos"
									type="number"
									size="small"
									onChange={handleAdultChange}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography component="span" variant="subtitle1">
									Childs:
								</Typography>
								{passengerConfigLocal.childrenAges.map((age, index) => (
									<Chip key={index} label={age} onDelete={handleChildRemove(index)} />
								))}

								<TextField
									name="childAge"
									variant="outlined"
									required
									id="childAge"
									label="Edad"
									type="number"
									value={childrenAge}
									size="small"
									onChange={handleChildAgeChange}
								/>
								<Button variant="contained" color="primary" onClick={handleChildAdd}>
									Agregar
								</Button>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Typography component="span" variant="subtitle1">
									Infants:
								</Typography>
								{passengerConfigLocal.infantAges.map((age, index) => (
									<Chip key={index} label={age} onDelete={handleInfantRemove(index)} />
								))}
								<TextField
									name="infants"
									variant="outlined"
									required
									id="infants"
									label="Edad"
									type="number"
									size="small"
									value={infantAge}
									onChange={handleInfantAgeChange}
								/>
								<Button variant="contained" color="primary" onClick={handleInfantAdd}>
									Agregar
								</Button>
							</Grid>
						</Grid>
						<Button
							type="button"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={handleRoomBaseConfigAdd}
						>
							Agregar configuración de pasajeros
						</Button>
					</form>
				)}
			</div>
		</Container>
	);
}
