import React, { useEffect, useState } from 'react';
import useQuery from '../../../common/hooks/useQuery';
import { Alert, Backdrop, Box, CircularProgress, Grid, Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ShowAlertState } from '../../../common/types';
import {
	FlightExplorerOrderBy,
	IBaggageFilter,
	IBaseFlightExplorerDestinationFilter,
	IDepartureDaysFilter,
	IDurationFilter,
	IFlightExplorerByDestinationResponse,
	IFlightExplorerDestinationFilter,
	IFlightExplorerReq,
	IPriceRangeFilter,
	IScheduleFilter,
	IStopFilter,
} from '../../types';
import {
	getFlightExplorerDataByDestination,
	getFlightExplorerDataByDestinationFilteredAndOrdered,
} from '../../services';
import { FlightItineraryCard } from '../FlightItineraryCard';
import { FlightExplorerStopFilter } from '../FlightExplorerStopFilter';
import { FlightExplorerBaggageFilter } from '../FlightExplorerBaggageFilter';
import { FlightExplorerPriceFilter } from '../FlightExplorerPriceFilter';
import { FlightExplorerDaysFilter } from '../FlightExplorerDaysFilter';
import { FlightExplorerDurationFilter } from '../FlightExplorerDurationFilter';
import { FlightExplorerScheduleFilter } from '../FlightExplorerScheduleFilter';
import { FlightExplorerAirlinesFilter } from '../FlightExplorerAirlinesFilter';
import { FlightExplorerCountryExcludeInStopFilter } from '../FlightExplorerCountryExcludeInStopFilter';
import { fillMonthsWithYearForExplorer, formatDateToShowUser } from '../../../common/helpers';
import { useTheme } from '@mui/styles';
import { FlightExplorerItineraryHeaderSort } from '../FlightExplorerItineraryHeaderSort';
import { extractErrorMessage } from '../../../quotation/helpers';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
	fillBackground: {
		backgroundColor: theme.palette.primary.main,
	},
}));
export const FlightExplorerDestinationDetail = (): JSX.Element => {
	const query = useQuery();
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [flightExplorerResponse, setFlightExplorerResponse] = useState<IFlightExplorerByDestinationResponse | null>(
		null,
	);
	const [lateralFilterValues, setLateralFilterValues] = useState<IBaseFlightExplorerDestinationFilter | null>(null);
	const [orderByValue, setOrderByValue] = useState<FlightExplorerOrderBy>(FlightExplorerOrderBy.BEST_PRICE);
	const departureCode = query.get('departureCode');
	const departureName = query.get('departureName');
	const destinationName = query.get('destinationName');
	const destinationCode = query.get('destinationCode');
	const durationFrom = query.get('durationFrom');
	const durationTo = query.get('durationTo');
	const travelInMonth = query.get('travelInMonth');
	const travelDateFrom = query.get('travelDateFrom');
	const travelDateTo = query.get('travelDateTo');
	const theme = useTheme();
	const flightExplorerReq = {
		departureCode: departureCode || '',
		...(durationFrom &&
			!isNaN(Number(durationFrom)) &&
			durationTo &&
			!isNaN(Number(durationTo)) && {
				duration: {
					from: Number(durationFrom),
					to: Number(durationTo),
				},
				...(travelInMonth && !isNaN(Number(travelInMonth)) && { travelInMonth: Number(travelInMonth) }),
				...(travelDateFrom && { travelDateFrom: travelDateFrom }),
				...(travelDateTo && { travelDateTo: travelDateTo }),
			}),
	} as unknown as IFlightExplorerReq;

	const title = () => {
		let baseTitle = `Vuelos a ${destinationName} desde ${departureName}`;
		if (durationFrom && !isNaN(Number(durationFrom)) && durationTo && !isNaN(Number(durationTo))) {
			baseTitle = `${baseTitle} con duración entre ${durationFrom} y ${durationTo} días`;
		}
		if (travelInMonth && !isNaN(Number(travelInMonth)) && travelInMonth !== '0') {
			const monthsWithYear = fillMonthsWithYearForExplorer();
			baseTitle = `${baseTitle} en el mes ${monthsWithYear.find((x) => x.value == Number(travelInMonth))?.label || ''}`;
		} else if (travelDateFrom && travelDateTo) {
			baseTitle = `${baseTitle} viajando entre ${formatDateToShowUser(travelDateFrom)} y ${formatDateToShowUser(
				travelDateTo,
			)}`;
		}
		return baseTitle;
	};

	const manageRequestError = (error: any) => {
		if (error.response.status !== 404) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir los vuelos del destino.'),
			});
		} else {
			setAlert({
				show: true,
				severity: 'info',
				message: 'No se encontraron resultados.',
			});
		}
		setLoading(false);
	};

	useEffect(() => {
		const fetchApi = async () => {
			if (destinationCode) {
				try {
					setLoading(true);
					const data = await getFlightExplorerDataByDestination(destinationCode, flightExplorerReq);
					setFlightExplorerResponse(data.data);
					setLoading(false);
				} catch (error: any) {
					manageRequestError(error);
				}
			} else {
				setAlert({
					show: true,
					severity: 'info',
					message: 'Código de destino incorrecto',
				});
			}
		};
		fetchApi();
	}, []);

	useEffect(() => {
		const fetchApi = async () => {
			if (destinationCode && flightExplorerResponse) {
				try {
					setLoading(true);
					const filter = Object.assign(
						{ searchId: flightExplorerResponse.searchId, orderBy: orderByValue },
						{ ...(lateralFilterValues || {}) },
					);
					const data = await getFlightExplorerDataByDestinationFilteredAndOrdered(
						filter as IFlightExplorerDestinationFilter,
					);
					flightExplorerResponse.options = [...data.data.options];
					flightExplorerResponse.orderInfo = { ...data.data.orderInfo };
					setFlightExplorerResponse(flightExplorerResponse);
					setLoading(false);
				} catch (error: any) {
					if (error.response.status === 404) {
						flightExplorerResponse.options = [];
					}
					manageRequestError(error);
				}
			}
		};
		fetchApi();
	}, [lateralFilterValues, orderByValue]);

	const handleChangeStopFilter = (data: IStopFilter | null) => {
		const filter = Object.assign({ ...(lateralFilterValues || {}) }, { stop: data });
		setLateralFilterValues(filter as IBaseFlightExplorerDestinationFilter);
	};

	const handleChangeCountryNameToExcludeInStopsFilter = (data: string[] | null) => {
		const filter = Object.assign({ ...(lateralFilterValues || {}) }, { countryNameToExcludeInStops: data });
		setLateralFilterValues(filter as IBaseFlightExplorerDestinationFilter);
	};

	const handleChangeBaggageFilter = (data: IBaggageFilter | null) => {
		const filter = Object.assign({ ...(lateralFilterValues || {}) }, { baggage: data });
		setLateralFilterValues(filter as IBaseFlightExplorerDestinationFilter);
	};
	const handleChangeRangePriceFilter = (data: IPriceRangeFilter | null) => {
		const filter = Object.assign({ ...(lateralFilterValues || {}) }, { priceRange: data });
		setLateralFilterValues(filter as IBaseFlightExplorerDestinationFilter);
	};
	const handleChangeDepartureDaysFilter = (data: IDepartureDaysFilter | null) => {
		const filter = Object.assign({ ...(lateralFilterValues || {}) }, { departureDays: data });
		setLateralFilterValues(filter as IBaseFlightExplorerDestinationFilter);
	};
	const handleChangeDurationFilter = (data: IDurationFilter | null) => {
		const filter = Object.assign({ ...(lateralFilterValues || {}) }, { duration: data });
		setLateralFilterValues(filter as IBaseFlightExplorerDestinationFilter);
	};
	const handleChangeScheduleFilter = (data: IScheduleFilter | null) => {
		const filter = Object.assign({ ...(lateralFilterValues || {}) }, { schedule: data });
		setLateralFilterValues(filter as IBaseFlightExplorerDestinationFilter);
	};

	const handleChangeAirlinesFilter = (data: string[] | null) => {
		const filter = Object.assign({ ...(lateralFilterValues || {}) }, { airlineCodes: data });
		setLateralFilterValues(filter as IBaseFlightExplorerDestinationFilter);
	};

	const handleChangeOrderBy = (order: FlightExplorerOrderBy) => setOrderByValue(order);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'center' }}>
				<Typography component="h1" fontWeight={'bold'} fontSize={'20px'} color={theme.palette.primary.main}>
					{title()}
				</Typography>
			</Box>

			{flightExplorerResponse && (
				<Grid container spacing={2} component="main" sx={{ 'padding-top': '15px', marginBottom: '15px' }}>
					<Grid item xs={2}>
						<FlightExplorerStopFilter
							data={flightExplorerResponse.filterInfo.filterStopInfo}
							handleChangeFilter={handleChangeStopFilter}
						/>
						<FlightExplorerCountryExcludeInStopFilter
							data={flightExplorerResponse.filterInfo.countryNamesInStopsWithTotal}
							handleChangeFilter={handleChangeCountryNameToExcludeInStopsFilter}
						/>
						<FlightExplorerBaggageFilter
							data={flightExplorerResponse.filterInfo.filterBaggageInfo}
							handleChangeFilter={handleChangeBaggageFilter}
						/>
						{flightExplorerResponse.filterInfo.filterPriceInfo.minPrice.amount !=
							flightExplorerResponse.filterInfo.filterPriceInfo.maxPrice.amount && (
							<FlightExplorerPriceFilter
								data={flightExplorerResponse.filterInfo.filterPriceInfo}
								handleChangeFilter={handleChangeRangePriceFilter}
							/>
						)}
						<FlightExplorerDaysFilter
							weekDaysInDeparture={flightExplorerResponse.filterInfo.weekDaysInDeparture}
							weekDaysInReturn={flightExplorerResponse.filterInfo.weekDaysInReturn}
							handleChangeFilter={handleChangeDepartureDaysFilter}
						/>
						<FlightExplorerDurationFilter
							maxFlightDurationInHour={flightExplorerResponse.filterInfo.maxFlightDurationInHour}
							minHourInStop={flightExplorerResponse.filterInfo.filterStopInfo.minHourInStop}
							maxHourInStop={flightExplorerResponse.filterInfo.filterStopInfo.maxHourInStop}
							handleChangeFilter={handleChangeDurationFilter}
						/>
						<FlightExplorerScheduleFilter handleChangeFilter={handleChangeScheduleFilter} />
						<FlightExplorerAirlinesFilter
							data={flightExplorerResponse.filterInfo.airlinesWithTotal}
							handleChangeFilter={handleChangeAirlinesFilter}
						/>
					</Grid>
					<Grid item xs={8}>
						{flightExplorerResponse && flightExplorerResponse.options.length > 0 && (
							<FlightExplorerItineraryHeaderSort
								handleChangeOrderBy={handleChangeOrderBy}
								data={flightExplorerResponse.orderInfo}
							/>
						)}
						{flightExplorerResponse?.options.map((item, index) => (
							<FlightItineraryCard item={item} key={'itinerary-' + index} />
						))}
					</Grid>
				</Grid>
			)}

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
