import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import { checkShowErrorMessage } from '../../../common/helpers';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { getChargedAgainstOptions, postAuthorization } from 'features/transactions/services';
import { ChargedAgainstOptionTypeEnum, IChargedAgainstOption } from 'features/transactions/types';
import { useEffect, useState } from 'react';

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export interface CreateAuthorizationModalProps {
	transactionId: number;
	open: boolean;
	handleClose: () => void;
	reloadList: () => void;
}
interface ICreateAuthorizationForm {
	transactionId: number;
	chargedAgainstOptionCode: string;
	amountToAuthorize: string;
	authorizationCode: string;
}
export function CreateAuthorizationModal({
	transactionId,
	open,
	handleClose,
	reloadList,
}: CreateAuthorizationModalProps): JSX.Element {
	const postAuthorizationDefaultValues = {
		transactionId: transactionId,
		chargedAgainstOptionCode: '',
		amountToAuthorize: '',
		authorizationCode: '',
	} as ICreateAuthorizationForm;
	const classes = useStyles();

	const [chargedAgainstOptions, setChargedAgainstOptions] = useState<IChargedAgainstOption[]>([]);
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		reset,
	} = useForm({ mode: 'all', defaultValues: postAuthorizationDefaultValues });
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const onSubmit = async (data: ICreateAuthorizationForm) => {
		try {
			setLoading(true);
			const chargedAgainstOptionType =
				chargedAgainstOptions.find((option) => option.code === data.chargedAgainstOptionCode)?.optionType ||
				ChargedAgainstOptionTypeEnum.VC;
			const dataToSend = {
				...data,
				amountToAuthorize: Number(data.amountToAuthorize),
				chargedAgainstOptionType: chargedAgainstOptionType,
			};
			await postAuthorization(dataToSend);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Autorización creada satisfactoriamente',
			});
			reset();
			reloadList();
			handleClose();
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error la autorización'),
			});
			setLoading(false);
		}
	};

	const loadChargedAgainstOptionsToSelect = async () => {
		try {
			setLoading(true);
			setChargedAgainstOptions((await getChargedAgainstOptions()).data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de cobrados contra'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		loadChargedAgainstOptionsToSelect();
	}, []);

	return (
		<div>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="h5" color="primary">
						Agregar nueva Autorización
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container gap={2}>
							<Grid item xs={12}>
								<Controller
									name="chargedAgainstOptionCode"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Por favor seleccione el valor de cobrado contra de la autorización',
										},
									}}
									render={({ field }) => (
										<FormControl sx={{ width: '100%', height: '100%' }}>
											<InputLabel id="charged-against-label">Cobrado contra</InputLabel>
											<Select
												labelId="charged-against-label"
												id="charged-against-select"
												label="Cobrado contra"
												disabled={!chargedAgainstOptions.length || loading}
												sx={{ width: '100%' }}
												{...field}
											>
												{chargedAgainstOptions.map((option) => {
													return (
														<MenuItem key={option.code} value={option.code}>
															{option.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="amountToAuthorize"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										min: {
											value: 1,
											message: 'El monto debe ser mayor que 0',
										},
										pattern: {
											value: /^(0|[1-9]\d*)(\.\d+)?$/,
											message: 'El valor debe ser numérico',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Monto"
											type="number"
											disabled={loading}
											helperText={checkShowErrorMessage(
												Boolean(errors.amountToAuthorize),
												errors.amountToAuthorize?.message,
											)}
											error={Boolean(errors.amountToAuthorize)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="authorizationCode"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										min: {
											value: 1,
											message: 'El código de autorización debe ser mayor que 0',
										},
										maxLength: {
											value: 100,
											message: 'El código de autorización debe tener un máximo de 100 caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Codigo de Autorización"
											type="text"
											disabled={loading}
											helperText={checkShowErrorMessage(
												Boolean(errors.authorizationCode),
												errors.authorizationCode?.message,
											)}
											error={Boolean(errors.authorizationCode)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} display={'flex'} gap={'10px'} justifyContent="flex-end">
								<Button variant="contained" disabled={loading || !isValid} color="success" fullWidth type="submit">
									Confirmar
								</Button>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</div>
	);
}
