import React from 'react';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { AuthorizationDetails as Component } from 'features/authorization/components/AuthorizationDetails';
import { useParams } from 'react-router-dom';
import { ReturnButton } from 'features/common/components/ReturnButton';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	returnButton: {
		textAlign: 'right',
	},
}));

function AuthorizationDetails(): JSX.Element {
	const classes = useStyles();
	const theme = useTheme();
	const { id } = useParams<{ id: string }>();

	return (
		<Layout>
			<div>
				<Container component="main" sx={{ marginBottom: theme.spacing(3) }}>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="h5" className={classes.headerTitle}>
								Detalle de Autorización
							</Typography>
							<div className={classes.returnButton}>
								<ReturnButton />
							</div>
						</Grid>
					</Grid>

					<Component id={+id} />
				</Container>
			</div>
		</Layout>
	);
}

export default AuthorizationDetails;
