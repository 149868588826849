import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { IAutomaticRouteSuggestion } from '../../../features/stats/types';
import { AutomaticRouteTariffPriceList } from '../../../features/stats/components/AutomaticRouteStats/AutomaticRouteTariffPriceList';

import { AutomaticRouteGraphicPrices } from '../../../features/stats/components/AutomaticRouteStats/AutomaticRouteGraphicPrices';
import { AutomaticRouteSearch } from '../../../features/stats/components/AutomaticRouteStats/AutomaticRouteSearch';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));

function AutomaticRouteStats(): JSX.Element {
	const classes = useStyles();
	const [automaticRouteSuggestion, setAutomaticRouteSuggestion] = useState<IAutomaticRouteSuggestion | null>(null);
	const handleSelectAutomaticRoute = (iAutomaticRouteSuggestion: IAutomaticRouteSuggestion) =>
		setAutomaticRouteSuggestion(iAutomaticRouteSuggestion);

	return (
		<Layout>
			<div>
				<Container component="main" maxWidth="xl">
					<Typography variant="h5" className={classes.headerTitle}>
						Estadística de precios de rutas
					</Typography>
					<AutomaticRouteSearch handleSelectAutomaticRoute={handleSelectAutomaticRoute} />
					{automaticRouteSuggestion && <AutomaticRouteGraphicPrices automaticRouteId={automaticRouteSuggestion.id} />}
					{automaticRouteSuggestion && <AutomaticRouteTariffPriceList automaticRouteInfo={automaticRouteSuggestion} />}
				</Container>
			</div>
		</Layout>
	);
}

export default AutomaticRouteStats;
