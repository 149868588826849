import React from 'react';
import { Container, Grid, Button } from '@mui/material';
import lupaError404 from 'assets/LupaError404.png';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Header } from 'features/quotation/components/Header';

interface Props {
	errorType: ErrorType;
}

export enum ErrorType {
	CLIENT_QUOTATION_NOT_FOUND,
	CLIENT_PAYMENT_LINK_NOT_FOUND,
}

export const PageClientError404 = ({ errorType }: Props): JSX.Element => {
	const errorMessages: Record<ErrorType, string> = {
		[ErrorType.CLIENT_QUOTATION_NOT_FOUND]:
			'No encontramos su cotización, contacte con el vendedor para que le comparta nuevamente.',
		[ErrorType.CLIENT_PAYMENT_LINK_NOT_FOUND]: 'No encontramos su link de pago, contacte con el vendedor.',
	};

	const errorMessage = errorMessages[errorType];

	const handleNotify = () => {
		window.location.href = 'https://wa.me/59897683318';
	};

	return (
		<>
			<Header />
			<Container
				component="main"
				sx={{
					width: '400px',
					display: 'block',
					justifyContent: 'center',
					mt: 20,
					textAlign: 'center',
				}}
			>
				<Grid sx={{ mb: 1 }}>
					<img src={lupaError404} alt="Error 404" />
				</Grid>
				<p style={{ font: 'Montserrat', fontSize: '20px' }}>¡Ups! Algo falló</p>
				<p style={{ color: '#00000099', font: 'Montserrat', fontSize: '16px' }}>{errorMessage}</p>
				<Grid style={{ display: 'flex', justifyContent: 'center' }}>
					<Button style={{ color: '#4A148C', width: '45%' }} variant="outlined" onClick={handleNotify}>
						{' '}
						<WarningAmberIcon sx={{ fontSize: 15 }} /> Notificar
					</Button>
				</Grid>
			</Container>
		</>
	);
};
