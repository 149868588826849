import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { DestinationSummaryForDestinationMultiple, QuotationServiceSimple } from '../../types';
import { Container } from '@mui/material';
import { getCityNameCleaned } from '../../helpers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		includeTitle: {
			fontStyle: 'normal',
			fontWeight: 700,
			letterSpacing: '0em',
			textAlign: 'left',
			color: theme.palette.secondary.main,
			marginBottom: theme.spacing(1),
		},
		textInfo: {
			fontSize: '13px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '24px',
			float: 'left',
		},
	}),
);
interface Props {
	destinations: DestinationSummaryForDestinationMultiple[];
	manualServices: QuotationServiceSimple[];
	hasFlight: boolean;
}

export const IncludeMultiDestination = ({ destinations, manualServices, hasFlight }: Props): JSX.Element => {
	const classes = useStyles();
	const getTotalNights = (destinationCode: string) =>
		destinations.filter((x) => x.cityCode === destinationCode).reduce((a, b) => a + b.nights, 0);
	return (
		<Container disableGutters sx={{ overflow: 'auto', marginY: 2 }}>
			<Typography component="h1" variant="subtitle1" className={classes.includeTitle} sx={{ paddingLeft: 2 }}>
				Incluye:
			</Typography>
			<ul className={classes.textInfo}>
				{hasFlight && <li>Boleto aéreo con tasas e impuestos incluidos.</li>}
				{destinations.map((option, index) => (
					<>
						{destinations.findIndex((x) => x.cityCode == option.cityCode) == index && (
							<li key={`nights-preview-${index}`}>
								{getTotalNights(option.cityCode)} noches de alojamiento en {getCityNameCleaned(option.cityName)}.
							</li>
						)}
						{option.services.map((service, index) => (
							<li key={`service-preview-${index}`}>
								{service.name} {service.description}.
							</li>
						))}
					</>
				))}
				{manualServices.map((manualService, index) => (
					<li key={`manual-service-preview-${index}`}>
						{manualService.name} {manualService.description}.
					</li>
				))}
			</ul>
		</Container>
	);
};
