import * as React from 'react';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Slider,
	TextField,
} from '@mui/material';
import moment from 'moment';
import { isValidDate } from '../../../quotation/helpers';
import { useTheme } from '@mui/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { DateType } from '../../../common/types';
import { fillMonthsWithYearForExplorer, formatDateToShowUser } from '../../../common/helpers';
import {
	IFlightExplorerDatesFilterValueContainer,
	IFlightExplorerDatesFilterValueExactDates,
	IFlightExplorerDatesFilterValueMonth,
} from '../../types';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

interface Props {
	open: boolean;
	onClose: (value: IFlightExplorerDatesFilterValueContainer) => void;
	value: IFlightExplorerDatesFilterValueContainer;
}

export const FlightExplorerDatesFilterModal = ({ open, onClose, value }: Props): JSX.Element => {
	const [tabSelected, setTabSelected] = useState(value.valueType);
	const tabMonthValueType = 0;
	const tabDatesValueType = 1;
	const defaultRangeDuration = [1, 15];
	const [rangeDurationInTabMonth, setRangeDurationInTabMonth] = React.useState<number[]>(
		value.valueType == tabDatesValueType
			? defaultRangeDuration
			: value.duration
			? [value.duration.from, value.duration.to]
			: defaultRangeDuration,
	);
	const [isActiveRangeDurationInTabMonth, setIsActiveRangeDurationInTabMonth] = React.useState<boolean>(
		value.valueType == tabMonthValueType && value.duration != null,
	);
	const [rangeDurationInTabDates, setRangeDurationInTabDates] = React.useState<number[]>(
		value.valueType == tabMonthValueType
			? defaultRangeDuration
			: value.duration
			? [value.duration.from, value.duration.to]
			: defaultRangeDuration,
	);
	const [isActiveRangeDurationInTabDates, setIsActiveRangeDurationInTabDates] = React.useState<boolean>(
		value.valueType == tabDatesValueType && value.duration != null,
	);
	const [title, setTitle] = useState('Cualquier fecha, cualquier duración');
	const monthsWithYear = fillMonthsWithYearForExplorer();
	const [valueInMonthSelected, setValueInMonthSelected] = useState(
		value.valueType == tabMonthValueType ? (value.value as IFlightExplorerDatesFilterValueMonth).travelInMonth : 0,
	);
	const [dateFrom, setDateFrom] = useState<string | null>(
		value.valueType == tabDatesValueType
			? (value.value as IFlightExplorerDatesFilterValueExactDates).travelDateFrom
			: '',
	);
	const [dateTo, setDateTo] = useState<string | null>(
		value.valueType == tabDatesValueType ? (value.value as IFlightExplorerDatesFilterValueExactDates).travelDateTo : '',
	);
	const [validDateFrom, setValidDateFrom] = useState(true);
	const [validDateTo, setValidDateTo] = useState(true);
	const theme = useTheme();

	useEffect(() => {
		if (tabSelected == tabDatesValueType) {
			if (dateFrom == '') setValidDateFrom(false);
			if (dateTo == '') setValidDateTo(false);
			if (dateFrom && dateTo) {
				const durationStr = isActiveRangeDurationInTabDates
					? `Duración ${rangeDurationInTabDates[0]}-${rangeDurationInTabDates[1]} días`
					: 'Cualquier duración';
				const titleToSet = `Viajando entre ${formatDateToShowUser(dateFrom)} y ${formatDateToShowUser(dateTo)}`;
				setTitle([titleToSet, durationStr].join(', '));
			}
		} else {
			monthsWithYear.length > 0 &&
				handleSelectMonth(
					valueInMonthSelected,
					monthsWithYear.findIndex((item) => item.value == valueInMonthSelected),
				);
		}
	}, [
		dateFrom,
		dateTo,
		tabSelected,
		isActiveRangeDurationInTabDates,
		rangeDurationInTabDates,
		isActiveRangeDurationInTabMonth,
		rangeDurationInTabMonth,
	]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabSelected(newValue);
	};
	const handleSelectMonth = (month: number, index: number) => {
		const titleToSet = month == 0 ? 'Cualquier fecha' : `Viajando en ${monthsWithYear[index].label}`;
		const durationStr = isActiveRangeDurationInTabMonth
			? `Duración ${rangeDurationInTabMonth[0]}-${rangeDurationInTabMonth[1]} días`
			: 'Cualquier duración';
		setTitle([titleToSet, durationStr].join(', '));
		setValueInMonthSelected(month);
	};

	const getDateFrom = () => {
		if (dateFrom) {
			const momentObj = moment(dateFrom, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};
	const getDateTo = () => {
		if (dateTo) {
			const momentObj = moment(dateTo, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};

	const handleDateChange = (dateValue: unknown, option: DateType) => {
		const value =
			dateValue && isValidDate(dateValue as Date)
				? (dateValue as Date).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0]
				: '';

		switch (option) {
			case DateType.DATEFROM:
				value && setDateFrom(value);
				setValidDateFrom(value != '');
				break;
			case DateType.DATETO:
				value && setDateTo(value);
				setValidDateTo(value != '');
				break;
			default:
				break;
		}
	};

	const getValue = (): IFlightExplorerDatesFilterValueContainer | null => {
		if (tabSelected == tabMonthValueType) {
			return {
				valueType: tabMonthValueType,
				title: title,
				...(isActiveRangeDurationInTabMonth &&
					rangeDurationInTabMonth.length == 2 && {
						duration: { from: rangeDurationInTabMonth[0], to: rangeDurationInTabMonth[1] },
					}),
				value: {
					travelInMonth: valueInMonthSelected,
				},
			};
		} else {
			if (!validDateFrom || !validDateTo) {
				return null;
			}
			return {
				valueType: tabDatesValueType,
				...(isActiveRangeDurationInTabDates &&
					rangeDurationInTabDates.length == 2 && {
						duration: { from: rangeDurationInTabDates[0], to: rangeDurationInTabDates[1] },
					}),
				title: title,
				value: {
					travelDateFrom: dateFrom || '',
					travelDateTo: dateTo || '',
				},
			};
		}
	};
	const handleApply = () => {
		const value = getValue();
		if (value) {
			onClose(value);
		}
	};

	const handleChangeRangeDurationInTabMonth = (event: Event, newValue: number | number[]) => {
		setRangeDurationInTabMonth(newValue as number[]);
	};

	const handleChangeRangeDurationInTabDates = (event: Event, newValue: number | number[]) => {
		setRangeDurationInTabDates(newValue as number[]);
	};

	const valuetextRangeDays = (value: number) => {
		return `${value} días`;
	};

	return (
		<Dialog onClose={onClose} open={open} maxWidth="md">
			<DialogTitle>
				<DateRangeIcon /> {title}
			</DialogTitle>
			<DialogContent>
				<Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={tabSelected}
						onChange={handleChange}
						aria-label="Vertical tabs example"
						sx={{ borderRight: 1, borderColor: 'divider' }}
					>
						<Tab label="Fecha de salida en el mes" {...a11yProps(0)} />
						<Tab label="Fechas de salida en el rango" {...a11yProps(1)} />
					</Tabs>
					<TabPanel value={tabSelected} index={tabMonthValueType}>
						<Typography component={'h1'}>Rango</Typography>
						<FormControl>
							<RadioGroup name="radio-buttons-group" value={valueInMonthSelected}>
								<Grid container spacing={1} maxWidth={'400px'}>
									<Grid item xs={12}>
										<FormControlLabel
											value={0}
											control={<Radio onClick={() => handleSelectMonth(0, -1)} />}
											label={
												<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Cualquier fecha</span>
											}
										/>
									</Grid>
									{monthsWithYear.map((item, index) => (
										<Grid item xs={4} key={`${item.label}`}>
											<FormControlLabel
												value={item.value}
												control={<Radio onClick={() => handleSelectMonth(item.value, index)} />}
												label={
													<span
														style={{
															color: theme.palette.primary.main,
															fontSize: '13px',
														}}
													>
														{' '}
														{item.label}
													</span>
												}
											/>
										</Grid>
									))}
								</Grid>
							</RadioGroup>

							<Box sx={{ mt: 2 }}>
								<Checkbox
									checked={isActiveRangeDurationInTabMonth}
									onChange={(event, checked) => setIsActiveRangeDurationInTabMonth(checked)}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
								<Typography component={'h1'} style={{ display: 'inline' }}>
									Duración ({rangeDurationInTabMonth[0]} - {rangeDurationInTabMonth[1]} días)
								</Typography>
								<Slider
									disabled={!isActiveRangeDurationInTabMonth}
									getAriaLabel={() => 'rango de días'}
									value={rangeDurationInTabMonth}
									onChange={handleChangeRangeDurationInTabMonth}
									valueLabelDisplay="auto"
									getAriaValueText={valuetextRangeDays}
									min={defaultRangeDuration[0]}
									max={defaultRangeDuration[1]}
								/>
							</Box>
						</FormControl>
					</TabPanel>
					<TabPanel value={tabSelected} index={tabDatesValueType}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								inputFormat="dd/MM/yyyy"
								renderInput={(params) => (
									<TextField {...params} sx={{ width: '100%' }} label="Desde" variant="standard" />
								)}
								value={getDateFrom()}
								minDate={new Date()}
								onChange={(date) => handleDateChange(date, DateType.DATEFROM)}
								OpenPickerButtonProps={{
									'aria-label': 'cambiar fecha',
								}}
							/>
							<div></div>
							<DatePicker
								inputFormat="dd/MM/yyyy"
								minDate={getDateFrom()}
								renderInput={(params) => (
									<TextField {...params} sx={{ width: '100%' }} label="Hasta" variant="standard" />
								)}
								value={getDateTo()}
								onChange={(date) => handleDateChange(date, DateType.DATETO)}
								OpenPickerButtonProps={{
									'aria-label': 'cambiar fecha',
								}}
							/>
						</LocalizationProvider>

						<Box sx={{ mt: 2 }}>
							<Checkbox
								checked={isActiveRangeDurationInTabDates}
								onChange={(event, checked) => setIsActiveRangeDurationInTabDates(checked)}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
							<Typography component={'h1'} style={{ display: 'inline' }}>
								Duración ({rangeDurationInTabDates[0]} - {rangeDurationInTabDates[1]} días)
							</Typography>
							<Slider
								disabled={!isActiveRangeDurationInTabDates}
								getAriaLabel={() => 'rango de días'}
								value={rangeDurationInTabDates}
								onChange={handleChangeRangeDurationInTabDates}
								valueLabelDisplay="auto"
								getAriaValueText={valuetextRangeDays}
								min={defaultRangeDuration[0]}
								max={defaultRangeDuration[1]}
							/>
						</Box>
					</TabPanel>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					style={{ marginRight: 15 }}
					color="primary"
					disabled={getValue() == null}
					onClick={handleApply}
				>
					Aplicar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
