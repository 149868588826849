import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { IFlightExplorerDestination } from '../../types';
import { getDestinationImage } from '../../services';
import loadingImage from '../../../../assets/loading.gif';
import notImage from '../../../../assets/destination-without-image.jpg';
import NumberFormat from 'react-number-format';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	normalItemText: {
		fontSize: '12px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 'normal',
		fontStyle: 'normal',
		textAlign: 'right',
	},
}));

export interface FlightExplorerDestinationCardProps {
	destination: IFlightExplorerDestination;
	handleDestinationCardClick: (destination: IFlightExplorerDestination) => void;
}
export const FlightExplorerDestinationCard = ({
	destination,
	handleDestinationCardClick,
}: FlightExplorerDestinationCardProps): JSX.Element => {
	const theme = useTheme();
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	useEffect(() => {
		const fetchApi = async () => {
			const result = await getDestinationImage(destination.sluggedName);
			const maybeUrl = result.data.data?.allCities[0]?.background.publicUrl;
			if (maybeUrl) setImageUrl(maybeUrl);
			try {
				setLoading(true);

				setLoading(false);
			} catch (error: any) {
				setLoading(false);
			}
		};
		fetchApi();
	}, []);

	const getStopDescriptions = () => {
		if (destination.flightCheapest.maxStops === 0) return 'Directo';
		if (destination.flightCheapest.maxStops === 1) return '1 Escala';
		return `${destination.flightCheapest.maxStops} Escalas`;
	};

	return (
		<Card sx={{ display: 'flex' }} onClick={() => handleDestinationCardClick(destination)}>
			{imageUrl && <CardMedia component="img" sx={{ width: 130 }} image={imageUrl} alt={destination.name} />}
			{loading && !imageUrl && (
				<CardMedia component="img" sx={{ width: 130 }} image={loadingImage} alt={destination.name} />
			)}
			{!loading && !imageUrl && (
				<CardMedia component="img" sx={{ width: 130 }} image={notImage} alt={destination.name} />
			)}
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<CardContent sx={{ flex: '1 0 auto' }}>
					<Typography component="div" variant="body1" color={theme.palette.primary.main}>
						{destination.name}
					</Typography>
					<Typography variant="subtitle1" component="div" fontSize={12}>
						{getStopDescriptions()} - {destination.flightCheapest.totalDuration.totalHours} horas{' '}
						{destination.flightCheapest.totalDuration.totalMinutes} minutos
					</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', pl: 1, pb: 1, gap: '5px' }}>
						<Typography
							variant="subtitle1"
							component="span"
							fontSize={13}
							color={theme.palette.secondary.main}
							fontWeight={'bold'}
						>
							{destination.flightCheapest.price.currency} {destination.flightCheapest.price.amount}
						</Typography>
						<Typography
							variant="subtitle1"
							component="span"
							fontSize={13}
							color={theme.palette.secondary.main}
							fontWeight={'normal'}
						>
							{' '}
							por adulto
						</Typography>
					</Box>
					{destination.flightCheapest.localPrice && (
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', pl: 1, pb: 1, gap: '5px' }}>
							<Typography className={classes.normalItemText} component={'p'}>
								<NumberFormat
									value={destination.flightCheapest.localPrice.amount}
									displayType={'text'}
									decimalSeparator={','}
									thousandSeparator={'.'}
									prefix={`${destination.flightCheapest.localPrice.currency} `}
								/>
							</Typography>
						</Box>
					)}
				</CardContent>
			</Box>
		</Card>
	);
};
