import makeStyles from '@mui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: theme.palette.common.white,
	},
	container: {
		maxWidth: 1100,
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: theme.shape.borderRadius,
		marginTop: theme.spacing(3),
	},
	containerHeader: {
		justifyContent: 'space-between',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	containerIcon: {
		display: 'flex',
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(5),
	},
	infoIcon: {
		display: 'flex',
		backgroundColor: theme.palette.warning.contrastText,
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(1),
	},
	title: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		color: theme.palette.grey[600],
	},
	icon: {
		color: theme.palette.warning.main,
		marginRight: theme.spacing(1),
	},
	iconHeader: {
		color: theme.palette.grey[600],
		paddingRight: theme.spacing(1),
	},
	buttonSave: {
		marginTop: theme.spacing(3),
		backgroundColor: theme.palette.success.light,
		padding: theme.spacing(2),
		color: theme.palette.common.white,
	},
	containerButtom: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	buttonAdd: {
		marginTop: theme.spacing(3),
		border: theme.spacing(0),
		color: theme.palette.warning.light,
	},
	card: {
		marginBottom: theme.spacing(5),
		marginLeft: theme.spacing(0),
		background: theme.palette.grey[100],
		display: 'flex',
		borderRadius: theme.shape.borderRadius,
		border: '1px solid rgba(0, 0, 0, 0.12)',
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	buttonExpande: {
		border: theme.spacing(0),
		background: theme.palette.common.white,
	},
	main: {
		marginTop: theme.spacing(5),
		padding: theme.spacing(2),
		paddingRight: theme.spacing(4),
	},
	colorService: {
		color: theme.palette.warning.main,
	},
	boxSelect: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(1),
	},
	iconSelect: {
		color: theme.palette.common.black,
		marginRight: theme.spacing(2),
	},
	buttonDeleter: {
		marginTop: theme.spacing(3),
		border: theme.spacing(0),
		color: theme.palette.error.main,
	},
	select: {
		width: '100%',
		backgroundColor: theme.palette.common.white,
	},
	helperText: {
		color: theme.palette.error.main,
	},
}));
