import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';

import { emailPattern } from 'features/common/constants';
import { checkShowErrorMessage } from 'features/common/helpers';
import { ControlledSelect, Option } from 'features/common/components/ControlledSelect';
import { IPassengerForm, NetsuitePlaces, NetsuiteTaxDataResponse, TaxDataItem } from 'features/salesOrder/types';
import { OrderFormFieldsMaxLength } from 'features/payment/types';

import { CountryValues, fieldRuleOptions, renderOptions } from './types';
import { paxFormFields } from './constants';
import { ControlledDatePicker } from 'features/common/components/ControlledDatePicker';
import { countries } from 'features/common/constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getNsCountryId = (places: NetsuitePlaces, countryCode: string) => {
	const mvCountry = countries.find((mvc) => mvc.code === countryCode);
	const nsCountry = places.countries.find((nsc) => nsc.name === mvCountry?.name);
	return nsCountry?.id || 1;
};

export const getTaxData = (
	country: string,
	taxDataItems?: TaxDataItem[],
	invoiceable?: boolean,
	isPhysicalPerson?: boolean,
): Option[] => {
	if (!taxDataItems) return [];

	return taxDataItems
		.filter((item) => {
			let conditionResult = item.countryCode === country;

			if (invoiceable || isPhysicalPerson) {
				const isAllowToJuridicPerson = Boolean(!isPhysicalPerson);
				const isAllowToPhysicalPersonInvoiceable = Boolean(isPhysicalPerson);

				if (!isPhysicalPerson) {
					conditionResult = item.countryCode === country && item.isAllowToJuridicPerson === isAllowToJuridicPerson;
				} else {
					conditionResult =
						item.countryCode === country &&
						item.isAllowToPhysicalPersonInvoiceable === isAllowToPhysicalPersonInvoiceable;
				}
			}

			return conditionResult;
		})
		.map(({ id, name }) => ({ id: String(id), name }));
};

export const getState = (country: string, places?: NetsuitePlaces): Option[] => {
	if (!places) return [];
	return places.states
		.filter((state) => state.countryCode === country)
		.map(({ id, name }) => ({ id: String(id), name }));
};

export const getProvinces = (state: string, places?: NetsuitePlaces): Option[] => {
	if (!places) return [];

	return places.provinces.filter((p) => p.stateId === Number(state)).map(({ id, name }) => ({ id: String(id), name }));
};

export const getMunicipalities = (province: string, places?: NetsuitePlaces): Option[] => {
	if (!places) return [];
	return places.municipalities
		.filter((municipality) => municipality.provinceId === Number(province))
		.map(({ id, name }) => ({ id: String(id), name }));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const doesDocumentNeedVerification = (taxData: NetsuiteTaxDataResponse, selectedId: string) => {
	if (Boolean(selectedId) === false) return false;
	const myDoc = taxData.documentTypes.find((d) => d.id === Number(selectedId));
	if (!myDoc) return false;
	return Boolean(myDoc.needVerificationNumber);
};

export const getFieldRuleOption = (
	fieldName: string,
	country: CountryValues,
	isPhysicalPerson?: boolean,
	isFacturable?: boolean,
): fieldRuleOptions => {
	const fieldMacro = paxFormFields[fieldName];
	if (!fieldMacro) {
		// la key que se le manda no existe
		return fieldRuleOptions.noshow;
	}
	const field = fieldMacro[country];

	let renderOption = field.juridica;
	if (isPhysicalPerson && Boolean(isFacturable) === false) {
		renderOption = field.fisicaNoFacturable;
	} else if (isPhysicalPerson && Boolean(isFacturable) === true) {
		renderOption = field.fisicaFacturable;
	}

	return renderOption;
};

export const getFieldType = (fieldName: string, country: CountryValues): renderOptions => {
	const fieldMacro = paxFormFields[fieldName];
	const field = fieldMacro[country];

	return field.render;
};

export const getPaxField = (
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	classes: any,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	errors: any,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	control: any,
	fieldName: string,
	country: CountryValues,
	isPhysicalPerson?: boolean,
	isFacturable?: boolean,
	selectOptions?: Option[],
): JSX.Element | null => {
	const fieldMacro = paxFormFields[fieldName];
	if (!fieldMacro) {
		// la key que se le manda no existe
		return null;
	}
	const field = fieldMacro[country];

	const renderOption = getFieldRuleOption(fieldName, country, isPhysicalPerson, isFacturable);

	if (renderOption === fieldRuleOptions.noshow) {
		return null;
	}

	if (field.render === renderOptions.textfield) {
		return (
			<>
				<Typography className={classes.title}>
					{isPhysicalPerson ? field.label : field.labelJuridico ? field.labelJuridico : field.label}
					{renderOption === fieldRuleOptions.required && <span style={{ color: 'red' }}>*</span>}
				</Typography>
				<Controller
					// @ts-ignore
					name={fieldName}
					control={control}
					rules={{
						required: { value: renderOption === fieldRuleOptions.required, message: 'Este campo es requerido' },
					}}
					render={({ field }) => (
						<TextField
							variant="outlined"
							required={renderOption === fieldRuleOptions.required}
							fullWidth
							size="small"
							className={classes.textField}
							helperText={checkShowErrorMessage(Boolean(errors[fieldName]), errors[fieldName]?.message)}
							error={Boolean(errors[fieldName])}
							{...field}
						/>
					)}
				/>
			</>
		);
	} else if (field.render === renderOptions.email) {
		return (
			<>
				<Typography className={classes.title}>
					{field.label} {renderOption === fieldRuleOptions.required && <span style={{ color: 'red' }}>*</span>}
				</Typography>
				<Controller
					// @ts-ignore
					name={fieldName}
					control={control}
					rules={{
						required: { value: renderOption === fieldRuleOptions.required, message: 'Este campo es requerido' },
						maxLength: {
							value: OrderFormFieldsMaxLength.EMAIL,
							message:
								'El correo electrónico excede el largo máximo permitido de: ' +
								OrderFormFieldsMaxLength.EMAIL +
								' caracteres',
						},
						pattern: {
							value: emailPattern,
							message: 'Ingrese un email válido',
						},
					}}
					render={({ field }) => (
						<TextField
							variant="outlined"
							fullWidth
							required={renderOption === fieldRuleOptions.required}
							size="small"
							InputLabelProps={{ shrink: true }}
							className={classes.textField}
							helperText={checkShowErrorMessage(Boolean(errors[fieldName]), errors[fieldName]?.message)}
							error={Boolean(errors[fieldName])}
							{...field}
						/>
					)}
				/>
			</>
		);
	} else if (field.render === renderOptions.phone) {
		return (
			<>
				<Typography className={classes.title}>
					{field.label} {renderOption === fieldRuleOptions.required && <span style={{ color: 'red' }}>*</span>}
				</Typography>
				<Controller
					// @ts-ignore
					name={fieldName}
					control={control}
					rules={{
						required: { value: renderOption === fieldRuleOptions.required, message: 'Este campo es requerido' },
						maxLength: {
							value: OrderFormFieldsMaxLength.PHONE,
							message:
								'El telefono excede el largo máximo permitido de: ' + OrderFormFieldsMaxLength.PHONE + ' caracteres',
						},
					}}
					render={({ field }) => (
						<TextField
							variant="outlined"
							fullWidth
							required={renderOption === fieldRuleOptions.required}
							size="small"
							InputLabelProps={{ shrink: true }}
							className={classes.textField}
							helperText={checkShowErrorMessage(Boolean(errors[fieldName]), errors[fieldName]?.message)}
							error={Boolean(errors[fieldName])}
							{...field}
						/>
					)}
				/>
			</>
		);
	} else if (field.render === renderOptions.select && selectOptions) {
		return (
			<>
				<Typography className={classes.title}>
					{field.label} {renderOption === fieldRuleOptions.required && <span style={{ color: 'red' }}>*</span>}
				</Typography>
				<ControlledSelect
					// @ts-ignore
					name={fieldName}
					required={renderOption === fieldRuleOptions.required}
					rules={{
						required: { value: renderOption === fieldRuleOptions.required, message: 'Este campo es requerido' },
					}}
					options={selectOptions}
				/>
			</>
		);
	} else if (field.render === renderOptions.date) {
		return (
			<>
				<Typography className={classes.title}>
					{field.label} {renderOption === fieldRuleOptions.required && <span style={{ color: 'red' }}>*</span>}
				</Typography>
				<ControlledDatePicker
					// @ts-ignore
					name={fieldName}
					rules={{ required: 'Este campo es requerido' }}
					inputFormat="dd/MM/yyyy"
					required={renderOption === fieldRuleOptions.required}
				/>
			</>
		);
	}

	return null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const payloadParser = (
	theCountry: CountryValues,
	isInvoiceable: boolean,
	isPhysicalPerson: boolean,
	data: IPassengerForm,
	theCountryNsId: string,
	taxData: NetsuiteTaxDataResponse,
) => {
	if (!isInvoiceable && isPhysicalPerson) {
		// si no es facturable y es una persona fisica (un viajero plano y llano)

		const retObj = {
			name: data.name,
			lastName: data.lastName,
			address: data.address, //opcional
			countryId: Number(Number(data.countryId)),
			birthdate: data.birthdate,
			phone: data.phone, // opcional
			email: data.email, // opcional
		};

		return retObj;
	} else if (theCountry === 'UY') {
		// En uruguay no importa si sos persona juridica o fisica
		// siempre te pido lo mismo datos si sos facturable
		//  por eso no hacemos diferencia entre estados

		const retObj = {
			name: data.name,
			lastName: data.lastName,
			address: data.address,
			countryId: Number(data.countryId),
			phone: data.phone,
			email: data.email,
			documentType: Number(data.documentType),
			documentNumber: data.documentNumber,
		};

		if (theCountryNsId === data.countryId) {
			// @ts-ignore
			retObj.state = data.state;
			// @ts-ignore
			retObj.province = data.province;
		}

		if (data.documentVerificationNumber && doesDocumentNeedVerification(taxData, String(data.documentType))) {
			// @ts-ignore
			retObj.documentVerificationNumber = data.documentVerificationNumber;
		}

		if (isPhysicalPerson) {
			// @ts-ignore
			retObj.birthdate = data.birthdate;
		}

		return retObj;
	} else if (theCountry === 'PY') {
		if (isPhysicalPerson) {
			// es fisica y facturable

			const retObj = {
				name: data.name,
				lastName: data.lastName,
				address: data.address,
				countryId: Number(data.countryId),
				birthdate: data.birthdate,
				phone: data.phone,
				email: data.email,
				taxPayerTypeId: 13,
				documentType: Number(data.documentType),
				documentNumber: data.documentNumber,
			};

			if (theCountryNsId === data.countryId) {
				// @ts-ignore
				retObj.state = data.state;
				// @ts-ignore
				retObj.province = data.province;
				// @ts-ignore
				retObj.municipality = data.municipality;
			}

			if (data.documentVerificationNumber && doesDocumentNeedVerification(taxData, String(data.documentType))) {
				// @ts-ignore
				retObj.documentVerificationNumber = data.documentVerificationNumber;
			}

			return retObj;
		} else {
			// persona juridica

			const retObj = {
				name: data.name,
				lastName: data.lastName,
				address: data.address,
				countryId: Number(data.countryId),
				phone: data.phone,
				email: data.email,
				taxPayerTypeId: 14,
				documentType: Number(data.documentType),
				documentNumber: data.documentNumber,
			};

			if (theCountryNsId === data.countryId) {
				// @ts-ignore
				retObj.state = data.state;
				// @ts-ignore
				retObj.province = data.province;
				// @ts-ignore
				retObj.municipality = data.municipality;
			}

			if (data.documentVerificationNumber && doesDocumentNeedVerification(taxData, String(data.documentType))) {
				// @ts-ignore
				retObj.documentVerificationNumber = data.documentVerificationNumber;
			}

			return retObj;
		}
	} else if (theCountry === 'CO') {
		// En colombia no importa si sos persona fisica o juridica
		// se piden los mismos datos si sos facturable

		const retObj = {
			name: data.name,
			lastName: data.lastName,
			address: data.address,
			postalCode: data.postalCode,
			countryId: Number(data.countryId),
			phone: data.phone,
			email: data.email,
			taxPayerTypeId: Number(data.taxPayerTypeId),
			taxRegimeId: Number(data.taxRegimeId),
			taxResidenceTypeId: Number(data.taxResidenceTypeId),
			fiscalResponsibilityId: Number(data.fiscalResponsibilityId),
			documentType: Number(data.documentType),
			documentNumber: data.documentNumber,
		};

		if (theCountryNsId === data.countryId) {
			// @ts-ignore
			retObj.state = data.state;
			// @ts-ignore
			retObj.province = data.province;
		}

		if (data.documentVerificationNumber && doesDocumentNeedVerification(taxData, String(data.documentType))) {
			// @ts-ignore
			retObj.documentVerificationNumber = data.documentVerificationNumber;
		}

		if (isPhysicalPerson) {
			// @ts-ignore
			retObj.birthdate = data.birthdate;
		}

		return retObj;
	} else {
		// theCountry === BO

		// En bolivia no importa si sos persona fisica o juridica
		// se piden los mismos datos si sos facturable

		const retObj = {
			name: data.name,
			lastName: data.lastName,
			address: data.address,
			countryId: Number(data.countryId),
			phone: data.phone,
			email: data.email,
			documentType: Number(data.documentType),
			documentNumber: data.documentNumber,
		};

		if (theCountryNsId === data.countryId) {
			// @ts-ignore
			retObj.state = data.state;
			// @ts-ignore
			retObj.province = data.province;
			// @ts-ignore
			retObj.municipality = data.municipality;
		}

		if (data.documentVerificationNumber && doesDocumentNeedVerification(taxData, String(data.documentType))) {
			// @ts-ignore
			retObj.documentVerificationNumber = data.documentVerificationNumber;
		}

		if (isPhysicalPerson) {
			// @ts-ignore
			retObj.birthdate = data.birthdate;
		}

		return retObj;
	}
};
