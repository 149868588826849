import React, { useMemo, useState } from 'react';
import { GoogleMap, InfoWindow, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { GOOGLE_MAPS_KEY } from '../../../../constants/constants';
import { IFlightExplorerDepartureDestination, IFlightExplorerDestination } from '../../types';
import airport from '../../../../assets/airport.png';
import { useTheme } from '@mui/styles';

interface Props {
	departure: IFlightExplorerDepartureDestination;
	destinations: IFlightExplorerDestination[];
	handleClick: (destination: IFlightExplorerDestination) => void;
	destinationActive: IFlightExplorerDestination | null;
}

interface ThisWindow extends Window {
	google: any;
}

declare const window: ThisWindow;
// eslint-disable-next-line react/prop-types

export const FlightExplorerMap = ({ departure, destinations, handleClick, destinationActive }: Props): JSX.Element => {
	const [mapLibraryLoaded, setMapLibraryLoaded] = useState<boolean>(false);

	const theme = useTheme();
	const onLoadMapScript = () => setMapLibraryLoaded(true);

	const lineSymbol = {
		path: 'M 0,-1 0,1',
		strokeOpacity: 1,
		scale: 2,
	};
	const markers = useMemo(
		() =>
			mapLibraryLoaded &&
			destinations &&
			destinations.map((destination) => (
				<Marker
					key={destination.code}
					position={{ lat: destination.lat, lng: destination.lng }}
					onClick={() => handleClick(destination)}
					title={`${destination.name}, ${destination.countryName} - ${destination.flightCheapest.price.amount} ${destination.flightCheapest.price.currency}`}
					icon={{
						url: airport,
						scaledSize: new window.google.maps.Size(20, 20),
					}}
				>
					{destinationActive && destinationActive.code == destination.code && (
						<InfoWindow
							position={{
								lat: destination.lat,
								lng: destination.lng,
							}}
							options={{
								pixelOffset: new window.google.maps.Size(0, -5),
								maxWidth: 'none',
							}}
						>
							<div
								style={{
									border: '1px',
									borderRadius: '10px',
									borderColor: theme.palette.primary.main,
									width: '100px',
								}}
							>
								<p>{destination.name}</p>
								<p>
									{destination.flightCheapest.price.currency} {destination.flightCheapest.price.amount}
								</p>
							</div>
						</InfoWindow>
					)}
				</Marker>
			)),
		[destinations, mapLibraryLoaded, destinationActive],
	);

	return (
		<>
			<div style={{ height: '90vh', width: '100%' }}>
				<LoadScript id="script-loader" googleMapsApiKey={GOOGLE_MAPS_KEY} onLoad={onLoadMapScript}>
					<GoogleMap
						id="availability-map"
						mapContainerStyle={{
							height: '100%',
							width: '100%',
							borderRadius: '14px',
						}}
						zoom={3}
						clickableIcons={false}
						options={{
							zoomControl: true,
							mapTypeControl: false,
							scaleControl: false,
							streetViewControl: false,
							rotateControl: false,
							fullscreenControl: false,
							gestureHandling: 'greedy',
						}}
						center={{ lat: 0, lng: 0 }}
					>
						{markers}
						<Marker
							key={departure.code}
							position={{ lat: departure.lat, lng: departure.lng }}
							title={`Saliendo desde ${departure.name}`}
						></Marker>
						{/*{overlayViews}*/}
						{destinationActive && (
							<Polyline
								options={{
									strokeColor: theme.palette.primary.main,
									strokeWeight: 1,
									geodesic: true,
									strokeOpacity: 0,
									icons: [
										{
											icon: lineSymbol,
											offset: '0',
											repeat: '20px',
										},
									],
								}}
								path={[
									{
										lat: departure.lat,
										lng: departure.lng,
									},
									{ lat: destinationActive.lat, lng: destinationActive.lng },
								]}
							/>
						)}
					</GoogleMap>
				</LoadScript>
			</div>
		</>
	);
};
