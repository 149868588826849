import React, { useEffect, useState } from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Snackbar,
	TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { BankAccountCountry, IBanksByCountry, ICurrenciesByCountry, IWireTransferCheckFilter } from '../../types';
import { checkShowErrorMessage } from 'features/common/helpers';
import { checkWireTransfer, getBanksByCountry, getCurrenciesByCountry } from 'features/payment/services';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment/moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import { setWireTransferCheckResult } from '../../slice';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

export const WireTransferCheckForm = (): JSX.Element => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const getDate = () => {
		return moment(new Date().toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0], 'YYYY-MM-DD')
			.subtract(3, 'days')
			.toDate();
	};

	const formDefaultValues = {
		country: '',
		currency: '',
		amount: 1,
		bank: '',
		date: getDate(),
		time: getDate(),
	};

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues,
		setValue,
	} = useForm({ mode: 'all', defaultValues: formDefaultValues });
	const [currenciesByCountry, setCurrenciesByCountry] = useState<ICurrenciesByCountry[]>([]);
	const [banksByCountry, setBanksByCountry] = useState<IBanksByCountry[]>([]);
	const [banksToShow, setBanksToShow] = useState<string[]>([]);
	const [currenciesToShow, setCurrenciesToShow] = useState<string[]>([]);
	const [bankAccountCountries, setBankAccountCountries] = useState<BankAccountCountry[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	const onSubmit = async () => {
		const valuesFromForm = getValues();
		const dateStr = valuesFromForm.date.toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0];
		const timeStr = (valuesFromForm.time as Date).toLocaleTimeString('en-GB', {
			hour: '2-digit',
			minute: '2-digit',
		});
		const filter = {
			country: valuesFromForm.country,
			currency: valuesFromForm.currency,
			amount: valuesFromForm.amount,
			dateFrom: `${dateStr}T${timeStr}`,
		} as IWireTransferCheckFilter;

		if (valuesFromForm.bank != '') {
			filter.bank = valuesFromForm.bank;
		}

		try {
			setLoading(true);
			dispatch(setWireTransferCheckResult(null));
			const result = (await checkWireTransfer(filter)).data;
			dispatch(setWireTransferCheckResult(result));
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al chequear la transferencia bancaria'),
			});
			setLoading(false);
		}
	};

	const getCurrenciesByCountryAtLoad = async () => {
		try {
			setLoading(true);
			const currenciesByCountry = (await getCurrenciesByCountry()).data;
			setCurrenciesByCountry(currenciesByCountry);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir las Monedas'),
			});
			setLoading(false);
		}
	};

	const handleSelectCountry = (value: BankAccountCountry) => {
		setBanksToShow(banksByCountry.filter((bank) => bank.country == value).map((bank) => bank.banks)[0]);
		setCurrenciesToShow(
			currenciesByCountry.filter((currency) => currency.country == value).map((currency) => currency.currencies)[0],
		);
		setValue('currency', '');
		setValue('bank', '');
	};

	const getBanksByCountryAtLoad = async () => {
		try {
			setLoading(true);
			const banksByCountry = (await getBanksByCountry()).data;
			setBankAccountCountries(banksByCountry.map((bank) => bank.country));
			setBanksByCountry(banksByCountry);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los Bancos'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getCurrenciesByCountryAtLoad();
	}, []);

	useEffect(() => {
		getBanksByCountryAtLoad();
	}, []);

	const handleSelectedCountry = (event: SelectChangeEvent<unknown>, onChange: (...event: any) => void) => {
		const {
			target: { value },
		} = event;
		onChange(value);
		handleSelectCountry(value as BankAccountCountry);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container gap={2}>
					<Grid item xs={12}>
						<Controller
							name="country"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Por favor seleccione el país',
								},
							}}
							render={({ field: { onChange }, fieldState: { error } }) => (
								<FormControl sx={{ width: '100%', height: '100%' }}>
									<InputLabel id="country-label">País</InputLabel>
									<Select
										labelId="country-label"
										label={'País'}
										required
										disabled={loading}
										sx={{ width: '100%' }}
										onChange={(event) => handleSelectedCountry(event, onChange)}
									>
										{bankAccountCountries.map((bankAccountCountry) => {
											return (
												<MenuItem key={bankAccountCountry} value={bankAccountCountry}>
													{bankAccountCountry}
												</MenuItem>
											);
										})}
									</Select>
									{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
								</FormControl>
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Controller
							name="bank"
							control={control}
							render={({ field, fieldState: { error } }) => (
								<FormControl sx={{ width: '100%', height: '100%' }}>
									<InputLabel id="bank-label" shrink>
										Banco
									</InputLabel>
									<Select
										error={Boolean(errors.bank)}
										labelId="bank-label"
										label={'Banco'}
										disabled={loading}
										sx={{ width: '100%' }}
										{...field}
										displayEmpty
									>
										<MenuItem value={''}>Todos</MenuItem>
										{banksToShow.map((bank) => {
											return (
												<MenuItem key={bank} value={bank}>
													{bank}
												</MenuItem>
											);
										})}
									</Select>
									{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
								</FormControl>
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Controller
							name="currency"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Por favor seleccione la moneda',
								},
							}}
							render={({ field }) => (
								<FormControl sx={{ width: '100%', height: '100%' }}>
									<InputLabel id="currency-label">Moneda</InputLabel>
									<Select
										labelId="currency-label"
										label={'Moneda'}
										disabled={loading}
										required
										sx={{ width: '100%' }}
										{...field}
									>
										{currenciesToShow.map((currency) => {
											return (
												<MenuItem key={currency} value={currency}>
													{currency}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Controller
							name="amount"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								min: {
									value: 1,
									message: 'El monto debe ser mayor que 0',
								},
								pattern: {
									value: /^(0|[1-9]\d*)(\.\d+)?$/,
									message: 'El valor debe ser numérico',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Monto"
									type="number"
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.amount), errors.amount?.message)}
									error={Boolean(errors.amount)}
									{...field}
								/>
							)}
						/>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={7} sm={7}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<Controller
									name="date"
									control={control}
									rules={{ required: { value: true, message: 'Este campo es requerido' } }}
									render={({ field }) => (
										<DatePicker
											inputFormat="dd/MM/yyyy"
											renderInput={(params) => (
												<TextField
													required
													{...params}
													variant="outlined"
													helperText={params?.inputProps?.placeholder}
												/>
											)}
											label="Fecha desde"
											OpenPickerButtonProps={{
												'aria-label': 'cambiar fecha',
											}}
											{...field}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>

						<Grid item xs={5} sm={5}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<Controller
									name="time"
									control={control}
									rules={{ required: { value: true, message: 'Este campo es requerido' } }}
									render={({ field }) => (
										<TimePicker
											ampm={false}
											openTo="hours"
											views={['hours', 'minutes']}
											inputFormat="HH:mm"
											mask="__:__"
											label="Hora desde"
											{...field}
											renderInput={(params) => <TextField required {...params} />}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>

					<Grid item xs={12} display={'flex'} gap={'10px'} justifyContent="flex-end">
						<Button variant="contained" disabled={loading || !isValid} color="success" fullWidth onClick={onSubmit}>
							Chequear
						</Button>
					</Grid>
				</Grid>
			</form>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
