import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StoreIcon from '@mui/icons-material/Store';
import withStyles from '@mui/styles/withStyles';

import visaLogo from '../../../../assets/visaLogo.png';
import masterLogo from '../../../../assets/masterLogo.png';
import amexLogo from '../../../../assets/amexLogo.png';
import itauLogo from '../../../../assets/itauLogo.svg';

const WhiteTextTypography = withStyles({
	root: {
		color: '#FFFFFF',
	},
})(Typography);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
			maxWidth: '100%',
		},
		cardsItem: {
			padding: '5px',
			display: 'inline-block',
			textAlign: 'center',
			verticalAlign: 'middle',
		},
		cardBoxHolder: {
			marginTop: 20,
		},
		cardBox: {
			width: 260,
		},
		consulta: {
			marginTop: 30,
		},
		background: {
			backgroundColor: theme.palette.primary.main,
			borderRadius: '8px',
		},
		itauBox: {
			backgroundColor: ' #EE7000',
			borderRadius: '8px',
			display: 'flex',
		},
		itauContentBox: {
			alignItems: 'center',
			display: 'flex',
			width: '40%',
			placeContent: 'space-evenly',
		},
		itauLogo: {
			width: '80px',
		},
		bankBox: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			backgroundColor: 'white',
			borderRadius: '8px',
			padding: '5px',
			marginTop: '5px',
		},
		noUnderlineLinks: {
			textDecoration: 'none',
			color: 'inherit',
		},
	}),
);

const ItauPYPaymentMethods = (): JSX.Element => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="md">
				<Box className={classes.background} paddingY={1} marginY={2}>
					<WhiteTextTypography variant="h6" align="center">
						NUESTRAS FORMAS DE PAGO:{' '}
					</WhiteTextTypography>
				</Box>
				<Box display="flex" justifyContent="space-around" alignItems="center">
					<Box display="flex" flexDirection="column" alignItems="center">
						<CreditCardIcon color="secondary" fontSize="large" />
						<Typography variant="subtitle2" align="center">
							Tarjetas de crédito y débito
						</Typography>
					</Box>
					<Box display="flex" flexDirection="column" alignItems="center">
						<StoreIcon color="secondary" fontSize="large" />
						<Typography variant="subtitle2" align="center">
							Puntos Itaú
						</Typography>
					</Box>
					<Box display="flex" flexDirection="column" alignItems="center">
						<AccountBalanceIcon color="secondary" fontSize="large" />
						<Typography variant="subtitle2" align="center">
							Tarjeta + Puntos{' '}
						</Typography>
					</Box>
				</Box>
				<Box className={classes.cardBoxHolder} display="flex" justifyContent="center" alignItems="flex-end">
					<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
						<Box className={classes.cardsItem}>
							<img src={visaLogo} alt="visa" />
						</Box>
						<Typography variant="subtitle2" align="center"></Typography>
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
						<Box className={classes.cardsItem}>
							<img src={amexLogo} alt="american-express" />
						</Box>
						<Typography variant="subtitle2" align="center"></Typography>
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
						<Box className={classes.cardsItem}>
							<img src={masterLogo} alt="master-card" />
						</Box>
						<Typography variant="subtitle2" align="center"></Typography>
					</Box>
				</Box>
				<Typography className={classes.consulta} variant="subtitle1" align="center">
					Aprovechá nuestros beneficios directos:
				</Typography>
				<a
					className={classes.noUnderlineLinks}
					href="https://www.itau.com.py/Beneficios/Detalle/6062-agenciadeviajes"
					target="_blank"
					rel="noreferrer"
				>
					<Box className={classes.itauBox} padding={2} justifyContent="center">
						<img src={itauLogo} className={classes.itauLogo} />
						<Box paddingX={2} className={classes.itauContentBox}>
							<Box>
								<WhiteTextTypography variant="h6" align="center">
									Hasta 15 cuotas sin intereses
								</WhiteTextTypography>
							</Box>
						</Box>
					</Box>
				</a>
				<Typography className={classes.consulta} variant="subtitle1" align="center">
					Tarjetas de crédito de otros bancos en un solo pago sin interés o en cuotas con recargo. Consultá con tu
					banco.
				</Typography>
			</Container>
		</div>
	);
};

export default ItauPYPaymentMethods;
