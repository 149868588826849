import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RootState } from 'store';

import { Service, ServiceOption } from '../types';
import { postServicesSuggestions } from '../../../services';
import DeleteIcon from '@mui/icons-material/Delete';
import { addServiceOptionToDestination, deleteServiceOptionToDestination } from '../slice';
import { calculateServicePrice } from '../helpers';
import { getPAXTotals } from '../selectors';
import moment from 'moment';
import { ServicesForm } from './ServicesForm';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
}));

interface DeleteServicePayload {
	destinationIndex: number;
	serviceOptionIndex: number;
}

export const ServicesConfig = (): JSX.Element => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { destination } = useSelector((state: RootState) => state.quotation);
	const paxTotals = useSelector(getPAXTotals);
	const [servicesOptions, setServicesOptions] = useState<ServiceOption[]>([]);

	const [openDeleteServiceConfirmDialog, setOpenDeleteServiceConfirmDialog] = React.useState(false);
	const initDeleteServicePayload = { destinationIndex: -1, serviceOptionIndex: -1 };
	const [deleteServicePayload, setDeleteServicePayload] =
		React.useState<DeleteServicePayload>(initDeleteServicePayload);

	const handleClickOpenConfirmDeleteService = (destinationIndex: number, serviceOptionIndex: number) => {
		setOpenDeleteServiceConfirmDialog(true);
		setDeleteServicePayload({ destinationIndex, serviceOptionIndex });
	};

	const handleCloseConfirmDeleteService = () => {
		setOpenDeleteServiceConfirmDialog(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const today = moment().format('YYYY-MM-DD');
				const from = destination[0]?.checking || today;
				const cityCodes = destination.map((item) => {
					return item.cityCode;
				});
				const result = await postServicesSuggestions(from, cityCodes);
				setServicesOptions(result.data);
			} catch (e) {
				console.warn(e);
			}
		};
		fetchData();
	}, [destination]);

	const handleAddService = (service: Service, destinationIndex: number) => {
		dispatch(addServiceOptionToDestination({ destinationIndex: destinationIndex, data: service }));
	};

	const handleDeleteServiceOption = () => {
		setOpenDeleteServiceConfirmDialog(false);
		// @ts-ignore
		dispatch(deleteServiceOptionToDestination(deleteServicePayload));
	};

	return useMemo(() => {
		return (
			<Container component="main" maxWidth="md">
				<div>
					<Dialog
						open={openDeleteServiceConfirmDialog}
						onClose={handleCloseConfirmDeleteService}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{'Eliminar el servicio'}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								¿Confirma la eliminación del servicio?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button variant="outlined" onClick={handleCloseConfirmDeleteService}>
								Cancelar
							</Button>
							<Button variant="contained" color="primary" onClick={handleDeleteServiceOption} autoFocus>
								Aceptar
							</Button>
						</DialogActions>
					</Dialog>
				</div>
				<div className={classes.paper}>
					<Typography component="h1" variant="h5">
						Servicios
					</Typography>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Nombre</TableCell>
									<TableCell>Tipo</TableCell>
									<TableCell align="right">Ciudad</TableCell>
									<TableCell align="right">Tarifa</TableCell>
									<TableCell align="left">descripcion</TableCell>
									<TableCell align="right">Precio ADT</TableCell>
									<TableCell align="right">Precio CHD</TableCell>
									<TableCell align="right">Precio INF</TableCell>
									<TableCell align="right" />
								</TableRow>
							</TableHead>
							<TableBody>
								{destination.map((destinationItem, indexDestination) => {
									return destinationItem.services.map((option, indexOption) => {
										const servicePrices = calculateServicePrice(
											paxTotals.adults,
											paxTotals.child,
											paxTotals.infants,
											option,
										);
										return (
											<TableRow key={indexOption}>
												<TableCell component="th" scope="row">
													{option.name}
												</TableCell>
												<TableCell>{option.serviceType}</TableCell>
												<TableCell align="right">{destinationItem.cityName}</TableCell>
												<TableCell align="right">{option.fareCost}</TableCell>
												<TableCell align="left">{option.description}</TableCell>
												<TableCell align="right">{servicePrices.adtPrice}</TableCell>
												<TableCell align="right">{servicePrices.chdPrice}</TableCell>
												<TableCell align="right">{servicePrices.infPrice}</TableCell>
												<TableCell align="right">
													<IconButton
														color="secondary"
														component="span"
														onClick={() => handleClickOpenConfirmDeleteService(indexDestination, indexOption)}
														size="large"
													>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									});
								})}
							</TableBody>
						</Table>
					</TableContainer>
					{destination.map(
						(destinationItem, index) =>
							destination.findIndex((x) => x.cityCode == destinationItem.cityCode) == index && (
								<div key={index}>
									<Typography component="h6" variant="h6">
										{destinationItem.cityName}
									</Typography>
									<ServicesForm
										handleAddService={handleAddService}
										servicesOptions={servicesOptions.filter(
											(option) =>
												option.forAllDestinations ||
												option.destinations
													.map((x) => x.code.toLowerCase())
													.some((x) => x == destinationItem.cityCode.toLowerCase()),
										)}
										destinationIndex={index}
									></ServicesForm>
								</div>
							),
					)}
				</div>
			</Container>
		);
	}, [destination, servicesOptions, openDeleteServiceConfirmDialog]);
};
