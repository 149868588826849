import React from 'react';
import { Layout } from '../../../features/common/components/Layout';
import { PackageExplorerDestinationDetail } from '../../../features/packagesExplorer/components/PackageExplorerDestinationDetail';

export const PackageExplorerDestinationDetailPage = (): JSX.Element => {
	return (
		<Layout>
			<PackageExplorerDestinationDetail />
		</Layout>
	);
};
