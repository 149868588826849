import React, { useState } from 'react';
import { Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import makeStyles from '@mui/styles/makeStyles';
import DatePicker from '@mui/lab/DatePicker';
import { CommonQuotationDestinationInfo, DestinationSuggestion } from '../../types';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	table: {
		minWidth: 650,
	},
}));

interface Props {
	destination: CommonQuotationDestinationInfo[];
	searchOptions: DestinationSuggestion[];
	onAutocompleteChange: (event: React.ChangeEvent<any>, value: DestinationSuggestion | null) => void;
	onChangeCity: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeNights: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onCheckingChange: (date: unknown) => void;
	onAddNew: () => void;
	destinationToAdd: CommonQuotationDestinationInfo;
}

export default function DestinationConfig(props: Props): JSX.Element {
	const classes = useStyles();
	const [keySelect, setKeySelect] = useState<string>('key-select-destination');
	const getDate = () => {
		const momentObj = moment(props.destinationToAdd.checking, 'YYYY-MM-DD');
		return momentObj.toDate();
	};

	const isValid = () => {
		return (
			props.destinationToAdd.cityCode != '' &&
			props.destinationToAdd.nights > 0 &&
			props.destinationToAdd.checking != ''
		);
	};

	const handlerOnnAddNewDestination = () => {
		props.onAddNew();
		setKeySelect(keySelect + '-key');
	};

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Selección de destino
				</Typography>

				<form className={classes.form} noValidate>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						{props.destination.map((item, index) => {
							return (
								<Grid container spacing={2} key={index}>
									<Grid item xs={12} sm={4}>
										<TextField
											name="city"
											variant="standard"
											label="Destino"
											size="small"
											fullWidth
											style={{ marginTop: -3 }}
											value={`${item.cityName}`}
											disabled
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											name="nights"
											variant="standard"
											label="Cantidad de noches"
											type="number"
											defaultValue={item.nights}
											size="small"
											fullWidth
											disabled
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											name="nights"
											variant="standard"
											label="CheckIn"
											value={item.checking}
											size="small"
											fullWidth
											disabled
										/>
									</Grid>
								</Grid>
							);
						})}
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<Autocomplete
									id="debug"
									options={props.searchOptions}
									key={keySelect}
									getOptionLabel={(option: DestinationSuggestion) => option.name}
									// @ts-ignore
									onChange={props.onAutocompleteChange}
									renderInput={(params) => (
										<TextField
											{...params}
											name="city"
											variant="standard"
											required
											label="Destino"
											size="small"
											onChange={props.onChangeCity}
											fullWidth
											style={{ marginTop: -3 }}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									name="nights"
									variant="standard"
									required
									label="Cantidad de noches"
									type="number"
									size="small"
									value={props.destinationToAdd.nights}
									onChange={props.onChangeNights}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<DatePicker
									inputFormat="dd/MM/yyyy"
									renderInput={(params) => (
										<TextField
											required
											{...params}
											variant="standard"
											id="checking"
											helperText={params?.inputProps?.placeholder}
										/>
									)}
									label="CheckIn"
									value={getDate()}
									onChange={props.onCheckingChange}
									OpenPickerButtonProps={{
										'aria-label': 'cambiar fecha',
									}}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row-reverse">
							<Button
								disabled={!isValid()}
								variant="contained"
								color="primary"
								style={{ margin: 8 }}
								onClick={handlerOnnAddNewDestination}
							>
								Agregar destino
							</Button>
						</Grid>
					</LocalizationProvider>
				</form>
			</div>
		</Container>
	);
}
