import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { QuotationDefaultNotInclude } from '../../types';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { extractErrorMessage } from '../../helpers';
import {
	deleteDefaultNotIncludes,
	getQuotationDefaultNotIncludesList,
	postDefaultNotIncludes,
	putDefaultNotIncludes,
} from '../../services';
import DeleteIcon from '@mui/icons-material/Delete';
import { checkShowErrorMessage } from '../../../common/helpers';
import { Edit } from '@mui/icons-material';

const defaultNotIncludesValues = {
	id: 0,
	notInclude: '',
};

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
		maxHeight: '40%',
		overflowY: 'scroll',
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export const DefaultNotIncludes = (): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const {
		handleSubmit,
		control,
		formState: { errors },
		setValue,
		getValues,
	} = useForm({ defaultValues: defaultNotIncludesValues, mode: 'all' });
	const [notIncludesList, setNotIncludesList] = useState<QuotationDefaultNotInclude[]>([]);
	const [changeId, setChangeId] = useState<number | null>(null);
	const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = React.useState(false);
	const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const handleCloseConfirmDelete = () => {
		setOpenDeleteConfirmDialog(false);
	};
	const handleCloseUpdateDialog = () => {
		setOpenUpdateDialog(false);
	};

	const handleClickOpenConfirmDelete = (id: number | undefined) => {
		if (id) {
			setOpenDeleteConfirmDialog(true);
			setChangeId(id);
		}
	};
	const handleClickOpenUpdate = (id: number | undefined) => {
		if (id) {
			setOpenUpdateDialog(true);
			setChangeId(id);
		}
	};

	const handleDeleteNotInclude = async () => {
		try {
			setLoading(true);
			changeId && (await deleteDefaultNotIncludes(changeId));
			await getNotIncludesList();
			setOpenDeleteConfirmDialog(false);
			setChangeId(null);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al eliminar el elemento'),
			});
			setLoading(false);
		}
	};
	const handleUpdateNotInclude = async () => {
		try {
			setLoading(true);
			changeId && setValue('id', changeId);
			await putDefaultNotIncludes(getValues());
			await getNotIncludesList();
			setOpenUpdateDialog(false);
			setChangeId(null);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al actualizar el elemento'),
			});
			setLoading(false);
		}
	};

	const getNotIncludesList = async () => {
		try {
			setLoading(true);
			setNotIncludesList([...(await getQuotationDefaultNotIncludesList()).data]);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getNotIncludesList();
	}, []);

	const onSubmit = async (data: any) => {
		try {
			setLoading(true);
			await postDefaultNotIncludes(data);
			getNotIncludesList();
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al agregar la nueva opción'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container spacing={2} p={2}>
				<div>
					<Dialog
						open={openDeleteConfirmDialog}
						onClose={handleCloseConfirmDelete}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{'Eliminar'}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">¿Confirma la eliminación?</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button variant="outlined" onClick={handleCloseConfirmDelete}>
								Cancelar
							</Button>
							<Button variant="contained" color="primary" onClick={handleDeleteNotInclude} autoFocus>
								Aceptar
							</Button>
						</DialogActions>
					</Dialog>
				</div>
				<div>
					<Dialog
						open={openUpdateDialog}
						onClose={handleCloseUpdateDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{'Actualizar'}</DialogTitle>
						<DialogContent>
							<Controller
								name="notInclude"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									maxLength: {
										value: 3000,
										message: 'La descripción no debe tener más de ' + 3000 + ' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="standard"
										label="Ingrese que no incluye"
										sx={{ width: '55vh' }}
										fullWidth
										helperText={checkShowErrorMessage(Boolean(errors.notInclude), errors.notInclude?.message)}
										error={Boolean(errors.notInclude)}
										{...field}
									/>
								)}
							/>
						</DialogContent>
						<DialogActions>
							<Button variant="outlined" onClick={handleCloseUpdateDialog}>
								Cancelar
							</Button>
							<Button variant="contained" color="primary" onClick={handleUpdateNotInclude} autoFocus>
								Aceptar
							</Button>
						</DialogActions>
					</Dialog>
				</div>
				<div className={classes.paper}>
					<Grid container spacing={2} className={classes.form}>
						<form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="notInclude"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										maxLength: {
											value: 3000,
											message: 'La descripción no debe tener más de ' + 3000 + ' caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="standard"
											label="Ingrese que no incluye"
											size="small"
											fullWidth
											helperText={checkShowErrorMessage(Boolean(errors.notInclude), errors.notInclude?.message)}
											error={Boolean(errors.notInclude)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item>
								<Button variant="contained" color="primary" style={{ margin: 8 }} type="submit">
									Agregar
								</Button>
							</Grid>
						</form>
					</Grid>
					<Typography component="h1" variant="h5">
						No incluye
					</Typography>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Nombre</TableCell>
									<TableCell align="right" width={5} />
									<TableCell align="right" width={5} />
								</TableRow>
							</TableHead>
							<TableBody>
								{notIncludesList?.map((notInclude) => {
									return (
										<TableRow key={'not-include-' + notInclude.id}>
											<TableCell component="th" scope="row">
												{notInclude.notInclude}
											</TableCell>
											<TableCell align="right">
												<IconButton
													color="secondary"
													component="span"
													onClick={() => handleClickOpenUpdate(notInclude.id)}
													size="large"
												>
													<Edit />
												</IconButton>
											</TableCell>
											<TableCell align="right">
												<IconButton
													color="secondary"
													component="span"
													onClick={() => handleClickOpenConfirmDelete(notInclude.id)}
													size="large"
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
