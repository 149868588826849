import React, { useEffect } from 'react';

import { Box, Container, FormLabel, Slider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { IFlightExplorerFilterPriceInfo, IPriceRangeFilter } from '../../types';

interface Props {
	data: IFlightExplorerFilterPriceInfo;
	handleChangeFilter: (data: IPriceRangeFilter | null) => void;
}

const useStyles = makeStyles(() => ({
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		color: 'white',
	},
	filterContent: {
		backgroundColor: 'rgb(241, 242, 241)',
		borderBottomRightRadius: '14px',
		borderBottomLeftRadius: '14px',
		paddingTop: '25px',
		paddingBottom: '25px',
		paddingRight: '15px',
		paddingLeft: '15px',
		transition: 'max-height 0.26s ease 0s, padding 0.26s ease 0s',
		overflowY: 'auto',
	},
}));

export const FlightExplorerPriceFilter = ({ data, handleChangeFilter }: Props): JSX.Element => {
	const classes = useStyles();
	const [value, setValue] = React.useState<number[]>([data.minPrice.amount, data.maxPrice.amount]);
	const theme = useTheme();
	const handleChange = (event: Event, newValue: number | number[]) => {
		const newArray = newValue as number[];
		if (newArray[0] != value[0] || newArray[1] != value[1]) setValue(newArray);
	};
	const valuetext = (value: number) => `${data.minPrice.currency} ${value}`;
	useEffect(() => {
		if (value[0] === data.minPrice.amount && value[1] === data.maxPrice.amount) {
			handleChangeFilter(null);
		} else {
			handleChangeFilter({ from: value[0], to: value[1] });
		}
	}, [value]);

	return (
		<Container sx={{ 'margin-bottom': '15px' }}>
			<Box sx={{ 'padding-left': '15px', backgroundColor: '#8167A3', height: '25px' }}>
				<FormLabel className={classes.title}>Precio</FormLabel>
			</Box>
			<Box className={classes.filterContent}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography
						variant="subtitle1"
						component="span"
						fontSize={12}
						color={theme.palette.secondary.main}
						fontWeight={'bold'}
					>
						{data.minPrice.currency} {value[0]}
					</Typography>
					<Typography
						variant="subtitle1"
						component="span"
						fontSize={12}
						color={theme.palette.secondary.main}
						fontWeight={'bold'}
					>
						{data.minPrice.currency} {value[1]}
					</Typography>
				</div>

				<Slider
					sx={{ width: '95%' }}
					size={'medium'}
					getAriaLabel={() => 'Rango de precios'}
					value={value}
					color={'secondary'}
					onChange={handleChange}
					valueLabelDisplay="off"
					getAriaValueText={valuetext}
					min={data.minPrice.amount}
					max={data.maxPrice.amount}
				/>
			</Box>
		</Container>
	);
};
