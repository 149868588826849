import React from 'react';
import { Container, Grid, Button } from '@mui/material';
import lupaError404 from 'assets/LupaError404.png';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';

interface Props {
	errorType: ErrorType;
}

export enum ErrorType {
	INTERNAL_TRANSACTION_NOT_FOUND,
	INTERNAL_AUTHORIZATION_NOT_FUND,
	INTERNAL_QUOTATIONS_NEW_NOT_FUND,
	INTERNAL_QUOTATIONS_NEW_FLIGHTS_NOT_FUND,
}

export const PageInternalError404 = ({ errorType }: Props): JSX.Element => {
	const history = useHistory();

	const errorMessages: Record<ErrorType, string> = {
		[ErrorType.INTERNAL_TRANSACTION_NOT_FOUND]: 'Transacción no encontrada',
		[ErrorType.INTERNAL_AUTHORIZATION_NOT_FUND]: 'Authorización no encontrada',
		[ErrorType.INTERNAL_QUOTATIONS_NEW_NOT_FUND]: 'Cotización no encontrada',
		[ErrorType.INTERNAL_QUOTATIONS_NEW_FLIGHTS_NOT_FUND]: 'Cotización de vuelos no encontrados',
	};

	const errorMessage = errorMessages[errorType];

	const handleNotify = () => {
		window.location.href = 'https://travelonux.atlassian.net/servicedesk/customer/portals';
	};

	const handleGoBack = () => {
		history.push('/');
	};

	return (
		<>
			<Container
				component="main"
				sx={{
					width: '400px',
					display: 'block',
					justifyContent: 'center',
					mt: 20,
					textAlign: 'center',
				}}
			>
				<Grid sx={{ mb: 1 }}>
					<img src={lupaError404} alt="Error 404" />
				</Grid>
				<p style={{ font: 'Montserrat', fontSize: '20px' }}>¡Ups! Algo falló </p>
				<p style={{ color: '#00000099', font: 'Montserrat', fontSize: '16px' }}>{errorMessage}</p>
				<Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button style={{ color: '#4A148C', width: '45%' }} variant="outlined" onClick={handleNotify}>
						{' '}
						<WarningAmberIcon sx={{ fontSize: 15 }} /> Notificar
					</Button>
					<Button style={{ backgroundColor: '#4A148C', width: '45%' }} variant="contained" onClick={handleGoBack}>
						{' '}
						<KeyboardArrowLeftIcon /> Volver
					</Button>
				</Grid>
			</Container>
		</>
	);
};
