import React, { useEffect, useState } from 'react';
import { Container, CssBaseline, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationServiceSimple } from '../../types';
import { getCityNameCleaned } from '../../helpers';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
}));

export interface DestinationPreview {
	services?: QuotationServiceSimple[];
	cityCode: string;
	cityName: string;
	nights: number;
}

interface Props {
	destinations: DestinationPreview[];
	manualServices: QuotationServiceSimple[];
	notIncludes: string[];
	description: string;
	hasAir: boolean;
	hasHotels: boolean;
}
export const QuotationIntroPreview = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { destinations, manualServices, notIncludes, description, hasAir } = props;
	const [introCity, setIntroCity] = useState<string>('');
	useEffect(() => {
		if (destinations?.length) {
			const cityName = destinations[0] && destinations[0]?.cityName.split('-')[1];
			cityName && setIntroCity(cityName);
		}
	}, [destinations]);

	const getTotalNights = (destinationCode: string) =>
		destinations.filter((x) => x.cityCode === destinationCode).reduce((a, b) => a + b.nights, 0);

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h4">
					¡Hola!
				</Typography>

				<Typography component="p" variant="body1">
					A continuación te detallo el paquete que armamos exclusivamente para vos.
				</Typography>
				<br />
				<br />

				<Typography component="h1" variant="h5">
					Incluye:
				</Typography>
				<ul>
					{destinations.length == 1 && hasAir && <li>Boleto aéreo {introCity} con tasas e impuestos incluidos.</li>}
					{destinations.length > 1 && hasAir && <li>Boleto aéreo con tasas e impuestos incluidos.</li>}
					{props.hasHotels &&
						destinations.map((option, index) => (
							<>
								{destinations.findIndex((x) => x.cityCode == option.cityCode) == index && (
									<li key={`nights-preview-${index}`}>
										{getTotalNights(option.cityCode)} noches de alojamiento en {getCityNameCleaned(option.cityName)}.
									</li>
								)}
								{option.services?.map((service, index) => <li key={`service-preview-${index}`}>{service.name}.</li>)}
							</>
						))}
					{manualServices.map((manualService, index) => (
						<li key={`manual-service-preview-${index}`}>{manualService.name}.</li>
					))}
				</ul>

				{notIncludes.length > 0 && (
					<>
						<Typography component="h1" variant="h5">
							No incluye:
						</Typography>
						<ul>
							{notIncludes.map((option, index) => (
								<li key={`not-includes-preview-${index}`}>{option}.</li>
							))}
						</ul>
					</>
				)}
				{description && description.length > 0 && (
					<>
						<Typography component="h1" variant="h5">
							Observaciones:
						</Typography>
						<Typography style={{ whiteSpace: 'pre-line' }} component={'span'} variant={'body2'}>
							{' '}
							{description}{' '}
						</Typography>
					</>
				)}
			</div>
		</Container>
	);
};
