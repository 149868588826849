import React, { useState } from 'react';
import { Grid, ImageList, ImageListItem } from '@mui/material';

interface Props {
	photos: string[];
}

export const HotelFarePhotosPanel = (props: Props): JSX.Element => {
	const [selectedPhoto, setSelectedPhoto] = useState<number>(0);

	const handleSelectPhoto = (index: number) => selectedPhoto !== index && setSelectedPhoto(index);

	return (
		<div>
			<Grid container spacing={1} p={1}>
				<Grid item xs={12} md={8}>
					<img style={{ maxWidth: '100%', height: 'auto' }} src={props.photos[selectedPhoto]} alt={'photo-selected'} />
				</Grid>
				<Grid item xs={12} md={4}>
					<ImageList cols={5} sx={{ width: '100%', maxHeight: '65vh' }} gap={10}>
						{props.photos.length > 0 &&
							props.photos.map((photo, index) => (
								<ImageListItem
									key={`photo-${index}`}
									onClick={() => handleSelectPhoto(index)}
									sx={{ opacity: index === selectedPhoto ? 0.7 : 1 }}
								>
									<img
										src={`${photo}?w=50&h=50&fit=crop&auto=format`}
										srcSet={`${photo}?w=50&h=50&fit=crop&auto=format&dpr=2 2x`}
										alt={`photo-${index}-thumbnail`}
										loading="lazy"
									/>
								</ImageListItem>
							))}
					</ImageList>
				</Grid>
			</Grid>
		</div>
	);
};
