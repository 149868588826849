import React from 'react';
import { PackageExplorerContainer } from '../../../features/packagesExplorer/components/PackageExplorerContainer';
import { Layout } from '../../../features/common/components/Layout';

export const PackageExplorer = (): JSX.Element => {
	return (
		<Layout>
			<PackageExplorerContainer />
		</Layout>
	);
};
