import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Button,
	Chip,
	Container,
	CssBaseline,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { RoomBaseConfig } from '../types';
import { addRoomToRoomBaseConfig, deleteRoomBaseConfigRow } from '../slice';
import { RootState } from '../../../store';
import { getPAXTotals } from '../selectors';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	table: {
		minWidth: 650,
	},
}));

const initialCreateBaseConfigRoomObject: RoomBaseConfig = {
	adults: 0,
	childs: [],
	infants: [],
};

export default function RoomBaseConfigComponent(): JSX.Element {
	const classes = useStyles();
	const dispatch = useDispatch();

	const paxTotals = useSelector(getPAXTotals);

	const [createBaseConfigRoomObject, setCreateBaseConfigRoomObject] = useState<RoomBaseConfig>(
		initialCreateBaseConfigRoomObject,
	);
	const [childsAge, setChildAge] = useState<number>(0);
	const [infantAge, setInfantAge] = useState<number>(0);

	const { roomBaseConfig } = useSelector((state: RootState) => state.quotation);

	const [openDeleteRoomConfigConfirmDialog, setOpenDeleteRoomConfigConfirmDialog] = React.useState(false);
	const initDeleteRoomConfigPayload = -1;
	const [deleteRoomConfigPayload, setDeleteRoomConfigPayload] = React.useState<number>(initDeleteRoomConfigPayload);

	const handleClickOpenConfirmDeleteRoomConfig = (index: number) => () => {
		setOpenDeleteRoomConfigConfirmDialog(true);
		setDeleteRoomConfigPayload(index);
	};

	const handleCloseConfirmDeleteRoomConfig = () => {
		setOpenDeleteRoomConfigConfirmDialog(false);
	};
	const handleDeleteRoomConfig = () => {
		setOpenDeleteRoomConfigConfirmDialog(false);
		// @ts-ignore
		dispatch(deleteRoomBaseConfigRow(deleteRoomConfigPayload));
	};

	const handleAdultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCreateBaseConfigRoomObject((prevState) => {
			return { ...prevState, adults: parseInt(e.target.value) };
		});
	};

	const handleChildAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChildAge(parseInt(e.target.value));
	};

	const handleChildAdd = () => {
		setCreateBaseConfigRoomObject((prevState) => {
			const newState = { ...prevState };
			newState.childs.push(childsAge);
			return newState;
		});
		setChildAge(0);
	};

	const handleChildRemove = (index: number) => () => {
		setCreateBaseConfigRoomObject((prevState) => {
			const newState = { ...prevState };
			newState.childs.splice(index, 1);
			return newState;
		});
	};

	const handleInfantAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInfantAge(parseInt(e.target.value));
	};

	const handleInfantAdd = () => {
		setCreateBaseConfigRoomObject((prevState) => {
			const newState = { ...prevState };
			newState.infants.push(infantAge);
			return newState;
		});
		setInfantAge(0);
	};

	const handleInfantRemove = (index: number) => () => {
		setCreateBaseConfigRoomObject((prevState) => {
			const newState = { ...prevState };
			newState.infants.splice(index, 1);
			return newState;
		});
	};

	const handleRoomBaseConfigAdd = () => {
		dispatch(addRoomToRoomBaseConfig(createBaseConfigRoomObject));
		setCreateBaseConfigRoomObject({
			adults: 0,
			childs: [],
			infants: [],
		});
	};

	return (
		<Container component="main" maxWidth="md">
			<div>
				<Dialog
					open={openDeleteRoomConfigConfirmDialog}
					onClose={handleCloseConfirmDeleteRoomConfig}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{'Eliminar configuración de la habitación'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							¿Confirma eliminar la configuración de la habitación #{deleteRoomConfigPayload + 1}?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={handleCloseConfirmDeleteRoomConfig}>
							Cancelar
						</Button>
						<Button variant="contained" color="primary" onClick={handleDeleteRoomConfig} autoFocus>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Configuración de habitaciones
				</Typography>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Room #</TableCell>
								<TableCell align="right">ADT</TableCell>
								<TableCell align="right">CHD</TableCell>
								<TableCell align="right">INF</TableCell>
								<TableCell align="right" />
							</TableRow>
						</TableHead>
						<TableBody>
							{roomBaseConfig.map((item, index) => (
								<TableRow key={index}>
									<TableCell component="th" scope="row">
										{index + 1}
									</TableCell>
									<TableCell align="right">{item.adults}</TableCell>
									<TableCell align="right">{item.childs.join()}</TableCell>
									<TableCell align="right">{item.infants.join()}</TableCell>
									<TableCell align="right">
										<IconButton
											onClick={handleClickOpenConfirmDeleteRoomConfig(index)}
											color="secondary"
											component="span"
											size="large"
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}

							<TableRow>
								<TableCell component="th" scope="row">
									Total
								</TableCell>
								<TableCell align="right">{paxTotals.adults}</TableCell>
								<TableCell align="right">{paxTotals.child}</TableCell>
								<TableCell align="right">{paxTotals.infants}</TableCell>
								<TableCell align="right"></TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<form className={classes.form} noValidate>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12}>
							<Typography component="span" variant="subtitle1">
								Adultos:
							</Typography>
							<TextField
								name="adults"
								variant="outlined"
								value={createBaseConfigRoomObject.adults}
								required
								id="adults"
								label="Cantidad de Adultos"
								type="number"
								size="small"
								onChange={handleAdultChange}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography component="span" variant="subtitle1">
								Childs:
							</Typography>
							{createBaseConfigRoomObject.childs.map((age, index) => (
								<Chip key={index} label={age} onDelete={handleChildRemove(index)} />
							))}

							<TextField
								name="childAge"
								variant="outlined"
								required
								id="childAge"
								label="Edad"
								type="number"
								value={childsAge}
								size="small"
								onChange={handleChildAgeChange}
							/>
							<Button variant="contained" color="primary" onClick={handleChildAdd}>
								Agregar
							</Button>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography component="span" variant="subtitle1">
								Infants:
							</Typography>
							{createBaseConfigRoomObject.infants.map((age, index) => (
								<Chip key={index} label={age} onDelete={handleInfantRemove(index)} />
							))}
							<TextField
								name="infants"
								variant="outlined"
								required
								id="infants"
								label="Edad"
								type="number"
								size="small"
								value={infantAge}
								onChange={handleInfantAgeChange}
							/>
							<Button variant="contained" color="primary" onClick={handleInfantAdd}>
								Agregar
							</Button>
						</Grid>
					</Grid>
					<Button
						type="button"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={handleRoomBaseConfigAdd}
					>
						Agregar Habitacion
					</Button>
				</form>
			</div>
		</Container>
	);
}
