import React, { useEffect, useState } from 'react';
import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputAdornment,
	Snackbar,
	TextField,
} from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import {
	IPackageDeparture,
	IPackageExplorerDatesFilterValueContainer,
	IPackageExplorerTopFilterValue,
} from '../../types';
import { getPackageDepartures } from '../../services';
import Autocomplete from '@mui/material/Autocomplete';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import SearchIcon from '@mui/icons-material/Search';
import { PackageExplorerDatesFilterInput } from '../PackageExplorerDatesFilterInput';
import { ShowAlertState } from '../../../common/types';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	select: {
		width: '-webkit-fill-available',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
}));

interface Props {
	handleSearch: (value: IPackageExplorerTopFilterValue) => void;
}

export const PackageExplorerTopFilter = ({ handleSearch }: Props): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [departures, setDepartures] = useState<IPackageDeparture[]>([]);
	const [selectedPackageDeparture, setSelectedPackageDeparture] = useState<IPackageDeparture | null>(null);
	const [dateValueSelected, setDateValueSelected] = useState<IPackageExplorerDatesFilterValueContainer | null>(null);
	const [firstTimeFireSearch, setFirstTimeFireSearch] = useState<boolean>(true);

	const isValidToSearch = () => dateValueSelected != null && selectedPackageDeparture != null;

	useEffect(() => {
		const fetchApi = async () => {
			try {
				setLoading(true);
				setDepartures([]);
				const data = await getPackageDepartures();
				setDepartures([...data.data]);
				if (data.data.length > 0) {
					setSelectedPackageDeparture(data.data[0]);
				}
				setLoading(false);
			} catch (error: any) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al pedir los punto de salidas'),
				});
				setLoading(false);
			}
		};
		fetchApi();
	}, []);

	useEffect(() => {
		if (firstTimeFireSearch && isValidToSearch()) {
			handleSearchOnClick();
			setFirstTimeFireSearch(false);
		}
	}, [dateValueSelected, selectedPackageDeparture]);

	const handleSelectedPackageDeparture = (newValue: IPackageDeparture | null) => {
		setSelectedPackageDeparture(newValue);
	};

	const handleSearchOnClick = () => {
		if (isValidToSearch()) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			handleSearch({ departure: selectedPackageDeparture!, dateFilterValue: dateValueSelected! });
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<form className={classes.form}>
				<Grid container spacing={2} pl={2}>
					<Grid item xs={5}>
						<FormControl sx={{ width: '100%' }} className={classes.formControl}>
							<Autocomplete
								options={departures}
								defaultValue={selectedPackageDeparture}
								value={selectedPackageDeparture}
								getOptionLabel={(option: IPackageDeparture) => option.name}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											startAdornment: (
												<InputAdornment position="start">
													<FlightTakeoffIcon />
												</InputAdornment>
											),
										}}
										label="Seleccione el punto de partida"
										variant="outlined"
									/>
								)}
								onChange={(_, data) => handleSelectedPackageDeparture(data)}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={5}>
						<PackageExplorerDatesFilterInput handleSelectDate={setDateValueSelected} />
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							size="small"
							onClick={handleSearchOnClick}
							disabled={!isValidToSearch() || loading}
							startIcon={<SearchIcon />}
							sx={{ height: 40, marginTop: '10px' }}
						>
							Buscar
						</Button>
					</Grid>
				</Grid>
			</form>

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
