export interface IFlightPrice {
	amount: number;
	currency: string;
}

export interface ISegmentDuration {
	totalHours: number;
	totalMinutes: number;
}

export interface IFlightSummary {
	price: IFlightPrice;
	maxStops: number;
	totalDuration: ISegmentDuration;
	localPrice?: IFlightPrice;
}

export interface IFlightExplorerDepartureDestination {
	code: string;
	name: string;
	countryName: string;
	lat: number;
	lng: number;
}

export interface IFlightExplorerDestination {
	code: string;
	name: string;
	sluggedName: string;
	countryName: string;
	lat: number;
	lng: number;
	flightCheapest: IFlightSummary;
}

export interface IDurationRange {
	from: number;
	to: number;
}

export interface IFlightExplorerReq {
	departureCode: string;
	travelInMonth?: number;
	travelDateFrom?: string;
	travelDateTo?: string;
	duration?: IDurationRange;
}

export interface IDestinationImageBackground {
	publicUrl: string;
}

export interface IDestinationImageResultItem {
	background: IDestinationImageBackground;
}

export interface IDestinationImageResult {
	allCities: IDestinationImageResultItem[];
}

export interface IDestinationImageResultContainer {
	data: IDestinationImageResult;
}

export interface IFlightExplorerDatesFilterValueExactDates {
	travelDateFrom: string;
	travelDateTo: string;
}

export interface IFlightExplorerDatesFilterValueMonth {
	travelInMonth: number;
}

export interface IFlightExplorerDatesFilterValueContainer {
	valueType: number;
	title: string;
	duration?: IDurationRange;
	value: IFlightExplorerDatesFilterValueExactDates | IFlightExplorerDatesFilterValueMonth;
}

export interface IFlightFilterMonthWithYear {
	label: string;
	value: number;
}

export interface IFlightExplorerTopFilterValue {
	departure: IFlightExplorerDepartureDestination;
	dateFilterValue: IFlightExplorerDatesFilterValueContainer;
}

export interface IDurationRange {
	from: number;
	to: number;
}

/**Begin interface flight explorer find by destination**/

export interface IFlightExplorerFilterStopInfo {
	allOptions: number;
	withoutStop: number;
	withOneStop: number;
	withTwoStops: number;
	minHourInStop: number;
	maxHourInStop: number;
}

export interface IFlightExplorerFilterBaggageInfo {
	allOptions: number;
	withOnlyHandBaggage: number;
	withHoldBaggage: number;
}

export interface IFlightExplorerFilterPriceInfo {
	minPrice: IFlightPrice;
	maxPrice: IFlightPrice;
}

export interface IAirlineFEWithTotal {
	airline: IAirlineFE;
	total: number;
}
export interface ICountryNameInStopsWithTotal {
	countryName: string;
	total: number;
}

export interface IFlightExplorerFilterInfo {
	filterStopInfo: IFlightExplorerFilterStopInfo;
	filterBaggageInfo: IFlightExplorerFilterBaggageInfo;
	filterPriceInfo: IFlightExplorerFilterPriceInfo;
	maxFlightDurationInHour: number;
	weekDaysInDeparture: number[];
	weekDaysInReturn: number[];
	airlinesWithTotal: IAirlineFEWithTotal[];
	countryNamesInStopsWithTotal: ICountryNameInStopsWithTotal[];
}

export interface IFlightItineraryFilterSummary {
	withoutStop: boolean;
	withOneStop: boolean;
	withTwoStops: boolean;
	withoutChangeAirport: boolean;
	onlyHandBaggage: boolean;
	withHoldBaggage: boolean;
	scheduleGoingDeparture: string;
	scheduleGoingArrival: string;
	scheduleReturnDeparture: string;
	scheduleReturnArrival: string;
	maxHourInStop: number;
	maxHourDuration: number;
	goingWeekDay: number;
	returnWeekDay: number;
	airlines: IAirlineFE[];
	countryNamesWithStop: string[];
	price: IFlightItineraryPrice;
}

export interface IFlightItineraryPrice {
	priceForOneAdult: IFlightPrice;
	priceForOneChild: IFlightPrice;
	priceForOneInfant: IFlightPrice;
}

export interface IAllianceFE {
	id: number;
	name: string;
	image: string;
}

export interface IAirlineFE {
	code: string;
	name: string;
	alliance?: IAllianceFE;
}

export interface ICityFESimple {
	code: string;
	name: string;
	isIata: boolean;
	lat?: number;
	lng?: number;
}

export interface IAirportFE {
	code: string;
	name: string;
	city: ICityFESimple;
	countryName: string;
	lat?: number;
	lng?: number;
}

export interface IDataPointTravelSegment {
	airport: IAirportFE;
	flightDate: string;
	flightTime: string;
	dateVariation?: number;
	terminalInformation?: string;
	airPortInformation?: string;
}

export interface ISegmentDuration {
	totalHours: number;
	totalMinutes: number;
}

export interface IChangeInfo {
	changeOfPlane: boolean;
	changeOfAirport: boolean;
}

export interface IScale {
	airport: IAirportFE;
	duration: ISegmentDuration;
	changeInfo: IChangeInfo;
}

export interface IBaggage {
	total: number;
	baggageType: number;
	unitQualifier?: string;
}

export interface ISummaryDateAndAirports {
	departureAirport: IAirportFE;
	arrivalAirport: IAirportFE;
	flightDate: string;
}

export interface IFlightInformation {
	airline: IAirlineFE;
	flightNumber: string;
	completeFlightNumber: string;
	optOperatingCompany?: string;
}

export interface IFlightCabin {
	code: string;
	cabinClass: string;
	fistLetterFareBasis: string;
}

export interface IFareTypeCorporateId {
	fareType: string;
	corporateId: string;
}

export interface IFareDetails {
	ptcCodes: string[];
	fareBasis: string;
	fareTypeCorporateId: IFareTypeCorporateId;
}

export interface ITravelSegment {
	flightInformation: IFlightInformation;
	departureData: IDataPointTravelSegment;
	arrivalData: IDataPointTravelSegment;
	duration: ISegmentDuration;
	cabin: IFlightCabin;
	baggage: IBaggage;
	fareDetails: IFareDetails;
	sliceAndDiceInfo?: string;
}

export interface IFlightMPC {
	airLines: IAirlineFE[];
	departureData: IDataPointTravelSegment;
	arrivalData: IDataPointTravelSegment;
	duration: ISegmentDuration;
	scales: IScale[];
	baggage: IBaggage;
	summaryDateAndAirports: ISummaryDateAndAirports;
	segments: ITravelSegment[];
}

export interface IOrderInfoItem {
	priceForOneAdult: IFlightPrice;
	duration: ISegmentDuration;
}
export interface IOrderInfo {
	recommended: IOrderInfoItem;
	bestPrice: IOrderInfoItem;
	bestDuration: IOrderInfoItem;
}

export enum FlightExplorerOrderBy {
	RECOMMENDED = 'ByRecommended',
	BEST_PRICE = 'ByBestPrice',
	BEST_DURATION = 'ByBestDuration',
}

export interface IFlightItinerary {
	goingFlightTravel: IFlightMPC;
	returnFlightTravel: IFlightMPC;
	filterSummary: IFlightItineraryFilterSummary;
	price: IFlightItineraryPrice;
	localPrice?: IFlightItineraryPrice;
	hasSliceAndDice: boolean;
}

export interface IFlightExplorerByDestinationResponse {
	filterInfo: IFlightExplorerFilterInfo;
	options: IFlightItinerary[];
	searchId: string;

	orderInfo: IOrderInfo;
}

export interface IFlightExplorerByDestinationFilteredAndOrderedResponse {
	options: IFlightItinerary[];

	orderInfo: IOrderInfo;
}

export interface IStopFilter {
	withoutStop?: boolean;
	withOneStop?: boolean;
	withTwoStops?: boolean;
	withoutAirportChange?: boolean;
}

export interface IBaggageFilter {
	withOnlyHandBaggage?: boolean;
	withHoldBaggage?: boolean;
}

export interface IPriceRangeFilter {
	from: number;
	to: number;
}

export interface IDepartureDaysFilter {
	departureDaysInGoing?: number[];
	departureDaysInReturn?: number[];
}

export interface IDurationRange {
	from: number;
	to: number;
}

export interface IDurationFilter {
	maxHourTravelDuration?: number;
	durationRangeStop?: IDurationRange;
}

export interface IScheduleFilter {
	goingDepartureHourRange?: IDurationRange;
	goingArrivalHourRange?: IDurationRange;
	returnDepartureHourRange?: IDurationRange;
	returnArrivalHourRange?: IDurationRange;
}

export interface IBaseFlightExplorerDestinationFilter {
	stop: IStopFilter | null;
	countryNameToExcludeInStops: string[] | null;
	baggage: IBaggageFilter | null;
	priceRange: IPriceRangeFilter | null;
	departureDays: IDepartureDaysFilter | null;
	duration: IDurationFilter | null;
	schedule: IScheduleFilter | null;
	airlineCodes?: string[] | null;
}

export interface IFlightExplorerDestinationFilter extends IBaseFlightExplorerDestinationFilter {
	searchId: string;
	orderBy: FlightExplorerOrderBy;
}

export interface IQueryGraphQL {
	query: string;
}

/**End interface flight explorer find by destination**/
