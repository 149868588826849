import React, { useEffect, useRef, useState } from 'react';
import {
	DestinationSummaryForDestinationMultiple,
	HotelOptionDetailDestinationMultiple,
	QuotationDestinationMultipleSummaryPriceCombination,
	QuotationServiceSimple,
} from '../../types';
import { Box, Button, Container, Grid, IconButton, Paper, SwipeableDrawer, Typography } from '@mui/material';
import { HotelDetailCardMultiDestination } from '../HotelDetailCardMultiDestination';
import { styled } from '@mui/material/styles';
import { Global } from '@emotion/react';
import { RoomPricesMultiDestinationPreviewList } from '../RoomPricesMultiDestinationPreviewList';
import { AddOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import {
	getBoardName,
	getCityNameCleaned,
	GetPAXTotalsResponse,
	hasErrorInDestinationMultipleParams,
} from '../../helpers';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { IncludeMultiDestination } from '../IncludeMultiDestination';

interface Props {
	destinations: DestinationSummaryForDestinationMultiple[];
	priceCombinations: QuotationDestinationMultipleSummaryPriceCombination[];
	paxTotals: GetPAXTotalsResponse;
	manualServices: QuotationServiceSimple[];
	showUtility: boolean;
	markup: number | null;
	totalFlights: number;
	flightIndexSelected: number | null;
	handleFlightSelectionChange?: (index: number | null) => void;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

const Puller = styled(Box)(() => ({
	width: 30,
	height: 6,
	borderRadius: 3,
	backgroundColor: 'gray',
	position: 'absolute',
	top: 8,
	left: 'calc(50% - 15px)',
}));

export interface SelectedHotelByDestination {
	destinationIndex: number;
	destinationCityCode: string;
	hotel: HotelOptionDetailDestinationMultiple;
}

export const MultiSelectHotelsMultiDestination = ({
	destinations,
	priceCombinations,
	paxTotals,
	manualServices,
	showUtility,
	markup,
	totalFlights,
	flightIndexSelected,
	handleFlightSelectionChange,
	exchangeRate,
	localCurrency,
	showLocalCurrency,
}: Props): JSX.Element => {
	const drawerBleeding = (destinations.length + 1) * 40;

	if (hasErrorInDestinationMultipleParams(priceCombinations, destinations, paxTotals)) {
		return <> </>;
	}
	const isMediumDown = useBreakpoints().mediumDown;

	/**En  priceCombinations se envian todas las combinaciones posibles cuando hay mas de 1 vuelo filtramos las que nos hace falta para mostrar**/

	const validCombinations =
		totalFlights > 1
			? priceCombinations.filter((x) => x.keys[0].flightIndex == flightIndexSelected)
			: priceCombinations;
	const priceCombinationsSorted = validCombinations.sort(function (a, b) {
		return a.summaryPriceForOneAdult - b.summaryPriceForOneAdult;
	});

	const combinationMinPrice = priceCombinationsSorted[0];
	const [selectedCombination, setSelectedCombination] =
		useState<QuotationDestinationMultipleSummaryPriceCombination | null>(combinationMinPrice);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const drawerRef = useRef<HTMLDivElement | null>(null);
	const pullerRef = useRef<HTMLDivElement | null>(null);
	/**Permite que al cargar por primera vez busque la mejor opción y no la que se le pase por default**/
	useEffect(() => {
		if (totalFlights > 1) {
			const allPriceCombinationsSorted = priceCombinations.sort(function (a, b) {
				return a.summaryPriceForOneAdult - b.summaryPriceForOneAdult;
			});
			const maybeFlightIndex = allPriceCombinationsSorted[0]?.keys[0]?.flightIndex;

			if (maybeFlightIndex != null && handleFlightSelectionChange) {
				handleFlightSelectionChange(maybeFlightIndex);
			}
		}
	}, []);

	const getSelectedHotels = (): SelectedHotelByDestination[] => {
		return combinationMinPrice.keys.map((key) => {
			const destination =
				destinations[
					destinations.findIndex((x, index) => x.cityCode == key.destinationCode && key.destinationIndex == index)
				];
			const hotel =
				destination.hotelOptions[
					destination.hotelOptions.findIndex(
						(x) => x.hotelId == key.hotelId && x.provider == key.hotelProvider && key.boardsKey == x.boardsKey,
					)
				];
			return {
				destinationIndex: key.destinationIndex,
				destinationCityCode: key.destinationCode,
				hotel: hotel,
			};
		});
	};

	useEffect(() => {
		setSelectedCombination(combinationMinPrice);
		setSelectedHotels(getSelectedHotels());
	}, [priceCombinations, flightIndexSelected]);

	const [selectedHotels, setSelectedHotels] = useState<SelectedHotelByDestination[]>(getSelectedHotels());

	const getDestinationIndex = (destinationCityCode: string, destinationIndex: number) =>
		selectedHotels.findIndex(
			(hotel) => hotel.destinationCityCode == destinationCityCode && hotel.destinationIndex == destinationIndex,
		);

	const handleSelectHotel = (
		destinationCityCode: string,
		destinationIndex: number,
		hotel: HotelOptionDetailDestinationMultiple,
	) => {
		const maybeDestinationSelected = getDestinationIndex(destinationCityCode, destinationIndex);
		const selectedHotelsCopy = [...selectedHotels];
		if (
			maybeDestinationSelected > -1 &&
			!isSelectedHotel(hotel.hotelId, destinationCityCode, destinationIndex, hotel.provider, hotel.boardsKey)
		) {
			selectedHotelsCopy.splice(maybeDestinationSelected, 1);
			const selectedNew = [
				...selectedHotelsCopy,
				{
					destinationCityCode,
					destinationIndex,
					hotel: hotel,
				},
			];

			const possiblesCombinations = validCombinations.filter(
				(x) =>
					x.keys.findIndex(
						(k) =>
							k.destinationCode == destinationCityCode &&
							k.destinationIndex == destinationIndex &&
							k.hotelId == hotel.hotelId &&
							k.hotelProvider == hotel.provider,
					) > -1,
			);
			const combinationForHotelsSelection = possiblesCombinations.find((x) =>
				x.keys.every(
					(k) =>
						selectedNew.findIndex(
							(s) =>
								s.destinationCityCode == k.destinationCode &&
								s.hotel.hotelId == k.hotelId &&
								s.hotel.provider == k.hotelProvider &&
								s.hotel.boardsKey === k.boardsKey,
						) > -1,
				),
			);
			setSelectedCombination(combinationForHotelsSelection || null);
			setSelectedHotels(selectedNew);
		}
	};

	const isSelectedHotel = (
		hotelId: number,
		destinationCityCode: string,
		destinationIndex: number,
		provider: string,
		boardsKey: string,
	) => {
		const selectHotel = selectedHotels[getDestinationIndex(destinationCityCode, destinationIndex)];
		return selectHotel
			? selectHotel.hotel.hotelId === hotelId &&
					selectHotel.hotel.provider === provider &&
					selectHotel.hotel.boardsKey == boardsKey
			: false;
	};

	const getBoardsForDestination = (destinationIndex: number, destinationCityCode: string): string => {
		const selectedHotel = selectedHotels[getDestinationIndex(destinationCityCode, destinationIndex)];
		return selectedHotel
			? Array.from(new Set(selectedHotel.hotel.rooms.map((x) => getBoardName(x.board)))).join(',')
			: '';
	};

	const existRepeatDestination = (): boolean =>
		destinations.some(
			(destination, index) => destinations.findIndex((x, i) => x.cityCode == destination.cityCode && i != index) > -1,
		);

	return (
		<Container sx={{ marginBottom: `${drawerBleeding}px`, paddingX: 0 }}>
			<Global
				styles={{
					'.MuiDrawer-root > .MuiPaper-root': {
						height: `calc(70% - ${drawerBleeding}px)`,
						overflow: 'visible',
					},
				}}
			/>
			{destinations.length &&
				destinations.map((destination, destinationIndex) => (
					<>
						<Typography color="secondary" fontWeight="bold" py={1}>
							Hoteles en {getCityNameCleaned(destination.cityName)}
						</Typography>
						<Container disableGutters key={destination.cityCode} sx={{ overflow: 'auto', paddingBottom: 1 }}>
							<Grid spacing={1} wrap="nowrap" p={2} container>
								{destination.hotelOptions.length > 0 &&
									destination.hotelOptions.map((hotel) => (
										<Grid
											key={`hotel-card-${destination.cityCode}-${destinationIndex}-${hotel.hotelId}-${hotel.provider}-${hotel.boardsKey}`}
											sx={{ display: 'flex', alignItems: 'stretch' }}
											minWidth={300}
											maxWidth={{ xs: '75%', sm: '40%' }}
											item
										>
											<HotelDetailCardMultiDestination
												isSelected={isSelectedHotel(
													hotel.hotelId,
													destination.cityCode,
													destinationIndex,
													hotel.provider,
													hotel.boardsKey,
												)}
												handleSelectHotel={() => handleSelectHotel(destination.cityCode, destinationIndex, hotel)}
												hotelOption={hotel}
											/>
										</Grid>
									))}
							</Grid>
						</Container>
					</>
				))}
			<SwipeableDrawer
				anchor="bottom"
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				onOpen={() => setOpenDrawer(true)}
				swipeAreaWidth={drawerBleeding}
				disableSwipeToOpen={false}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: pullerRef.current ? -pullerRef.current?.offsetHeight : -drawerBleeding,
						backgroundColor: 'white',
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						visibility: 'visible',
						bottom: drawerRef.current ? drawerRef.current?.offsetHeight : '',
						right: 0,
						left: 0,
					}}
				>
					<Puller />

					<Grid p={2} pb={3} paddingTop={1} ref={pullerRef} justifyContent="space-between" container>
						<Grid xs={12} item>
							<Typography color="info.main" fontSize={12}>
								Tu selección{' '}
							</Typography>
						</Grid>
						<Grid xs={10} item>
							{selectedCombination && flightIndexSelected != null && totalFlights > 1 && (
								<Grid xs={12} gap={0} item display="flex" flexDirection="row">
									<Grid xs={12} item>
										<Typography component="span" fontWeight="bold" color="primary" fontSize={13}>
											Vuelos:{' '}
										</Typography>
										<Typography component="span" fontSize={13} fontWeight="bold" color="secondary">
											{' '}
											Opción {flightIndexSelected + 1}
										</Typography>
									</Grid>
								</Grid>
							)}
							{selectedHotels.length > 0 &&
								destinations.map((destination, destinationIndex) => (
									<Grid width="100%" key={`${destinationIndex}-${destination.cityCode}-selection-hotels`} container>
										<Grid xs={12} item>
											<Typography component="span" fontSize={12} color="primary" fontWeight="bold">
												{getCityNameCleaned(destination.cityName)}:{' '}
											</Typography>
											<Typography component="span" fontSize={12} color="secondary" fontWeight="bold">
												{' '}
												{selectedHotels[getDestinationIndex(destination.cityCode, destinationIndex)].hotel.hotelName}
											</Typography>
										</Grid>
									</Grid>
								))}
							{selectedCombination && (
								<Grid xs={12} gap={0} item display="flex" flexDirection="row">
									<Grid xs={12} item>
										<Typography component="span" fontWeight="bold" color="primary" fontSize={13}>
											Precio Promedio por adulto:{' '}
										</Typography>
										<Typography component="span" fontSize={13} fontWeight="bold" color="secondary">
											{selectedCombination.summaryPriceForOneAdult} USD
										</Typography>
										{showLocalCurrency && (
											<Typography component="span" fontSize={13} fontWeight="bold" color="secondary">
												&nbsp; /{' '}
												{Math.round(selectedCombination.summaryPriceForOneAdult * exchangeRate)
													.toLocaleString()
													.replace(/,/g, '.')}{' '}
												{localCurrency}
											</Typography>
										)}
									</Grid>
								</Grid>
							)}
						</Grid>

						<Grid item textAlign="center" justifyContent="center" sx={{ pointerEvents: 'all' }} xs={2}>
							<Typography fontSize={12}> {openDrawer ? 'Volver' : 'Detalles'}</Typography>
							<IconButton
								sx={{
									backgroundColor: 'info.main',
									'&:hover': {
										// without this on mobile the button loses color sometimes after tap
										'@media (hover: none)': {
											backgroundColor: 'info.main',
											'&:active': {
												backgroundColor: 'info.main',
											},
										},
									},
									'&:active': {
										backgroundColor: 'info.main',
									},
									color: 'white',
								}}
								onClick={() => setOpenDrawer(!openDrawer)}
							>
								{openDrawer ? <ExpandMoreOutlined /> : <AddOutlined />}
							</IconButton>
						</Grid>
					</Grid>
				</Box>
				<Paper
					ref={drawerRef}
					sx={{
						height: '100%',
						overflowY: 'auto',
					}}
				>
					{selectedCombination && flightIndexSelected != null && totalFlights > 1 && (
						<>
							<Typography textAlign={{ sx: 'right', md: 'center' }} fontWeight="bold" color="primary" marginX={2}>
								{' '}
								Selección de Vuelo
							</Typography>
							<Container sx={{ overflow: 'auto' }}>
								<Grid spacing={2} py={2} wrap="nowrap" justifyContent={{ sx: 'right' }} container>
									{Array.from({ length: totalFlights }, (_, index) => index).map((flightIndex) => {
										const isSelected = flightIndex === flightIndexSelected;
										return (
											<Grid key={`flight-${flightIndex}-select`} sx={{ display: 'flex', alignItems: 'stretch' }} item>
												<Button
													size="small"
													sx={{ width: 'auto' }}
													variant="contained"
													onClick={() => handleFlightSelectionChange && handleFlightSelectionChange(flightIndex)}
													color={isSelected ? 'warning' : 'inherit'}
												>
													<Typography
														fontSize={12}
														fontWeight={isSelected ? 'bold' : ''}
														minHeight="30px"
														display="flex"
														alignItems="center"
														justifyContent="center"
														minWidth="200px"
														component="p"
													>
														Opción {flightIndex + 1}
													</Typography>
												</Button>
											</Grid>
										);
									})}
								</Grid>
							</Container>
						</>
					)}
					{destinations.length &&
						destinations.map((destination, destinationIndex) => (
							<>
								<Typography textAlign={{ sx: 'right', md: 'center' }} fontWeight="bold" color="primary" marginX={2}>
									{destination.nights} noches en {destination.cityName}{' '}
								</Typography>
								{existRepeatDestination() && getBoardsForDestination(destinationIndex, destination.cityCode) != '' && (
									<Typography textAlign={{ sx: 'right', md: 'center' }} fontWeight="bold" color="primary" marginX={2}>
										{getBoardsForDestination(destinationIndex, destination.cityCode)}{' '}
									</Typography>
								)}
								<Container sx={{ overflow: 'auto' }} key={`${destination.cityCode}-container-rooms`}>
									<Grid spacing={2} py={2} wrap="nowrap" justifyContent={{ sx: 'right' }} container>
										{destination.hotelOptions.length > 0 &&
											destination.hotelOptions.map((hotel) => {
												const isSelected = isSelectedHotel(
													hotel.hotelId,
													destination.cityCode,
													destinationIndex,
													hotel.provider,
													hotel.boardsKey,
												);
												return (
													<Grid
														key={`${hotel.hotelId}-${hotel.provider}-${hotel.boardsKey}-select`}
														sx={{ display: 'flex', alignItems: 'stretch' }}
														item
													>
														<Button
															size="small"
															sx={{ width: 'auto' }}
															variant="contained"
															onClick={() => handleSelectHotel(destination.cityCode, destinationIndex, hotel)}
															color={isSelected ? 'warning' : 'inherit'}
														>
															<Typography
																fontSize={12}
																fontWeight={isSelected ? 'bold' : ''}
																minHeight="30px"
																display="flex"
																alignItems="center"
																justifyContent="center"
																minWidth="200px"
																component="p"
															>
																{hotel.hotelName}
															</Typography>
														</Button>
													</Grid>
												);
											})}
									</Grid>
								</Container>
							</>
						))}
					<Container disableGutters sx={{ overflow: 'auto', marginY: 2 }}>
						{selectedHotels.length > 0 && selectedCombination && (
							<RoomPricesMultiDestinationPreviewList
								hotels={selectedHotels.map((hotel) => hotel.hotel)}
								combination={selectedCombination}
								localCurrency={localCurrency}
								exchangeRate={exchangeRate}
								showLocalCurrency={showLocalCurrency}
								paxTotals={paxTotals}
								showUtility={showUtility && !isMediumDown}
								markup={markup}
							/>
						)}
					</Container>

					<IncludeMultiDestination
						destinations={destinations}
						manualServices={manualServices}
						hasFlight={totalFlights > 0}
					/>
				</Paper>
			</SwipeableDrawer>
		</Container>
	);
};
