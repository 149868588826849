import { ApiService } from 'services';
import { AxiosResponse } from 'axios';
import {
	AccommodationFaresRequest,
	AccommodationFaresResponse,
	DestinationList,
	IHotelMostQuoted,
	InputCopyQuotation,
	IQuotationSaleReportList,
	Quotation,
	QuotationDefaultDescription,
	QuotationDefaultNotInclude,
	QuotationFlightInfoFromAutomaticRoute,
	QuotationOnlyFlights,
	QuotationRegion,
	QuotationsListItem,
} from './types';
import { IEnvelopeList, ISort } from 'features/common/types';
import { ApiResponse } from 'features/common/types';
import { formatSortingFields } from '../common/helpers';

const endpoints = {
	new: 'api/quotations',
	newOnlyFlights: 'api/quotations/onlyflights',
	edit: 'api/quotations/[id]',
	editOnlyFlights: 'api/quotations/[id]/onlyflights',
	list: 'api/quotations/list?envelope=true',
	get: 'api/quotations/[id]',
	getOnlyFlights: 'api/quotations/[id]/onlyflights',
	getDestinations: 'api/quotations/places/destinations',
	getRegions: 'api/quotations/places/regions',
	getDefaultOrigin: 'api/quotations/defaultorigin',
	copy: 'api/quotations/copy',
	defaultDescription: 'api/quotations/defaultdescriptions',
	defaultNotIncludes: 'api/quotations/defaultnotincludes',
	flightInfoFromAutomaticRoute: 'api/quotations/automaticroutes/flightinfo',
	flightInfoFromGDS: 'api/quotations/automaticroutes/flightinfoinmptb',
	hotelsMostQuoted: 'api/quotations/hotelmostquoted/[destinationCode]',
	hotelsFares: 'api/quotations/accommodations/fares',
	salesReport: 'api/quotations/salesreport',
};

export const postQuotation = (payload: Quotation): Promise<AxiosResponse<{ id: string }>> =>
	ApiService.post(endpoints.new, payload);

export const postQuotationOnlyFlights = (payload: QuotationOnlyFlights): Promise<AxiosResponse<{ id: string }>> =>
	ApiService.post(endpoints.newOnlyFlights, payload);

export const putQuotation = (payload: Quotation): Promise<AxiosResponse<Quotation>> =>
	ApiService.put(endpoints.edit.replace('[id]', String(payload.id)), payload);

export const putQuotationOnlyFlights = (payload: QuotationOnlyFlights): Promise<AxiosResponse<QuotationOnlyFlights>> =>
	ApiService.put(endpoints.editOnlyFlights.replace('[id]', String(payload.id)), payload);

export const getQuotation = (id: number): Promise<AxiosResponse<Quotation>> =>
	ApiService.get(endpoints.get.replace('[id]', String(id)));

export const getQuotationOnlyFlights = (id: number): Promise<AxiosResponse<QuotationOnlyFlights>> =>
	ApiService.get(endpoints.getOnlyFlights.replace('[id]', String(id)));

export const getQuotationDefaultDescription = (): Promise<AxiosResponse<QuotationDefaultDescription>> =>
	ApiService.get(endpoints.defaultDescription);

export const getQuotationDefaultNotIncludesList = (): Promise<AxiosResponse<QuotationDefaultNotInclude[]>> =>
	ApiService.get(endpoints.defaultNotIncludes);

export const postDefaultNotIncludes = (data: QuotationDefaultNotInclude): Promise<AxiosResponse> =>
	ApiService.post(endpoints.defaultNotIncludes, data);

export const deleteDefaultNotIncludes = (id: number): Promise<AxiosResponse> =>
	ApiService.delete(`${endpoints.defaultNotIncludes}/${id}`);

export const putDefaultNotIncludes = (data: QuotationDefaultNotInclude): Promise<AxiosResponse> =>
	ApiService.put(`${endpoints.defaultNotIncludes}/${data.id}`, data);

export const putQuotationDefaultDescription = (newDescription: QuotationDefaultDescription): Promise<AxiosResponse> =>
	ApiService.put(endpoints.defaultDescription, newDescription);

export const copyQuotation = (payload: InputCopyQuotation): Promise<AxiosResponse<number>> =>
	ApiService.post(endpoints.copy, payload);

export const listQuotation = (
	page = 0,
	filter: { [p: string]: any },
): Promise<AxiosResponse<ApiResponse<QuotationsListItem[]>>> => {
	const queryParams = Object.assign({ page, sort: '-changed' });
	return ApiService.post(`${endpoints.list}`, filter, { params: queryParams });
};

export const getDestinations = (): Promise<AxiosResponse<DestinationList[]>> =>
	ApiService.get(endpoints.getDestinations);
export const getRegions = (): Promise<AxiosResponse<QuotationRegion[]>> => ApiService.get(endpoints.getRegions);
export const getDefaultOrigin = (): Promise<AxiosResponse<string>> => ApiService.get(endpoints.getDefaultOrigin);

export const getFlightInfoFromAutomaticRoute = (
	automaticRouteId: number,
	travelDate: string,
): Promise<AxiosResponse<QuotationFlightInfoFromAutomaticRoute>> =>
	ApiService.get(endpoints.flightInfoFromAutomaticRoute, {
		params: { routeId: automaticRouteId, travelDate },
	});
export const getFlightInfoFromGDS = (
	automaticRouteId: number,
	travelDate: string,
): Promise<AxiosResponse<QuotationFlightInfoFromAutomaticRoute>> =>
	ApiService.get(endpoints.flightInfoFromGDS, {
		params: { routeId: automaticRouteId, travelDate },
	});
export const getHotelMostQuotedByDestination = (destinationCode: string): Promise<AxiosResponse<IHotelMostQuoted[]>> =>
	ApiService.get(endpoints.hotelsMostQuoted.replace('[destinationCode]', destinationCode));

export const getHotelFares = (request: AccommodationFaresRequest): Promise<AxiosResponse<AccommodationFaresResponse>> =>
	ApiService.post(endpoints.hotelsFares, request);

export const getSalesReportList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IQuotationSaleReportList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.salesReport, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getSalesReportListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IQuotationSaleReportList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.salesReport, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};
