import React from 'react';
import { Switch, Typography, Box } from '@mui/material';

export type ConfigType = 'GDS' | 'images';
interface FlightConfigTypeSwitchProps {
	configType: ConfigType;
	onConfigChange: () => void;
}

const FlightConfigTypeSwitch: React.FC<FlightConfigTypeSwitchProps> = ({
	configType,
	onConfigChange,
}: FlightConfigTypeSwitchProps) => {
	return (
		<Box>
			<Box display="flex" justifyContent="space-between" width="300px" alignItems="center">
				<Typography variant="body1">Configurar por imágenes</Typography>
				<Switch checked={configType !== 'GDS'} onChange={onConfigChange} color="primary" />
			</Box>
			<Box display="flex" justifyContent="space-between" width="300px" alignItems="center">
				<Typography variant="body1">Configurar por GDS</Typography>
				<Switch checked={configType === 'GDS'} onChange={onConfigChange} color="primary" />
			</Box>
		</Box>
	);
};

export default FlightConfigTypeSwitch;
