import React from 'react';
import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { RatesList } from 'features/stats/components/RatesList';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		marginTop: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
}));

function Rates(): JSX.Element {
	const classes = useStyles();

	return (
		<Layout>
			<div>
				<Container component="main" maxWidth="md" className={classes.container}>
					<RatesList />
				</Container>
			</div>
		</Layout>
	);
}

export default Rates;
