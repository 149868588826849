import React from 'react';
import { Layout } from 'features/common/components/Layout';
import Component from 'features/quotation/components/DefaultDescriptionConfig';
import makeStyles from '@mui/styles/makeStyles';
import { Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	paginationHolder: {
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

function QuotationDefaultDescriptionConfig(): JSX.Element {
	const classes = useStyles();
	return (
		<Layout>
			<Container component="main" maxWidth="xl" className={classes.paper}>
				<div>
					<Component />
				</div>
			</Container>
		</Layout>
	);
}

export default QuotationDefaultDescriptionConfig;
