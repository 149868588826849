import React from 'react';
import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationFlightInfo } from '../../types';
import { FlightSegmentMobile } from './FlightSegmentMobile';
import useBreakpoints from 'features/common/hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
	card: { marginTop: theme.spacing(1), paddingLeft: '0', paddingRight: '0', minHeight: '100%' },
}));
interface Props {
	flightInfo: QuotationFlightInfo[];
}

export const FlightMobile = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { flightInfo } = props;

	const { smallDown } = useBreakpoints();

	const styles = {
		minWidth: smallDown ? '100vw' : '640px',
	};

	return (
		<Card className={classes.card}>
			<CardContent sx={styles}>
				{flightInfo.map((info) => (
					<FlightSegmentMobile key={info.flightNumber} segment={info} />
				))}
			</CardContent>
		</Card>
	);
};
