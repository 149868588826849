import {
	Alert,
	AlertColor,
	Backdrop,
	Checkbox,
	CircularProgress,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Popover,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { pageSizeByDefault, pageSizeLargeOptions } from 'constants/constants';
import {
	getAuthorizationList,
	getAuthorizationListToExport,
	registerAuthorization,
} from 'features/authorization/services';
import { IAuthorizationList } from 'features/authorization/types';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	convertChargedAgainstOptionDataToFilter,
	convertFiltersToRecord,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateTimeToShowUser,
	formatDateToShowUser,
	getEnvelopedListPageTotal,
	getSortableFields,
} from 'features/common/helpers';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	SelectFilterListData,
	SelectFilterModelSimple,
} from 'features/common/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import { getAuthorizationStateName } from 'features/transactions/helpers';
import { getChargedAgainstOptions } from 'features/transactions/services';
import { authorizationStateList } from 'features/transactions/types';
import React, { useEffect, useState } from 'react';
import { ConfirmationModal } from '../../../common/components/ConfirmationModal/ConfirmationModal';
import { ExportToExcelButton } from '../../../common/components/ExportToExcelButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { putFilterInProfile } from '../../../common/services';
import { PageNoResult } from '../../../common/components/PageNoResults';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const headerCells: IHeadCell[] = [
	{
		field: 'transactionId',
		sortable: true,
		headerName: 'Id de Transacción',
	},
	{
		field: 'id',
		sortable: true,
		headerName: 'ID de Autorización',
	},
	{
		field: 'crmTicket',
		sortable: true,
		headerName: 'Deal',
	},
	{
		field: 'file',
		sortable: true,
		headerName: 'File',
	},
	{
		field: 'isRegistered',
		sortable: true,
		headerName: 'Registrada',
	},
	{
		field: 'cardLastFourDigit',
		sortable: true,
		headerName: 'Últimos 4 Dígitos de Tarjeta',
	},
	{
		field: 'cardExpiration',
		sortable: true,
		headerName: 'Vencimiento de Tarjeta',
	},
	{
		field: 'chargedAgainstOptionName',
		sortable: true,
		headerName: 'Cobrado contra',
	},
	{
		field: 'amountAuthorized',
		sortable: true,
		headerName: 'Monto Autorizado',
	},
	{
		field: 'authorizationCode',
		sortable: true,
		headerName: 'Código de Autorización',
	},
	{
		field: 'paymentDate',
		sortable: true,
		headerName: 'Fecha de Cobro',
	},
	{
		field: 'paymentDueDate',
		sortable: true,
		headerName: 'Fecha de Vto. de Cobro',
	},
	{
		field: 'pendingToCross',
		sortable: true,
		headerName: 'Pendiente de Cruce',
	},
	{
		field: 'state',
		sortable: true,
		headerName: 'Estado',
	},
	{
		field: 'createdAt',
		sortable: true,
		headerName: 'Fecha de Creación',
	},
];

export interface AuthorizationListProps {
	profileFilters?: FilterModel[];
	profileSorts?: ISort[];
}

export const AuthorizationList = ({ profileFilters, profileSorts }: AuthorizationListProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentSize, setCurrentSize] = useState<number>(pageSizeByDefault);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [authorizationList, setAuthorizationList] = useState<IAuthorizationList[] | null>(null);
	const [sortFields, setSortFields] = useState<ISort[]>(
		profileSorts
			? [...profileSorts]
			: [
					{ field: 'transactionId', order: 'desc' },
					{ field: 'createdAt', order: 'desc' },
			  ],
	);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>(profileFilters ? [...profileFilters] : []);
	const [chargedAgainstOptions, setChargedAgainstOptions] = useState<SelectFilterListData[]>([]);
	const [authorizationIdAnchorEl, setAuthorizationIdAnchorEl] = React.useState<(EventTarget & HTMLSpanElement) | null>(
		null,
	);
	const [transactionIdAnchorEl, setTransactionIdAnchorEl] = React.useState<(EventTarget & HTMLSpanElement) | null>(
		null,
	);
	const [registerConfirmationModalAuthorizationId, setRegisterConfirmationModalAuthorizationId] = useState<
		number | null
	>(null);
	const { auth } = useSelector((state: RootState) => state);

	const statesOfCross = authorizationStateList as SelectFilterListData[];

	const userId = auth.user?.id;
	const apiKey = auth.apiKey;
	const countryCode = auth.country;
	const profileTag = 'authorizations-list-tag';

	const filters = [
		{ label: 'File', type: FilterType.STRING, key: 'file' },
		new DateToFromFilterModel('La Fecha de Cruce', 'crossDateFrom', 'crossDateTo'),
		new DateToFromFilterModel('La Fecha de Cobro', 'paymentDateFrom', 'paymentDateTo'),
		new SelectFilterModelSimple('Estado', 'state', statesOfCross),
		{
			label: 'Deal',
			type: FilterType.NUMERIC,
			key: 'crmTicket',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Registrada',
			type: FilterType.BOOLEAN,
			key: 'isRegistered',
			operator: OperatorFilterEnum.EQUALS,
		},
		{ label: 'Últimos 4 Dígitos de Tarjeta', type: FilterType.STRING, key: 'cardLastFourDigit' },
		{ label: 'Vencimiento de Tarjeta', type: FilterType.STRING, key: 'cardExpiration' },
		{ label: 'ID de Transacción', type: FilterType.STRING, key: 'transactionId' },
		{ label: 'ID de Autorización', type: FilterType.NUMERIC, key: 'id' },
		{ label: 'Código de Autorización', type: FilterType.STRING, key: 'authorizationCode' },
		new SelectFilterModelSimple('Cobrado contra', 'chargedAgainstOptionCode', chargedAgainstOptions),
		{
			label: 'Monto Autorizado',
			type: FilterType.NUMERIC,
			key: 'amountAuthorized',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Monto Autorizado',
			type: FilterType.NUMERIC,
			key: 'amountAuthorizedFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Monto Autorizado',
			type: FilterType.NUMERIC,
			key: 'amountAuthorizedTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		{ label: 'Fecha de Cruce', type: FilterType.DATE, key: 'crossDate' },
		{ label: 'Fecha de cobro', type: FilterType.DATE, key: 'paymentDate' },
		{ label: 'Fecha de Vto. de Cobro', type: FilterType.DATE, key: 'paymentDueDate' },
		new DateToFromFilterModel('La Fecha de Vto. de Cobro', 'paymentDueDateFrom', 'paymentDueDateTo'),
		{
			label: 'Pendiente de Cruce',
			type: FilterType.NUMERIC,
			key: 'pendingToCross',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Pendiente de Cruce',
			type: FilterType.NUMERIC,
			key: 'pendingToCrossFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Pendiente de Cruce',
			type: FilterType.NUMERIC,
			key: 'pendingToCrossTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
	];

	const getAuthorizationListToClient = async () => {
		try {
			setLoading(true);
			await putFilterInProfile(profileTag, apiKey, countryCode, sortFields, filterApplied, userId?.toString());
			const envelopedAuthorizationList = (
				await getAuthorizationList(currentPage - 1, currentSize, sortFields, convertFiltersToRecord(filterApplied))
			).data;
			setPageTotal(getEnvelopedListPageTotal(envelopedAuthorizationList));
			setAuthorizationList(envelopedAuthorizationList.data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de autorizaciones'),
			});
			setLoading(false);
		}
	};

	const getCarriers = async () => {
		try {
			setLoading(true);
			setChargedAgainstOptions(convertChargedAgainstOptionDataToFilter((await getChargedAgainstOptions()).data));
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los Validating Carriers'),
			});
			setLoading(false);
		}
	};

	const handleRegisterAuthorization = async (authorizationId: number) => {
		try {
			setLoading(true);
			await registerAuthorization(authorizationId);
			setRegisterConfirmationModalAuthorizationId(null);
			getAuthorizationListToClient();
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al registrar la autorización'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getCarriers();
	}, []);

	useEffect(() => {
		getAuthorizationListToClient();
	}, [filterApplied, sortFields, currentPage, currentSize]);

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const sortableFields = getSortableFields(headerCells);

	const transformValue = (key: string, value: any, row: IAuthorizationList) => {
		switch (key) {
			case 'file':
				return value ? value : '-';
			case 'isRegistered':
				return value ? 'Si' : 'No';
			case 'paymentDate':
			case 'paymentDueDate':
				return formatDateToShowUser(value);
			case 'state':
				return getAuthorizationStateName(value);
			case 'createdAt':
				return formatDateTimeToShowUser(value);
			case 'amountAuthorized':
				return `${row.amountAuthorized} ${row.coinCode}`;
			case 'pendingToCross':
				return `${row.pendingToCross} ${row.coinCode}`;
			default:
				return value;
		}
	};

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const envelopedAuthorizationList = (
				await getAuthorizationListToExport(sortFields, convertFiltersToRecord(filterApplied))
			).data;
			const authorizationsList = envelopedAuthorizationList.data;
			const optionsToExport = {
				title: 'Listado de Autorizaciones',
				headers: headerCells,
				widthColumns: [20, 20, 20, 20, 10, 25, 20, 20, 20, 20, 20, 20, 20, 20, 20],
				filename: 'Listado de Autorizaciones',
			} as ExportExcelOptions;
			exportToExcel(authorizationsList, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de autorizaciones'),
			});
			setLoading(false);
		}
	};

	const colSpan = headerCells.length;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1}>
				<Grid container spacing={2}>
					<Grid item xs={11}>
						<FilterGrid filters={filters} handleAppliedFilters={handleApplyFilters} preAppliedFilters={filterApplied} />
					</Grid>
					<Grid item xs={1}>
						<ExportToExcelButton disabled={authorizationList?.length == 0} handleExportToExcel={handleExportToExcel} />
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: '600px' }}>
					<div>
						<Popover
							id={transactionIdAnchorEl !== null ? 'transactionid-popover' : undefined}
							open={transactionIdAnchorEl !== null}
							onClose={() => setTransactionIdAnchorEl(null)}
							anchorEl={transactionIdAnchorEl}
							disableRestoreFocus
							sx={{
								pointerEvents: 'none',
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Typography sx={{ p: 2 }}>Ir a los detalles de esta transacción</Typography>
						</Popover>
						<Popover
							id={authorizationIdAnchorEl !== null ? 'authorizationid-popover' : undefined}
							open={authorizationIdAnchorEl !== null}
							onClose={() => setAuthorizationIdAnchorEl(null)}
							anchorEl={authorizationIdAnchorEl}
							disableRestoreFocus
							sx={{
								pointerEvents: 'none',
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Typography sx={{ p: 2 }}>Ir a los detalles de esta autorización</Typography>
						</Popover>

						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="right"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="right">
												{cell.headerName}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && authorizationList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									authorizationList?.map((authorization) => (
										<TableRow key={authorization.id} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell align="right" component="p">
												<Link
													onMouseEnter={(
														event:
															| React.MouseEvent<HTMLAnchorElement, MouseEvent>
															| React.MouseEvent<HTMLSpanElement, MouseEvent>,
													) => setTransactionIdAnchorEl(event.currentTarget)}
													onMouseLeave={() => setTransactionIdAnchorEl(null)}
													href={`/transaction/${authorization.transactionId}`}
													underline="hover"
												>
													{authorization.transactionId}
												</Link>
											</TableCell>
											<TableCell align="right" component="p">
												<Link
													onMouseEnter={(
														event:
															| React.MouseEvent<HTMLAnchorElement, MouseEvent>
															| React.MouseEvent<HTMLSpanElement, MouseEvent>,
													) => setAuthorizationIdAnchorEl(event.currentTarget)}
													onMouseLeave={() => setAuthorizationIdAnchorEl(null)}
													href={`/authorization/${authorization.id}`}
													underline="hover"
												>
													{authorization.id}
												</Link>
											</TableCell>
											<TableCell align="right" component="p">
												{authorization.crmTicket}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('file', authorization.file, authorization)}
											</TableCell>
											<TableCell align="right" component="p">
												{
													<Checkbox
														checked={authorization.isRegistered}
														disabled={authorization.isRegistered}
														onChange={() => setRegisterConfirmationModalAuthorizationId(authorization.id)}
													/>
												}
											</TableCell>
											<TableCell align="right" component="p">
												{authorization.cardLastFourDigit}
											</TableCell>
											<TableCell align="right" component="p">
												{authorization.cardExpiration}
											</TableCell>
											<TableCell align="right" component="p">
												{authorization.chargedAgainstOptionName}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('amountAuthorized', authorization.amountAuthorized, authorization)}
											</TableCell>
											<TableCell align="right" component="p">
												{authorization.authorizationCode}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('paymentDate', authorization.paymentDate, authorization)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('paymentDueDate', authorization.paymentDueDate, authorization)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('pendingToCross', authorization.pendingToCross, authorization)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('state', authorization.state, authorization)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('createdAt', authorization.createdAt, authorization)}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeLargeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={2} textAlign="center" marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{registerConfirmationModalAuthorizationId && (
				<ConfirmationModal
					message={'La autorización se marcará como registrada, esta acción no se puede deshacer'}
					handleAccept={() => handleRegisterAuthorization(registerConfirmationModalAuthorizationId)}
					open={registerConfirmationModalAuthorizationId !== null}
					handleClose={() => setRegisterConfirmationModalAuthorizationId(null)}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
