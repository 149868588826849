import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationFlightInfo } from '../../types';
import moment from 'moment';
import { isoDateToFullDate } from '../../helpers';

const airportCss = {
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 400,
	color: '#7B7B7B',
};

const hourCss = {
	fontSize: '16px',
	fontStyle: 'normal',
	fontWeight: 700,
	color: '#7B7B7B',
};

const useStyles = makeStyles((theme) => ({
	card: { marginTop: theme.spacing(1), width: '100%' },
	airlineName: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		color: '#7B7B7B',
	},
	dateInfo: {
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 500,
		color: '#7B7B7B',
		float: 'right',
	},
	logo: {
		height: '1rem',
		marginTop: '5px',
	},

	airportCode: airportCss,
	hour: hourCss,
	hourAlightRight: { ...hourCss, float: 'right' },
	airportCodeAlightRight: { ...airportCss, float: 'right' },
	separator: {
		width: '100%',
		marginLeft: '15px',
		marginRight: '15px',
		marginTop: '10px',
		backgroundColor: '#311748',
		borderRadius: '4px',
		boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.25)',
		height: '2px',
	},
	separatorContainer: {
		textAlign: 'center',
	},
	rowReverse: {
		flexDirection: 'row-reverse',
	},
	days: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		color: '#F51F20',
		paddingLeft: '5px',
		float: 'right',
	},
}));
interface Props {
	segment: QuotationFlightInfo;
}

export const FlightSegmentMobile = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { segment } = props;
	const departureDate = moment(segment.departure.date, 'YYYY-MM-DD');
	const arrivalDate = moment(segment.arrival.date, 'YYYY-MM-DD');
	const dayDifference: number = arrivalDate.diff(departureDate, 'days');

	return (
		<Card className={classes.card}>
			<CardContent>
				<Grid container>
					<Grid item xs={1}>
						<img src={segment.airline.image} alt={segment.airline.name} className={classes.logo} />
					</Grid>
					<Grid item xs={4}>
						<Typography className={classes.airlineName} component={'p'} variant={'body2'}>
							{segment.airline.name}
						</Typography>
					</Grid>

					<Grid item xs={7}>
						<Typography className={classes.dateInfo} component={'p'} variant={'body2'}>
							{isoDateToFullDate(segment.departure.date)}
						</Typography>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={3}>
						<Typography className={classes.airportCode} component={'p'} variant={'body2'}>
							{segment.departure.airport.code}
						</Typography>
					</Grid>
					<Grid item xs={6} className={classes.separatorContainer}>
						<div className={classes.separator} />
					</Grid>
					<Grid item xs={3}>
						<Typography className={classes.airportCodeAlightRight} component={'p'} variant={'body2'}>
							{segment.arrival.airport.code}
						</Typography>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={5}>
						<Typography className={classes.airportCode} component={'p'} variant={'body2'}>
							{segment.departure.airport.cityName}
						</Typography>
					</Grid>
					<Grid item xs={2}></Grid>
					<Grid item xs={5}>
						<Typography className={classes.airportCodeAlightRight} component={'p'} variant={'body2'}>
							{segment.arrival.airport.cityName}
						</Typography>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={3}>
						<Typography className={classes.hour} component={'p'} variant={'body2'}>
							{segment.departure.hour.substring(0, 5)}
						</Typography>
					</Grid>
					<Grid item xs={6} />
					<Grid item xs={3}>
						{dayDifference > 0 && (
							<Typography className={classes.days} component={'p'} variant={'body2'}>
								{'+'}
								{dayDifference}
							</Typography>
						)}
						<Typography className={classes.hourAlightRight} component={'p'} variant={'body2'}>
							{segment.arrival.hour.substring(0, 5)}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
