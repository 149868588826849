import { DefaultNotIncludes as Component } from 'features/quotation/components/DefaultNotIncludes';
import React from 'react';
import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	paginationHolder: {
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

export const DefaultNotIncludes = (): JSX.Element => {
	const classes = useStyles();

	return (
		<Layout>
			<Container component="main" maxWidth="xl">
				<div className={classes.paper}>
					<Component />
				</div>
			</Container>
		</Layout>
	);
};
