import React from 'react';
import { Layout } from '../../../features/common/components/Layout';
import IFrameSellerReport from '../../../features/dataStudio/components/IFrameSellerReport';

export const DataStudioSellerReport = (): JSX.Element => {
	return (
		<Layout>
			<IFrameSellerReport />
		</Layout>
	);
};
