import { AxiosResponse } from 'axios';
import endpoints from 'constants/endpoints';
import { formatSortingFields } from 'features/common/helpers';
import { IEnvelopeList, ISort } from 'features/common/types';
import { ApiService } from 'services';
import {
	IAuthorization,
	IAuthorizationAllowedToRefund,
	IBankAccountsList,
	IBranchOfficesList,
	ICardInfoResult,
	ICashTransactionList,
	IContainerCurrentBalanceInBankByCountry,
	ICurrentBalanceByCountryContainer,
	IGatewayTransaction,
	IOperators,
	ICashTransactionLog,
	ITransactionDetails,
	ITransactionList,
	ITransactionSummary,
	VNTransactionLineTypeEnum,
	IValidatingCarrier,
	VNItauPointTransactionList,
	IWireTransferChangeObservation,
	IWireTransferTransactionList,
	ICurrentBalanceByBranchOffice,
	IDestinationsToMoveCash,
	ICashTransactionMoveCash,
	IVNBancardTransactionList,
	IChargedAgainstOption,
} from './types';
import { ICoin } from 'features/payment/types';

export const getTransactionSummary = (): Promise<AxiosResponse<ITransactionSummary>> =>
	ApiService.get(endpoints.transactionSummary);
export const getTransactionList = async (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<ITransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.transactionList, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getTransactionListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<ITransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.transactionList, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};
export const getGatewayTransactionList = (
	orderTypeId: number,
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IGatewayTransaction>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(`${endpoints.transactionInGatewayBase}/${orderTypeId}`, {
		params: {
			orderTypeId,
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getGatewayTransactionListToExport = (
	orderTypeId: number,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IGatewayTransaction>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(`${endpoints.transactionInGatewayBase}/${orderTypeId}`, {
		params: {
			orderTypeId,
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};
export const getChargedAgainstOptions = (): Promise<AxiosResponse<IChargedAgainstOption[]>> =>
	ApiService.get(endpoints.chargedAgainstOptions);
export const getValidatingCarriers = (): Promise<AxiosResponse<IValidatingCarrier[]>> =>
	ApiService.get(endpoints.validatingCarriers);
export const getOperators = (): Promise<AxiosResponse<IOperators[]>> => ApiService.get(endpoints.operators);
export const getTransactionDetails = (id: number): Promise<AxiosResponse<ITransactionDetails>> =>
	ApiService.get(`${endpoints.transactions}/${id}`);
export const postAuthorization = (authorization: IAuthorization): Promise<AxiosResponse> =>
	ApiService.post(endpoints.authorizations, authorization);
export const finalizeTransaction = (id: number): Promise<AxiosResponse> =>
	ApiService.patch(`${endpoints.transactions}/${id}/finalize`);
export const getCardInfo = (id: number): Promise<AxiosResponse<ICardInfoResult>> =>
	ApiService.get(`${endpoints.transactions}/${id}/cardinfo`);

export const getWireTransferTransactionList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IWireTransferTransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.wireTransferList, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getWireTransferTransactionListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IWireTransferTransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.wireTransferList, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getCurrentBalanceByCountries = (): Promise<AxiosResponse<ICurrentBalanceByCountryContainer>> =>
	ApiService.get(endpoints.wireTransferCurrentBalanceByCountries);
export const getCurrentBalanceInBankByCountries = (): Promise<AxiosResponse<IContainerCurrentBalanceInBankByCountry>> =>
	ApiService.get(endpoints.wireTransferCurrentBalanceInBankByCountries);
export const refreshWireTransfersTransactions = (): Promise<AxiosResponse> =>
	ApiService.put(endpoints.wireTransferRefresh);
export const changeObservationsWireTransfersTransactions = (
	data: IWireTransferChangeObservation,
): Promise<AxiosResponse> => ApiService.patch(endpoints.wireTransferChangeObservations, data);

export const getAuthorizationAllowedToRefundByTransactionId = (
	transactionId: number,
): Promise<AxiosResponse<IAuthorizationAllowedToRefund[]>> =>
	ApiService.get(`${endpoints.transactions}/${transactionId}/authorizationsallowedtorefund`);

export const getCashTransactionList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<ICashTransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.cashTransaction, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getCashTransactionListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<ICashTransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.cashTransaction, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getCoinsList = (): Promise<AxiosResponse<ICoin[]>> =>
	ApiService.get(`${endpoints.cashTransaction}/coins/all`);
export const getBankAccountsList = (): Promise<AxiosResponse<IBankAccountsList[]>> =>
	ApiService.get(`${endpoints.cashTransaction}/bankaccounts`);
export const getBranchOfficesList = (): Promise<AxiosResponse<IBranchOfficesList[]>> =>
	ApiService.get(`${endpoints.cashTransaction}/branchoffices/all`);

export const getCashTransactionLogs = (id: number): Promise<AxiosResponse<ICashTransactionLog[]>> =>
	ApiService.get(`${endpoints.cashTransaction}/${id}/logs`);
export const getBranchOfficesBalance = (): Promise<AxiosResponse<ICurrentBalanceByBranchOffice[]>> =>
	ApiService.get(`${endpoints.cashTransaction}/branchoffices/currentbalance`);

export const getDestinationToMoveCashTransaction = (): Promise<AxiosResponse<IDestinationsToMoveCash>> =>
	ApiService.get(`${endpoints.cashTransaction}/destinationstomovecash`);

export const moveCashTransaction = (data: ICashTransactionMoveCash): Promise<AxiosResponse> =>
	ApiService.put(`${endpoints.cashTransaction}/movecash`, data);

export const transactionAuthorization = (id: number, passengers: number[]): Promise<AxiosResponse> => {
	const requestBody = {
		passengersNumbers: passengers,
	};
	return ApiService.post(`/api/quotations/authorizations/${id}/fpcashcommand`, requestBody);
};

export const getTransactionAuthorizedReport = (fromDate: Date | null, toDate: Date | null): Promise<AxiosResponse> => {
	const formattedFromDate = fromDate ? fromDate.toISOString().split('T')[0] : '';
	const formattedToDate = toDate ? toDate.toISOString().split('T')[0] : '';

	return ApiService.get(
		`/api/quotations/transactions/reportauthorized?from=${formattedFromDate}&to=${formattedToDate}`,
	);
};

export const getTransactionBancardListVN = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IVNBancardTransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.viajeNaranjaBancardTransactionList, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getTransactionBancardListExportVN = async (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IVNBancardTransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.viajeNaranjaBancardTransactionList, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getItauPointTransactionListVN = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<VNItauPointTransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.viajeNaranjaItauPointTransactionList, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getItauPointTransactionListExportVN = async (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<VNItauPointTransactionList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.viajeNaranjaItauPointTransactionList, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const returnViajeNaranjaItauPoints = (
	relationId: number,
	lineType: VNTransactionLineTypeEnum,
): Promise<AxiosResponse> => {
	const data = {
		relationId: relationId,
		lineType: lineType,
	};
	return ApiService.put(endpoints.viajeNaranjaReturnItauPoints, data);
};
