import React, { useEffect, useState } from 'react';
import { Alert, AlertColor, Backdrop, CircularProgress, Container, Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { CrossesList as Component } from 'features/cross/components/CrossesList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FilterModel, ISort } from '../../../features/common/types';
import { getFilterFromProfile } from '../../../features/common/services';
import { extractErrorMessage } from '../../../features/quotation/helpers';
import { CountryCodeEnum } from '../../../features/common/types';
import { PageNotAllowedForMarket } from '../../../features/common/components/PageNotAllowedForMarket';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	paginationHolder: {
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

function CrossList(): JSX.Element {
	const classes = useStyles();

	const [loading, setLoading] = useState<boolean>(true);
	const { auth } = useSelector((state: RootState) => state);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'createdAt', order: 'desc' }]);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const userId = auth.user?.id;
	const apiKey = auth.apiKey;
	const countryCode = auth.country;
	const profileTag = 'crosses-transaction-list-tag';

	const getInitialProfileFilters = async () => {
		try {
			const { profileSorts, profileFilters } = await getFilterFromProfile(
				profileTag,
				apiKey,
				countryCode,
				userId?.toString(),
			);
			profileSorts && setSortFields([...profileSorts]);
			profileFilters && setFilterApplied([...profileFilters]);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir los filtros del perfil'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getInitialProfileFilters();
	}, []);

	const isUruguay = countryCode === CountryCodeEnum.URUGUAY;

	return (
		<Layout>
			<Container component="main" maxWidth="xl">
				{isUruguay ? (
					<>
						<Typography variant="h5" className={classes.headerTitle}>
							Cruces
						</Typography>
						<div className={classes.paper}>
							<Backdrop className={classes.backdrop} open={loading}>
								<CircularProgress color="inherit" />
							</Backdrop>
							{!loading && <Component profileFilters={filterApplied} profileSorts={sortFields} />}
							<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
								<Alert variant="filled" severity={alert.severity}>
									{alert.message}
								</Alert>
							</Snackbar>
						</div>
					</>
				) : (
					<PageNotAllowedForMarket />
				)}
			</Container>
		</Layout>
	);
}

export default CrossList;
