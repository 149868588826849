import { AxiosResponse } from 'axios';
import endpoints from 'constants/endpoints';
import { ApiService } from '../../services';
import {
	INomenclator,
	IAutomaticRouteSuggestion,
	IAutomaticRouteTariffPrice,
	IAutomaticRouteTariffPriceAmadeusCommand,
	IAutomaticRouteTariffPriceContainerSegment,
	IAutomaticRuteTariffPriceInfoForGraphic,
	IPackageCompositeDetailForPower,
	IPackageDetail,
	IPackagePriceContainer,
	Rates,
} from './types';
import { IEnvelopeList, ISort } from '../common/types';
import { formatSortingFields } from '../common/helpers';

export const getRates = (): Promise<AxiosResponse<Rates>> => ApiService.get(endpoints.ratesList);

export const automaticRouteSuggestion = async (filter: string): Promise<AxiosResponse<IAutomaticRouteSuggestion[]>> =>
	ApiService.get(`${endpoints.automaticRouteSuggestions}${filter}`);
export const automaticRouteTariffPrices = async (
	automaticRouteId: number,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IAutomaticRouteTariffPrice>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.automaticRouteFarePrice.replace(':id', automaticRouteId + ''), {
		params: { ...(sorts.length > 0 && { sort: sorts }), ...(filters && filters), envelope: true },
	});
};

export const automaticRouteTariffPricesSegments = async (
	automaticRouteId: number,
	travelDate: string,
): Promise<AxiosResponse<IAutomaticRouteTariffPriceContainerSegment>> => {
	return ApiService.get(endpoints.automaticRouteFarePriceSegments, {
		params: { routeId: automaticRouteId, travelDate: travelDate },
	});
};

export const automaticRouteTariffPricesAmadeusCommands = async (
	automaticRouteId: number,
	travelDate: string,
): Promise<AxiosResponse<IAutomaticRouteTariffPriceAmadeusCommand>> => {
	return ApiService.get(endpoints.automaticRouteFarePriceAmadeusCommands, {
		params: { routeId: automaticRouteId, travelDate: travelDate },
	});
};

export const automaticRouteTariffPricesForGraphic = async (
	automaticRouteId: number,
): Promise<AxiosResponse<IAutomaticRuteTariffPriceInfoForGraphic>> => {
	return ApiService.get(endpoints.automaticRouteFarePriceInfoForGraphics.replace(':id', automaticRouteId + ''));
};
export const packageCompositeSuggestion = async (filter: string): Promise<AxiosResponse<INomenclator[]>> =>
	ApiService.get(`${endpoints.packageCompositeSuggestions}${filter}`);
export const retrievePackagesPrices = async (
	packageId: number,
	startDate: string,
	endDate: string,
): Promise<AxiosResponse<IPackagePriceContainer>> => {
	const finalPackagePricesEndpoint = endpoints.retrievePackagesPrices
		.replace('[packageId]', String(packageId))
		.replace('[startDate]', startDate)
		.replace('[endDate]', endDate);
	return ApiService.get(finalPackagePricesEndpoint);
};
export const retrievePackageDetail = async (packageId: number): Promise<AxiosResponse<IPackageDetail>> =>
	ApiService.get(`${endpoints.retrievePackagesDetails}/${packageId}/detail`);

export const packageCompositeDetailByTravelDate = async (
	id: number,
	travelDate: string,
): Promise<AxiosResponse<IPackageCompositeDetailForPower>> => {
	return ApiService.get(endpoints.packageCompositeDetail.replace(':id', id + ''), {
		params: { travelDate: travelDate },
	});
};
