import React from 'react';
import { ThankYouTransaction as Component } from 'features/payment/components/ThankYouTransaction';
import { Header } from '../../../features/quotation/components/Header';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Footer from '../../../features/quotation/components/Footer/Footer';

export const ThankYouTransaction = (): JSX.Element => {
	const { payment } = useSelector((state: RootState) => state);

	return (
		<>
			<Header />
			{payment.orderDataToClient && (
				<>
					<Component orderType={payment.orderDataToClient.orderType} />
					<Footer country={payment.orderDataToClient.plCountryCode} />
				</>
			)}
		</>
	);
};
