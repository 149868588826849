import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import logo from '../../../assets/logo.png';
import withStyles from '@mui/styles/withStyles';
import { CountryCodeEnum } from '../../common/types';

const WhiteTextTypography = withStyles({
	root: {
		color: '#FFFFFF',
	},
})(Typography);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
		},
		paper: {
			padding: theme.spacing(1),
			textAlign: 'center',
			color: theme.palette.text.secondary,
		},
		background: {
			backgroundColor: theme.palette.primary.main,
			borderRadius: '8px',
		},
		noUnderlineLinks: {
			textDecoration: 'none',
		},
	}),
);

interface Props {
	country: string;
}
export default function ImportantInformation(props: Props): JSX.Element {
	const classes = useStyles();

	return props.country === CountryCodeEnum.URUGUAY ? (
		<div className={classes.root}>
			<Container component="main" maxWidth="md">
				<Typography variant="h6">IMPORTANTE: </Typography>
				<Typography variant="body2">
					Estas tarifas son regulares, por lo tanto están sujetas a disponibilidad en el momento de la emisión. La única
					forma de garantizar los lugares es generando una reserva, la misma no tiene costo y es sin compromiso, para
					eso necesitamos copia del documento de los pasajeros, una vez generada solo tiene validez por el día, después
					de ese tiempo debemos de volver a cotizar sin poder garantizar que podemos conseguir las mismas tarifas y
					rutas.
				</Typography>
				<Typography variant="body2">
					Para pagos en moneda local el tipo de cambio aplicado será el definido por mevuelo.com en el día y está sujeto
					a variaciones sin previo aviso.
				</Typography>
				<Typography variant="body2">
					Recuerda revisar la fecha de vencimiento del pasaporte o la cédula de identidad, según el caso. Para viajar
					deben tener una validez mínima de 6 meses desde la fecha de salida de cada país. Te sugerimos informarte
					acerca de los documentos necesarios exigidos en los destinos contratados y cómo obtenerlos (visas, vacunas,
					permiso del menor, partida de nacimiento, libreta de conducir, etc.).
				</Typography>
				<Typography variant="body2">
					Las tasas turísticas cobradas en destino por los gobiernos locales así como los resort fees cobrados por los
					hoteles, no están incluidos en el precio del paquete.
				</Typography>
				<Typography variant="h6">ATENCIÓN: </Typography>
				<Typography variant="subtitle2">Recuerda que la documentación es responsabilidad del pasajero.</Typography>
				<Typography variant="body2">
					Una vez emitido el boleto aéreo, estará sujeto a penalidades previstas por las líneas aéreas, para el caso de
					cambios de fechas, rutas, devoluciones y/o anulaciones, totales o parciales.
				</Typography>
				<Typography variant="body2">
					Dicha penalidad, puede determinar una multa mínima de USD100 hasta la pérdida total de la tarifa emitida. En
					el caso de los servicios, una vez confirmada su reserva, cualquier modificación o cancelación puede tener
					penalidades. Consulte las condiciones en cada caso.
				</Typography>
				<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com.uy" target="_blank" rel="noreferrer">
					<Box
						paddingTop={2}
						marginTop={4}
						paddingBottom={2}
						marginBottom={4}
						display="flex"
						justifyContent="space-evenly"
						className={classes.background}
						alignItems="center"
					>
						<Box>
							<img src={logo} alt="logo" />
						</Box>
						<WhiteTextTypography variant="h6" align="center">
							¡TENÉS GANAS!
						</WhiteTextTypography>
					</Box>
				</a>
			</Container>
		</div>
	) : (
		<div className={classes.root}>
			<Container component="main" maxWidth="md">
				<Typography variant="h6">IMPORTANTE: </Typography>
				<Typography variant="body2">
					Estas tarifas son regulares, por lo tanto están sujetas a disponibilidad en el momento de la emisión. La única
					forma de garantizar los lugares es generando una reserva, la misma no tiene costo y es sin compromiso, para
					eso necesitamos copia del documento de los pasajeros, una vez generada solo tiene validez por el día, después
					de ese tiempo debemos de volver a cotizar sin poder garantizar que podemos conseguir las mismas tarifas y
					rutas.
				</Typography>
				<Typography variant="body2">
					Para pagos en moneda local el tipo de cambio aplicado será el definido por mevuelo.com en el día y está sujeto
					a variaciones sin previo aviso.
				</Typography>
				<Typography variant="body2">
					Recuerda revisar la fecha de vencimiento del pasaporte o la cédula de identidad, según el caso. Para viajar
					deben tener una validez mínima de 6 meses desde la fecha de salida de cada país. Te sugerimos informarte
					acerca de los documentos necesarios exigidos en los destinos contratados y cómo obtenerlos (visas, vacunas,
					permiso del menor, partida de nacimiento, libreta de conducir, etc.).
				</Typography>
				<Typography variant="body2">
					Las tasas turísticas cobradas en destino por los gobiernos locales así como los resort fees cobrados por los
					hoteles, no están incluidos en el precio del paquete.
				</Typography>
				<Typography variant="h6">ATENCIÓN: </Typography>
				<Typography variant="subtitle2">Recuerda que la documentación es responsabilidad del pasajero.</Typography>
				<Typography variant="body2">
					Una vez emitido el boleto aéreo, estará sujeto a penalidades previstas por las líneas aéreas, para el caso de
					cambios de fechas, rutas, devoluciones y/o anulaciones, totales o parciales.
				</Typography>
				<Typography variant="body2" sx={{ marginBottom: 2 }}>
					Dicha penalidad, puede determinar una multa mínima de USD100 hasta la pérdida total de la tarifa emitida. En
					el caso de los servicios, una vez confirmada su reserva, cualquier modificación o cancelación puede tener
					penalidades. Consulte las condiciones en cada caso.
				</Typography>
				{props.country !== CountryCodeEnum.TN_ITAU_PY && (
					<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com.py" target="_blank" rel="noreferrer">
						<Box
							paddingTop={2}
							marginTop={4}
							paddingBottom={2}
							marginBottom={4}
							display="flex"
							justifyContent="space-evenly"
							className={classes.background}
							alignItems="center"
						>
							<Box>
								<img src={logo} alt="logo" />
							</Box>
							<WhiteTextTypography variant="h6" align="center">
								¡TENÉS GANAS!
							</WhiteTextTypography>
						</Box>
					</a>
				)}
			</Container>
		</div>
	);
}
