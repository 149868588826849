import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	select: {
		width: '100%',
		backgroundColor: theme.palette.common.white,
	},
}));

interface Props {
	name: string;
	label: string;
	required?: boolean;
	rules?: Record<string, any>;
	error?: boolean;
	helperText?: string;
	inputProps?: Record<string, any>;
	size?: 'small' | 'medium';
	variant?: 'outlined' | 'standard';
	onChange?: (value: string) => void;
	isNumber?: boolean;
	disabled?: boolean;
}

export const ControlledTextField = ({
	name,
	label,
	rules,
	helperText,
	size = 'small',
	variant = 'outlined',
	onChange,
	disabled = false,
	isNumber,
	...inputProps
}: Props): JSX.Element => {
	const classes = useStyles();
	const { control, formState } = useFormContext();
	const error = formState.errors[name]?.message;
	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<FormControl sx={{ width: '100%' }}>
					<TextField
						variant={variant}
						required
						fullWidth
						size={size}
						helperText={error || helperText}
						className={classes.select}
						error={Boolean(error)}
						{...field}
						label={label}
						onChange={(e) => {
							field.onChange(e);
							if (onChange) {
								onChange(e.target.value);
							}
						}}
						disabled={disabled}
						{...inputProps}
						type={isNumber ? 'number' : 'text'}
					/>
				</FormControl>
			)}
		/>
	);
};
