import React, { useEffect, useState } from 'react';
import {
	Alert,
	AlertColor,
	Avatar,
	Backdrop,
	CardContent,
	CircularProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	Select,
	Snackbar,
	Typography,
	useTheme,
} from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { IRate, Rates } from '../types';
import { getRates } from '../services';
import Card from '@mui/material/Card';
import { formatDateToShowUser } from '../../common/helpers';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(1),
		textAlign: 'left',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},

	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	coinCode: {
		fontWeight: 'bold',
	},
	fee: {
		fontWeight: 'bold',
		fontSize: '15px',
	},
	select: {
		width: '200px',
	},
	listItem: {
		paddingLeft: '0px!important',
	},
}));

export const RatesList = (): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const [rates, setRates] = useState<Rates | null>(null);
	const [selectedRate, setSelectedRate] = useState<IRate | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	const handleSelectDateRate = (date: string) => setSelectedRate(rates?.ratesList.find((x) => x.date === date) || null);

	const getRatesAtLoad = async () => {
		try {
			setLoading(true);
			const rates = (await getRates()).data;
			if (rates.ratesList.length > 0) {
				setSelectedRate(rates.ratesList[0]);
			}
			setRates(rates);

			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error cargar las rates'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getRatesAtLoad();
	}, []);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Card sx={{ maxWidth: 345 }}>
				<CardContent>
					<Typography variant="body1" className={classes.headerTitle}>
						Cambio de moneda en base a USD
					</Typography>
					{selectedRate && (
						<>
							<Typography variant="body1" component={'span'}>
								Fecha
							</Typography>{' '}
							<Select
								size={'small'}
								className={classes.select}
								value={selectedRate?.date}
								onChange={(event) => handleSelectDateRate(event.target.value)}
							>
								{rates?.ratesList.map((rate) => (
									<MenuItem key={rate.date} value={rate.date}>
										{formatDateToShowUser(rate.date)}
									</MenuItem>
								))}
							</Select>
						</>
					)}

					<List dense={true}>
						{selectedRate?.values.map((rate) => (
							<ListItem key={rate.coinCode} className={classes.listItem}>
								<ListItemAvatar>
									<Avatar sx={{ bgcolor: theme.palette.primary.main }}>
										<Typography variant="body2" className={classes.coinCode}>
											{rate.coinCode}
										</Typography>
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={
										<React.Fragment>
											<Typography
												className={classes.fee}
												sx={{ display: 'inline' }}
												component="span"
												variant="body2"
												color="text.primary"
											>
												Cambio: {rate.fee}
											</Typography>
										</React.Fragment>
									}
								/>
							</ListItem>
						))}
					</List>

					{rates && (
						<Typography variant="body2" className={classes.coinCode}>
							Fee Aplicado: {rates.feeConfig} ({rates.feeConfig * 100} %)
						</Typography>
					)}
				</CardContent>
			</Card>

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
