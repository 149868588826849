import React, { useEffect, useState } from 'react';
import { Alert, Backdrop, CircularProgress, Grid, Snackbar } from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import {
	IPackageExplorerDatesFilterValueExactDates,
	IPackageExplorerDatesFilterValueMonth,
	IPackageExplorerDestinationFilterReq,
	IPackageExplorerReq,
	IPackageExplorerRes,
	IPackageExplorerTopFilterValue,
	PackageExplorerLateralFilterConcept,
} from '../../types';
import { getPackageExplorerData, getPackageExplorerDestinationsFiltered } from '../../services';
import { PackageExplorerDestinationList } from '../PackageExplorerDestinationList';
import { PackageExplorerMap } from '../PackageExplorerMap';
import { PackageExplorerLateralFilter } from '../PackageExplorerLateralFilter';
import { PackageExplorerTopFilter } from '../PackageExplorerTopFilter';
import routes from '../../../../constants/routes';
import { ShowAlertState } from '../../../common/types';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
	select: {
		width: '-webkit-fill-available',
	},
}));

export const PackageExplorerContainer = (): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [packageExplorerResult, setPackageExplorerResult] = useState<IPackageExplorerRes | null>(null);
	const [filterToSearch, setFilterToSearch] = useState<IPackageExplorerTopFilterValue | null>(null);
	const [lateralFilterToSearch, setLateralFilterToSearch] = useState<IPackageExplorerDestinationFilterReq | null>(null);

	useEffect(() => {
		const fetchApi = async () => {
			if (filterToSearch != null) {
				try {
					setLoading(true);
					setPackageExplorerResult(null);
					const filter = { departureCode: filterToSearch.departure.code } as IPackageExplorerReq;
					if (filterToSearch.dateFilterValue.valueType == 0) {
						const filterTravelInMonth = filterToSearch.dateFilterValue
							.value as unknown as IPackageExplorerDatesFilterValueMonth;
						if (filterTravelInMonth.travelInMonth > 0) {
							filter.travelInMonth = filterTravelInMonth.travelInMonth;
						}
					} else {
						const filterTravelInDates = filterToSearch.dateFilterValue
							.value as unknown as IPackageExplorerDatesFilterValueExactDates;
						filter.travelDateFrom = filterTravelInDates.travelDateFrom;
						filter.travelDateTo = filterTravelInDates.travelDateTo;
					}
					const data = await getPackageExplorerData(filter);
					setPackageExplorerResult(data.data);
					setLoading(false);
				} catch (error: any) {
					setAlert({
						show: true,
						severity: 'error',
						message: extractErrorMessage(error, 'Ocurrió un error al pedir el package explorer'),
					});
					setLoading(false);
				}
			}
		};
		fetchApi();
	}, [filterToSearch]);

	useEffect(() => {
		const fetchApi = async () => {
			if (lateralFilterToSearch != null) {
				try {
					setLoading(true);
					const data = await getPackageExplorerDestinationsFiltered(lateralFilterToSearch);
					const newResult = {
						...packageExplorerResult,
						destinations: data.data,
					} as IPackageExplorerRes;
					setPackageExplorerResult(newResult);
					setLoading(false);
				} catch (error: any) {
					setAlert({
						show: true,
						severity: 'error',
						message: extractErrorMessage(error, 'Ocurrió un error al filtrar los destinos'),
					});
					setLoading(false);
				}
			}
		};
		fetchApi();
	}, [lateralFilterToSearch]);

	const handleApplyLateralFilter = (concept: string, value: number | null) => {
		if (packageExplorerResult != null) {
			const filterToApply = lateralFilterToSearch
				? { ...lateralFilterToSearch }
				: ({ searchId: packageExplorerResult.searchId } as IPackageExplorerDestinationFilterReq);
			switch (concept) {
				case PackageExplorerLateralFilterConcept.REGIONS:
					filterToApply.regionId = value;
					break;
				case PackageExplorerLateralFilterConcept.TRIPSEASONS:
					filterToApply.tripSeasonId = value;
					break;
				case PackageExplorerLateralFilterConcept.TRIPSTYLES:
					filterToApply.tripStyleId = value;
					break;
			}
			setLateralFilterToSearch(filterToApply);
		}
	};

	const handleDestinationDetail = (destinationCode: string) => {
		if (packageExplorerResult != null) {
			const destinationDetailPath = routes.packageExplorer.destinationDetail;
			const data = {
				destinationCode: destinationCode,
				searchId: packageExplorerResult.searchId,
				...(lateralFilterToSearch &&
					lateralFilterToSearch.regionId && { regionId: lateralFilterToSearch.regionId + '' }),
				...(lateralFilterToSearch &&
					lateralFilterToSearch.tripSeasonId && {
						tripSeasonId: lateralFilterToSearch.tripSeasonId + '',
					}),
				...(lateralFilterToSearch &&
					lateralFilterToSearch.tripStyleId && {
						tripStyleId: lateralFilterToSearch.tripStyleId + '',
					}),
			};

			const searchParams = new URLSearchParams(data);
			const completeUrl = `${window.location.origin}${destinationDetailPath}?${searchParams.toString()}`;
			window.open(completeUrl, '_blank');
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<PackageExplorerTopFilter handleSearch={setFilterToSearch}></PackageExplorerTopFilter>
			{packageExplorerResult && (
				<Grid container spacing={2}>
					<Grid item xs={2}>
						<PackageExplorerLateralFilter
							title={'Regiones'}
							concept={PackageExplorerLateralFilterConcept.REGIONS}
							data={packageExplorerResult.searcher.regions}
							handleApplyFilter={handleApplyLateralFilter}
						/>
						<PackageExplorerLateralFilter
							title={'Temporada de viajes'}
							concept={PackageExplorerLateralFilterConcept.TRIPSEASONS}
							data={packageExplorerResult.searcher.seasons}
							handleApplyFilter={handleApplyLateralFilter}
						/>
						<PackageExplorerLateralFilter
							title={'Estilos de viajes'}
							concept={PackageExplorerLateralFilterConcept.TRIPSTYLES}
							data={packageExplorerResult.searcher.tripStyles}
							handleApplyFilter={handleApplyLateralFilter}
						/>
					</Grid>
					<Grid item xs={10}>
						<PackageExplorerMap
							destinations={packageExplorerResult?.destinations || []}
							handleClick={handleDestinationDetail}
						/>
						{filterToSearch && (
							<PackageExplorerDestinationList
								departureName={filterToSearch.departure.name}
								destinations={packageExplorerResult?.destinations || []}
								handleDestinationCardClick={handleDestinationDetail}
							/>
						)}
					</Grid>
				</Grid>
			)}

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
