export enum PackageExplorerLateralFilterConcept {
	REGIONS = 'REGIONS',
	TRIPSEASONS = 'TRIPSEASONS',
	TRIPSTYLES = 'TRIPSTYLES',
}
export interface IPackageExplorerDestinationFilterReq {
	searchId: string;
	regionId: number | null;
	tripSeasonId: number | null;
	tripStyleId: number | null;
}
export interface IPackageExplorerDatesFilterValueExactDates {
	travelDateFrom: string;
	travelDateTo: string;
}

export interface IPackageExplorerDatesFilterValueMonth {
	travelInMonth: number;
}

export interface IPackageExplorerDatesFilterValueContainer {
	valueType: number;
	title: string;
	value: IPackageExplorerDatesFilterValueExactDates | IPackageExplorerDatesFilterValueMonth;
}

export interface IPackageExplorerTopFilterValue {
	departure: IPackageDeparture;
	dateFilterValue: IPackageExplorerDatesFilterValueContainer;
}

export interface IPackageDeparture {
	code: string;
	name: string;
}

export interface IPackageFilterMonthWithYear {
	label: string;
	value: number;
}

export interface IPackageExplorerFilter {
	id: number;
	name: string;
	icon: string;
}

export interface ITripStyleFE extends IPackageExplorerFilter {
	id: number;
	name: string;
	icon: string;
}

export interface ITripSeasonFE extends IPackageExplorerFilter {
	id: number;
	name: string;
	icon: string;
	from?: string;
	to?: string;
}

export interface IPackageRegionToPackageExplorer extends IPackageExplorerFilter {
	id: number;
	name: string;
	icon: string;
}

export interface IPackageExplorerSearcher {
	regions: IPackageRegionToPackageExplorer[];
	seasons: ITripSeasonFE[];
	tripStyles: ITripStyleFE[];
}

export interface IPackageExplorerReq {
	departureCode: string;
	travelInMonth?: number;
	travelDateFrom?: string;
	travelDateTo?: string;
}

export interface IPackagePrice {
	amount: number;
	currency: string;
}

export interface ICompositeIconFE {
	id: number;
	icon: string;
	name: string;
}
export interface IRating {
	tagColor: string;
	name: string;
}
export interface IPackageFEList {
	id: number;
	keyword: string;
	image: string;
	title: string;
	subtitle: string;
	duration: string;
	travelOn: string;
	basePrice: number;
	packageType: number;
	compositeIcons: ICompositeIconFE[];
	lowerPrice: IPackagePrice;
	shadowColor: string;
	lowerLocalPrice?: IPackagePrice;
	rating?: IRating;
}

export interface IPackageExplorerDestination {
	code: string;
	name: string;
	countryName: string;
	image: string;
	lat: number;
	lng: number;
	basePrice: number;
	lowerPrice: IPackagePrice;
	duration: string;
	travelOn: string;
	packages: IPackageFEList[];
	lowerLocalPrice?: IPackagePrice;
}

export interface IPackageExplorerRes {
	req: IPackageExplorerReq;
	searcher: IPackageExplorerSearcher;
	destinations: IPackageExplorerDestination[];
	searchId: string;
}
