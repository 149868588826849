import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { getAirLineLogo } from '../../helpers';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
	logoContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	airlineLogoImage: {
		alignItems: 'center',
		display: 'flex',
		height: '25px',
		justifyContent: 'center',
		width: '25px',
		'& img': {
			maxHeight: '25px',
			maxWidth: '25px',
		},
	},
	airlineLogoName: {
		marginLeft: '7px',
		fontWeight: 500,
		fontStyle: 'normal',
		fontStretch: 'normal',
		textAlign: 'left',
	},
}));

interface Props {
	code: string;
	showName: boolean;
	name?: string;
}
export const AirLineLogo = ({ code, showName, name }: Props): JSX.Element => {
	const classes = useStyles();
	return (
		<div className={classes.logoContainer}>
			<div className={classes.airlineLogoImage}>
				<img alt={name} src={getAirLineLogo(code)} />
			</div>
			{showName && (
				<Typography variant="subtitle1" className={classes.airlineLogoName} fontSize={'14px'} component="span">
					{name}
				</Typography>
			)}
		</div>
	);
};
