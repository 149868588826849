import React from 'react';

import { Box, Button, Divider, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GroupIcon from '@mui/icons-material/Group';
// import BadgeIcon from '@mui/icons-material/Badge';
import { IServices, serviceTypes } from 'features/salesOrder/types';
import { useHistory } from 'react-router-dom';
import { DoNotDisturb } from '@mui/icons-material';

interface Props {
	services: IServices[];
	selectedItemIndex: number | string;
	onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number | string) => void;
	onAddNewService: () => void;
}

export const ListMenu = ({ selectedItemIndex, services, onClick, onAddNewService }: Props): JSX.Element => {
	const history = useHistory();
	return (
		<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
			<Link
				sx={{ cursor: 'pointer' }}
				variant="body1"
				color="secondary"
				onClick={() => history.push('/salesorder/list')}
			>
				volver al listado
			</Link>
			<List component="nav" aria-label="salesorder leftmenu">
				<ListItemButton selected={selectedItemIndex === 0} onClick={(event) => onClick(event, 0)}>
					<ListItemIcon>
						<QueryStatsIcon />
					</ListItemIcon>
					<ListItemText primary="General" />
				</ListItemButton>
				<ListItemButton selected={selectedItemIndex === 1} onClick={(event) => onClick(event, 1)}>
					<ListItemIcon>
						<GroupIcon />
					</ListItemIcon>
					<ListItemText primary="Pasajeros" />
				</ListItemButton>
			</List>
			<Divider />
			<List component="nav" aria-label="salesorder leftmenu">
				{services.map((service) => {
					const { serviceType, keyId } = service;
					const serviceDetail = serviceTypes.find((st) => st.id === serviceType);
					return (
						<ListItem key={keyId} secondaryAction={service.enable === false && <DoNotDisturb />}>
							<ListItemButton selected={selectedItemIndex === keyId} onClick={(event) => onClick(event, service.keyId)}>
								<ListItemIcon>{serviceDetail?.icon}</ListItemIcon>
								<ListItemText primary={serviceDetail?.name} />
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
			<Button variant="contained" color="primary" onClick={onAddNewService} fullWidth>
				Agregar Servicio
			</Button>
		</Box>
	);
};
