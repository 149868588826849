import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, FormControl, Autocomplete, FormHelperText } from '@mui/material';

import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/styles';

interface option {
	keyId: string;
	name: string;
	lastName: string;
}
interface Props {
	name: string;
	rules?: Record<string, any>;
	size?: 'small' | 'medium';
	variant?: 'outlined' | 'standard';
	options: option[];
	isMultiple: boolean;
	helperText?: string;
}

export function ControlledAutoComplete(props: Props): JSX.Element {
	const { name, rules, size = 'small', variant = 'outlined', options, isMultiple = false, helperText } = props;

	const classes = useStyles();
	const { control, formState } = useFormContext();
	const error = formState.errors[name]?.message;

	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<FormControl sx={{ width: '100%' }}>
					<Autocomplete
						multiple={isMultiple}
						options={options}
						value={
							Array.isArray(field.value)
								? field.value.map((v: any) => options.find((option) => option.keyId === v))
								: []
						}
						getOptionLabel={(option) => (option ? `${option.name} ${option.lastName}` : '')}
						onChange={(event, value) => {
							const selectedValues = isMultiple
								? Array.isArray(value)
									? value.map((v) => (v ? v.keyId : ''))
									: []
								: value
								? (value as option).keyId
								: [''];
							field.onChange(selectedValues);
						}}
						renderInput={(params) => (
							<>
								<TextField
									{...params}
									variant={variant}
									fullWidth
									size={size}
									className={classes.select}
									error={error}
									{...field}
								/>
								{helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
							</>
						)}
					/>
				</FormControl>
			)}
		/>
	);
}
