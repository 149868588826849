import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Card,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { isValidDate } from '../../../quotation/helpers';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { FilterList, ArrowRight, ArrowDropDown } from '@mui/icons-material';
import { IReservationSearchForm } from '../../types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
	MuiAccordionroot: {
		'&.MuiAccordion-root': {
			backgroundColor: '#D9EEF9',
		},
	},
}));

const ReservationSearchCardDefaultValues = {
	reservationReference: '',
	dates: '',
	fromDate: undefined,
	toDate: undefined,
	destinations: '',
	innerReference: '',
	reservationState: '',
	reservationBroker: '',
	passengerName: '',
	cancellationCosts: '',
	user: '',
};

const referenceAutocompleteOptions = [
	{ label: '12-558872', code: '12-558872' },
	{ label: '12-558873', code: '12-558873' },
	{ label: '15-558872', code: '15-558872' },
];

const cityAutocompleteOptions = [
	{ label: 'Londres', code: 'LND' },
	{ label: 'Praga', code: 'PRA' },
	{ label: 'Ciudad de México', code: 'MX' },
	{ label: 'Montevideo', code: 'MX' },
];

export const ReservationSearchCard = (): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const methods = useForm<IReservationSearchForm>({
		defaultValues: ReservationSearchCardDefaultValues,
		mode: 'all',
	});
	const [accordion, setAccordion] = useState<string>('');
	const { handleSubmit, control, setValue } = methods;

	const onSubmit = (data: FieldValues) => {
		console.log(JSON.stringify(data));
	};

	const handleChangeAccordion = (accordionName: string) => {
		setAccordion(accordionName === accordion ? '' : accordionName);
	};

	const handleFromDateChange = (dateValue: unknown, onChange: (...event: any[]) => void) => {
		const value =
			dateValue && isValidDate(dateValue as Date)
				? (dateValue as Date).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0]
				: '';
		setValue('toDate', value);
		onChange(value);
	};
	const handleToDateChange = (dateValue: unknown, onChange: (...event: any[]) => void) => {
		const value =
			dateValue && isValidDate(dateValue as Date)
				? (dateValue as Date).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0]
				: '';
		setValue('fromDate', value);
		onChange(value);
	};

	return (
		<Card sx={{ width: '100%' }}>
			<FormProvider {...methods}>
				<form noValidate onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2} padding={2}>
						<Grid item xs={12}>
							<Grid container spacing={1} padding={2} sx={{ backgroundColor: '#D9EEF9' }}>
								<Grid item xs={11}>
									<Controller
										name="reservationReference"
										control={control}
										render={({ field: { onChange, value } }) => (
											<Autocomplete
												options={referenceAutocompleteOptions}
												popupIcon={<FilterList />}
												isOptionEqualToValue={(e, data) => data.code == value}
												onChange={(_, data) => {
													onChange(data?.code);
													return data?.code;
												}}
												fullWidth
												renderInput={(params) => (
													<TextField
														{...params}
														label="Referencia de Reserva"
														variant="outlined"
														placeholder="ej. 12-558872"
														sx={{
															width: '100%',
															svg: { color: theme.palette.secondary.main },
															backgroundColor: 'white',
														}}
													/>
												)}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={1} alignSelf="center">
									<Button variant="contained" color="secondary" fullWidth type="submit">
										Buscar
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Accordion
										elevation={0}
										expanded={accordion === 'panel1'}
										classes={{
											root: classes.MuiAccordionroot,
										}}
										onChange={() => handleChangeAccordion('panel1')}
									>
										<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
											<Typography fontSize={13} color="#67BDEA" display="flex" alignItems="center">
												{accordion === 'panel1' ? <ArrowDropDown /> : <ArrowRight />}REALIZADAS (ÚLTIMOS 30 DÍAS)
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit
												amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
											</Typography>
										</AccordionDetails>
									</Accordion>
								</Grid>
								<Grid item xs={6}>
									<Accordion
										elevation={0}
										expanded={accordion === 'panel2'}
										classes={{
											root: classes.MuiAccordionroot,
										}}
										onChange={() => handleChangeAccordion('panel2')}
									>
										<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
											<Typography fontSize={13} color="#67BDEA" display="flex" alignItems="center">
												{accordion === 'panel2' ? <ArrowDropDown /> : <ArrowRight />}PRÓXIMAS A GASTOS (PRÓXIMOS 7 DÍAS)
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit
												amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
											</Typography>
										</AccordionDetails>
									</Accordion>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<Controller
								name="dates"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth>
										<InputLabel id="reservation-date-select">Fechas</InputLabel>
										<Select label="Fechas" fullWidth {...field}>
											<MenuItem value={'cancelations'}>Gastos de Cancelación</MenuItem>
										</Select>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Controller
								name="fromDate"
								control={control}
								render={({ field: { value, onChange } }) => (
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											inputFormat="dd/MM/yyyy"
											renderInput={(params) => (
												<TextField
													{...params}
													label="Entrada"
													sx={{ width: '100%', svg: { color: theme.palette.secondary.main } }}
													variant="outlined"
												/>
											)}
											value={value}
											onChange={(date) => handleFromDateChange(date, onChange)}
											OpenPickerButtonProps={{
												'aria-label': 'cambiar fecha',
											}}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Controller
								name="toDate"
								control={control}
								render={({ field: { value, onChange } }) => (
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											inputFormat="dd/MM/yyyy"
											renderInput={(params) => (
												<TextField
													{...params}
													label="Salida"
													sx={{ width: '100%', svg: { color: theme.palette.secondary.main } }}
													variant="outlined"
												/>
											)}
											value={value}
											onChange={(date) => handleToDateChange(date, onChange)}
											OpenPickerButtonProps={{
												'aria-label': 'cambiar fecha',
											}}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
						<Grid item xs={4}>
							<Controller
								name="destinations"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										options={cityAutocompleteOptions}
										popupIcon={<FilterList />}
										isOptionEqualToValue={(e, data) => data.code == value}
										onChange={(_, data) => {
											onChange(data?.code);
											return data?.code;
										}}
										fullWidth
										renderInput={(params) => (
											<TextField
												label="Destinos"
												variant="outlined"
												sx={{ width: '100%', svg: { color: theme.palette.secondary.main } }}
												{...params}
											/>
										)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={11}>
							<Grid container padding={1} spacing={1}>
								<Grid item xs={6}>
									<Controller
										name="innerReference"
										control={control}
										render={({ field }) => (
											<FormControl fullWidth>
												<InputLabel id="reservation-reference-select">Referencia</InputLabel>
												<Select label="Referencia" fullWidth {...field}>
													<MenuItem value={'test'}>Test</MenuItem>
												</Select>
											</FormControl>
										)}
									/>
								</Grid>
								<Grid item xs={3}>
									<Controller
										name="reservationState"
										control={control}
										render={({ field }) => (
											<FormControl fullWidth>
												<InputLabel id="reservation-state-select">Estado de la Reserva</InputLabel>
												<Select label="Estado de la Reserva" fullWidth {...field}>
													<MenuItem value={'all'}>Todas</MenuItem>
												</Select>
											</FormControl>
										)}
									/>
								</Grid>
								<Grid item xs={3}>
									<Controller
										name="reservationBroker"
										control={control}
										render={({ field }) => (
											<FormControl fullWidth>
												<InputLabel id="reservation-broker-select">Broker</InputLabel>
												<Select label="Broker" fullWidth {...field}>
													<MenuItem value={'all'}>Todos</MenuItem>
												</Select>
											</FormControl>
										)}
									/>
								</Grid>
								<Grid item xs={6}>
									<Controller
										name="passengerName"
										control={control}
										render={({ field }) => (
											<FormControl fullWidth>
												<TextField {...field} label="Nombre o apellidos del pasajero principal" />
											</FormControl>
										)}
									/>
								</Grid>
								<Grid item xs={3}>
									<Controller
										name="cancellationCosts"
										control={control}
										render={({ field }) => (
											<FormControl fullWidth>
												<InputLabel id="cancellation-costs-select">Gastos de Cancelación</InputLabel>
												<Select label="Gastos de Cancelación" fullWidth {...field}>
													<MenuItem value={'all'}>Todos</MenuItem>
												</Select>
											</FormControl>
										)}
									/>
								</Grid>
								<Grid item xs={3}>
									<Controller
										name="user"
										control={control}
										render={({ field }) => (
											<FormControl fullWidth>
												<InputLabel id="user-select">Usuario</InputLabel>
												<Select label="Usuario" fullWidth {...field}>
													<MenuItem value={'all'}>Todos</MenuItem>
												</Select>
											</FormControl>
										)}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={1} alignSelf="flex-end" marginBottom={1}>
							<Button variant="contained" color="secondary" fullWidth type="submit">
								Buscar
							</Button>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Card>
	);
};
