import {
	ICheckExistFlightDefaultIssueRule,
	IFlightIssueRule,
	IFlightIssueRuleList,
	IFlightIssueRuleUpsert,
} from './types';
import { AxiosResponse } from 'axios';
import { ApiSaleOrderService } from '../../services';
import { ISort } from '../common/types';
import { formatSortingFields } from '../common/helpers';

export const upsertFlightIssueRule = (rule: IFlightIssueRuleUpsert): Promise<AxiosResponse<void>> =>
	ApiSaleOrderService.put(`/flight-issue-rules/${rule.id}`, rule);

export const getFlightIssueRule = (id: string): Promise<AxiosResponse<IFlightIssueRule>> =>
	ApiSaleOrderService.get(`/flight-issue-rules/${id}`);
export const checkExistDefaultIssueRule = (): Promise<AxiosResponse<ICheckExistFlightDefaultIssueRule>> =>
	ApiSaleOrderService.get('/flight-issue-rules/check-exist-default-rule');

export const getFlightIssueRuleList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
	search?: string,
): Promise<AxiosResponse<IFlightIssueRuleList>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiSaleOrderService.post(
		'/flight-issue-rules',
		{
			...(filters && filters),
			...(search && { search: search }),
		},
		{
			params: {
				page,
				size,
				...(sorts.length > 0 && { sort: sorts }),
				envelope: true,
			},
		},
	);
};

export const deleteFlightIssueRule = (id: string): Promise<AxiosResponse<void>> =>
	ApiSaleOrderService.delete(`/flight-issue-rules/${id}`);
