import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addDestinationItem } from '../../slice';
import { RootState } from 'store';
import { DestinationSuggestion, DestinationToAdd } from '../../types';

import { destinationSuggestion, getOptionalServicesByDestination } from 'services';

import Component from './DestinationConfig';
import { extractErrorMessage, isValidDate } from '../../helpers';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

const initialDestinationToAdd: DestinationToAdd = {
	cityCode: '',
	cityName: '',
	nights: 1,
	checking: new Date().toISOString().split('T')[0],
	optionalServices: [],
};

interface Props {
	onError: (error: string) => void;
}
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export default function DestinationConfigCompleteQuotation(props: Props): JSX.Element {
	const dispatch = useDispatch();

	const [searchCity, setSearchCity] = useState<string>('');
	const [destinationToAdd, setDestinationToAdd] = useState<DestinationToAdd>({
		...initialDestinationToAdd,
	});
	const [searchOptions, setSearchOptions] = useState<DestinationSuggestion[]>([]);
	const [loading, setLoading] = React.useState(false);
	const { destination } = useSelector((state: RootState) => state.quotation);
	const classes = useStyles();
	useEffect(() => {
		const fetchApi = async () => {
			const data = await destinationSuggestion(searchCity);
			data && data.data && setSearchOptions(data.data);
		};
		if (searchCity && searchCity.length >= 3) fetchApi();
	}, [searchCity]);

	const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchCity(e.target.value);
	};

	const handleChangeNights = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDestinationToAdd((prevState) => ({ ...prevState, nights: Number(e.target.value) }));
	};

	const handleAddNewDestinationItem = async () => {
		try {
			setLoading(true);
			const optionalServices = await getOptionalServicesByDestination(destinationToAdd.cityCode);
			destinationToAdd.optionalServices = optionalServices.data;
			dispatch(addDestinationItem(destinationToAdd));
			const newDate = moment(destinationToAdd.checking, 'YYYY-MM-DD')
				.add(destinationToAdd.nights, 'days')
				.toDate()
				.toISOString()
				.split('T')[0];
			setDestinationToAdd({ ...initialDestinationToAdd, checking: newDate });
			setSearchOptions([]);
			setLoading(false);
		} catch (error) {
			const errorMessage = extractErrorMessage(
				error,
				'Ocurrió un error al cargar lo servicios opcionales para el destino',
			);
			setLoading(false);
			props.onError(errorMessage);
		}
	};

	const handleAutocompleteChange = (event: React.ChangeEvent<any>, value: DestinationSuggestion | null) => {
		setDestinationToAdd((prevState) => ({
			...prevState,
			cityCode: value?.code || '',
			cityName: value?.name || '',
		}));
	};

	const handleCheckingChange = (date: unknown) => {
		const value = date && isValidDate(date as Date) ? (date as Date).toISOString().split('T')[0] : '';
		if (value) {
			setDestinationToAdd((prevState) => ({ ...prevState, checking: value }));
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Component
				destination={destination}
				searchOptions={searchOptions}
				onAddNew={handleAddNewDestinationItem}
				onAutocompleteChange={handleAutocompleteChange}
				onCheckingChange={handleCheckingChange}
				onChangeNights={handleChangeNights}
				onChangeCity={handleChangeCity}
				destinationToAdd={destinationToAdd}
			/>
		</>
	);
}
