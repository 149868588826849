import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { isValidDate, isValidDateAndYearMajorOrEqual1900, regexOnlyNumberInt } from '../helpers';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { QuotationDestinationList, QuotationRegion, QuotationRoomDistributionFilter } from '../types';
import moment from 'moment';
import { getDefaultOrigin, getRegions } from '../services';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import '../../../assets/mevuelo-font/mv-fonts.css';
import notImage from '../../../assets/not-image.jpg';
import PublicIcon from '@mui/icons-material/Public';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
	filterContainer: {
		marginTop: theme.spacing(2),
	},
	filterInput: {
		width: '80%',
		marginRight: '15px',
	},
	filterSimilarBox: {
		borderWidth: '1px',
		borderColor: 'dark',
		borderStyle: 'solid',
		paddingTop: '15px',
		paddingBottom: '15px',
		paddingLeft: '15px',
	},
	icon: {
		height: '35px',
		width: '35px',
		marginRight: '15px',
		marginLeft: '10px',
		fontsize: '20px',
	},
	regionCityImage: {
		height: '50px',
		width: '50px',
		borderRadius: '30px',
		marginRight: '15px',
	},
	titleHeaderSelect: {
		fontSize: '15px',
		paddingTop: 0,
		marginLeft: '20px',
	},
}));

const initialSimilarFilter = {
	wasCopied: false,
	daysOfCreated: 5,
};

export interface QuotationFindSimilarFilter {
	onlyMyQuotations?: boolean;
	filterOnlyFlights?: boolean;
	wasCopied?: boolean;
	daysOfCreated?: number;
	origin?: string;
	destinationCodes?: string;
	travelFrom?: string;
	travelTo?: string;
	totalNights?: number;
	roomDistributions?: QuotationRoomDistributionFilter[];
}

export interface QuotationsSimilarFilterProps {
	setLoading: (loading: boolean) => void;
	onClearFilter: () => void;
	onApplyFilter: (filter: QuotationFindSimilarFilter) => void;
}

export const QuotationsSimilarFilter = (props: QuotationsSimilarFilterProps): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const [filtersSimilar, setFilterSimilar] = useState<QuotationFindSimilarFilter>(initialSimilarFilter);
	const [regions, setRegions] = React.useState<QuotationRegion[]>([]);
	const [selectedRegions, setSelectedRegions] = React.useState<string[]>([]);
	const [selectedDestinations, setSelectedDestinations] = React.useState<QuotationDestinationList[]>([]);

	const handleChangeOrigin = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleChangeOriginCommon(event.target.value);
	};

	const handleChangeOriginCommon = (value: string) => {
		setFilterSimilar({ ...filtersSimilar, origin: value });
	};

	const handleChangeRoomNumber = (value: number) => {
		if (filtersSimilar.roomDistributions && value < filtersSimilar?.roomDistributions?.length) {
			const newArray = [...filtersSimilar.roomDistributions].slice(
				0,
				value - filtersSimilar?.roomDistributions?.length,
			);
			setFilterSimilar({ ...filtersSimilar, roomDistributions: [...newArray] });
		} else if (filtersSimilar.roomDistributions && value > filtersSimilar?.roomDistributions?.length) {
			const newArray = [...filtersSimilar.roomDistributions];
			newArray.splice(
				filtersSimilar?.roomDistributions?.length,
				0,
				...Array.from(Array(value - filtersSimilar?.roomDistributions?.length), () => {
					return { totalAdults: 1, totalChildren: 0, totalInfants: 0 };
				}),
			);
			setFilterSimilar({ ...filtersSimilar, roomDistributions: [...newArray] });
		} else {
			const newArray = [
				...Array.from(Array(value), () => {
					return { totalAdults: 1, totalChildren: 0, totalInfants: 0 };
				}),
			];
			setFilterSimilar({ ...filtersSimilar, roomDistributions: [...newArray] });
		}
	};

	const handleChangeTotalAdults = (value: number, index: number) => {
		if (filtersSimilar.roomDistributions && filtersSimilar.roomDistributions.length > index) {
			const newArray = [...filtersSimilar.roomDistributions];
			newArray[index].totalAdults = value;
			setFilterSimilar({ ...filtersSimilar, roomDistributions: [...newArray] });
		}
	};
	const handleChangeTotalChildren = (value: number | null, index: number) => {
		if (filtersSimilar.roomDistributions && filtersSimilar.roomDistributions.length > index) {
			const newArray = [...filtersSimilar.roomDistributions];
			newArray[index].totalChildren = value;
			setFilterSimilar({ ...filtersSimilar, roomDistributions: [...newArray] });
		}
	};
	const handleChangeTotalInfants = (value: number | null, index: number) => {
		if (filtersSimilar.roomDistributions && filtersSimilar.roomDistributions.length > index) {
			const newArray = [...filtersSimilar.roomDistributions];
			newArray[index].totalInfants = value;
			setFilterSimilar({ ...filtersSimilar, roomDistributions: [...newArray] });
		}
	};

	const handleChangeDaysOfCreated = (value?: number) => {
		setFilterSimilar({ ...filtersSimilar, daysOfCreated: value });
	};

	const handleChangeTotalNights = (value?: number) => {
		setFilterSimilar({ ...filtersSimilar, totalNights: value });
	};

	const handleChangeWasCopied = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterSimilar({ ...filtersSimilar, wasCopied: event.target.checked });
	};

	const handleChangeFilterOnlyFlights = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterSimilar({ ...filtersSimilar, filterOnlyFlights: event.target.checked });
	};

	const getTravelFrom = () => {
		if (filtersSimilar.travelFrom) {
			const momentObj = moment(filtersSimilar.travelFrom, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};

	const getTravelTo = () => {
		if (filtersSimilar.travelTo) {
			const momentObj = moment(filtersSimilar.travelTo, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};

	const handleChangeTravelFrom = (date: unknown) => {
		const value = date && isValidDate(date as Date) ? (date as Date).toISOString().split('T')[0] : '';
		if (value) {
			setFilterSimilar({
				...filtersSimilar,
				travelFrom: value,
				...(filtersSimilar.travelTo == null && isValidDateAndYearMajorOrEqual1900(date as Date) && { travelTo: value }),
			});
		} else if (date == null) {
			setFilterSimilar({ ...filtersSimilar, travelFrom: undefined, travelTo: undefined });
		}
	};

	const handleChangeTravelTo = (date: unknown) => {
		const value =
			date && isValidDateAndYearMajorOrEqual1900(date as Date) ? (date as Date).toISOString().split('T')[0] : '';
		if (value) {
			setFilterSimilar({
				...filtersSimilar,
				travelTo: value,
				...(filtersSimilar.travelFrom == null &&
					isValidDateAndYearMajorOrEqual1900(date as Date) && { travelFrom: value }),
			});
		} else if (date == null) {
			setFilterSimilar({ ...filtersSimilar, travelFrom: undefined, travelTo: undefined });
		}
	};

	const fetchRegions = async () => {
		try {
			props.setLoading(true);
			const result = await getRegions();
			const newRegions = [
				...result.data,
				{
					name: 'regionGroupSelect',
					destinations: result.data.map((x) => {
						return { code: x.name, name: x.name, regionName: 'regionGroupSelect' };
					}),
				},
			];
			setRegions(newRegions);
			props.setLoading(false);
		} catch (error) {
			props.setLoading(false);
		}
	};

	useEffect(() => {
		fetchRegions();
	}, []);

	useEffect(() => {
		const fetchOrigin = async () => {
			try {
				props.setLoading(true);
				const result = await getDefaultOrigin();
				handleChangeOriginCommon(result.data);
				props.setLoading(false);
			} catch (error) {
				props.setLoading(false);
			}
		};

		fetchOrigin();
	}, []);

	const clearFilters = () => {
		setSelectedDestinations([]);
		setFilterSimilar({ wasCopied: false });
		props.onClearFilter();
	};

	const handleApplyFilters = () => {
		const destinationCodes: string[] = [];
		selectedDestinations.forEach((x) => {
			if (x.regionName == 'regionGroupSelect') {
				const region = regions.find((y) => y.name == x.code);
				if (region) {
					region.destinations.forEach((z) => {
						destinationCodes.push(z.code);
					});
				}
			} else {
				destinationCodes.push(x.code);
			}
		});
		const newFilters = { ...filtersSimilar, destinationCodes: destinationCodes.join(',') };
		if (newFilters.filterOnlyFlights === false) {
			newFilters.filterOnlyFlights = undefined;
		}
		setFilterSimilar(newFilters);
		props.onApplyFilter(newFilters);
	};

	const handleOnClickMenuRegions = (value: string) => {
		const indexOf = selectedRegions.indexOf(value);
		if (indexOf === -1) {
			setSelectedRegions([...selectedRegions, value]);
			setSelectedDestinations(
				selectedDestinations.concat({ code: value, name: value, regionName: 'regionGroupSelect' }),
			);
		} else {
			selectedRegions.splice(indexOf, 1);
			selectedDestinations.splice(
				selectedDestinations.findIndex((x) => x.code === value && x.regionName == 'regionGroupSelect'),
				1,
			);
			setSelectedRegions([...selectedRegions]);
		}
	};

	const handleOnChange = (value: QuotationDestinationList[]) => {
		setSelectedDestinations(value);
		setSelectedRegions(
			selectedRegions.filter((x) => value.some((y) => y.code === x && y.regionName == 'regionGroupSelect')),
		);
	};

	return (
		<Box className={classes.filterSimilarBox} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
			<TextField
				sx={{ width: 100 }}
				value={filtersSimilar.origin || ''}
				onChange={handleChangeOrigin}
				label="Origen"
				variant="outlined"
			/>
			<Autocomplete
				options={regions
					.map((region) => region.destinations)
					.reduce((accumulator, value) => accumulator.concat(value), [])}
				multiple={true}
				groupBy={(option) => option.regionName}
				getOptionLabel={(option) => option.name}
				clearOnBlur={false}
				getOptionDisabled={(option) => {
					return selectedRegions.indexOf(option.regionName) > -1;
				}}
				sx={{ width: 300 }}
				renderOption={(props, destination) => (
					<li {...props} key={destination.name}>
						{destination?.image && (
							<img className={classes.regionCityImage} src={destination.image} alt={destination.name} />
						)}
						{!destination?.image && <img className={classes.regionCityImage} src={notImage} alt={destination.name} />}

						{destination.name}
					</li>
				)}
				onChange={(event, value) => handleOnChange(value)}
				value={selectedDestinations}
				renderGroup={(params) => {
					const region = regions.find((x) => x.name == params.group);
					if (region) {
						if (region.name == 'regionGroupSelect') {
							return <></>;
						}
						return (
							<>
								<li
									onClick={() => handleOnClickMenuRegions(params.group)}
									style={{
										position: 'sticky',
										top: '-8px',
										zIndex: 1,
										backgroundColor: selectedRegions.indexOf(params.group) == -1 ? 'white' : theme.palette.grey[200],
										cursor: 'pointer',
									}}
									key={'region-group' + params.group}
								>
									{' '}
									{region?.icon && (
										<i
											style={{
												height: '35px',
												width: '35px',
												marginRight: '15px',
												marginLeft: '10px',
												fontSize: '30px',
											}}
											className={`icon-${region?.icon}`}
										></i>
									)}
									{!region?.icon && <PublicIcon sx={{ ml: 1, mr: 1 }} />}
									<Typography className={classes.titleHeaderSelect} variant="subtitle2" align="center" component="span">
										{' '}
										{region?.name || ''}
									</Typography>
								</li>
								{params.children}
							</>
						);
					} else return <></>;
				}}
				renderInput={(params) => <TextField {...params} label="Destino" />}
			/>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					inputFormat="dd/MM/yyyy"
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							sx={{ width: 200 }}
							helperText={params?.inputProps?.placeholder}
						/>
					)}
					label="Fecha desde"
					value={getTravelFrom()}
					onChange={handleChangeTravelFrom}
					OpenPickerButtonProps={{
						'aria-label': 'cambiar fecha',
					}}
				/>

				<DatePicker
					inputFormat="dd/MM/yyyy"
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							sx={{ width: 200 }}
							helperText={params?.inputProps?.placeholder}
						/>
					)}
					label="Fecha hasta"
					value={getTravelTo()}
					onChange={handleChangeTravelTo}
					OpenPickerButtonProps={{
						'aria-label': 'cambiar fecha',
					}}
				/>
			</LocalizationProvider>

			<TextField
				sx={{ width: 200 }}
				variant="outlined"
				fullWidth
				label="Cantidad de noches"
				type="number"
				onChange={({ target: { value } }) => {
					if (regexOnlyNumberInt.test(value) && Number(value) >= 1) {
						handleChangeTotalNights(Number(value));
					} else {
						handleChangeTotalNights();
					}
				}}
				value={filtersSimilar.totalNights || ''}
			/>

			<TextField
				sx={{ width: 150 }}
				variant="outlined"
				fullWidth
				label="Días de creada"
				type="number"
				onChange={({ target: { value } }) => {
					if (regexOnlyNumberInt.test(value) && Number(value) >= 0) {
						handleChangeDaysOfCreated(Number(value));
					} else {
						handleChangeDaysOfCreated();
					}
				}}
				value={filtersSimilar.daysOfCreated || ''}
			/>

			<FormControlLabel
				control={<Switch checked={filtersSimilar.wasCopied} onChange={handleChangeWasCopied} />}
				label="Fue copiada"
			/>
			<FormControlLabel
				control={<Switch checked={filtersSimilar.filterOnlyFlights} onChange={handleChangeFilterOnlyFlights} />}
				label="Cotizaciones solo vuelo"
			/>
			<br />
			<FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
				<IconButton
					onClick={() =>
						handleChangeRoomNumber(
							filtersSimilar.roomDistributions && filtersSimilar.roomDistributions.length > 1
								? filtersSimilar.roomDistributions.length - 1
								: 0,
						)
					}
				>
					<RemoveIcon />
				</IconButton>
				<TextField
					sx={{ width: 180 }}
					variant="outlined"
					fullWidth
					InputProps={{
						readOnly: true,
					}}
					label="Cantidad de Habitaciones"
					onChange={({ target: { value } }) => {
						regexOnlyNumberInt.test(value) && Number(value) >= 0 && handleChangeRoomNumber(Number(value));
					}}
					value={filtersSimilar.roomDistributions?.length || 0}
				/>
				<IconButton
					onClick={() =>
						handleChangeRoomNumber(filtersSimilar.roomDistributions ? filtersSimilar.roomDistributions.length + 1 : 1)
					}
				>
					<AddIcon />
				</IconButton>
			</FormControl>
			{filtersSimilar.roomDistributions && filtersSimilar.roomDistributions.length > 0 && (
				<Grid container>
					{filtersSimilar.roomDistributions.map((room, index) => {
						return (
							<Grid item key={`room-${index}-filters`}>
								<Grid container spacing={1} my={1}>
									<Grid item xs={12}>
										<Typography variant="body2" fontWeight="bold">
											Habitación {index + 1}
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<TextField
											sx={{ width: 200 }}
											key={`room-${index}-totalAdults-filter`}
											variant="outlined"
											label="Cantidad de Adultos"
											type="number"
											onChange={({ target: { value } }) => {
												regexOnlyNumberInt.test(value) &&
													Number(value) >= 0 &&
													handleChangeTotalAdults(Number(value), index);
											}}
											value={room.totalAdults || 0}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											sx={{ width: 200 }}
											key={`room-${index}-totalChildren-filter`}
											variant="outlined"
											label="Cantidad de Niños"
											onChange={({ target: { value } }) => {
												if (regexOnlyNumberInt.test(value) && Number(value) >= 0) {
													handleChangeTotalChildren(Number(value), index);
												} else if (value === '') {
													handleChangeTotalChildren(null, index);
												}
											}}
											value={room.totalChildren !== null && room.totalChildren === 0 ? 0 : room.totalChildren || ''}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											sx={{ width: 200 }}
											key={`room-${index}-totalInfants-filter`}
											variant="outlined"
											label="Cantidad de Infantes"
											onChange={({ target: { value } }) => {
												if (regexOnlyNumberInt.test(value) && Number(value) >= 0) {
													handleChangeTotalInfants(Number(value), index);
												} else if (value === '') {
													handleChangeTotalInfants(null, index);
												}
											}}
											value={room.totalInfants !== null && room.totalInfants === 0 ? 0 : room.totalInfants || ''}
										/>
									</Grid>
								</Grid>
							</Grid>
						);
					})}
				</Grid>
			)}

			<span>
				<Button
					variant="contained"
					size="small"
					startIcon={<SearchIcon />}
					sx={{ height: 40 }}
					onClick={handleApplyFilters}
				>
					Buscar
				</Button>

				<Button variant="outlined" startIcon={<ClearIcon />} sx={{ height: 40, ml: 1 }} onClick={clearFilters}>
					Limpiar
				</Button>
			</span>
		</Box>
	);
};
