import React from 'react';
import { Grid, IconButton, List, ListItem, Theme } from '@mui/material';
import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

interface QuotationFlightImage {
	id: string;
	image: string;
}

interface FlightImageConfigListProps {
	images: QuotationFlightImage[];
	onRemove: (id: string) => void;
	onMoveForward: (id: string) => void;
	onMoveBackward: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	thumbnail: {
		maxWidth: '200px',
		maxHeight: '200px',
		marginRight: theme.spacing(2),
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	removeButton: {
		color: theme.palette.secondary.main,
	},
	moveButton: {
		color: theme.palette.primary.main,
	},
}));

const FlightImageConfigList: React.FC<FlightImageConfigListProps> = ({
	images,
	onRemove,
	onMoveForward,
	onMoveBackward,
}: FlightImageConfigListProps) => {
	const classes = useStyles();

	return (
		<List>
			{images.map((image, index) => (
				<ListItem key={image.id}>
					<Grid container alignItems="center">
						<Grid item>
							<img src={image.image} className={classes.thumbnail} alt={`Thumbnail ${index}`} />
						</Grid>
						<Grid item>
							<div className={classes.buttonContainer}>
								{' '}
								{/* Use div para contener los botones */}
								<IconButton
									title={'Eliminar'}
									className={classes.removeButton}
									aria-label="delete"
									onClick={() => onRemove(image.id)}
								>
									<Delete />
								</IconButton>
								{index !== images.length - 1 && (
									<IconButton
										title={'Mover abajo'}
										className={classes.moveButton}
										aria-label="move forward"
										onClick={() => onMoveForward(image.id)}
									>
										<ArrowDownward />
									</IconButton>
								)}
								{index !== 0 && (
									<IconButton
										title={'Mover arriba'}
										className={classes.moveButton}
										aria-label="move backward"
										onClick={() => onMoveBackward(image.id)}
									>
										<ArrowUpward />
									</IconButton>
								)}
							</div>
						</Grid>
					</Grid>
				</ListItem>
			))}
		</List>
	);
};

export default FlightImageConfigList;
