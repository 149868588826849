import React from 'react';
import { WireTransferCheckForm } from 'features/payment/components/WireTransferCheckForm';
import { Grid, Typography } from '@mui/material';
import { Layout } from '../../../features/common/components/Layout';
import { WireTransferCheckResult } from '../../../features/payment/components/WireTransferCheckResult';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export const WireTransferCheck = (): JSX.Element => {
	const { payment } = useSelector((state: RootState) => state);

	return (
		<Layout>
			<Grid container spacing={2} paddingY={5} paddingLeft={5} paddingRight={5} justifyContent={'center'}>
				<Grid item xs={12} display="flex" justifyContent={'center'}>
					<Typography variant="h6">Chequear transferencia bancaria</Typography>
				</Grid>
				<Grid item xs={2}>
					<WireTransferCheckForm />
				</Grid>
				{payment.wireTransferCheckResult != null && (
					<Grid item xs={10}>
						{' '}
						<WireTransferCheckResult />
					</Grid>
				)}
			</Grid>
		</Layout>
	);
};
