import React, { useEffect } from 'react';
import {
	Alert,
	AlertColor,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Snackbar,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import {
	IHotelsInfoForPackageCompositeDetailPower,
	INomenclator,
	IPackageCompositeDetailForPower,
	ManualHotelFareTotalPriceForAllNights,
} from '../../stats/types';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircle from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import HotelIcon from '@mui/icons-material/Hotel';
import { getHotelFares, postQuotation } from '../services';
import {
	AccommodationFaresByRoom,
	AccommodationFaresRoomPrice,
	Destination,
	HotelOptions,
	OptionalService,
	QuotationRoomDistributionFaresRequest,
	RoomFare,
	roomRateType,
	roomServiceType,
	Service,
} from '../types';
import {
	clearQuotation,
	removeHotelOptionsFromDestinations,
	setDestinations,
	setHotelOptionsToDestination,
	setMarkup,
} from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import RoomBaseConfig from 'features/quotation/components/RoomBaseConfig';
import { formatDateToShowUser } from '../../common/helpers';
import moment from 'moment/moment';
import {
	compareDistributionAndRoomDistribution,
	createHotelFareRequestBase,
	customBoards,
	customRates,
	findCustomBoard,
	findCustomRate,
	findPriceInManualRoute,
	getAccommodationFaresByDistribution,
	minPriceInRooms,
} from '../helpers';
import { getHotelDetail } from '../../hotels/components/services';
import { SelectMenuProps } from '../../../constants/constants';
import { useTheme } from '@mui/styles';
import GeneralConfigCompleteQuotation from './GeneralConfig/GeneralConfigCompleteQuotation';
import AirConfigCompleteQuotation from './AirConfig/AirConfigCompleteQuotation';
import { getBasePriceString } from '../../packagesExplorer/helpers';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	containerComponent: {
		marginTop: theme.spacing(2),
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

interface Props {
	packageSelected: INomenclator;
	travelDate: string;
	packageDetail: IPackageCompositeDetailForPower;
	open: boolean;
	onClose: () => void;
	onSuccessCreateQuotation: (newId: number) => void;
}

export const CreateQuotationFromPackageWithManualRouteDialog = (props: Props): JSX.Element => {
	const defaultPriceToFlightConfig = {
		adtPrice: props.packageDetail.route.manualRouteFarePrice?.priceAdult || 0,
		chdPrice: props.packageDetail.route.manualRouteFarePrice?.priceChild || 0,
		infPrice: 0,
	};
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [loading, setLoading] = React.useState(false);

	const classes = useStyles();
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const dispatch = useDispatch();
	const theme = useTheme();
	const { quotation } = useSelector((state: RootState) => state);
	const { airs, destination, roomBaseConfig } = useSelector((state: RootState) => state.quotation);
	const [customBoardsSelected, setCustomBoardsSelected] = React.useState<string[]>([]);
	const [customRateSelected, setCustomRateSelected] = React.useState<string>('');
	const [errorInBasePriceToUseSaveHotelTariff, setErrorInBasePriceToUseSaveHotelTariff] = React.useState<string | null>(
		null,
	);
	const [configureFlights, setConfigureFlights] = React.useState<boolean>(true);
	const handleOnError = (error: string) => {
		setAlert({ show: true, severity: 'error', message: error });
	};
	const showSaveHotelFaresOption = props.packageDetail.destinations.some((destination) =>
		destination.hotels.some((hotel) => hotel.provider === 'MeVuelo'),
	);
	const showHitBrokerOption = props.packageDetail.destinations.some((destination) =>
		destination.hotels.some((hotel) => hotel.provider === 'Hotelbeds'),
	);
	const flattenedMeVueloHotels = props.packageDetail.destinations.reduce(
		(acc, destination) => [...acc, ...destination.hotels.filter((hotel) => hotel.provider === 'MeVuelo')],
		[] as IHotelsInfoForPackageCompositeDetailPower[],
	);
	const checkPossibleErrorInBasePriceInHotels = (): string | null => {
		const maybeHotelWithNotManualFare = flattenedMeVueloHotels.find(
			(hotel) => !hotel.manualHotelFareTotalPriceForAllNights,
		);
		if (maybeHotelWithNotManualFare) {
			return 'No se encontró la tarifa manual del hotel ' + maybeHotelWithNotManualFare.name;
		}
		for (const roomConfig of roomBaseConfig) {
			const totalAdults = roomConfig.adults;
			if (totalAdults <= 0) {
				return 'La habitación con ' + totalAdults + ' adultos debe tener al menos 1 adulto para usar tarifas alojadas';
			} else if (totalAdults > 4) {
				return (
					'La habitación con ' +
					totalAdults +
					' adultos supera el máximo de 4 adultos permitido  para usar tarifas alojadas'
				);
			} else {
				const maybeHotelWithProblemInManualFare = flattenedMeVueloHotels.find(
					(hotel) =>
						findPriceInManualRoute(
							totalAdults,
							hotel.manualHotelFareTotalPriceForAllNights || ({} as ManualHotelFareTotalPriceForAllNights),
						) === undefined,
				);
				if (maybeHotelWithProblemInManualFare) {
					return (
						'No se encontró la tarifa ' +
						getBasePriceString(totalAdults) +
						' del hotel ' +
						maybeHotelWithProblemInManualFare.name +
						' para la habitación con ' +
						totalAdults +
						' adultos'
					);
				}
			}
		}
		return null;
	};

	const isValidAccommodationFaresRoomPriceByCustomRateSelected = (
		accommodationFaresRoomPrice: AccommodationFaresRoomPrice,
	): boolean => {
		if (customRateSelected === '') return true;
		const customRateFromCancellationPolice = findCustomRate(accommodationFaresRoomPrice.cancellationPolicy);
		return customRateFromCancellationPolice?.code == customRateSelected;
	};

	const createHotelOptionFromHotelBedRequest = async (
		destination: Destination,
		hotelId: number,
	): Promise<HotelOptions | null> => {
		try {
			const requestHotelFare = createHotelFareRequestBase(roomBaseConfig, hotelId, destination);
			const hotelFaresResponse = (await getHotelFares(requestHotelFare)).data;

			const roomFares: RoomFare[] = [];
			roomBaseConfig.forEach((room) => {
				const quotationRoomDistributionFaresForRoom = {
					adults: room.adults,
					childrenAges: (room.childs || []).concat(room.infants || []),
				} as QuotationRoomDistributionFaresRequest;
				const faresByDistribution = getAccommodationFaresByDistribution(
					hotelFaresResponse.faresByDistribution,
					requestHotelFare.distributions,
				);
				const faresByRoom: AccommodationFaresByRoom[] =
					faresByDistribution.find((fareByDistribution) =>
						compareDistributionAndRoomDistribution(quotationRoomDistributionFaresForRoom, fareByDistribution),
					)?.faresByRoom || [];
				let allFaresByRoom: AccommodationFaresRoomPrice[] = [];
				faresByRoom.forEach((fareByRoom) => {
					allFaresByRoom = allFaresByRoom.concat(fareByRoom.fares);
				});

				const allFareByRoomsApplyBoards = allFaresByRoom.filter(
					(fareByRoom) =>
						customBoardsSelected.some((boardCode) => boardCode == findCustomBoard(fareByRoom.board)?.code) &&
						isValidAccommodationFaresRoomPriceByCustomRateSelected(fareByRoom),
				);

				const faresByRoomApplyBoardsSortedByPrice = allFareByRoomsApplyBoards.sort((n1, n2) => n1.fare - n2.fare);
				if (faresByRoomApplyBoardsSortedByPrice.length > 0) {
					const fareForRoom = faresByRoomApplyBoardsSortedByPrice[0];
					const customBoard = findCustomBoard(fareForRoom.board);
					const customRate = findCustomRate(fareForRoom.cancellationPolicy);
					roomFares.push({
						price: fareForRoom.fare,
						board: (customBoard ? customBoard.code : '') as roomServiceType,
						rateType: (customRate ? customRate.code : '') as roomRateType,
					} as RoomFare as RoomFare);
				}
			});

			if (roomFares.length === roomBaseConfig.length) {
				const hotelDetail = (await getHotelDetail(hotelId, 'Hotelbeds')).data;
				return {
					hotelId: hotelId,
					hotelName: hotelDetail.name,
					chdCharged: 0,
					infCharged: 0,
					provider: 'Hotelbeds',
					rooms: roomFares,
					details: hotelDetail,
				} as HotelOptions;
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	};

	const createHotelOptionFromSavedTariff = async (
		hotel: IHotelsInfoForPackageCompositeDetailPower,
	): Promise<HotelOptions | null> => {
		try {
			if (hotel.provider !== 'MeVuelo' || !hotel.manualHotelFareTotalPriceForAllNights) return null;

			const roomFares: RoomFare[] = roomBaseConfig
				.map((roomConfig) => {
					const maybePrice = findPriceInManualRoute(
						roomConfig.adults,
						hotel.manualHotelFareTotalPriceForAllNights || ({} as ManualHotelFareTotalPriceForAllNights),
					);
					if (maybePrice === undefined) return null;
					return {
						price: maybePrice * roomConfig.adults,
						board: hotel.board,
						rateType: 'nonRefundable' as roomRateType,
					} as RoomFare;
				})
				.filter((x) => x !== null) as RoomFare[];
			if (roomFares.length !== roomBaseConfig.length) return null;
			const hotelDetail = (await getHotelDetail(hotel.externalId, hotel.provider)).data;
			return {
				hotelId: hotel.externalId,
				hotelName: hotelDetail.name,
				chdCharged: 0,
				infCharged: 0,
				provider: hotel.provider,
				rooms: roomFares,
				details: hotelDetail,
			} as HotelOptions;
		} catch (e) {
			return null;
		}
	};

	const handleApplyHotelConfig = async () => {
		setLoading(true);
		for (const destinationItem of destination) {
			const indexDest = destination.indexOf(destinationItem);
			const hotelsToGetPriceFromHotelBeds = new Set<number>();
			props.packageDetail.destinations[indexDest].hotels.forEach((hotel) => {
				if (hotel.provider === 'Hotelbeds') hotelsToGetPriceFromHotelBeds.add(hotel.externalId);
			});

			const hotelOptionsFromHotelBed = (
				await Promise.all(
					Array.from(hotelsToGetPriceFromHotelBeds).map(async (hotelId) => {
						return await createHotelOptionFromHotelBedRequest(destinationItem, hotelId);
					}),
				)
			).filter((hotelOption) => hotelOption !== null);

			const hotelOptionsFromMeVuelo = (
				await Promise.all(
					Array.from(flattenedMeVueloHotels).map(async (meVueloHotel) => {
						return await createHotelOptionFromSavedTariff(meVueloHotel);
					}),
				)
			).filter((hotelOption) => hotelOption !== null);

			const allHotelsOptions = hotelOptionsFromHotelBed.concat(hotelOptionsFromMeVuelo);

			const hotelsOptionsSorted = allHotelsOptions
				// @ts-ignore
				.sort((a, b) => minPriceInRooms(a.rooms) - minPriceInRooms(b.rooms));

			dispatch(
				setHotelOptionsToDestination({
					destinationIndex: indexDest,
					data: hotelsOptionsSorted as HotelOptions[],
				}),
			);
		}
		setLoading(false);
	};

	const createDestinations = () => {
		const destinationsToAdd: Destination[] = [];
		const totalNightsFirstDestinations = props.packageDetail.destinations[0].hotels[0].totalNights;
		props.packageDetail.destinations.forEach((destinationInfo, index) => {
			const servicesInDestination = props.packageDetail.services.filter(
				(service) =>
					(service.forAllDestinations || service.destinationCodes.some((x) => x == destinationInfo.code)) &&
					service.priceDbl != null,
			);
			const quotationServiceForDestination: Service[] = servicesInDestination.map((service) => {
				return {
					fareCost: service.priceDbl,
					serviceType: 'perPassenger',
					name: service.name,
					description: service.observations || '',
					chdCharged: 1,
					infCharged: 1,
				} as Service;
			});
			if (index === 0) {
				destinationsToAdd.push({
					checking: props.travelDate,
					cityCode: destinationInfo.code,
					cityName: `${destinationInfo.code}-${destinationInfo.name}`,
					nights: totalNightsFirstDestinations,
					services: quotationServiceForDestination,
					hotelOptions: [] as HotelOptions[],
					optionalServices: [] as OptionalService[],
				});
			} else {
				const checkingDateInBeforeDestination = destinationsToAdd[index - 1].checking;
				const nightsInDestination = destinationInfo.hotels[0].totalNights;
				const nightsInBeforeDestination = destinationsToAdd[index - 1].nights;
				const checkInDateInDestination = moment(checkingDateInBeforeDestination, 'YYYY-MM-DD')
					.add(nightsInBeforeDestination, 'days')
					.format('YYYY-MM-DD');

				destinationsToAdd.push({
					checking: checkInDateInDestination,
					cityCode: destinationInfo.code,
					cityName: `${destinationInfo.code}-${destinationInfo.name}`,
					nights: nightsInDestination,
					services: quotationServiceForDestination,
					hotelOptions: [] as HotelOptions[],
					optionalServices: [] as OptionalService[],
				});
			}
		});

		return destinationsToAdd;
	};

	const existOneDestinationWithHotels = () =>
		quotation.destination.some((destination) => destination.hotelOptions.length > 0);
	const allDestinationHasHotels = () =>
		quotation.destination.every((destination) => destination.hotelOptions.length > 0);

	const isValidQuotationData = () => {
		const isDestinationCreated = quotation.destination.length == props.packageDetail.destinations.length;
		const isValidAirData =
			(!configureFlights && airs.length == 0) ||
			(configureFlights && airs.length > 0 && airs[0].data && airs[0].data.length > 0);
		return isDestinationCreated && isValidAirData && existOneDestinationWithHotels();
	};
	const destinationNameWithoutHotels = (): string[] => {
		return quotation.destination.filter((destination) => destination.hotelOptions.length == 0).map((x) => x.cityName);
	};

	const handleCancel = () => {
		dispatch(clearQuotation());
		props.onClose();
	};

	const handleCloseModalWithCreateQuotationSuccess = (quotationId: number) => {
		dispatch(clearQuotation());
		props.onSuccessCreateQuotation(quotationId);
	};

	const handleSaveQuotation = async () => {
		try {
			setLoading(true);
			const finalQuotation = {
				...quotation,
				email: quotation.email !== '' ? quotation.email : null,
			};
			const response = await postQuotation(finalQuotation);
			setLoading(false);
			handleCloseModalWithCreateQuotationSuccess(+`${response.data}`);
		} catch (e) {
			handleOnError('Ocurrió un error al crear la cotización');
			setLoading(false);
		}
	};

	const handleChangeRooms = () => {
		dispatch(removeHotelOptionsFromDestinations());
		setErrorInBasePriceToUseSaveHotelTariff(checkPossibleErrorInBasePriceInHotels());
	};

	const handleClose = (event: any, reason: string) => {
		if (reason !== 'backdropClick') {
			handleCancel();
		}
	};

	useEffect(() => {
		dispatch(setDestinations(createDestinations()));
	}, []);

	useEffect(() => {
		dispatch(setMarkup(`${props.packageDetail.markup}`));
	}, []);

	useEffect(() => {
		handleChangeRooms();
	}, [roomBaseConfig]);

	const initBoardSelectedFromBoardsFromPackage = () => {
		const boardsToSets: string[] = [];
		props.packageDetail.destinations.forEach((destination) => {
			destination.hotels.forEach((hotel) => {
				if (hotel.provider === 'Hotelbeds' && boardsToSets.indexOf(hotel.board) === -1) {
					boardsToSets.push(hotel.board);
				}
			});
		});
		setCustomBoardsSelected(boardsToSets);
	};

	useEffect(() => {
		initBoardSelectedFromBoardsFromPackage();
	}, []);

	const handleChangeBoards = (event: SelectChangeEvent<typeof customBoardsSelected>) => {
		const {
			target: { value },
		} = event;
		setCustomBoardsSelected(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	function getBoardSelectStyle(boardCode: string) {
		return {
			fontWeight:
				customBoardsSelected.indexOf(boardCode) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	}

	const handleChangeRates = (event: SelectChangeEvent) => {
		setCustomRateSelected(event.target.value);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Snackbar open={alert.show} autoHideDuration={3000} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
			<Dialog
				open={props.open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
				fullWidth
				maxWidth="md"
			>
				<DialogTitle id="alert-dialog-title">
					{'Crear nueva cotización a partir del paquete: ' +
						props.packageSelected.name +
						' con ruta manual saliendo el ' +
						formatDateToShowUser(props.travelDate)}
				</DialogTitle>
				<DialogContent>
					<GeneralConfigCompleteQuotation onErrorOnAutocompleteContact={handleOnError} showDescriptionField={false} />

					<RoomBaseConfig />

					<AirConfigCompleteQuotation
						showNotConfigureFlightControl={true}
						showSelectionControl={false}
						maxFlightsAllowed={1}
						defaultPriceConfig={defaultPriceToFlightConfig}
						handleChangeConfigureFlights={setConfigureFlights}
					/>

					{destination.length > 0 && roomBaseConfig.length > 0 && (
						<Container component="main" maxWidth="md" className={classes.containerComponent}>
							<Typography component="h1" variant="h5">
								Configuración de hoteles {allDestinationHasHotels() && <CheckCircle sx={{ color: 'green' }} />}
								{!allDestinationHasHotels() && (
									<ErrorIcon
										titleAccess={'No se han cargado los hoteles. Debe dar clic en el botón aplicar para cargarlos'}
										sx={{ color: 'red', cursor: 'pointer' }}
									/>
								)}
								{existOneDestinationWithHotels() && destinationNameWithoutHotels().length > 0 && (
									<WarningIcon
										titleAccess={`Existen destinos que no se le pudieron cargar hotels: [${destinationNameWithoutHotels().join(
											',',
										)}]. Cuando se adicione la cotización en la vista de detalle de la misma debe de adicionarle hoteles manualmente`}
										sx={{ color: 'yellow', cursor: 'pointer' }}
									/>
								)}
							</Typography>

							{showSaveHotelFaresOption && (
								<>
									<Typography component="h1" variant="body1">
										{' '}
										<HotelIcon /> Tarifas alojadas{' '}
										{errorInBasePriceToUseSaveHotelTariff && (
											<ErrorIcon
												titleAccess={errorInBasePriceToUseSaveHotelTariff}
												sx={{ color: 'red', cursor: 'pointer' }}
											/>
										)}
									</Typography>
									<Typography component="h1" variant="body2">
										{' '}
										Se aplicarán tarifas alojadas para todos los hoteles propios de Mevuelo
									</Typography>
								</>
							)}

							{showHitBrokerOption && (
								<>
									<Typography component="h1" variant="body1">
										{' '}
										<TravelExploreIcon /> Hit Brokers
									</Typography>
									<Typography component="h1" variant="body2">
										{' '}
										Se aplicarán tarifas de Hit Brokers para todos los hoteles de HotelBeds
									</Typography>

									<div className={classes.containerComponent}>
										<FormControl required={true} sx={{ m: 1, mt: 0, width: 300 }}>
											<InputLabel>Regímenes</InputLabel>
											<Select
												multiple
												value={customBoardsSelected}
												onChange={handleChangeBoards}
												input={<OutlinedInput label="Regímenes" />}
												sx={{ height: '40px' }}
												MenuProps={SelectMenuProps}
											>
												{customBoards.map((customBoard) => (
													<MenuItem
														key={customBoard.code}
														value={customBoard.code}
														style={getBoardSelectStyle(customBoard.code)}
													>
														{customBoard.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<FormControl sx={{ m: 1, mt: 0, width: 300 }}>
											<InputLabel>Tipo de tarifa</InputLabel>
											<Select
												value={customRateSelected}
												onChange={handleChangeRates}
												sx={{ height: '40px' }}
												label="Tipo de tarifa"
												MenuProps={SelectMenuProps}
											>
												<MenuItem value="">
													<em>No aplicar ninguna</em>
												</MenuItem>
												{customRates.map((customRates) => (
													<MenuItem key={customRates.code} value={customRates.code}>
														{customRates.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
								</>
							)}

							<Button
								disabled={
									(customBoardsSelected.length == 0 && showHitBrokerOption) ||
									(errorInBasePriceToUseSaveHotelTariff != null && showSaveHotelFaresOption)
								}
								sx={{ ml: 2, height: '40px' }}
								title={'Aplicar'}
								variant="outlined"
								startIcon={<CheckIcon />}
								onClick={handleApplyHotelConfig}
							>
								{' '}
								Aplicar
							</Button>
						</Container>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleCancel}>
						Cancelar
					</Button>
					<Button
						variant="contained"
						color="primary"
						disabled={!isValidQuotationData()}
						onClick={handleSaveQuotation}
						autoFocus
					>
						Crear cotización
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
