import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	convertFiltersToRecord,
	convertValidatingCarrierDataToFilter,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateToShowUser,
	getSortableFields,
} from 'features/common/helpers';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	SelectFilterListData,
	SelectFilterModelSimple,
} from 'features/common/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import { getValidatingCarriers } from 'features/transactions/services';
import React, { useEffect, useState } from 'react';
import { ExportToExcelButton } from '../../../../common/components/ExportToExcelButton';
import {
	automaticRouteTariffPrices,
	automaticRouteTariffPricesAmadeusCommands,
	automaticRouteTariffPricesSegments,
} from '../../../services';
import {
	IAutomaticRouteSuggestion,
	IAutomaticRouteTariffPrice,
	IAutomaticRouteTariffPriceAmadeusCommand,
	IAutomaticRouteTariffPriceAmadeusCommandContainer,
	IAutomaticRouteTariffPriceContainerSegment,
	origenTariffTypes,
	OriginTariffTypeEnum,
} from '../../../types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AutomaticRouteTariffSegmentModal } from '../AutomaticRouteTariffsSegmentModal';
import { PageNoResult } from '../../../../common/components/PageNoResults';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	rowSelected: {
		backgroundColor: '#b198ce',
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const headerCells: IHeadCell[] = [
	{
		field: 'travelDate',
		sortable: true,
		headerName: 'Fecha de viaje',
	},
	{
		field: 'adultTariff',
		sortable: true,
		headerName: 'Tarifa ADT',
	},
	{
		field: 'childTariff',
		sortable: true,
		headerName: 'Tarifa CHD',
	},
	{
		field: 'infantTariff',
		sortable: true,
		headerName: 'Tarifa INF',
	},
	{
		field: 'originTariffType',
		sortable: true,
		headerName: 'Origen de la tarifa',
	},
	{
		field: 'applyDiscount',
		sortable: true,
		headerName: 'Aplicó descuento',
	},
	{
		field: 'observations',
		sortable: true,
		headerName: 'Observaciones',
	},
	{
		field: 'discountApplyInAdult',
		sortable: true,
		headerName: 'Descuento aplicado ADT',
	},
	{
		field: 'discountApplyInChild',
		sortable: true,
		headerName: 'Descuento aplicado CHD',
	},
	{
		field: 'discountApplyInInfant',
		sortable: true,
		headerName: 'Descuento aplicado INF',
	},
	{
		field: 'validatingCarrierCode',
		sortable: true,
		headerName: 'Validating Carrier',
	},
	{
		field: 'isOriginal',
		sortable: true,
		headerName: 'Original',
	},
	{
		field: 'related',
		sortable: true,
		headerName: 'Relacionada',
	},
];

export interface AutomaticRouteProps {
	automaticRouteInfo: IAutomaticRouteSuggestion;
}

export const AutomaticRouteTariffPriceList = ({ automaticRouteInfo }: AutomaticRouteProps): JSX.Element => {
	const classes = useStyles();

	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [tariffPriceList, setTariffPriceList] = useState<IAutomaticRouteTariffPrice[] | null>(null);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'travelDate', order: 'asc' }]);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const [validatingCarriers, setValidatingCarriers] = useState<SelectFilterListData[]>([]);
	const [containerSegments, setContainerSegments] = useState<IAutomaticRouteTariffPriceContainerSegment | null>(null);
	const [containersCommandInfo, setContainersCommandInfo] = useState<
		IAutomaticRouteTariffPriceAmadeusCommandContainer[]
	>([]);
	const [selectedTravelDate, setSelectedTravelDate] = useState<string | null>(null);

	const filters = [
		{ label: 'Fecha de viaje', type: FilterType.DATE, key: 'travelDate' },
		{ label: 'Fechas sin tarifas', type: FilterType.BOOLEAN, key: 'withoutTariff' },
		new DateToFromFilterModel('La Fecha de viaje', 'travelDateFrom', 'travelDateTo'),
		{ label: 'Tarifa ADT', type: FilterType.NUMERIC, key: 'adultTariff' },
		{
			label: 'Tarifa ADT',
			type: FilterType.NUMERIC,
			key: 'adultTariffFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Tarifa ADT',
			type: FilterType.NUMERIC,
			key: 'adultTariffTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		{ label: 'Tarifa CHD', type: FilterType.NUMERIC, key: 'childTariff' },
		{
			label: 'Tarifa CHD',
			type: FilterType.NUMERIC,
			key: 'childTariffFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Tarifa CHD',
			type: FilterType.NUMERIC,
			key: 'childTariffTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		{ label: 'Tarifa INF', type: FilterType.NUMERIC, key: 'infantTariff' },
		{
			label: 'Tarifa INF',
			type: FilterType.NUMERIC,
			key: 'infantTariffFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Tarifa INF',
			type: FilterType.NUMERIC,
			key: 'infantTariffTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		new SelectFilterModelSimple('Origen de la tarifa', 'originTariffType', origenTariffTypes),
		{ label: 'Aplicó descuento', type: FilterType.BOOLEAN, key: 'applyDiscount' },
		{ label: 'Observaciones', type: FilterType.STRING, key: 'observations' },
		{ label: 'Descuento aplicado ADT', type: FilterType.NUMERIC, key: 'discountApplyInAdult' },
		{ label: 'Descuento aplicado CHD', type: FilterType.NUMERIC, key: 'discountApplyInChild' },
		{ label: 'Descuento aplicado INF', type: FilterType.NUMERIC, key: 'discountApplyInInfant' },
		new SelectFilterModelSimple('Validating Carrier', 'validatingCarrierCode', validatingCarriers),
		{ label: 'Original', type: FilterType.BOOLEAN, key: 'isOriginal' },
		{ label: 'Relacionada', type: FilterType.DATE, key: 'related' },
	];

	const getAutomaticRouteTariffPriceListToClient = async () => {
		try {
			setLoading(true);
			const envelopedTransactionList = (
				await automaticRouteTariffPrices(automaticRouteInfo.id, sortFields, convertFiltersToRecord(filterApplied))
			).data;
			setTariffPriceList(envelopedTransactionList.data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de precios de tarifas de la ruta'),
			});
			setLoading(false);
		}
	};

	const getCarriersAndOperators = async () => {
		try {
			setLoading(true);
			setValidatingCarriers(convertValidatingCarrierDataToFilter((await getValidatingCarriers()).data));
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los carriers'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getCarriersAndOperators();
	}, []);

	useEffect(() => {
		getAutomaticRouteTariffPriceListToClient();
	}, [automaticRouteInfo, filterApplied, sortFields]);

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const sortableFields = getSortableFields(headerCells);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const transformValue = (key: string, value: any, row: IAutomaticRouteTariffPrice) => {
		switch (key) {
			case 'originTariffType':
				return origenTariffTypes.find((item) => item.id === value)?.name;
			case 'travelDate':
			case 'related':
				return value ? formatDateToShowUser(value) : '-';
			case 'isOriginal':
			case 'applyDiscount':
				return value != undefined ? (value ? 'Si' : 'No') : '-';

			default:
				return value ? value : '-';
		}
	};

	const transformValueToRenderInList = (key: string, row: IAutomaticRouteTariffPrice) =>
		// @ts-ignore
		transformValue(key, row[key], row);

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const envelopedTariffPriceList = (
				await automaticRouteTariffPrices(automaticRouteInfo.id, sortFields, convertFiltersToRecord(filterApplied))
			).data;
			const tarrifPriceList = envelopedTariffPriceList.data;
			const optionsToExport = {
				title: `Listado de precios de tarifas para la ruta ${automaticRouteInfo.name}`,
				headers: headerCells,
				widthColumns: [15, 15, 15, 15, 20, 20, 40, 15, 15, 15, 15, 10, 15],
				filename: `Listado de precios de tarifas para la ruta ${automaticRouteInfo.name}`,
			} as ExportExcelOptions;
			exportToExcel(tarrifPriceList, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const handleLoadSegments = async (travelDate: string) => {
		try {
			setLoading(true);
			setSelectedTravelDate(travelDate);
			const containerSegments = (await automaticRouteTariffPricesSegments(automaticRouteInfo.id, travelDate)).data;
			setContainerSegments(containerSegments);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los segmentos'),
			});
			setLoading(false);
		}
	};

	const handleOnCloseModal = () => {
		setContainerSegments(null);
		setSelectedTravelDate(null);
	};

	const handleCopyAmadeusCommand = async (
		travelDate: string,
		propertyToCopy: keyof IAutomaticRouteTariffPriceAmadeusCommand,
	) => {
		try {
			const maybeCommandInfo = containersCommandInfo.find((x) => x.travelDate == travelDate);
			if (maybeCommandInfo) {
				// @ts-ignore
				navigator.clipboard.writeText(maybeCommandInfo.commandInfo[propertyToCopy]);
			} else {
				setLoading(true);
				const containerCommandInfo = (
					await automaticRouteTariffPricesAmadeusCommands(automaticRouteInfo.id, travelDate)
				).data;
				setContainersCommandInfo([
					...containersCommandInfo,
					{ travelDate: travelDate, commandInfo: containerCommandInfo },
				]);
				// @ts-ignore
				navigator.clipboard.writeText(containerCommandInfo[propertyToCopy]);
				setLoading(false);
			}
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los segmentos'),
			});
			setLoading(false);
		}
	};

	const colSpan = headerCells.length;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1} paddingY={5}>
				<Grid container spacing={2}>
					<Grid item xs={11}>
						<FilterGrid
							filters={filters}
							handleAppliedFilters={handleApplyFilters}
							preAppliedFilters={filterApplied}
						></FilterGrid>
					</Grid>
					<Grid item xs={1}>
						<ExportToExcelButton disabled={tariffPriceList?.length == 0} handleExportToExcel={handleExportToExcel} />
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: '600px' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
									<TableCell className={classes.tableHeader}>Segmentos</TableCell>
									<TableCell className={classes.tableHeader}>FXD</TableCell>
									<TableCell className={classes.tableHeader}>SN Ida</TableCell>
									<TableCell className={classes.tableHeader}>SN Regreso</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && tariffPriceList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									tariffPriceList?.map((item) => (
										<TableRow
											className={item.travelDate == selectedTravelDate ? classes.rowSelected : ''}
											key={item.travelDate}
											hover
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="p">{transformValueToRenderInList('travelDate', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('adultTariff', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('childTariff', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('infantTariff', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('originTariffType', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('applyDiscount', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('observations', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('discountApplyInAdult', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('discountApplyInChild', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('discountApplyInInfant', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('validatingCarrierCode', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('isOriginal', item)}</TableCell>
											<TableCell component="p">{transformValueToRenderInList('related', item)}</TableCell>
											<TableCell component="p">
												{item.originTariffType == OriginTariffTypeEnum.TRACK && (
													<Button
														title={'Ver segmentos'}
														startIcon={<VisibilityIcon />}
														sx={{ height: 40, ml: 1 }}
														onClick={() => handleLoadSegments(item.travelDate)}
													></Button>
												)}
											</TableCell>
											<TableCell component="p">
												{item.originTariffType == OriginTariffTypeEnum.TRACK && (
													<Button
														title={'Copiar FXD'}
														startIcon={<ContentCopyIcon />}
														sx={{ height: 40, ml: 1 }}
														onClick={() => handleCopyAmadeusCommand(item.travelDate, 'fxd')}
													></Button>
												)}
											</TableCell>
											<TableCell component="p">
												{item.originTariffType == OriginTariffTypeEnum.TRACK && (
													<Button
														title={'Copiar SN Ida'}
														startIcon={<ContentCopyIcon />}
														sx={{ height: 40, ml: 1 }}
														onClick={() => handleCopyAmadeusCommand(item.travelDate, 'snGoing')}
													></Button>
												)}
											</TableCell>
											<TableCell component="p">
												{item.originTariffType == OriginTariffTypeEnum.TRACK && (
													<Button
														title={'Copiar SN Vuelta'}
														startIcon={<ContentCopyIcon />}
														sx={{ height: 40, ml: 1 }}
														onClick={() => handleCopyAmadeusCommand(item.travelDate, 'snReturn')}
													></Button>
												)}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
			</Grid>
			{containerSegments && (
				<AutomaticRouteTariffSegmentModal
					open={containerSegments != null}
					data={containerSegments}
					onCancel={handleOnCloseModal}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
