import React from 'react';

import BlockIcon from '@mui/icons-material/Block';
import { Container } from '@mui/material';

export const PageNotAllowedForMarket = (): JSX.Element => {
	return (
		<Container
			component="main"
			maxWidth="md"
			sx={{ display: 'block', justifyContent: 'center', mt: 20, textAlign: 'center' }}
		>
			<BlockIcon sx={{ color: 'red', textAlign: 'center', fontSize: 70 }} />
			<h2>Página no disponible para el mercado seleccionado</h2>
		</Container>
	);
};
