import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import {
	Grid,
	MenuItem,
	Pagination,
	Select,
	Backdrop,
	CircularProgress,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	Alert,
	TableCell,
	Button,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	InputAdornment,
	IconButton,
	Box,
	Drawer,
	Typography,
	FormControl,
	InputLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { pageSizeByDefault, pageSizeOptions } from 'constants/constants';

import { PageNoResult } from 'features/common/components/PageNoResults';
import { ICountryFE, ShowAlertState } from 'features/common/types';
import { extractErrorMessage } from 'features/quotation/helpers';

import { useDebounceState } from 'features/common/hooks/useDebounceState';
import { getCountries } from 'features/common/services';
import { Controller, useForm } from 'react-hook-form';
import { nanoidGenerator } from 'features/common/helpers';
import { ICustomerLeisureDTO, IPatchCustomerLeisureForm } from 'features/customers/types';

import { useStyles } from './styles';
import { getCustomerLeisureById, getCustomerLeisureList, putCustomerLeisure } from 'features/customers/services';

const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };

export const CustomerLeisureList = (): JSX.Element => {
	const [isEdit, setIsEdit] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const classes = useStyles();
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [currentSize, setCurrentSize] = useState<number>(pageSizeByDefault);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchValue, setSearchValue] = useState<string>('');
	const [data, setData] = useState<ICustomerLeisureDTO[]>([]);
	const [showEdit, setShowEdit] = useState(false);
	const [countries, setCountries] = useState<ICountryFE[]>([]);
	const debouncedSearch: string = useDebounceState(searchValue, 300);

	const toggleShowEdit = () => setShowEdit((prevState) => !prevState);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const handleCloseAlert = () => {
		setAlert(defaultAlertState);
	};

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const {
		register,
		reset,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = useForm<IPatchCustomerLeisureForm>();

	const handleNew = () => {
		setIsEdit(false);
		reset();
		setValue('id', nanoidGenerator());
		toggleShowEdit();
	};

	const handleEdit = (id: string) => async () => {
		setIsEdit(true);
		const { data } = await getCustomerLeisureById(id);
		setValue('id', data.id);
		setValue('name', data.name);
		setValue('lastName', data.lastName);
		setValue('country', data.country.code);
		setValue('stateName', data.stateName);
		setValue('cityName', data.cityName);
		setValue('street', data.street);
		setValue('postalCode', data.postalCode);
		setValue('taxIdentificationNumber', data.taxIdentificationNumber);
		setValue('phone', data.phone || '');
		setValue('email', data.email);
		toggleShowEdit();
	};

	useEffect(() => {
		const fetchCountries = async () => {
			try {
				const { data } = await getCountries();
				setCountries(data);
			} catch (_e) {
				console.error(_e);
			}
		};

		fetchCountries();
	}, [setCountries]);

	const loadData = useCallback(async () => {
		try {
			setLoading(true);
			const { data } = await getCustomerLeisureList(currentPage, currentSize, debouncedSearch);
			setPageTotal(Math.ceil(data.total / data.size));
			setTotalRecords(data.total);
			setData(data.elements);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado '),
			});
			setLoading(false);
		}
	}, [setPageTotal, setTotalRecords, setData, setLoading, setAlert, currentPage, currentSize, debouncedSearch]);

	const onSubmit = async (formData: IPatchCustomerLeisureForm) => {
		try {
			const countryFound = countries.find((item) => item.code === formData.country);

			if (countryFound) {
				const request: Partial<ICustomerLeisureDTO> = {
					id: formData.id,
					name: formData.name,
					lastName: formData.lastName,
					country: {
						code: countryFound.code,
						name: countryFound.name,
					},
					stateName: formData.stateName,
					cityName: formData.cityName,
					street: formData.street,
					postalCode: formData.postalCode,
					phone: formData.phone,
					email: formData.email,
				};
				if (formData.taxIdentificationNumber) {
					request.taxIdentificationNumber = formData.taxIdentificationNumber;
				}

				await putCustomerLeisure(request);
				loadData();
			} else {
				console.error('Error con el pais seleccionado');
			}
		} catch (_e) {
			console.error(_e);
		}
		toggleShowEdit();
	};

	useEffect(() => {
		loadData();
	}, [loadData, currentPage, currentSize, debouncedSearch]);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container sx={{ justifyContent: 'center' }} gap={1}>
				<Grid container className={classes.container}>
					<Grid item xs={3}>
						<TextField
							label="Buscar"
							variant="outlined"
							size="small"
							value={searchValue}
							onChange={handleChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={9} className={classes.containerButtons} gap={1}>
						{totalRecords || 0} Resultados
						<AddCircleIcon fontSize="large" color="primary" sx={{ cursor: 'pointer' }} onClick={handleNew} />
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									<TableCell align="left">Nombre</TableCell>

									<TableCell align="right">Apellido</TableCell>

									<TableCell align="right">RUT</TableCell>

									<TableCell align="right">Pais</TableCell>

									<TableCell align="left"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!data.length ? (
									<TableRow>
										<TableCell colSpan={5}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									data.map((item) => (
										<TableRow key={item.id}>
											<TableCell align="right" component="p">
												{item.name}
											</TableCell>
											<TableCell align="right" component="p">
												{item.lastName}
											</TableCell>
											<TableCell align="right" component="p">
												{item.taxIdentificationNumber}
											</TableCell>
											<TableCell align="right" component="p">
												{item.country.name}
											</TableCell>
											<TableCell align="right">
												<Button onClick={handleEdit(item.id)}>
													<CreateIcon />
												</Button>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
					<Select value={currentSize} onChange={handleRowsPerPageChange}>
						{pageSizeOptions.map((value) => (
							<MenuItem key={value} value={value}>
								{value}
							</MenuItem>
						))}
					</Select>
					<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
				</Box>
			</Grid>

			<Drawer anchor="right" open={showEdit} onClose={toggleShowEdit}>
				<Box sx={{ padding: 3, maxWidth: 600 }} display="flex" flexDirection="column" gap={3}>
					<Typography component="h1" variant="h5">
						{isEdit ? 'Editar cliente' : 'Nuevo cliente'}
					</Typography>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<input type="hidden" {...register('id')} />
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Nombre"
									size="small"
									{...register('name', { required: true })}
									error={!!errors.name}
									helperText={errors.name && 'Campo requerido'}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Apellido"
									size="small"
									{...register('lastName', { required: true })}
									error={!!errors.lastName}
									helperText={errors.lastName && 'Campo requerido'}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl size="small" fullWidth>
									<InputLabel id="country">Pais</InputLabel>
									<Controller
										render={({ field }) => (
											<Select labelId="country" label="Pais" {...field}>
												{countries.map((item) => (
													<MenuItem key={item.code} value={item.code}>
														{item.name}
													</MenuItem>
												))}
											</Select>
										)}
										defaultValue="UY"
										name="country"
										control={control}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Estado"
									size="small"
									{...register('stateName', { required: true })}
									error={!!errors.stateName}
									helperText={errors.stateName && 'Campo requerido'}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Ciudad"
									size="small"
									{...register('cityName', { required: true })}
									error={!!errors.cityName}
									helperText={errors.cityName && 'Campo requerido'}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Direccion"
									size="small"
									{...register('street', { required: true })}
									error={!!errors.street}
									helperText={errors.street && 'Campo requerido'}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Codigo Postal"
									size="small"
									{...register('postalCode', { required: true })}
									error={!!errors.postalCode}
									helperText={errors.postalCode && 'Campo requerido'}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									fullWidth
									label="Tax identification number"
									size="small"
									{...register('taxIdentificationNumber')}
									error={!!errors.taxIdentificationNumber}
									helperText={errors.taxIdentificationNumber && 'Campo requerido'}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									fullWidth
									label="Telefono"
									size="small"
									{...register('phone')}
									error={!!errors.phone}
									helperText={errors.phone && 'Campo requerido'}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									label="Email"
									size="small"
									{...register('email', { required: true })}
									error={!!errors.email}
									helperText={errors.email && 'Campo requerido'}
								/>
							</Grid>
						</Grid>
						<Box display="flex" flexDirection="row-reverse">
							<Button variant="contained" type="submit">
								Guardar
							</Button>
						</Box>
					</form>
				</Box>
			</Drawer>

			<Snackbar open={alert.show} autoHideDuration={2000} onClose={handleCloseAlert}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
