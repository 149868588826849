import React from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { ControlledTextField } from '../ControlledTextField';
import { FlightIssueDefaultRuleFormValues } from '../../types';

export interface IFlightIssueDefaultRuleFormProps {
	isToCreate: boolean;
	values: FlightIssueDefaultRuleFormValues;
	handleSubmitForm: (value: FlightIssueDefaultRuleFormValues) => void;
}

export const FlightIssueDefaultRuleForm = ({
	isToCreate,
	values,
	handleSubmitForm,
}: IFlightIssueDefaultRuleFormProps): JSX.Element => {
	const methods = useForm<FlightIssueDefaultRuleFormValues>({ mode: 'all', defaultValues: values });

	const {
		formState: { isValid },
		getValues,
	} = methods;

	return (
		<>
			<Card>
				<CardContent>
					{isToCreate && <Typography variant="h6">Crear nueva regla por defecto:</Typography>}
					{!isToCreate && <Typography variant="h6">Editar regla por defecto:</Typography>}
					<br />
					<FormProvider {...methods}>
						<Box component="form" display="flex" flexDirection="column" gap={1}>
							<Box display="flex" flexDirection="row" justifyContent="space-around">
								<Box display="flex" alignItems="center" gap={2} flex={1}>
									<ControlledTextField
										name={'ruleComment'}
										label={'Texto para la regla'}
										required={true}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
											maxLength: { value: 500, message: 'Máximo 500 caracteres' },
										}}
										isNumber={false}
									/>
								</Box>
							</Box>
							<Box display={'flex'} width="100%">
								<Button
									variant="contained"
									disabled={!isValid}
									color="success"
									sx={{ marginLeft: 'auto' }}
									onClick={() => handleSubmitForm(getValues())}
								>
									Guardar
								</Button>
							</Box>
						</Box>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
};
