import { createTheme } from '@mui/material/styles';
import './fonts/itau-display-pro/itau-display-pro.css';

// Tema con la primera fuente
export const itau = createTheme({
	typography: {
		fontFamily: 'ItauDisplayPro, sans-serif',
	},
	palette: {
		primary: {
			main: '#31194B',
		},
		secondary: {
			main: '#ED6602',
		},
		text: {
			primary: '#272727',
		},
	},
});

// Tema con la segunda fuente
export const mevuelo = createTheme({
	typography: {
		fontFamily: '"Montserrat", sans-serif',
	},
	palette: {
		primary: {
			main: '#31194B',
		},
		secondary: {
			main: '#ED6602',
		},
		text: {
			primary: '#272727',
		},
		warning: {
			main: '#4A148C',
			contrastText: '#EDE7F6',
			light: '#AB47BC',
		},
	},
});
