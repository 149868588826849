import React from 'react';

import { Container, Grid, Typography } from '@mui/material';
import { Layout } from 'features/common/components/Layout';
import { ActivityLogList } from '../../../features/activityLog/components/ActivityLogList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { isAdminOrSuperAdmin } from '../../../features/auth/helpers';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));

export const ActivityLogListPage = (): JSX.Element => {
	const classes = useStyles();
	const { auth } = useSelector((state: RootState) => state);
	if (!isAdminOrSuperAdmin(auth.user)) return <></>;
	return (
		<Layout>
			<Container component="main" maxWidth="xl">
				<Grid item xs={12} sx={{ ml: 20 }}>
					<Typography variant="h5" className={classes.headerTitle}>
						Activity Logs List
					</Typography>
				</Grid>
				<Grid paddingY={5}>
					<ActivityLogList />
				</Grid>
			</Container>
		</Layout>
	);
};
