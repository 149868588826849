import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, IconButton, Paper, styled, TableCell, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import PlaceIcon from '@mui/icons-material/Place';
import routes from 'constants/routes';
import { IFlightIssueRule } from '../../types';
import { flightIssueRuleHeaderCells } from '../../constants';
import { transformValue } from '../../helpers';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import AirlinesIcon from '@mui/icons-material/Airlines';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const useStyles = makeStyles((theme) => ({
	tableCell: {
		padding: theme.spacing(1),
		boxSizing: 'border-box',
		width: '100%',
	},
	grid: {
		width: '100%',
	},
}));

const DetailItem = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
	padding: theme.spacing(1),
	textAlign: 'center',
	minHeight: '52px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	boxSizing: 'border-box',
	fontSize: theme.typography.body2.fontSize,
}));

export interface IExpandedRowFlightIssueRuleListDetailProps {
	item: IFlightIssueRule;
	handleDelete: (id: string) => void;
}

export function ExpandedRowFlightIssueRuleListDetail({
	item,
	handleDelete,
}: IExpandedRowFlightIssueRuleListDetailProps): JSX.Element {
	const classes = useStyles();
	const history = useHistory();
	const handleEditClick = (id: string) => () => {
		history.push(routes.flightIssueRule.upsert.replace(':id', id));
	};
	return (
		<TableRow>
			<TableCell colSpan={flightIssueRuleHeaderCells.length + 1} className={classes.tableCell}>
				<Grid container spacing={1} className={classes.grid}>
					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<AirlinesIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Validating Carrier Incluye:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('vcIncludes', item.vcIncludes, item)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<AirlinesIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Validating Carrier Excluye:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('vcExcludes', item.vcExcludes, item)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<PlaceIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Origen Incluye:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('originIncludes', item.originIncludes, item)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<PlaceIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Origen Excluye:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('originExcludes', item.originExcludes, item)}
							</Typography>
						</DetailItem>
					</Grid>

					<Grid item xs={2}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<FlightLandIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Escalas Incluye:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('layoverIncludes', item.layoverIncludes, item)}
							</Typography>
						</DetailItem>
					</Grid>

					<Grid item xs={2}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<FlightLandIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Escalas Excluye:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('layoverExcludes', item.layoverExcludes, item)}
							</Typography>
						</DetailItem>
					</Grid>

					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<PlaceIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Destino Incluye:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('stopoverIncludes', item.stopoverIncludes, item)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={2}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<PlaceIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Incluir otros:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('mustContainOtherStopover', item.mustContainOtherStopover, item)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<PlaceIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Destino Excluye:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('stopoverExcludes', item.stopoverExcludes, item)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<AirplaneTicketIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									GDS:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('gds', item.gds, item)}
							</Typography>
						</DetailItem>
					</Grid>

					<Grid item xs={2}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<AttachMoneyIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Tarifa por pax:
								</Typography>
							</Box>
							{item.costByTicketOperator && (
								<Typography variant="body2" sx={{ ml: 2 }}>
									{' '}
									{transformValue('costByTicketOperator', item.costByTicketOperator, item)}{' '}
									{transformValue('costByTicket', item.costByTicket, item)}
								</Typography>
							)}
							{!item.costByTicketOperator && (
								<Typography variant="body2" sx={{ ml: 2 }}>
									{'-'}
								</Typography>
							)}
						</DetailItem>
					</Grid>

					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<PersonIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Actualizado por:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('updatedBy', item.updatedBy, item)}
							</Typography>
						</DetailItem>
					</Grid>

					<Grid item xs={3}>
						<DetailItem>
							<Box style={{ display: 'flex', alignItems: 'center' }}>
								<CalendarMonthIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Fecha actualización:
								</Typography>
							</Box>
							<Typography variant="body2" sx={{ ml: 2 }}>
								{' '}
								{transformValue('updated', item.updated, item)}
							</Typography>
						</DetailItem>
					</Grid>

					<Grid item xs={1}>
						<IconButton aria-label="edit" title={'Editar'} onClick={handleEditClick(item.id)}>
							<EditIcon />
						</IconButton>
						{!item.isDefaultRule && (
							<IconButton aria-label="delete" title={'Eliminar'} onClick={() => handleDelete(item.id)}>
								<DeleteIcon />
							</IconButton>
						)}
					</Grid>
				</Grid>
			</TableCell>
		</TableRow>
	);
}
