import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AirConfigCompleteQuotation from '../AirConfig/AirConfigCompleteQuotation';

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

export interface Props {
	open: boolean;
	handleClose: () => void;
}

export function AirConfigModal({ open, handleClose }: Props): JSX.Element {
	return (
		<div>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="h5" color="primary">
						Configuración de vuelos a partir de TST
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<AirConfigCompleteQuotation showNotConfigureFlightControl={false} showSelectionControl={false} />
				</DialogContent>
			</Dialog>
		</div>
	);
}
