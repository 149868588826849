import Cookies from 'js-cookie';

const TOKEN_KEY = 'Q_TOKEN';
const API_KEY = 'Q_API_KEY';
const MeVueloToken = 'M_TOKEN';
//const TOKEN_KEY = '28580003-a476-4430-8c99-7a70f8477dc1';

export class CookieService {
	static setToken = (token: string, hours: number): string | undefined =>
		Cookies.set(TOKEN_KEY, token, { expires: Math.floor(hours / 24) });

	static setMeVueloToken = (token: string): string | undefined => Cookies.set(MeVueloToken, token);

	static setApiKey = (apiKey: string): string | undefined => Cookies.set(API_KEY, apiKey);

	static getToken = (): string | undefined => Cookies.get(TOKEN_KEY);
	static getApiKey = (): string | undefined => Cookies.get(API_KEY);

	static getMeVueloToken = (): string | undefined => Cookies.get(MeVueloToken);

	static clearToken = (): void => Cookies.remove(TOKEN_KEY);
	static clearApiKey = (): void => Cookies.remove(API_KEY);
}
