import makeStyles from '@mui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: theme.palette.common.white,
	},
	container: {
		maxWidth: 1100,
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: theme.shape.borderRadius,
	},
	card: {
		marginBottom: theme.spacing(5),
		marginLeft: theme.spacing(0),
		background: theme.palette.grey[100],
		display: 'flex',
		borderRadius: theme.shape.borderRadius,
		border: '1px solid rgba(0, 0, 0, 0.12)',
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	title: {
		color: theme.palette.grey[600],
	},
	title1: {
		color: theme.palette.grey[600],
		fontSize: '14px',
		paddingBottom: theme.spacing(1),
	},
	containerHeader: {
		justifyContent: 'space-between',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	header: {
		display: 'flex',
		marginLeft: theme.spacing(3),
		marginTop: theme.spacing(4),
	},
	main: {
		marginTop: theme.spacing(5),
		padding: theme.spacing(2),
		paddingRight: theme.spacing(4),
	},
	icon: {
		color: theme.palette.grey[600],
	},
	names: {
		color: theme.palette.grey[600],
		display: 'flex',
	},
	data: {
		paddingBottom: theme.spacing(1),
	},
	buttonExpande: {
		border: theme.spacing(0),
		background: theme.palette.common.white,
	},
	textField: {
		backgroundColor: theme.palette.common.white,
	},
	containerButtom: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	buttonSave: {
		marginTop: theme.spacing(3),
		backgroundColor: theme.palette.success.light,
		padding: theme.spacing(2),
		color: theme.palette.common.white,
	},
	buttonAdd: {
		marginTop: theme.spacing(3),
		border: theme.spacing(0),
		color: theme.palette.warning.light,
	},
	search: {
		color: theme.palette.warning.light,
		height: '80%',
		marginTop: theme.spacing(2.5),
	},
	buttonDeleter: {
		marginTop: theme.spacing(6),
		border: theme.spacing(0),
		color: theme.palette.error.main,
	},
	buttonLoading: {
		gap: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
		background: theme.palette.warning.contrastText,
		display: 'flex',
		alignItems: 'center',
	},
	buttonLoadingOn: {
		gap: theme.spacing(1),
		background: theme.palette.warning.contrastText,
		boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
		display: 'flex',
		alignItems: 'center',
	},
	img: {
		borderRadius: theme.shape.borderRadius,
	},
	box: {
		'& > img': {
			marginRight: theme.spacing(1),
			borderRadius: theme.shape.borderRadius,
			marginLeft: theme.spacing(-1),
		},
	},
	select: {
		color: theme.palette.grey[600],
		backgroundColor: 'transparent',
		borderRadius: theme.spacing(0),
		paddingRight: theme.spacing(2),
		'&.selected': {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.warning.light,
			borderRadius: theme.shape.borderRadius,
		},
	},
	checkboxSelected: {
		'&.Mui-checked': {
			color: theme.palette.common.white,
		},
	},
}));
