import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddCommentIcon from '@mui/icons-material/AddComment';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { IHeadCell } from 'features/common/types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { formatDateToShowUser } from '../../../common/helpers';
import { IInfimiaWireTransferTransactionItem } from '../../types';
import { AddReferenceToWireTransferModal } from '../AddReferenceToWireTransferModal';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

const headerCells: IHeadCell[] = [
	{
		field: 'country',
		sortable: false,
		headerName: 'País',
	},
	{
		field: 'bank',
		sortable: false,
		headerName: 'Banco',
	},
	{
		field: 'account',
		sortable: false,
		headerName: 'Número de cuenta',
	},
	{
		field: 'bankDate',
		sortable: false,
		headerName: 'Fecha de entrada',
	},
	{
		field: 'bankReference',
		sortable: false,
		headerName: 'Referencia bancaria',
	},
	{
		field: 'companyReference',
		sortable: false,
		headerName: 'Referencia MeVuelo',
	},
	{
		field: 'credit',
		sortable: false,
		headerName: 'Crédito',
	},
	{
		field: 'currency',
		sortable: false,
		headerName: 'Moneda',
	},
];

export const WireTransferCheckResult = (): JSX.Element | null => {
	const classes = useStyles();
	const [wireTransferCheckItem, setWireTransferCheckItem] = useState<IInfimiaWireTransferTransactionItem | null>(null);
	const { payment } = useSelector((state: RootState) => state);

	if (payment.wireTransferCheckResult == null) {
		return null;
	}

	const handleAddReference = (item: IInfimiaWireTransferTransactionItem) => {
		setWireTransferCheckItem(item);
	};

	const handleCloseAddReferenceModal = () => {
		setWireTransferCheckItem(null);
	};
	const isAllowedToAddReference = (item: IInfimiaWireTransferTransactionItem): boolean =>
		!item?.companyReference && Number(item.credit) > 0;

	return (
		<>
			<Grid container gap={1}>
				<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: '600px' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return (
											<TableCell className={classes.tableHeader} key={cell.field} align="right">
												{cell.headerName}
											</TableCell>
										);
									})}
									<TableCell className={classes.tableHeader} align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{payment.wireTransferCheckResult?.length > 0 ? (
									payment.wireTransferCheckResult?.map((wireTransferCheckItem) => (
										<TableRow
											key={wireTransferCheckItem.id}
											hover
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell align="right" component="p">
												{wireTransferCheckItem.country}
											</TableCell>
											<TableCell align="right" component="p">
												{wireTransferCheckItem.bank}
											</TableCell>
											<TableCell align="right" component="p">
												{wireTransferCheckItem.account}
											</TableCell>
											<TableCell align="right" component="p">
												{formatDateToShowUser(wireTransferCheckItem.bankDate)}
											</TableCell>
											<TableCell align="right" component="p">
												{wireTransferCheckItem.bankReference}
											</TableCell>
											<TableCell align="right" component="p">
												{wireTransferCheckItem.companyReference ? wireTransferCheckItem.companyReference : ''}
											</TableCell>
											<TableCell align="right" component="p">
												{wireTransferCheckItem.credit}
											</TableCell>
											<TableCell align="right" component="p">
												{wireTransferCheckItem.currency}
											</TableCell>
											<TableCell align="right" component="p">
												{isAllowedToAddReference(wireTransferCheckItem) && (
													<AddCommentIcon
														cursor={'pointer'}
														color="primary"
														titleAccess={'Adicionar referencia MeVuelo'}
														onClick={() => handleAddReference(wireTransferCheckItem)}
													/>
												)}
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell align="center" colSpan={10}>
											No se encontraron resultados
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
			</Grid>
			{wireTransferCheckItem && (
				<AddReferenceToWireTransferModal
					item={wireTransferCheckItem}
					open={wireTransferCheckItem != null}
					handleClose={handleCloseAddReferenceModal}
				/>
			)}
		</>
	);
};
