import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AirDisplay } from './AirDisplay';

import { Air, IFlightFinalPriceSummary } from '../../types';
import { RootState } from '../../../../store';
import { TstDialog } from './TstDialog';
import { addAirConfigQuotationOnlyFlights, deleteAirConfigQuotationOnlyFlights } from '../../sliceOnlyFlights';
import { FlightPricePreviewDesktop } from '../QuotationOnlyFlightsPreview/FlightPricePreviewDesktop';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { FlightPricePreviewMobile } from '../QuotationOnlyFlightsPreview/FlightPricePreviewMobile';
import { FlightFormConfig } from './FlightFormConfig';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	formControl: {
		width: '100%', // Fix IE 11 issue.
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	previewTitle: {
		marginBottom: theme.spacing(3),
	},
	configureFlight: {
		float: 'right',
	},
}));

export interface Props {
	showSelectionControl?: boolean;
	indexSelected?: number | null;
	handleSelectionChange?: (index: number | null) => void;
	flightFinalPriceSummaries: IFlightFinalPriceSummary[];
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}
export default function AirConfigQuotationOnlyFlights({
	showSelectionControl,
	indexSelected,
	handleSelectionChange,
	flightFinalPriceSummaries,
	exchangeRate,
	localCurrency,
	showLocalCurrency,
}: Props): JSX.Element {
	const classes = useStyles();
	const [showTstDialog, setShowTstDialog] = useState<boolean[]>([]);
	const { airs, id, passengerConfig, markup } = useSelector((state: RootState) => state.quotationOnlyFlights);
	const isMediumDown = useBreakpoints().mediumDown;

	const dispatch = useDispatch();

	const handleDeleteAirConfig = (index: number) => {
		dispatch(deleteAirConfigQuotationOnlyFlights(index));
	};

	const handleAddAir = (air: Air) => {
		dispatch(addAirConfigQuotationOnlyFlights(air));
	};

	const handleShowTstDialog = (index: number) => {
		setShowTstDialog((prevState) => {
			const newState = [...prevState];
			newState[index] = !newState[index];
			return newState;
		});
	};

	return useMemo(() => {
		return (
			<Container component="main" maxWidth="md">
				<div className={classes.paper}>
					<Typography component="h1" variant="h5">
						Vuelos *
					</Typography>

					{airs &&
						airs.map((air, index) => {
							return (
								<>
									{air.tst && (
										<Grid container direction="row-reverse">
											<Button
												variant="contained"
												color="primary"
												onClick={() => handleShowTstDialog(index)}
												style={{ margin: 8 }}
											>
												Ver TST
											</Button>
										</Grid>
									)}
									{(showTstDialog[index] || false) && air.tst && (
										<TstDialog
											open={showTstDialog[index] || false}
											tst={air?.tst}
											onClose={() => handleShowTstDialog(index)}
										/>
									)}

									<AirDisplay
										handleDeleteFlight={handleDeleteAirConfig}
										data={air}
										previewMode={false}
										index={index}
										handleSelectionChange={handleSelectionChange}
										showSelectionControl={showSelectionControl}
										indexSelected={indexSelected}
									/>
									{passengerConfig && flightFinalPriceSummaries[index] && !isMediumDown && (
										<FlightPricePreviewDesktop
											exchangeRate={exchangeRate}
											localCurrency={localCurrency}
											showLocalCurrency={showLocalCurrency}
											passengerConfig={passengerConfig}
											markup={markup}
											flightFinalPriceSummary={flightFinalPriceSummaries[index]}
											showUtility={true}
										/>
									)}
									{passengerConfig && flightFinalPriceSummaries[index] && isMediumDown && (
										<FlightPricePreviewMobile
											exchangeRate={exchangeRate}
											localCurrency={localCurrency}
											showLocalCurrency={showLocalCurrency}
											passengerConfig={passengerConfig}
											markup={markup}
											flightFinalPriceSummary={flightFinalPriceSummaries[index]}
											showUtility={true}
										/>
									)}
								</>
							);
						})}

					{airs.length < 3 && <FlightFormConfig handleAddAir={handleAddAir} />}
				</div>
			</Container>
		);
	}, [
		airs,
		showTstDialog,
		id,
		indexSelected,
		showSelectionControl,
		passengerConfig,
		markup,
		isMediumDown,
		flightFinalPriceSummaries,
		showLocalCurrency,
	]);
}
