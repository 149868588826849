import React from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { dayNumber, monthNumber } from 'features/quotation/helpers';
import { IFlightServiceLeg } from 'features/salesOrder/types';
import logo from 'assets/logo.png';
import { Delete } from '@mui/icons-material';
import { deleteFlightLeg } from 'features/salesOrder/services';
import { ShowAlertState } from 'features/common/types';

const useStyles = makeStyles(() =>
	createStyles({
		logo: {
			height: '1rem',
		},
		paddingLeft: {
			paddingLeft: '4px',
		},
		logog: {
			height: '2rem',
		},
	}),
);

interface FlightsTableProps {
	flightLegs: IFlightServiceLeg[];
	saleOrderId: string;
	serviceId: string;
	onUpdateService: () => void;
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
	allowDelete?: boolean;
}

export const FlightsTable = ({
	flightLegs,
	saleOrderId,
	serviceId,
	onUpdateService,
	onSetAlert,
	onSetLoading,
	allowDelete,
}: FlightsTableProps): JSX.Element => {
	const classes = useStyles();

	return (
		<TableContainer sx={{ mt: 5, mb: 5 }}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="center" colSpan={1}>
							<img src={logo} alt="logo" className={classes.logog} />
						</TableCell>
						<TableCell align="center" colSpan={3}>
							Partida
						</TableCell>
						<TableCell align="center" colSpan={3}>
							Arribo
						</TableCell>
						<TableCell />
					</TableRow>
					<TableRow>
						<TableCell align="center" colSpan={1}>
							Vuelo
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Aeropuerto
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Fecha
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Hora
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Aeropuerto
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Fecha
						</TableCell>
						<TableCell align="center" colSpan={1}>
							Hora
						</TableCell>
						{allowDelete && <TableCell />}
					</TableRow>
				</TableHead>
				<TableBody>
					{flightLegs.map((info) => (
						<TableRow key={info.flightNumber}>
							<TableCell padding="none" className={classes.paddingLeft}>
								<img src={info.airline.image} alt={info.airline.name} className={classes.logo} /> {info.flightNumber}
							</TableCell>
							<TableCell>
								{info.departure.airport.name}, {info.departure.airport.cityName}
							</TableCell>
							<TableCell align="center" padding="none">
								{dayNumber(info.departure.date)} {monthNumber(info.departure.date)}
							</TableCell>
							<TableCell align="center">{info.departure.hour.substring(0, 5)}</TableCell>
							<TableCell>
								{info.arrival.airport.name}, {info.arrival.airport.cityName}
							</TableCell>
							<TableCell align="center" padding="none">
								{dayNumber(info.arrival.date)} {monthNumber(info.arrival.date)}
							</TableCell>
							<TableCell align="center">{info.arrival.hour.substring(0, 5)}</TableCell>
							{allowDelete && (
								<TableCell align="center">
									<IconButton
										onClick={async () => {
											try {
												await deleteFlightLeg(saleOrderId, serviceId, info.keyId);
												onSetAlert({
													show: true,
													severity: 'success',
													message: 'Pierna Borrada',
												});
											} catch (error) {
												onSetAlert({
													show: true,
													severity: 'error',
													// @ts-ignore
													message: error.response.data.message,
												});
											} finally {
												setTimeout(() => {
													onSetLoading(false);
													onUpdateService();
												}, 500);
											}
										}}
									>
										<Delete color="error" />
									</IconButton>
								</TableCell>
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
