import React from 'react';
import { Card } from '@mui/material';
import { IServiceInfoForPackageCompositeDetailPower } from '../../types';
import Typography from '@mui/material/Typography';
import { PackageCompositeServiceDetail } from './PackageCompositeServiceDetail';

export interface PackageCompositeServicesDetailsProps {
	services: IServiceInfoForPackageCompositeDetailPower[];
}

export const PackageCompositeServicesDetails = ({ services }: PackageCompositeServicesDetailsProps): JSX.Element => {
	return (
		<Card sx={{ 'margin-top': '10px' }}>
			<Typography color="primary" variant={'h6'} fontWeight="bold" py={1} px={1}>
				Servicios asociados
			</Typography>
			{services.map((service, serviceIndex) => (
				<PackageCompositeServiceDetail key={'service-' + serviceIndex} service={service} />
			))}
		</Card>
	);
};
