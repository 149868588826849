import React, { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	LinearProgress,
	Tooltip,
	List,
	ListItem,
	ListItemText,
	Paper,
	LinearProgressProps,
	Avatar,
	ListItemAvatar,
	Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
	Person as PersonIcon,
	AssignmentTurnedIn as AssignmentTurnedInIcon,
	CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { formatDateTimeToShowUser } from '../../../../../features/common/helpers';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
enum CustomerStateItemInfoState {
	Pending = 1,
	InProgress = 2,
	FinishOk = 3,
	FinishWithError = 4,
}

enum EventType {
	InitialState = 'InitialState',
	Add = 'Add',
	Update = 'Update',
	Delete = 'Delete',
	Ping = 'Ping',
}

interface ICustomerStateMetadata {
	name: string;
	lastName: string;
	country: string;
	isPhysicalPerson: boolean;
	userNameTriggerSync: string;
}

interface ICustomerStateItemInfo {
	id: string;
	metadata: ICustomerStateMetadata;
	state: CustomerStateItemInfoState;
	toRunAgain: boolean;
	totalRetry: number;
	createdAt: string;
	maybeError?: string;
	// Nuevo campo para controlar la animación
	show: boolean;
}

interface ISyncInitialEventStateCustomer {
	items: ICustomerStateItemInfo[];
	eventType: EventType;
}

interface ISyncUpdateOrAddEventStateCustomer {
	item: ICustomerStateItemInfo;
	eventType: EventType;
}

interface ISyncDeleteEventStateCustomer {
	itemId: string;
	eventType: EventType;
	isDeleteByMaxRetry: boolean;
}

// Styled components
const StyledList = styled(List)(({ theme }) => ({
	width: '100%',
	backgroundColor: theme.palette.background.paper,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
	marginBottom: theme.spacing(2),
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: theme.shape.borderRadius,
	backgroundColor: theme.palette.background.paper,
}));

const CustomersSyncProcessStatus: React.FC = () => {
	const [events, setEvents] = useState<ICustomerStateItemInfo[]>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const apiUrl = process.env.REACT_APP_SALES_ORDER_API_URL || '';
		const eventSource = new EventSource(`${apiUrl}events/customers-sync`);

		eventSource.onmessage = (event: MessageEvent) => {
			try {
				const newEvent = JSON.parse(event.data);
				const { eventType } = newEvent as { eventType: EventType };
				console.log('event received:', newEvent);

				if (eventType === EventType.Ping) {
					return;
				} else if (eventType === EventType.InitialState) {
					const initialEvent = newEvent as ISyncInitialEventStateCustomer;
					setEvents((prevEvents) => {
						const combinedEvents = [...prevEvents, ...initialEvent.items.map((item) => ({ ...item, show: true }))];
						return sortEventsByDate(combinedEvents);
					});
				} else if (eventType === EventType.Add || eventType === EventType.Update) {
					const updateEvent = newEvent as ISyncUpdateOrAddEventStateCustomer;
					const newItem = { ...updateEvent.item, show: true };
					setEvents((prevEvents) => {
						const index = prevEvents.findIndex((e) => e.id === newItem.id);
						if (index !== -1) {
							const updatedEvents = [...prevEvents];
							updatedEvents[index] = newItem;
							return sortEventsByDate(updatedEvents);
						} else {
							return sortEventsByDate([...prevEvents, newItem]);
						}
					});
				} else if (eventType === EventType.Delete) {
					const deleteEvent = newEvent as ISyncDeleteEventStateCustomer;

					setEvents((prevEvents) => {
						const index = prevEvents.findIndex((e) => e.id === deleteEvent.itemId);
						if (index !== -1) {
							const item = prevEvents[index];
							if (item.state === CustomerStateItemInfoState.InProgress && !deleteEvent.isDeleteByMaxRetry) {
								console.log('Item in progress, setting to FinishOk with animation');
								const updatedItem = {
									...item,
									state: CustomerStateItemInfoState.FinishOk,
								};
								const updatedEvents = [...prevEvents];
								updatedEvents[index] = updatedItem;

								// Iniciar animación de fade-out después de cambiar el estado
								setTimeout(() => {
									setEvents((events) => events.map((e) => (e.id === deleteEvent.itemId ? { ...e, show: false } : e)));
								}, 500); // Esperar un poco antes de iniciar la animación

								// Eliminar el elemento después de que la animación termine
								setTimeout(() => {
									setEvents((events) => events.filter((e) => e.id !== deleteEvent.itemId));
								}, 2500); // Duración total antes de eliminar (500ms delay + 1500ms animation)

								return updatedEvents;
							} else {
								return prevEvents.filter((e) => e.id !== deleteEvent.itemId);
							}
						} else {
							return prevEvents;
						}
					});
				}
			} catch (e) {
				console.error('Error parsing event:', e);
			}
		};

		eventSource.onerror = (err) => {
			console.error('SSE connection error', err);
			setError('Ocurrió un error en la conexión con el servidor. Por favor actualice la página.');
			eventSource.close();
		};

		return () => {
			eventSource.close();
		};
	}, []);

	const sortEventsByDate = (eventsArray: ICustomerStateItemInfo[]) => {
		return eventsArray.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
	};

	const getProgressBarProps = (state: CustomerStateItemInfoState): LinearProgressProps => {
		const baseProps: LinearProgressProps = {
			sx: { width: '100%', mt: 1 },
		};

		switch (state) {
			case CustomerStateItemInfoState.Pending:
				return {
					...baseProps,
					variant: 'determinate',
					value: 0,
					color: 'info',
				};
			case CustomerStateItemInfoState.InProgress:
				return {
					...baseProps,
					variant: 'indeterminate',
					color: 'primary',
				};
			case CustomerStateItemInfoState.FinishOk:
				return {
					...baseProps,
					variant: 'determinate',
					value: 100,
					color: 'success',
				};
			case CustomerStateItemInfoState.FinishWithError:
				return {
					...baseProps,
					variant: 'determinate',
					value: 100,
					color: 'error',
				};
			default:
				return {
					...baseProps,
					variant: 'determinate',
					value: 0,
					color: 'info',
				};
		}
	};

	const getStateTextInSpanish = (state: CustomerStateItemInfoState): string => {
		switch (state) {
			case CustomerStateItemInfoState.Pending:
				return 'Pendiente';
			case CustomerStateItemInfoState.InProgress:
				return 'En Progreso';
			case CustomerStateItemInfoState.FinishOk:
				return 'Finalizado Correctamente';
			case CustomerStateItemInfoState.FinishWithError:
				return 'Finalizado con Error';
			default:
				return 'Desconocido';
		}
	};

	return (
		<Box p={2}>
			{error && <Typography color="error">{error}</Typography>}
			<Paper elevation={3} style={{ maxHeight: 500, overflow: 'auto', padding: '16px' }}>
				{events.length === 0 ? (
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" p={4}>
						<AssignmentTurnedInIcon style={{ fontSize: 80, color: '#ccc' }} />
						<Typography variant="h6" color="textSecondary" align="center">
							No hay elementos procesándose en este momento.
						</Typography>
					</Box>
				) : (
					<StyledList>
						{events.map((event) => {
							const progressBarProps = getProgressBarProps(event.state);
							const title = `ID: ${event.id} ${event.metadata.name} ${event.metadata.lastName}`;
							const subtitle = `${event.metadata.country} • En cola desde: ${formatDateTimeToShowUser(
								event.createdAt,
							).substr(0, 16)} • Creado por: ${event.metadata.userNameTriggerSync}`;
							const stateText = getStateTextInSpanish(event.state);
							const listItemContent = (
								<>
									<ListItemAvatar>
										<Avatar>
											{event.state === CustomerStateItemInfoState.FinishOk ? (
												<CheckCircleIcon color="success" />
											) : event.metadata.isPhysicalPerson ? (
												<PersonIcon />
											) : (
												<BusinessCenterIcon />
											)}
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary={title} secondary={subtitle} />
									<Box sx={{ width: '50%', ml: 2 }}>
										<Typography variant="body2" align="center">
											{stateText}
										</Typography>
										<LinearProgress {...progressBarProps} sx={{ height: '15px' }} />
									</Box>
								</>
							);

							return (
								<Collapse key={event.id} in={event.show} timeout={{ enter: 500, exit: 1500 }} unmountOnExit>
									<StyledListItem>
										{event.state === CustomerStateItemInfoState.FinishWithError && event.maybeError ? (
											<Tooltip title={event.maybeError}>{listItemContent}</Tooltip>
										) : (
											listItemContent
										)}
									</StyledListItem>
								</Collapse>
							);
						})}
					</StyledList>
				)}
			</Paper>
		</Box>
	);
};

export default CustomersSyncProcessStatus;
