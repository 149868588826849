import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { OrderTypeEnum } from '../../../common/types';
import thankYouIcon from '../../../../assets/thankyou-icon.png';
export interface ThankYouTransactionProps {
	orderType: OrderTypeEnum;
}

export const ThankYouTransaction = ({ orderType }: ThankYouTransactionProps): JSX.Element => {
	return (
		<Container sx={{ marginY: 2 }} component="main" maxWidth="md">
			<Grid container spacing={2}>
				<Grid item textAlign="center" xs={12}>
					<img src={thankYouIcon} width="25%" height="auto" alt="thank-you-icon" />
				</Grid>
				<Grid item xs={12} marginLeft={{ xs: 2, sm: 10 }}>
					<Typography>¡Ya casi estamos listos!</Typography>
				</Grid>
				<Grid item xs={12} marginLeft={{ xs: 2, sm: 10 }}>
					<Typography>
						{orderType === OrderTypeEnum.SIMPLE
							? 'Debido a nuestras políticas de procesamiento y prevención de fraude, esta transacción será revisada de forma manual.'
							: 'El pago fue procesado.'}
					</Typography>
				</Grid>
				<Grid item xs={12} marginLeft={{ xs: 2, sm: 10 }}>
					<Typography>Nos pondremos en contacto contigo en caso de que surja algún inconveniente.</Typography>
				</Grid>
				<Grid item xs={12} marginLeft={{ xs: 2, sm: 10 }}>
					<Typography>Ante cualquier consulta, no dudes en contactarte con nosotros.</Typography>
				</Grid>
			</Grid>
		</Container>
	);
};
