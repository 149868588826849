import { ApiSaleOrderService } from 'services';
import { AxiosResponse } from 'axios';
import { IProviderBulkInsertInNetsuiteResultDTO, IProviderDTO, IProviderListDTO } from './types';

const endpoints = {
	list: 'providers',
};

export const getProviderList = (
	page = 1,
	size = 15,
	filters?: Record<string, string | number | boolean>,
	search = '',
): Promise<
	AxiosResponse<{
		page: number;
		total: number;
		size: number;
		elements: IProviderListDTO[];
	}>
> =>
	ApiSaleOrderService.get(endpoints.list, {
		params: {
			page,
			size,
			search,
			...(filters && filters),
			sort: 'fantasyName',
		},
	});

export const getProviderById = (id: string): Promise<AxiosResponse<IProviderDTO>> =>
	ApiSaleOrderService.get(`${endpoints.list}/${id}`);

export const putProvider = (body: Partial<IProviderDTO>): Promise<AxiosResponse<IProviderDTO>> =>
	ApiSaleOrderService.put(`${endpoints.list}/${body.id}`, body);

export const syncProviderWithNetsuite = (id: string): Promise<AxiosResponse<void>> =>
	ApiSaleOrderService.put(`${endpoints.list}/${id}/sync-with-netsuite`);

export const insertAllProviderInNetsuite = (): Promise<AxiosResponse<IProviderBulkInsertInNetsuiteResultDTO>> =>
	ApiSaleOrderService.post(`${endpoints.list}/insert-all-providers-in-netsuite`);
