import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Snackbar,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { IOperators } from 'features/transactions/types';
import { useState } from 'react';
import { IEticketUnbindInfoAllowed, IUnbindEticket } from 'features/authorization/types';
import { unbindCrossEticket } from 'features/authorization/services';
import { getCrossOperatorName } from 'features/authorization/helpers';

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export interface CreateUnbindModalProps {
	authorizationId: number;
	eTickets: IEticketUnbindInfoAllowed[];
	operators: IOperators[];
	open: boolean;
	handleClose: () => void;
	reloadList: () => void;
}

export function CreateUnbindModal({
	authorizationId,
	eTickets,
	operators,
	open,
	handleClose,
	reloadList,
}: CreateUnbindModalProps): JSX.Element {
	const postRefundDefaultValues = {
		authorizationId: authorizationId,
		amount: 0,
		eticket: '',
	};
	const classes = useStyles();

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		reset,
	} = useForm({ mode: 'all', defaultValues: postRefundDefaultValues });
	const [loading, setLoading] = useState<boolean>(false);
	const [operatorName, setOperatorName] = useState<string>('');
	const [maxRefund, setMaxRefund] = useState<number>(0);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const onSubmit = async (data: IUnbindEticket) => {
		try {
			data['amount'] = maxRefund;
			setLoading(true);
			await unbindCrossEticket(data);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Desvinculación realizada satisfactoriamente',
			});
			reset();
			setOperatorName('');
			reloadList();
			handleClose();
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al realizar desvinculación'),
			});
			setLoading(false);
		}
	};

	const handleSelectedETicket = (event: SelectChangeEvent<string>, onChange: (...event: any[]) => void) => {
		setLoading(true);
		const maybeElement = eTickets.find((eTicket) => eTicket.eticket === event.target.value);
		setMaxRefund(maybeElement ? maybeElement.maxToRefund : 0);
		maybeElement && setOperatorName(getCrossOperatorName(operators, maybeElement.operator));
		onChange(event.target.value);
		setLoading(false);
	};

	const onClose = () => {
		reset();
		setOperatorName('');
		handleClose();
	};

	return (
		<div>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="h5" color="primary">
						Desvincular Boleto
					</Typography>
					<IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container gap={2}>
							<Grid item xs={12}>
								<Controller
									name="eticket"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Por favor seleccione el E-Ticket',
										},
									}}
									render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
										<FormControl color={invalid ? 'error' : undefined} sx={{ width: '100%', height: '100%' }}>
											<InputLabel id="e-ticket-select-label">E-Ticket</InputLabel>
											<Select
												labelId="e-ticket-select-label"
												id="e-ticket-select"
												label="E-Ticket"
												value={value}
												disabled={!eTickets.length}
												onChange={(event) => handleSelectedETicket(event, onChange)}
												error={Boolean(errors.eticket)}
											>
												{eTickets.length &&
													eTickets.map((eTicket) => (
														<MenuItem key={eTicket.eticket} value={eTicket.eticket}>
															{eTicket.eticket}
														</MenuItem>
													))}
											</Select>
											{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
										</FormControl>
									)}
								/>
							</Grid>

							{operatorName !== '' && (
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										fullWidth
										value={operatorName}
										label="Operador"
										type="text"
										aria-readonly
									/>
								</Grid>
							)}
							<Grid item xs={12} display={'flex'} gap={'10px'} justifyContent="flex-end">
								<Button variant="contained" disabled={loading || !isValid} color="warning" fullWidth type="submit">
									Confirmar Desvinculación
								</Button>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</div>
	);
}
