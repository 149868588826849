import React, { useEffect, useRef } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Air, AirSimple, DestinationOnlyFlights, IFlightFinalPriceSummary, IPassengerConfig } from '../../types';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import Footer from '../Footer/Footer';
import ImportantInformation from '../ImportantInformation';
import { AirDisplay } from '../AirConfig/AirDisplay';
import { QuotationIntroPreview } from '../QuotationPreview/QuotationIntroPreview';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { FlightPricePreviewDesktop } from './FlightPricePreviewDesktop';
import { FlightPricePreviewMobile } from './FlightPricePreviewMobile';

// @ts-ignore
const useStyles = makeStyles((theme) => ({
	root: {
		lexGrow: 1,
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	previewTitle: {
		marginBottom: theme.spacing(3),
	},
	itineraryMobileTitle: {
		fontStyle: 'normal',
		fontWeight: 'bold',
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(3),
	},
	presupuestoTitle: {
		fontStyle: 'normal',
		fontWeight: 'bold',
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.primary.main,
	},
}));

interface Props {
	destinations: DestinationOnlyFlights[] | null;
	passengerConfig: IPassengerConfig | null;
	notIncludes: string[];
	description: string;
	countryCode: string;
	airs: Air[] | AirSimple[];
	flightFinalPriceSummaries: IFlightFinalPriceSummary[];
	flightIndexSelected: number | null;
	showFlightSelectionControl?: boolean;
	handleFlightSelectionChange?: (index: number | null) => void;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

export default function QuotationPreviewOnlyFlights({
	destinations,
	passengerConfig,
	flightFinalPriceSummaries,
	notIncludes,
	description,
	countryCode,
	airs,
	flightIndexSelected,
	showFlightSelectionControl,
	handleFlightSelectionChange,
	exchangeRate,
	localCurrency,
	showLocalCurrency,
}: Props): JSX.Element {
	const classes = useStyles();
	const destinationToUse: DestinationOnlyFlights[] = destinations || [];
	const isMediumDown = useBreakpoints().mediumDown;
	const flightsRef = useRef([]);

	const handleFlightRef = (element: HTMLDivElement | null, index: number) => {
		// @ts-ignore
		flightsRef.current[index] = element;
	};
	useEffect(() => {
		if (airs.length > 1 && flightIndexSelected != null && flightsRef.current[flightIndexSelected] != null) {
			// @ts-ignore
			flightsRef.current[flightIndexSelected].scrollIntoView({ behavior: 'smooth' });
		}
	}, [flightIndexSelected]);

	useEffect(() => {
		// para cuando sea mas de un vuelo se seleccione la opción más económica
		if (airs.length > 1 && handleFlightSelectionChange != null && flightFinalPriceSummaries.length == airs.length) {
			const newFlightFinalPriceSummariesSort = [...flightFinalPriceSummaries];
			const priceSorted = newFlightFinalPriceSummariesSort.sort(function (a, b) {
				return a.totalPrice - b.totalPrice;
			});
			const indexMinPrice = flightFinalPriceSummaries.findIndex((x) => x.totalPrice == priceSorted[0].totalPrice);
			handleFlightSelectionChange(indexMinPrice > -1 ? indexMinPrice : 0);
		}
	}, []);

	return (
		<>
			<QuotationIntroPreview
				hasHotels={false}
				description={description}
				destinations={destinationToUse}
				manualServices={[]}
				notIncludes={notIncludes}
				hasAir={airs.length > 0}
			/>

			{airs.length == 1 && (
				<Container component="main" maxWidth="md">
					<div className={classes.paper}>
						<Typography variant="h5" className={classes.previewTitle}>
							Este es el itinerario de vuelos sugerido:
						</Typography>
						<AirDisplay data={airs[0]} previewMode={true} index={0} />
						{passengerConfig && flightFinalPriceSummaries.length == 1 && !isMediumDown && (
							<FlightPricePreviewDesktop
								exchangeRate={exchangeRate}
								localCurrency={localCurrency}
								showLocalCurrency={showLocalCurrency}
								hideSwich={true}
								passengerConfig={passengerConfig}
								markup={null}
								flightFinalPriceSummary={flightFinalPriceSummaries[0]}
								showUtility={false}
							/>
						)}
						{passengerConfig && flightFinalPriceSummaries.length == 1 && isMediumDown && (
							<FlightPricePreviewMobile
								exchangeRate={exchangeRate}
								localCurrency={localCurrency}
								showLocalCurrency={showLocalCurrency}
								passengerConfig={passengerConfig}
								markup={null}
								flightFinalPriceSummary={flightFinalPriceSummaries[0]}
								showUtility={false}
							/>
						)}
					</div>
				</Container>
			)}
			{airs.length > 1 && (
				<Container component="main" maxWidth="md">
					<div className={classes.paper}>
						<Typography variant="h5" className={classes.previewTitle}>
							Selecciona la opción de vuelos que desees:
						</Typography>
						<Container disableGutters sx={{ overflow: 'auto', paddingBottom: 1 }}>
							<Grid
								spacing={0}
								wrap="nowrap"
								p={0}
								m={0}
								container
								minWidth={airs.length * 400}
								sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}
							>
								{/*// @ts-ignore*/}
								{airs.map((air: Air | AirSimple, index: number) => {
									return (
										<Grid
											ref={(element) => handleFlightRef(element, index)}
											sx={{ display: 'flex', flexDirection: 'column' }}
											key={index}
											pr={1}
											item
										>
											<AirDisplay
												data={air}
												previewMode={true}
												index={index}
												handleSelectionChange={handleFlightSelectionChange}
												showSelectionControl={showFlightSelectionControl}
												indexSelected={flightIndexSelected}
											/>
											{passengerConfig && flightFinalPriceSummaries.length == airs.length && !isMediumDown && (
												<FlightPricePreviewDesktop
													exchangeRate={exchangeRate}
													localCurrency={localCurrency}
													showLocalCurrency={showLocalCurrency}
													passengerConfig={passengerConfig}
													markup={null}
													flightFinalPriceSummary={flightFinalPriceSummaries[index]}
													showUtility={false}
												/>
											)}
											{passengerConfig && flightFinalPriceSummaries.length == airs.length && isMediumDown && (
												<FlightPricePreviewMobile
													exchangeRate={exchangeRate}
													localCurrency={localCurrency}
													showLocalCurrency={showLocalCurrency}
													passengerConfig={passengerConfig}
													markup={null}
													flightFinalPriceSummary={flightFinalPriceSummaries[index]}
													showUtility={false}
												/>
											)}
										</Grid>
									);
								})}
							</Grid>
						</Container>
					</div>
				</Container>
			)}
			<PaymentMethods country={countryCode} />
			<Footer country={countryCode} />
			<ImportantInformation country={countryCode} />
		</>
	);
}
