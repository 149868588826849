import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Globo from 'assets/globe-purple-marker.png';
import Typography from '@mui/material/Typography';
import { IOpinion, ISummaryOpinion } from '../../types';

const StyledPaper = styled(Paper)(() => ({}));

interface Props {
	opinions: IOpinion[];
	summaryOpinion: ISummaryOpinion;
}

export const OpinionsPanel = ({ opinions, summaryOpinion }: Props): JSX.Element => {
	return (
		<Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
			<Grid container spacing={2} marginTop={5}>
				<img style={{ height: 30, width: 30 }} src={Globo} alt="" />
				<Typography sx={{ ml: 1, mt: 1 }}>Valoracion de Viajeros</Typography>
			</Grid>
			<Grid container spacing={2} marginTop={3}>
				<Typography
					fontSize={40}
					fontWeight="bold"
					marginLeft={5}
					sx={{ backgroundColor: '#FF6700', p: 1, color: 'white', pb: 0 }}
					component="div"
				>
					{summaryOpinion.point}
				</Typography>
				<Typography fontSize={40} color="#0288d1" fontWeight="bold" sx={{ ml: 1 }}>
					{summaryOpinion.note}
				</Typography>
				<Typography sx={{ ml: 1, mt: 3 }}>Valoracion de Viajeros</Typography>
			</Grid>
			{opinions.map((item, index) => (
				// eslint-disable-next-line no-mixed-spaces-and-tabs
				<StyledPaper
					key={index}
					sx={{
						my: 1,
						mx: 'auto',
						p: 4,
						marginTop: 5,
						marginLeft: 5,
						marginRight: 5,
					}}
				>
					<Grid container wrap="nowrap" spacing={2}>
						<Grid container wrap="nowrap" spacing={2}>
							<Grid item>
								<Typography
									fontWeight="bold"
									sx={{ backgroundColor: '#FF6700', p: 1, color: 'white', pb: 0 }}
									marginLeft={5}
									component="div"
								>
									{item.icon}
								</Typography>
							</Grid>
							<Grid item>
								<Typography color="#0288d1" component="div">
									{item.info}{' '}
								</Typography>
							</Grid>
						</Grid>
						<Grid container wrap="nowrap" component="div" marginLeft={45} spacing={2}>
							<Grid item>
								<Typography color="secondary" fontWeight="bold" component="div">
									{item.user}
								</Typography>
							</Grid>
							<Grid item>
								<Typography color="Grey" component="div">
									{item.date}{' '}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs>
						<Typography color="Grey" component="div" marginTop={3}>
							{item.opinion}
						</Typography>
					</Grid>
				</StyledPaper>
			))}
		</Box>
	);
};
