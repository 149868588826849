import React from 'react';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { useTheme } from '@mui/styles';
import { ISaleOrderListItem } from '../../types';
import { formatDateTimeToShowUser } from '../../../common/helpers';

export const transformValue = (
	key: string,
	value: string | number | undefined,
	row: ISaleOrderListItem,
): string | number | undefined => {
	switch (key) {
		case 'created':
			return formatDateTimeToShowUser(row.created).replace(/\.\d+$/, '');
		case 'mainPassengerCompleteName':
			return row.mainPassengerCompleteName ? row.quotationId : '-';
		case 'quotationId':
			return row.quotationId ? row.quotationId : '-';
		case 'salesAmount':
			return row.saleAmount ? row.saleAmount : '-';
		case 'totalCost':
			return row.totalCost ? row.totalCost : '-';
		case 'chargedAmount':
			return row.chargedAmount ? row.chargedAmount : '-';
		case 'pendingPayAmount':
			return row.pendingPayAmount ? row.pendingPayAmount : '-';
		case 'grossProfit':
			return row.grossProfit !== undefined ? row.grossProfit : '-';
		case 'destinations':
			return row.destinations && row.destinations.length > 0 ? row.destinations.join(', ') : '-';
		case 'departureDate':
			return row.travelDepartureDate ? row.travelDepartureDate : '-';
		case 'returnDate':
			return row.travelReturnDate ? row.travelReturnDate : '-';
		default:
			return value;
	}
};

export const transformGrossProfit = (grossProfit?: number): JSX.Element | string => {
	return grossProfit !== undefined ? (
		<>
			<span style={getGrossProfitStyles(grossProfit).text}>{grossProfit}</span>
			<span style={getGrossProfitStyles(grossProfit).icon}>
				{grossProfit > 0 ? <TrendingUpIcon /> : grossProfit < 0 ? <TrendingDownIcon /> : <TrendingFlatIcon />}
			</span>
		</>
	) : (
		'-'
	);
};

const getGrossProfitStyles = (grossProfit: number) => {
	const theme = useTheme();
	if (grossProfit > 0) {
		return {
			icon: { fontSize: 20, color: theme.palette.success.main },
			text: { color: theme.palette.success.main },
		};
	} else if (grossProfit < 0) {
		return {
			icon: { fontSize: 20, color: theme.palette.error.dark },
			text: { color: theme.palette.error.dark },
		};
	} else {
		return {
			icon: { fontSize: 20, color: theme.palette.info.main },
			text: { color: theme.palette.info.main },
		};
	}
};
