import React from 'react';
import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IServiceInfoForPackageCompositeDetailPower } from '../../types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	headerTitle: {
		fontSize: '13px',
		fontWeight: 'bold',
		display: 'inline',
		color: theme.palette.primary.main,
	},
	contentDescription: {
		fontSize: '13px',
		display: 'inline',
		marginLeft: '10px',
		color: theme.palette.primary.main,
	},
	headerTable: {
		fontSize: '13px',
		fontWeight: 'bold',
		color: theme.palette.primary.main,
	},
	bodyTable: {
		fontSize: '13px',
		color: theme.palette.primary.main,
	},
}));

export interface PackageCompositeServiceDetailProps {
	service: IServiceInfoForPackageCompositeDetailPower;
}

export const PackageCompositeServiceDetail = ({ service }: PackageCompositeServiceDetailProps): JSX.Element => {
	const classes = useStyles();

	return (
		<Card variant="elevation" sx={{ padding: '10px', 'padding-top': '0px', 'margin-top': '10px' }}>
			<Grid container marginTop={1}>
				<Grid item xs={12}>
					<Typography className={classes.headerTitle}>Nombre:</Typography>
					<Typography className={classes.contentDescription}>{service.name}</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography className={classes.headerTitle}>Tipo de servicio:</Typography>
					<Typography className={classes.contentDescription}>{service.serviceType}</Typography>
				</Grid>

				{service?.observations && (
					<Grid item xs={12}>
						<Typography className={classes.headerTitle}>Observaciones:</Typography>
						<Typography className={classes.contentDescription}>{service.observations}</Typography>
					</Grid>
				)}

				<Grid container>
					<Grid item xs={1}>
						<Typography className={classes.headerTitle}>Costo:</Typography>
					</Grid>
					<Grid item xs={5} sx={{ 'margin-top': '10px' }}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className={classes.headerTable}>SGL</TableCell>
										<TableCell className={classes.headerTable}>DBL</TableCell>
										<TableCell className={classes.headerTable}>TPL</TableCell>
										<TableCell className={classes.headerTable}>CPL</TableCell>
										<TableCell className={classes.headerTable}>CHD(4a11)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell className={classes.bodyTable}>
											{service.priceSingle ? 'USD ' + service.priceSingle : '-'}
										</TableCell>
										<TableCell className={classes.bodyTable}>
											{service.priceDbl ? 'USD ' + service.priceDbl : '-'}
										</TableCell>
										<TableCell className={classes.bodyTable}>
											{service.priceTpl ? 'USD ' + service.priceTpl : '-'}
										</TableCell>
										<TableCell className={classes.bodyTable}>
											{service.priceCpl ? 'USD ' + service.priceCpl : '-'}
										</TableCell>
										<TableCell className={classes.bodyTable}>
											{service.priceChild ? 'USD ' + service.priceChild : '-'}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};
