import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

export interface HotelCategoryFilterProps {
	onChange: (value: number | null) => void;
}

export const HotelCategoryFilter = (props: HotelCategoryFilterProps): JSX.Element => {
	const [selected, setSelected] = useState<number | null>(0);

	useEffect(() => props.onChange(selected), [selected]);

	return (
		<Paper>
			<Grid container sx={{ display: 'grid', marginLeft: 2, marginTop: 4 }}>
				<Grid item xs={12}>
					<Typography variant="h6" fontWeight="bold">
						Categoría
					</Typography>
				</Grid>
				<Grid item xs={12} sx={{ display: 'flex', mt: 2 }}>
					<Typography display="flex">
						<Rating
							sx={{ fontSize: 30 }}
							name="read-only"
							value={selected}
							onChange={(_, value) => setSelected(value)}
						/>
					</Typography>
					<VpnKeyIcon sx={{ color: 'Grey', ml: 3, fontSize: 30 }} />
				</Grid>
			</Grid>
		</Paper>
	);
};
