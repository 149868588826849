import React from 'react';
import { Container, CssBaseline, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GeneralConfigForm from './GeneralConfigForm';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

interface Props {
	onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onPhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onCRMTicketChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onConfirmAutoCompleteDataByDeal: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	crmTicket: number;
	name: string;
	email: string | null;
	phone: string;
	descripcion: string;
	showDescriptionField: boolean;
}

export default function GeneralConfig(props: Props): JSX.Element {
	const classes = useStyles();
	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Configuración General
				</Typography>
				<GeneralConfigForm {...props} />
			</div>
		</Container>
	);
}
