import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom';

import { pageSizeOptions } from 'constants/constants';

import { extractErrorMessage } from 'features/quotation/helpers';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import { PageNoResult } from 'features/common/components/PageNoResults';
import { convertFiltersToRecord, createSortHandler, getSortableFields } from 'features/common/helpers';
import { FilterModel, ISort, ShowAlertState } from 'features/common/types';

import { checkExistDefaultIssueRule, deleteFlightIssueRule, getFlightIssueRuleList } from '../../services';
import { IFlightIssueRule } from '../../types';
import routes from '../../../../constants/routes';
import { flightIssueRuleFilters, flightIssueRuleHeaderCells, newDefaultRuleId, newRuleId } from '../../constants';
import { flightIssueRuleListStyles } from './styles';
import { transformValue } from '../../helpers';
import { ExpandedRowFlightIssueRuleListDetail } from '../ExpandedRowFlightIssueRuleListDetail';
import { ConfirmationModal } from '../../../common/components/ConfirmationModal/ConfirmationModal';

export const FlightIssueRuleList = (): JSX.Element => {
	const classes = flightIssueRuleListStyles();
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'priority', order: 'asc' }]);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const [flightIssueRules, setFlightIssueRules] = useState<IFlightIssueRule[] | null>([]);
	const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
	const colSpan = flightIssueRuleHeaderCells.length;
	const [searchValue, setSearchValue] = useState<string>('');
	const [idToDelete, setIdToDelete] = useState<string | null>(null);

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);
	const handleExpandRow = (index: number, event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		const updatedRows = new Set(expandedRows);
		if (expandedRows.has(index)) {
			updatedRows.delete(index);
		} else {
			updatedRows.add(index);
		}
		setExpandedRows(updatedRows);
	};
	const handleRowClick = (index: number) => (event: React.MouseEvent<HTMLElement>) => {
		handleExpandRow(index, event);
	};
	const sortableFields = getSortableFields(flightIssueRuleHeaderCells);

	const loadFlightIssueRules = useCallback(async () => {
		try {
			setLoading(true);
			const envelopedFlightIssueRuleList = (
				await getFlightIssueRuleList(
					currentPage,
					currentSize,
					sortFields,
					convertFiltersToRecord(filterApplied),
					searchValue,
				)
			).data;
			setPageTotal(Math.ceil(envelopedFlightIssueRuleList.total / envelopedFlightIssueRuleList.size));
			setTotalCount(envelopedFlightIssueRuleList.total);
			setFlightIssueRules(envelopedFlightIssueRuleList.elements);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de reglas de emisión de vuelo'),
			});
			setLoading(false);
		}
	}, [currentPage, currentSize, sortFields, filterApplied, searchValue]);

	const handleSearch = () => {
		setCurrentPage(1);
		loadFlightIssueRules();
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const handleCloseAlert = () => {
		setAlert(defaultAlertState);
	};

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		loadFlightIssueRules();
	}, [filterApplied, sortFields, currentPage, currentSize, loadFlightIssueRules]);

	const handleGoNewRule = async () => {
		try {
			setLoading(true);
			const existDefaultRule = (await checkExistDefaultIssueRule()).data.exist;
			if (existDefaultRule) {
				history.push(`${routes.flightIssueRule.list}/${newRuleId}`);
			} else {
				history.push(`${routes.flightIssueRule.list}/${newDefaultRuleId}`);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al verificar si existe la regla por defecto'),
			});
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async () => {
		if (idToDelete != null) {
			setIdToDelete(null);
			try {
				setLoading(true);
				await deleteFlightIssueRule(idToDelete);
				setAlert({
					show: true,
					severity: 'success',
					message: 'Regla eliminada correctamente',
				});
				loadFlightIssueRules();
			} catch (error: any) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al eliminar la regla de emisión de vuelo'),
				});
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container sx={{ justifyContent: 'center' }} gap={1}>
				<Grid container spacing={2} className={classes.container}>
					<Grid item xs={3}>
						<Button variant="contained" onClick={handleGoNewRule}>
							{' '}
							+ Nueva Regla{' '}
						</Button>
					</Grid>
					<Grid item xs={9} className={classes.containerButtons} gap={1}>
						<TextField
							label="Buscar"
							variant="outlined"
							size="small"
							value={searchValue}
							onChange={handleChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleSearch}>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<FilterGrid filters={flightIssueRuleFilters} handleAppliedFilters={handleApplyFilters} />
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ m: 5, justifyContent: 'flex-start', ml: 19 }}>
					mostrando {flightIssueRules?.length} resultados de {totalCount}
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{flightIssueRuleHeaderCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													className={classes.tableSortLabel}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
									<TableCell align="center"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{flightIssueRules?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									flightIssueRules?.map((flightIssueRule, rowIndex) => (
										<React.Fragment key={flightIssueRule.id}>
											<TableRow
												key={flightIssueRule.id}
												className={expandedRows.has(rowIndex) ? classes.expandedRow : ''}
												onClick={handleRowClick(rowIndex)}
											>
												<TableCell align="center" style={{ padding: '10px' }} component="p">
													{transformValue('priority', flightIssueRule.priority, flightIssueRule)}{' '}
												</TableCell>
												<TableCell align="center" component="p">
													{flightIssueRule.ruleComment}
												</TableCell>
												<TableCell align="center" style={{ padding: '10px' }} component="p">
													{transformValue('created', flightIssueRule.created, flightIssueRule)}{' '}
												</TableCell>
												<TableCell align="center" component="p">
													{flightIssueRule.createdBy}
												</TableCell>

												<TableCell align="center" style={{ padding: '10px' }} component="p">
													{transformValue('isDefaultRule', flightIssueRule.isDefaultRule, flightIssueRule)}{' '}
												</TableCell>

												<TableCell align="center">
													<button
														style={{ border: 0, background: 'transparent' }}
														onClick={(e) => handleExpandRow(rowIndex, e)}
													>
														{expandedRows.has(rowIndex) ? (
															<ExpandLessIcon className={classes.icon} />
														) : (
															<ExpandMoreIcon className={classes.icon} />
														)}
													</button>
												</TableCell>
											</TableRow>
											{expandedRows.has(rowIndex) && (
												<ExpandedRowFlightIssueRuleListDetail item={flightIssueRule} handleDelete={setIdToDelete} />
											)}
										</React.Fragment>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={6} marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<ConfirmationModal
				open={idToDelete != null}
				handleClose={() => setIdToDelete(null)}
				handleAccept={handleDelete}
				message="Confirmar eliminar regla de emisión de vuelo"
			/>
			<Snackbar open={alert.show} autoHideDuration={2000} onClose={handleCloseAlert}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
