import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getBoardName, GetPAXTotalsResponse } from '../../helpers';
import { HotelOptionsSimple } from '../../types';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { CountryCodeEnum } from 'features/common/types';

const useStyles = makeStyles((theme) => ({
	cardRoom: { marginTop: theme.spacing(1), paddingLeft: '20px', paddingRight: '20px' },
	roomTitle: {
		fontStyle: 'normal',
		fontWeight: 700,
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(1),
	},
	textInfo: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
	},
	regimenInfo: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
		color: 'rgba(0,0,0,0.57)',
	},
	priceTitle: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
		float: 'left',
	},
	signTitle: {
		fontSize: '10px',
		fontStyle: 'italic',
		fontWeight: 700,
		lineHeight: '5px',
		color: '#71BCED',
	},
	signValue: {
		fontSize: '9px',
		fontStyle: 'italic',
		fontWeight: 700,
		color: '#71BCED',
		float: 'right',
		lineHeight: '5px',
	},
	priceValue: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 700,
		color: theme.palette.secondary.main,
		float: 'right',
	},
	rowPriceContainer: {
		marginTop: theme.spacing(1),
	},
	rowFinalPriceContainer: {
		marginTop: theme.spacing(2),
	},
	localPrice: {
		display: 'flex',
		fontSize: '15px',
		width: '150px',
		justifyContent: 'start',
	},
}));
interface Props {
	hotelOption: HotelOptionsSimple;
	paxTotals: GetPAXTotalsResponse;
	showUtility: boolean;
	markup: number | null;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

export const HotelOptionsPricePreviewMobile = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { hotelOption, paxTotals } = props;
	const { auth } = useSelector((state: RootState) => state);
	return (
		<>
			{hotelOption.rooms.map((optionItem, optionItemIndex) => {
				const mealPlan = optionItem.board;
				const paxCountInRoom = paxTotals.totalPaxPerRoom[optionItemIndex];
				const totalAdults = paxCountInRoom.totalAdults;
				const CHDExists = paxCountInRoom.hasChild;
				const INFExists = paxCountInRoom.hasInfant;
				const priceLocal = Math.round(optionItem.adtPrice * props.exchangeRate);
				const priceLocalTotal = Math.round(optionItem.totalPrice * props.exchangeRate);
				return (
					<Card key={hotelOption.hotelId + '-room-' + optionItemIndex} className={classes.cardRoom}>
						<CardContent>
							<Typography component="h1" variant="subtitle1" className={classes.roomTitle}>
								Habitación {optionItemIndex + 1}
							</Typography>
							<Typography className={classes.textInfo} component={'p'} variant={'body2'}>
								{' '}
								{totalAdults} {totalAdults > 1 ? 'adultos' : 'adulto'} {CHDExists && '+'}{' '}
								{CHDExists && paxCountInRoom.totalChild}{' '}
								{CHDExists ? (CHDExists && paxCountInRoom.totalChild > 1 ? 'niños' : 'niño') : ''} {INFExists && '+'}{' '}
								{INFExists && paxCountInRoom.totalInfant}{' '}
								{INFExists ? (INFExists && paxCountInRoom.totalInfant > 1 ? 'bebés' : 'bebé') : ''} por habitación{' '}
							</Typography>
							<Typography className={classes.textInfo} component={'span'} variant={'body2'}>
								Régimen:
							</Typography>{' '}
							<Typography className={classes.regimenInfo} component={'span'} variant={'body2'}>
								{getBoardName(mealPlan)}
							</Typography>
							<Grid container>
								<Grid item xs={6}>
									<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
										Precio por adulto
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
										USD {optionItem.adtPrice} <br />
										{props.showLocalCurrency && (
											<Typography className={classes.localPrice}>
												{props.localCurrency} {priceLocal}
											</Typography>
										)}
									</Typography>
								</Grid>
							</Grid>
							{optionItem.description && optionItem.description !== '' && (
								<Typography align="right">{optionItem.description}</Typography>
							)}
							{optionItem.sign && (
								<Grid container>
									<Grid item xs={6}>
										<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
											{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography className={classes.signValue} component={'p'} variant={'body2'}>
											USD {Math.round(optionItem.sign?.adtSign)}
											{props.showLocalCurrency && (
												<Typography className={classes.localPrice}>
													{props.localCurrency} {Math.round(optionItem.sign?.adtSign * props.exchangeRate)}
												</Typography>
											)}
										</Typography>
									</Grid>
								</Grid>
							)}
							{CHDExists && (
								<div className={classes.rowPriceContainer}>
									<Grid container>
										<Grid item xs={6}>
											<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
												Precio por niño
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
												{CHDExists && 'USD'} {CHDExists && optionItem.chdPrice}
											</Typography>
										</Grid>
									</Grid>

									{optionItem.sign && (
										<Grid container>
											<Grid item xs={6}>
												<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
													{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography className={classes.signValue} component={'p'} variant={'body2'}>
													USD {Math.round(optionItem.sign?.chdSign)}
													{props.showLocalCurrency && (
														<Typography className={classes.localPrice}>
															{props.localCurrency} {Math.round(optionItem.sign?.chdSign * props.exchangeRate)}
														</Typography>
													)}
												</Typography>
											</Grid>
										</Grid>
									)}
								</div>
							)}
							{INFExists && (
								<div className={classes.rowPriceContainer}>
									<Grid container>
										<Grid item xs={6}>
											<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
												Precio por bebé
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
												{INFExists && 'USD'} {INFExists && optionItem.infPrice}
											</Typography>
										</Grid>
									</Grid>
									{optionItem.sign && (
										<Grid container>
											<Grid item xs={6}>
												<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
													{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography className={classes.signValue} component={'p'} variant={'body2'}>
													USD {Math.round(optionItem.sign?.infSign)}
													{props.showLocalCurrency && (
														<Typography className={classes.localPrice}>
															{props.localCurrency} {Math.round(optionItem.sign?.infSign * props.exchangeRate)}
														</Typography>
													)}
												</Typography>
											</Grid>
										</Grid>
									)}{' '}
								</div>
							)}
							<div className={classes.rowFinalPriceContainer}>
								<Grid container>
									<Grid item xs={6}>
										<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
											Precio total por paquete
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
											USD {optionItem.totalPrice}
											<br />
											{props.showLocalCurrency && (
												<Typography className={classes.localPrice}>
													{props.localCurrency} {priceLocalTotal}
												</Typography>
											)}
										</Typography>
									</Grid>
								</Grid>

								{optionItem.sign && (
									<Grid container>
										<Grid item xs={6}>
											<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
												{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography className={classes.signValue} component={'p'} variant={'body2'}>
												USD {Math.round(optionItem.sign?.totalSign)}
												{props.showLocalCurrency && (
													<Typography className={classes.localPrice}>
														{props.localCurrency} {Math.round(optionItem.sign?.totalSign * props.exchangeRate)}
													</Typography>
												)}
											</Typography>
										</Grid>
									</Grid>
								)}
							</div>
						</CardContent>
					</Card>
				);
			})}
		</>
	);
};
