import React, { useEffect, useState } from 'react';
import invokeLibrary from 'features/common/utils/invokeLibrary';
import { urlSiemprePago } from '../../constants';
import { isMobile } from '../../../common/helpers';
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export interface ISiemprePagoInitData {
	coinCode: string;
	amount: number;
	email: string;
	handleCaptureToken: (token: ISiemprePagoToken) => void;
	handleClose: () => void;
}

export interface ISiemprePagoToken {
	token: string;
	targetType: string;
}

interface ITokenFromSiemprePagoLibrary {
	TokenId: string;
	Brand: string;
}

interface ThisWindow extends Window {
	PWCheckout: any;
}

declare const window: ThisWindow;

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

export const SiemprePagoForm = ({
	coinCode,
	amount,
	email,
	handleCaptureToken,
	handleClose,
}: ISiemprePagoInitData): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);
	const classes = useStyles();

	const initLibrary = () => {
		const checkout = window.PWCheckout;

		checkout.SetProperties({
			name: 'Mevuelo',
			button_label: 'Pagar #monto#',
			email: email,
			image: window.location.origin + '/logo.png',
			currency: coinCode,
			amount: amount,
			form_id: 'formId',
			autoSubmit: false,
			checkout_card: 1,
			close_onclick: false,
		});

		checkout.OpenIframeNormal();

		checkout.Bind('opened', () => {
			const spModal = document.getElementById('custom_modal');

			if (spModal) {
				if (!isMobile.any()) {
					spModal.style.top = '99px';
				}
				spModal.style.overflow = 'hidden';
			}
		});

		checkout.Bind('tokenCreated', (token: ITokenFromSiemprePagoLibrary) => {
			handleCaptureToken({
				token: token.TokenId,
				targetType: token.Brand,
			});
		});

		checkout.Bind('closed', ({ Reason }: any) => {
			if (Reason !== 'TOKEN_RECEIVED') handleClose();
		});
	};

	const loadLibrary = async () => {
		try {
			setLoading(true);
			await invokeLibrary(urlSiemprePago);
			initLibrary();
			setLoading(false);
		} catch (error) {
			setError('No se pudo cargar correctamente la configuración de la pasarela de pago.');
			setLoading(false);
		}
	};

	useEffect(() => {
		loadLibrary();
	}, []);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<form id="formId" style={{ display: 'none' }}>
				<input type="hidden" name="PWToken" id="PWToken" />
			</form>
			<Snackbar open={error != null} autoHideDuration={2000} onClose={() => setError(null)}>
				<Alert variant="filled" severity={'error'}>
					{error}
				</Alert>
			</Snackbar>
		</>
	);
};
