import { IValidatingCarrier } from '../../transactions/types';
import { ShowAlertState } from '../../common/types';
import { useCallback, useState } from 'react';
import { getValidatingCarriers } from '../../transactions/services';

interface UseValidatingCarriersParams {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setAlert: React.Dispatch<React.SetStateAction<ShowAlertState>>;
}

interface UseValidatingCarriersReturn {
	validatingCarriers: IValidatingCarrier[];
	loadValidatingCarriers: () => void;
}

const useValidatingCarriers = ({ setLoading, setAlert }: UseValidatingCarriersParams): UseValidatingCarriersReturn => {
	const [validatingCarriers, setValidatingCarriers] = useState<IValidatingCarrier[]>([]);

	const loadValidatingCarriers = useCallback(async () => {
		try {
			setLoading(true);
			const response = await getValidatingCarriers();
			setValidatingCarriers(response.data);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: 'Ocurrió un error al cargar los validating carriers',
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, [setLoading, setAlert]);

	return { validatingCarriers, loadValidatingCarriers };
};

export default useValidatingCarriers;
