import React from 'react';
import { Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { QuotationFlightImage } from '../../types';
import useBreakpoints from 'features/common/hooks/useBreakpoints';

interface FlightImagePreviewListProps {
	flightImages: QuotationFlightImage[];
}

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		padding: theme.spacing(1),
	},
	image: {
		width: '100%',
		height: 'auto',
	},
}));

const FlightImagePreviewList: React.FC<FlightImagePreviewListProps> = ({
	flightImages,
}: FlightImagePreviewListProps) => {
	const classes = useStyles();
	const { smallDown } = useBreakpoints();

	const styles = {
		minWidth: smallDown ? '100vw' : '640px',
	};
	return (
		<Box display="flex" flexDirection="column" gap={1}>
			{flightImages.map((image, index) => (
				<Box key={index}>
					<Paper elevation={3} className={classes.imageContainer} sx={styles}>
						<img src={image.image} className={classes.image} />
					</Paper>
				</Box>
			))}
		</Box>
	);
};

export default FlightImagePreviewList;
