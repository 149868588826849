import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { formatDateTimeToShowUser } from '../../helpers';
import { INetsuiteSyncStatusDTO, NetsuiteSyncStatusEnum, netsuiteSyncStatusEnumWithName } from '../../types';

interface NetsuiteSyncStatusProps {
	syncStatus: INetsuiteSyncStatusDTO;
}

// Mapeo de estados a iconos y colores
const statusIconMap: Record<NetsuiteSyncStatusEnum, { icon: JSX.Element; color: string; label: string }> = {
	[NetsuiteSyncStatusEnum.Synchronized]: {
		icon: <CheckCircleIcon />,
		color: 'green',
		label: netsuiteSyncStatusEnumWithName[NetsuiteSyncStatusEnum.Synchronized].name,
	},
	[NetsuiteSyncStatusEnum.SynchronizationStart]: {
		icon: <SyncAltIcon />,
		color: 'blue',
		label: netsuiteSyncStatusEnumWithName[NetsuiteSyncStatusEnum.SynchronizationStart].name,
	},
	[NetsuiteSyncStatusEnum.Error]: {
		icon: <ErrorIcon />,
		color: 'red',
		label: netsuiteSyncStatusEnumWithName[NetsuiteSyncStatusEnum.Error].name,
	},
	[NetsuiteSyncStatusEnum.PendingSynchronization]: {
		icon: <HourglassEmptyIcon />,
		color: 'orange',
		label: netsuiteSyncStatusEnumWithName[NetsuiteSyncStatusEnum.PendingSynchronization].name,
	},
};

const NetsuiteSyncStatus: React.FC<NetsuiteSyncStatusProps> = (props: NetsuiteSyncStatusProps) => {
	const { status, lastError, lastSyncStartAt, lastSuccessSyncAt, lastActionExecutedBy } = props.syncStatus;
	const { icon, color, label } = statusIconMap[status];

	const getTooltipContent = (): string => {
		let content = `Estado: ${label}`;

		switch (status) {
			case NetsuiteSyncStatusEnum.Error:
				if (lastError) {
					content += ` - Error: ${lastError}`;
				}
				break;
			case NetsuiteSyncStatusEnum.Synchronized:
				if (lastSuccessSyncAt) {
					content += ` - Última sincronización exitosa: ${formatDateTimeToShowUser(lastSuccessSyncAt)}`;
				}
				break;
			case NetsuiteSyncStatusEnum.SynchronizationStart:
				if (lastSyncStartAt) {
					content += ` - Sincronización iniciada en: ${formatDateTimeToShowUser(lastSyncStartAt)}`;
				}
				break;
			case NetsuiteSyncStatusEnum.PendingSynchronization:
				// No se agrega nada adicional para este estado
				return content;
			default:
				return 'Estado: Desconocido';
		}

		if (lastActionExecutedBy) {
			content += ` - Ejecutado por: ${lastActionExecutedBy}`;
		}

		return content;
	};

	return (
		<Tooltip title={getTooltipContent()} arrow>
			<IconButton style={{ color }}>{icon}</IconButton>
		</Tooltip>
	);
};

export default NetsuiteSyncStatus;
