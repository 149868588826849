import React, { useEffect, useState } from 'react';
import { Alert, AlertColor, Backdrop, CircularProgress, Snackbar, TextField } from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { packageCompositeSuggestion } from '../../services';
import { INomenclator } from '../../types';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
	select: {
		width: '-webkit-fill-available',
	},
}));

export interface PackageListProps {
	handleSelectPackage: (packageSelected: INomenclator | null) => void;
}

export const PackagesSearch = (props: PackageListProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [searchPackage, setSearchPackage] = useState<string>('');
	const [searchOptions, setSearchOptions] = useState<INomenclator[]>([]);

	useEffect(() => {
		const fetchApi = async () => {
			try {
				setLoading(true);
				const data = await packageCompositeSuggestion(searchPackage);
				data && setSearchOptions(data.data);
				setLoading(false);
			} catch (error: any) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al pedir los Paquetes'),
				});
				setLoading(false);
			}
		};
		if (searchPackage && searchPackage.length >= 3) fetchApi();
	}, [searchPackage]);

	const handleChangePackageAutocomplete = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchPackage(e.target.value);
	};

	const handleAutocompleteChange = (event: React.ChangeEvent<any>, value: INomenclator) => {
		if (value) props.handleSelectPackage(value);
		else {
			props.handleSelectPackage(null);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Autocomplete
				options={searchOptions}
				getOptionLabel={(option: INomenclator) => option.name}
				// @ts-ignore
				onChange={handleAutocompleteChange}
				renderInput={(params) => (
					<TextField
						{...params}
						name="package"
						variant="standard"
						required
						label="Nombre del paquete"
						size="small"
						onChange={handleChangePackageAutocomplete}
						fullWidth
						style={{ marginTop: -3 }}
					/>
				)}
			/>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
