import React from 'react';
import { Layout } from 'features/common/components/Layout';
import Component from 'features/user/components/UserNew';

function UserNew(): JSX.Element {
	return (
		<Layout>
			<Component />
		</Layout>
	);
}

export default UserNew;
