import * as React from 'react';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import LocalAirportSharpIcon from '@mui/icons-material/LocalAirportSharp';
import { Grid } from '@mui/material';
import { FlightItineraryDetails } from '../FlightItineraryDetails/FlightItineraryDetails';
import { IFlightMPC } from '../../types';
import { isoDateToString, scalesToString, timeStringWithoutSeconds } from '../../helpers';
import { excludeAirlineBaggage } from '../../constants';
import { AirLineLogo } from '../AirlineLogo';
import warningImage from '../../../../assets/warning.png';
const useStyles = makeStyles((theme) => ({
	rotatedIcon90Deg: {
		transform: 'rotate(90deg)',
		display: 'inline',
		fontWeight: 'bold',
	},
	rotatedIcon270Deg: {
		transform: 'rotate(270deg)',
		display: 'inline',
		fontWeight: 'bold',
	},
	textHeaderInfo: {
		color: 'white',
		fontSize: '14px',
		fontWeight: 'normal',
	},
	timeInfo: {
		fontSize: '14px',
		color: '#7B7B7B',
	},
	logo: {
		height: '1rem',
	},
	timeLineHolder: {
		position: 'relative',
		width: '100%',
		height: '14px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		marginTop: '5px',
	},
	timeLineDirecto: {
		height: '6px',
		width: '100%',
		position: 'absolute',
		top: '4px',
		backgroundColor: 'rgb(39, 118, 184)',
		color: 'white',
	},
	scaleDot: {
		backgroundColor: 'rgb(39, 118, 184)',
		width: '14px',
		height: '14px',
		borderRadius: '50%',
		zIndex: 1,
	},
	scaleLabel: {
		height: '17px',
		borderRadius: '10px',
		fontWeight: 500,
		fontSize: '14px',
		textAlign: 'center',
		color: 'rgb(23, 31, 75)',
		textTransform: 'uppercase',
	},
	scaleContainer: {
		marginRight: '30px',
		height: '38px',
		width: '91px',
		display: 'inline',
	},
	baggageIconHolder: {
		display: 'flex',
		alignItems: 'baseline',
	},
	baggageActive: {
		color: theme.palette.secondary.main,
	},
	baggageInactive: {
		color: theme.palette.grey[500],
	},
	chevronIcon: {
		marginRight: '10px',
		cursor: 'pointer',
		transition: '0.6s',
	},
	chevronIconActive: {
		transform: 'rotate(180deg)',
	},
}));

interface Props {
	item: IFlightMPC;
	itemName: string;
	isReturning: boolean;
}

export const FlightItineraryItem = ({ item, itemName, isReturning }: Props): JSX.Element => {
	const theme = useTheme();
	const classes = useStyles();
	const hasWarning = item.scales.filter((scaleItem) => scaleItem.changeInfo.changeOfAirport).length > 0;
	const [open, setOpen] = useState<boolean>(false);

	const handleClick = () => setOpen(!open);

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={4}>
					<div
						style={{
							backgroundColor: '#8167A3',
							padding: '5px',
							minWidth: '200px',
							height: '52px',
						}}
					>
						<LocalAirportSharpIcon
							className={`${isReturning ? classes.rotatedIcon270Deg : classes.rotatedIcon90Deg} ${
								classes.textHeaderInfo
							}`}
						/>{' '}
						<Typography
							variant="subtitle1"
							component="span"
							fontSize={14}
							fontStyle={'normal'}
							fontWeight={'normal'}
							color={'#E9D6A6'}
						>
							{itemName}
						</Typography>
						<Typography variant="subtitle1" component="p" className={`${classes.textHeaderInfo}`}>
							{' '}
							{isoDateToString(item.departureData.flightDate)}
						</Typography>
					</div>
				</Grid>
				<Grid item xs={4}>
					<div
						style={{
							backgroundColor: '#8167A3',
							padding: '5px',
							minWidth: '200px',
							height: '52px',
						}}
					>
						<Typography
							variant="subtitle1"
							component="span"
							fontSize={14}
							fontStyle={'normal'}
							fontWeight={'normal'}
							color={'#E9D6A6'}
						>
							{item.departureData.airport.code}
						</Typography>
						<Typography variant="subtitle1" component="p" className={`${classes.textHeaderInfo}`}>
							{' '}
							{item.departureData.airport.city.name}
						</Typography>
					</div>
				</Grid>
				<Grid item xs={4}>
					<div
						style={{
							backgroundColor: '#8167A3',
							padding: '5px',
							minWidth: '200px',
							height: '52px',
						}}
					>
						<Typography
							variant="subtitle1"
							component="span"
							fontSize={14}
							fontStyle={'normal'}
							fontWeight={'normal'}
							color={'#E9D6A6'}
						>
							{item.arrivalData.airport.code}
						</Typography>
						<Typography variant="subtitle1" component="p" className={`${classes.textHeaderInfo}`}>
							{' '}
							{item.arrivalData.airport.city.name}
						</Typography>
					</div>
				</Grid>

				<Grid item xs={4}>
					<div style={{ backgroundColor: 'white', padding: '5px' }}>
						{item.airLines.map((airlineItem, indexAirlineItem) => (
							<AirLineLogo key={indexAirlineItem} code={airlineItem.code} name={airlineItem.name} showName={true} />
						))}
					</div>
				</Grid>
				<Grid item xs={4}>
					<div
						style={{
							backgroundColor: 'white',
							padding: '5px',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							paddingLeft: '9px',
							flex: '1 1 0%',
						}}
					>
						<Typography variant="subtitle1" component="span" className={`${classes.timeInfo}`}>
							{' '}
							{timeStringWithoutSeconds(item.departureData.flightTime)}
						</Typography>
						<div className={classes.scaleContainer}>
							<div className={classes.scaleLabel}>
								{' '}
								{scalesToString(item.scales.length)}
								{hasWarning && <img src={warningImage} alt="Cambio de aeropuerto" />}
							</div>

							<div className={classes.timeLineHolder}>
								<div className={classes.timeLineDirecto}></div>
								{item.scales.map((i, index) => (
									<div key={index} className={classes.scaleDot}></div>
								))}
							</div>
						</div>
					</div>
				</Grid>
				<Grid item xs={4}>
					<div
						style={{
							backgroundColor: 'white',
							padding: '5px',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							paddingLeft: '9px',
							flex: '1 1 0%',
						}}
					>
						<Typography variant="subtitle1" component="span" className={`${classes.timeInfo}`}>
							{' '}
							{timeStringWithoutSeconds(item.arrivalData.flightTime)}
						</Typography>
						<Typography variant="subtitle1" component="span" className={`${classes.timeInfo}`}>
							{' '}
							{item.duration.totalHours > 1 ? `${item.duration.totalHours}h` : `${item.duration.totalHours}hs`}
							{item.duration.totalMinutes > 0 ? `${item.duration.totalMinutes}m` : null}
						</Typography>
						<div className={classes.baggageIconHolder}>
							<Typography
								className={`icon icon-mv-briefcase ${
									item.baggage.total == 0 ? classes.baggageInactive : classes.baggageActive
								}`}
								component="span"
								fontSize={14}
								fontStyle={'normal'}
								fontWeight={'normal'}
							></Typography>
							<Typography
								className={`icon icon-mv-hand-bag ${
									item.baggage.total == 0 && excludeAirlineBaggage.includes(item.airLines[0].code)
										? classes.baggageInactive
										: classes.baggageActive
								}`}
								component="span"
								fontSize={14}
								fontStyle={'normal'}
								fontWeight={'normal'}
								color={theme.palette.secondary.main}
							></Typography>
						</div>
						<div
							className={`${classes.chevronIcon} ${open ? classes.chevronIconActive : ''}`}
							onClick={() => handleClick()}
						>
							<Typography
								className="icon icon-mv-arrow-down"
								component="span"
								fontSize={14}
								fontStyle={'normal'}
								fontWeight={'normal'}
								color={theme.palette.secondary.main}
							></Typography>
						</div>
					</div>
				</Grid>
			</Grid>

			{open && (
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FlightItineraryDetails
							segments={item.segments}
							duration={item.duration}
							scales={item.scales}
							baggage={item.baggage}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};
