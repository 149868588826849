import React, { useEffect, useState } from 'react';

import { Box, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { IBaggageFilter, IFlightExplorerFilterBaggageInfo } from '../../types';
import Typography from '@mui/material/Typography';
import { BpCheckedIcon, BpIcon } from '../../constants';

interface Props {
	data: IFlightExplorerFilterBaggageInfo;
	handleChangeFilter: (data: IBaggageFilter | null) => void;
}

const useStyles = makeStyles(() => ({
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		color: 'white',
	},
	filterContent: {
		backgroundColor: 'rgb(241, 242, 241)',
		borderBottomRightRadius: '14px',
		borderBottomLeftRadius: '14px',
		padding: '0px',
		transition: 'max-height 0.26s ease 0s, padding 0.26s ease 0s',
		overflowY: 'auto',
		maxHeight: '450px',
		paddingLeft: '15px',
	},
	totalInfo: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 'auto',
		display: 'inline-flex',
		fontSize: '14px',
		fontWeight: '500',
		height: '15px',
		marginTop: '10px',
		textAlign: 'center',
		borderRadius: '14px',
		padding: '4px 8px',
		boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 3px 0px',
		backgroundColor: 'rgb(255, 103, 0)',
		color: 'rgb(255, 255, 255)',
	},
}));

export const FlightExplorerBaggageFilter = ({ data, handleChangeFilter }: Props): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState<string>('allOptions');

	useEffect(() => {
		switch (value) {
			case 'allOptions':
				handleChangeFilter(null);
				break;
			case 'withOnlyHandBaggage':
				handleChangeFilter({ withOnlyHandBaggage: true });
				break;
			case 'withHoldBaggage':
				handleChangeFilter({ withHoldBaggage: true });
				break;
		}
	}, [value]);

	return (
		<Container sx={{ 'margin-bottom': '15px' }}>
			<Box sx={{ 'padding-left': '15px', backgroundColor: '#8167A3', height: '25px' }}>
				<FormLabel className={classes.title}>Equipaje</FormLabel>
			</Box>
			<Box className={classes.filterContent}>
				<FormControl>
					<RadioGroup name="radio-buttons-group" value={value}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<FormControlLabel
								value={'allOptions'}
								control={
									<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} onClick={() => setValue('allOptions')} />
								}
								label={
									<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Todas las opciones </span>
								}
							/>
							<Typography variant="subtitle1" component="span" className={`${classes.totalInfo}`}>
								{' '}
								{data.allOptions}
							</Typography>
						</div>

						{data.withOnlyHandBaggage > 0 && (
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<FormControlLabel
									value={'withOnlyHandBaggage'}
									control={
										<Radio
											checkedIcon={<BpCheckedIcon />}
											icon={<BpIcon />}
											onClick={() => setValue('withOnlyHandBaggage')}
										/>
									}
									label={
										<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Solo equipaje de mano</span>
									}
								/>
								<Typography variant="subtitle1" component="span" className={`${classes.totalInfo}`}>
									{' '}
									{data.withOnlyHandBaggage}
								</Typography>
							</div>
						)}

						{data.withHoldBaggage > 0 && (
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<FormControlLabel
									value={'withHoldBaggage'}
									control={
										<Radio
											checkedIcon={<BpCheckedIcon />}
											icon={<BpIcon />}
											onClick={() => setValue('withHoldBaggage')}
										/>
									}
									label={
										<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Con equipaje en bodega</span>
									}
								/>
								<Typography variant="subtitle1" component="span" className={`${classes.totalInfo}`}>
									{' '}
									{data.withHoldBaggage}
								</Typography>
							</div>
						)}
					</RadioGroup>
				</FormControl>
			</Box>
		</Container>
	);
};
