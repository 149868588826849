import {
	Alert,
	AlertColor,
	Backdrop,
	Checkbox,
	CircularProgress,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Popover,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { pageSizeByDefault, pageSizeLargeOptions } from 'constants/constants';
import { getCrossOperatorName, getCrossStateName } from 'features/authorization/helpers';
import { CrossStateEnum, crossStateList } from 'features/authorization/types';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	convertFiltersToRecord,
	convertOperatorsDataToFilter,
	convertValidatingCarrierDataToFilter,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateTimeToShowUser,
	formatDateToShowUser,
	getEnvelopedListPageTotal,
	getSortableFields,
} from 'features/common/helpers';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	SelectFilterListData,
	SelectFilterModelSimple,
} from 'features/common/types';
import { discountCross, getCrossesList, getCrossesListToExport } from 'features/cross/services';
import { ICrossList } from 'features/cross/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import { getOperators, getValidatingCarriers } from 'features/transactions/services';
import { IOperators } from 'features/transactions/types';
import React, { useEffect, useState } from 'react';
import { ExportToExcelButton } from '../../../common/components/ExportToExcelButton';
import { ConfirmationModal } from '../../../common/components/ConfirmationModal/ConfirmationModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { putFilterInProfile } from '../../../common/services';
import { PageNoResult } from '../../../common/components/PageNoResults';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const headerCells: IHeadCell[] = [
	{
		field: 'transactionId',
		sortable: true,
		headerName: 'Id de Transacción',
	},
	{
		field: 'authorizationId',
		sortable: true,
		headerName: 'Id de Autorización',
	},
	{
		field: 'id',
		sortable: true,
		headerName: 'ID de Cruce',
	},
	{
		field: 'authorizationCode',
		sortable: true,
		headerName: 'Código de Autorización',
	},
	{
		field: 'isDiscounted',
		sortable: true,
		headerName: 'Descontada',
	},
	{
		field: 'cardLastFourDigit',
		sortable: true,
		headerName: 'Últimos 4 Dígitos Tarjeta',
	},
	{
		field: 'validatingCarrierName',
		sortable: true,
		headerName: 'Validating Carrier',
	},
	{
		field: 'amount',
		sortable: true,
		headerName: 'Monto',
	},
	{
		field: 'dateCross',
		sortable: true,
		headerName: 'Fecha de Cruce',
	},
	{
		field: 'eticket',
		sortable: true,
		headerName: 'E-Ticket',
	},
	{
		field: 'operator',
		sortable: true,
		headerName: 'Operador',
	},
	{
		field: 'state',
		sortable: true,
		headerName: 'Estado',
	},
	{
		field: 'createdAt',
		sortable: true,
		headerName: 'Fecha de Creación',
	},
];

export interface CrossesListProps {
	profileFilters?: FilterModel[];
	profileSorts?: ISort[];
}

export const CrossesList = ({ profileFilters, profileSorts }: CrossesListProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentSize, setCurrentSize] = useState<number>(pageSizeByDefault);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [crossList, setCrossList] = useState<ICrossList[] | null>(null);
	const [sortFields, setSortFields] = useState<ISort[]>(
		profileSorts
			? [...profileSorts]
			: [
					{ field: 'transactionId', order: 'desc' },
					{ field: 'authorizationId', order: 'desc' },
					{ field: 'createdAt', order: 'desc' },
			  ],
	);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>(profileFilters ? [...profileFilters] : []);
	const [validatingCarriers, setValidatingCarriers] = useState<SelectFilterListData[]>([]);
	const [operatorsToName, setOperatorsToName] = useState<IOperators[]>([]);
	const [operators, setOperators] = useState<SelectFilterListData[]>([]);
	const [authorizationIdAnchorEl, setAuthorizationIdAnchorEl] = React.useState<(EventTarget & HTMLSpanElement) | null>(
		null,
	);
	const [transactionIdAnchorEl, setTransactionIdAnchorEl] = React.useState<(EventTarget & HTMLSpanElement) | null>(
		null,
	);
	const [registerConfirmationModalCrossId, setRegisterConfirmationModalCrossId] = useState<number | null>(null);
	const { auth } = useSelector((state: RootState) => state);

	const statesOfCross = crossStateList as SelectFilterListData[];

	const userId = auth.user?.id;
	const apiKey = auth.apiKey;
	const countryCode = auth.country;
	const profileTag = 'crosses-transaction-list-tag';

	const filters = [
		{ label: 'ID de Transacción', type: FilterType.STRING, key: 'transactionId' },
		{ label: 'ID de Autorización', type: FilterType.NUMERIC, key: 'authorizationId' },
		{ label: 'ID de Cruce', type: FilterType.NUMERIC, key: 'id' },
		{
			label: 'Código de Autorización',
			type: FilterType.NUMERIC,
			key: 'authorizationCode',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Descontada',
			type: FilterType.BOOLEAN,
			key: 'isDiscounted',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Últimos 4 Dígitos de Tarjeta',
			type: FilterType.NUMERIC,
			key: 'cardLastFourDigit',
			operator: OperatorFilterEnum.EQUALS,
		},
		new SelectFilterModelSimple('Validating Carrier', 'validatingCarrierCode', validatingCarriers),
		{
			label: 'Monto',
			type: FilterType.NUMERIC,
			key: 'amount',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Monto',
			type: FilterType.NUMERIC,
			key: 'amountFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Monto',
			type: FilterType.NUMERIC,
			key: 'amountTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		{ label: 'Fecha de Cruce', type: FilterType.DATE, key: 'dateCross' },
		new DateToFromFilterModel('La Fecha de Cruce', 'dateCrossFrom', 'dateCrossTo'),
		{ label: 'E-Ticket', type: FilterType.STRING, key: 'eticket' },
		new SelectFilterModelSimple('Estado', 'state', statesOfCross),
		new SelectFilterModelSimple('Operador', 'operator', operators),
	];

	const getCrossListToClient = async () => {
		try {
			setLoading(true);
			await putFilterInProfile(profileTag, apiKey, countryCode, sortFields, filterApplied, userId?.toString());
			const envelopedCrossList = (
				await getCrossesList(currentPage - 1, currentSize, sortFields, convertFiltersToRecord(filterApplied))
			).data;
			setPageTotal(getEnvelopedListPageTotal(envelopedCrossList));
			setCrossList(envelopedCrossList.data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de cruces'),
			});
			setLoading(false);
		}
	};

	const getCarriersAndOperators = async () => {
		try {
			setLoading(true);
			setValidatingCarriers(convertValidatingCarrierDataToFilter((await getValidatingCarriers()).data));
			const operators = (await getOperators()).data;
			setOperatorsToName(operators);
			setOperators(convertOperatorsDataToFilter(operators));
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los operadores y carriers'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getCarriersAndOperators();
	}, []);

	useEffect(() => {
		getCrossListToClient();
	}, [filterApplied, sortFields, currentPage, currentSize]);

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const transformValue = (key: string, value: any, row: ICrossList) => {
		switch (key) {
			case 'operator':
				return getCrossOperatorName(operatorsToName, row.operator);
			case 'isDiscounted':
				return value ? 'Si' : 'No';
			case 'dateCross':
				return formatDateToShowUser(value);
			case 'state':
				return getCrossStateName(value);
			case 'createdAt':
				return formatDateTimeToShowUser(value);
			case 'amount':
				return `${row.amount} ${row.coinCode}`;
			default:
				return value;
		}
	};

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const envelopedCrossList = (await getCrossesListToExport(sortFields, convertFiltersToRecord(filterApplied))).data;
			const crossList = envelopedCrossList.data;
			const optionsToExport = {
				title: 'Listado de Cruces',
				headers: headerCells,
				widthColumns: [20, 20, 15, 25, 15, 25, 25, 20, 20, 20, 20, 20, 20],
				filename: 'Listado de Cruces',
			} as ExportExcelOptions;
			exportToExcel<ICrossList>(crossList, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de cruces'),
			});
			setLoading(false);
		}
	};

	const handleDiscountCross = async (crossId: number) => {
		try {
			setLoading(true);
			await discountCross(crossId);
			setRegisterConfirmationModalCrossId(null);
			getCrossListToClient();
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al poner como descontado el cruce'),
			});
			setLoading(false);
		}
	};

	const sortableFields = getSortableFields(headerCells);

	const colSpan = headerCells.length;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1}>
				<Grid container spacing={2}>
					<Grid item xs={11}>
						<FilterGrid filters={filters} handleAppliedFilters={handleApplyFilters} preAppliedFilters={filterApplied} />
					</Grid>
					<Grid item xs={1}>
						<ExportToExcelButton disabled={crossList?.length == 0} handleExportToExcel={handleExportToExcel} />
					</Grid>
				</Grid>

				<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: '600px' }}>
					<div>
						<Popover
							id={transactionIdAnchorEl !== null ? 'transactionid-popover' : undefined}
							open={transactionIdAnchorEl !== null}
							onClose={() => setTransactionIdAnchorEl(null)}
							anchorEl={transactionIdAnchorEl}
							disableRestoreFocus
							sx={{
								pointerEvents: 'none',
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Typography sx={{ p: 2 }}>Ir a los detalles de esta transacción</Typography>
						</Popover>
						<Popover
							id={authorizationIdAnchorEl !== null ? 'authorizationid-popover' : undefined}
							open={authorizationIdAnchorEl !== null}
							onClose={() => setAuthorizationIdAnchorEl(null)}
							anchorEl={authorizationIdAnchorEl}
							disableRestoreFocus
							sx={{
								pointerEvents: 'none',
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Typography sx={{ p: 2 }}>Ir a los detalles de esta autorización</Typography>
						</Popover>

						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="right"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="right">
												{cell.headerName}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && crossList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									crossList?.map((cross) => (
										<TableRow
											key={cross.id}
											hover
											sx={{
												'&:last-child td, &:last-child th': { border: 0 },
												'*': { color: cross.state === CrossStateEnum.UNBIND ? 'red' : '' },
											}}
										>
											<TableCell align="right" component="p">
												<Link
													onMouseEnter={(
														event:
															| React.MouseEvent<HTMLAnchorElement, MouseEvent>
															| React.MouseEvent<HTMLSpanElement, MouseEvent>,
													) => setTransactionIdAnchorEl(event.currentTarget)}
													onMouseLeave={() => setTransactionIdAnchorEl(null)}
													href={`/transaction/${cross.transactionId}`}
													underline="hover"
												>
													{cross.transactionId}
												</Link>
											</TableCell>
											<TableCell align="right" component="p">
												<Link
													onMouseEnter={(
														event:
															| React.MouseEvent<HTMLAnchorElement, MouseEvent>
															| React.MouseEvent<HTMLSpanElement, MouseEvent>,
													) => setAuthorizationIdAnchorEl(event.currentTarget)}
													onMouseLeave={() => setAuthorizationIdAnchorEl(null)}
													href={`/authorization/${cross.authorizationId}`}
													underline="hover"
												>
													{cross.authorizationId}
												</Link>
											</TableCell>
											<TableCell align="right" component="p">
												{cross.id}
											</TableCell>
											<TableCell align="right" component="p">
												{cross.authorizationCode}
											</TableCell>
											<TableCell align="right" component="p">
												{
													<Checkbox
														checked={cross.isDiscounted}
														disabled={cross.isDiscounted}
														onChange={() => setRegisterConfirmationModalCrossId(cross.id)}
													/>
												}
											</TableCell>
											<TableCell align="right" component="p">
												{cross.cardLastFourDigit}
											</TableCell>
											<TableCell align="right" component="p">
												{cross.validatingCarrierName}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('amount', cross.amount, cross)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('dateCross', cross.dateCross, cross)}
											</TableCell>
											<TableCell align="right" component="p">
												{cross.eticket}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('operator', cross.operator, cross)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('state', cross.state, cross)}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('createdAt', cross.createdAt, cross)}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeLargeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={2} textAlign="center" marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{registerConfirmationModalCrossId && (
				<ConfirmationModal
					message={'El cruce se marcará como descontado, esta acción no se puede deshacer'}
					handleAccept={() => handleDiscountCross(registerConfirmationModalCrossId)}
					open={registerConfirmationModalCrossId !== null}
					handleClose={() => setRegisterConfirmationModalCrossId(null)}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
