import React, { useMemo, useState } from 'react';
import { IPackageExplorerDestination } from '../../types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { GOOGLE_MAPS_KEY } from '../../../../constants/constants';
import globe from 'assets/globe-purple-marker.png';

interface ThisWindow extends Window {
	google: any;
}

interface Props {
	destinations: IPackageExplorerDestination[];
	handleClick: (destinationCode: string) => void;
}

declare const window: ThisWindow;
export const PackageExplorerMap = ({ destinations, handleClick }: Props): JSX.Element => {
	const [mapLibraryLoaded, setMapLibraryLoaded] = useState<boolean>(false);
	const onLoadMapScript = () => setMapLibraryLoaded(true);

	const markers = useMemo(
		() =>
			mapLibraryLoaded &&
			destinations &&
			destinations.map((destination) => (
				<Marker
					key={destination.code}
					position={{ lat: destination.lat, lng: destination.lng }}
					onClick={() => handleClick(destination.code)}
					label={{
						text: `${destination.lowerPrice.amount}`,
						color: 'white',
						fontSize: '12px',
						fontWeight: 'bold',
					}}
					title={`${destination.name}, ${destination.countryName} - ${destination.lowerPrice.amount} ${destination.lowerPrice.currency}`}
					icon={{
						url: globe,
						scaledSize: new window.google.maps.Size(48, 66),
					}}
				/>
			)),
		[destinations, mapLibraryLoaded],
	);

	return (
		<>
			<div style={{ height: '65vh', width: '100%' }}>
				<LoadScript id="script-loader" googleMapsApiKey={GOOGLE_MAPS_KEY} onLoad={onLoadMapScript}>
					<GoogleMap
						id="availability-map"
						mapContainerStyle={{
							height: '100%',
							width: '100%',
							borderRadius: '14px',
						}}
						zoom={3}
						clickableIcons={false}
						options={{
							zoomControl: true,
							mapTypeControl: false,
							scaleControl: false,
							streetViewControl: false,
							rotateControl: false,
							fullscreenControl: false,
							gestureHandling: 'greedy',
						}}
						center={{ lat: 0, lng: 0 }}
					>
						{markers}
					</GoogleMap>
				</LoadScript>
			</div>
		</>
	);
};
