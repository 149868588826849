import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';

import { RootState } from 'store';
import { hotelSuggestion } from 'services';
import { addHotelOptionToDestination, deleteHotelOptionToDestination } from '../../slice';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPAXTotals } from '../../selectors';
import { getHotelDetail } from '../../../hotels/components/services';
import {
	calculateRoomCostByPax,
	createBoardsKey,
	createHotelFareRequestBase,
	customBoards,
	customRates,
	findCustomBoard,
	findCustomRate,
	getBoardName,
} from '../../helpers';
import { AccommodationFaresRequest, HotelOptions, RoomFare } from '../../types';
import { FarePickerDialog, FareSelected } from '../FarePickerDialog';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	formControl: {
		margin: -3,
		width: '100%',
	},
	table: {
		minWidth: 650,
	},
	brokerField: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	buttonViewHotelPrice: {
		marginLeft: '25px',
		height: '40px',
		marginTop: '20px',
	},
	localPrice: {
		display: 'flex',
		width: '150px',
		justifyContent: 'center',
	},
}));

export interface HotelSuggestion {
	hotelId: number;
	hotelName: string;
	provider: string;
}

export interface HotelSuggestionWithCityCode {
	hotelSuggestion: HotelSuggestion;
	cityCode: string;
}

interface DeleteHotelOptionPayload {
	destinationIndex: number;
	hotelOptionIndex: number;
}

interface AutomaticPriceFarePayload {
	hotelId: number;
	provider: string;
	showSwitch: boolean;
	automaticPrice: boolean;
	showModalFare: boolean;
}

interface RoomInfoByDestinationPayload {
	price: number;
	board: string;
	rateType: string;
	description?: string;
}

interface Props {
	onError: (error: string) => void;
}

export default function HotelOptionsConfig({ onError }: Props): JSX.Element {
	const classes = useStyles();
	const [searchOptions, setSearchOptions] = useState<HotelSuggestionWithCityCode[]>([]);
	const [chdChargedValue, setCHDChargedValue] = React.useState(0);
	const [infChargedValue, setINFChargedValue] = React.useState(0);
	const [brokerName, setBrokerName] = React.useState('');
	const regexCHDOrINFValue = /^\d+(.\d{0,2})?$/;
	const dispatch = useDispatch();
	const { destination, roomBaseConfig, localCurrency, exchangeRate, showLocalCurrency } = useSelector(
		(state: RootState) => state.quotation,
	);
	const paxTotals = useSelector(getPAXTotals);
	const [openDeleteHotelOptionConfirmDialog, setOpenDeleteHotelOptionConfirmDialog] = React.useState(false);
	const initDeleteHotelOptionPayload = { destinationIndex: -1, hotelOptionIndex: -1 };
	const [deleteHotelOptionPayload, setDeleteHotelOptionPayload] =
		React.useState<DeleteHotelOptionPayload>(initDeleteHotelOptionPayload);

	const [automaticPriceFarePayloads, setAutomaticPricePayload] = React.useState<AutomaticPriceFarePayload[]>([]);
	const getRoomInfoInitialState = () => {
		const result: RoomInfoByDestinationPayload[][] = [];
		destination.forEach((_dest, indexDest) => {
			const roomByDestination: RoomInfoByDestinationPayload[] = [];
			roomBaseConfig.map((_roomConf, indexRoom) => {
				roomByDestination[indexRoom] = { price: 0, board: '', rateType: '', description: 'Estandar' };
			});
			result[indexDest] = roomByDestination;
		});
		return result;
	};
	const [roomInfoByDestinationPayload, setRoomInfoByDestinationPayload] = React.useState<
		RoomInfoByDestinationPayload[][]
	>(getRoomInfoInitialState());
	useEffect(() => {
		setRoomInfoByDestinationPayload(getRoomInfoInitialState());
	}, [destination, roomBaseConfig]);

	const handleChangeBoard = (destinationIndex: number, roomIndex: number, value: string) => {
		const newState = [...roomInfoByDestinationPayload];
		newState[destinationIndex][roomIndex].board = value;
		setRoomInfoByDestinationPayload(newState);
	};

	const handleChangeRate = (destinationIndex: number, roomIndex: number, value: string) => {
		const newState = [...roomInfoByDestinationPayload];
		newState[destinationIndex][roomIndex].rateType = value;
		setRoomInfoByDestinationPayload(newState);
	};

	const handleChangePrice = (destinationIndex: number, roomIndex: number, value: string) => {
		const newState = [...roomInfoByDestinationPayload];
		newState[destinationIndex][roomIndex].price = Number(value);
		setRoomInfoByDestinationPayload(newState);
	};
	const handleChangeDescription = (destinationIndex: number, roomIndex: number, value: string) => {
		if (value !== '') {
			const newState = [...roomInfoByDestinationPayload];
			newState[destinationIndex][roomIndex].description = value;
			setRoomInfoByDestinationPayload(newState);
		}
	};

	const handleClickOpenConfirmDeleteHotelOption = (destinationIndex: number, hotelOptionIndex: number) => {
		setOpenDeleteHotelOptionConfirmDialog(true);
		setDeleteHotelOptionPayload({ destinationIndex, hotelOptionIndex });
	};

	const handleCloseConfirmDeleteHotelOption = () => {
		setOpenDeleteHotelOptionConfirmDialog(false);
	};
	const handleDeleteHotelOption = () => {
		setOpenDeleteHotelOptionConfirmDialog(false);
		// @ts-ignore
		dispatch(deleteHotelOptionToDestination(deleteHotelOptionPayload));
	};

	const handleChangeSearchHotelName = (cityCode: string) => async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 2) {
			const data = await hotelSuggestion(cityCode, e.target.value);
			setSearchOptions(
				data.data.map((x) => {
					return { hotelSuggestion: x, cityCode: cityCode } as HotelSuggestionWithCityCode;
				}),
			);
		}
	};

	const addHotelOption = async (e: React.SyntheticEvent, rooms: RoomFare[]) => {
		// @ts-ignore
		const hotelId = Number(e.target['hotelId'].value);

		// @ts-ignore
		const hotelName = e.target.hotelName.value;

		// @ts-ignore
		const provider = e.target['hotelProvider'].value;

		// @ts-ignore
		const destinationIndex = Number(e.target['destinationIndex'].value);
		// @ts-ignore
		const result = await getHotelDetail(String(e.target['hotelId'].value), e.target['hotelProvider'].value);
		const details = result.data;
		const hotelOptionsObj = {
			// @ts-ignore
			hotelName: hotelName,
			hotelId: hotelId,
			provider: provider,
			chdCharged: chdChargedValue,
			infCharged: infChargedValue,
			...(brokerName && { brokerName }),
			rooms: rooms,
			details,
		} as HotelOptions;
		dispatch(
			addHotelOptionToDestination({
				destinationIndex: destinationIndex,
				data: hotelOptionsObj,
			}),
		);
		clearBrokerName();
	};

	const handleAddHotelOption = async (e: React.SyntheticEvent) => {
		e.preventDefault();

		// @ts-ignore
		const hotelId = Number(e.target['hotelId'].value);

		// @ts-ignore
		const hotelName = e.target.hotelName.value;

		// @ts-ignore
		const provider = e.target['hotelProvider'].value;
		// @ts-ignore
		const destinationIndex = Number(e.target['destinationIndex'].value);

		if (destinationIndex >= 0 && destinationIndex < destination.length) {
			const maybeSameHotel = destination[destinationIndex].hotelOptions.find(
				(x) => x.hotelId == hotelId && x.provider == provider,
			);

			const roomsFares = roomBaseConfig.map((i, index) => ({
				// @ts-ignore
				price: e.target[`hotelPrice-${index}`].value,
				// @ts-ignore
				board: e.target[`hotelBoard-${index}`].value,
				// @ts-ignore
				rateType: e.target[`rateType-${index}`].value,
				// @ts-ignore
				description: e.target[`room-${index}-description`].value,
			}));
			if (maybeSameHotel) {
				const boardStringsInHotelFound = createBoardsKey(maybeSameHotel.rooms);
				const boardStringInNewHotel = createBoardsKey(roomsFares);
				if (boardStringsInHotelFound == boardStringInNewHotel) {
					onError(
						`El hotel ${hotelName} con una configuración de régimenes igual al actual ya fue adicionado en el destino`,
					);
				} else {
					await addHotelOption(e, roomsFares);
				}
			} else {
				await addHotelOption(e, roomsFares);
			}
		}
	};

	const handleSelectHotel = (destinationIndex: number, hotelId: number, provider: string) => {
		const newValue = [...automaticPriceFarePayloads];
		newValue[destinationIndex] = {
			hotelId,
			provider,
			showSwitch: provider == 'Hotelbeds',
			automaticPrice: false,
			showModalFare: false,
		};
		setAutomaticPricePayload(newValue);
	};

	const clearBrokerName = () => setBrokerName('');

	const handleClearSelectedHotel = (destinationIndex: number) => {
		const newValue = [...automaticPriceFarePayloads];
		if (newValue[destinationIndex]) {
			newValue.splice(destinationIndex, 1);
		}
		setAutomaticPricePayload(newValue);
	};

	const canShowAutomaticPriceSwitch = (destinationIndex: number): boolean => {
		const indexValue = automaticPriceFarePayloads[destinationIndex];
		return indexValue ? indexValue.showSwitch : false;
	};

	const canShowModalFareAutomaticPrice = (destinationIndex: number): boolean => {
		const indexValue = automaticPriceFarePayloads[destinationIndex];
		return indexValue ? indexValue.showModalFare : false;
	};

	const hideOrShowModalFareAutomaticPrice = (destinationIndex: number, show: boolean) => {
		if (automaticPriceFarePayloads[destinationIndex]) {
			const newValue = [...automaticPriceFarePayloads];
			newValue[destinationIndex].showModalFare = show;
			setAutomaticPricePayload(newValue);
		}
	};

	const handleFaresSelected = (destinationIndex: number, faresSelected: FareSelected[]) => {
		if (
			roomInfoByDestinationPayload[destinationIndex] &&
			roomInfoByDestinationPayload[destinationIndex].length === faresSelected.length
		) {
			const infoForRooms: RoomInfoByDestinationPayload[] = faresSelected.map((fareSelected) => {
				const customBoard = findCustomBoard(fareSelected.fare.board);
				const customRate = findCustomRate(fareSelected.fare.cancellationPolicy);
				return {
					price: fareSelected.fare.fare,
					board: customBoard ? customBoard.code : '',
					rateType: customRate ? customRate.code : '',
				};
			});

			const newState = [...roomInfoByDestinationPayload];
			newState[destinationIndex] = infoForRooms;
			setRoomInfoByDestinationPayload(newState);
		}
	};

	const setAutomaticPrice = (destinationIndex: number, automaticPrice: boolean) => {
		if (automaticPriceFarePayloads[destinationIndex]) {
			const newValue = [...automaticPriceFarePayloads];
			newValue[destinationIndex].automaticPrice = automaticPrice;
			setAutomaticPricePayload(newValue);
			if (automaticPrice) {
				clearBrokerName();
			}
		}
	};

	const isAutomaticPrice = (destinationIndex: number): boolean => {
		const indexValue = automaticPriceFarePayloads[destinationIndex];
		return indexValue ? indexValue.automaticPrice : false;
	};

	const hasSomeBrokerName = (): boolean =>
		destination.some((x) => x.hotelOptions.some((h) => h?.brokerName && h?.brokerName != ''));

	const createHotelFareRequest = (indexDestination: number): AccommodationFaresRequest => {
		const destinationInIndex = destination[indexDestination];
		return createHotelFareRequestBase(
			roomBaseConfig,
			automaticPriceFarePayloads[indexDestination].hotelId,
			destinationInIndex,
		);
	};

	return (
		<Container component="main" maxWidth="md">
			<div>
				<Dialog
					open={openDeleteHotelOptionConfirmDialog}
					onClose={handleCloseConfirmDeleteHotelOption}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{'Eliminar tarifa de habitación de hotel'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							¿Confirma la eliminación de la tarifa de la habitación?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={handleCloseConfirmDeleteHotelOption}>
							Cancelar
						</Button>
						<Button variant="contained" color="primary" onClick={handleDeleteHotelOption} autoFocus>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Alojamiento
				</Typography>
				{destination.map((destinationItem, index) => (
					<>
						<TableContainer component={Paper}>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Room #</TableCell>
										<TableCell>Hotel Name</TableCell>
										<TableCell align="right">Precio x ADT</TableCell>
										<TableCell align="right">{paxTotals.child > 0 ? 'Precio x CHD' : ''}</TableCell>
										<TableCell align="right">{paxTotals.infants > 0 ? 'Precio x INF' : ''}</TableCell>
										<TableCell align="right">Precio Total</TableCell>
										<TableCell align="right">Regimen</TableCell>
										<TableCell align="right">Tipo de Tarifa</TableCell>
										<TableCell align="right">{hasSomeBrokerName() ? 'Broker' : ''}</TableCell>
										<TableCell align="right" />
									</TableRow>
								</TableHead>
								<TableBody>
									{destinationItem.hotelOptions.map((option, indexOption) => {
										return option.rooms.map((optionItem, optionItemIndex) => {
											const totalAdult = paxTotals.totalPaxPerRoom[optionItemIndex].totalAdults;
											const totalChild = paxTotals.totalPaxPerRoom[optionItemIndex].totalChild;
											const totalInfant = paxTotals.totalPaxPerRoom[optionItemIndex].totalInfant;
											const roomCostByPax = calculateRoomCostByPax(
												optionItem.price,
												totalAdult,
												totalChild,
												totalInfant,
												option.chdCharged,
												option.infCharged,
											);
											const price = Math.round(roomCostByPax.adtCost * exchangeRate);
											const priceLocal = price.toLocaleString().replace(/,/g, '.');
											const priceTotal = Math.round(optionItem.price * exchangeRate);
											const priceLocalTotal = priceTotal.toLocaleString().replace(/,/g, '.');
											return (
												<TableRow
													key={`${index}-${destinationItem.cityCode + '-' + indexOption + '-' + optionItemIndex}`}
												>
													<TableCell component="th" scope="row">
														{optionItemIndex + 1}
													</TableCell>
													<TableCell>{option.hotelName}</TableCell>
													<TableCell align="center">
														USD {roomCostByPax.adtCost} <br />
														{showLocalCurrency && (
															<TableCell size="small" className={classes.localPrice}>
																{localCurrency} {priceLocal}
															</TableCell>
														)}
													</TableCell>

													<TableCell align="right">
														{paxTotals.totalPaxPerRoom[optionItemIndex].hasChild && roomCostByPax.chdCost}
													</TableCell>
													<TableCell align="right">
														{paxTotals.totalPaxPerRoom[optionItemIndex].hasInfant && roomCostByPax.infCost}
													</TableCell>
													<TableCell align="center">
														USD {optionItem.price}
														<br />
														{showLocalCurrency && (
															<TableCell size="small" className={classes.localPrice}>
																{localCurrency} {priceLocalTotal}
															</TableCell>
														)}
													</TableCell>
													<TableCell align="right">{getBoardName(optionItem.board)}</TableCell>
													<TableCell align="right">{optionItem.rateType}</TableCell>
													<TableCell align="right">{option?.brokerName ? option?.brokerName : ''}</TableCell>
													<TableCell align="right">
														<IconButton
															color="secondary"
															component="span"
															onClick={() => handleClickOpenConfirmDeleteHotelOption(index, indexOption)}
															size="large"
														>
															<DeleteIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											);
										});
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<form key={index} onSubmit={handleAddHotelOption}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography component="span" variant="h6">
										{destinationItem.cityName}
									</Typography>
									<Grid>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={4}>
												<input type="hidden" id={`hotelIdAdd-${index}`} name="hotelId" />
												<input type="hidden" id={`hotelProviderAdd-${index}`} name="hotelProvider" />
												<input type="hidden" name="destinationIndex" value={index} />
												<Autocomplete
													id="optionHotelNameAutocomplete"
													options={searchOptions.filter((x) => x.cityCode == destinationItem.cityCode)}
													getOptionLabel={(option: HotelSuggestionWithCityCode) => option.hotelSuggestion.hotelName}
													onChange={(event: React.ChangeEvent<any>, value: HotelSuggestionWithCityCode | null) => {
														if (value && value.hotelSuggestion.hotelName && value.hotelSuggestion.hotelId) {
															const hiddenElement = document.getElementById(`hotelIdAdd-${index}`);
															const hiddenProviderElement = document.getElementById(`hotelProviderAdd-${index}`);
															// @ts-ignore
															if (hiddenElement) hiddenElement.value = value.hotelSuggestion.hotelId;
															// @ts-ignore
															if (hiddenProviderElement) hiddenProviderElement.value = value.hotelSuggestion.provider;
															handleSelectHotel(index, value.hotelSuggestion.hotelId, value.hotelSuggestion.provider);
														} else {
															handleClearSelectedHotel(index);
														}
													}}
													// debug
													renderInput={(params: any) => (
														<TextField
															name="hotelName"
															variant="standard"
															// value={item.cityName}
															required
															label="Nombre del hotel"
															size="small"
															onChange={handleChangeSearchHotelName(destinationItem.cityCode)}
															fullWidth
															style={{ marginTop: -3 }}
															{...params}
														/>
													)}
												/>
											</Grid>

											<Grid item xs={12} sm={2}>
												<TextField
													variant="outlined"
													required
													fullWidth
													label="CHD Charged?"
													name="chdCharged"
													type="number"
													onChange={({ target: { value } }) => {
														if (regexCHDOrINFValue.test(value) && Number(value) <= 1) {
															setCHDChargedValue(Number(value));
														}
													}}
													value={chdChargedValue}
												/>
											</Grid>
											<Grid item xs={12} sm={2}>
												<TextField
													variant="outlined"
													required
													fullWidth
													label="INF Charged?"
													name="infCharged"
													type="number"
													value={infChargedValue}
													onChange={({ target: { value } }) => {
														if (regexCHDOrINFValue.test(value) && Number(value) <= 1) {
															setINFChargedValue(Number(value));
														}
													}}
												/>
											</Grid>

											{canShowAutomaticPriceSwitch(index) && (
												<Grid item xs={12} sm={2}>
													<FormControlLabel
														control={
															<Switch
																checked={isAutomaticPrice(index)}
																onChange={(_, checked) => setAutomaticPrice(index, checked)}
																name="autoFares"
																color="primary"
															/>
														}
														label="Tarifas Automáticas"
													/>

													{canShowModalFareAutomaticPrice(index) && (
														<FarePickerDialog
															open={canShowModalFareAutomaticPrice(index)}
															onSubmit={(fares) => {
																hideOrShowModalFareAutomaticPrice(index, false);
																handleFaresSelected(index, fares);
															}}
															fareRequest={createHotelFareRequest(index)}
															onClose={() => hideOrShowModalFareAutomaticPrice(index, false)}
														/>
													)}
												</Grid>
											)}

											{canShowAutomaticPriceSwitch(index) && isAutomaticPrice(index) && (
												<Button
													className={classes.buttonViewHotelPrice}
													variant="contained"
													size="small"
													color="primary"
													onClick={() => hideOrShowModalFareAutomaticPrice(index, true)}
												>
													Ver Precios
												</Button>
											)}
										</Grid>

										{!isAutomaticPrice(index) && (
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12}>
													<TextField
														className={classes.brokerField}
														variant="outlined"
														name="brokerName"
														label="Nombre del Broker"
														onChange={({ target: { value } }) => setBrokerName(value)}
														value={brokerName}
														inputProps={{
															maxLength: 250,
														}}
														fullWidth
													/>
												</Grid>
											</Grid>
										)}
										{roomBaseConfig.map((roomConfig, indexRoom) => (
											<Grid
												key={`${index}-${destinationItem.cityCode + '-roomconfig-' + indexRoom}`}
												container
												spacing={2}
											>
												<Grid item xs={12}>
													<Typography component="span" variant="subtitle1">
														Habitacion {indexRoom + 1}
													</Typography>
												</Grid>
												{roomInfoByDestinationPayload[index] && roomInfoByDestinationPayload[index][indexRoom] && (
													<>
														<Grid item xs={12} sm={4}>
															<TextField
																variant="outlined"
																required
																fullWidth
																label="Precio"
																name={`hotelPrice-${indexRoom}`}
																type="number"
																onChange={({ target: { value } }) => {
																	if (regexCHDOrINFValue.test(value) && Number(value) > 0) {
																		handleChangePrice(index, indexRoom, value);
																	} else if (value == '') handleChangePrice(index, indexRoom, '0');
																}}
																value={roomInfoByDestinationPayload[index][indexRoom].price}
															/>
														</Grid>

														<Grid item xs={12} sm={4}>
															<FormControl className={classes.formControl}>
																<InputLabel required>Regimen</InputLabel>
																<Select
																	required
																	label="Regimen"
																	name={`hotelBoard-${indexRoom}`}
																	value={roomInfoByDestinationPayload[index][indexRoom].board}
																	onChange={(event: SelectChangeEvent) => {
																		handleChangeBoard(index, indexRoom, event.target.value as string);
																	}}
																	// onChange={handleChange}
																>
																	{customBoards.map((board) => (
																		<MenuItem
																			key={`${index}-${
																				destinationItem.cityCode + '-roomconfig-boards-' + indexRoom + '-' + board.code
																			}`}
																			value={board.code}
																		>
																			{board.name}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</Grid>
														<Grid item xs={12} sm={4}>
															<FormControl className={classes.formControl}>
																<InputLabel required>Tipo de Tarifa</InputLabel>
																<Select
																	label="Tipo de Tarifa"
																	required
																	value={roomInfoByDestinationPayload[index][indexRoom].rateType}
																	name={`rateType-${indexRoom}`}
																	onChange={(event: SelectChangeEvent) => {
																		handleChangeRate(index, indexRoom, event.target.value as string);
																	}}
																	// onChange={handleChange}
																>
																	{customRates.map((rate) => (
																		<MenuItem
																			key={`${
																				destinationItem.cityCode + '-roomconfig-rates-' + indexRoom + '-' + rate.code
																			}`}
																			value={rate.code}
																		>
																			{rate.name}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</Grid>
														<Grid item xs={12}>
															<TextField
																variant="outlined"
																multiline
																required
																fullWidth
																label="Tipo de habitacion"
																name={`room-${indexRoom}-description`}
																inputProps={{
																	maxLength: 100,
																}}
																InputLabelProps={{ shrink: true }}
																onChange={({ target: { value } }) => {
																	handleChangeDescription(index, indexRoom, value);
																}}
																value={roomInfoByDestinationPayload[index][indexRoom].description}
															/>
														</Grid>
													</>
												)}
											</Grid>
										))}
										<Grid container direction="row-reverse">
											<Button variant="contained" color="primary" style={{ margin: 8 }} type="submit">
												Agregar opción de hotel
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</>
				))}
			</div>
		</Container>
	);
}
