import React, { useState } from 'react';
import { IPackageExplorerFilter } from '../../types';
import { Box, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';

interface Props {
	title: string;
	concept: string;
	data: IPackageExplorerFilter[];
	handleApplyFilter: (concept: string, value: number | null) => void;
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '14px',
		fontWeight: 'bold',
		color: theme.palette.primary.main,
		position: 'sticky',
	},
}));

export const PackageExplorerLateralFilter = ({ title, concept, data, handleApplyFilter }: Props): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState<string>('');
	const handleClick = (itemValue: number) => {
		const itemValueStr = itemValue.toString();
		const valueToSet = itemValueStr === value ? '' : itemValueStr;
		setValue(valueToSet);
		handleApplyFilter(concept, valueToSet ? itemValue : null);
	};

	return (
		<Container sx={{ 'padding-top': '15px' }}>
			<FormLabel className={classes.title}>{title}</FormLabel>
			<Box sx={{ 'max-height': '450px', 'overflow-y': 'auto' }}>
				<FormControl>
					<RadioGroup name="radio-buttons-group" value={value}>
						{data.map((item) => (
							<FormControlLabel
								key={`package-filter-${concept}-${item.id}`}
								value={item.id}
								control={<Radio onClick={() => handleClick(item.id)} />}
								label={
									<span
										style={{ color: theme.palette.primary.main, fontSize: '13px' }}
										className={'icon icon-' + item.icon}
									>
										{' '}
										{item.name}
									</span>
								}
							/>
						))}
					</RadioGroup>
				</FormControl>
			</Box>
		</Container>
	);
};
