import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { addNotInclude, deleteNotInclude } from '../../slice';
import { NotIncludes } from './NotIncludes';

export const NotIncludesCompleteQuotation = (): JSX.Element => {
	const dispatch = useDispatch();
	const { notIncludes } = useSelector((state: RootState) => state.quotation);

	const handleDeleteNotInclude = (index: number) => {
		dispatch(deleteNotInclude(index));
	};
	const handleAddNotInclude = (notIncludeText: string) => {
		dispatch(addNotInclude(notIncludeText));
	};

	return (
		<NotIncludes notIncludes={notIncludes} handleDelete={handleDeleteNotInclude} handleAdd={handleAddNotInclude} />
	);
};
