import React from 'react';

import { Layout } from '../../../features/common/components/Layout';
import { OrderFormViajeNaranja } from 'features/payment/components/OrderFormViajeNaranja';
import makeStyles from '@mui/styles/makeStyles';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PageNotAllowedForMarket } from '../../../features/common/components/PageNotAllowedForMarket';
import { CountryCodeEnum } from '../../../features/common/types';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
}));

export const OrderPageViajeNaranja = (): JSX.Element => {
	const { auth } = useSelector((state: RootState) => state);
	const classes = useStyles();

	return (
		<Layout>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					{auth.country === CountryCodeEnum.TN_ITAU_PY && <OrderFormViajeNaranja />}
					{auth.country !== CountryCodeEnum.TN_ITAU_PY && <PageNotAllowedForMarket />}
				</div>
			</Container>
		</Layout>
	);
};
