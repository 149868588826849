import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import errorIcon from '../../../../assets/error-icon.png';

export interface Props {
	error: string;
}

export const GatewayFailedTransaction = ({ error }: Props): JSX.Element => {
	return (
		<Container sx={{ marginY: 2 }} component="main" maxWidth="md">
			<Grid container spacing={2}>
				<Grid item textAlign="center" xs={12}>
					<img src={errorIcon} width="10%" height="auto" alt="error-icon" />
				</Grid>
				<Grid item xs={12} marginLeft={{ xs: 2, sm: 10 }}>
					<Typography fontSize={25}>¡Ups! Hubo un error</Typography>
				</Grid>
				<Grid item xs={12} marginLeft={{ xs: 2, sm: 10 }}>
					<Typography>Lo sentimos pero no se pudo procesar su pago por la siguente razón:</Typography>
					<Typography fontWeight="bold">{error}</Typography>
				</Grid>
				<Grid item xs={12} marginLeft={{ xs: 2, sm: 10 }}>
					<Typography>Por favor contacta con tu banco.</Typography>
				</Grid>
			</Grid>
		</Container>
	);
};
