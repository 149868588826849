import React, { useState, useRef, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useFormContext, Controller } from 'react-hook-form';
import { getAccomodationProviderAutocomplete, getAccomodationProviderId } from 'features/salesOrder/services';

interface Props {
	name: string;
	rules?: Record<string, any>;
	allowNotApplicable?: boolean;
	accommodationServiceProviderType: AccommodationServiceProviderTypeEnum;
	setAccommodationServiceProviderType: (providerType: AccommodationServiceProviderTypeEnum) => void;
}
export enum AccommodationServiceProviderTypeEnum {
	Hotelbeds = 'Hotelbeds',
	Mevuelo = 'Mevuelo',
}

export interface AccomodationProviderItem {
	id: string | number;
	name: string;
	cityName: string;
	providerType: AccommodationServiceProviderTypeEnum;
}
const notApplicableId = 'n/a';

const notApplicable: AccomodationProviderItem = {
	id: notApplicableId,
	name: 'Desconocido (No Aplica)',
	cityName: 'Desconocido',
	providerType: AccommodationServiceProviderTypeEnum.Hotelbeds,
};

export const AutocompleteAccomodationProviders = ({
	name,
	rules,
	allowNotApplicable,
	accommodationServiceProviderType,
	setAccommodationServiceProviderType,
}: Props): JSX.Element => {
	const {
		control,
		watch,
		formState: { errors },
	} = useFormContext();
	const [initialValue, setInitialValue] = useState<AccomodationProviderItem>(notApplicable);
	// @ts-ignore
	const formValue = watch(name) as string;
	const [inputValue, setInputValue] = useState(allowNotApplicable === true ? notApplicable.id : '');
	const [options, setOptions] = useState<any[]>(
		allowNotApplicable === true ? [{ ...initialValue, ...notApplicable }] : [{ ...initialValue }],
	);

	const autocompleteDebouncer = useRef<number | null>(null);

	const fetchOptions = async (valueToSearch: string | number): Promise<AccomodationProviderItem[]> => {
		const resultCheckNotApplicable = allowNotApplicable === true ? [notApplicable] : [];
		if (valueToSearch === notApplicableId || !valueToSearch || String(valueToSearch).length < 3) {
			return resultCheckNotApplicable;
		} else {
			try {
				const response = await getAccomodationProviderAutocomplete(String(valueToSearch));
				return response.data;
			} catch (error) {
				// @ts-ignore
				return resultCheckNotApplicable;
			}
		}
	};

	useEffect(() => {
		if (formValue) {
			const fetchProviderByIdAndProviderType = async (
				providerId: string,
				accommodationServiceProviderType: AccommodationServiceProviderTypeEnum,
			) => {
				try {
					const result = await getAccomodationProviderId(providerId, accommodationServiceProviderType);

					if (result.data) {
						// @ts-ignore
						setInitialValue(result.data);
					}
				} catch (_e) {
					console.log('Error in get Provider', _e);
				}
			};
			fetchProviderByIdAndProviderType(formValue, accommodationServiceProviderType);
		}
	}, [formValue, accommodationServiceProviderType]);

	const inputChangeHandler = (value: string) => {
		setInputValue(value);
		if (autocompleteDebouncer.current !== null) {
			clearTimeout(autocompleteDebouncer.current);
		}
		if (value.length > 2) {
			autocompleteDebouncer.current = setTimeout(async () => {
				try {
					fetchOptions(inputValue).then((results) => {
						if (allowNotApplicable === true) {
							const newOptions = [{ ...notApplicable }, ...results];
							setOptions(newOptions);
						} else {
							setOptions(results);
						}
					});
				} catch (error) {
					return allowNotApplicable === true ? [{ ...notApplicable }] : [];
				}
				300;
			});
		} else {
			setOptions(allowNotApplicable === true ? [{ ...notApplicable }] : []);
		}
	};

	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<FormControl sx={{ width: '100%' }}>
					<Autocomplete
						{...field}
						options={options}
						getOptionLabel={(option) =>
							typeof option === 'string'
								? option
								: option.id === notApplicableId
								? `${option.name}`
								: `${option.name} - ${option.cityName} [${option.providerType}]`
						}
						autoComplete
						includeInputInList
						filterSelectedOptions
						value={
							initialValue
								? initialValue.id === notApplicableId
									? `${initialValue.name}`
									: `${initialValue.name} - ${initialValue.cityName} [${initialValue.providerType}]`
								: allowNotApplicable
								? `${notApplicable.name} -${notApplicable.cityName}`
								: ''
						}
						onChange={(_, newValue) => {
							const selectedOption = options.find((opt) =>
								newValue ? opt.id === newValue.id && opt.providerType === newValue.providerType : false,
							);
							field.onChange(selectedOption ? selectedOption.id : '');
							if (selectedOption) {
								setAccommodationServiceProviderType(selectedOption.providerType);
							}
						}}
						onInputChange={(_, newValue) => {
							inputChangeHandler(newValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								error={Boolean(errors[name])}
								variant="outlined"
								value={inputValue}
								fullWidth
								size="small"
								InputLabelProps={{ shrink: true }}
								helperText="Este campo es requerido"
								style={{ backgroundColor: 'white' }}
							/>
						)}
					/>
				</FormControl>
			)}
		></Controller>
	);
};
