import React from 'react';
import { Container, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { TransactionDetails as Component } from 'features/transactions/components/TransactionDetails';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}));

function TransactionDetails(): JSX.Element {
	const classes = useStyles();
	const theme = useTheme();
	const { id } = useParams<{ id: string }>();

	return (
		<Layout>
			<div>
				<Container component="main" sx={{ marginBottom: theme.spacing(3) }}>
					<Typography variant="h5" className={classes.headerTitle}>
						Detalle de Transacción
					</Typography>
					<Component id={+id} />
				</Container>
			</div>
		</Layout>
	);
}

export default TransactionDetails;
