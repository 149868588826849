import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IFlightFinalPriceSummary, IPassengerConfig } from '../../types';

const useStyles = makeStyles((theme) => ({
	cardFlightPrice: { paddingLeft: '20px', paddingRight: '20px' },
	cardTitle: {
		fontStyle: 'normal',
		fontWeight: 700,
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(1),
	},
	textInfo: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
	},
	headerTextTitle: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 'bold',
		lineHeight: '24px',
		textAlign: 'center',
	},
	priceTitle: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
		float: 'left',
	},

	priceValue: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 700,
		color: theme.palette.secondary.main,
		float: 'right',
	},
	rowPriceContainer: {
		marginTop: theme.spacing(1),
	},
	rowFinalPriceContainer: {
		marginTop: theme.spacing(2),
	},
}));
interface Props {
	flightFinalPriceSummary: IFlightFinalPriceSummary;
	passengerConfig: IPassengerConfig;
	showUtility: boolean;
	markup: number | null;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

export const FlightPricePreviewMobile = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { flightFinalPriceSummary, passengerConfig } = props;
	const totalChild = passengerConfig.childrenAges.length;
	const totalInfant = passengerConfig.infantAges.length;
	const totalInfants = passengerConfig.infantAges.length;
	const CHDExists = totalChild > 0;
	const INFExists = totalInfants > 0;
	const localPriceADT = flightFinalPriceSummary.adtPrice * props.exchangeRate;
	const localPriceCHD = flightFinalPriceSummary.chdPrice * props.exchangeRate;
	const localPriceINF = flightFinalPriceSummary.infPrice * props.exchangeRate;
	const localPriceTOTAL = flightFinalPriceSummary.totalPrice * props.exchangeRate;
	return (
		<Card className={classes.cardFlightPrice}>
			<CardContent>
				<Typography className={classes.headerTextTitle} component={'p'} variant={'body2'}>
					{' '}
					Presupuesto
				</Typography>
				<Typography className={classes.textInfo} component={'p'} variant={'body2'}>
					{' '}
					{passengerConfig.adults} {passengerConfig.adults > 1 ? 'adultos' : 'adulto'} {CHDExists && '+'}{' '}
					{CHDExists && totalChild} {CHDExists ? (CHDExists && totalChild > 1 ? 'niños' : 'niño') : ''}{' '}
					{INFExists && '+'} {INFExists && totalInfant}{' '}
					{INFExists ? (INFExists && totalInfant > 1 ? 'bebés' : 'bebé') : ''}{' '}
				</Typography>

				<Grid container>
					<Grid item xs={6}>
						<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
							Precio por adulto
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
							USD {flightFinalPriceSummary.adtPrice}
						</Typography>
						{props.showLocalCurrency && (
							<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
								{props.localCurrency} {localPriceADT}
							</Typography>
						)}
					</Grid>
				</Grid>

				{CHDExists && (
					<div className={classes.rowPriceContainer}>
						<Grid container>
							<Grid item xs={6}>
								<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
									Precio por niño
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
									{CHDExists && 'USD'} {CHDExists && flightFinalPriceSummary.chdPrice}
								</Typography>
								{props.showLocalCurrency && CHDExists && (
									<Typography>
										{props.localCurrency} {localPriceCHD}
									</Typography>
								)}
							</Grid>
						</Grid>
					</div>
				)}

				{INFExists && (
					<div className={classes.rowPriceContainer}>
						<Grid container>
							<Grid item xs={6}>
								<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
									Precio por bebé
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
									{INFExists && 'USD'} {INFExists && flightFinalPriceSummary.infPrice}
								</Typography>
								{props.showLocalCurrency && INFExists && (
									<Typography>
										{props.localCurrency} {localPriceINF}
									</Typography>
								)}
							</Grid>
						</Grid>
					</div>
				)}
				<div className={classes.rowFinalPriceContainer}>
					<Grid container>
						<Grid item xs={6}>
							<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
								Precio total
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
								USD {flightFinalPriceSummary.totalPrice}
							</Typography>
							{props.showLocalCurrency && (
								<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
									{props.localCurrency} {localPriceTOTAL}
								</Typography>
							)}
						</Grid>
					</Grid>
				</div>
			</CardContent>
		</Card>
	);
};
