import React from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Snackbar,
	TextField,
	Toolbar,
	Typography,
	useTheme,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import { checkShowErrorMessage } from '../../../common/helpers';
import {
	IAddReferenceToWireTransferInInfimia,
	IInfimiaWireTransferTransactionItem,
	WireTransferCommentMaxLength,
} from '../../types';
import makeStyles from '@mui/styles/makeStyles';
import { extractErrorMessage, isOnlyDigit } from '../../../quotation/helpers';
import CloseIcon from '@mui/icons-material/Close';
import { findContactDataByDeal } from '../../../../services';
import { addReferenceToWireTransferInInfimia } from '../../services';
import { setWireTransferCheckResult } from '../../slice';
import { useDispatch } from 'react-redux';

export interface AddCommentToWireTransferModalProps {
	open: boolean;
	item: IInfimiaWireTransferTransactionItem;
	handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export const AddReferenceToWireTransferModal = ({
	item,
	open,
	handleClose,
}: AddCommentToWireTransferModalProps): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState<boolean>(false);
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues,
		setValue,
	} = useForm({ mode: 'all', defaultValues: { id: item.id, comment: '' } });
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [dealId, setDealId] = React.useState<number>(0);
	const [name, setName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [phone, setPhone] = React.useState<string>('');
	const [isRequestedDataDeal, setIsRequestedDataDeal] = React.useState<boolean>(false);
	const [dealWithoutContact, setDealWithoutContact] = React.useState<boolean>(false);
	const [isConfirmedDeal, setIsConfirmedDeal] = React.useState<boolean>(false);

	const handleConfirmAutoCompleteContactDataByDeal = (e: React.KeyboardEvent<HTMLInputElement>) => {
		setIsRequestedDataDeal(false);
		setIsConfirmedDeal(false);
		setDealWithoutContact(false);
		setValue('comment', '');
		clearValuesContact();
		if (e.key === 'Enter' && dealId > 0) {
			handleAutoCompleteNameEmailAndPhone();
		}
	};
	const clearValuesContact = () => {
		name && setName('');
		email && setEmail('');
		phone && setPhone('');
	};

	const handleAutoCompleteNameEmailAndPhone = async () => {
		try {
			setLoading(true);
			setIsRequestedDataDeal(false);
			setIsConfirmedDeal(false);
			setDealWithoutContact(false);
			setValue('comment', '');
			clearValuesContact();
			const response = await findContactDataByDeal(dealId);
			setName(response.data.name);
			setEmail(response.data.email || '');
			setPhone(response.data.phone || '');
			setLoading(false);
			setIsRequestedDataDeal(true);
		} catch (error) {
			setLoading(false);
			if (error?.response?.status !== 404) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'No se pudo obtener los datos de contacto'),
				});
			} else {
				setIsRequestedDataDeal(true);
				setDealWithoutContact(true);
			}
		}
	};

	const handleDealIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isOnlyDigit(e.target.value) && Number(e.target.value) > 0) setDealId(Number(e.target.value));
	};

	const handlerConfirmDealData = () => {
		setValue('comment', '');
		setValue('comment', `${dealId} | `);
		setIsConfirmedDeal(true);
	};

	const regularExpressionComment = /^(\d+)( \|+ )+(.*)$/;

	const onSubmit = async () => {
		try {
			setLoading(true);
			await addReferenceToWireTransferInInfimia(
				Object.assign(
					{
						id: item.id,
						dealId: dealId,
						country: item.country,
						bank: item.bank,
						account: item.account,
						bankReference: item.bankReference,
						bankDate: item.bankDate,
						currency: item.currency,
						amount: Number(item.credit),
					},
					getValues(),
				) as IAddReferenceToWireTransferInInfimia,
			);

			setLoading(false);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Se ha adicionado el comentario a la transferencia satisfactoriamente',
			});
			setTimeout(() => {
				handleClose();
				dispatch(setWireTransferCheckResult(null));
			}, 1500);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al adicionar el comentario a la transferencia'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="body1" component={'span'} color="primary">
						Adicionar comentario
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<Grid container gap={2}>
						<Grid item xs={12}>
							<form>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4}>
										<TextField
											variant="outlined"
											required
											fullWidth
											type="number"
											id="crmTicket"
											label="Bitrix Deal #"
											name="crmTicket"
											autoComplete="crmTicket"
											autoFocus
											onKeyDown={handleConfirmAutoCompleteContactDataByDeal}
											onChange={handleDealIdChange}
										/>
										{dealWithoutContact && (
											<Typography color={theme.palette.info.main} component="span" variant="subtitle2">
												El deal no tiene contacto asociado
											</Typography>
										)}
									</Grid>
									<Grid item xs={12} sm={8}>
										<TextField
											autoComplete="name"
											name="adults"
											variant="outlined"
											fullWidth
											disabled={true}
											id="name"
											value={name}
											label="Nombre"
										/>
									</Grid>

									<Grid item xs={12}>
										<TextField
											variant="outlined"
											fullWidth
											disabled={true}
											id="email"
											label="Email"
											value={email}
											name="email"
											autoComplete="email"
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											disabled={true}
											fullWidth
											name="phone"
											label="Telefono"
											value={phone}
											id="phone"
											autoComplete="phone"
										/>
									</Grid>
								</Grid>

								<Grid item xs={3} display={'flex'} gap={'10px'} justifyContent="flex-end" paddingTop={1}>
									<Button
										variant="contained"
										disabled={loading || (!isRequestedDataDeal && !isConfirmedDeal)}
										color="success"
										fullWidth
										onClick={handlerConfirmDealData}
									>
										Confirmar
									</Button>
								</Grid>
							</form>
						</Grid>
						<Grid item xs={12}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="comment"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										pattern: {
											value: regularExpressionComment,
											message: 'El mensaje debe tener el formato dealId | tu comentario',
										},
										maxLength: {
											value: WireTransferCommentMaxLength.MESSAGE,
											message:
												'El tamaño del mensaje excede la cantidad máxima de ' +
												WireTransferCommentMaxLength.MESSAGE +
												' caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											multiline
											focused
											rows={5}
											label="Comentario"
											helperText={checkShowErrorMessage(Boolean(errors.comment), errors.comment?.message)}
											error={Boolean(errors.comment)}
											{...field}
										/>
									)}
								/>
							</form>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onSubmit} disabled={!isValid || !isConfirmedDeal || loading} type="submit">
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
