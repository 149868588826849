import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactElement } from 'react';

import { ReactComponent as Bamboo } from '../../assets/payment-processors/bamboo.svg';
import { ReactComponent as Bancard } from '../../assets/payment-processors/bancard.svg';
import { ReactComponent as RedEnlace } from '../../assets/payment-processors/red-enlace.svg';
import { ReactComponent as ToAirlinePay } from '../../assets/payment-processors/toAirline.svg';
import { ReactComponent as Cash } from '../../assets/custom-icons/cash-payment.svg';
import { ReactComponent as Transfer } from '../../assets/custom-icons/transfer-check.svg';
import { ReactComponent as Stats } from '../../assets/stats-line-chart-svgrepo-com.svg';

export const BambooIcon = (): ReactElement => (
	<SvgIcon fontSize="large">
		<Bamboo />
	</SvgIcon>
);
export const BancardIcon = (): ReactElement => (
	<SvgIcon fontSize="large">
		<Bancard />
	</SvgIcon>
);
export const RedenlaceIcon = (): ReactElement => (
	<SvgIcon fontSize="large">
		<RedEnlace />
	</SvgIcon>
);
export const ToAirlinePayIcon = (): ReactElement => (
	<SvgIcon fontSize="large">
		<ToAirlinePay />
	</SvgIcon>
);
export const CashIcon = (): ReactElement => (
	<SvgIcon fontSize="large">
		<Cash />
	</SvgIcon>
);
export const TransferIcon = (): ReactElement => (
	<SvgIcon fontSize="large">
		<Transfer />
	</SvgIcon>
);
export const StatsIcon = (): ReactElement => (
	<SvgIcon>
		<Stats />
	</SvgIcon>
);
