import { IconButton, Popover, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const ReturnButton = (): JSX.Element => {
	const history = useHistory();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	return (
		<>
			<Popover
				id={anchorEl !== null ? 'return-to-transaction-list-popover' : undefined}
				open={anchorEl !== null}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
				disableRestoreFocus
				sx={{
					pointerEvents: 'none',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Typography sx={{ p: 2 }}>Regresar a la página anterior</Typography>
			</Popover>
			<IconButton
				edge="start"
				color="inherit"
				size="small"
				onClick={() => history.goBack()}
				onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
				onMouseLeave={() => setAnchorEl(null)}
				aria-label="close"
			>
				<ArrowBackIcon />
			</IconButton>
		</>
	);
};
