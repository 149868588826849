import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { IFlightExplorerDatesFilterValueContainer } from '../../types';
import { FlightExplorerDatesFilterModal } from '../FlightExplorerDatesFilterModal';

interface Props {
	handleSelectDate: (value: IFlightExplorerDatesFilterValueContainer) => void;
}
export const FlightExplorerDatesFilterInput = ({ handleSelectDate }: Props): JSX.Element => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [valueRender, setValueRender] = useState<string>('Cualquier fecha, cualquier duración');
	const defaultValueSelected = {
		valueType: 0,
		title: 'Cualquier fecha, cualquier duración',
		value: { travelInMonth: 0 },
	} as IFlightExplorerDatesFilterValueContainer;
	const [valueSelected, setValueSelected] = useState<IFlightExplorerDatesFilterValueContainer>(defaultValueSelected);
	const handleClick = () => setOpenModal(true);

	const handleClose = (value: IFlightExplorerDatesFilterValueContainer) => {
		setOpenModal(false);
		setValueSelected(value);
		setValueRender(value.title);
		handleSelectDate(value);
	};

	useEffect(() => {
		handleSelectDate(defaultValueSelected);
	}, []);

	return (
		<>
			<TextField
				fullWidth={true}
				value={valueRender}
				disabled={true}
				sx={{ cursor: 'pointer' }}
				onClick={handleClick}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<DateRangeIcon />
						</InputAdornment>
					),
				}}
				variant="outlined"
			/>
			{openModal && <FlightExplorerDatesFilterModal open={openModal} onClose={handleClose} value={valueSelected} />}
		</>
	);
};
