import React from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Snackbar,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import { checkShowErrorMessage } from '../../../common/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { extractErrorMessage } from '../../../quotation/helpers';
import CloseIcon from '@mui/icons-material/Close';
import { IWireTransferChangeObservation, IWireTransferTransactionList } from '../../../transactions/types';
import { changeObservationsWireTransfersTransactions } from '../../../transactions/services';

export interface AddObservationWireTransferModalProps {
	row: IWireTransferTransactionList;
	handleClose: () => void;
	handleAccept: () => void;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export const AddObservationWireTransferModal = ({
	row,
	handleClose,
	handleAccept,
}: AddObservationWireTransferModalProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = React.useState<boolean>(false);
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues,
	} = useForm({ mode: 'all', defaultValues: { observations: row?.observations || '' } });
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	const onSubmit = async () => {
		try {
			setLoading(true);
			await changeObservationsWireTransfersTransactions(
				Object.assign({ id: row.id, provider: row.provider }, getValues()) as IWireTransferChangeObservation,
			);
			setLoading(false);
			setAlert({
				show: true,
				severity: 'success',
				message: `Se ha ${row?.observations ? 'actualizado' : 'adicionado'} la observación correctamente`,
			});
			setTimeout(() => {
				handleAccept();
			}, 1500);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(
					error,
					`Ocurrió un error al ${row?.observations ? 'actualizar' : 'adicionar'}  la observación`,
				),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={true} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="body1" component={'span'} color="primary">
						{row?.observations ? 'Actualizar' : 'Adicionar'} observación
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<Grid container>
						<Grid item xs={12}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="observations"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										minLength: {
											value: 3,
											message: 'El tamaño del mensaje debe ser al menos de 3 caracteres',
										},
										maxLength: {
											value: 2000,
											message: 'El tamaño del mensaje excede los 2000 caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											multiline
											focused
											rows={5}
											label="Observación"
											helperText={checkShowErrorMessage(Boolean(errors.observations), errors.observations?.message)}
											error={Boolean(errors.observations)}
											{...field}
										/>
									)}
								/>
							</form>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onSubmit} disabled={!isValid || loading} type="submit">
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
