import React, { useEffect, useState } from 'react';
import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Snackbar,
} from '@mui/material';
import invokeLibrary from '../../../common/utils/invokeLibrary';
import makeStyles from '@mui/styles/makeStyles';
import { urlBancard } from '../../constants';

interface IBancardFormModalInitData {
	open: boolean;
	processId: string;
	onCancel: () => void;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	dialog: {
		minHeight: '200px',
		minWidth: '200px',
	},

	dialogContainer: {
		overflow: 'unset !important',
		minHeight: '200px !important',
		minWidth: '200px !important',
	},
}));

interface ThisWindow extends Window {
	Bancard: any;
}

declare const window: ThisWindow;

export const BancardFormModal = ({ open, processId, onCancel }: IBancardFormModalInitData): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);
	const classes = useStyles();
	const idIframeContainer = 'bancard-iframe';

	const initLibrary = () => {
		window.Bancard.Checkout.createForm(idIframeContainer, processId, {});
	};

	const loadLibrary = async () => {
		try {
			setLoading(true);
			await invokeLibrary(urlBancard);
			initLibrary();
			setLoading(false);
		} catch (error) {
			setError('No se pudo cargar correctamente la configuración de la pasarela.');
			setLoading(false);
		}
	};

	useEffect(() => {
		loadLibrary();
	}, []);

	const onCloseByError = () => {
		setError(null);
		onCancel();
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={open} fullWidth maxWidth="xs" disableEscapeKeyDown={true}>
				<DialogContent className={classes.dialogContainer}>
					<div id={idIframeContainer} />
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={onCancel}>
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar open={error != null} autoHideDuration={2000} onClose={onCloseByError}>
				<Alert variant="filled" severity={'error'}>
					{error}
				</Alert>
			</Snackbar>
		</>
	);
};
