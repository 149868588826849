import React, { useEffect, useState } from 'react';
import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	FormControl,
	Grid,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { ICashTransactionMoveCash, IDestinationToMoveCashList } from '../../types';
import { Controller, useForm } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { ShowAlertState } from '../../../common/types';
import { getDestinationToMoveCashTransaction, moveCashTransaction } from '../../services';
import { extractErrorMessage } from '../../../quotation/helpers';
import { ConfirmationModal } from '../../../common/components/ConfirmationModal/ConfirmationModal';
import { checkShowErrorMessage } from '../../../common/helpers';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

interface MoveCashModalProps {
	open: boolean;
	cashTransactionIds: number[];
	onClose: (wasConfirmOperation: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
	closeButton: {
		cursor: 'pointer',
		display: 'inline',
		float: 'right',
	},
}));

function PaperComponent(paperProps: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...paperProps} />
		</Draggable>
	);
}

export const MoveCashModal = ({ open, cashTransactionIds, onClose }: MoveCashModalProps): JSX.Element => {
	const classes = useStyles();
	const formDefaultValues = {
		destinationMove: '',
	};

	const {
		control,
		formState: { errors, isValid },
		getValues,
	} = useForm({ mode: 'all', defaultValues: formDefaultValues });
	const [loading, setLoading] = useState<boolean>(false);
	const [destinationsToMoveCash, setDestinationsToMoveCash] = useState<IDestinationToMoveCashList[]>([]);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const loadMoveDestinations = async () => {
		try {
			setLoading(true);
			const response = await getDestinationToMoveCashTransaction();
			const destinationsBranchOffices = response.data.branchOffices.map((x) => {
				return { id: x.id, name: x.name } as IDestinationToMoveCashList;
			});
			const destinationsBankAccounts = response.data.bankAccounts.map((x) => {
				return { id: x.id, name: x.name } as IDestinationToMoveCashList;
			});
			const destinations = [...destinationsBranchOffices, ...destinationsBankAccounts];
			setDestinationsToMoveCash(destinations);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los destinos para realizar el movimiento'),
			});
			setLoading(false);
		}
	};

	const onSubmit = async () => {
		try {
			setOpenConfirmModal(false);
			setLoading(true);
			const destinationMove: string = getValues('destinationMove');
			const reg = new RegExp('^[0-9]+$');
			const dataToSend = {
				cashTransactionIds: cashTransactionIds,
				...(reg.test(destinationMove) && { branchOfficeId: Number(destinationMove) }),
				...(!reg.test(destinationMove) && { bankAccountId: destinationMove }),
			} as ICashTransactionMoveCash;
			await moveCashTransaction(dataToSend);
			setLoading(false);
			onClose(true);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al realizar el movimiento'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		loadMoveDestinations();
	}, []);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog
				open={open}
				disableEscapeKeyDown={true}
				aria-labelledby="draggable-dialog-title"
				PaperComponent={PaperComponent}
			>
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					<Typography variant="h6" component={'span'}>
						Mover Cash{' '}
					</Typography>{' '}
					<CloseIcon className={classes.closeButton} onClick={() => onClose(false)}></CloseIcon>
				</DialogTitle>
				<DialogContent>
					<form className={classes.form}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Controller
									name="destinationMove"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Este campo es requerido',
										},
									}}
									render={({ field: { onChange } }) => (
										<FormControl sx={{ minWidth: '350px' }} className={classes.formControl}>
											<Autocomplete
												onChange={(event, item) => {
													onChange(item?.id || '');
												}}
												options={destinationsToMoveCash}
												getOptionLabel={(option: IDestinationToMoveCashList) => option.name}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Destino del movimiento"
														variant="outlined"
														error={!!errors.destinationMove}
														helperText={checkShowErrorMessage(
															Boolean(errors.destinationMove),
															errors.destinationMove?.message,
														)}
													/>
												)}
											/>
										</FormControl>
									)}
								/>
							</Grid>

							<Grid item xs={12} display={'flex'} justifyContent="flex-end">
								<Button
									variant="contained"
									onClick={() => setOpenConfirmModal(true)}
									disabled={!isValid}
									color="success"
									fullWidth
								>
									Realizar movimiento
								</Button>
								<ConfirmationModal
									open={openConfirmModal}
									handleClose={() => setOpenConfirmModal(false)}
									handleAccept={onSubmit}
									message="Confirmar movimiento de efectivo"
								/>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={2000} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
