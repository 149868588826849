import React from 'react';
import { Layout } from '../../features/common/components/Layout';
import IFrameQuotaData from '../../features/quotaData/IFrameQuotaData';

export const QuotaDataReport = (): JSX.Element => {
	return (
		<Layout>
			<IFrameQuotaData />
		</Layout>
	);
};
