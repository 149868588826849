export enum fieldRuleOptions {
	required,
	noshow,
	optional,
}

export enum renderOptions {
	textfield,
	select,
	date,
	phone,
	email,
}

export type CountryValues = 'UY' | 'PY' | 'CO' | 'BO';

export interface FieldData {
	label: string;
	labelJuridico?: string;
	fisicaNoFacturable: fieldRuleOptions;
	fisicaFacturable: fieldRuleOptions;
	juridica: fieldRuleOptions;
	render: renderOptions;
}

export interface PaxFormFields {
	[key: string]: {
		[key in CountryValues]: FieldData;
	};
}
