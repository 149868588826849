import React, { useEffect, useState } from 'react';
import { Alert, AlertColor, Backdrop, CircularProgress, Snackbar, TextField, Typography } from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { automaticRouteSuggestion } from '../../../services';
import { INomenclator, IAutomaticRouteSuggestion } from '../../../types';
import { formatDateTimeToShowUser } from '../../../../common/helpers';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
	select: {
		width: '-webkit-fill-available',
	},
}));

export interface AutomaticRouteSearchProps {
	handleSelectAutomaticRoute: (automaticRouteSuggestion: IAutomaticRouteSuggestion) => void;
}

export const AutomaticRouteSearch = (props: AutomaticRouteSearchProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [searchAutomaticRoute, setSearchAutomaticRoute] = useState<string>('');
	const [searchOptions, setSearchOptions] = useState<IAutomaticRouteSuggestion[]>([]);
	const [automaticRouteSelected, setAutomaticRouteSelected] = useState<IAutomaticRouteSuggestion | null>(null);

	useEffect(() => {
		const fetchApi = async () => {
			try {
				setLoading(true);
				const data = await automaticRouteSuggestion(searchAutomaticRoute);
				data && setSearchOptions(data.data);
				setLoading(false);
			} catch (error: any) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al pedir las rutas'),
				});
				setLoading(false);
			}
		};
		if (searchAutomaticRoute && searchAutomaticRoute.length >= 3) fetchApi();
	}, [searchAutomaticRoute]);

	const handleChangeAutomaticRouteAutocomplete = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchAutomaticRoute(e.target.value);
	};

	const handleAutocompleteChange = (event: React.ChangeEvent<any>, value: IAutomaticRouteSuggestion) => {
		props.handleSelectAutomaticRoute(value);
		setAutomaticRouteSelected(value);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Autocomplete
				options={searchOptions}
				getOptionLabel={(option: INomenclator) => option.name}
				// @ts-ignore
				onChange={handleAutocompleteChange}
				renderInput={(params) => (
					<TextField
						{...params}
						name="package"
						variant="standard"
						required
						label="Nombre de la ruta"
						size="small"
						onChange={handleChangeAutomaticRouteAutocomplete}
						fullWidth
						style={{ marginTop: -3 }}
					/>
				)}
			/>
			{automaticRouteSelected && (
				<div style={{ paddingTop: '10px' }}>
					<Typography variant="body2">
						Última actualización:{' '}
						{automaticRouteSelected?.lastTrackUpdate
							? formatDateTimeToShowUser(automaticRouteSelected?.lastTrackUpdate)
							: '-'}
					</Typography>
					<Typography variant="body2">
						Tiempo máximo a la ida:{' '}
						{automaticRouteSelected?.goingFlightTravelMaxDuration
							? automaticRouteSelected.goingFlightTravelMaxDuration + ' hs'
							: '-'}
					</Typography>
					<Typography variant="body2">
						Tiempo máximo a la vuelta:{' '}
						{automaticRouteSelected?.returnFlightTravelMaxDuration
							? automaticRouteSelected.returnFlightTravelMaxDuration + ' hs'
							: '-'}
					</Typography>
				</div>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
