import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	ButtonGroup,
	CircularProgress,
	Grid,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { pageSizeOptions } from 'constants/constants';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	convertFiltersToRecord,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateTimeToShowUser,
	formatDateToShowUser,
	getEnvelopedListPageTotal,
	getSortableFields,
} from 'features/common/helpers';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	SelectFilterListData,
	SelectFilterModelSimple,
} from 'features/common/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import {
	getWireTransferTransactionList,
	getWireTransferTransactionListToExport,
	refreshWireTransfersTransactions,
} from 'features/transactions/services';
import { IWireTransferTransactionList } from 'features/transactions/types';
import React, { useEffect, useState } from 'react';
import { ExportToExcelButton } from '../../../common/components/ExportToExcelButton';
import { getBanksByCountry, getCurrenciesByCountry, getWireTransferProviders } from '../../../payment/services';
import { BankAccountCountry } from '../../../payment/types';
import SyncIcon from '@mui/icons-material/Sync';
import { ConfirmationModal } from '../../../common/components/ConfirmationModal/ConfirmationModal';
import CommentIcon from '@mui/icons-material/Comment';
import { AddObservationWireTransferModal } from '../../../payment/components/AddObservationWireTransferModal';
import { PageNoResult } from '../../../common/components/PageNoResults';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	btnContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		maxWidth: '90px',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const headerCells: IHeadCell[] = [
	{
		field: 'id',
		sortable: true,
		headerName: '#',
	},
	{
		field: 'provider',
		sortable: true,
		headerName: 'Proveedor',
	},
	{
		field: 'country',
		sortable: true,
		headerName: 'País',
	},
	{
		field: 'bank',
		sortable: true,
		headerName: 'Banco',
	},
	{
		field: 'account',
		sortable: true,
		headerName: 'Cuenta',
	},
	{
		field: 'bankDate',
		sortable: true,
		headerName: 'Fecha en el banco',
	},
	{
		field: 'bankReference',
		sortable: true,
		headerName: 'Referencia bancaria',
	},
	{
		field: 'companyReference',
		sortable: true,
		headerName: 'Referencia Mevuelo',
	},
	{
		field: 'credit',
		sortable: true,
		headerName: 'Crédito',
	},
	{
		field: 'debit',
		sortable: true,
		headerName: 'Débito',
	},
	{
		field: 'currency',
		sortable: true,
		headerName: 'Moneda',
	},
	{
		field: 'creditInUSD',
		sortable: true,
		headerName: 'Crédito en USD',
	},
	{
		field: 'debitInUSD',
		sortable: true,
		headerName: 'Débito en USD',
	},
	{
		field: 'arbitration',
		sortable: true,
		headerName: 'Arbitraje',
	},
	{
		field: 'observations',
		sortable: true,
		headerName: 'Observaciones',
	},
	{
		field: 'createdAt',
		sortable: true,
		headerName: 'Fecha de Creación',
	},
	{
		field: 'changedAt',
		sortable: true,
		headerName: 'Fecha de Modificación',
	},
];

export interface WireTransferTransactionListProps {
	onRefreshInformation: () => void;
}

export const WireTransferTransactionList = ({
	onRefreshInformation,
}: WireTransferTransactionListProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [countriesToShow, setCountriesToShow] = useState<SelectFilterListData[]>([]);
	const [wireTransferProvidersToFilter, setWireTransferProvidersToFilter] = useState<SelectFilterListData[]>([]);
	const [banksToFilter, setBanksToFilter] = useState<SelectFilterListData[]>([]);
	const [currenciesToFilter, setCurrenciesToFilter] = useState<SelectFilterListData[]>([]);
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [wireTransferTransactionsList, setWireTransferTransactionsList] = useState<
		IWireTransferTransactionList[] | null
	>(null);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'createdAt', order: 'desc' }]);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [refreshedInformation, setRefreshedInformation] = useState(false);
	const [rowSelectedToAddObservations, setRowSelectedToAddObservations] = useState<IWireTransferTransactionList | null>(
		null,
	);

	const filters = [
		{ label: 'ID', type: FilterType.STRING, key: 'id' },
		new SelectFilterModelSimple('Proveedor', 'provider', wireTransferProvidersToFilter),
		{
			label: 'Cuenta',
			type: FilterType.STRING,
			key: 'account',
			operator: OperatorFilterEnum.EQUALS,
		},
		new SelectFilterModelSimple('País', 'country', countriesToShow),
		new SelectFilterModelSimple('Banco', 'bank', banksToFilter),
		{
			label: 'Fecha en el banco',
			type: FilterType.DATE,
			key: 'bankDate',
			operator: OperatorFilterEnum.EQUALS,
		},
		new DateToFromFilterModel('La Fecha en el banco', 'bankDateFrom', 'bankDateTo'),
		{
			label: 'Referencia bancaria',
			type: FilterType.STRING,
			key: 'bankReference',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Referencia Mevuelo',
			type: FilterType.STRING,
			key: 'companyReference',
			operator: OperatorFilterEnum.EQUALS,
		},
		{ label: 'Crédito', type: FilterType.NUMERIC, key: 'credit' },
		{
			label: 'Crédito',
			type: FilterType.NUMERIC,
			key: 'creditFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Crédito',
			type: FilterType.NUMERIC,
			key: 'creditTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		{ label: 'Débito', type: FilterType.NUMERIC, key: 'debit' },
		{
			label: 'Débito',
			type: FilterType.NUMERIC,
			key: 'debitFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Débito',
			type: FilterType.NUMERIC,
			key: 'debitTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		new SelectFilterModelSimple('Moneda', 'currency', currenciesToFilter),
		{ label: 'Crédito en USD', type: FilterType.NUMERIC, key: 'creditInUSD' },
		{
			label: 'Crédito en USD',
			type: FilterType.NUMERIC,
			key: 'creditInUSDFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Crédito en USD',
			type: FilterType.NUMERIC,
			key: 'creditInUSDTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		{ label: 'Débito  en USD', type: FilterType.NUMERIC, key: 'debitInUSD' },
		{
			label: 'Débito  en USD',
			type: FilterType.NUMERIC,
			key: 'debitInUSDFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Débito  en USD',
			type: FilterType.NUMERIC,
			key: 'debitInUSDTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		{ label: 'Arbitraje', type: FilterType.NUMERIC, key: 'arbitration' },
		{
			label: 'Observaciones ',
			type: FilterType.STRING,
			key: 'observations',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{ label: 'Fecha de Creación', type: FilterType.DATE, key: 'createdAt' },
		new DateToFromFilterModel('La Fecha de Creación', 'createdAtFrom', 'createdAtTo'),
		{ label: 'Fecha de Modificación', type: FilterType.DATE, key: 'changedAt' },
		new DateToFromFilterModel('La Fecha de Modificación', 'changedAtFrom', 'changedAtTo'),
	];

	const getWireTransferTransactionListToClient = async () => {
		try {
			setLoading(true);
			const envelopedTransactionList = (
				await getWireTransferTransactionList(
					currentPage - 1,
					currentSize,
					sortFields,
					convertFiltersToRecord(filterApplied),
				)
			).data;
			setPageTotal(getEnvelopedListPageTotal(envelopedTransactionList));
			setWireTransferTransactionsList([...envelopedTransactionList.data]);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const getCurrenciesByCountryAtLoad = async () => {
		try {
			setLoading(true);
			const currenciesByCountry = (await getCurrenciesByCountry()).data;
			const allCurrencies: string[] = [];
			const allCountries: BankAccountCountry[] = [];
			currenciesByCountry.forEach((currency) => {
				currency.currencies.forEach((x) => allCurrencies.push(x));
				allCountries.push(currency.country);
			});

			setCurrenciesToFilter(
				Array.from(new Set(allCurrencies))
					.sort()
					.map((x) => ({ id: x, name: x })),
			);
			setCountriesToShow(
				Array.from(new Set(allCountries))
					.sort()
					.map((x) => ({ id: x, name: x })),
			);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir las Monedas'),
			});
			setLoading(false);
		}
	};

	const getProvidersAtLoad = async () => {
		try {
			setLoading(true);
			const wireTransferProviders = (await getWireTransferProviders()).data;
			setWireTransferProvidersToFilter(wireTransferProviders.map((x) => ({ id: x, name: x })));
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir los proveedores'),
			});
			setLoading(false);
		}
	};

	const getBanksByCountryAtLoad = async () => {
		try {
			setLoading(true);
			const banksByCountry = (await getBanksByCountry()).data;
			const allBanksToShowInFilter: string[] = [];
			banksByCountry.forEach((bankByCountry) => {
				bankByCountry.banks.forEach((x) => allBanksToShowInFilter.push(x));
			});
			setBanksToFilter(
				Array.from(new Set(allBanksToShowInFilter))
					.sort()
					.map((x) => ({ id: x, name: x })),
			);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los Bancos'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getWireTransferTransactionListToClient();
	}, [filterApplied, sortFields, currentPage, currentSize, refreshedInformation]);

	useEffect(() => {
		getCurrenciesByCountryAtLoad();
	}, []);

	useEffect(() => {
		getBanksByCountryAtLoad();
	}, []);
	useEffect(() => {
		getProvidersAtLoad();
	}, []);

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const sortableFields = getSortableFields(headerCells);

	const transformValue = (key: string, value: any, row: IWireTransferTransactionList) => {
		switch (key) {
			case 'createdAt':
				return formatDateTimeToShowUser(row.createdAt);
			case 'changedAt':
				return formatDateTimeToShowUser(row.changedAt);
			case 'bankDate':
				return formatDateToShowUser(row.bankDate);
			case 'companyReference':
				return row.companyReference ? row.companyReference : '-';
			case 'observations':
				return row.observations ? row.observations : '-';

			default:
				return value;
		}
	};

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const envelopedWireTransferTransactionList = (
				await getWireTransferTransactionListToExport(sortFields, convertFiltersToRecord(filterApplied))
			).data;
			const wireTransferList = envelopedWireTransferTransactionList.data;
			const optionsToExport = {
				title: 'Listado de transferencias bancarias',
				headers: headerCells,
				widthColumns: [10, 15, 15, 15, 25, 20, 50, 50, 15, 15, 10, 15, 15, 20, 50, 20, 20],
				filename: 'Listado de transferencias bancarias',
			} as ExportExcelOptions;
			exportToExcel(wireTransferList, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const handleRefreshWireTransfer = () => {
		setShowConfirmation(true);
	};
	const handleAcceptRefresh = async () => {
		try {
			handleCancelRefresh();
			setLoading(true);
			await refreshWireTransfersTransactions();
			setLoading(false);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Información actualizada satisfactoriamente',
			});
			setRefreshedInformation(!refreshedInformation);
			onRefreshInformation();
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al refrescar las transacciones'),
			});
			setLoading(false);
		}
	};

	const handleCancelRefresh = () => setShowConfirmation(false);

	const handleAddObservations = (row: IWireTransferTransactionList) => setRowSelectedToAddObservations(row);

	const handleCloseAddObservations = () => setRowSelectedToAddObservations(null);
	const handleAcceptAddObservations = async () => {
		handleCloseAddObservations();
		await getWireTransferTransactionListToClient();
	};

	const colSpan = headerCells.length;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1}>
				<Grid container spacing={2}>
					<Grid item xs={11}>
						<FilterGrid filters={filters} handleAppliedFilters={handleApplyFilters}></FilterGrid>
					</Grid>
					<Grid item xs={1}>
						<ButtonGroup className={classes.btnContainer}>
							<ExportToExcelButton
								disabled={wireTransferTransactionsList?.length == 0}
								handleExportToExcel={handleExportToExcel}
							/>
							<Button
								disabled={loading}
								variant="contained"
								size="small"
								startIcon={<SyncIcon />}
								sx={{ height: 40, width: 30 }}
								title={'Refrescar  información desde los proveedores'}
								onClick={handleRefreshWireTransfer}
							/>
						</ButtonGroup>
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
									<TableCell className={classes.tableHeader}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && wireTransferTransactionsList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									wireTransferTransactionsList?.map((wireTransferTransaction) => (
										<TableRow key={`${wireTransferTransaction.id}-${wireTransferTransaction.provider}`}>
											<TableCell align="right" component="p">
												{wireTransferTransaction.id}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.provider}
											</TableCell>
											<TableCell align="right" component="p">
												{wireTransferTransaction.country}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.bank}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.account}
											</TableCell>
											<TableCell align="right" component="p">
												{transformValue('bankDate', wireTransferTransaction.bankDate, wireTransferTransaction)}
											</TableCell>
											<TableCell align="right" component="p">
												{wireTransferTransaction.bankReference}
											</TableCell>
											<TableCell align="center" component="p">
												{transformValue(
													'companyReference',
													wireTransferTransaction?.companyReference,
													wireTransferTransaction,
												)}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.credit}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.debit}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.currency}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.creditInUSD}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.debitInUSD}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.arbitration}
											</TableCell>
											<TableCell align="center" component="p">
												{wireTransferTransaction.observations}
											</TableCell>
											<TableCell align="center" component="p">
												{transformValue('createdAt', wireTransferTransaction.createdAt, wireTransferTransaction)}
											</TableCell>
											<TableCell align="center" component="p">
												{transformValue('changedAt', wireTransferTransaction.changedAt, wireTransferTransaction)}
											</TableCell>
											<TableCell align="right" component="p">
												<CommentIcon
													cursor={'pointer'}
													color="primary"
													titleAccess={`${wireTransferTransaction?.observations}? 'Actualizar': 'Adicionar' observaciones`}
													onClick={() => handleAddObservations(wireTransferTransaction)}
												/>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={6} marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{showConfirmation && (
				<ConfirmationModal
					message={'Está seguro de refrescar la información de transacciones desde Infimia?'}
					open={showConfirmation}
					handleAccept={handleAcceptRefresh}
					handleClose={handleCancelRefresh}
				/>
			)}

			{rowSelectedToAddObservations && (
				<AddObservationWireTransferModal
					row={rowSelectedToAddObservations}
					handleClose={handleCloseAddObservations}
					handleAccept={handleAcceptAddObservations}
				/>
			)}

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
