import { IOperators } from 'features/transactions/types';
import { CrossStateEnum, crossStateList } from './types';

export function getCrossStateName(state: CrossStateEnum): string {
	const maybeElement = crossStateList.find((element) => element.id === state);
	return maybeElement ? maybeElement.name : '';
}

export function getCrossOperatorName(operators: IOperators[], operatorId: string): string {
	const operator = operators.find((operator) => operator.value === operatorId);
	return operator ? operator.description : '';
}
