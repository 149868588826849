import * as React from 'react';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
import moment from 'moment';
import { isValidDate } from '../../../quotation/helpers';
import {
	IPackageExplorerDatesFilterValueContainer,
	IPackageExplorerDatesFilterValueExactDates,
	IPackageExplorerDatesFilterValueMonth,
} from '../../types';
import { useTheme } from '@mui/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { DateType } from '../../../common/types';
import { fillMonthsWithYearForExplorer, formatDateToShowUser } from '../../../common/helpers';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

interface Props {
	open: boolean;
	onClose: (value: IPackageExplorerDatesFilterValueContainer) => void;
	value: IPackageExplorerDatesFilterValueContainer;
}

export const PackageExplorerDatesFilterModal = ({ open, onClose, value }: Props): JSX.Element => {
	const [tabSelected, setTabSelected] = useState(value?.valueType ?? 0);
	const [title, setTitle] = useState('Cualquier fecha');
	const monthsWithYear = fillMonthsWithYearForExplorer();
	const [valueInMonthSelected, setValueInMonthSelected] = useState(
		value?.valueType == 0 ? (value.value as IPackageExplorerDatesFilterValueMonth).travelInMonth : 0,
	);
	const [dateFrom, setDateFrom] = useState<string | null>(
		value?.valueType == 1 ? (value.value as IPackageExplorerDatesFilterValueExactDates).travelDateFrom : '',
	);
	const [dateTo, setDateTo] = useState<string | null>(
		value?.valueType == 1 ? (value.value as IPackageExplorerDatesFilterValueExactDates).travelDateTo : '',
	);
	const [validDateFrom, setValidDateFrom] = useState(true);
	const [validDateTo, setValidDateTo] = useState(true);
	const theme = useTheme();

	useEffect(() => {
		if (tabSelected == 1) {
			if (dateFrom == '') setValidDateFrom(false);
			if (dateTo == '') setValidDateTo(false);
			if (dateFrom && dateTo) {
				setTitle(`Viajando entre ${formatDateToShowUser(dateFrom)} y ${formatDateToShowUser(dateTo)}`);
			}
		} else {
			monthsWithYear.length > 0 &&
				handleSelectMonth(
					valueInMonthSelected,
					monthsWithYear.findIndex((item) => item.value == valueInMonthSelected),
				);
		}
	}, [dateFrom, dateTo, tabSelected]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabSelected(newValue);
	};
	const handleSelectMonth = (month: number, index: number) => {
		const titleToSet = month == 0 ? 'Cualquier fecha' : `Viajando en ${monthsWithYear[index].label}`;
		setTitle(titleToSet);
		setValueInMonthSelected(month);
	};

	const getDateFrom = () => {
		if (dateFrom) {
			const momentObj = moment(dateFrom, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};
	const getDateTo = () => {
		if (dateTo) {
			const momentObj = moment(dateTo, 'YYYY-MM-DD');
			return momentObj.toDate();
		} else {
			return null;
		}
	};

	const handleDateChange = (dateValue: unknown, option: DateType) => {
		const value =
			dateValue && isValidDate(dateValue as Date)
				? (dateValue as Date).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0]
				: '';

		switch (option) {
			case DateType.DATEFROM:
				value && setDateFrom(value);
				setValidDateFrom(value != '');
				break;
			case DateType.DATETO:
				console.log(value);
				value && setDateTo(value);
				setValidDateTo(value != '');
				break;
			default:
				break;
		}
	};

	const getValue = (): IPackageExplorerDatesFilterValueContainer | null => {
		if (tabSelected == 0) {
			return {
				valueType: 0,
				title: title,
				value: {
					travelInMonth: valueInMonthSelected,
				},
			};
		} else {
			if (!validDateFrom || !validDateTo) {
				return null;
			}
			return {
				valueType: 1,
				title: title,
				value: {
					travelDateFrom: dateFrom || '',
					travelDateTo: dateTo || '',
				},
			};
		}
	};
	const handleApply = () => {
		const value = getValue();
		if (value) {
			onClose(value);
		}
	};

	return (
		<Dialog onClose={onClose} open={open} maxWidth="md">
			<DialogTitle>
				<DateRangeIcon /> {title}
			</DialogTitle>
			<DialogContent>
				<Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={tabSelected}
						onChange={handleChange}
						aria-label="Vertical tabs example"
						sx={{ borderRight: 1, borderColor: 'divider' }}
					>
						<Tab label="Rango de fechas" {...a11yProps(0)} />
						<Tab label="Fechas exactas" {...a11yProps(1)} />
					</Tabs>
					<TabPanel value={tabSelected} index={0}>
						<Typography component={'h1'}>Rango</Typography>
						<FormControl>
							<RadioGroup name="radio-buttons-group" value={valueInMonthSelected}>
								<Grid container spacing={1} maxWidth={'400px'}>
									<Grid item xs={12}>
										<FormControlLabel
											value={0}
											control={<Radio onClick={() => handleSelectMonth(0, -1)} />}
											label={
												<span style={{ color: theme.palette.primary.main, fontSize: '13px' }}> Cualquier fecha</span>
											}
										/>
									</Grid>
									{monthsWithYear.map((item, index) => (
										<Grid item xs={4} key={`${item.label}`}>
											<FormControlLabel
												value={item.value}
												control={<Radio onClick={() => handleSelectMonth(item.value, index)} />}
												label={
													<span
														style={{
															color: theme.palette.primary.main,
															fontSize: '13px',
														}}
													>
														{' '}
														{item.label}
													</span>
												}
											/>
										</Grid>
									))}
								</Grid>
							</RadioGroup>
						</FormControl>
					</TabPanel>
					<TabPanel value={tabSelected} index={1}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								inputFormat="dd/MM/yyyy"
								renderInput={(params) => (
									<TextField {...params} sx={{ width: '100%' }} label="Desde" variant="standard" />
								)}
								value={getDateFrom()}
								minDate={new Date()}
								onChange={(date) => handleDateChange(date, DateType.DATEFROM)}
								OpenPickerButtonProps={{
									'aria-label': 'cambiar fecha',
								}}
							/>
							<div></div>
							<DatePicker
								inputFormat="dd/MM/yyyy"
								minDate={getDateFrom()}
								renderInput={(params) => (
									<TextField {...params} sx={{ width: '100%' }} label="Hasta" variant="standard" />
								)}
								value={getDateTo()}
								onChange={(date) => handleDateChange(date, DateType.DATETO)}
								OpenPickerButtonProps={{
									'aria-label': 'cambiar fecha',
								}}
							/>
						</LocalizationProvider>
					</TabPanel>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					style={{ marginRight: 15 }}
					color="primary"
					disabled={getValue() == null}
					onClick={handleApply}
				>
					Aplicar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
