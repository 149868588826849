import React from 'react';
import { Layout } from 'features/common/components/Layout';
import Component from 'features/agency/components/AgencyForm';
import { useParams } from 'react-router-dom';

function AgencyUpdate(): JSX.Element {
	const { id } = useParams<{ id: string }>();
	return (
		<Layout>
			<Component id={+id} />
		</Layout>
	);
}

export default AgencyUpdate;
