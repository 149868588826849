import React from 'react';
import { Container, Grid } from '@mui/material';
import lupaError404 from 'assets/LupaError404.png';

export const PageNoResult = (): JSX.Element => {
	return (
		<>
			<Container
				component="main"
				sx={{ width: '400px', display: 'block', justifyContent: 'center', textAlign: 'center' }}
			>
				<Grid sx={{ mb: 1 }}>
					<img src={lupaError404} alt="Error 404" />
				</Grid>
				<p style={{ font: 'Montserrat', fontSize: '20px' }}>¡Ups!</p>
				<p style={{ color: '#00000099', font: 'Montserrat', fontSize: '16px' }}>
					No se encontraron resultados, por favor refine su búsqueda.{' '}
				</p>
				<Grid style={{ display: 'flex', justifyContent: 'center' }}></Grid>
			</Container>
		</>
	);
};
