import React from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { ChildrenClass, IHotelSearchForm } from '../../types';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { maxPeoplePerRoom } from '../../constants';

export interface HotelRoomFieldProps {
	roomIndex: number;
}

export const HotelChildFields = ({ roomIndex }: HotelRoomFieldProps): JSX.Element => {
	const { control, setValue, getValues } = useFormContext<IHotelSearchForm>();
	//It's like an useEffect that triggers re-render if this fieldValue changes
	useWatch({
		control,
		name: `rooms.${roomIndex}.adults` as 'rooms.0.adults',
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: `rooms.${roomIndex}.childrenList` as 'rooms.0.childrenList',
	});

	const handleSelectChildren = (
		event: SelectChangeEvent<number>,
		roomIndex: number,
		onChange: (...event: any[]) => void,
	) => {
		setValue(`rooms.${roomIndex}.childrenNumber` as 'rooms.0.childrenNumber', +event.target.value);
		const oldArray = getValues(`rooms.${roomIndex}.childrenList` as 'rooms.0.childrenList');
		if (oldArray.length > 0 && oldArray.length > +event.target.value) {
			const indexesToDelete = Array.from({ length: oldArray.length }, (_, i) => i).slice(+event.target.value);
			remove(+event.target.value === 0 ? undefined : indexesToDelete);
		} else {
			append(
				Array.from(Array(+event.target.value - oldArray.length), () => {
					return new ChildrenClass(0);
				}),
			);
		}
		onChange(+event.target.value);
	};

	const handleSelectChildAge = (
		event: SelectChangeEvent<number>,
		roomIndex: number,
		childIndex: number,
		onChange: (...event: any[]) => void,
	) => {
		setValue(`rooms.${roomIndex}.childrenList.${childIndex}.age` as 'rooms.0.childrenList.0.age', +event.target.value);
		onChange(+event.target.value);
	};

	return (
		<React.Fragment>
			<Grid item xs={12} md={1}>
				<Controller
					name={`rooms.${roomIndex}.childrenNumber` as const}
					control={control}
					rules={{
						required: { value: true, message: 'Este campo es requerido' },
					}}
					render={({ field: { value, onChange } }) => (
						<FormControl fullWidth>
							<InputLabel id={`children-select-${roomIndex + 1}-label`}>Niños</InputLabel>
							<Select
								value={value}
								disabled={maxPeoplePerRoom === getValues(`rooms.${roomIndex}.adults` as 'rooms.0.adults')}
								label="Niños"
								fullWidth
								onChange={(e) => handleSelectChildren(e, roomIndex, onChange)}
							>
								{Array.from(
									Array(maxPeoplePerRoom - getValues(`rooms.${roomIndex}.adults` as 'rooms.0.adults') + 1),
									(e, i) => {
										return (
											<MenuItem key={`children-room-${roomIndex}-${i}`} value={i}>
												{i}
											</MenuItem>
										);
									},
								)}
							</Select>
						</FormControl>
					)}
				/>
			</Grid>
			{fields.map((child, index) => (
				<Grid key={`rooms.${roomIndex}.childrenList.${index}`} item xs={12} md={1}>
					<Controller
						control={control}
						name={`rooms.${roomIndex}.childrenList.${index}.age` as const}
						render={({ field: { value, onChange } }) => (
							<FormControl key={`room-${roomIndex}.child-.${index}-age`} fullWidth>
								<InputLabel id={`select-room-${roomIndex}-age-child-${index}`}>Edad Niño {index + 1}</InputLabel>
								<Select
									value={value}
									label={`Edad Niño ${index + 1}`}
									fullWidth
									onChange={(e) => handleSelectChildAge(e, roomIndex, index, onChange)}
								>
									{Array.from(Array(5), (e, i) => {
										return (
											<MenuItem key={`room-select-${roomIndex}-child-age-option-${i}`} value={i}>
												{i}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						)}
					/>
				</Grid>
			))}
		</React.Fragment>
	);
};
