import React from 'react';
import { IFlightExplorerDestination } from '../../types';
import { Container, Grid } from '@mui/material';
import { FlightExplorerDestinationCard } from '../FlightExplorerDestinationCard';

interface Props {
	destinations: IFlightExplorerDestination[];
	handleDestinationCardMouseEnter: (destination: IFlightExplorerDestination) => void;
	handleDestinationCardMouseLeave: () => void;
	handleDestinationCardClick: (destination: IFlightExplorerDestination) => void;
}

export const FlightExplorerDestinationList = ({
	destinations,
	handleDestinationCardMouseEnter,
	handleDestinationCardMouseLeave,
	handleDestinationCardClick,
}: Props): JSX.Element => {
	return (
		<Container
			component="main"
			maxWidth={'lg'}
			sx={{
				'padding-top': '15px',
				marginBottom: '15px',
				'max-height': '90vh',
				'overflow-y': 'auto',
			}}
		>
			<Grid container spacing={2} pt={2}>
				{destinations.map((destination) => (
					<Grid
						item
						xs={12}
						md={21}
						key={`flight-explorer-destination-${destination.code}`}
						onMouseEnter={() => handleDestinationCardMouseEnter(destination)}
						onMouseLeave={handleDestinationCardMouseLeave}
					>
						<FlightExplorerDestinationCard
							destination={destination}
							handleDestinationCardClick={handleDestinationCardClick}
						/>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};
