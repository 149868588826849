import {
	Autocomplete,
	Card,
	Checkbox,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import LastSearchIcon from '@mui/icons-material/History';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { isValidDate } from '../../../quotation/helpers';
import { maxPeoplePerRoom } from '../../constants';
import { HotelRoomClass, IHotelSearchForm } from '../../types';
import { Controller, FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { HotelChildFields } from './HotelChildFields';
import { FilterList } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { checkShowErrorMessage } from '../../../common/helpers';
import { datePickerFormat } from '../../../common/types';
import { SideBar } from '../FiltersSidebar';

const HotelSearchCardDefaultValues = {
	destination: '',
	checkIn: undefined,
	checkOut: undefined,
	nights: 1,
	roomAmount: 1,
	rooms: [
		{
			adults: 1,
			childrenNumber: 1,
			childrenList: [
				{
					age: 0,
				},
			],
		},
	],
	packagingRate: false,
};

const cityAutocompleteOptions = [
	{ label: 'Praga', code: 'PRA' },
	{ label: 'Ciudad de México', code: 'MX' },
	{ label: 'Montevideo', code: 'MX' },
];

export const HotelSearchCard = (): JSX.Element => {
	const theme = useTheme();
	const methods = useForm<IHotelSearchForm>({
		defaultValues: HotelSearchCardDefaultValues,
		mode: 'all',
	});
	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		formState: { errors },
	} = methods;
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'rooms',
	});

	const onSubmit = (data: FieldValues) => {
		console.log(JSON.stringify(data));
	};

	const handleEntryDateChange = (dateValue: unknown, onChange: (...event: any[]) => void) => {
		const value =
			dateValue && isValidDate(dateValue as Date)
				? (dateValue as Date).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0]
				: '';
		setValue('checkIn', value);
		onChange(value);
	};
	const handleExitDateChange = (dateValue: unknown, onChange: (...event: any[]) => void) => {
		const value =
			dateValue && isValidDate(dateValue as Date)
				? (dateValue as Date).toLocaleString('sv', { timeZoneName: 'short' }).split(' ')[0]
				: '';
		setValue('checkOut', value);
		onChange(value);
	};

	const handleSelectNumberOfRoomsChange = (newNumber: number, onChange: (...event: any[]) => void) => {
		if (newNumber > 0) {
			setValue('roomAmount', newNumber);
			const oldArray = getValues('rooms');
			if (oldArray.length > 0 && oldArray.length > newNumber) {
				const indexesToDelete = Array.from({ length: oldArray.length }, (_, i) => i).slice(newNumber);
				remove(indexesToDelete);
			} else {
				append(
					Array.from(Array(newNumber - oldArray.length), () => {
						return new HotelRoomClass(1, 0, []);
					}),
				);
			}
			onChange(newNumber);
		}
	};

	const handleSelectAdults = (
		event: SelectChangeEvent<number>,
		roomIndex: number,
		onChange: (...event: any[]) => void,
	) => {
		setValue(`rooms.${roomIndex}.adults` as 'rooms.0.adults', +event.target.value);
		onChange(+event.target.value);
	};

	return (
		<Card sx={{ width: '100%' }}>
			<FormProvider {...methods}>
				<form noValidate onSubmit={handleSubmit(onSubmit)} style={{ marginRight: 130 }}>
					<Grid container spacing={2} padding={2}>
						<Grid item xs={12}>
							<Controller
								name="destination"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										options={cityAutocompleteOptions}
										popupIcon={<FilterList />}
										isOptionEqualToValue={(e, data) => data.code == value}
										onChange={(_, data) => {
											onChange(data?.code);
											return data?.code;
										}}
										fullWidth
										renderInput={(params) => (
											<TextField
												label="Destino, zona o nombre del hotel"
												required
												helperText={checkShowErrorMessage(Boolean(errors.destination), errors.destination?.message)}
												error={Boolean(errors.destination)}
												variant="outlined"
												placeholder="ej. Praga"
												sx={{ width: '100%', svg: { color: theme.palette.secondary.main } }}
												{...params}
											/>
										)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button variant="outlined" size="small" startIcon={<LastSearchIcon />}>
								Última Busqueda
							</Button>
						</Grid>
						<Grid item xs={12} md={5}>
							<Controller
								name="checkIn"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								render={({ field: { value, onChange } }) => (
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											inputFormat={datePickerFormat}
											renderInput={(params) => (
												<TextField
													{...params}
													required
													helperText={checkShowErrorMessage(Boolean(errors.checkIn), errors.checkIn?.message)}
													error={Boolean(errors.checkIn)}
													label="Entrada"
													sx={{ width: '100%', svg: { color: theme.palette.secondary.main } }}
													variant="outlined"
												/>
											)}
											value={value}
											onChange={(date) => handleEntryDateChange(date, onChange)}
											OpenPickerButtonProps={{
												'aria-label': 'cambiar fecha',
											}}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<Controller
								name="checkOut"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								render={({ field: { value, onChange } }) => (
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											inputFormat={datePickerFormat}
											renderInput={(params) => (
												<TextField
													{...params}
													required
													helperText={checkShowErrorMessage(Boolean(errors.checkOut), errors.checkOut?.message)}
													error={Boolean(errors.checkOut)}
													label="Salida"
													sx={{ width: '100%', svg: { color: theme.palette.secondary.main } }}
													variant="outlined"
												/>
											)}
											value={value}
											onChange={(date) => handleExitDateChange(date, onChange)}
											OpenPickerButtonProps={{
												'aria-label': 'cambiar fecha',
											}}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Controller
								name="nights"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										InputProps={{
											inputProps: {
												min: 1,
											},
										}}
										type="number"
										label="Noches"
										sx={{ width: '100%', svg: { color: theme.palette.secondary.main } }}
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Controller
								name="roomAmount"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								render={({ field: { value, onChange } }) => (
									<FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row' }}>
										<IconButton onClick={() => handleSelectNumberOfRoomsChange(value - 1, onChange)}>
											<RemoveIcon />
										</IconButton>
										<TextField
											required
											helperText={checkShowErrorMessage(Boolean(errors.roomAmount), errors.roomAmount?.message)}
											error={Boolean(errors.roomAmount)}
											InputProps={{
												inputProps: {
													min: 1,
												},
												readOnly: true,
											}}
											value={value}
											onChange={(e) => handleSelectNumberOfRoomsChange(+e.target.value, onChange)}
											label="Habitaciones"
											variant="outlined"
										/>
										<IconButton onClick={() => handleSelectNumberOfRoomsChange(value + 1, onChange)}>
											<AddIcon />
										</IconButton>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} sx={{ overflowY: 'scroll', maxHeight: '50vh' }}>
							{fields.map((room, roomIndex) => (
								<React.Fragment key={`rooms-${roomIndex}`}>
									<Typography>Habitacion {roomIndex + 1}</Typography>
									<Grid container sx={{ width: '100%' }} spacing={1} padding={1}>
										<Grid item xs={12} md={1}>
											<Controller
												name={`rooms.${roomIndex}.adults` as const}
												control={control}
												rules={{
													required: { value: true, message: 'Este campo es requerido' },
												}}
												render={({ field: { value, onChange } }) => (
													<FormControl fullWidth>
														<InputLabel id={`adults-select-${roomIndex + 1}-label`}>Adultos</InputLabel>
														<Select
															value={value}
															label="Adultos"
															fullWidth
															onChange={(e) => handleSelectAdults(e, roomIndex, onChange)}
														>
															{Array.from(Array(maxPeoplePerRoom), (e, i) => {
																return (
																	<MenuItem key={`adults-room-${roomIndex}-${i + 1}`} value={i + 1}>
																		{i + 1}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												)}
											/>
										</Grid>

										<HotelChildFields roomIndex={roomIndex} />
									</Grid>
								</React.Fragment>
							))}
						</Grid>
						<Grid item xs={10}>
							<Grid item xs={12} md={2}>
								<Controller
									name="packagingRate"
									control={control}
									render={({ field }) => (
										<span
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Checkbox {...field} color="secondary" />
											<Typography width="100%">Tarifa para empaquetar</Typography>
										</span>
									)}
								/>
							</Grid>
						</Grid>
						<Grid item xs={2}>
							<Button variant="contained" color="secondary" fullWidth type="submit">
								Buscar
							</Button>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
			<SideBar />
		</Card>
	);
};
