import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Layout } from 'features/common/components/Layout';
import { SalesOrderCreateForm } from '../../features/salesOrder/components/SaleOrderCreateForm';

export const SalesOrderCreatePage = (): JSX.Element => {
	return (
		<Layout>
			<Grid container paddingY={5} justifyContent={'center'}>
				<Grid item xs={12} display="flex" justifyContent={'center'}>
					<Typography variant="h6">Sales Order</Typography>
				</Grid>
				<Grid paddingY={5}>
					<SalesOrderCreateForm />
				</Grid>
			</Grid>
		</Layout>
	);
};
