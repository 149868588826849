import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import minturCo from '../../../../assets/colombiaTravel.png';
import whiteLogo from '../../../../assets/mevuelo-blanco.svg';
import qrPy from '../../../../assets/qrRealConsulting.png';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

const WhiteTextTypography = withStyles({
	root: {
		color: '#FFFFFF',
	},
})(Typography);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
		},
		container: {
			backgroundColor: theme.palette.primary.main,
			borderRadius: '12px',
			paddingTop: theme.spacing(4),
		},
		whiteIcon: {
			color: '#FFFFFF',
		},
		qr: {
			width: '120px',
		},
		minTur: {
			width: '40px',
			margin: '8px',
		},
		noUnderlineLinks: {
			textDecoration: 'none',
		},
		overflowWrap: {
			overflowWrap: 'anywhere',
		},
	}),
);

const MevueloCoFooter = (): JSX.Element => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="md">
				<Box className={classes.container}>
					<WhiteTextTypography variant="h6" align="center">
						CONTACTANOS
					</WhiteTextTypography>
					<Box paddingTop={2} display="flex" justifyContent="space-evenly">
						<a className={classes.noUnderlineLinks} href="tel:018005190785">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={90}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<LocalPhoneIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									01 800 519 0 785
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="http://wa.me/573142926276" target="_blank" rel="noreferrer">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={90}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<WhatsAppIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									314 292 6276
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://goo.gl/maps/AbFYcVaSQ67MjwwJ9"
							target="_blank"
							rel="noreferrer"
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={90}
								marginLeft={2}
								marginRight={2}
								marginTop={3}
							>
								<LocationOnIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									{' '}
									Calle 98 n 9 A 41
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle2" align="center">
									Chapinero, Bogotá
								</WhiteTextTypography>
							</Box>
						</a>
					</Box>
					<Box display="flex" justifyContent="space-around" alignItems="center" paddingTop={4} paddingBottom={6}>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.facebook.com/mevuelo.co/"
							target="_blank"
							rel="noreferrer"
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={90}
								className={classes.overflowWrap}
							>
								<FacebookIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.co
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.instagram.com/mevuelo.co/?hl=es-la"
							target="_blank"
							rel="noreferrer"
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={90}
								className={classes.overflowWrap}
							>
								<InstagramIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.co
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="mailto:hola.co@mevuelo.com">
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={90}
								className={classes.overflowWrap}
							>
								<EmailIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									hola.co@mevuelo.com
								</WhiteTextTypography>
							</Box>
						</a>
						<a
							className={classes.noUnderlineLinks}
							href="https://www.linkedin.com/company/mevuelo/"
							target="_blank"
							rel="noreferrer"
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								width={90}
								className={classes.overflowWrap}
							>
								<LinkedInIcon className={classes.whiteIcon} fontSize="large" />
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo
								</WhiteTextTypography>
							</Box>
						</a>
					</Box>
					<WhiteTextTypography variant="subtitle1" align="center">
						AGENCIA DE VIAJES REGISTRADA:
					</WhiteTextTypography>
					<Box paddingTop={4} display="flex" justifyContent="space-evenly" alignItems="center">
						<Box display="flex" flexDirection="column" alignItems="center">
							<WhiteTextTypography variant="subtitle2" align="center">
								MEVUELO S.A.S.
							</WhiteTextTypography>
							<WhiteTextTypography variant="subtitle2" align="center">
								Resolución REGISTUR Nº 1263/2019
							</WhiteTextTypography>
						</Box>
						<a
							className={classes.noUnderlineLinks}
							href="https://registur.gov.py/registur/verificacion?qr=e4e4f7c8442ad196282fb79b77bd6abe"
							target="_blank"
							rel="noreferrer"
						>
							<img className={classes.qr} src={qrPy} alt="qr" />
						</a>
						<img className={classes.minTur} src={minturCo} alt="ministerio" />
					</Box>
					<Box display="flex" justifyContent="space-evenly" paddingTop={6} paddingBottom={6} alignItems="end">
						<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com" target="_blank" rel="noreferrer">
							<Box display="flex" flexDirection="column" alignItems="center">
								<WhiteTextTypography variant="subtitle2" align="center">
									mevuelo.com está presente en:{' '}
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle1" align="center">
									Colombia, Uruguay, Paraguay y Bolivia{' '}
								</WhiteTextTypography>
							</Box>
						</a>
						<a className={classes.noUnderlineLinks} href="https://www.mevuelo.com.py" target="_blank" rel="noreferrer">
							<Box>
								<img src={whiteLogo} alt="logo" />
							</Box>
						</a>
					</Box>
				</Box>
			</Container>
		</div>
	);
};
export default MevueloCoFooter;
