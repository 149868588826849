import React, { useEffect, useState } from 'react';
import { Alert, AlertColor, Backdrop, CircularProgress, Snackbar, useTheme } from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getMonthName } from '../../../common/helpers';
import { DataToChart, IPackageDetail } from '../../types';
import { retrievePackageDetail } from '../../services';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
	select: {
		width: '-webkit-fill-available',
	},
	container: {
		height: '200 px',
	},
}));

export interface PackageDataProps {
	packageId: number;
}

export const PackageData = ({ packageId }: PackageDataProps): JSX.Element => {
	const theme = useTheme();
	const classes = useStyles();
	const [details, setDetails] = useState<IPackageDetail>();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [dataToChart, setDataToChart] = useState<DataToChart | null>(null);

	const getPackagesDetails = async () => {
		try {
			setLoading(true);
			const packagesDetails = (await retrievePackageDetail(packageId)).data;
			setDetails(packagesDetails);
			setLoading(false);
			setDataToChart(null);
			const labels: string[] = [];
			const prices: number[] = [];
			packagesDetails.pricesByMonth.forEach((value) => {
				if (value) {
					labels.push(getMonthName(value.month));
					prices.push(value.price.amount);
				}
			});
			setDataToChart({ labels: labels, prices: prices });
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir los Paquetes'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getPackagesDetails();
	}, [packageId]);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: true,
				text: 'El menor precio de cada mes:',
			},
		},
		maintainAspectRatio: false,
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{details && details.pricesByMonth.length > 0 ? (
				<div className={classes.container}>
					<Bar
						options={options}
						data={{
							labels: dataToChart?.labels,
							datasets: [
								{
									label: 'Tarifas por Adulto',
									data: dataToChart?.prices,
									hoverBorderWidth: 5,
									backgroundColor: theme.palette.primary.main,
								},
							],
						}}
					/>
				</div>
			) : (
				'No tenemos datos de este paquete'
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
