import React from 'react';

const quotaDataUrl = 'https://lookerstudio.google.com/embed/reporting/f0731a91-047c-4845-919e-71c20192108d/page/6tpMD';

export default function IFrameQuotaData(): JSX.Element {
	return (
		<iframe
			style={{ position: 'absolute', height: '90%', border: 0 }}
			allowFullScreen
			width="100%"
			frameBorder="0"
			src={quotaDataUrl}
		/>
	);
}
