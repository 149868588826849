import React, { useEffect, useState } from 'react';
import { Alert, Backdrop, CircularProgress, Grid, Snackbar } from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import {
	IFlightExplorerDatesFilterValueExactDates,
	IFlightExplorerDatesFilterValueMonth,
	IFlightExplorerDestination,
	IFlightExplorerReq,
	IFlightExplorerTopFilterValue,
} from '../../types';
import { FlightExplorerDestinationList } from '../FlightExplorerDestinationList';
import { getFlightExplorerData } from '../../services';
import { ShowAlertState } from 'features/common/types';
import { FlightExplorerMap } from '../FlightExplorerMap';
import { FlightExplorerTopFilter } from '../FlightExplorerTopFilter';
import routes from '../../../../constants/routes';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
	select: {
		width: '-webkit-fill-available',
	},
}));

export const FlightExplorerContainer = (): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [flightExplorerDestinationResult, setFlightExplorerDestinationResult] = useState<IFlightExplorerDestination[]>(
		[],
	);
	const [destinationActive, setDestinationActive] = useState<IFlightExplorerDestination | null>(null);
	const [filterToSearch, setFilterToSearch] = useState<IFlightExplorerTopFilterValue | null>(null);

	useEffect(() => {
		const fetchApi = async () => {
			try {
				setLoading(true);
				setFlightExplorerDestinationResult([]);
				const filter = { departureCode: 'ASU' } as IFlightExplorerReq;

				const data = await getFlightExplorerData(filter);
				setFlightExplorerDestinationResult([...data.data]);
				setLoading(false);
			} catch (error: any) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al pedir el flight explorer'),
				});
				setLoading(false);
			}
		};
		fetchApi();
	}, []);

	useEffect(() => {
		const fetchApi = async () => {
			if (filterToSearch != null) {
				try {
					setLoading(true);
					setFlightExplorerDestinationResult([]);
					const filter = {
						departureCode: filterToSearch.departure.code,
						...(filterToSearch.dateFilterValue.duration && {
							duration: filterToSearch.dateFilterValue.duration,
						}),
					} as IFlightExplorerReq;
					if (filterToSearch.dateFilterValue.valueType == 0) {
						const filterTravelInMonth = filterToSearch.dateFilterValue
							.value as unknown as IFlightExplorerDatesFilterValueMonth;
						if (filterTravelInMonth.travelInMonth > 0) {
							filter.travelInMonth = filterTravelInMonth.travelInMonth;
						}
					} else {
						const filterTravelInDates = filterToSearch.dateFilterValue
							.value as unknown as IFlightExplorerDatesFilterValueExactDates;
						filter.travelDateFrom = filterTravelInDates.travelDateFrom;
						filter.travelDateTo = filterTravelInDates.travelDateTo;
					}
					const data = await getFlightExplorerData(filter);
					setFlightExplorerDestinationResult([...data.data]);
					setLoading(false);
				} catch (error: any) {
					setAlert({
						show: true,
						severity: 'error',
						message: extractErrorMessage(error, 'Ocurrió un error al pedir el flight explorer'),
					});
					setLoading(false);
				}
			}
		};
		fetchApi();
	}, [filterToSearch]);

	const handleDestinationCardMouseEnter = (destination: IFlightExplorerDestination) => {
		setDestinationActive(destination);
	};

	const handleDestinationCardMouseLeave = () => setDestinationActive(null);
	const handleDestinationClick = (destination: IFlightExplorerDestination) => {
		if (flightExplorerDestinationResult && flightExplorerDestinationResult.length > 0 && filterToSearch != null) {
			const destinationDetailPath = routes.flightExplorer.destinationDetail;
			let data = {
				departureCode: filterToSearch.departure.code,
				departureName: filterToSearch.departure.name,
				destinationCode: destination.code,
				destinationName: destination.name,
				...(filterToSearch.dateFilterValue.duration && {
					durationFrom: filterToSearch.dateFilterValue.duration.from + '',
					durationTo: filterToSearch.dateFilterValue.duration.to + '',
				}),
			};
			if (filterToSearch.dateFilterValue.valueType == 0) {
				const filterTravelInMonth = filterToSearch.dateFilterValue
					.value as unknown as IFlightExplorerDatesFilterValueMonth;
				if (filterTravelInMonth.travelInMonth > 0) {
					data = Object.assign(data, { travelInMonth: filterTravelInMonth.travelInMonth });
				}
			} else {
				const filterTravelInDates = filterToSearch.dateFilterValue
					.value as unknown as IFlightExplorerDatesFilterValueExactDates;
				data = Object.assign(data, {
					travelDateFrom: filterTravelInDates.travelDateFrom,
					travelDateTo: filterTravelInDates.travelDateTo,
				});
			}

			const searchParams = new URLSearchParams(data);
			const completeUrl = `${window.location.origin}${destinationDetailPath}?${searchParams.toString()}`;
			window.open(completeUrl, '_blank');
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<FlightExplorerTopFilter handleSearch={setFilterToSearch}></FlightExplorerTopFilter>
			{filterToSearch && flightExplorerDestinationResult && (
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<FlightExplorerDestinationList
							destinations={flightExplorerDestinationResult}
							handleDestinationCardMouseEnter={handleDestinationCardMouseEnter}
							handleDestinationCardMouseLeave={handleDestinationCardMouseLeave}
							handleDestinationCardClick={handleDestinationClick}
						></FlightExplorerDestinationList>
					</Grid>
					<Grid item xs={9}>
						{flightExplorerDestinationResult && (
							<FlightExplorerMap
								departure={filterToSearch.departure}
								destinationActive={destinationActive}
								destinations={flightExplorerDestinationResult}
								handleClick={handleDestinationClick}
							/>
						)}
					</Grid>
				</Grid>
			)}

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
