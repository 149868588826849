import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	Grid,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { PieChart, Pie, Cell } from 'recharts';

import { dashIfNone, formatDateTimeToShowUser } from 'features/common/helpers';
import { IChannel, IPatchSaleOrder, ISaleOrderDetail, ITransaction, segmentTypes } from 'features/salesOrder/types';
import {
	getChannels,
	getVariationLogs,
	patchSaleOrder,
	getPaymentReceivedTransactions,
} from 'features/salesOrder/services';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from 'features/common/types';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
	data: ISaleOrderDetail;
	onSetAlert: (values: ShowAlertState) => void;
	onUpdate: () => void;
}

const COLORS = ['#0088FE', '#FF8042'];

const formatDate = (value: string) => {
	// "2024-04-18"
	const arr = value.split('-');
	return `${arr[2]}/${arr[1]}/${arr[0]}`;
};

export interface LogItem {
	id: string;
	saleOrderId: string;
	saleAmount: number;
	saleAmountVariation: number;
	totalCost: number;
	totalCostVariation: number;
	grossProfit: number;
	grossProfitVariation: number;
	dateCreated: string;
}

// @ts-ignore
const truncate = (str, maxLength) => {
	return str.length > maxLength ? str.substring(0, maxLength - 3) + '...' : str;
};

// @ts-ignore
const getServiceDescription = (service) => {
	if (!service || !service.serviceType) {
		return truncate('No especificado', 60);
	}
	let description;
	switch (service.serviceType) {
		case 'Accommodation': {
			const destinationCode = service.destination?.code ?? 'N/A';
			const serviceProviderName = service.serviceProvider?.name ?? 'N/A';
			const roomsLength = service.rooms?.length ?? 0;
			description = `Alojamiento | ${destinationCode}: ${serviceProviderName} | ${roomsLength} hab`;
			break;
		}
		case 'Flight': {
			const vcCode = service.vc?.code ?? 'N/A';
			const reference = service.reference ?? 'N/A';
			const passengerIdsLength = service.passengerIds?.length ?? 0;
			description = `Vuelo | vc: ${vcCode} | pnr: ${reference} | ${passengerIdsLength} pax`;
			break;
		}
		case 'Other': {
			const otherServiceType = service.otherServiceType ?? 'Otro';
			const serviceName = service.serviceName ?? 'N/A';
			const passengerIdsLength = service.passengerIds?.length ?? 0;
			description = `${otherServiceType}: ${serviceName} | ${passengerIdsLength} pax`;
			break;
		}
		case 'Transfer': {
			const transferSegment = service.transferSegments?.[0];
			const transferType = transferSegment?.transferType ?? 'N/A';
			const originName = transferSegment?.originName ?? 'N/A';
			const destinationName = transferSegment?.destinationName ?? 'N/A';
			const passengerIdsLength = service.passengerIds?.length ?? 0;
			description = `Traslados ${transferType} ${originName} ${destinationName} | ${passengerIdsLength} pax`;
			break;
		}
		default: {
			description = service.serviceType ?? 'No especificado';
			break;
		}
	}
	return truncate(description, 50);
};

// @ts-ignore
const getEnableStatus = (service) => {
	return service.enable ? 'SI' : 'NO';
};

// @ts-ignore
const capitalizeWords = (str) => {
	// @ts-ignore
	return str ? str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : '';
};

export const General = ({ data, onSetAlert, onUpdate }: Props): JSX.Element => {
	const { id: saleOrderId } = useParams<{ id: string }>();
	const [variationData, setVariationData] = useState<LogItem[]>([]);
	const [channels, setChannels] = useState<IChannel[]>([]);
	const [transactions, setTransactions] = useState<ITransaction[]>([]);
	const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);
	const [transactionsError, setTransactionsError] = useState<string | null>(null);
	const [isEditingClientReference, setIsEditingClientReference] = useState(false);
	const [clientReference, setClientReference] = useState(data.clientReference || '');
	const clientReferenceTextFieldRef = useRef(null);
	useEffect(() => {
		const fetchLogs = async () => {
			try {
				const { data: result } = await getVariationLogs(saleOrderId);
				setVariationData(result);
			} catch (e) {
				console.log(e);
			}
		};

		fetchLogs();
	}, []);

	useEffect(() => {
		const fetchTransactions = async () => {
			try {
				const { data: transactionsData } = await getPaymentReceivedTransactions(saleOrderId);
				setTransactions(transactionsData);
			} catch (e) {
				setTransactionsError('Error fetching transactions');
			} finally {
				setLoadingTransactions(false);
			}
		};

		fetchTransactions();
	}, [saleOrderId]);

	const mainPassenger = data.passengers.find((passenger) => passenger.mainPassenger);
	const nameAndLastName = mainPassenger ? `${mainPassenger.name} ${mainPassenger.lastName}` : 'No definido';
	const passengerCount = data?.passengers?.length ?? 0;
	const services = data.services;

	const balanceStyleAndText = (amount: number | undefined, departureDate: string | undefined) => {
		if (amount === undefined || departureDate === undefined) {
			return { style: { color: 'inherit' }, text: '' };
		}

		const today = new Date();
		const departureDateObj = new Date(departureDate);
		const diffTime = departureDateObj.getTime() - today.getTime();
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		if (amount > 0 && diffDays < 0) {
			return {
				style: { color: 'red', fontWeight: 'bold' as const },
				text: (
					<>
						{`PAX VIAJÓ HACE ${-diffDays} ${-diffDays === 1 ? 'DÍA' : 'DÍAS'}`}
						<br />
						{'URGENTE COBRAR SALDO'}
					</>
				),
			};
		} else if (amount > 0 && diffDays < 30) {
			return {
				style: { color: 'red', fontWeight: 'bold' as const },
				text: (
					<>
						{`${diffDays} ${diffDays === 1 ? 'día' : 'días'} para la fecha de viaje`}
						<br />
						{'COBRAR SALDO'}
					</>
				),
			};
		} else if (amount > 0 && diffDays >= 30 && diffDays <= 45) {
			return {
				style: { color: 'red' },
				text: (
					<>
						{`${diffDays} ${diffDays === 1 ? 'día' : 'días'} para la fecha de viaje`}
						<br />
						{'COBRAR SALDO'}
					</>
				),
			};
		}
		return { style: { color: 'inherit' }, text: '' };
	};

	const { style, text } = balanceStyleAndText(data.pendingPayAmount, data.travelDepartureDate);

	const handleChangeSegmentType = (event: SelectChangeEvent) => {
		handleRequest({
			segmentType: event.target.value as string,
		});
	};

	const handleChangeChannel = (event: SelectChangeEvent) => {
		handleRequest({
			channelId: Number(event.target.value),
		});
	};

	const handleRequest = async (data: IPatchSaleOrder) => {
		try {
			await patchSaleOrder(saleOrderId, data);
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Salesorder actualizada',
			});
			onUpdate();
		} catch (e) {
			// @ts-ignore
			alert(e.response.data.message);
		}
	};

	useEffect(() => {
		const loadChannels = async () => {
			try {
				const response = await getChannels();
				setChannels(response.data);
			} catch (e: any) {
				console.error(e);
			}
		};

		loadChannels();
	}, []);

	const handleChangeClientReference = (event: React.ChangeEvent<HTMLInputElement>) => {
		setClientReference(event.target.value);
	};

	const handleSaveClientReference = async () => {
		setIsEditingClientReference(false);
		await handleRequest({ clientReference: clientReference });
	};

	const handleEditClientReferenceClick = () => {
		setIsEditingClientReference(true);
		setTimeout(() => {
			if (clientReferenceTextFieldRef && clientReferenceTextFieldRef.current) {
				// @ts-ignore
				clientReferenceTextFieldRef?.current?.focus();
			}
		}, 100);
	};

	const handleKeyPressClientReferenceField = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && (data.clientReference || '') !== clientReference) {
			e.preventDefault();
			handleSaveClientReference();
		}
	};

	const handleChangeAutomaticSyncWithNetsuite = async (event: React.ChangeEvent<HTMLInputElement>) =>
		await handleRequest({ automaticSyncWithNetsuite: event.target.checked });

	return (
		<Grid container pl={3} mt={2} spacing={2} flexDirection="row">
			<Grid item xs={9} flexDirection="row">
				<Box gap={2} display="flex">
					<Card elevation={4} sx={{ width: '100%' }}>
						<CardContent
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 1,
							}}
						>
							<Typography variant="h6">Orden de ventas</Typography>

							<Box display="flex" gap={1}>
								<Typography variant="body2" sx={{ width: '100px' }}>
									Creada:
								</Typography>
								<Typography variant="body2">
									{data.created ? formatDateTimeToShowUser(data.created).substring(0, 10) : '-'}
								</Typography>
								<Typography variant="body2" style={{ fontWeight: 'bold' }}>
									por {data.createdBy}
								</Typography>
							</Box>

							<Box display="flex" gap={1}>
								<Typography variant="body2" sx={{ width: '100px' }}>
									Modificada:
								</Typography>
								<Typography variant="body2">
									{' '}
									{data.updated ? formatDateTimeToShowUser(data.updated).substring(0, 10) : ''}{' '}
								</Typography>
								<Typography variant="body2" style={{ fontWeight: 'bold' }}>
									{' '}
									{data.updatedBy ? `por ${data.updatedBy}` : ''}
								</Typography>
							</Box>

							<Box alignItems="center" display="flex" gap={1}>
								<Typography variant="body2" sx={{ width: '100px' }}>
									Segmento:
								</Typography>
								<Select
									fullWidth
									size="small"
									value={segmentTypes.find((st) => st.id === data.segmentType)?.id}
									onChange={handleChangeSegmentType}
								>
									{segmentTypes.map((segmentType) => {
										return (
											<MenuItem key={segmentType.id} value={segmentType.id}>
												{segmentType.name}
											</MenuItem>
										);
									})}
								</Select>
							</Box>

							<Box alignItems="center" display="flex" gap={1}>
								<Typography variant="body2" sx={{ width: '100px' }}>
									Canal:
								</Typography>
								{/* 
								// @ts-ignore */}
								<Select fullWidth size="small" value={data.channel.id} onChange={handleChangeChannel}>
									{channels.map((channel) => {
										return (
											<MenuItem key={channel.id} value={channel.id}>
												{channel.name}
											</MenuItem>
										);
									})}
								</Select>
								{/* <Typography variant="body2">{data.channel.name}</Typography> */}
							</Box>

							<Box alignItems="center" display="flex" gap={1}>
								<Typography variant="body2" sx={{ width: '115px' }}>
									Referencia de cliente:
								</Typography>
								<TextField
									fullWidth
									onKeyPress={handleKeyPressClientReferenceField}
									inputRef={clientReferenceTextFieldRef}
									disabled={!isEditingClientReference}
									value={clientReference}
									size="small"
									onChange={handleChangeClientReference}
									inputProps={{ maxLength: 255 }}
								/>
								<IconButton
									color="primary"
									disabled={isEditingClientReference && (data.clientReference || '') === clientReference}
									onClick={isEditingClientReference ? handleSaveClientReference : handleEditClientReferenceClick}
								>
									{isEditingClientReference ? <SaveIcon /> : <EditIcon />}
								</IconButton>
							</Box>

							<Box alignItems="center" display="flex" gap={1}>
								<Typography variant="body2" sx={{ width: '150px' }}>
									Sincronizar automáticamente con Netsuite :
								</Typography>
								<Switch
									checked={data.automaticSyncWithNetsuite}
									onChange={handleChangeAutomaticSyncWithNetsuite}
									color="primary"
								/>
							</Box>

							<Box display="flex" gap={1}>
								<Typography variant="body2" sx={{ width: '100px' }}>
									Pax principal:
								</Typography>
								<Typography variant="body2">{capitalizeWords(nameAndLastName)}</Typography>
							</Box>
						</CardContent>
					</Card>

					<Card elevation={4} sx={{ width: '100%' }}>
						<CardContent>
							<Typography variant="h6">Detalle del viaje</Typography>
							<Box display="flex" gap={1}>
								<Typography variant="body2">Destino/s:</Typography>
								<Typography variant="body2"> {dashIfNone(data.destinations)} </Typography>
							</Box>
							<Box display="flex" gap={1}>
								<Typography variant="body2">Fecha de comienzo:</Typography>
								<Typography variant="body2">
									{data.travelDepartureDate ? formatDate(data.travelDepartureDate) : '-'}
								</Typography>
							</Box>
							<Box display="flex" gap={1}>
								<Typography variant="body2">Fecha de finalización:</Typography>
								<Typography variant="body2">
									{data.travelReturnDate ? formatDate(data.travelReturnDate) : '-'}
								</Typography>
							</Box>
							<Box display="flex" gap={1}>
								<Typography variant="body2">Cantidad de pax:</Typography>
								<Typography variant="body2">{passengerCount}</Typography>
							</Box>
						</CardContent>
					</Card>
				</Box>
				<Box mt={2}>
					<Card elevation={4}>
						<CardContent>
							<TableContainer>
								<Typography variant="h6">Servicios de la orden</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Tipo de Servicio</TableCell>
											<TableCell>Precio de venta</TableCell>
											<TableCell>Costo</TableCell>
											<TableCell>Activo</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{services.map((service, index) => (
											<TableRow key={index}>
												{/* 
													//@ts-ignore */}
												<TableCell>{service ? getServiceDescription(service) : 'Descripción no disponible'}</TableCell>
												<TableCell>
													{/* 
													//@ts-ignore */}
													{service.currency} {service.totalSaleAmount ?? 'No especificado'}
												</TableCell>

												<TableCell>
													{/* 
													//@ts-ignore */}
													{service.currency} {service.totalCost ?? 'No especificado'}
												</TableCell>
												<TableCell>
													{/* 
													//@ts-ignore */}
													{service ? getEnableStatus(service) : 'Status no disponible'}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</CardContent>
					</Card>
				</Box>
				<Box mt={2}>
					<Card elevation={4}>
						<CardContent>
							<TableContainer>
								<Typography variant="h6">Modificaciones</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell align="left">Fecha</TableCell>
											<TableCell align="right">Venta</TableCell>
											<TableCell align="right">modi (V)</TableCell>
											<TableCell align="right">Costo</TableCell>
											<TableCell align="right">modi (C)</TableCell>
											<TableCell align="right">Margen</TableCell>
											<TableCell align="right">modi (M)</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{variationData.map((row, index) => (
											<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="left">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														{formatDate(row.dateCreated)}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.saleAmount}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.saleAmountVariation}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.totalCost}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.totalCostVariation}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.grossProfit}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.grossProfitVariation}
													</Typography>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</CardContent>
					</Card>
				</Box>
			</Grid>
			<Grid item xs={3}>
				<Box>
					<Card elevation={4}>
						<CardContent>
							<Typography align="left" variant="h6">
								Datos de la venta
							</Typography>
							<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
								<Typography>Venta: </Typography>
								<Typography>
									{data.currency} {dashIfNone(data.saleAmount)}
								</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
								<Typography>Costo: </Typography>
								<Typography>
									{data.currency} {dashIfNone(data.totalCost)}
								</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
								<Typography>MB %: </Typography>
								<Typography>{dashIfNone(data.grossProfitPercentage)}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
								<Typography>MB A: </Typography>
								<Typography>
									{data.currency} {dashIfNone(data.grossProfit)}
								</Typography>
							</Box>
							<Box width="100%" justifyContent="center" mt={2} position={'relative'} display="flex" alignItems="center">
								<PieChart width={276} height={140}>
									<Pie
										data={[
											{ name: 'Cobrado', value: data.chargedAmount || 10 },
											{ name: 'Saldo', value: data.pendingPayAmount },
										]}
										cx={115}
										cy={120}
										startAngle={180}
										endAngle={0}
										innerRadius={100}
										outerRadius={120}
										fill="#8884d8"
										paddingAngle={1}
										dataKey="value"
									>
										<Cell key="cell-1" fill={COLORS[0]} />
										<Cell key="cell-2" fill={COLORS[1]} />
									</Pie>
								</PieChart>
							</Box>
							<Box display="flex" gap={1} justifyContent="space-between" width="100%">
								<Box display="flex" alignItems="center" gap={1}>
									<Box width={15} height={15} sx={{ backgroundColor: COLORS[0] }} />
									<Typography sx={{ width: '60px' }} variant="body2">
										Cobrado
									</Typography>
								</Box>
								<Typography>
									{data.currency} {data.chargedAmount || 0}
								</Typography>
							</Box>
							<Box display="flex" gap={1} justifyContent="space-between" width="100%">
								<Box display="flex" alignItems="center" gap={1}>
									<Box width={15} height={15} sx={{ backgroundColor: COLORS[1] }} />
									<Typography sx={{ width: '60px' }} variant="body2">
										Saldo
									</Typography>
								</Box>
								<Typography style={style}>
									{data.currency} {dashIfNone(data.pendingPayAmount)}
								</Typography>
							</Box>
							<Box
								display="flex"
								gap={1}
								marginTop={4}
								justifyContent={'center'}
								padding={2}
								sx={{ backgroundColor: text ? '#F6EFB3' : '' }}
							>
								<Box width={15} height={15} />
								<Typography variant="body2" style={style}>
									{text}
								</Typography>
							</Box>
						</CardContent>
					</Card>
				</Box>
				<Box mt={2}>
					<Card elevation={4}>
						<CardContent>
							<Typography variant="h6">Cobros</Typography>
							{loadingTransactions ? (
								<Typography>Cargando transacciones...</Typography>
							) : transactionsError ? (
								<Typography>{transactionsError}</Typography>
							) : (
								<TableContainer>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												{/* <TableCell>Order ID</TableCell>
												<TableCell>Transaction ID</TableCell> */}
												{/* <TableCell>Monto de Transacción</TableCell>
												<TableCell>Moneda de Transacción</TableCell> */}
												<TableCell>Fecha</TableCell>
												<TableCell>Monto</TableCell>
												<TableCell>Tipo de Relación</TableCell>
												<TableCell>Tipo de Plataforma</TableCell>
												<TableCell>Observaciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{transactions.map((transaction) => (
												<TableRow key={transaction.transactionId}>
													{/* <TableCell>{transaction.orderId}</TableCell>
													<TableCell>{transaction.transactionId}</TableCell> */}
													{/* <TableCell>{transaction.transactionAmount}</TableCell>
													<TableCell>{transaction.transactionCurrency}</TableCell> */}
													<TableCell>{transaction.createdAt}</TableCell>
													<TableCell>{transaction.amountInMainCurrency}</TableCell>
													<TableCell>{transaction.relationType}</TableCell>
													<TableCell>{transaction.platformType}</TableCell>
													<TableCell>{dashIfNone(transaction.observations)}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</CardContent>
					</Card>
				</Box>
			</Grid>
		</Grid>
	);
};
