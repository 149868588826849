// NetsuiteEntitySyncStatus.tsx
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Link, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { INetsuiteSyncStatusDTO } from '../../../../../features/common/types';
import NetsuiteSyncStatus from '../../../../../features/common/components/NetsuiteSyncStatus';

const useStyles = makeStyles((theme) => ({
	box: {
		margin: theme.spacing(2),
		backgroundColor: 'inherit',
		marginLeft: theme.spacing(1),
		border: 0,
	},
	title: {
		marginBottom: theme.spacing(2),
	},
	link: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		marginLeft: theme.spacing(0.5),
	},
}));

interface NetsuiteEntitySyncStatusProps {
	syncStatus: INetsuiteSyncStatusDTO;
}

const NetsuiteEntitySyncStatus: React.FC<NetsuiteEntitySyncStatusProps> = (prop: NetsuiteEntitySyncStatusProps) => {
	const classes = useStyles();

	const { netSuiteId, detailUrlInNetsuite } = prop.syncStatus;

	return (
		<Box className={classes.box}>
			<Typography variant="h6" className={classes.title}>
				Estado de sincronización con Netsuite
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={1}>
					<NetsuiteSyncStatus syncStatus={prop.syncStatus} />
				</Grid>
				<Grid item xs={6} container justifyContent="flex-start" alignItems="center">
					{netSuiteId && detailUrlInNetsuite ? (
						<Link href={detailUrlInNetsuite} target="_blank" rel="noopener" className={classes.link}>
							Ver detalle en Netsuite
							<OpenInNewIcon fontSize="small" className={classes.icon} />
						</Link>
					) : (
						<Typography variant="body2" color="textSecondary">
							No hay detalles disponibles para ver en Netsuite
						</Typography>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default NetsuiteEntitySyncStatus;
