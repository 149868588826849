import React from 'react';
import { Redirect } from 'react-router-dom';
import { Theme, Typography, Container, Link } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { LoginForm } from 'features/auth/components/LoginForm';
import { CookieService } from 'features/auth/services/CookieService';
import routes from 'constants/routes';
import bc from '../../assets/background.webp';
import logo from '../../assets/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			minHeight: '100vh',
			background: theme.palette.background.default,
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundImage: `url(${bc})`,
		},
		container: {
			background: 'rgb(37,19,57,0.5)',
			borderRadius: '8px',
			marginBottom: '40px',
			color: '#FFFFFF',
			textAlignLast: 'center',
		},
		logo: {
			height: '3.5rem',
			paddingTop: '20px',
			paddingBottom: '10px',
		},
	}),
);

const Login = (): JSX.Element => {
	const classes = useStyles();
	const token = CookieService.getToken();

	if (token) {
		return <Redirect to={routes.home} />;
	}
	return (
		<div className={classes.page}>
			<img className={classes.logo} src={logo} alt="mevuelo.com logo" />
			<Container maxWidth="sm" className={classes.container}>
				<Typography color="inherit" variant="h4" component="h2">
					{' '}
					¡Bienvenido!
				</Typography>
				<Typography color="inherit" variant="subtitle1" component="h2">
					{' '}
					Este sitio es de uso exclusivo para colaboradores de mevuelo.com | SSC y las subsidiarias en cada uno de los
					paises en los cuales opera.
				</Typography>
				<Typography color="inherit" variant="subtitle1" component="h2">
					{' '}
					Si eres parte de nuestro staff te invitamos a hacer login a continuación.
				</Typography>
				<Typography color="inherit" variant="subtitle1" component="h2">
					{' '}
					Si llegaste aquí por error haz click en el{' '}
					<Link color="secondary" href="https://mevuelo.com">
						siguiente link
					</Link>{' '}
					para ir a nuestro sitio de clientes o contáctate con tu asesor de ventas.
				</Typography>
			</Container>
			<LoginForm />
		</div>
	);
};

export default Login;
