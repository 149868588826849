import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, AlertColor, Container, FormControlLabel, Snackbar, Switch, TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash.debounce';
import QuotationListItem from 'features/quotation/components/QuotationListItem';

import { Layout } from 'features/common/components/Layout';
import { listQuotation } from '../../features/quotation/services';
import { QuotationsListItem } from 'features/quotation/types';
import routes from 'constants/routes';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
	QuotationFindSimilarFilter,
	QuotationsSimilarFilter,
} from '../../features/quotation/components/QuotationsSimilarFilter';
import { CopyQuotationDialog } from '../../features/quotation/components/CopyQuotationDialog';
import { cleanRoomDistributionFilters, handleViewQuotation } from '../../features/quotation/helpers';
import { PageNoResult } from '../../features/common/components/PageNoResults';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	filterContainer: {
		marginTop: theme.spacing(2),
	},
	filterInput: {
		width: '80%',
		marginRight: '15px',
	},
	paginationHolder: {
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

interface PaginationData {
	page: number;
	total: number;
}
export interface QuotationFilter {
	filter: string | null;
	onlyMyQuotations: boolean;
}

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}
interface IInfoToCopyQuotation {
	id: number;
	isOnlyFlights: boolean;
}

function QuoationsList(): JSX.Element {
	const classes = useStyles();
	const history = useHistory();
	const [quotationsList, setQuotationList] = useState<QuotationsListItem[]>([]);
	const [paginationData, setPaginationData] = useState<PaginationData>({
		page: 1,
		total: 1,
	});
	const [loading, setLoading] = React.useState(false);
	const [filterData, setFilterData] = useState<QuotationFilter>({
		filter: '',
		onlyMyQuotations: true,
	});

	const [filtersSimilar, setFilterSimilar] = useState<QuotationFindSimilarFilter>({});
	const [filtersBySimilar, setFilterBySimilar] = useState(false);

	const [infoToCopy, setInfoToCopy] = useState<IInfoToCopyQuotation | null>(null);
	const [idQuotationToShowDetailOnCopy, setIdQuotationToShowDetailOnCopy] = useState(-1);

	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	const [openDialogCopyQuotation, setOpenDialogCopyQuotation] = React.useState(false);

	const [inputFilter, setInputFilter] = useState<string>('');

	useEffect(() => {
		if (idQuotationToShowDetailOnCopy > 0 && infoToCopy) {
			const timer = setTimeout(() => {
				const routeToEdit = infoToCopy.isOnlyFlights ? routes.quotations.editOnlyFlights : routes.quotations.edit;
				history.push(routeToEdit.replace(':id', String(idQuotationToShowDetailOnCopy)));
			}, 1200);
			return () => clearTimeout(timer);
		}
	}, [idQuotationToShowDetailOnCopy]);

	const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterData({ ...filterData, filter: event.target.value });
	};

	const debouncedChangeFilterHandler = useMemo(() => debounce(handleChangeFilter, 300), [filterData]);

	const handleChangeInputFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputFilter(event.target.value);
		debouncedChangeFilterHandler(event);
	};

	const handleChangeOnlyMyQuotation = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterData({ ...filterData, onlyMyQuotations: event.target.checked });
	};

	const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
		if (value !== paginationData.page) {
			setPaginationData((prevState) => ({
				...prevState,
				page: value,
			}));
		}
	};

	const handleClickOnCopyQuotation = (id: number, isOnlyFlights: boolean) => {
		setInfoToCopy({ id: id, isOnlyFlights: isOnlyFlights });
		setOpenDialogCopyQuotation(true);
	};

	const handleSuccessCopyQuotation = (id: number) => {
		setOpenDialogCopyQuotation(false);
		setAlert({ show: true, severity: 'success', message: 'Cotización copiada satisfactoriamente' });
		setIdQuotationToShowDetailOnCopy(id);
	};

	useEffect(() => {
		const fetchApi = async () => {
			try {
				// @ts-ignore
				let newFilters = { ...filterData };
				if (filtersBySimilar) {
					// @ts-ignore
					newFilters = { ...filtersSimilar };
				}

				const filterClear = Object.keys(newFilters)
					// @ts-ignore
					.filter((k) => newFilters[k] !== null && newFilters[k] !== undefined && newFilters[k] !== '')
					// @ts-ignore
					.reduce((a, k) => ({ ...a, [k]: newFilters[k] }), {});
				setLoading(true);
				const result = await listQuotation(paginationData.page - 1, filterClear);
				if (
					paginationData.page != Number(result.data.headers['X-Page']) + 1 ||
					paginationData.total != Number(result.data.headers['X-Page-Count'])
				) {
					setPaginationData({
						page: Number(result.data.headers['X-Page']) + 1,
						total: Number(result.data.headers['X-Page-Count']),
					});
				}

				setQuotationList(result.data.data);
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		};

		fetchApi();
	}, [paginationData.page, filterData, filtersSimilar]);

	const onClearSimilarFilter = () => {
		setFilterBySimilar(false);
		setFilterSimilar({});
	};

	const onApplySimilarFilters = (filter: QuotationFindSimilarFilter) => {
		setFilterBySimilar(true);
		const newFilters = { ...filter, onlyMyQuotations: filterData.onlyMyQuotations };
		if (newFilters.roomDistributions && newFilters.roomDistributions.length > 0) {
			const filterCleaned = {
				...newFilters,
				roomDistributions: cleanRoomDistributionFilters(newFilters.roomDistributions),
			};
			setFilterSimilar(filterCleaned);
		} else if (newFilters.roomDistributions && newFilters.roomDistributions.length === 0) {
			const filterCleaned = { ...newFilters };
			delete filterCleaned.roomDistributions;
			setFilterSimilar(filterCleaned);
		} else {
			setFilterSimilar(newFilters);
		}
	};

	const handleCloseCopyQuotationDialog = () => {
		setInfoToCopy(null);
		setOpenDialogCopyQuotation(false);
	};

	return (
		<Layout>
			<Container component="main" maxWidth="md">
				<Backdrop className={classes.backdrop} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<div className={classes.filterContainer}>
					<TextField
						className={classes.filterInput}
						value={inputFilter}
						id="outlined-helperText"
						label="Filtrar"
						onChange={handleChangeInputFilter}
						helperText="by: id, crmTicket, name,phone,description or email"
						variant="outlined"
					/>
					<FormControlLabel
						control={
							<Switch
								checked={filterData.onlyMyQuotations}
								name="onlyMyQuotations"
								onChange={handleChangeOnlyMyQuotation}
							/>
						}
						label="solo las mías"
					/>
				</div>

				<QuotationsSimilarFilter
					setLoading={setLoading}
					onClearFilter={onClearSimilarFilter}
					onApplyFilter={onApplySimilarFilters}
				/>

				<div className={classes.paper}>
					{quotationsList.length === 0 ? (
						<PageNoResult />
					) : (
						quotationsList.map((quotation) => (
							<QuotationListItem
								key={quotation.id}
								data={quotation}
								onClick={handleViewQuotation(quotation.id, quotation.isOnlyFlights)}
								onDuplicate={() => handleClickOnCopyQuotation(quotation.id, quotation.isOnlyFlights)}
							/>
						))
					)}
				</div>
				<div className={classes.paginationHolder}>
					<Pagination
						count={paginationData.total}
						page={paginationData.page}
						variant="outlined"
						color="primary"
						onChange={handleChangePage}
					/>
				</div>
				<Snackbar open={alert.show} autoHideDuration={1000} onClose={() => setAlert(defaultAlertState)}>
					<Alert variant="filled" severity={alert.severity}>
						{alert.message}
					</Alert>
				</Snackbar>
				{openDialogCopyQuotation && infoToCopy && (
					<CopyQuotationDialog
						quotationIdToCopy={infoToCopy.id}
						isOnlyFlights={infoToCopy.isOnlyFlights}
						open={true}
						onClose={handleCloseCopyQuotationDialog}
						onSuccessCopyQuotation={handleSuccessCopyQuotation}
					/>
				)}
			</Container>
		</Layout>
	);
}

export default QuoationsList;
