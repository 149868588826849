import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GetPAXTotalsResponse } from '../../helpers';
import { HotelCardDesktop } from 'features/hotels/components/HotelCardDesktop';
import { DestinationSimple, HotelOptionsSimple } from '../../types';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { HotelCardMobile } from '../../../hotels/components/HotelCardMobile';
import { HotelOptionsPricePreviewDesktop } from './HotelOptionsPricePreviewDesktop';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
	optionTableHolder: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	utility: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold',
	},
	sign: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	optionsTitle: {
		fontStyle: 'normal',
		fontWeight: 500,
		letterSpacing: '0em',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(1),
	},
}));
interface Props {
	destinations: DestinationSimple[];
	paxTotals: GetPAXTotalsResponse;
	showUtility: boolean;
	markup: number | null;
	totalFlights: number;
	flightIndexSelected: number | null;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

export const QuotationFinalPricePreview = (props: Props): JSX.Element => {
	const classes = useStyles();
	const isMediumDown = useBreakpoints().mediumDown;

	const { destinations, paxTotals, totalFlights, flightIndexSelected } = props;
	const hotelOptionsToShow = (hotelOptions: HotelOptionsSimple[]): HotelOptionsSimple[] => {
		if (totalFlights > 1 && flightIndexSelected != null) {
			return hotelOptions.filter((x) => x.flightIndex === flightIndexSelected);
		} else {
			return hotelOptions;
		}
	};

	return (
		<>
			{destinations.map((destinationItem) => {
				return hotelOptionsToShow(destinationItem.hotelOptions).map((option, index) => {
					return (
						<div key={index} className={classes.optionTableHolder}>
							{!isMediumDown && (
								<Typography component="h1" variant="subtitle1">
									Opción {index + 1}:
								</Typography>
							)}
							{isMediumDown && (
								<Typography component="h1" variant="h6" className={classes.optionsTitle}>
									Opción {index + 1}:
								</Typography>
							)}
							{!isMediumDown && (
								<>
									{option.details && (
										<HotelCardDesktop
											title={option.details.name}
											address={option.details.address}
											category={option.details.accommodationCategory}
											rating={option.details.rating}
											ratingText={option.details.ratingText}
											commentsCount={option.details.amountReviews}
											mainImage={option.details.mainImage}
											link={option.details.link}
											pool={option.details.pool}
											airConditioning={option.details.airConditioning}
											wifi={option.details.wifi}
											gym={option.details.gym}
											parking={option.details.parking}
										/>
									)}
									<HotelOptionsPricePreviewDesktop
										exchangeRate={props.exchangeRate}
										localCurrency={props.localCurrency}
										showLocalCurrency={props.showLocalCurrency}
										hotelOption={option}
										markup={props.markup}
										showUtility={props.showUtility}
										paxTotals={paxTotals}
									/>
								</>
							)}

							{option.details && isMediumDown && (
								<HotelCardMobile
									exchangeRate={props.exchangeRate}
									localCurrency={props.localCurrency}
									showLocalCurrency={props.showLocalCurrency}
									hotelOption={option}
									markup={props.markup}
									showUtility={props.showUtility}
									paxTotals={paxTotals}
								/>
							)}
						</div>
					);
				});
			})}
			<Typography>
				{' '}
				{paxTotals.child > 0
					? '* Son considerados niños aquellos pasajeros que tengan entre 2 y 11 años al momento de culminar el viaje'
					: ''}{' '}
			</Typography>
			<Typography>
				{' '}
				{paxTotals.infants > 0
					? '* Son considerados bebés aquellos pasajeros que tengan entre 0 y 1 año al momento de culminar el viaje'
					: ''}{' '}
			</Typography>
		</>
	);
};
