import { makeStyles } from '@mui/styles';

export const flightIssueRuleListStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.grey.A400,
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	btnContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		maxWidth: '90px',
	},
	expandedRow: {
		backgroundColor: '#FFE2D3',
	},
	icon: {
		color: '#AB47BC',
	},
	container: {
		paddingBottom: 20,
		borderBottom: '1px solid #00000040',
		width: '85%',
	},
	containerButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	tableSortLabel: {
		color: theme.palette.grey.A700,
		'&:hover': {
			color: theme.palette.common.black,
		},
	},
}));
