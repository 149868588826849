import React from 'react';
import { Grid, Typography } from '@mui/material';
import { CashOrderForm as Component } from 'features/payment/components/CashOrderForm';
import { Layout } from '../../../features/common/components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { isItauVN } from 'features/common/helpers';
import { PageNotAllowedForMarket } from '../../../features/common/components/PageNotAllowedForMarket';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
}));

export const CashPaymentPage = (): JSX.Element => {
	const classes = useStyles();
	const { auth } = useSelector((state: RootState) => state);

	const countryCode = auth.country;
	const ItauVN = isItauVN(countryCode);

	return (
		<Layout>
			<Container component="main" maxWidth="xs">
				{ItauVN ? (
					<PageNotAllowedForMarket />
				) : (
					<div className={classes.paper}>
						<Grid item xs={12} display="flex" justifyContent={'center'}>
							<Typography sx={{ mb: 4 }} variant="h6">
								Orden de pago Cash
							</Typography>
						</Grid>
						<Component />
					</div>
				)}
			</Container>
		</Layout>
	);
};
