import { AxiosResponse } from 'axios';
import endpoints from 'constants/endpoints';
import { ApiService } from 'services';
import {
	DateToFromFilterModel,
	FilterModel,
	IAirportFE,
	ICountryFE,
	ILocalize,
	ISort,
	SelectFilterModelMultiple,
} from './types';

export const getClientLocation = (): Promise<AxiosResponse<ILocalize>> => ApiService.get(endpoints.locateClient);

export const getCountries = (): Promise<AxiosResponse<ICountryFE[]>> => ApiService.get(endpoints.countries);

export const getFilterFromProfile = async (
	profileTag: string,
	apiKey: string,
	countryCode: string,
	userId?: string,
): Promise<{ profileSorts: ISort[] | null; profileFilters: FilterModel[] | null }> => {
	const profileKey = `power-${profileTag}-${userId}-${apiKey}`;
	const originalFilters = await ApiService.get(endpoints.profileFilters + `/${profileKey}`);
	const parsedValues = JSON.parse(originalFilters.data.values) as {
		sorts: ISort[] | null;
		filters: FilterModel[] | null;
	};
	if (parsedValues.filters && parsedValues.filters.length > 0) {
		parsedValues.filters = [
			...parsedValues.filters.map((filter: any) => {
				if (filter.secondValue && filter.label && filter.value) {
					return new DateToFromFilterModel(
						filter.label,
						filter.key,
						filter.secondKey,
						filter.value,
						filter.secondValue,
					);
				}
				if (filter.label && filter.value && filter.data) {
					return new SelectFilterModelMultiple(filter.label, filter.key, [...filter.data], [...filter.value]);
				}
				return filter;
			}),
		];
	}
	if (parsedValues.sorts && parsedValues.filters) {
		return { profileSorts: parsedValues.sorts, profileFilters: parsedValues.filters };
	} else if (parsedValues.sorts && !parsedValues.filters) {
		return { profileSorts: parsedValues.sorts, profileFilters: null };
	} else if (!parsedValues.sorts && parsedValues.filters) {
		return { profileSorts: null, profileFilters: parsedValues.filters };
	} else {
		return { profileSorts: null, profileFilters: null };
	}
};

export const putFilterInProfile = (
	profileTag: string,
	apiKey: string,
	countryCode: string,
	sortingFields: ISort[],
	filters: FilterModel[],
	userId?: string,
): void => {
	if (userId) {
		const valueToFilterProfile = {
			sorts: sortingFields,
			filters: filters,
		};
		const profileKey = `power-${profileTag}-${userId}-${apiKey}`;

		const stringyfiedValues = JSON.stringify(valueToFilterProfile);

		const newProfileObject = {
			key: profileKey,
			values: stringyfiedValues,
		};
		try {
			ApiService.put(endpoints.profileFilters, newProfileObject);
		} catch (e) {
			console.log(e);
		}
	}
};

export const getAirports = (): Promise<AxiosResponse<IAirportFE[]>> => ApiService.get(endpoints.airports);
