import React, { useEffect, useState } from 'react';
import { Checkbox, Grid, Paper, Typography } from '@mui/material';

export interface FavoriteHotelsFilterProps {
	onChange: (value: boolean) => void;
}

export const FavoriteHotelsFilter = (props: FavoriteHotelsFilterProps): JSX.Element => {
	const [selected, setSelected] = useState<boolean>(false);

	useEffect(() => props.onChange(selected), [selected]);

	return (
		<Paper>
			<Grid container sx={{ display: 'grid', marginLeft: 2, marginTop: 4 }}>
				<Grid item xs={12}>
					<Typography variant="h6" fontWeight="bold">
						Favoritos
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Checkbox value={selected} color="secondary" onChange={(_, value) => setSelected(value)} /> Sólo favoritos
				</Grid>
			</Grid>
		</Paper>
	);
};
