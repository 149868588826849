import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FlightItineraryDetailsSegmentsBlock } from '../FlightItineraryDetailsSegmentsBlock';
import { IBaggage, IScale, ISegmentDuration, ITravelSegment } from '../../types';
import { excludeAirlineBaggage } from '../../constants';

const useStyles = makeStyles(() => ({
	segmentHolder: {
		margin: '0 10px',
	},
	baggageDetailHolder: {
		minHeight: '68px',
		backgroundColor: '#eaeceb',
		display: 'flex',
	},
	baggageDetailItemHolder: {
		flex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	descriptionHolder: {
		display: 'flex',
		flexDirection: 'column',
	},
	text: {
		fontWeight: 'normal',
		fontStyle: 'normal',
		fontStretch: 'normal',
		textAlign: 'left',
	},
	defaultText: {
		color: 'rgb(123, 123, 123)',
	},
	durationDetailHolder: {
		fontSize: '22px',
		fontWeight: 'bold',
		fontStyle: 'normal',
		fontStretch: 'normal',
		lineHeight: '0.76',
		letterSpacing: '0.23px',
		textAlign: 'center',
		color: '#fff',
		padding: '7px 10px',
		backgroundColor: '#7b7b7b',
	},
}));

interface Props {
	segments: ITravelSegment[];
	scales: IScale[];
	duration: ISegmentDuration;
	baggage: IBaggage;
}
export const FlightItineraryDetails = ({ segments, scales, duration, baggage }: Props): JSX.Element => {
	const classes = useStyles();
	const theme = useTheme();
	const noShowWaitBarIndex = segments.length - 1;
	const airlineCode = segments[0].flightInformation.airline.code;
	return (
		<div className={'animated fadeIn'}>
			<div className={classes.segmentHolder}>
				{segments.map((segment, index) => (
					<FlightItineraryDetailsSegmentsBlock
						key={index}
						scales={scales}
						segment={segment}
						parentIndex={index}
						showWaitBar={!(index === noShowWaitBarIndex)}
					/>
				))}
			</div>
			<div className={classes.baggageDetailHolder}>
				<div className={classes.baggageDetailItemHolder}>
					<Typography
						className="icon icon-mv-hand-bag"
						component="span"
						fontSize={27}
						fontStyle={'normal'}
						fontWeight={'normal'}
						color={theme.palette.secondary.main}
					></Typography>
					<div className={classes.descriptionHolder}>
						{!baggage.total && excludeAirlineBaggage.includes(airlineCode) ? (
							<Typography
								variant="subtitle1"
								className={`${classes.text} ${classes.defaultText}`}
								fontSize={'14px'}
								style={{ fontWeight: 'bold' }}
								component="span"
							>
								No incluye equipaje de mano
							</Typography>
						) : (
							<Typography
								variant="subtitle1"
								className={`${classes.text} ${classes.defaultText}`}
								fontSize={'14px'}
								fontWeight={'bold'}
								color={theme.palette.secondary.main}
								component="span"
							>
								Incluye equipaje de mano
							</Typography>
						)}
					</div>
				</div>
				{baggage.total > 0 && (
					<div className={classes.baggageDetailItemHolder}>
						<Typography
							className="icon icon-mv-briefcase"
							component="span"
							fontSize={27}
							fontStyle={'normal'}
							fontWeight={'normal'}
							color={theme.palette.secondary.main}
						></Typography>
						<div className={classes.descriptionHolder}>
							<Typography
								variant="subtitle1"
								className={`${classes.text} ${classes.defaultText}`}
								fontSize={'14px'}
								style={{ fontWeight: 'bold' }}
								color={theme.palette.secondary.main}
								component="p"
							>
								Incluye equipaje para despachar
							</Typography>
							{baggage.baggageType && baggage.baggageType === 2 && (
								<Typography
									variant="subtitle1"
									className={`${classes.text} ${classes.defaultText}`}
									fontSize={'14px'}
									style={{ fontWeight: 'bold' }}
									component="span"
								>
									{baggage.total}
									{baggage.total > 1 ? ' maletas' : ' maleta'} por adulto
								</Typography>
							)}
							{baggage.baggageType && baggage.baggageType === 1 && (
								<Typography
									variant="subtitle1"
									className={`${classes.text} ${classes.defaultText}`}
									fontSize={'14px'}
									style={{ fontWeight: 'bold' }}
									component="span"
								>
									{baggage.total}
									{baggage.total > 1 ? ' Kilos' : ' kilo'} por adulto
								</Typography>
							)}
						</div>
					</div>
				)}
			</div>

			<div className={classes.durationDetailHolder}>
				Duraci&oacute;n total {duration.totalHours ? `${duration.totalHours}h` : ''}{' '}
				{duration.totalHours ? `${duration.totalMinutes}mins` : ''}
			</div>
		</div>
	);
};
