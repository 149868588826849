import { GDSEnum } from '../salesOrder/types';
import { SelectFilterListData } from '../common/types';

export enum CostByTicketOperatorEnum {
	Equals = '=',
	GreaterThan = '>',
	LessThan = '<',
}
export interface FlightIssueNotDefaultRuleFormValues {
	isDefaultRule: boolean;
	vcIncludes: string[];
	vcExcludes: string[];
	originIncludes: string[];
	originExcludes: string[];
	layoverIncludes: string[];
	layoverExcludes: string[];
	stopoverIncludes: string[];
	stopoverExcludes: string[];
	gds: GDSEnum[];
	priority: string;
	ruleComment: string;
	mustContainOtherStopover?: boolean;
	costByTicketOperator?: string;
	costByTicket?: string;
}

export interface FlightIssueDefaultRuleFormValues {
	isDefaultRule: boolean;
	ruleComment: string;
}
export interface IFlightIssueRuleUpsert {
	id: string;
	priority?: number;
	isDefaultRule: boolean;
	ruleComment: string;
	vcIncludes: string[];
	vcExcludes: string[];
	originIncludes: string[];
	originExcludes: string[];
	stopoverIncludes: string[];
	mustContainOtherStopover?: boolean;
	stopoverExcludes: string[];
	layoverIncludes: string[];
	layoverExcludes: string[];
	gds: GDSEnum[];
	costByTicket?: number;
	costByTicketOperator?: CostByTicketOperatorEnum;
}

export interface IFlightIssueRule {
	id: string;
	priority?: number;
	isDefaultRule: boolean;
	ruleComment: string;
	vcIncludes: string[];
	vcExcludes: string[];
	originIncludes: string[];
	originExcludes: string[];
	stopoverIncludes: string[];
	mustContainOtherStopover?: boolean;
	stopoverExcludes: string[];
	layoverIncludes: string[];
	layoverExcludes: string[];
	gds: GDSEnum[];
	costByTicket?: number;
	costByTicketOperator?: CostByTicketOperatorEnum;
	created: string;
	createdBy: string;
	updated?: string;
	updatedBy?: string;
}

export interface IFlightIssueRuleList {
	page: number;
	size: number;
	total: number;
	sort?: string;
	elements: IFlightIssueRule[];
}

export interface ICheckExistFlightDefaultIssueRule {
	exist: boolean;
}

export const costByTicketOperatorToFilter: SelectFilterListData[] = Object.values(CostByTicketOperatorEnum).map(
	(value) => ({
		id: value,
		name: value,
	}),
);
export const gdsEnumListToFilter: SelectFilterListData[] = Object.values(GDSEnum).map((value) => ({
	id: value,
	name: value,
}));
