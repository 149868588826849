export default [
	{
		code: 'AA',
		name: 'AA-American Airlines',
	},
	{
		code: 'AC',
		name: 'AC-Air Canada',
	},
	{
		code: 'AD',
		name: 'AD-Azul lineas aéreas',
	},
	{
		code: 'AF',
		name: 'AF-Air France',
	},
	{
		code: 'AM',
		name: 'AM-Aeromexico',
	},
	{
		code: 'AR',
		name: 'AR-Aerolíneas Argentinas',
	},
	{
		code: 'AV',
		name: 'AV-Avianca',
	},
	{
		code: 'AZ',
		name: 'AZ-Alitalia',
	},
	{
		code: 'BA',
		name: 'BA-British Airways',
	},
	{
		code: 'CM',
		name: 'CM-Copa Airlines ',
	},
	{
		code: 'DL',
		name: 'DL-Delta Airlines',
	},
	{
		code: 'DM',
		name: 'DM-Arajet',
	},
	{
		code: 'EK',
		name: 'EK-Emirates ',
	},
	{
		code: 'ET',
		name: 'ET-Ethiopian Airlines',
	},
	{
		code: 'EY',
		name: 'EY-Etihad Airlines',
	},
	{
		code: 'FO',
		name: 'FO-Flybondi',
	},
	{
		code: 'G3',
		name: 'G3-Gol lineas aéreas ',
	},
	{
		code: 'GP',
		name: 'GP-APG Airlines',
	},
	{
		code: 'H2',
		name: 'H2-Sky Airlines',
	},
	{
		code: 'HR',
		name: 'HR-Hahn Air',
	},
	{
		code: 'IB',
		name: 'IB-Iberia',
	},
	{
		code: 'JA',
		name: 'JA-Jetsmart',
	},
	{
		code: 'KL',
		name: 'KL-KLM',
	},
	{
		code: 'LA',
		name: 'LA-LATAM Airlines',
	},
	{
		code: 'LH',
		name: 'LH-Lufthansa',
	},
	{
		code: 'LX',
		name: 'LX-Swiss International Air Lines',
	},
	{
		code: 'NK',
		name: 'NK-Spirit Airlines',
	},
	{
		code: 'NZ',
		name: 'NZ-Air New Zealand',
	},
	{
		code: 'OB',
		name: 'OB-Boliviana de Aviación',
	},
	{
		code: 'P5',
		name: 'P5-Wingo',
	},
	{
		code: 'PZ',
		name: 'PZ-TAM',
	},
	{
		code: 'Q4',
		name: 'Q4-Arajet',
	},
	{
		code: 'QR',
		name: 'QR-Qatar Airways',
	},
	{
		code: 'SA',
		name: 'SA-South African Airlines',
	},
	{
		code: 'T0',
		name: 'T0-TACA Perú',
	},
	{
		code: 'TK',
		name: 'TK-Turkish Airlines',
	},
	{
		code: 'TP',
		name: 'TP-TAP Air Portugal',
	},
	{
		code: 'UA',
		name: 'UA-United Airlines',
	},
	{
		code: 'UX',
		name: 'UX-Air Europa',
	},
	{
		code: 'VE',
		name: 'VE-Clic Air',
	},
	{
		code: 'VY',
		name: 'VY-Vueling Airlines',
	},
	{
		code: 'WJ',
		name: 'WJ-Jetsmart',
	},
	{
		code: 'Z8',
		name: 'Z8-Amaszonas',
	},
	{
		code: 'ZP',
		name: 'ZP-Paranair',
	},
	{
		code: '9R',
		name: '9R-Satena',
	},
];
