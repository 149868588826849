import React from 'react';
import { CountryCodeEnum } from '../../../common/types';

import MevueloUYPaymentMethods from './MevueloUy';
import MevueloPYPaymentMethods from './MevueloPy';
import MevueloBOPaymentMethods from './MevueloBo';
import MevueloCOPaymentMethods from './MevueloCo';
import ItauPYPaymentMethods from './ItauPy';

interface Props {
	country: string;
}

export default function PaymentMethods(props: Props): JSX.Element {
	switch (props.country) {
		case CountryCodeEnum.URUGUAY:
			return <MevueloUYPaymentMethods />;
		case CountryCodeEnum.PARAGUAY:
			return <MevueloPYPaymentMethods />;
		case CountryCodeEnum.BOLIVIA:
			return <MevueloBOPaymentMethods />;
		case CountryCodeEnum.COLOMBIA:
			return <MevueloCOPaymentMethods />;
		case CountryCodeEnum.TN_ITAU_PY:
			return <ItauPYPaymentMethods />;
		default:
			return <MevueloUYPaymentMethods />;
	}
}
