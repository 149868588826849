import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import { checkShowErrorMessage } from '../../../common/helpers';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { getOperators } from 'features/transactions/services';
import { IOperators } from 'features/transactions/types';
import { useEffect, useState } from 'react';
import { postETicket } from 'features/authorization/services';

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export interface CreateETicketModalProps {
	authorizationId: number;
	open: boolean;
	handleClose: () => void;
	reloadList: () => void;
}

export function CreateETicketModal({
	authorizationId,
	open,
	handleClose,
	reloadList,
}: CreateETicketModalProps): JSX.Element {
	const postRefundDefaultValues = {
		authorizationId: authorizationId,
		amount: 0,
		eticket: '',
		operator: '',
	};

	const classes = useStyles();
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		reset,
	} = useForm({ mode: 'all', defaultValues: postRefundDefaultValues });
	const [loading, setLoading] = useState<boolean>(false);
	const [operators, setOperators] = useState<IOperators[]>([]);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);

	const onSubmit = async (data: any) => {
		try {
			setLoading(true);
			await postETicket(data);
			setAlert({
				show: true,
				severity: 'success',
				message: 'E-Ticket generado satisfactoriamente',
			});
			reset();
			reloadList();
			handleClose();
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al generar el E-Ticket'),
			});
			setLoading(false);
		}
	};

	const getOperatorsInfo = async () => {
		try {
			setLoading(true);
			setOperators((await getOperators()).data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getOperatorsInfo();
	}, []);

	return (
		<div>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="h5" color="primary">
						Agregar E-Ticket
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container gap={2}>
							<Grid item xs={12}>
								<Controller
									name="eticket"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										maxLength: {
											value: 250,
											message: 'El E-Ticket tiene un máximo de 250 caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="E-Ticket"
											type="text"
											disabled={loading}
											helperText={checkShowErrorMessage(Boolean(errors.eticket), errors.eticket?.message)}
											error={Boolean(errors.eticket)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="amount"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										min: {
											value: 1,
											message: 'El monto debe ser mayor que 0',
										},
										pattern: {
											value: /^(0|[1-9]\d*)(\.\d+)?$/,
											message: 'El valor debe ser numérico',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Monto"
											type="number"
											disabled={loading}
											helperText={checkShowErrorMessage(Boolean(errors.amount), errors.amount?.message)}
											error={Boolean(errors.amount)}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="operator"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Por favor seleccione el Operator del ETicket',
										},
									}}
									render={({ field }) => (
										<FormControl sx={{ width: '100%' }}>
											<InputLabel id="operator-label">Operador</InputLabel>
											<Select
												labelId="operator-label"
												id="operator-select"
												label="Operador"
												disabled={!operators.length || loading}
												sx={{ width: '100%' }}
												{...field}
											>
												{operators.map((operator) => {
													return (
														<MenuItem key={operator.value} value={operator.value}>
															{operator.description}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={12} display={'flex'} gap={'10px'} justifyContent="flex-end">
								<Button variant="contained" disabled={loading || !isValid} color="success" fullWidth type="submit">
									Confirmar
								</Button>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</div>
	);
}
