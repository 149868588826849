import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

export interface HotelNameFilterProps {
	onSearch: (value: string) => void;
}

export const HotelNameFilter = (props: HotelNameFilterProps): JSX.Element => {
	const [name, setName] = useState<string>('');

	useEffect(() => props.onSearch(name), [name]);

	return (
		<Paper>
			<Grid container sx={{ display: 'grid', marginLeft: 2, marginTop: 4 }}>
				<Grid item xs={12}>
					<Typography variant="h6" fontWeight="bold">
						Nombre del Hotel
					</Typography>
				</Grid>
				<Grid item xs={11} sx={{ border: 1, borderRadius: 1, color: 'Grey' }}>
					<InputBase
						sx={{ ml: 1, flex: 1 }}
						value={name}
						onChange={(event) => setName(event.target.value)}
						placeholder="Nombre del Hotel"
					/>
					<IconButton type="button" sx={{ p: '10px' }} aria-label="search">
						<SearchIcon />
					</IconButton>
				</Grid>
			</Grid>
		</Paper>
	);
};
