import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { doLogin } from '../../slice';
import {
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import { LoadingButton } from 'features/common/components/LoadingButton';

interface LoginFormValues {
	username: string;
	password: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			padding: theme.spacing(4),
			width: '80%',
			maxWidth: '30rem',
		},
		passwordField: {
			paddingLeft: '12px',
		},
	}),
);

export const LoginForm: FC = (props) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginFormValues>();
	const classes = useStyles();
	const { status, errorMessage } = useSelector((state: RootState) => state.auth);
	const dispatch = useDispatch();

	const handlePasswordToggle = () => setShowPassword((prev) => !prev);

	const onSubmit = (form: LoginFormValues) => {
		dispatch(doLogin({ password: form.password, login: form.username }));
	};

	return (
		<Paper elevation={3} className={classes.paper} {...props}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container direction="column" justifyContent="center" spacing={3}>
					<Typography variant="h6" align="center">
						Login | T-1000 mevuelo.com
					</Typography>
					<Grid item xs={12}>
						<TextField
							fullWidth
							variant="filled"
							label="Usuario"
							error={!!errors.username}
							helperText={errors.username && 'Usuario es requerido'}
							{...register('username')}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth error={!!errors.password}>
							<InputLabel htmlFor="password">Contraseña</InputLabel>
							<Input
								id="password"
								{...register('password')}
								type={showPassword ? 'text' : 'password'}
								className={classes.passwordField}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={handlePasswordToggle} size="large">
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
							{errors.password && <FormHelperText>Contraseña es requerido</FormHelperText>}
						</FormControl>
					</Grid>
					{status === 'failed' && (
						<Grid item>
							<FormControl error>
								<FormHelperText>{errorMessage}</FormHelperText>
							</FormControl>
						</Grid>
					)}
					<Grid item xs={12}>
						<LoadingButton variant="contained" color="primary" fullWidth type="submit" loading={status === 'loading'}>
							Ingresar
						</LoadingButton>
					</Grid>
				</Grid>
			</form>
		</Paper>
	);
};
