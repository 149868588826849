import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LocationOn from '@mui/icons-material/LocationOn';
import Rating from '@mui/material/Rating';
import PoolIcon from '@mui/icons-material/Pool';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WifiIcon from '@mui/icons-material/Wifi';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import PlaneIcon from '@mui/icons-material/Flight';
import DropDownIcon from '@mui/icons-material/ArrowDropDown';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { Button, CardActions } from '@mui/material';
import { HotelFareTabEnum, IOpinion, ISummaryOpinion } from '../../types';
import { HotelFareDescriptionPanel } from './descriptionPanel';
import { ArrowDropUp } from '@mui/icons-material';
import { HotelFarePhotosPanel } from './photosPanel';
import { HotelMap } from './mapPanel';
import { OpinionsPanel } from './opinionsPanel';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			width: '100%',
		},
		details: {
			flex: '4 0 auto',
			display: 'flex',
			flexDirection: 'column',
			maxWidth: '67%',
		},
		cover: {
			width: '33%',
			height: 'auto',
		},
		facilities: {
			display: 'flex',
			color: 'secondary',
			flexDirection: 'column',
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			placeContent: 'space-evenly',
		},
		flex: {
			display: 'flex',
		},
		map: {
			width: '30%',
		},
		ratingMolecule: {
			display: 'flex',
			alignItems: 'center',
			paddingTop: theme.spacing(1),
		},
		ratingText: {
			paddingLeft: theme.spacing(2),
		},
		powered: {
			paddingLeft: theme.spacing(2),
			width: '115px',
		},
		hotelStars: {
			display: 'flex',
			paddingTop: theme.spacing(1),
		},
		webSiteContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		fareDetails: {
			display: 'flex',
			position: 'relative',
			flexDirection: 'column',
			alignItems: 'flex-start',
			'&.MuiCardActions-root>:not(:first-of-type)': {
				marginLeft: 0,
			},
			backgroundColor: '#FFEBC8',
		},
		prices: {
			display: 'flex',
			width: '100%',
			flexDirection: 'column',
			alignItems: 'flex-end',
		},
		buttonGroup: {
			display: 'flex',
			width: '100%',
			alignItems: 'flex-start',
			gap: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		bottomDetails: {
			width: '100%',
			marginTop: 'auto',
		},
		tabsButton: {
			backgroundColor: '#D9EEF9',
			color: theme.palette.info.main,
		},
	}),
);
interface Props {
	title: string;
	address: string;
	category: number;
	rating: number | undefined;
	ratingText: string | undefined;
	commentsCount: number | undefined;
	link: string | undefined;
	mainImage: string;
	pool: boolean;
	airConditioning: boolean;
	wifi: boolean;
	gym: boolean;
	parking: boolean;
	roomType: string;
	cancelation: {
		cancelable: boolean;
		cancelationMarkup: number | undefined;
	};
	packagingFares: boolean;
	farePrice: {
		coinCode: string;
		originalPrice: number;
		promotionalPrice: number | undefined;
	};
	description: {
		mainText: string;
		internet?: {
			components: string[];
		};
		services?: {
			components: string[];
		};
		entertainment?: {
			components: string[];
		};
	};
	photos: string[];
	map: {
		lat: number;
		lng: number;
	};
	opinions: {
		summaryOpinion: ISummaryOpinion;
		opinions: IOpinion[];
	};
}

export const HotelFareCard = (props: Props): JSX.Element => {
	const classes = useStyles();
	const [openedTab, setOpenedTab] = useState<number | null>(null);

	const handleTabClick = (value: number) => {
		if (openedTab && openedTab == value) {
			setOpenedTab(null);
		} else {
			setOpenedTab(value);
		}
	};

	return (
		<div>
			<Card className={classes.root}>
				<CardMedia className={classes.cover} image={props.mainImage} title={props.title} />
				<div className={classes.details}>
					<CardContent>
						<Typography component="h5" variant="h5" fontWeight="bold">
							{props.title}
						</Typography>
						<div className={classes.flex}>
							<LocationOn color="secondary" />
							<Typography variant="subtitle1" color="textSecondary">
								{props.address}
							</Typography>
						</div>
						<div className={classes.hotelStars}>
							<Rating name="read-only" value={props.category} readOnly />
						</div>
						<div className={classes.facilities}>
							{props.pool && (
								<span>
									<PoolIcon color="secondary" /> Piscina{' '}
								</span>
							)}
							{props.wifi && (
								<span>
									<WifiIcon color="secondary" /> Wi-fi
								</span>
							)}
							{props.airConditioning && (
								<span>
									<AcUnitIcon color="secondary" /> Aire Acondicionado
								</span>
							)}
							{props.parking && (
								<span>
									<LocalParkingIcon color="secondary" /> Estacionamiento
								</span>
							)}
							{props.gym && (
								<span>
									<FitnessCenterIcon color="secondary" /> Gimnasio
								</span>
							)}
						</div>
						<div className={classes.buttonGroup}>
							<Button
								onClick={() => handleTabClick(HotelFareTabEnum.MAP)}
								className={classes.tabsButton}
								variant="contained"
								endIcon={openedTab === HotelFareTabEnum.MAP ? <ArrowDropUp /> : <DropDownIcon />}
							>
								{' '}
								Mapa{' '}
							</Button>
							<Button
								onClick={() => handleTabClick(HotelFareTabEnum.PHOTOS)}
								className={classes.tabsButton}
								variant="contained"
								endIcon={openedTab === HotelFareTabEnum.PHOTOS ? <ArrowDropUp /> : <DropDownIcon />}
							>
								{' '}
								Fotos{' '}
							</Button>
							<Button
								onClick={() => handleTabClick(HotelFareTabEnum.DESCRIPTION)}
								className={classes.tabsButton}
								variant="contained"
								endIcon={openedTab === HotelFareTabEnum.DESCRIPTION ? <ArrowDropUp /> : <DropDownIcon />}
							>
								{' '}
								Descripción{' '}
							</Button>
							<Button
								onClick={() => handleTabClick(HotelFareTabEnum.OPINIONS)}
								className={classes.tabsButton}
								variant="contained"
								endIcon={openedTab === HotelFareTabEnum.OPINIONS ? <ArrowDropUp /> : <DropDownIcon />}
							>
								{' '}
								Opiniones{' '}
							</Button>
						</div>
					</CardContent>
				</div>
				<CardActions className={classes.fareDetails}>
					<Typography component="p" fontSize={20} fontWeight="bold" color="secondary">
						{props.roomType}
					</Typography>
					{props.cancelation.cancelable ? (
						<Typography display="flex" gap={1} justifyContent="center">
							<CheckIcon color="disabled" />
							{props.cancelation.cancelationMarkup && props.cancelation.cancelationMarkup}% de gastos de cancelación
						</Typography>
					) : (
						<Typography display="flex" gap={1} justifyContent="center">
							<CancelIcon color="disabled" />
							No Cancelable
						</Typography>
					)}
					{props.packagingFares && (
						<Typography display="flex" gap={1} justifyContent="center">
							<PlaneIcon color="disabled" />
							Tarifas para Empaquetar
						</Typography>
					)}
					<div className={classes.bottomDetails}>
						{props.farePrice.promotionalPrice ? (
							<div className={classes.prices}>
								<Typography component="p" variant="h6" color="secondary" sx={{ textDecoration: 'line-through' }}>
									{props.farePrice.originalPrice} {props.farePrice.coinCode}
								</Typography>
								<Typography component="p" variant="h4" color="secondary">
									{props.farePrice.promotionalPrice} {props.farePrice.coinCode}
								</Typography>
							</div>
						) : (
							<Typography component="p" variant="h4" color="secondary">
								{props.farePrice.originalPrice}
							</Typography>
						)}
						<Button sx={{ width: '100%' }} color="secondary" variant="contained">
							{' '}
							Reservar{' '}
						</Button>
						<Button sx={{ width: '100%' }} color="primary" size="small" endIcon={<DropDownIcon />}>
							Más tarifas y regimenes (15)
						</Button>
					</div>
				</CardActions>
			</Card>
			{openedTab === HotelFareTabEnum.MAP && <HotelMap map={props.map} />}
			{openedTab === HotelFareTabEnum.PHOTOS && <HotelFarePhotosPanel photos={props.photos} />}
			{openedTab === HotelFareTabEnum.DESCRIPTION && <HotelFareDescriptionPanel description={props.description} />}
			{openedTab === HotelFareTabEnum.OPINIONS && (
				<OpinionsPanel opinions={props.opinions.opinions} summaryOpinion={props.opinions.summaryOpinion} />
			)}
		</div>
	);
};
