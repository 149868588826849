import React, { useEffect, useState } from 'react';
import { Alert, AlertColor, Backdrop, Box, CircularProgress, Fab, Grid, Snackbar } from '@mui/material';
import { extractErrorMessage, handleViewQuotation } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import {
	IDestinationPackageCompositeDetailWithHotelDetail,
	IHotelDetail,
	IHotelsInfoForPackageCompositeDetailPowerWithDetail,
	INomenclator,
	IPackageCompositeDetailForPower,
	IPackagePriceByTravelDate,
	packageBasePrices,
} from '../../types';
import { packageCompositeDetailByTravelDate } from '../../services';
import Typography from '@mui/material/Typography';
import { formatDateToShowUser } from '../../../common/helpers';
import { AxiosResponse } from 'axios';
import { HotelDetail } from '../../../hotels/types';
import { getHotelDetail } from '../../../hotels/components/services';
import { PackageCompositeRouteDetail } from './PackageCompositeRouteDetail';
import { PackageCompositeDestinationsDetails } from './PackageCompositeDestinationsDetails';
import { PackageCompositeServicesDetails } from './PackageCompositeServicesDetails';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CreateQuotationFromPackageDialog } from '../../../quotation/components/CreateQuotationFromPackageDialog';
import { clearQuotation } from '../../../quotation/slice';
import { useDispatch } from 'react-redux';
import { CreateQuotationFromPackageWithManualRouteDialog } from '../../../quotation/components/CreateQuotationFromPackageWithManualRouteDialog';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	headerTitle: {
		fontSize: '13px',
		fontWeight: 'bold',
		display: 'inline',
		color: theme.palette.primary.main,
	},
	contentDescription: {
		fontSize: '13px',
		display: 'inline',
		marginLeft: '10px',
		color: theme.palette.primary.main,
	},
}));

export interface PackageCompositeDetailProps {
	packageSelected: INomenclator;
	packagePriceByTravelDate: IPackagePriceByTravelDate;
}

export const PackageCompositeDetailByTravelDate = ({
	packageSelected,
	packagePriceByTravelDate,
}: PackageCompositeDetailProps): JSX.Element => {
	const classes = useStyles();
	const [details, setDetails] = useState<IPackageCompositeDetailForPower | null>(null);
	const [destinationsDetails, setDestinationsDetails] = useState<IDestinationPackageCompositeDetailWithHotelDetail[]>(
		[],
	);
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [hotelSaved, setHotelSaved] = React.useState<IHotelDetail[]>([]);
	const [openDialogCreateQuotation, setOpenDialogCreateQuotation] = React.useState<boolean>(false);
	const dispatch = useDispatch();

	const getPackageCompositeDetailsByTravelDate = async () => {
		try {
			setLoading(true);
			const packagesDetails = (
				await packageCompositeDetailByTravelDate(packageSelected.id, packagePriceByTravelDate.travelDate)
			).data;
			setDetails(packagesDetails);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir el detalle de la composición del paquete'),
			});
			setLoading(false);
		}
	};

	const loadDetailOfHotels = async () => {
		if (details) {
			const newDestinations = [];
			const hotelToSave: IHotelDetail[] = [];
			for (const destination of details.destinations) {
				const promisesHotelDetail: Promise<AxiosResponse<HotelDetail>>[] = [];

				destination.hotels.forEach((hotelOption) => {
					const maybeExistHotel = hotelSaved.find(
						(hotel) => hotel.externalId == hotelOption.externalId && hotel.provider == hotelOption.provider,
					);
					if (maybeExistHotel) {
						// @ts-ignore
						promisesHotelDetail.push(Promise.resolve({ data: maybeExistHotel.details }));
					} else {
						promisesHotelDetail.push(getHotelDetail(hotelOption.externalId, hotelOption.provider));
					}
				});
				const resultPromise = await Promise.all(promisesHotelDetail);
				const hotelOptions: IHotelsInfoForPackageCompositeDetailPowerWithDetail[] = [];
				destination.hotels.forEach((hotelOption, indexHotelOption) => {
					const indexHotel = hotelSaved.findIndex(
						(hotel) => hotel.externalId == hotelOption.externalId && hotel.provider == hotelOption.provider,
					);
					if (indexHotel === -1) {
						hotelToSave.push({
							externalId: hotelOption.externalId,
							provider: hotelOption.provider,
							details: resultPromise[indexHotelOption].data,
						});
					}
					hotelOptions.push({
						...hotelOption,
						details: resultPromise[indexHotelOption].data,
					} as IHotelsInfoForPackageCompositeDetailPowerWithDetail);
				});
				newDestinations.push({
					...destination,
					hotels: hotelOptions,
				} as IDestinationPackageCompositeDetailWithHotelDetail);
				setDestinationsDetails([...newDestinations]);
				setHotelSaved([...hotelSaved, ...hotelToSave]);
			}
		}
	};

	const handleCreateNewQuotation = () => {
		dispatch(clearQuotation());
		setOpenDialogCreateQuotation(true);
	};
	const handleSuccessCreateQuotation = (id: number) => {
		setOpenDialogCreateQuotation(false);
		setAlert({
			show: true,
			severity: 'success',
			message: 'Cotización creada sastifactoriamente',
		});
		setTimeout(() => {
			handleViewQuotation(id, false)();
		}, 2000);
	};

	useEffect(() => {
		getPackageCompositeDetailsByTravelDate();
	}, [packageSelected.id, packagePriceByTravelDate]);

	useEffect(() => {
		loadDetailOfHotels();
	}, [details]);

	useEffect(() => {
		setHotelSaved([]);
	}, [packageSelected.id]);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{details && (
				<>
					<Grid container marginTop={2}>
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Fecha seleccionada:</Typography>
							<Typography className={classes.contentDescription}>
								{formatDateToShowUser(packagePriceByTravelDate.travelDate)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Precio para la fecha seleccionada:</Typography>
							<Typography className={classes.contentDescription}>USD {packagePriceByTravelDate.priceInUSD}</Typography>
						</Grid>

						<Grid item xs={12} marginTop={2}>
							<Typography color="primary" variant={'h6'} fontWeight="bold" py={1} px={1}>
								Composición del paquete
							</Typography>
						</Grid>
					</Grid>
					<PackageCompositeRouteDetail route={details.route} />
					<PackageCompositeDestinationsDetails destinations={destinationsDetails} />
					{details.services.length > 0 && <PackageCompositeServicesDetails services={details.services} />}
					<Grid container marginTop={2}>
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>
								Costo total en base {packageBasePrices[details.basePrice - 1]?.name || ''}:
							</Typography>
							<Typography className={classes.contentDescription}>USD {details.cost}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>
								PVP en base {packageBasePrices[details.basePrice - 1]?.name || ''}:
							</Typography>
							<Typography className={classes.contentDescription}>USD {details.pvp}</Typography>
						</Grid>

						{details.privateNotes && (
							<>
								{' '}
								<Grid item xs={12} marginTop={2}>
									<Typography color="primary" variant={'h6'} fontWeight="bold" py={1} px={1}>
										Notas privadas del paquete
									</Typography>
								</Grid>
								<Grid item xs={12} marginTop={1}>
									<div dangerouslySetInnerHTML={{ __html: details.privateNotes }}></div>
								</Grid>
							</>
						)}
					</Grid>
					{details && destinationsDetails.length > 0 && !loading && (
						<Box zIndex="tooltip" position="fixed" bottom={150} right={30}>
							<Fab
								size="small"
								color="secondary"
								aria-label="Crear nueva cotización"
								title={'Crear nueva cotización'}
								onClick={handleCreateNewQuotation}
							>
								<AddCircleIcon />
							</Fab>
						</Box>
					)}
					{openDialogCreateQuotation &&
						details &&
						destinationsDetails.length > 0 &&
						!loading &&
						details.route.isAutomaticRoute && (
							<CreateQuotationFromPackageDialog
								packageSelected={packageSelected}
								open={openDialogCreateQuotation}
								packageDetail={details}
								onSuccessCreateQuotation={handleSuccessCreateQuotation}
								travelDate={packagePriceByTravelDate.travelDate}
								onClose={() => setOpenDialogCreateQuotation(false)}
							/>
						)}
					{openDialogCreateQuotation &&
						details &&
						destinationsDetails.length > 0 &&
						!loading &&
						!details.route.isAutomaticRoute &&
						details.route.manualRouteFarePrice && (
							<CreateQuotationFromPackageWithManualRouteDialog
								packageSelected={packageSelected}
								open={openDialogCreateQuotation}
								packageDetail={details}
								onSuccessCreateQuotation={handleSuccessCreateQuotation}
								travelDate={packagePriceByTravelDate.travelDate}
								onClose={() => setOpenDialogCreateQuotation(false)}
							/>
						)}
				</>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
