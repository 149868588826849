import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Select, MenuItem, FormControl, FormHelperText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';

// import { useStyles } from '../InsuranceServiceForm/style';
import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/components/InsuranceServiceForm/style';
import { checkShowErrorMessage } from 'features/common/helpers';
import {
	typeUnit,
	ICodeName,
	IServiceCostVariationLogs,
	IOtherService,
	ServiceUnitEnum,
	TypeServiceEnum,
} from 'features/salesOrder/types';
import { ControlledSelect } from 'features/common/components/ControlledSelect';
import { ControlledAutoComplete } from 'features/common/components/ControlledAutoComplete';
import { ControlledTextField } from 'features/common/components/ControlledTextField';
import { ControlledCheckBox } from 'features/common/components/ControlledCheckBox';
import { ControlledDatePicker } from 'features/common/components/ControlledDatePicker';
import {
	excludeTypeUnits,
	filterServiceCostVariationLogs,
	calcularPrecioFinal,
	calculatMarkupPercentaje,
} from 'features/salesOrder/components/SalesOrderServiceForm/helpers';
import { AutocompleteProviders } from 'features/common/components/AutocompleteProviders';
import { ServiceCostVariationLogs } from 'features/salesOrder/components/SalesOrderServiceForm/components/ServiceCostVariationLogs';
import { getCostVariationLogsByService, putServiceForm } from 'features/salesOrder/services';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from 'features/common/types';

const format = (date: string) => {
	// const arrayDate = date.split('-');
	// return `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
	return date;
};

interface Props {
	index: number;
	passengers: { keyId: string; name: string; lastName: string }[];
	service: IOtherService;
	onUpdateService: () => void;
	currencyesList: ICodeName[];
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
}
const iconMapping = {
	Persona: <PersonIcon />,
	Grupo: <GroupIcon />,
};

export const OtherService = ({
	index,
	passengers,
	service,
	currencyesList,
	onUpdateService,
	onSetAlert,
	onSetLoading,
}: Props): JSX.Element => {
	const classes = useStyles();
	const methods = useForm();
	const {
		control,
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = methods;

	const idService = service.keyId;

	const [tariffProviderId, setTariffProviderId] = useState('n/a');
	const { id: saleOrderId } = useParams<{ id: string }>();
	const [serviceCostVariationLogs, setServiceCostVariationLogs] = useState<IServiceCostVariationLogs[]>([]);
	const filteredArray = filterServiceCostVariationLogs(serviceCostVariationLogs, idService);

	const loadCostLog = useCallback(async () => {
		const result = await getCostVariationLogsByService(saleOrderId, idService);
		setServiceCostVariationLogs(result.data);
	}, [setServiceCostVariationLogs]);

	useEffect(() => {
		loadCostLog();
	}, [loadCostLog, service]);

	const handleCostChange = (newCost: number) => {
		// @ts-ignore
		setValue('cost', newCost, { shouldDirty: true });
		const saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;
		const newMarkup = calculatMarkupPercentaje(newCost, saleAmount) as string;
		// @ts-ignore
		setValue('markupPercentage', newMarkup, { shouldDirty: true });
		calculateTotalCost();
	};

	const handleSaleAmountChange = (newValue: number) => {
		// @ts-ignore
		setValue('saleAmount', newValue, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		if (newValue === 0) {
			setValue('markupPercentage', 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, newValue) as string;

			// @ts-ignore
			setValue('markupPercentage', newMarkup, { shouldDirty: true });
		}

		calculateTotalCost();
	};

	const handleMarkupPercentageChange = (newValue: number) => {
		// @ts-ignore
		setValue('markupPercentage', newValue, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		const newSaleAmount = calcularPrecioFinal(cost, newValue) as string;
		// @ts-ignore
		setValue('saleAmount', newSaleAmount, { shouldDirty: true });
		calculateTotalCost();
	};

	const sameTariffServiceProvider = watch('sameTariffServiceProvider' as `${string}.${string | number}`, false);

	const passengersSelected = watch('passengerIds' as `${string}.${string | number}`) || [];
	const unit = watch('unit' as `${string}.${string | number}`);

	const calculateTotalCost = useCallback(() => {
		let cost = getValues('cost' as `${string}.${string | number}`) || 0;
		let saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;

		if (unit === 'Person') {
			cost = cost * passengersSelected.length;
			saleAmount =
				saleAmount * passengersSelected.length || (saleAmount ? saleAmount * passengersSelected.length : null);
		}

		setValue('totalCost' as `${string}.${string | number}`, cost);
		setValue('totalSaleAmount' as `${string}.${string | number}`, saleAmount);
	}, [getValues, passengersSelected, setValue, unit]);

	const handleUpdateService = async (data: IOtherService) => {
		try {
			onSetLoading(true);
			data.startDate = format(data.startDate);
			data.endDate = format(data.endDate);
			data.limitDate = format(data.limitDate);

			// @ts-ignore
			if (data.tariffProviderId === 'n/a') {
				onSetAlert({
					show: true,
					severity: 'warning',
					message: 'Elije un proveedor de Tarifa',
				});
				// @ts-ignore
				delete data.tariffProviderId;
			}

			// @ts-ignore
			if (data.serviceProviderId === 'n/a' && !data.sameTariffServiceProvider) {
				onSetAlert({
					show: true,
					severity: 'warning',
					message: 'Elije un proveedor de Servicio',
				});
			}
			// @ts-ignore
			if (data.serviceProviderId === 'n/a' && data.sameTariffServiceProvider) {
				// @ts-ignore
				data.serviceProviderId = data.tariffProviderId;
			}

			if (data.totalSaleAmount === null) {
				// @ts-ignore
				data.totalSaleAmount = 0;
			}

			data.serviceType = TypeServiceEnum.Other;

			await putServiceForm({ saleOrderId, serviceId: data.keyId, data });

			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Otros guardado con exito!',
			});

			onUpdateService();
		} catch (e) {
			// @ts-ignore
			alert(e.response.data.message);
		} finally {
			onSetLoading(false);
		}
	};

	const typeOptions = [
		{ name: 'Asistencia en viajes o Seguros', value: 'seguros' },
		{ name: 'Actividades, paseos o excursiones de solo 1 día', value: 'actividades_menos1dia' },
		{ name: 'Paquetes o excursiones de más de 1 día', value: 'pkts_o_exc_mas1dia' },
		{ name: 'Ancillaries de vuelos', value: 'flight_ancillaries' },
		{ name: 'Ferry (ej. Buquebus, Baleares, etc)', value: 'ferry' },
		{ name: 'Alquiler de auto', value: 'rent_a_car' },
		{ name: 'Crucero', value: 'crucero' },
		{ name: 'Circuito', value: 'circuito' },
		{ name: 'Eventos o Conciertos con entradas', value: 'evento_o_concierto' },
		{ name: 'Entradas a espectáculos o parques', value: 'entradas' },
		{ name: 'Visas u otros Documentos', value: 'documentos' },
		{ name: 'Merchandising mevuelo.com', value: 'merchandising' },
		{ name: 'Devoluciones', value: 'devolucion' },
		{ name: 'ADMs o Penalidades', value: 'adm_o_penalizacion' },
		{ name: 'Fee', value: 'fee' },
		{ name: 'Optimización', value: 'opt' },
		{ name: 'Otros (detallar en Nombre y Notas internas)', value: 'otros_servicios' },
		{ name: 'Costo de medio de pago', value: 'medio_de_pago' },
		{ name: 'Costo de transferencia', value: 'transferencia' },
	];

	useEffect(() => {
		if (sameTariffServiceProvider) {
			setValue('serviceProviderId' as `${string}.${string | number}`, tariffProviderId);
		}
		if (!sameTariffServiceProvider) {
			setValue('sameTariffServiceProvider' as `${string}.${string | number}`, false);
		}
	}, [sameTariffServiceProvider, tariffProviderId]);

	useEffect(() => {
		const limitDateField = document.querySelector('input[name="limitDate"]');
		const startDate = document.querySelector('input[name="startDate"]');
		const endDate = document.querySelector('input[name="endDate"]');
		if (limitDateField) {
			// @ts-ignore
			limitDateField.value = '';
		}
		if (startDate) {
			// @ts-ignore
			startDate.value = '';
		}
		if (endDate) {
			// @ts-ignore
			endDate.value = '';
		}

		const effectTariffProviderId =
			typeof service.tariffProvider === 'string'
				? service.tariffProvider || 'n/a'
				: service.tariffProvider?.id || 'n/a';
		setTariffProviderId(effectTariffProviderId);
		const effectServiceProviderId = service.serviceProvider?.id || 'n/a';
		setValue('reference' as `${string}.${string | number}`, service.reference || '');
		setValue('tariffProviderId' as `${string}.${string | number}`, effectTariffProviderId);
		setValue('serviceProviderId' as `${string}.${string | number}`, effectServiceProviderId);
		setValue(
			'unit' as `${string}.${string | number}`,
			service.unit.length === 0 ? ServiceUnitEnum.Person : service.unit,
			{ shouldDirty: true },
		);
		setValue('cost' as `${string}.${string | number}`, service.cost || 0);
		setValue('keyId' as `${string}.${string | number}`, service.keyId);
		setValue('saleAmount' as `${string}.${string | number}`, service.saleAmount || 0);
		setValue('currency' as `${string}.${string | number}`, service.currency || 'USD');
		setValue('markupPercentage' as `${string}.${string | number}`, service.markupPercentage || 0);
		setValue('voucherNotes' as `${string}.${string | number}`, service.voucherNotes || '');
		setValue('internalNotes' as `${string}.${string | number}`, service.internalNotes || '');
		setValue('passengerIds' as `${string}.${string | number}`, service.passengerIds || '');

		setValue('sameTariffServiceProvider' as `${string}.${string | number}`, service.sameTariffServiceProvider || false);
		setValue('otherServiceType' as `${string}.${string | number}`, service.otherServiceType || '');
		setValue('serviceName' as `${string}.${string | number}`, service.serviceName || '');
		setValue('startDate' as `${string}.${string | number}`, service.startDate || '');
		setValue('endDate' as `${string}.${string | number}`, service.endDate || '');
		setValue('limitDate' as `${string}.${string | number}`, service.limitDate || '');

		service.cost && handleCostChange(Number(service.cost || 0));
		service.saleAmount && handleSaleAmountChange(Number(service.saleAmount || 0));

		// handleCostChange(Number(service.cost));
		// handleSaleAmountChange(Number(service.saleAmount));
	}, [setValue, index, service]);

	useEffect(() => {
		setValue('currency' as `${string}.${string | number}`, 'USD');
	}, [setValue]);

	const [selectedValue, setSelectedValue] = useState('');

	useEffect(() => {
		// Suponiendo que `service.otherServiceType` es el valor que viene de la API
		if (service && service.otherServiceType) {
			setSelectedValue(service.otherServiceType);
		}
	}, [service]);

	const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setSelectedValue(event.target.value);
	};

	calculateTotalCost();

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit((data) => {
					handleUpdateService(data as IOtherService);
				})}
			>
				<div>
					<Typography variant="h6">Descripción</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'} mb={1}>
								Pasajeros
							</Typography>
							<ControlledAutoComplete
								name={'passengerIds'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={passengers}
								isMultiple={true}
								helperText={checkShowErrorMessage(Boolean(errors.passengerIds), errors.passengerIds?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>Referencia</Typography>
							<ControlledTextField
								name={'reference'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								helperText={checkShowErrorMessage(Boolean(errors.reference), errors.reference?.message)}
							/>
						</Grid>
						<Grid container spacing={2} className={classes.containerSelect}>
							<Grid item sm={4}>
								<Typography color={'Grey'}>Proveedor de tarifas</Typography>
								<AutocompleteProviders
									name={'tariffProviderId'}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
									}}
								/>
							</Grid>
							<Grid item sm={4} display="flex" justifyContent="center" alignItems="center">
								<ControlledCheckBox name={'sameTariffServiceProvider'} label="Mismo proveedor de tarifa/servicio" />
							</Grid>

							{!sameTariffServiceProvider && (
								<Grid item sm={4}>
									<Typography color={'Grey'}>Prestador de servicio</Typography>
									<AutocompleteProviders
										name={'serviceProviderId'}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
									/>
								</Grid>
							)}
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Tipo</Typography>
							<FormControl size="small" fullWidth error={Boolean(errors.otherServiceType)}>
								<Controller
									name="otherServiceType"
									control={control}
									render={({ field }) => (
										<Select
											{...field}
											value={selectedValue}
											onChange={(e) => {
												handleChange(e);
												field.onChange(e);
											}}
										>
											{typeOptions.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.name}
												</MenuItem>
											))}
										</Select>
									)}
								/>
								{errors.otherServiceType && <FormHelperText>{errors.otherServiceType.message}</FormHelperText>}
							</FormControl>
							{/* <Typography color={'Grey'}>Tipo</Typography>
							<ControlledSelect
								name={'otherServiceType'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={typeOptions}
							/> */}
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Nombre del servicio</Typography>
							<ControlledTextField
								name={'serviceName'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								helperText={checkShowErrorMessage(Boolean(errors.serviceName), errors.serviceName?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Fecha inicial</Typography>
							<ControlledDatePicker
								name={'startDate'}
								rules={{ required: 'Este campo es requerido' }}
								required={true}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Fecha final</Typography>
							<ControlledDatePicker name={'endDate'} rules={{ required: 'Este campo es requerido' }} required={true} />
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Fecha limite</Typography>
							<ControlledDatePicker
								name={'limitDate'}
								rules={{ required: 'Este campo es requerido' }}
								inputFormat="dd MMM, yyyy"
								required={true}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Unidad por</Typography>
							<ControlledSelect
								name={'unit'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={excludeTypeUnits(typeUnit, ['Habitacion', 'Vehiculo', 'Camarote'])}
								iconTypeTrip={iconMapping}
							/>
						</Grid>
						<Grid item xs={12} sm={1}>
							<Typography color={'Grey'}>Moneda</Typography>
							<ControlledSelect
								name={'currency'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={currencyesList.map((item) => ({ id: item.code.toString(), name: item.code }))}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Costo</Typography>
							<ControlledTextField
								name={'cost'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								onChange={(value) => handleCostChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.cost), errors.cost?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Markup %</Typography>
							<ControlledTextField
								name={'markupPercentage'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								isNumber
								onChange={(value) => handleMarkupPercentageChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.markupPercentage), errors.markupPercentage?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Venta</Typography>
							<ControlledTextField
								isNumber
								name={'saleAmount'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								onChange={(value) => handleSaleAmountChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.saleAmount), errors.saleAmount?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Costo Total</Typography>
							<ControlledTextField name={'totalCost'} disabled />
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Venta Total</Typography>
							<ControlledTextField name={'totalSaleAmount'} disabled />
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>Notas de voucher</Typography>
							<Controller
								// @ts-ignore
								name={'voucherNotes'}
								control={control}
								defaultValue="Favor de presentar voucher en el check-in"
								render={({ field }) => (
									<TextField
										fullWidth
										className={classes.textField}
										variant="outlined"
										multiline
										rows={5}
										{...field}
										helperText={checkShowErrorMessage(Boolean(errors.voucherNotes), errors.voucherNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>
								<LockIcon /> Notas internas
							</Typography>
							<Controller
								// @ts-ignore
								name={'internalNotes'}
								control={control}
								render={({ field }) => (
									<TextField
										fullWidth
										className={classes.textField}
										variant="outlined"
										multiline
										rows={5}
										{...field}
										helperText={checkShowErrorMessage(Boolean(errors.voucherNotes), errors.voucherNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<ServiceCostVariationLogs filteredArray={filteredArray} />
					</Grid>
				</div>
				{(service.enable || service.enable === undefined) && (
					<Box display="flex" flexDirection="row-reverse" mt={2}>
						<Button type="submit" variant="contained" color="success">
							Guardar
						</Button>
					</Box>
				)}
			</form>
		</FormProvider>
	);
};
