import React from 'react';
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Paper,
	Radio,
	Theme,
	Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { FlightsTableDesktop } from './FlightsTableDesktop';
import { Air, AirSimple } from '../../types';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { FlightMobile } from './FlightMobile';
import FlightImagePreviewList from './FileImagePreviewList';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(2),
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

interface AirDisplayProps {
	data: Air | AirSimple;
	previewMode: boolean;
	index: number;
	showSelectionControl?: boolean;
	indexSelected?: number | null;
	handleSelectionChange?: (index: number | null) => void;
	handleDeleteFlight?: (index: number) => void;
}

export const AirDisplay = ({
	data,
	previewMode,
	index,
	showSelectionControl,
	indexSelected,
	handleSelectionChange,
	handleDeleteFlight,
}: AirDisplayProps): JSX.Element | null => {
	if (!data) return null;
	const classes = useStyles();
	const isMediumDown = useBreakpoints().mediumDown;
	const hasImages = data.images.length > 0;
	const hasFlightsInfo = Array.isArray(data?.data) && data?.data.length > 0;
	const handleClearAirConfig = () => {
		handleDeleteFlight && handleDeleteFlight(index);
	};

	const HeaderFlightSelection = () => (
		<Box
			bgcolor="primary.main"
			color="secondary.main"
			onClick={() => handleSelectionChange && handleSelectionChange(index)}
			sx={{
				display: 'flex',
				cursor: 'pointer',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
				opacity: index === indexSelected ? 0.9 : 0.7,
			}}
		>
			<Typography component="h6" variant="h6" align={'left'} ml={1}>
				Opción {index + 1}
			</Typography>
			<Radio
				style={{ marginLeft: 'auto' }}
				checked={indexSelected == index}
				onChange={() => handleSelectionChange && handleSelectionChange(index)}
				sx={{ color: 'inherit', '&.Mui-checked': { color: 'inherit' } }}
			/>
		</Box>
	);

	return (
		<Paper sx={{ marginBottom: '20px' }}>
			<div className={classes.content}>
				<div>
					{hasFlightsInfo && !isMediumDown && (
						<div>
							{showSelectionControl && <HeaderFlightSelection />}
							<FlightsTableDesktop flightInfo={data.data || []} />
						</div>
					)}
					{hasFlightsInfo && isMediumDown && (
						<div>
							{showSelectionControl && <HeaderFlightSelection />}
							<FlightMobile flightInfo={data.data || []} />
						</div>
					)}
					{hasImages && (
						<div>
							{showSelectionControl && <HeaderFlightSelection />}
							{<FlightImagePreviewList flightImages={data.images} />}
						</div>
					)}
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						{!previewMode && (
							<List dense>
								<ListSubheader component="div">Carrier</ListSubheader>
								<ListItem>
									<ListItemText primary={data.validatingCarrier} />
								</ListItem>
							</List>
						)}
						<List dense>
							<ListSubheader component="div">Equipajes incluídos:</ListSubheader>

							<ListItem>
								<ListItemText primary="Artículo personal" />
							</ListItem>

							{data.carryOn ? (
								<ListItem>
									<ListItemText primary="Equipaje de mano" />
								</ListItem>
							) : (
								<ListItem>
									<ListItemText primary="No incluye equipaje de mano" />
								</ListItem>
							)}

							{data.baggage === 'none' && (
								<ListItem>
									<ListItemText primary="No incluye equipaje para despachar" />
								</ListItem>
							)}

							{data.baggage === 'baggage1x23k' && (
								<ListItem>
									<ListItemText primary="Equipaje en bodega 1 pieza hasta 23Kg" />
								</ListItem>
							)}

							{data.baggage === 'baggage2x23k' && (
								<ListItem>
									<ListItemText primary="Equipaje en bodega 2 piezas hasta 23Kg" />
								</ListItem>
							)}
						</List>
						{!previewMode && 'fareADT' in data && (
							<List dense>
								<ListSubheader component="div">Tarifas</ListSubheader>
								<div style={{ display: 'flex' }}>
									<ListItem>
										<ListItemText primary="Adultos" secondary={data.fareADT} />
									</ListItem>
									<ListItem>
										<ListItemText primary="CHD" secondary={data.fareCHD} />
									</ListItem>
									<ListItem>
										<ListItemText primary="INF" secondary={data.fareINF} />
									</ListItem>
									<ListItem>
										<ListItemText primary="Moneda" secondary="USD" />
									</ListItem>
								</div>
							</List>
						)}
					</div>
				</div>
			</div>

			{!previewMode && (
				<Button
					type="button"
					fullWidth
					variant="contained"
					color="secondary"
					className={classes.submit}
					onClick={handleClearAirConfig}
				>
					Eliminar Aire
				</Button>
			)}
		</Paper>
	);
};
