import React, { useEffect, useState } from 'react';
import { DefaultDescriptionConfig } from './DefaultDescriptionConfig';
import { getQuotationDefaultDescription, putQuotationDefaultDescription } from '../../services';
import { Alert, AlertColor, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { extractErrorMessage, isNotFoundError } from '../../helpers';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationDefaultDescription } from '../../types';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

const ConnectedQuotationDefaultDescriptionConfig = (): JSX.Element => {
	const classes = useStyles();
	const [description, setDescription] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	const onChangeDescription = (newDescription: string, onChange: (...event: any[]) => void) => {
		setDescription(newDescription);
		onChange(newDescription);
	};

	const onSubmit = async (data: QuotationDefaultDescription) => {
		try {
			setLoading(true);
			await putQuotationDefaultDescription(data);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Su descripción se actualizó con éxito',
			});
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al actualizar la descripción'),
			});
			setLoading(false);
		}
	};

	const getDefaultDescription = async () => {
		try {
			setLoading(true);
			const defaultDescription = await getQuotationDefaultDescription();
			setDescription(defaultDescription.data.description);
			setLoading(false);
		} catch (error) {
			if (isNotFoundError(error)) {
				setDescription('');
			} else {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al cargar la descripción por defecto'),
				});
			}

			setLoading(false);
		}
	};

	useEffect(() => {
		getDefaultDescription();
	}, []);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{description != null && (
				<DefaultDescriptionConfig
					description={description}
					onChangeDescription={onChangeDescription}
					onSubmit={onSubmit}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default ConnectedQuotationDefaultDescriptionConfig;
