import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	Air,
	AutocompleteContactData,
	DestinationToAddQuotationOnlyFlights,
	IPassengerConfig,
	QuotationOnlyFlights,
} from './types';
import { getQuotationOnlyFlights, postQuotationOnlyFlights, putQuotationOnlyFlights } from './services';
import { ApiError } from '../auth/types';

export const initialQuotationOnlyFlights: QuotationOnlyFlights = {
	id: 0,
	crmTicket: 0,
	description: '',
	name: '',
	email: '',
	phone: '',
	passengerConfig: null,
	notIncludes: [],
	destination: [],
	airs: [],
	markup: 0,
	loading: false,
	wasCopied: false,
	showLocalCurrency: false,
};

export const saveQuotationOnlyFlights = createAsyncThunk<
	{ id: string },
	QuotationOnlyFlights,
	{ rejectValue: ApiError }
>('quotationsOnlyFlights/save', async (payload, thunkApi) => {
	try {
		const response = await postQuotationOnlyFlights(payload);
		return response.data;
	} catch (error) {
		if (error.isAxiosError) {
			return thunkApi.rejectWithValue(error.response.data as ApiError);
		} else {
			return thunkApi.rejectWithValue(error);
		}
	}
});

export const updateQuotationOnlyFlights = createAsyncThunk<
	QuotationOnlyFlights,
	QuotationOnlyFlights,
	{ rejectValue: ApiError }
>('quotationsOnlyFlights/update', async (payload, thunkApi) => {
	try {
		const response = await putQuotationOnlyFlights(payload);
		return response.data;
	} catch (error) {
		if (error.isAxiosError) {
			return thunkApi.rejectWithValue(error.response.data as ApiError);
		} else {
			return thunkApi.rejectWithValue(error);
		}
	}
});

export const getQuotationOnlyFlightsById = createAsyncThunk<QuotationOnlyFlights, number, { rejectValue: ApiError }>(
	'quotationsOnlyFlights/getById',
	async (payload, thunkApi) => {
		try {
			const response = await getQuotationOnlyFlights(payload);
			return response.data;
		} catch (error) {
			if (error.isAxiosError) {
				return thunkApi.rejectWithValue(error.response.data as ApiError);
			} else {
				return thunkApi.rejectWithValue(error);
			}
		}
	},
);

export const quotationOnlyFlightsSlice = createSlice({
	name: 'quotationOnlyFlights',
	initialState: initialQuotationOnlyFlights,
	reducers: {
		toggleShowLocalCurrencyOnlyFlights: (state) => {
			state.showLocalCurrency = !state.showLocalCurrency;
		},
		clearQuotationOnlyFlights: (state) => {
			state.id = 0;
			state.crmTicket = 0;
			state.description = '';
			state.name = '';
			state.email = '';
			state.phone = '';
			state.passengerConfig = null;
			state.notIncludes = [];
			state.destination = [];
			state.airs = [];
			state.loading = false;
			state.showLocalCurrency = false;
		},
		setCrmTicketQuotationOnlyFlights: (state, { payload }: PayloadAction<number>) => {
			state.crmTicket = payload;
		},
		setAutoCompleteContactDataQuotationOnlyFlights: (state, { payload }: PayloadAction<AutocompleteContactData>) => {
			state.name = payload.name;
			state.phone = payload?.phone || '';
			state.email = payload?.email || '';
		},
		setNameQuotationOnlyFlights: (state, { payload }: PayloadAction<string>) => {
			state.name = payload;
		},
		setPassengerConfig: (state, { payload }: PayloadAction<IPassengerConfig | null>) => {
			state.passengerConfig = payload;
		},
		setEmailQuotationOnlyFlights: (state, { payload }: PayloadAction<string>) => {
			state.email = payload;
		},
		setDescriptionQuotationOnlyFlights: (state, { payload }: PayloadAction<string>) => {
			state.description = payload;
		},
		setLoadingQuotationOnlyFlights: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setPhoneQuotationOnlyFlights: (state, { payload }: PayloadAction<string>) => {
			state.phone = payload;
		},

		addAirConfigQuotationOnlyFlights: (state, { payload }: PayloadAction<Air>) => {
			state.airs = [...state.airs, payload];
		},
		refreshAirConfigQuotationOnlyFlights: (state, { payload }: PayloadAction<Air[]>) => {
			state.airs = [...payload];
		},
		deleteAirConfigQuotationOnlyFlights: (state, { payload }: PayloadAction<number>) => {
			const newAirs = [...state.airs];
			newAirs.splice(payload, 1);
			state.airs = newAirs;
		},

		addDestinationItemQuotationOnlyFlights: (
			state,
			{ payload }: PayloadAction<DestinationToAddQuotationOnlyFlights>,
		) => {
			state.destination = [...state.destination, { ...payload }];
		},
		addNotIncludeQuotationOnlyFlights: (state, { payload }: PayloadAction<string>) => {
			const newNotIncluded = [...state.notIncludes];
			newNotIncluded.push(payload);
			state.notIncludes = newNotIncluded;
		},
		setNotIncludesQuotationOnlyFlights: (state, { payload }: PayloadAction<string[]>) => {
			state.notIncludes = [...payload];
		},
		deleteNotIncludeQuotationOnlyFlights: (state, { payload }: PayloadAction<number>) => {
			const newNotIncluded = [...state.notIncludes];
			newNotIncluded.splice(payload, 1);
			state.notIncludes = newNotIncluded;
		},
		setMarkupQuotationOnlyFlights: (state, { payload }: PayloadAction<string>) => {
			state.markup = Number(payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(saveQuotationOnlyFlights.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(saveQuotationOnlyFlights.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(saveQuotationOnlyFlights.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(updateQuotationOnlyFlights.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(updateQuotationOnlyFlights.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(updateQuotationOnlyFlights.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getQuotationOnlyFlightsById.pending, (state) => {
			state.loading = true;
			state.destination = [];
		});
		builder.addCase(getQuotationOnlyFlightsById.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getQuotationOnlyFlightsById.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.agentId = payload.agentId;
			state.id = payload.id;
			state.name = payload.name;
			state.crmTicket = payload.crmTicket;
			state.email = payload.email;
			state.phone = payload.phone;
			state.description = payload.description;
			state.airs = payload.airs;
			state.passengerConfig = payload.passengerConfig;
			state.destination = payload.destination;
			state.notIncludes = payload.notIncludes;
			state.wasCopied = payload.wasCopied;
		});
	},
});

export const {
	setNameQuotationOnlyFlights,
	setCrmTicketQuotationOnlyFlights,
	setAutoCompleteContactDataQuotationOnlyFlights,
	setDescriptionQuotationOnlyFlights,
	setEmailQuotationOnlyFlights,
	setPhoneQuotationOnlyFlights,
	setPassengerConfig,
	addAirConfigQuotationOnlyFlights,
	deleteAirConfigQuotationOnlyFlights,
	refreshAirConfigQuotationOnlyFlights,
	addDestinationItemQuotationOnlyFlights,
	addNotIncludeQuotationOnlyFlights,
	deleteNotIncludeQuotationOnlyFlights,
	clearQuotationOnlyFlights,
	setLoadingQuotationOnlyFlights,
	setNotIncludesQuotationOnlyFlights,
	setMarkupQuotationOnlyFlights,
	toggleShowLocalCurrencyOnlyFlights,
} = quotationOnlyFlightsSlice.actions;

export const { reducer, actions } = quotationOnlyFlightsSlice;

export const quotationOnlyFlightsReducer = quotationOnlyFlightsSlice.reducer;
