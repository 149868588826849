import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, Checkbox } from '@mui/material';

interface Props {
	name: string;
	label: string;
	onChange?: (checked: boolean) => void;
}

export const ControlledCheckBox = ({ name, label }: Props): JSX.Element => {
	const { control } = useFormContext();

	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			render={({ field }) => {
				return (
					<FormControlLabel
						control={
							<Checkbox
								{...field}
								checked={field.value === undefined ? false : field.value}
								onChange={(e) => field.onChange(e.target.checked)}
								defaultChecked={field.value}
							/>
						}
						label={label}
					/>
				);
			}}
		/>
	);
};
