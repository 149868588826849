import React, { useEffect } from 'react';

import { Avatar, Box, Container, FormLabel, Stack, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { weekDaysNamesAbbrForFilters } from '../../constants';
import { IDepartureDaysFilter } from '../../types';

interface Props {
	weekDaysInDeparture: number[];
	weekDaysInReturn: number[];
	handleChangeFilter: (data: IDepartureDaysFilter | null) => void;
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		color: 'white',
	},
	filterContent: {
		backgroundColor: 'rgb(241, 242, 241)',
		borderBottomRightRadius: '14px',
		borderBottomLeftRadius: '14px',
		paddingTop: '5px',
		paddingBottom: '25px',
		paddingLeft: '15px',
		transition: 'max-height 0.26s ease 0s, padding 0.26s ease 0s',
		overflowY: 'auto',
	},
	daySelected: {
		backgroundColor: theme.palette.secondary.main,
		fontWeight: 'bold',
		fontSize: '12px',
	},
	dayUnselected: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		fontWeight: 'bold',
		fontSize: '12px',
	},
	dayDisabled: {
		backgroundColor: 'gray',
	},
}));

interface TabPanelProps {
	children: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export const FlightExplorerDaysFilter = ({
	weekDaysInDeparture,
	weekDaysInReturn,
	handleChangeFilter,
}: Props): JSX.Element => {
	const classes = useStyles();

	const [value, setValue] = React.useState(0);
	const [daysSelectedInGoing, setDaysSelectedInGoing] = React.useState<number[]>([]);
	const [daysSelectedInReturn, setDaysSelectedInReturn] = React.useState<number[]>([]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleDaySelected = (day: number, daysSelected: number[], updateState: (days: number[]) => void) => {
		if (daysSelected.includes(day)) {
			updateState(daysSelected.filter((item) => item !== day));
		} else {
			updateState([...daysSelected, day]);
		}
	};

	useEffect(() => {
		if (daysSelectedInGoing.length > 0 || daysSelectedInReturn.length > 0) {
			handleChangeFilter({
				...(daysSelectedInGoing.length > 0 && { departureDaysInGoing: daysSelectedInGoing }),
				...(daysSelectedInReturn.length > 0 && { departureDaysInReturn: daysSelectedInReturn }),
			} as IDepartureDaysFilter);
		} else {
			handleChangeFilter(null);
		}
	}, [daysSelectedInGoing, daysSelectedInReturn]);

	return (
		<Container sx={{ 'margin-bottom': '15px' }}>
			<Box sx={{ 'padding-left': '15px', backgroundColor: '#8167A3', height: '25px' }}>
				<FormLabel className={classes.title}>Días de salida</FormLabel>
			</Box>
			<Box className={classes.filterContent}>
				<Tabs value={value} onChange={handleChange}>
					<Tab label="Ida" {...a11yProps(0)} />
					<Tab label="Vuelta" {...a11yProps(1)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<Stack direction="row" spacing={1} pt={1}>
						{weekDaysNamesAbbrForFilters.map((item, index) =>
							weekDaysInDeparture.includes(item.id) ? (
								<Avatar
									title={item.name}
									onClick={() => handleDaySelected(item.id, daysSelectedInGoing, setDaysSelectedInGoing)}
									sx={{ width: 24, height: 24, cursor: 'pointer' }}
									key={'day-filter-going-' + index}
									className={`${daysSelectedInGoing.includes(item.id) ? classes.daySelected : classes.dayUnselected}`}
								>
									{item.name[0]}
								</Avatar>
							) : (
								<Avatar
									sx={{ width: 24, height: 24 }}
									key={'day-filter-going-' + index}
									className={classes.dayDisabled}
								>
									{item.name[0]}
								</Avatar>
							),
						)}
					</Stack>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Stack direction="row" spacing={1} pt={1}>
						{weekDaysNamesAbbrForFilters.map((item, index) =>
							weekDaysInReturn.includes(item.id) ? (
								<Avatar
									title={item.name}
									onClick={() => handleDaySelected(item.id, daysSelectedInReturn, setDaysSelectedInReturn)}
									sx={{ width: 24, height: 24, cursor: 'pointer' }}
									key={'day-filter-return-' + index}
									className={`${daysSelectedInReturn.includes(item.id) ? classes.daySelected : classes.dayUnselected}`}
								>
									{item.name[0]}
								</Avatar>
							) : (
								<Avatar
									sx={{ width: 24, height: 24 }}
									key={'day-filter-going-' + index}
									className={classes.dayDisabled}
								>
									{item.name[0]}
								</Avatar>
							),
						)}
					</Stack>
				</TabPanel>
			</Box>
		</Container>
	);
};
