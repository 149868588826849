import React from 'react';
import { Container, CssBaseline, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuotationServiceSimple } from '../../types';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
}));

interface Props {
	services: QuotationServiceSimple[];
	manualServices: QuotationServiceSimple[];
}
export const QuotationServicesObservationPreview = ({ services, manualServices }: Props): JSX.Element => {
	const classes = useStyles();

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Observaciones relacionadas a los servicios:
				</Typography>
				<ul>
					{services.map((service, index) => (
						<li key={`service-observations-preview-${index}`}>
							{service.name}: {service.description}
						</li>
					))}

					{manualServices.map((manualService, index) => (
						<li key={`manual-service-observations-preview-${index}`}>
							{manualService.name}: {manualService.description}
						</li>
					))}
				</ul>
			</div>
		</Container>
	);
};
