import { IEnvelopeList, ISort } from '../common/types';
import { AxiosResponse } from 'axios';
import { formatSortingFields } from '../common/helpers';
import { ApiService } from '../../services';
import endpoints from '../../constants/endpoints';
import { IActivityLogList } from './types';

export const getActivityLogList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IActivityLogList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.activityLogList, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getActivityLogListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IActivityLogList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.activityLogList, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};
