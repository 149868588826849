import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	select: {
		width: '100%',
		backgroundColor: theme.palette.common.white,
	},
}));

interface Option {
	code: string;
	name: string;
}

interface Props {
	name: string;
	label: string;
	rules?: Record<string, any>;
	size?: 'small' | 'medium';
	variant?: 'outlined' | 'standard';
	options: Option[];
	isMultiple: boolean;
	helperText?: string;
}

export function ControlledAutoComplete(props: Props): JSX.Element {
	const { name, label, rules, size = 'small', variant = 'outlined', options, isMultiple = false, helperText } = props;

	const classes = useStyles();
	const { control, formState } = useFormContext();
	const error = formState.errors[name]?.message;

	return (
		<Controller
			// @ts-ignore
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<FormControl sx={{ width: '100%' }}>
					<Autocomplete
						multiple={isMultiple}
						options={options}
						value={
							isMultiple
								? options.filter((option) => (field.value || []).includes(option.code))
								: options.find((option) => option.code === field.value) || null
						}
						getOptionLabel={(option) => option.name}
						onChange={(event, value) => {
							const selectedValues = isMultiple
								? (value as Option[]).map((v) => v.code)
								: (value as Option)?.code || '';
							field.onChange(selectedValues);
						}}
						renderInput={(params) => (
							<>
								<TextField
									{...params}
									label={label}
									variant={variant}
									fullWidth
									size={size}
									className={classes.select}
									error={Boolean(error)}
									helperText={error || helperText}
								/>
							</>
						)}
					/>
				</FormControl>
			)}
		/>
	);
}
