import { useState, useEffect } from 'react';

const breakpoints = {
	xsmall: 0,
	small: 540,
	medium: 768,
	large: 960,
	xlarge: 1140,
};

const checkBreakPoints = () => ({
	smallDown: document.body.clientWidth < breakpoints.small,
	mediumDown: document.body.clientWidth <= breakpoints.medium,
	largeDown: document.body.clientWidth <= breakpoints.large,
	xlDown: document.body.clientWidth <= breakpoints.xlarge,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
	const [results, setResults] = useState(checkBreakPoints());

	function handleResize() {
		setResults(checkBreakPoints());
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return results;
};
