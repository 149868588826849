import React from 'react';
import {
	Dialog,
	DialogContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { ICashTransactionLog } from '../../types';
import { formatDateTimeToShowUser } from '../../../common/helpers';

interface Props {
	open: boolean;
	data: ICashTransactionLog[];
	onCancel: () => void;
}

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	closeButton: {
		cursor: 'pointer',
		display: 'inline',
		float: 'right',
	},
}));

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

export const CashTransactionLogsModal = ({ open, data, onCancel }: Props): JSX.Element => {
	const classes = useStyles();

	const headerCells = [
		{ headerName: 'Origen' },
		{ headerName: 'Destino' },
		{ headerName: 'Realizado por' },
		{ headerName: 'Fecha' },
	];

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="xl"
			disableEscapeKeyDown={true}
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
		>
			<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
				<Typography variant="h6" component={'span'}>
					Logs de movimientos realizados{' '}
				</Typography>{' '}
				<CloseIcon className={classes.closeButton} onClick={onCancel}></CloseIcon>
			</DialogTitle>
			<DialogContent>
				<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: '600px' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell, index) => {
										return (
											<TableCell className={classes.tableHeader} key={`${cell.headerName}-${index}`}>
												{cell.headerName}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((item, index) => (
									<TableRow key={`${index}`} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell component="p">{item.originName}</TableCell>
										<TableCell component="p">{item.destinationName}</TableCell>
										<TableCell component="p">{item.userName}</TableCell>
										<TableCell component="p">{formatDateTimeToShowUser(item.moveAt)}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};
