import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	container: {
		paddingBottom: 20,
	},
	containerButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
}));
