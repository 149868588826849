import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

export interface ConfirmationModalProps {
	message: string;
	open: boolean;
	handleClose: () => void;
	handleAccept: () => void;
}

export function ConfirmationModal({ message, open, handleClose, handleAccept }: ConfirmationModalProps): JSX.Element {
	return (
		<div>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
					¿Está seguro/a?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancelar
					</Button>
					<Button onClick={handleAccept}>Confirmar</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
