import { fieldRuleOptions, PaxFormFields, renderOptions } from './types';

export const paxFormFields: PaxFormFields = {
	name: {
		UY: {
			label: 'Nombres',
			labelJuridico: 'Razon Social',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Nombres',
			labelJuridico: 'Razon Social',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		BO: {
			label: 'Nombres',
			labelJuridico: 'Razon Social',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'Nombres',
			labelJuridico: 'Razon Social',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
	},
	lastName: {
		UY: {
			label: 'Apellidos',
			labelJuridico: 'Fantasia',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Apellidos',
			labelJuridico: 'Fantasia',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		BO: {
			label: 'Apellidos',
			labelJuridico: 'Fantasia',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'Apellidos',
			labelJuridico: 'Fantasia',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
	},
	birthdate: {
		UY: {
			label: 'Fecha de nacimiento',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.date,
		},
		CO: {
			label: 'Fecha de nacimiento',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.date,
		},
		BO: {
			label: 'Fecha de nacimiento',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.date,
		},
		PY: {
			label: 'Fecha de nacimiento',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.date,
		},
	},
	countryId: {
		UY: {
			label: 'Pais',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		CO: {
			label: 'Pais',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		BO: {
			label: 'Pais',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		PY: {
			label: 'Pais',
			fisicaNoFacturable: fieldRuleOptions.required,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
	},
	state: {
		UY: {
			label: 'Estado',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Estado',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		BO: {
			label: 'Estado',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		PY: {
			label: 'Estado',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
	},
	province: {
		UY: {
			label: 'Ciudad',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Ciudad',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		BO: {
			label: 'Provincia',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		PY: {
			label: 'Distrito',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
	},
	municipality: {
		UY: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		BO: {
			label: 'Comuna',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		PY: {
			label: 'Ciudad',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
	},
	address: {
		UY: {
			label: 'Direccion',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Direccion',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		BO: {
			label: 'Direccion',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'Direccion',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
	},
	postalCode: {
		UY: {
			label: 'Codigo Postal',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Codigo Postal',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		BO: {
			label: 'Codigo Postal',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'Codigo Postal',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
	},
	phone: {
		UY: {
			label: 'Telefono',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.phone,
		},
		CO: {
			label: 'Telefono',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.phone,
		},
		BO: {
			label: 'Telefono',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.phone,
		},
		PY: {
			label: 'Telefono',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.phone,
		},
	},
	email: {
		UY: {
			label: 'Mail',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.email,
		},
		CO: {
			label: 'Mail',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.email,
		},
		BO: {
			label: 'Mail',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.email,
		},
		PY: {
			label: 'Mail',
			fisicaNoFacturable: fieldRuleOptions.optional,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.email,
		},
	},

	taxPayerTypeId: {
		UY: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Tipo de contribuyente',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		BO: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'Tipo de contribuyente',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
	},
	taxRegimeId: {
		UY: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Regimen Fiscal',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		BO: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'Regimen Fiscal',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
	},
	taxResidenceTypeId: {
		UY: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Tipo de Residencia Fiscal',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		BO: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
	},
	fiscalResponsibilityId: {
		UY: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		CO: {
			label: 'Responsabilidad Fiscal',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		BO: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'no se muestra',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.noshow,
			juridica: fieldRuleOptions.noshow,
			render: renderOptions.textfield,
		},
	},
	documentType: {
		UY: {
			label: 'Tipo de documento',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		CO: {
			label: 'Tipo de documento',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		BO: {
			label: 'Tipo de documento',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
		PY: {
			label: 'Tipo de documento',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.select,
		},
	},
	documentNumber: {
		UY: {
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
			label: 'Numero de documento',
		},
		CO: {
			label: 'Numero de documento',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		BO: {
			label: 'Numero de documento',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
		PY: {
			label: 'Numero de documento',
			fisicaNoFacturable: fieldRuleOptions.noshow,
			fisicaFacturable: fieldRuleOptions.required,
			juridica: fieldRuleOptions.required,
			render: renderOptions.textfield,
		},
	},
	// TODO documentVerificationNumber
};
