import React from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { findContactDataByDeal } from '../../../services';
import { extractErrorMessage, isOnlyDigit } from '../helpers';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { AutocompleteContactData } from '../types';
import { copyQuotation } from '../services';
import GeneralConfigForm from './GeneralConfig/GeneralConfigForm';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

interface Props {
	quotationIdToCopy: number;
	isOnlyFlights: boolean;
	open: boolean;
	onClose: () => void;
	onSuccessCopyQuotation: (newId: number) => void;
}

export const CopyQuotationDialog = (props: Props): JSX.Element => {
	const [email, setEmail] = React.useState('');
	const [name, setName] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [crmTicket, setCrmTicket] = React.useState(0);
	const [description, setDescription] = React.useState('');
	const [errorMessageToShow, setErrorMessageToShow] = React.useState('');

	const [loading, setLoading] = React.useState(false);
	const classes = useStyles();

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value);

	const handleCRMTicketChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isOnlyDigit(e.target.value) && Number(e.target.value) > 0) setCrmTicket(Number(e.target.value));
	};

	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);

	const setAutoCompleteContactData = (payload: AutocompleteContactData) => {
		setName(payload.name);
		setPhone(payload.phone || '');
		setEmail(payload.email || '');
	};

	const handleAutoCompleteNameEmailAndPhone = async () => {
		try {
			setLoading(true);
			const response = await findContactDataByDeal(crmTicket);
			setAutoCompleteContactData(response.data);
			setLoading(false);
		} catch (error) {
			setErrorMessageToShow(extractErrorMessage(error, 'No se pudo obtener los datos de contacto'));
			setLoading(false);
		}
	};

	const handleConfirmAutoCompleteContactDataByDeal = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && crmTicket > 0) {
			handleAutoCompleteNameEmailAndPhone();
		}
	};

	const handleCopyQuotation = async () => {
		if (isValid()) {
			try {
				setLoading(true);
				const payload = {
					quotationIdToCopy: props.quotationIdToCopy,
					isOnlyFlights: props.isOnlyFlights,
					crmTicket,
					description,
					name,
					email: email === '' ? null : email,
					phone,
				};
				const result = await copyQuotation(payload);
				setLoading(false);
				props.onSuccessCopyQuotation(result.data);
			} catch (error) {
				setErrorMessageToShow(extractErrorMessage(error, 'Ocurrió un error al copiar la cotización'));
				setLoading(false);
			}
		}
	};

	const isValidName = () => name && name.length > 0 && name.length <= 500 && name.replace(/ /g, '').length > 0;
	const isValidDeal = () => crmTicket > 0;
	const isValid = () => isValidName() && isValidDeal();
	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Snackbar open={errorMessageToShow.length > 0} autoHideDuration={1000} onClose={() => setErrorMessageToShow('')}>
				<Alert variant="filled" severity={'error'}>
					{errorMessageToShow}
				</Alert>
			</Snackbar>
			<Dialog
				open={props.open}
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
				maxWidth="md"
			>
				<DialogTitle id="alert-dialog-title">{'Copiar cotización'}</DialogTitle>
				<DialogContent>
					<Typography component="h1" variant="h6">
						Inserte los datos de la nueva cotización
					</Typography>
					<GeneralConfigForm
						onEmailChange={handleEmailChange}
						onCRMTicketChange={handleCRMTicketChange}
						onPhoneChange={handlePhoneChange}
						onNameChange={handleNameChange}
						onConfirmAutoCompleteDataByDeal={handleConfirmAutoCompleteContactDataByDeal}
						crmTicket={crmTicket}
						name={name}
						email={email}
						phone={phone}
						descripcion={description}
						onDescriptionChange={handleDescriptionChange}
						showDescriptionField={true}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={props.onClose}>
						Cancelar
					</Button>
					<Button variant="contained" color="primary" disabled={!isValid()} onClick={handleCopyQuotation} autoFocus>
						Copiar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
