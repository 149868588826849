import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

interface Props {
	open: boolean;
	onClose: () => void;
	tst: string;
}

export const TstDialog = ({ open, onClose, tst }: Props): JSX.Element => {
	return (
		<Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
			<DialogTitle>Ver TST</DialogTitle>
			<DialogContent>
				<TextField id="outlined-multiline-static" multiline rows={13} variant="outlined" value={tst} fullWidth />
			</DialogContent>
			<DialogActions>
				<Button variant="contained" style={{ marginRight: 15 }} color="primary" onClick={onClose}>
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
