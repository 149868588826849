import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearQuotationOnlyFlights, getQuotationOnlyFlightsById } from '../../features/quotation/sliceOnlyFlights';
import QuotationsNewOnlyFlights from './newOnlyFlights';
import { PageInternalError404, ErrorType } from '../../features/common/components/PageInternalError404';

function QuotationsEditOnlyFlight(): JSX.Element {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const [showError404, setShowError404] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch(clearQuotationOnlyFlights());
				const actionResult = await dispatch(getQuotationOnlyFlightsById(Number(id)));
				// @ts-ignore
				if (actionResult['payload']['code'] === 130) {
					setShowError404(true);
				}
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, []);

	if (showError404) {
		return <PageInternalError404 errorType={ErrorType.INTERNAL_QUOTATIONS_NEW_FLIGHTS_NOT_FUND} />;
	}

	return <QuotationsNewOnlyFlights />;
}

export default QuotationsEditOnlyFlight;
