import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Layout } from 'features/common/components/Layout';
import { CustomerLeisureList } from 'features/customers/components/CustomerLeisureList';

export const CustomersList = (): JSX.Element => {
	return (
		<Layout>
			<Grid container paddingY={5} justifyContent={'center'}>
				<Grid item xs={12} sx={{ ml: 20 }}>
					<Typography variant="h4" style={{ fontWeight: 500 }}>
						Clientes
					</Typography>
				</Grid>
				<Grid paddingY={5}>
					<CustomerLeisureList />
				</Grid>
			</Grid>
		</Layout>
	);
};
