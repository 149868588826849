import React from 'react';
import { Layout } from '../../../features/common/components/Layout';
import { FlightExplorerDestinationDetail } from '../../../features/flightsExplorer/components/FlightExplorerDestinationDetail';

export const FlightExplorerDestinationDetailPage = (): JSX.Element => {
	return (
		<Layout>
			<FlightExplorerDestinationDetail />
		</Layout>
	);
};
