import React, { useState } from 'react';
import {
	Alert,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Radio,
	Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useSWR from 'swr';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	AccommodationFaresByDistribution,
	AccommodationFaresRequest,
	AccommodationFaresResponse,
	AccommodationFaresRoomPrice,
	Board,
	CancellationPolicy,
} from '../types';
import { cacheTimes, getAccommodationFaresByDistribution, roomToString } from '../helpers';
import { fetcherPost } from '../../../services';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';

interface FarePickerDialogProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (selection: FareSelected[]) => void;
	fareRequest: AccommodationFaresRequest;
}
export interface FareSelected {
	roomKey: string;
	fare: AccommodationFaresRoomPrice;
}

interface State {
	distOpenIndex: number | null;
	openRoomCode: string | null;
	selection: FareSelected[];
}

const getInitialState = (): State => ({
	distOpenIndex: null,
	openRoomCode: null,
	selection: [],
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		list: {
			maxHeight: '80vh',
		},

		secondLevelItem: { paddingLeft: theme.spacing(3) },
		hasValid: {
			backgroundColor: theme.palette.primary.light + ' !important;',
			color: '#FFFFFF',
		},

		thirdLevelItem: { paddingLeft: theme.spacing(6) },
	}),
);

export const FarePickerDialog = ({ open, onClose, onSubmit, fareRequest }: FarePickerDialogProps): JSX.Element => {
	const [{ distOpenIndex, openRoomCode, selection }, setState] = useState<State>(getInitialState());

	const { data, isValidating, error } = useSWR<AccommodationFaresResponse>(
		['api/quotations/accommodations/fares', fareRequest],
		fetcherPost,
		{ dedupingInterval: cacheTimes['1h'] },
	);

	const classes = useStyles();

	const getDataAdaptToRoomToRender = (): AccommodationFaresByDistribution[] => {
		if (data?.faresByDistribution) {
			return getAccommodationFaresByDistribution(data?.faresByDistribution, fareRequest.distributions);
		} else return [];
	};

	const isSelectionValid = () =>
		selection.length === fareRequest.distributions.length &&
		selection.every((s) => s?.fare && s.fare.board && s.fare.cancellationPolicy && s.fare.fare);

	const toggleOpenDistribution = (index: number) =>
		setState((prev) => ({
			...prev,
			distOpenIndex: prev.distOpenIndex === index ? null : index,
			openRoomCode: null,
		}));

	const toggleOpenRoom = (code: string) =>
		setState((prev) => ({
			...prev,
			openRoomCode: prev.openRoomCode === code ? null : code,
		}));

	const handleSelection = (distIndex: number) => (fare: AccommodationFaresRoomPrice, roomKey: string) => {
		setState((prev: State) => {
			const { selection: prevSelection } = prev;
			if (!selection[distIndex]) {
				const newSelection = [...selection];
				newSelection[distIndex] = { fare, roomKey };
				return {
					...prev,
					selection: newSelection,
				};
			}

			return {
				...prev,
				selection: prevSelection.map((s, index) => (index === distIndex ? { fare, roomKey } : s)),
			};
		});
	};

	const getIsDistValid = (dist: FareSelected): boolean =>
		!!(dist.fare.board && dist.fare.cancellationPolicy && dist.fare.fare);

	const getIsDistSelected = (index: number) => {
		if (selection[index]) return getIsDistValid(selection[index]);
		else return false;
	};

	const getIsRoomSelected = (index: number, roomKey: string): boolean => {
		return getIsDistSelected(index) && selection[index].roomKey === roomKey;
	};

	const getIsFareSelected = (
		index: number,
		roomKey: string,
		fare: {
			fare: number;
			board: Board;
			cancellationPolicy: CancellationPolicy;
		},
	): boolean => {
		const isParentRoomSelected = getIsDistSelected(index) && selection[index].roomKey === roomKey;
		if (!isParentRoomSelected) {
			return false;
		}
		const selected = selection[index];
		return (
			selected.fare.board?.code === selected.fare.board.code &&
			selected.fare.fare === fare.fare &&
			selected.fare.cancellationPolicy === fare.cancellationPolicy
		);
	};

	return (
		<Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
			{isValidating ? <LinearProgress /> : null}

			<DialogTitle>Elije una tarifa</DialogTitle>
			<DialogContent>
				{error ? (
					<Alert severity="warning">
						{error['response'] && error['response']['data'] && error['response']['data']['message']
							? error['response']['data']['message']
							: 'Ocurrió un error al cargar las tarifas'}
					</Alert>
				) : null}

				<List dense className={classes.list}>
					{getDataAdaptToRoomToRender().map(({ distribution, faresByRoom }, index) => {
						const isOpen = distOpenIndex === index;
						const isDistSelected = getIsDistSelected(index);

						return (
							<React.Fragment key={`accommodation-${index}`}>
								<ListItem button selected={isDistSelected} onClick={() => toggleOpenDistribution(index)}>
									{isDistSelected && (
										<ListItemIcon>
											<CheckIcon style={{ color: green[500] }} />
										</ListItemIcon>
									)}

									<ListItemText primary={`Habitación ${index + 1}`} secondary={roomToString(distribution)} />
									{isOpen ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={isOpen}>
									<List component="div" disablePadding>
										{faresByRoom.map(({ fares, room }) => {
											const roomKey = `accommodation-${index}-room-${room.code}`;
											const isRoomOpen = openRoomCode === room.code;
											const isRoomSelected = getIsRoomSelected(index, roomKey);

											return (
												<React.Fragment key={roomKey}>
													<ListItem
														button
														className={`${classes.secondLevelItem} ${isRoomSelected ? classes.hasValid : ''}`}
														selected={isRoomSelected}
														key={room.code}
														onClick={() => toggleOpenRoom(room.code)}
													>
														<ListItemText primary={room.name} />
														{isRoomOpen ? <ExpandLess /> : <ExpandMore />}
													</ListItem>
													<Collapse in={isRoomOpen}>
														<List component="div" disablePadding>
															{fares.map((fare) => {
																const isSelected = getIsFareSelected(index, roomKey, fare);
																return (
																	<ListItem
																		className={classes.thirdLevelItem}
																		button
																		selected={isSelected}
																		onClick={() => handleSelection(index)(fare, roomKey)}
																		key={`${roomKey}-fare-${fare.fare}-${fare.board.code}-${fare.cancellationPolicy}`}
																	>
																		<Radio
																			color={'primary'}
																			checked={isSelected}
																			key={`${roomKey}-fare-radio-${fare.fare}-${fare.board.code}-${fare.cancellationPolicy}`}
																			onChange={() => handleSelection(index)(fare, roomKey)}
																		/>
																		<ListItemText
																			primary={`${fare.currency} ${fare.fare}`}
																			secondary={`${fare.cancellationPolicy} - ${fare.board.name}`}
																		/>
																	</ListItem>
																);
															})}
														</List>
													</Collapse>
												</React.Fragment>
											);
										})}
									</List>
								</Collapse>
							</React.Fragment>
						);
					})}
				</List>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Cancelar
				</Button>
				<Button variant="contained" color="primary" disabled={!isSelectionValid()} onClick={() => onSubmit(selection)}>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
