import React, { useEffect, useState } from 'react';
import { CssBaseline, TextField, Grid, Typography, Container, Button, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { AgencyFieldsMaxLength, InputAgency } from 'features/agency/types';
import { PhotoCamera } from '@mui/icons-material';
import { agencyFormData, agencyUpdate, getAgencyDetails, newAgencyPost } from 'features/agency/services';
import { useHistory } from 'react-router-dom';
import { checkShowErrorMessage } from 'features/common/helpers';
import routes from 'constants/routes';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const Input = styled('input')({
	display: 'none',
});

interface AgencyFormProps {
	id?: number;
}

const agencyDefaultValues = {
	name: '',
	rut: '',
	email: '',
	address: '',
	phone: '',
	city: '',
	country: '',
	twitter: '',
	facebook: '',
	instagram: '',
	logo: '',
};

export default function AgencyForm({ id }: AgencyFormProps): JSX.Element {
	const classes = useStyles();
	const history = useHistory();
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({ defaultValues: agencyDefaultValues });
	const [file, setFile] = useState<File | null>(null);
	const [originalImageURL, setOriginalImageURL] = useState<string>('');
	const [fileDataURL, setFileDataURL] = useState<any>();

	useEffect(() => {
		if (id) {
			const getAgency = async () => {
				const agency = (await getAgencyDetails(+id)).data;
				console.log(agency);
				setOriginalImageURL(agency.logoUrl);
				// @ts-ignore
				reset(agency);
			};
			getAgency();
		}
	}, []);

	useEffect(() => {
		if (file) {
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				const result = e.target?.result;
				if (result) {
					setFileDataURL(result);
				}
			};
			fileReader.readAsDataURL(file);
		}
	}, [file]);

	const onSubmit = (data: FieldValues) => {
		try {
			const inputAgency = {
				...data,
			} as InputAgency;
			id ? agencyUpdate(agencyFormData(inputAgency, file), +id) : newAgencyPost(agencyFormData(inputAgency, file));
			history.push(routes.agency.list);
		} catch (error) {
			console.log(error);
		}
	};

	const handleReturn = () => {
		history.goBack();
	};

	const handleImageUpload = (e: any) => {
		setFile(e.target.files[0]);
	};

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					{id ? 'Actualizar agencia' : 'Crear Agencia'}
				</Typography>
				<form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Controller
								name="name"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									maxLength: {
										value: AgencyFieldsMaxLength.NAME,
										message:
											'El Nombre de la Agencia excede el largo máximo permitido de: ' +
											AgencyFieldsMaxLength.NAME +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<>
										<TextField
											variant="outlined"
											required
											fullWidth
											label="Nombre"
											multiline
											helperText={checkShowErrorMessage(Boolean(errors.name), errors.name?.message)}
											error={Boolean(errors.name)}
											{...field}
										/>
									</>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="rut"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									maxLength: {
										value: AgencyFieldsMaxLength.RUT,
										message:
											'El Codigo RUT excede el largo máximo permitido de: ' + AgencyFieldsMaxLength.RUT + ' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="RUT"
										multiline
										helperText={checkShowErrorMessage(Boolean(errors.rut), errors.rut?.message)}
										error={Boolean(errors.rut)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name="email"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									pattern: {
										value: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
										message: 'Ingrese un email válido',
									},
									maxLength: {
										value: AgencyFieldsMaxLength.EMAIL,
										message:
											'El email excede el largo máximo permitido de: ' + AgencyFieldsMaxLength.EMAIL + ' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Correo"
										helperText={checkShowErrorMessage(Boolean(errors.email), errors.email?.message)}
										multiline
										error={Boolean(errors.email)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name="phone"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									maxLength: {
										value: AgencyFieldsMaxLength.PHONE,
										message:
											'El Numero de Teléfono excede el largo máximo permitido de: ' +
											AgencyFieldsMaxLength.PHONE +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Telefono"
										multiline
										helperText={checkShowErrorMessage(Boolean(errors.phone), errors.phone?.message)}
										error={Boolean(errors.phone)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name="country"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									maxLength: {
										value: AgencyFieldsMaxLength.COUNTRY,
										message:
											'El Nombre del Pais excede el largo máximo permitido de: ' +
											AgencyFieldsMaxLength.COUNTRY +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Pais"
										multiline
										helperText={checkShowErrorMessage(Boolean(errors.country), errors.country?.message)}
										error={Boolean(errors.country)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name="city"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									maxLength: {
										value: AgencyFieldsMaxLength.CITY,
										message:
											'El Nombre de la Ciudad excede el largo máximo permitido de: ' +
											AgencyFieldsMaxLength.CITY +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Ciudad"
										multiline
										helperText={checkShowErrorMessage(Boolean(errors.city), errors.city?.message)}
										error={Boolean(errors.city)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="address"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									maxLength: {
										value: AgencyFieldsMaxLength.PHONE,
										message:
											'La Dirección excede el largo máximo permitido de: ' +
											AgencyFieldsMaxLength.ADDRESS +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Direccion"
										multiline
										helperText={checkShowErrorMessage(Boolean(errors.address), errors.address?.message)}
										error={Boolean(errors.address)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Controller
								name="facebook"
								control={control}
								rules={{
									maxLength: {
										value: AgencyFieldsMaxLength.FACEBOOK,
										message:
											'La pagina de Facebook excede el largo máximo permitido de: ' +
											AgencyFieldsMaxLength.FACEBOOK +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										fullWidth
										label="Facebook"
										helperText={checkShowErrorMessage(Boolean(errors.facebook), errors.facebook?.message)}
										error={Boolean(errors.facebook)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Controller
								name="twitter"
								control={control}
								rules={{
									maxLength: {
										value: AgencyFieldsMaxLength.TWITTER,
										message:
											'El Usuario de Twitter excede el largo máximo permitido de: ' +
											AgencyFieldsMaxLength.TWITTER +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										fullWidth
										label="Twitter"
										helperText={checkShowErrorMessage(Boolean(errors.twitter), errors.twitter?.message)}
										error={Boolean(errors.twitter)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Controller
								name="instagram"
								control={control}
								rules={{
									maxLength: {
										value: AgencyFieldsMaxLength.INSTAGRAM,
										message:
											'El Usuario de Instagram excede el largo máximo permitido de: ' +
											AgencyFieldsMaxLength.INSTAGRAM +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										fullWidth
										label="Instagram"
										helperText={checkShowErrorMessage(Boolean(errors.instagram), errors.instagram?.message)}
										error={Boolean(errors.instagram)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={'auto'} sm={4}>
							<Controller
								name="logo"
								control={control}
								render={() => (
									<>
										<label htmlFor="contained-button-file">
											<Input accept="image/*" id="contained-button-file" type="file" onChange={handleImageUpload} />
											<Button variant="contained" component="span" startIcon={<PhotoCamera />}>
												Subir Logo
											</Button>
										</label>
									</>
								)}
							/>
						</Grid>
						<Grid item xs={12} display={'flex'} justifyContent="center" maxHeight={'400px'} overflow={'auto'}>
							<img src={fileDataURL ? fileDataURL : originalImageURL} alt="preview" height={'100%'} width="auto"></img>
						</Grid>
						<Grid item xs={12} display={'flex'} gap={'10px'} justifyContent="flex-end">
							<Button variant="contained" color="warning" onClick={handleReturn}>
								Cancelar
							</Button>
							<Button variant="contained" color="primary" type="submit">
								Guardar
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		</Container>
	);
}
