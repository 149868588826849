import React from 'react';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Layout } from 'features/common/components/Layout';
import { BancardListOrangeAgency as Component } from 'features/transactions/components/BancardListOrangeAgencyParaguay';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { CountryCodeEnum } from '../../../features/common/types';
import { PageNotAllowedForMarket } from '../../../features/common/components/PageNotAllowedForMarket';

const useStyles = makeStyles((theme) => ({
	headerTitle: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

function TransactionListViajeNaranja(): JSX.Element {
	const classes = useStyles();
	const theme = useTheme();
	const { auth } = useSelector((state: RootState) => state);
	const countryCode = auth.country;

	const isItauVN = countryCode === CountryCodeEnum.TN_ITAU_PY;

	return (
		<Layout>
			<Container component="main" maxWidth="xl" sx={{ marginBottom: theme.spacing(3) }}>
				{isItauVN ? (
					<>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="h5" className={classes.headerTitle}>
									Lista de Transacciones en Bancard de Viajes Naranja
								</Typography>
							</Grid>
						</Grid>
						<Component />
					</>
				) : (
					<PageNotAllowedForMarket />
				)}
			</Container>
		</Layout>
	);
}

export default TransactionListViajeNaranja;
