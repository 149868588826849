import React, { useState } from 'react';
import { Button, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { QuotationDefaultDescription, QuotationFieldsMaxLength } from '../../types';
import { Edit, EditOff } from '@mui/icons-material';
import { checkShowErrorMessage } from '../../../common/helpers';

export interface DescriptionConfigProps {
	description: string;
	onChangeDescription: (newDescription: string, onChange: (...event: any[]) => void) => void;
	onSubmit: (data: QuotationDefaultDescription) => void;
}

export const DefaultDescriptionConfig = (props: DescriptionConfigProps): JSX.Element => {
	const orderFormDefaultValues = {
		description: props.description,
	};

	const [edit, setEdit] = useState<boolean>(false);

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues,
	} = useForm({ mode: 'all', defaultValues: orderFormDefaultValues });

	return (
		<Paper>
			<form onSubmit={handleSubmit(props.onSubmit)}>
				<Grid container p={2} spacing={2} display="flex" flexDirection="column">
					<Grid xs={12}>
						<Typography variant="h4" color="primary" textAlign="center">
							Descripción por Defecto de Cotizaciones
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="description"
							control={control}
							rules={{
								maxLength: {
									value: QuotationFieldsMaxLength.DESCRIPTION,
									message:
										'La descripción excede el largo máximo permitido de: ' +
										QuotationFieldsMaxLength.DESCRIPTION +
										' caracteres',
								},
							}}
							render={({ field: { value, onChange } }) => (
								<TextField
									id="outlined-multiline-static"
									label="Descripcion"
									multiline
									rows={5}
									sx={{ position: 'relative' }}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end" sx={{ position: 'absolute', top: 30, right: 15 }}>
												<IconButton onClick={() => setEdit(!edit)} edge="end">
													{edit ? <EditOff /> : <Edit />}
												</IconButton>
											</InputAdornment>
										),
									}}
									variant="outlined"
									disabled={!edit}
									value={value}
									helperText={checkShowErrorMessage(Boolean(errors.description), errors.description?.message)}
									error={Boolean(errors.description)}
									onChange={(event) => props.onChangeDescription(event.target.value, onChange)}
									fullWidth
								/>
							)}
						/>
						{QuotationFieldsMaxLength.DESCRIPTION - getValues().description.length >= 0 && (
							<Typography variant="body2" sx={{ color: 'green' }}>
								{'Total de caracteres para alcanzar el máximo permitido: ' +
									(QuotationFieldsMaxLength.DESCRIPTION - getValues().description.length)}
							</Typography>
						)}
					</Grid>
					<Grid item xs={4} alignSelf="flex-end">
						<Button variant="contained" type="submit" disabled={!edit || !isValid} color="success" fullWidth>
							Cambiar descripción por defecto
						</Button>
					</Grid>
				</Grid>
			</form>
		</Paper>
	);
};
