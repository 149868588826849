import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OrderForm as Component } from 'features/payment/components/OrderForm';
import { Layout } from '../../../features/common/components/Layout';
import { orderTypes } from '../../../features/common/types';
import makeStyles from '@mui/styles/makeStyles';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PageNotAllowedForMarket } from '../../../features/common/components/PageNotAllowedForMarket';
import { isAllowOrderTypeInCountry } from '../../../features/common/helpers';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
}));

export const OrderPage = (): JSX.Element => {
	const classes = useStyles();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const { auth } = useSelector((state: RootState) => state);
	const PaymentOrder = orderTypes.find((orderType) => {
		return orderType.id === +id;
	});
	const countryCode = auth.country;

	useEffect(() => {
		typeof PaymentOrder === 'undefined' && history.goBack();
	}, []);

	const isAllowedOrderType = isAllowOrderTypeInCountry(+id, countryCode);

	return typeof PaymentOrder === 'undefined' ? (
		<></>
	) : (
		<Layout>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					{!isAllowedOrderType ? <PageNotAllowedForMarket /> : <Component id={+id} name={PaymentOrder.name} />}
				</div>
			</Container>
		</Layout>
	);
};
