import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Component from './GeneralConfig';
import { RootState } from 'store';
import { isOnlyDigit } from '../../helpers';
import { findContactDataByDeal } from '../../../../services';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import {
	setAutoCompleteContactDataQuotationOnlyFlights,
	setCrmTicketQuotationOnlyFlights,
	setDescriptionQuotationOnlyFlights,
	setEmailQuotationOnlyFlights,
	setNameQuotationOnlyFlights,
	setPhoneQuotationOnlyFlights,
} from '../../sliceOnlyFlights';

interface Props {
	onErrorOnAutocompleteContact: (error: string) => void;
	showDescriptionField: boolean;
}
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.tooltip + 1,
		color: '#fff',
	},
}));

export default function GeneralConfigQuotationOnlyFlights(props: Props): JSX.Element {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { crmTicket, name, email, phone, description } = useSelector((state: RootState) => state.quotationOnlyFlights);
	const [loading, setLoading] = React.useState(false);

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setEmailQuotationOnlyFlights(e.target.value));
	};
	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setNameQuotationOnlyFlights(e.target.value));
	};
	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setPhoneQuotationOnlyFlights(e.target.value));
	};
	const handleCRMTicketChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isOnlyDigit(e.target.value) && Number(e.target.value) > 0)
			dispatch(setCrmTicketQuotationOnlyFlights(Number(e.target.value)));
	};

	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setDescriptionQuotationOnlyFlights(e.target.value));
	};

	const handleConfirmAutoCompleteContactDataByDeal = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && crmTicket > 0) {
			handleAutoCompleteNameEmailAndPhone();
		}
	};

	const handleAutoCompleteNameEmailAndPhone = async () => {
		try {
			setLoading(true);
			const response = await findContactDataByDeal(crmTicket);
			dispatch(setAutoCompleteContactDataQuotationOnlyFlights(response.data));
			setLoading(false);
		} catch (error) {
			const errorMessage =
				error['response'] && error['response']['data'] && error['response']['data']['message']
					? error['response']['data']['message']
					: 'No se pudo obtener los datos de contacto';
			setLoading(false);
			props.onErrorOnAutocompleteContact(errorMessage);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Component
				onEmailChange={handleEmailChange}
				onCRMTicketChange={handleCRMTicketChange}
				onPhoneChange={handlePhoneChange}
				onNameChange={handleNameChange}
				onConfirmAutoCompleteDataByDeal={handleConfirmAutoCompleteContactDataByDeal}
				crmTicket={crmTicket}
				name={name}
				email={email}
				phone={phone}
				descripcion={description}
				onDescriptionChange={handleDescriptionChange}
				showDescriptionField={props.showDescriptionField}
			/>
		</>
	);
}
