import React, { useEffect } from 'react';

import { Box, Container, FormLabel, Slider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { IDurationFilter } from '../../types';

interface Props {
	maxFlightDurationInHour: number;
	minHourInStop: number;
	maxHourInStop: number;
	handleChangeFilter: (data: IDurationFilter | null) => void;
}

const useStyles = makeStyles(() => ({
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		color: 'white',
	},
	filterContent: {
		backgroundColor: 'rgb(241, 242, 241)',
		borderBottomRightRadius: '14px',
		borderBottomLeftRadius: '14px',
		paddingTop: '25px',
		paddingBottom: '25px',
		paddingRight: '15px',
		paddingLeft: '15px',
		transition: 'max-height 0.26s ease 0s, padding 0.26s ease 0s',
		overflowY: 'auto',
	},
}));

export const FlightExplorerDurationFilter = ({
	maxFlightDurationInHour,
	minHourInStop,
	maxHourInStop,
	handleChangeFilter,
}: Props): JSX.Element => {
	const classes = useStyles();
	const [rangeHourInStop, setRangeHourInStop] = React.useState<number[]>([minHourInStop, maxHourInStop]);
	const [flightDuration, setFlightDuration] = React.useState<number>(maxFlightDurationInHour);
	const theme = useTheme();
	const handleChangeScalaValue = (event: Event, newValue: number | number[]) => {
		const newArray = newValue as number[];
		if (newArray[0] != rangeHourInStop[0] || newArray[1] != rangeHourInStop[1]) setRangeHourInStop(newArray);
	};
	const valuetext = (value: number) => `${value} horas`;

	const handleChangeFlightDuration = (event: Event, newValue: number | number[]) => {
		if (typeof newValue === 'number') {
			setFlightDuration(newValue);
		}
	};

	useEffect(() => {
		if (
			rangeHourInStop[0] === minHourInStop &&
			rangeHourInStop[1] === maxHourInStop &&
			flightDuration === maxFlightDurationInHour
		) {
			handleChangeFilter(null);
		} else {
			handleChangeFilter({
				...(flightDuration != maxFlightDurationInHour && { maxHourTravelDuration: flightDuration }),
				...((rangeHourInStop[0] !== minHourInStop || rangeHourInStop[1] != maxHourInStop) && {
					durationRangeStop: { from: rangeHourInStop[0], to: rangeHourInStop[1] },
				}),
			} as IDurationFilter);
		}
	}, [rangeHourInStop, flightDuration]);

	return (
		<Container sx={{ 'margin-bottom': '15px' }}>
			<Box sx={{ 'padding-left': '15px', backgroundColor: '#8167A3', height: '25px' }}>
				<FormLabel className={classes.title}>Duración</FormLabel>
			</Box>
			<Box className={classes.filterContent}>
				<Typography
					variant="subtitle1"
					component="span"
					fontSize={14}
					color={theme.palette.primary.main}
					fontWeight={'bold'}
				>
					{' '}
					Tiempo de viaje máximo{' '}
				</Typography>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography
						variant="subtitle1"
						component="span"
						fontSize={12}
						color={theme.palette.secondary.main}
						fontWeight={'bold'}
					>
						{' '}
						Hasta {flightDuration} horas{' '}
					</Typography>
				</div>

				<Slider
					sx={{ width: '95%' }}
					size={'medium'}
					value={flightDuration}
					color={'secondary'}
					onChange={handleChangeFlightDuration}
					valueLabelDisplay="off"
					max={maxFlightDurationInHour}
				/>

				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Typography
						variant="subtitle1"
						component="span"
						fontSize={12}
						color={theme.palette.secondary.main}
						fontWeight={'bold'}
					>
						{' '}
						{maxFlightDurationInHour} horas{' '}
					</Typography>
				</div>

				{minHourInStop != maxHourInStop && (
					<>
						<Typography
							variant="subtitle1"
							component="span"
							fontSize={14}
							color={theme.palette.primary.main}
							fontWeight={'bold'}
						>
							{' '}
							Escala{' '}
						</Typography>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography
								variant="subtitle1"
								component="span"
								fontSize={12}
								color={theme.palette.secondary.main}
								fontWeight={'bold'}
							>
								{' '}
								De {rangeHourInStop[0]} a {rangeHourInStop[1]} horas{' '}
							</Typography>
						</div>

						<Slider
							sx={{ width: '95%' }}
							size={'medium'}
							getAriaLabel={() => 'Rango de horas'}
							value={rangeHourInStop}
							color={'secondary'}
							onChange={handleChangeScalaValue}
							valueLabelDisplay="off"
							getAriaValueText={valuetext}
							min={minHourInStop}
							max={maxHourInStop}
						/>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography
								variant="subtitle1"
								component="span"
								fontSize={12}
								color={theme.palette.secondary.main}
								fontWeight={'bold'}
							>
								{' '}
								{minHourInStop} horas{' '}
							</Typography>
							<Typography
								variant="subtitle1"
								component="span"
								fontSize={12}
								color={theme.palette.secondary.main}
								fontWeight={'bold'}
							>
								{' '}
								{maxHourInStop} horas
							</Typography>
						</div>
					</>
				)}
			</Box>
		</Container>
	);
};
