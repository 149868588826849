import React, { useEffect, useRef, useState } from 'react';
import { Alert, AlertColor, Backdrop, CircularProgress, Snackbar, useTheme } from '@mui/material';
import { extractErrorMessage } from 'features/quotation/helpers';
import makeStyles from '@mui/styles/makeStyles';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import moment from 'moment';
import { retrievePackagesPrices } from '../../services';
import { IPackagePriceByTravelDate } from '../../types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

interface DataToChart {
	labels: string[];
	prices: number[];
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
	select: {
		width: '-webkit-fill-available',
	},
}));

export interface PackagePricesProps {
	packageId: number;
	handleSelectTravelDate: (item: IPackagePriceByTravelDate | null) => void;
}

export const PackagePrices = ({ packageId, handleSelectTravelDate }: PackagePricesProps): JSX.Element => {
	const theme = useTheme();
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [dataToChart, setDataToChart] = useState<DataToChart | null>(null);
	const [backgroundColorToChart, setBackgroundColorToChart] = useState<string[]>([]);
	const [indexSelected, setIndexSelected] = useState<number>(-1);

	const fromMoment = moment().add(20, 'days');
	const startDate = fromMoment.format('YYYY') + '-' + fromMoment.format('MM') + '-' + fromMoment.format('DD');

	const toMoment = fromMoment.add(1, 'year');
	const endDate = toMoment.format('YYYY') + '-' + toMoment.format('MM') + '-' + toMoment.format('DD');
	const chartRef = useRef();
	const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
		// @ts-ignore
		const index = getElementsAtEvent(chartRef.current, event)[0]?.index;
		if (!isNaN(index)) {
			setIndexSelected(index);
			handleSelectTravelDate({
				travelDate: dataToChart?.labels[index],
				priceInUSD: dataToChart?.prices[index],
			} as IPackagePriceByTravelDate);
		}
	};

	const getPackagePrices = async () => {
		try {
			setLoading(true);
			setDataToChart(null);
			handleSelectTravelDate(null);
			const containerPackagePriceByDate = (await retrievePackagesPrices(packageId, startDate, endDate)).data;
			const labels: string[] = [];
			const prices: number[] = [];
			containerPackagePriceByDate.items.forEach((value) => {
				labels.push(value.travelDate);
				prices.push(value.priceInUSD);
			});
			containerPackagePriceByDate?.minPriceInfo && handleSelectTravelDate(containerPackagePriceByDate?.minPriceInfo);
			const indexMin = labels.indexOf(containerPackagePriceByDate?.minPriceInfo?.travelDate || '');
			setDataToChart({ labels: labels, prices: prices });
			setIndexSelected(indexMin);
			setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir las tarifas del paquete'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getPackagePrices();
	}, [packageId]);

	useEffect(() => {
		setBackgroundColorToChart([
			...(dataToChart?.prices.map((value, index) =>
				index != indexSelected ? theme.palette.primary.main : theme.palette.secondary.main,
			) || []),
		]);
	}, [dataToChart, indexSelected]);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: true,
				text: 'Precios por dia para los próximos 9 meses',
			},
		},
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{dataToChart && dataToChart.labels.length > 0 ? (
				<Bar
					ref={chartRef}
					onClick={onClick}
					options={options}
					data={{
						labels: dataToChart?.labels,
						datasets: [
							{
								label: 'Tarifas por Adulto',
								data: dataToChart?.prices,
								minBarLength: 50,
								backgroundColor: backgroundColorToChart,
							},
						],
					}}
				/>
			) : packageId == -1 ? (
				'Selecciona un paquete para ver el comportamiento de las tarifas'
			) : (
				'Este paquete no cuenta con tarifas automáticas por lo que no podemos mostrarte la estadística de precios'
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
