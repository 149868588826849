import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import StoreIcon from '@mui/icons-material/Store';
import withStyles from '@mui/styles/withStyles';

import visaLogo from '../../../../assets/visaLogo.png';
import masterLogo from '../../../../assets/masterLogo.png';
import amexLogo from '../../../../assets/amexLogo.png';
import itauLogo from '../../../../assets/itauLogo.svg';
import moment from 'moment';

//import interfisa from '../../../assets/interfisa-bank.jpg';
//import basa from '../../../assets/banco-basa-bank.jpg';
//import atlas from '../../../assets/banco-atlas-bank.jpg';
//import sudameris from '../../../assets/sudameris-bank.jpg';
//import vision from '../../../assets/vision-bank.jpg';
//import gnb from '../../../assets/gnb-bank.jpg';
//import continental from '../../../assets/continental-bank.jpg';
//import regional from '../../../assets/regional-bank.jpg';

const WhiteTextTypography = withStyles({
	root: {
		color: '#FFFFFF',
	},
})(Typography);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(2),
			maxWidth: '100%',
		},
		cardsItem: {
			padding: '5px',
			display: 'inline-block',
			textAlign: 'center',
			verticalAlign: 'middle',
		},
		cardBoxHolder: {
			marginTop: 20,
		},
		cardBox: {
			width: 260,
		},
		consulta: {
			marginTop: 30,
		},
		background: {
			backgroundColor: theme.palette.primary.main,
			borderRadius: '8px',
		},
		itauBox: {
			backgroundColor: ' #EE7000',
			borderRadius: '8px',
			display: 'flex',
		},
		itauContentBox: {
			alignItems: 'center',
			display: 'flex',
			width: '100%',
			placeContent: 'space-evenly',
		},
		itauLogo: {
			width: '80px',
		},
		bankBox: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			backgroundColor: 'white',
			borderRadius: '8px',
			padding: '5px',
			marginTop: '5px',
		},
		noUnderlineLinks: {
			textDecoration: 'none',
			color: 'inherit',
		},
	}),
);

const banksPromotionData = [
	{
		bankName: 'Interfisa',
		link: 'https://www.interfisa.com.py/bases/BC_PromoAgeVia.pdf',
		description: 'Hasta 12 y 18 cuotas sin interés según la tarjeta de crédito del Banco Interfisa',
		promotionOn: '2023-05-16',
		promotionDateOff: '2023-12-31',
	},
	{
		bankName: 'Basa',
		link: 'https://www.bancobasa.com.py/storage/app/media/pdf/bases-condiciones/tarjetas-institucionales/agencias-de-viaje-2022.pdf',
		description: 'Hasta 12 cuotas sin interés con tarjetas de crédito del Banco Basa',
		promotionOn: '2022-01-01',
		promotionDateOff: '2022-06-30',
	},
	{
		bankName: 'Atlas',
		link: 'https://www.bancoatlas.com.py/web/beneficios/beneficios.html',
		description: 'Hasta 6 cuotas sin interés con tarjetas de crédito del Banco Atlas',
		promotionOn: '2022-01-01',
		promotionDateOff: '2022-12-31',
	},
	{
		bankName: 'Sudameris',
		link: 'https://www.sudameris.com.py/storage/app/media/12-cuotas-2023.pdf',
		description: 'Hasta 12 cuotas sin interés con tarjetas de crédito del Banco Sudameris',
		promotionOn: '2023-01-01',
		promotionDateOff: '2023-12-31',
	},
	{
		bankName: 'Vision',
		link: 'https://www.visionbanco.com/archivos/Bases_y_condiciones_-_Agencias_y_Aerolineas_Nuevo_Formato_-_12.23.pdf',
		description: 'Hasta 18 cuotas sin interés con tarjetas de crédito del Banco Vision',
		promotionOn: '2023-01-02',
		promotionDateOff: '2023-12-31',
	},
	{
		bankName: 'GNB',
		link: 'https://www.beneficiosbancognb.com.py/beneficios/14/',
		description: 'Hasta 12 cuotas sin interés con tarjetas de crédito del Banco GNB',
		promotionOn: '2023-04-01',
		promotionDateOff: '2023-08-31',
	},
	{
		bankName: 'Familia',
		link: 'https://www.familiar.com.py/assets/media/info-util/promociones/11-02-22%20BASES%20Y%20CONDICIONES%20PROMOCION%20-%20VIAJES.pdf?uid=1644982999',
		description: 'Hasta 12 cuotas sin interés con tarjetas de crédito del Banco Familia',
		promotionOn: '2022-02-01',
		promotionDateOff: '2022-12-31',
	},
	{
		bankName: 'Continental',
		link: 'https://www2.bancontinental.com.py/files/Guia-de-beneficios-de-tarjetas-y-contipuntos-4-01.pdf',
		description: 'Hasta 12 cuotas sin interés con tarjetas de crédito del Banco Continental',
		promotionOn: '2022-01-01',
		promotionDateOff: '2022-12-31',
	},
	{
		bankName: 'Regional',
		link: 'https://cdn.regional.com.py/media/uploaded-files/Beneficios/Cat%C3%A1logo%20CENTRAL%2027.04.pdf',
		description: 'Hasta 12 cuotas sin interés con tarjetas de crédito del Banco Regional',
		promotionOn: '2022-01-01',
		promotionDateOff: '2022-08-31',
	},
];

const MevueloPYPaymentMethods = (): JSX.Element => {
	const classes = useStyles();
	const currentDate = moment();
	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="md">
				<Box className={classes.background} paddingY={1} marginY={2}>
					<WhiteTextTypography variant="h6" align="center">
						NUESTRAS FORMAS DE PAGO:{' '}
					</WhiteTextTypography>
				</Box>
				<Box display="flex" justifyContent="space-around" alignItems="center">
					<Box display="flex" flexDirection="column" alignItems="center">
						<AccountBalanceWalletIcon color="secondary" fontSize="large" />
						<Typography variant="subtitle2" align="center">
							Transferencia bancaria
						</Typography>
					</Box>
					<Box display="flex" flexDirection="column" alignItems="center">
						<CreditCardIcon color="secondary" fontSize="large" />
						<Typography variant="subtitle2" align="center">
							Tarjetas de crédito y débito
						</Typography>
					</Box>
					<Box display="flex" flexDirection="column" alignItems="center">
						<StoreIcon color="secondary" fontSize="large" />
						<Typography variant="subtitle2" align="center">
							Pago en el local
						</Typography>
					</Box>
					<Box display="flex" flexDirection="column" alignItems="center">
						<AccountBalanceIcon color="secondary" fontSize="large" />
						<Typography variant="subtitle2" align="center">
							Redes de cobranza
						</Typography>
					</Box>
				</Box>
				<Box className={classes.cardBoxHolder} display="flex" justifyContent="center" alignItems="flex-end">
					<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
						<Box className={classes.cardsItem}>
							<img src={visaLogo} alt="visa" />
						</Box>
						<Typography variant="subtitle2" align="center"></Typography>
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
						<Box className={classes.cardsItem}>
							<img src={amexLogo} alt="american-express" />
						</Box>
						<Typography variant="subtitle2" align="center"></Typography>
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" className={classes.cardBox}>
						<Box className={classes.cardsItem}>
							<img src={masterLogo} alt="master-card" />
						</Box>
						<Typography variant="subtitle2" align="center"></Typography>
					</Box>
				</Box>
				<Typography className={classes.consulta} variant="subtitle1" align="center">
					Aprovechá nuestros beneficios directos:
				</Typography>
				<a
					className={classes.noUnderlineLinks}
					href="https://www.itau.com.py/Beneficios/Detalle/6062-agenciadeviajes"
					target="_blank"
					rel="noreferrer"
				>
					<Box className={classes.itauBox} padding={2}>
						<img src={itauLogo} className={classes.itauLogo} />
						<Box paddingX={2} className={classes.itauContentBox}>
							<Box>
								<WhiteTextTypography variant="subtitle2" align="center">
									Hasta
								</WhiteTextTypography>
								<WhiteTextTypography variant="h3" align="center">
									10
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle2" align="center">
									cuotas sin interés
								</WhiteTextTypography>
							</Box>
							<Box>
								<WhiteTextTypography variant="subtitle2" align="center">
									con tarjetas de crédito de
								</WhiteTextTypography>
								<WhiteTextTypography variant="h4" align="center">
									Itaú
								</WhiteTextTypography>
								<WhiteTextTypography variant="subtitle2" align="center">
									*12 cuotas con AMEX o Visa Infinite
								</WhiteTextTypography>
							</Box>
						</Box>
					</Box>
				</a>
				{banksPromotionData.map((item, index) => {
					const promotionOn = moment(item.promotionOn, 'YYYY-MM-DD');
					const promotionOff = moment(item.promotionDateOff, 'YYYY-MM-DD');
					const isPromotionAvailable = currentDate.isBetween(promotionOn, promotionOff, 'day', '[]');

					return (
						<div key={index}>
							{isPromotionAvailable && (
								<a className={classes.noUnderlineLinks} href={item.link} target="_blank" rel="noreferrer">
									<Box className={classes.bankBox}>
										{/* <img src={item.logo} /> */}
										<Typography variant="subtitle2" align="center">
											{item.description}
										</Typography>
									</Box>
								</a>
							)}
						</div>
					);
				})}

				<Typography className={classes.consulta} variant="subtitle1" align="center">
					Tarjetas de crédito de otros bancos en un solo pago sin interés o en cuotas con recargo. Consultá con tu
					banco.
				</Typography>
			</Container>
		</div>
	);
};

export default MevueloPYPaymentMethods;
