import React from 'react';
import { Button, ButtonProps, CircularProgress, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			position: 'relative',
		},
		progress: {
			color: theme.palette.primary.main,
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	}),
);

interface LoadingButtonProps extends ButtonProps {
	loading: boolean;
}

export const LoadingButton = ({ loading, ...rest }: LoadingButtonProps): JSX.Element => {
	const classes = useStyles();
	return (
		<div className={classes.wrapper}>
			<Button {...rest} />
			{loading && <CircularProgress size={24} className={classes.progress} />}
		</div>
	);
};
