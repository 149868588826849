import { AxiosResponse } from 'axios';
import endpoints from 'constants/endpoints';
import { formatSortingFields } from 'features/common/helpers';
import { IEnvelopeList, ISort } from 'features/common/types';
import { ApiService } from 'services';
import { ICrossList } from './types';

export const getCrossesList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<ICrossList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.crosses, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};
export const discountCross = (crossId: number): Promise<AxiosResponse> =>
	ApiService.patch(endpoints.crosses + `/${crossId}`);

export const getCrossesListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<ICrossList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.crosses, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};
