export interface Children {
	age: number;
}

export interface HotelRoom {
	adults: number;
	childrenNumber: number;
	childrenList: Children[];
}

export class HotelRoomClass implements HotelRoom {
	adults: number;
	childrenNumber: number;
	childrenList: Children[];
	constructor(adults: number, childrenNumber: number, childrenList: Children[]) {
		this.adults = adults;
		this.childrenNumber = childrenNumber;
		this.childrenList = childrenList;
	}
}

export class ChildrenClass implements Children {
	age: number;
	constructor(age: number) {
		this.age = age;
	}
}

export interface IHotelSearchForm {
	destination: string;
	checkIn: string;
	checkOut: string;
	nights: number;
	roomAmount: number;
	packagingRate: boolean;
	rooms: HotelRoom[];
}

export interface HotelDetail {
	name: string;
	address: string;
	accommodationCategory: number;
	travelonuxId: number;
	mainImage: string;
	airConditioning: boolean;
	pool: boolean;
	wifi: boolean;
	gym: boolean;
	parking: boolean;
	description: string | undefined;
	rating: number | undefined;
	ratingText: string | undefined;
	amountReviews: number | undefined;
	lat: number | undefined;
	lng: number | undefined;
	link: string | undefined;
}

export enum HotelFareTabEnum {
	DESCRIPTION = 1,
	PHOTOS,
	MAP,
	OPINIONS,
}
export interface IOpinion {
	icon: number;
	info: string;
	user: string;
	date: string;
	opinion: string;
}
export interface ISummaryOpinion {
	point: number;
	note: string;
}
