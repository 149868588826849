import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { IPackageExplorerDestination } from '../../types';
import makeStyles from '@mui/styles/makeStyles';
import NumberFormat from 'react-number-format';
import { getBasePriceString } from '../../helpers';

export interface PackageExplorerDestinationCardProps {
	destination: IPackageExplorerDestination;
}
const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '500',
		fontSize: '18px',
		color: theme.palette.primary.main,
		padding: '5px',
	},
	descriptionHolder: {
		display: 'flex',
		padding: '0px 5px 5px',
	},
	descriptionHolderItem: {
		flex: '1 1 0%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	itemDescription: {
		fontSize: '12px',
		color: 'rgb(123, 123, 123)',
		fontFamily: 'Montserrat, serif',
		fontWeight: 'normal',
		fontStyle: 'normal',
		fontStretch: 'normal',
		marginBottom: '0px',
		textAlign: 'left',
	},
	priceHolder: {
		flex: '1 1 0%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},
	priceItem: {
		display: 'flex',
		alignItems: 'stretch',
	},
	fromAndCurrencyContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	fromItem: {
		fontSize: '8px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 600,
		lineHeight: '10px',
	},
	currencyItem: {
		fontSize: '14px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 600,
		lineHeight: '17px',
	},
	priceValueItem: {
		fontSize: '30px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 500,
		lineHeight: '37px',
	},
	normalItemText: {
		fontSize: '12px',
		color: theme.palette.secondary.main,
		fontFamily: 'Montserrat, serif',
		fontWeight: 'normal',
		fontStyle: 'normal',
		textAlign: 'right',
	},
}));
export const PackageExplorerDestinationCard = ({ destination }: PackageExplorerDestinationCardProps): JSX.Element => {
	const classes = useStyles();
	return (
		<Card style={{ cursor: 'pointer' }} elevation={5}>
			<CardMedia
				sx={{ height: 259 }}
				image={destination.image}
				title={`${destination.name}, ${destination.countryName}`}
			/>
			<CardContent>
				<Typography className={classes.title}>{`${destination.name}, ${destination.countryName}`}</Typography>

				<div className={classes.descriptionHolder}>
					<div className={classes.descriptionHolderItem}>
						<Typography className={classes.itemDescription} component={'p'}>
							{destination.travelOn}
						</Typography>
						<Typography className={classes.itemDescription} component={'p'}>
							{destination.duration}
						</Typography>
					</div>

					<div className={classes.priceHolder}>
						<div className={classes.priceItem}>
							<div className={classes.fromAndCurrencyContainer}>
								<Typography className={classes.fromItem} component={'span'}>
									DESDE
								</Typography>
								<Typography className={classes.currencyItem} component={'span'}>
									{destination.lowerPrice.currency}
								</Typography>
							</div>
							<Typography className={classes.priceValueItem} component={'span'}>
								{destination.lowerPrice.amount}
							</Typography>
						</div>
						{destination.lowerLocalPrice && (
							<Typography className={classes.normalItemText} component={'p'}>
								<NumberFormat
									value={destination.lowerLocalPrice.amount}
									displayType={'text'}
									decimalSeparator={','}
									thousandSeparator={'.'}
									prefix={`${destination.lowerLocalPrice.currency} `}
								/>
							</Typography>
						)}
						<Typography className={classes.normalItemText} component={'span'}>
							Precio por persona
						</Typography>
						<Typography className={classes.normalItemText} component={'span'} style={{ fontWeight: 'bold' }}>
							{getBasePriceString(destination.basePrice)}
						</Typography>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};
