import React from 'react';
import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IFlightFinalPriceSummary, IPassengerConfig } from '../../types';
import { Utility } from '../Utility';
import { useDispatch } from 'react-redux';
import { toggleShowLocalCurrencyOnlyFlights } from 'features/quotation/sliceOnlyFlights';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
	optionTableHolder: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	utility: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold',
	},
	sign: {
		color: theme.palette.primary.main,
	},
	optionsTitle: {
		fontStyle: 'normal',
		fontWeight: 600,
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(1),
	},
	localPrice: {
		display: 'flex',
		justifyContent: 'center',
	},
}));
interface Props {
	flightFinalPriceSummary: IFlightFinalPriceSummary;
	passengerConfig: IPassengerConfig;
	showUtility: boolean;
	markup: number | null;
	exchangeRate: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
	hideSwich?: boolean;
}

export const FlightPricePreviewDesktop = (props: Props): JSX.Element => {
	const { exchangeRate, localCurrency, showLocalCurrency } = props;
	const dispatch = useDispatch();
	const classes = useStyles();
	const { flightFinalPriceSummary, passengerConfig } = props;
	const totalChild = passengerConfig.childrenAges.length;
	const totalInfant = passengerConfig.infantAges.length;
	const totalInfants = passengerConfig.infantAges.length;
	const CHDExists = totalChild > 0;
	const INFExists = totalInfants > 0;
	const priceLocalADT = Math.round(flightFinalPriceSummary.adtPrice * exchangeRate)
		.toLocaleString()
		.replace(/,/g, '.');
	const priceLocalCHD = Math.round(flightFinalPriceSummary.chdPrice * exchangeRate)
		.toLocaleString()
		.replace(/,/g, '.');
	const priceLocalINF = Math.round(flightFinalPriceSummary.infPrice * exchangeRate)
		.toLocaleString()
		.replace(/,/g, '.');
	const priceLocalTOTAL = Math.round(flightFinalPriceSummary.totalPrice * exchangeRate)
		.toLocaleString()
		.replace(/,/g, '.');

	const handleShowLocalCurrency = () => {
		dispatch(toggleShowLocalCurrencyOnlyFlights());
	};
	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell align="center" colSpan={6} style={{ fontWeight: 'bold' }}>
							Presupuesto
							{!props.hideSwich && (
								<>
									<Switch checked={showLocalCurrency} onChange={handleShowLocalCurrency} />
									Ver precio local
								</>
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell align="right">Pasajeros</TableCell>
						<TableCell align="right">Precio x Adulto</TableCell>
						<TableCell align="right">{totalChild > 0 ? 'Precio x Niño' : ''}</TableCell>
						<TableCell align="right">{totalInfants > 0 ? 'Precio x Bebé' : ''}</TableCell>
						<TableCell align="right">Precio Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell align="right">
							{passengerConfig.adults} {passengerConfig.adults > 1 ? 'adultos' : 'adulto'} {CHDExists && '+'}{' '}
							{CHDExists && totalChild} {CHDExists ? (CHDExists && totalChild > 1 ? 'niños' : 'niño') : ''}{' '}
							{INFExists && '+'} {INFExists && totalInfant}{' '}
							{INFExists ? (INFExists && totalInfant > 1 ? 'bebés' : 'bebé') : ''}
						</TableCell>
						<TableCell align="center">
							USD {flightFinalPriceSummary.adtPrice}{' '}
							<Utility price={flightFinalPriceSummary.adtPrice} markup={props.markup} show={props.showUtility} />
							{showLocalCurrency && (
								<TableCell className={classes.localPrice}>
									{localCurrency} {priceLocalADT}
								</TableCell>
							)}
						</TableCell>
						<TableCell align="center">
							{CHDExists && 'USD'} {CHDExists && flightFinalPriceSummary.chdPrice}{' '}
							<Utility
								price={flightFinalPriceSummary.chdPrice}
								markup={props.markup}
								show={CHDExists && props.showUtility}
							/>
							{props.showLocalCurrency && CHDExists && (
								<TableCell className={classes.localPrice}>
									{localCurrency} {priceLocalCHD}
								</TableCell>
							)}
						</TableCell>
						<TableCell align="center">
							{INFExists && 'USD'} {INFExists && flightFinalPriceSummary.infPrice}{' '}
							<Utility
								price={flightFinalPriceSummary.infPrice}
								markup={props.markup}
								show={INFExists && props.showUtility}
							/>
						</TableCell>
						{showLocalCurrency && INFExists && (
							<TableCell className={classes.localPrice}>
								{localCurrency} {priceLocalINF}
							</TableCell>
						)}
						<TableCell align="center">
							USD {flightFinalPriceSummary.totalPrice}{' '}
							<Utility price={flightFinalPriceSummary.totalPrice} markup={props.markup} show={props.showUtility} />
							{showLocalCurrency && (
								<TableCell className={classes.localPrice}>
									{localCurrency} {priceLocalTOTAL}
								</TableCell>
							)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
