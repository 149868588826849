export enum CancelationType {
	COSTLESS = 1,
	PARTIAL,
	UNAVAILABLE,
	NONREFUNDABLE,
}

export interface CancelationTypeInterface {
	id: CancelationType;
	name: string;
}

export const cancelationOptions: CancelationTypeInterface[] = [
	{
		id: CancelationType.COSTLESS,
		name: 'Cancelacion sin costo',
	},
	{
		id: CancelationType.PARTIAL,
		name: 'Gastos de cancelación parciales',
	},
	{
		id: CancelationType.UNAVAILABLE,
		name: 'Gastos de cancelación no disponibles',
	},
	{
		id: CancelationType.NONREFUNDABLE,
		name: 'No Reembolsable',
	},
];
export enum StablishmentProfileFilter {
	SPORTS = 1,
	ENCHANTMENT,
	SPA,
	IPSUM,
}
export interface IstablishmentProfileFilter {
	id: StablishmentProfileFilter;
	name: string;
}
export const stablishmentProfiles: IstablishmentProfileFilter[] = [
	{
		id: StablishmentProfileFilter.SPORTS,
		name: 'Con Actividades Deportivas',
	},
	{
		id: StablishmentProfileFilter.ENCHANTMENT,
		name: 'Hoteles con Encanto',
	},
	{
		id: StablishmentProfileFilter.SPA,
		name: 'Hoteles con Spa',
	},
	{
		id: StablishmentProfileFilter.IPSUM,
		name: 'Donde se pite Ipsum',
	},
];

export enum RegimeFilter {
	FULL = 1,
	HALF,
	ROOMONLY,
	ROOOMANDBREAKFAST,
}

export interface RegimeTypeInterface {
	id: RegimeFilter;
	name: string;
}

export const regimeTypes: RegimeTypeInterface[] = [
	{
		id: RegimeFilter.FULL,
		name: 'Pension Completa',
	},
	{
		id: RegimeFilter.HALF,
		name: 'Media Pension',
	},
	{
		id: RegimeFilter.ROOMONLY,
		name: 'Solo Habitacion',
	},
	{
		id: RegimeFilter.ROOOMANDBREAKFAST,
		name: 'Alojamiento y desayuno',
	},
];

export enum InstalationsFilter {
	REDUCEDMOBILITY = 1,
	AC,
	BBQ,
	HIDROMASSAGE,
	BATHTUB,
	HEATING,
	CHIMNEY,
	SHOWER,
	GYM,
	COMMROOMS,
	INTERNET,
	PARKING,
	POOL,
	PRIVATEPOOL,
	DINNER,
	GAMEROOM,
	SPA,
	TV,
}

export interface IInstalations {
	id: InstalationsFilter;
	name: string;
}

export const instalationsTypes: IInstalations[] = [
	{
		id: InstalationsFilter.REDUCEDMOBILITY,
		name: 'Adaptado a movilidad reducida',
	},
	{
		id: InstalationsFilter.AC,
		name: 'Aire acondicionado',
	},
	{
		id: InstalationsFilter.BBQ,
		name: 'Instalaciones para barbacoas',
	},
	{
		id: InstalationsFilter.HIDROMASSAGE,
		name: 'Bañera de hidromasajes',
	},
	{
		id: InstalationsFilter.BATHTUB,
		name: 'Baño',
	},
	{
		id: InstalationsFilter.HEATING,
		name: 'Calefacción',
	},
	{
		id: InstalationsFilter.CHIMNEY,
		name: 'Chimenea',
	},
	{
		id: InstalationsFilter.SHOWER,
		name: 'Ducha',
	},
	{
		id: InstalationsFilter.GYM,
		name: 'Gimnasio',
	},
	{
		id: InstalationsFilter.COMMROOMS,
		name: 'Habitaciones comunicadas',
	},
	{
		id: InstalationsFilter.INTERNET,
		name: 'Internet',
	},
	{
		id: InstalationsFilter.PARKING,
		name: 'Parking',
	},
	{
		id: InstalationsFilter.POOL,
		name: 'Piscina',
	},
	{
		id: InstalationsFilter.PRIVATEPOOL,
		name: 'Piscina privada',
	},
	{
		id: InstalationsFilter.DINNER,
		name: 'Restaurante',
	},
	{
		id: InstalationsFilter.GAMEROOM,
		name: 'Sala de juegos',
	},
	{
		id: InstalationsFilter.SPA,
		name: 'Spa',
	},
	{
		id: InstalationsFilter.TV,
		name: 'Televisión',
	},
];

export enum LodgingTypeFilter {
	HOTEL = 1,
	INN,
}

export interface IlodgingType {
	id: LodgingTypeFilter;
	name: string;
}

export const lodingTypes: IlodgingType[] = [
	{
		id: LodgingTypeFilter.HOTEL,
		name: 'Hotel',
	},
	{
		id: LodgingTypeFilter.INN,
		name: 'Posada',
	},
];
