import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, Alert, Snackbar, Backdrop, CircularProgress } from '@mui/material';
import { VNTransactionLineTypeEnum } from 'features/transactions/types';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { extractErrorMessage } from 'features/quotation/helpers';
import { returnViajeNaranjaItauPoints } from 'features/transactions/services';
import { ShowAlertState } from 'features/common/types';

interface Props {
	open: boolean;
	handleClose: () => void;
	relationId: number;
	lineType: VNTransactionLineTypeEnum;
	onRefresh: () => void;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

export function ReturnViajeNaranjaItauPointsModal({
	open,
	handleClose,
	relationId,
	lineType,
	onRefresh,
}: Props): JSX.Element {
	const classes = useStyles();
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [loading, setLoading] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState('');

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};

	const style = {
		position: 'absolute' as const,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const handleConfirm = async () => {
		try {
			setLoading(true);
			await returnViajeNaranjaItauPoints(relationId, lineType);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Puntos extornados satisfactoriamente',
			});
			setLoading(false);
			handleClose();
			onRefresh();
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al extornar los puntos'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
				<Box sx={{ ...style, width: 500, textAlign: 'center' }}>
					<Button sx={{ ml: 55, p: 0, width: 5 }} onClick={handleClose}>
						X
					</Button>
					<p id="child-modal-title"> Estás seguro que quieres Extornar ? Esta acción es irreversible.</p>
					<p>Para continuar ingrese Extornar y haga clik en confirmar.</p>
					<input
						type="text"
						value={inputValue}
						onChange={handleInputChange}
						style={{
							marginBottom: 20,
							marginTop: 10,
							width: 300,
							textAlign: 'center',
							fontSize: '20px',
						}}
					/>
					<br />
					<Button disabled={inputValue !== 'Extornar'} variant="contained" onClick={handleConfirm}>
						Confirmar
					</Button>
				</Box>
			</Modal>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
