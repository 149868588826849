import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getBoardName, GetPAXTotalsResponse } from '../../helpers';
import { HotelOptionDetailDestinationMultiple, QuotationDestinationMultipleRoomWithPrice } from '../../types';
import { Utility } from '../Utility';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { CountryCodeEnum } from 'features/common/types';

const useStyles = makeStyles((theme) => ({
	cardRoom: { marginTop: theme.spacing(1), paddingLeft: '20px', paddingRight: '20px' },
	roomTitle: {
		fontStyle: 'normal',
		fontWeight: 700,
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.secondary.main,
		marginBottom: theme.spacing(1),
	},
	textInfo: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
	},
	regimenInfo: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
		color: 'rgba(0,0,0,0.57)',
	},
	priceTitle: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
		float: 'left',
	},
	signTitle: {
		fontSize: '10px',
		fontStyle: 'italic',
		fontWeight: 700,
		lineHeight: '5px',
		color: '#71BCED',
	},
	signValue: {
		fontSize: '9px',
		fontStyle: 'italic',
		fontWeight: 700,
		color: '#71BCED',
		float: 'right',
		lineHeight: '5px',
		marginLeft: theme.spacing(1),
	},
	priceValue: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 700,
		color: theme.palette.secondary.main,
		float: 'right',
		marginRight: '15px',
	},
	rowPriceContainer: {
		marginTop: theme.spacing(1),
	},
	rowFinalPriceContainer: {
		marginTop: theme.spacing(2),
	},
}));
interface Props {
	roomsSummaryPrice: QuotationDestinationMultipleRoomWithPrice;
	indexRoom: number;
	hotels: HotelOptionDetailDestinationMultiple[];
	paxTotals: GetPAXTotalsResponse;
	showUtility: boolean;
	markup: number | null;
	localCurrency: string;
	exchangeRate: number;
	showLocalCurrency?: boolean;
}

export const RoomPricesMultiDestinationPreviewCard = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { roomsSummaryPrice, indexRoom, paxTotals, hotels, localCurrency, exchangeRate, showLocalCurrency } = props;
	const { auth } = useSelector((state: RootState) => state);
	const boardNames = roomsSummaryPrice.boards.map((x) => getBoardName(x)).join(' , ');
	const paxCountInRoom = paxTotals.totalPaxPerRoom[indexRoom];
	const totalAdults = paxCountInRoom.totalAdults;
	const CHDExists = paxCountInRoom.hasChild;
	const INFExists = paxCountInRoom.hasInfant;
	const localPriceADT = Math.round(roomsSummaryPrice.adtPrice * exchangeRate)
		.toLocaleString()
		.replace(/,/g, '.');
	const localPriceCHD = Math.round(roomsSummaryPrice.chdPrice * exchangeRate)
		.toLocaleString()
		.replace(/,/g, '.');
	const localPriceRoom = Math.round(roomsSummaryPrice.totalPrice * exchangeRate)
		.toLocaleString()
		.replace(/,/g, '.');

	return (
		<Grid gap={1} wrap="nowrap" container>
			<Grid component={Card} minWidth={325} item>
				<CardContent>
					<Typography component="h1" variant="subtitle1" className={classes.roomTitle}>
						Habitación {indexRoom + 1}
					</Typography>
					<Typography className={classes.textInfo} component={'p'} variant={'body2'}>
						{' '}
						{totalAdults} {totalAdults > 1 ? 'adultos' : 'adulto'} {CHDExists && '+'}{' '}
						{CHDExists && paxCountInRoom.totalChild}{' '}
						{CHDExists ? (CHDExists && paxCountInRoom.totalChild > 1 ? 'niños' : 'niño') : ''} {INFExists && '+'}{' '}
						{INFExists && paxCountInRoom.totalInfant}{' '}
						{INFExists ? (INFExists && paxCountInRoom.totalInfant > 1 ? 'bebés' : 'bebé') : ''} por habitación{' '}
					</Typography>
					<Typography className={classes.textInfo} component={'span'} variant={'body2'}>
						Régimen:
					</Typography>{' '}
					<Typography className={classes.regimenInfo} component={'span'} variant={'body2'}>
						{boardNames}
					</Typography>
					{hotels.length > 0 &&
						hotels.map((hotel) => {
							if (hotel.rooms[indexRoom].description && hotel.rooms[indexRoom].description !== '') {
								return (
									<>
										<br />
										<Typography className={classes.textInfo} component="span" fontSize={11} variant={'body2'}>
											{hotel.hotelName}:{' '}
										</Typography>
										<Typography component={'span'} fontSize={12} variant={'body2'}>
											{hotel.rooms[indexRoom].description}
										</Typography>
									</>
								);
							}
						})}
					<Grid container>
						<Grid item xs={6}>
							<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
								Precio por adulto
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
								USD {roomsSummaryPrice.adtPrice}{' '}
								{props.showUtility && props.markup != null && (
									<Utility price={roomsSummaryPrice.adtPrice} markup={props.markup} show={props.showUtility} />
								)}
							</Typography>
							{showLocalCurrency && (
								<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
									{localCurrency} {localPriceADT}
								</Typography>
							)}
						</Grid>
					</Grid>
					{roomsSummaryPrice.sign && (
						<Grid container>
							<Grid item xs={6}>
								<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
									{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.signValue} component={'p'} variant={'body2'}>
									USD {Math.round(roomsSummaryPrice.sign?.adtSign)}
								</Typography>
								{showLocalCurrency && (
									<Typography className={classes.signValue} component={'p'} variant={'body2'}>
										{localCurrency}{' '}
										{Math.round(roomsSummaryPrice.sign?.adtSign * exchangeRate)
											.toLocaleString()
											.replace(/,/g, '.')}
									</Typography>
								)}
							</Grid>
						</Grid>
					)}
					{CHDExists && (
						<div className={classes.rowPriceContainer}>
							<Grid container>
								<Grid item xs={6}>
									<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
										Precio por niño
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
										{CHDExists && 'USD'} {CHDExists && roomsSummaryPrice.chdPrice}{' '}
										{props.showUtility && props.markup != null && CHDExists && (
											<Utility price={roomsSummaryPrice.chdPrice} markup={props.markup} show={props.showUtility} />
										)}
									</Typography>
									{showLocalCurrency && (
										<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
											{localCurrency} {localPriceCHD}
										</Typography>
									)}
								</Grid>
							</Grid>

							{roomsSummaryPrice.sign && (
								<Grid container>
									<Grid item xs={6}>
										<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
											{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography className={classes.signValue} component={'p'} variant={'body2'}>
											USD {Math.round(roomsSummaryPrice.sign?.chdSign)}
										</Typography>
										{showLocalCurrency && (
											<Typography className={classes.signValue} component={'p'} variant={'body2'}>
												{localCurrency}{' '}
												{Math.round(roomsSummaryPrice.sign?.chdSign * exchangeRate)
													.toLocaleString()
													.replace(/,/g, '.')}
											</Typography>
										)}
									</Grid>
								</Grid>
							)}
						</div>
					)}
					{INFExists && (
						<div className={classes.rowPriceContainer}>
							<Grid container>
								<Grid item xs={6}>
									<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
										Precio por bebé
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
										{INFExists && 'USD'} {INFExists && roomsSummaryPrice.infPrice}{' '}
										{props.showUtility && props.markup != null && INFExists && (
											<Utility price={roomsSummaryPrice.infPrice} markup={props.markup} show={props.showUtility} />
										)}
									</Typography>
									{showLocalCurrency && (
										<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
											{localCurrency}{' '}
											{INFExists &&
												Math.round(roomsSummaryPrice.infPrice * exchangeRate)
													.toLocaleString()
													.replace(/,/g, '.')}
										</Typography>
									)}
								</Grid>
							</Grid>
							{roomsSummaryPrice.sign && (
								<Grid container>
									<Grid item xs={6}>
										<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
											{auth.country === CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography className={classes.signValue} component={'p'} variant={'body2'}>
											USD {Math.round(roomsSummaryPrice.sign?.infSign)}
										</Typography>
										{showLocalCurrency && (
											<Typography className={classes.signValue} component={'p'} variant={'body2'}>
												{localCurrency}{' '}
												{Math.round(roomsSummaryPrice.sign?.infSign * exchangeRate)
													.toLocaleString()
													.replace(/,/g, '.')}
											</Typography>
										)}
									</Grid>
								</Grid>
							)}{' '}
						</div>
					)}
					<div className={classes.rowFinalPriceContainer}>
						<Grid container>
							<Grid item xs={6}>
								<Typography className={classes.priceTitle} component={'p'} variant={'body2'}>
									Precio total por habitación
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
									USD {roomsSummaryPrice.totalPrice}
									{props.showUtility && props.markup != null && (
										<Utility price={roomsSummaryPrice.totalPrice} markup={props.markup} show={props.showUtility} />
									)}
								</Typography>
								{showLocalCurrency && (
									<Typography className={classes.priceValue} component={'p'} variant={'body2'}>
										{localCurrency} {localPriceRoom}
									</Typography>
								)}
							</Grid>
						</Grid>

						{roomsSummaryPrice.sign && (
							<Grid container>
								<Grid item xs={6}>
									<Typography className={classes.signTitle} component={'p'} variant={'body2'}>
										{auth.country == CountryCodeEnum.COLOMBIA ? 'Reserva desde' : 'Seña'}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography className={classes.signValue} component={'p'} variant={'body2'}>
										USD {Math.round(roomsSummaryPrice.sign?.totalSign)}
									</Typography>
									{showLocalCurrency && (
										<Typography className={classes.signValue} component={'p'} variant={'body2'}>
											{localCurrency}{' '}
											{Math.round(roomsSummaryPrice.sign?.totalSign * exchangeRate)
												.toLocaleString()
												.replace(/,/g, '.')}
										</Typography>
									)}
								</Grid>
							</Grid>
						)}
					</div>
				</CardContent>
			</Grid>
		</Grid>
	);
};
