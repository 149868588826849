import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';

import Divider from '@mui/material/Divider';

import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FavoriteHotelsFilter } from '../filters/FavoriteHotelsFilter';
import { HotelNameFilter } from '../filters/HotelNameFilter';

import { HotelRegimeFilter } from '../filters/HotelRegimeFilter';
import { HotelCategoryFilter } from '../filters/HotelCategoryFilter';
import { CancelationTypeFilter } from '../filters/CancelationTypeFilter';
import { StablishmentProfileFilter } from '../filters/StablishmentProfileFilter';
import { InstalationsFilter } from '../filters/InstalationsFilter';
import { LodgingTypeFilter } from '../filters/LodgingTypeFilter';
import { regimeTypes, cancelationOptions, stablishmentProfiles, instalationsTypes, lodingTypes } from '../types';

const drawerWidth = 320;

export const SideBar = (): JSX.Element => {
	const handleFavoriteHotels = (value: boolean): void => {
		console.log(value);
	};
	const handleNameFilter = (value: string): void => {
		console.log(value);
	};
	const handleChangeRegimenFilter = (value: number[]): void => {
		console.log(value);
	};
	const handleCategoryFilter = (value: number | null): void => {
		console.log(value);
	};
	const handleCncelationType = (value: number[]): void => {
		console.log(value);
	};
	const handleStablishmentFilter = (value: number[]): void => {
		console.log(value);
	};
	const handleInstalationFilter = (value: number[]): void => {
		console.log(value);
	};
	const handleLodingType = (value: number[]): void => {
		console.log(value);
	};
	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="permanent"
				anchor="right"
			>
				<Toolbar>
					<ListItemText sx={{ color: 'Grey' }}>
						<h6 style={{ fontSize: 18 }}> {'>'} Filtros</h6>
					</ListItemText>
				</Toolbar>
				<Divider />
				<Stack direction="row" spacing={2} sx={{ marginTop: 3, marginBottom: 3, marginLeft: 1 }}>
					<Button size="small" color="secondary" variant="contained">
						Aplicar
					</Button>
					<Button variant="contained" disabled>
						Remover Filtros
					</Button>
				</Stack>
				<Divider />
				<FavoriteHotelsFilter onChange={handleFavoriteHotels} />
				<HotelNameFilter onSearch={handleNameFilter} />
				<HotelRegimeFilter data={regimeTypes} onChange={handleChangeRegimenFilter} />

				<HotelCategoryFilter onChange={handleCategoryFilter} />
				<CancelationTypeFilter data={cancelationOptions} onChange={handleCncelationType} />
				<StablishmentProfileFilter data={stablishmentProfiles} onChange={handleStablishmentFilter} />
				<InstalationsFilter data={instalationsTypes} onChange={handleInstalationFilter} />
				<LodgingTypeFilter data={lodingTypes} onChange={handleLodingType} />
			</Drawer>
		</Box>
	);
};
