import React from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	Popover,
	Snackbar,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import { ContentPaste } from '@mui/icons-material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Controller, useForm } from 'react-hook-form';
import { checkShowErrorMessage } from '../../../common/helpers';
import { ISendClientLinkToPayOrder, LinkToClientMaxLength } from '../../types';
import { postLinkToClient, postLinkToClientVN } from '../../services';
import makeStyles from '@mui/styles/makeStyles';
import { extractErrorMessage } from '../../../quotation/helpers';
import CloseIcon from '@mui/icons-material/Close';

export interface SendClientLinkModalProps {
	open: boolean;
	link: string;
	id: string;
	handleClose: () => void;
	isViajeNaranja: boolean;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export const SendClientLinkModal = ({
	id,
	link,
	open,
	handleClose,
	isViajeNaranja,
}: SendClientLinkModalProps): JSX.Element => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues,
	} = useForm({ mode: 'all', defaultValues: { id: id, message: '' } });
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	const onSubmit = async () => {
		try {
			setLoading(true);
			if (isViajeNaranja) {
				await postLinkToClientVN(getValues() as ISendClientLinkToPayOrder);
			} else {
				await postLinkToClient(getValues() as ISendClientLinkToPayOrder);
			}

			setLoading(false);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Se ha enviado el link de pago al cliente satisfactoriamente',
			});
			setTimeout(() => {
				handleClose();
			}, 1500);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al enviar el link de pago al cliente'),
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
				<Toolbar
					id="draggable-dialog-title"
					style={{ cursor: 'move' }}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography variant="h5" color="primary">
						Enviar link al cliente
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<DialogContent>
					<Grid container gap={2}>
						<Grid item xs={12}>
							<TextField
								aria-readonly
								sx={{ width: '100%' }}
								value={link}
								InputProps={{
									endAdornment: (
										<>
											<Popover
												id={anchorEl ? 'simple-popover' : undefined}
												open={Boolean(anchorEl)}
												onClose={() => setAnchorEl(null)}
												anchorEl={anchorEl}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'left',
												}}
											>
												<Typography sx={{ p: 2 }}>Link Copiado al Portapapeles.</Typography>
											</Popover>
											<InputAdornment position="end">
												<IconButton
													onClick={(event) => {
														setAnchorEl(event.currentTarget);
														navigator.clipboard.writeText(link);
													}}
												>
													<ContentPaste />
												</IconButton>
											</InputAdornment>
										</>
									),
								}}
							></TextField>
						</Grid>
						<Grid item xs={12}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="message"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										maxLength: {
											value: LinkToClientMaxLength.MESSAGE,
											message:
												'El tamaño del mensaje excede la cantidad máxima de ' +
												LinkToClientMaxLength.MESSAGE +
												' caracteres',
										},
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											multiline
											focused
											rows={5}
											label="Mensaje"
											helperText={checkShowErrorMessage(Boolean(errors.message), errors.message?.message)}
											error={Boolean(errors.message)}
											{...field}
										/>
									)}
								/>
							</form>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onSubmit} disabled={!isValid || loading} type="submit">
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
