import {
	Alert,
	AlertColor,
	Backdrop,
	CircularProgress,
	Grid,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { pageSizeOptions } from 'constants/constants';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import {
	convertChargedAgainstOptionDataToFilter,
	convertFiltersToRecord,
	convertOperatorsDataToFilter,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	formatDateTimeToShowUser,
	getEnvelopedListPageTotal,
	getSortableFields,
} from 'features/common/helpers';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	IHeadCell,
	ISort,
	OperatorFilterEnum,
	SelectFilterListData,
	SelectFilterModelSimple,
} from 'features/common/types';
import { extractErrorMessage } from 'features/quotation/helpers';
import {
	getChargedAgainstOptions,
	getOperators,
	getTransactionList,
	getTransactionListToExport,
} from 'features/transactions/services';
import { ITransactionList } from 'features/transactions/types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ExportToExcelButton } from '../../../common/components/ExportToExcelButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { putFilterInProfile } from '../../../common/services';
import { TransactionAuthorizedReportModal } from '../TransactionAuthorizedReportModal';
import { isAdminOrSuperAdmin } from 'features/auth/helpers';
import FeedIcon from '@mui/icons-material/Feed';
import { PageNoResult } from '../../../common/components/PageNoResults';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
}));

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const headerCells: IHeadCell[] = [
	{
		field: 'id',
		sortable: true,
		headerName: '#',
	},
	{
		field: 'orderId',
		sortable: true,
		headerName: 'Orden',
	},
	{
		field: 'crmTicket',
		sortable: true,
		headerName: 'Deal',
	},
	{
		field: 'file',
		sortable: true,
		headerName: 'File',
	},
	{
		field: 'cardholder',
		sortable: true,
		headerName: 'Titular',
	},
	{
		field: 'cardStamp',
		sortable: true,
		headerName: 'Sello',
	},
	{
		field: 'cardNumberFormatted',
		sortable: true,
		headerName: 'Número de Tarjeta',
	},
	{
		field: 'cardExpiration',
		sortable: true,
		headerName: 'Vencimiento',
	},
	{
		field: 'cardSecurityCodeFormatted',
		sortable: true,
		headerName: 'Código de seguridad',
	},
	{
		field: 'fee',
		sortable: true,
		headerName: 'Cuotas',
	},
	{
		field: 'coinCode',
		sortable: true,
		headerName: 'Moneda',
	},
	{
		field: 'amount',
		sortable: true,
		headerName: 'Monto de Transacción',
	},
	{
		field: 'pendingAuthorization',
		sortable: true,
		headerName: 'Pendiente de Autorizar',
	},
	{
		field: 'pendingToCross',
		sortable: true,
		headerName: 'Pendiente de Cruzar',
	},
	{
		field: 'createdAt',
		sortable: true,
		headerName: 'Fecha de Creación',
	},
];

export interface ListProfileProps {
	profileFilters?: FilterModel[];
	profileSorts?: ISort[];
}

export const TransactionList = (props: ListProfileProps): JSX.Element => {
	const classes = useStyles();
	const history = useHistory();

	const [loading, setLoading] = useState<boolean>(false);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [transactionsList, setTransactionsList] = useState<ITransactionList[] | null>(null);
	const [sortFields, setSortFields] = useState<ISort[]>(
		props.profileSorts ? [...props.profileSorts] : [{ field: 'createdAt', order: 'desc' }],
	);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>(
		props.profileFilters ? [...props.profileFilters] : [],
	);
	const [operators, setOperators] = useState<SelectFilterListData[]>([]);
	const [chargedAgainstOptions, setChargedAgainstOptions] = useState<SelectFilterListData[]>([]);
	const [openModal, setOpenModal] = React.useState(false);

	const { auth } = useSelector((state: RootState) => state);

	const filters = [
		{
			label: 'Pendiente de Cruce mayor que 0',
			type: FilterType.BOOLEAN,
			key: 'pendingToCrossMajorZero',
		},
		{
			label: 'Pendiente de Autorización mayor que 0',
			type: FilterType.BOOLEAN,
			key: 'pendingAuthorizationMajorZero',
		},
		{
			label: 'Monto de Transacción',
			type: FilterType.NUMERIC,
			key: 'amountTransactionFrom',
			operator: OperatorFilterEnum.GREATEROREQUAL,
		},
		{
			label: 'Monto de Transacción',
			type: FilterType.NUMERIC,
			key: 'amountTransactionTo',
			operator: OperatorFilterEnum.LESSEROREQUAL,
		},
		new DateToFromFilterModel('La Fecha de cobro en autorizaciones', 'paymentDateFrom', 'paymentDateTo'),
		{ label: 'Fecha de Creación', type: FilterType.DATE, key: 'createdAt' },
		{ label: 'ID', type: FilterType.NUMERIC, key: 'id' },
		{ label: 'Orden', type: FilterType.STRING, key: 'orderId' },
		{
			label: 'Deal',
			type: FilterType.NUMERIC,
			key: 'crmTicket',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Titular',
			type: FilterType.STRING,
			key: 'cardholder',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'Sello',
			type: FilterType.STRING,
			key: 'cardStamp',
			operator: OperatorFilterEnum.CONTAINS,
		},
		{
			label: 'E-ticket',
			type: FilterType.STRING,
			key: 'eticket',
			operator: OperatorFilterEnum.EQUALS,
		},
		{
			label: 'Código de Autorización',
			type: FilterType.STRING,
			key: 'authorizationCode',
			operator: OperatorFilterEnum.EQUALS,
		},
		{ label: 'File', type: FilterType.STRING, key: 'file', operator: OperatorFilterEnum.EQUALS },
		{ label: 'Cuotas', type: FilterType.NUMERIC, key: 'fee', operator: OperatorFilterEnum.EQUALS },
		new SelectFilterModelSimple('Operador', 'operator', operators),
		new SelectFilterModelSimple('Cobrado contra', 'chargedAgainstOptionCode', chargedAgainstOptions),
		{
			label: 'Tarjeta ',
			type: FilterType.STRING,
			key: 'cardLastFourDigit',
			operator: OperatorFilterEnum.ENDS,
		},
		new DateToFromFilterModel('La Fecha de Creación', 'createdAtFrom', 'createdAtTo'),
		{ label: 'Fecha de Cruce', type: FilterType.DATE, key: 'crossDate' },
		new DateToFromFilterModel('La Fecha de Cruce', 'crossDateFrom', 'crossDateTo'),
		{ label: 'Fecha de cobro en autorizaciones', type: FilterType.DATE, key: 'paymentDate' },
	];

	const handleOpen = () => {
		setOpenModal(true);
	};
	const handleClose = () => {
		setOpenModal(false);
	};
	const userId = auth.user?.id;
	const apiKey = auth.apiKey;
	const countryCode = auth.country;
	const profileTag = 'transaction-list-tag';

	const getTransactionListToClient = async () => {
		try {
			setLoading(true);
			await putFilterInProfile(profileTag, apiKey, countryCode, sortFields, filterApplied, userId?.toString());
			const envelopedTransactionList = (
				await getTransactionList(currentPage - 1, currentSize, sortFields, convertFiltersToRecord(filterApplied))
			).data;
			setPageTotal(getEnvelopedListPageTotal(envelopedTransactionList));
			setTransactionsList(envelopedTransactionList.data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const getChargedAgainstOptionsAndOperators = async () => {
		try {
			setLoading(true);
			setChargedAgainstOptions(convertChargedAgainstOptionDataToFilter((await getChargedAgainstOptions()).data));
			setOperators(convertOperatorsDataToFilter((await getOperators()).data));
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al cargar los operadores y opciones de cobrado contra'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getChargedAgainstOptionsAndOperators();
	}, []);

	useEffect(() => {
		getTransactionListToClient();
	}, [filterApplied, sortFields, currentPage, currentSize]);

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const sortableFields = getSortableFields(headerCells);

	const transformValue = (key: string, value: any, row: ITransactionList) => {
		switch (key) {
			case 'createdAt':
				return formatDateTimeToShowUser(row.createdAt);
			case 'file':
				return row?.file ? row.file : '-';
			default:
				return value;
		}
	};

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const envelopedTransactionList = (
				await getTransactionListToExport(sortFields, convertFiltersToRecord(filterApplied))
			).data;
			const transactionLists = envelopedTransactionList.data;
			const optionsToExport = {
				title: 'Listado de Transacciones',
				headers: headerCells,
				widthColumns: [5, 40, 15, 20, 30, 15, 20, 15, 20, 10, 10, 20, 20, 20, 20],
				filename: 'Listado de Transacciones',
			} as ExportExcelOptions;
			exportToExcel(transactionLists, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado de transacciones'),
			});
			setLoading(false);
		}
	};

	const colSpan = headerCells.length;

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container gap={1} paddingY={5}>
				<Grid container spacing={2}>
					<Grid item xs={10}>
						<FilterGrid
							filters={filters}
							handleAppliedFilters={handleApplyFilters}
							preAppliedFilters={filterApplied}
						></FilterGrid>
					</Grid>
					<Grid item xs={2} sx={{ display: 'flex', justifyContent: 'right' }}>
						<ExportToExcelButton disabled={transactionsList?.length == 0} handleExportToExcel={handleExportToExcel} />
						{isAdminOrSuperAdmin(auth.user) && (
							<Button
								onClick={handleOpen}
								variant="contained"
								sx={{ ml: 1 }}
								title="Reporte de transacciones autorizadas con monto autorizado igual a pendiente a cruzar"
							>
								<FeedIcon />
							</Button>
						)}
						{openModal && <TransactionAuthorizedReportModal open={openModal} handleClose={handleClose} />}
					</Grid>
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												className={classes.tableHeader}
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													sx={{
														color: 'white !important',
														'&:hover': {
															color: 'white !important',
														},
													}}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{!loading && transactionsList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									transactionsList?.map((transaction) => (
										<TableRow
											key={transaction.id}
											hover
											onDoubleClick={() => history.push(`transaction/${transaction.id}`)}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell align="right" component="p">
												{transaction.id}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.orderId}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.crmTicket}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction?.file ? transaction.file : '-'}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.cardholder}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.cardStamp}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.cardNumberFormatted}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.cardExpiration}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.cardSecurityCodeFormatted}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.fee}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.coinCode}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.amount}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.pendingAuthorization}
											</TableCell>
											<TableCell align="right" component="p">
												{transaction.pendingToCross}
											</TableCell>
											<TableCell align="right" component="p">
												{formatDateTimeToShowUser(transaction.createdAt)}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={6} marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
