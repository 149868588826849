import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';

interface IWompiFormModalInitData {
	open: boolean;
	reference: number;
	amountInCents: number;
	publicKey: string;
	integritySignature: string;
	currency: string;
	customerData: any;
	onCancel: () => void;
}

export const WompiModalForm = ({
	reference,
	publicKey,
	amountInCents,
	currency,
	integritySignature,
	customerData,
}: IWompiFormModalInitData): JSX.Element | null => {
	const pathRoute = routes.payment.wompiCheckConfirmation.replace(':reference', String(reference));
	const redirectUrl = `${window.location.origin}${pathRoute}`;
	const options = {
		currency,
		amountInCents,
		reference: String(reference),
		redirectUrl,
		publicKey,
		signature: {
			integrity: integritySignature,
		},
		customerData: {
			email: customerData.email,
			fullName: customerData.fullName,
			phoneNumber: customerData.phoneNumber,
			phoneNumberPrefix: customerData.phoneNumberPrefix,
			legalId: customerData.legalId,
			legalIdType: customerData.legalIdType,
		},
	};
	const history = useHistory();
	const pagoWoompi = async () => {
		// @ts-ignore
		const woompiClient = new WidgetCheckout(options);
		woompiClient.open(function () {
			history.push(pathRoute);
		});
	};
	pagoWoompi();
	return null;
};
