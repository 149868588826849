import React from 'react';
import { TextField, Grid, Typography, Container, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { emailPattern } from 'features/common/constants';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function UserNew(): JSX.Element {
	const classes = useStyles();
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const onSubmit = (data: FieldValues) => console.log('data', data);
	console.log('errors', errors);

	return (
		<Container component="main" maxWidth="md">
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Crear nuevo Usuario
				</Typography>
				<form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Controller
								name="name"
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Nombre"
										autoFocus
										helperText="Es un valor requerido"
										error={Boolean(errors.name)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name="email"
								control={control}
								rules={{
									required: true,
									pattern: emailPattern,
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Correo"
										helperText="Email incorrecto"
										error={Boolean(errors.email)}
										{...field}
									/>
								)}
							/>
						</Grid>
						{/* To be updated later on with a select */}
						<Grid item xs={12} sm={6}>
							<Controller
								name="roles"
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Roles (separados x coma?)"
										helperText="Es un valor requerido"
										error={Boolean(errors.phone)}
										{...field}
									/>
								)}
							/>
						</Grid>
						{/* To be updated later on with a select */}
						<Grid item xs={12} sm={6}>
							<Controller
								name="agency"
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										label="Agencia"
										helperText="Es un valor requerido"
										error={Boolean(errors.country)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button variant="contained" color="primary" type="submit">
								Create User
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		</Container>
	);
}
