import { AxiosResponse } from 'axios';
import { AgencyList, InputAgency, OutputAgency } from './types';
import { ISort } from '../common/types';
import { ApiMeVueloService } from '../../services';
import { formatSortingFields } from '../common/helpers';

export const getAgencyPagination = (
	page: number,
	size: number,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<AgencyList>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiMeVueloService.get('agencies', {
		params: { page, size, ...(sorts.length > 0 && { sort: sorts }), ...(filters && filters) },
	});
};

export const getAgencyDetails = (id: number): Promise<AxiosResponse<OutputAgency>> =>
	ApiMeVueloService.get(`agencies/${id}`);

export const agencyFormData = (inputAgency: InputAgency, logoFile: File | null): any => {
	const formData = new FormData();
	Object.keys(inputAgency).forEach((key) => {
		if (key !== 'logo') {
			formData.append(`${key}`, `${inputAgency[key as keyof InputAgency]}`);
		}
	});

	if (logoFile) formData.append('logo', logoFile);
	return formData;
};

export function newAgencyPost(formData: FormData): any {
	return ApiMeVueloService.post('agencies', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

export function changeAgencyStatus(id: number, status: boolean): any {
	return ApiMeVueloService.patch(`agencies/${id}/enable?status=${status}`);
}

export function agencyUpdate(formData: FormData, id: number): any {
	formData.delete('id');
	formData.delete('active');
	return ApiMeVueloService.put(`agencies/${id}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}
