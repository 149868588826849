import React from 'react';
import { GatewayCancelTransaction as Component } from 'features/payment/components/GatewayCancelTransaction';
import { Header } from '../../../features/quotation/components/Header';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Footer from '../../../features/quotation/components/Footer/Footer';

export const CancelTransaction = (): JSX.Element => {
	const { payment } = useSelector((state: RootState) => state);

	return (
		<>
			<Header />
			{payment.orderDataToClient && (
				<>
					<Component />
					<Footer country={payment.orderDataToClient.plCountryCode} />
				</>
			)}
		</>
	);
};
