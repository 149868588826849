import React from 'react';
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { QuotationFieldsMaxLength } from '../../types';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	table: {
		minWidth: 650,
	},
	formControl: {
		marginTop: -3,
		width: '100%',
	},
	formGroup: {
		marginTop: 14,
	},
}));

interface Props {
	notIncludes: string[];
	handleAdd: (notInclude: string) => void;
	handleDelete: (index: number) => void;
}

export const NotIncludes = ({ notIncludes, handleAdd, handleDelete }: Props): JSX.Element => {
	const classes = useStyles();
	const [openDeleteServiceConfirmDialog, setOpenDeleteConfirmDialog] = React.useState(false);
	const [deletePayload, setDeletePayload] = React.useState<number>(-1);
	const [notIncludeText, setNotIncludeText] = React.useState<string>('');
	const handleClickOpenConfirmDelete = (index: number) => {
		setOpenDeleteConfirmDialog(true);
		setDeletePayload(index);
	};

	const handleCloseConfirmDelete = () => {
		setOpenDeleteConfirmDialog(false);
	};

	const isValidIncludeText = () =>
		notIncludeText &&
		notIncludeText.length > 0 &&
		notIncludeText.length <= QuotationFieldsMaxLength.NOTINCLUDES &&
		notIncludeText.replace(/ /g, '').length > 0;

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNotIncludeText(e.target.value);
	};
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && isValidIncludeText()) {
			handleAddNotInclude();
		}
	};

	const handleDeleteNotInclude = () => {
		setOpenDeleteConfirmDialog(false);
		handleDelete(deletePayload);
	};

	const handleAddNotInclude = () => {
		handleAdd(notIncludeText);
		setNotIncludeText('');
	};

	// @ts-ignore
	return (
		<Container component="main" maxWidth="md">
			<div>
				<Dialog
					open={openDeleteServiceConfirmDialog}
					onClose={handleCloseConfirmDelete}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{'Eliminar'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">¿Confirma la eliminación?</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={handleCloseConfirmDelete}>
							Cancelar
						</Button>
						<Button variant="contained" color="primary" onClick={handleDeleteNotInclude} autoFocus>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					No incluye
				</Typography>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Nombre</TableCell>
								<TableCell align="right" />
							</TableRow>
						</TableHead>
						<TableBody>
							{notIncludes?.map((notInclude, index) => {
								return (
									<TableRow key={'not-include-' + index}>
										<TableCell component="th" scope="row">
											{notInclude}
										</TableCell>
										<TableCell align="right">
											<IconButton
												color="secondary"
												component="span"
												onClick={() => handleClickOpenConfirmDelete(index)}
												size="large"
											>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<Grid container spacing={2} className={classes.form}>
					<Grid item xs={12} sm={12}>
						<TextField
							value={notIncludeText}
							variant="standard"
							label="Ingrese que no incluye"
							size="small"
							onChange={handleChangeName}
							onKeyDown={handleKeyDown}
							fullWidth
						/>
						{QuotationFieldsMaxLength.NOTINCLUDES - notIncludeText.length >= 0 && (
							<Typography variant="body2" sx={{ color: 'green' }}>
								{'Total de caracteres para alcanzar el máximo permitido: ' +
									(QuotationFieldsMaxLength.NOTINCLUDES - notIncludeText.length)}
							</Typography>
						)}
						{QuotationFieldsMaxLength.NOTINCLUDES - notIncludeText.length < 0 && (
							<Typography variant="body2" sx={{ color: 'red' }}>
								{'La cantidad de caracteres supera el máximo permitido de ' +
									QuotationFieldsMaxLength.NOTINCLUDES +
									' caracteres'}
							</Typography>
						)}
					</Grid>
				</Grid>
				<Grid container direction="row-reverse">
					<Button
						variant="contained"
						color="primary"
						style={{ margin: 8 }}
						disabled={!isValidIncludeText()}
						onClick={handleAddNotInclude}
					>
						Agregar
					</Button>
				</Grid>
			</div>
		</Container>
	);
};
