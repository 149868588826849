import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertColor, Backdrop, Container, LinearProgress, Snackbar, Typography } from '@mui/material';
import { checkWompiPaymentConfirmation } from '../../services';
import { extractErrorMessage } from '../../../quotation/helpers';
import { manageWompiResponse } from '../../helpers';
import { maxWompiAttempts } from '../../constants';
import routes from '../../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { GatewayFailedTransaction } from '../GatewayFailedTransaction';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
}));

const defaultCheckPaymentErrorMessage = 'Ocurrió un error al chequear el estado del pago';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export interface WompiCheckConfirmation {
	reference: string;
}

export const WompiCheckConfirmation = ({ reference }: WompiCheckConfirmation): JSX.Element => {
	const classes = useStyles();
	const history = useHistory();
	const [counter, setCounter] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(true);
	const [gatewayError, setGatewayError] = useState<string | null>(null);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);

	const getResponse = async () => {
		try {
			const [alertType, alertMessage] = manageWompiResponse((await checkWompiPaymentConfirmation(reference)).data);
			if (alertType == 'error' || alertType == 'success' || counter + 1 === maxWompiAttempts) {
				setLoading(false);
				if (alertType !== 'success') {
					const message = alertMessage !== '' ? alertMessage : defaultCheckPaymentErrorMessage;
					setGatewayError(message);
				} else {
					history.push(routes.payment.thankYouTransaction);
				}
			} else {
				setCounter(counter + 1);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, defaultCheckPaymentErrorMessage),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		setTimeout(() => getResponse(), 5000);
	}, [counter]);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<Container>
					<Typography textAlign="center">Chequeando estado del pago...</Typography>
					<LinearProgress sx={{ borderRadius: 3 }} color="primary" />
				</Container>
			</Backdrop>
			{gatewayError && <GatewayFailedTransaction error={gatewayError} />}
			<Snackbar open={alert.show} autoHideDuration={2500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
