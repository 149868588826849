import React, { useEffect } from 'react';

import { Box, Container, FormLabel, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FlightExplorerScheduleItemFilter } from '../FlightExplorerScheduleItemFilter';
import { IDurationRange, IScheduleFilter } from '../../types';

const useStyles = makeStyles(() => ({
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		color: 'white',
	},
	filterContent: {
		backgroundColor: 'rgb(241, 242, 241)',
		borderBottomRightRadius: '14px',
		borderBottomLeftRadius: '14px',
		paddingTop: '5px',
		paddingBottom: '25px',
		paddingLeft: '15px',
		transition: 'max-height 0.26s ease 0s, padding 0.26s ease 0s',
		overflowY: 'auto',
	},
}));

interface TabPanelProps {
	children: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-schedule-${index}`} {...other}>
			{children}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-schedule-${index}`,
		'aria-controls': `simple-tab-schedule-${index}`,
	};
}
interface Props {
	handleChangeFilter: (data: IScheduleFilter | null) => void;
}
export const FlightExplorerScheduleFilter = ({ handleChangeFilter }: Props): JSX.Element => {
	const classes = useStyles();
	const [tabIndex, setTabIndex] = React.useState(0);
	const [scheduleFilter, setScheduleFilter] = React.useState<IScheduleFilter | null>(null);

	useEffect(() => {
		handleChangeFilter(scheduleFilter);
	}, [scheduleFilter]);

	const handleChangeGoingDepartureSchedule = (goingDepartureHourRange: IDurationRange | null) => {
		if (goingDepartureHourRange === null) {
			if (scheduleFilter !== null) {
				setScheduleFilter({ ...scheduleFilter, goingDepartureHourRange: undefined });
			}
		} else {
			const baseFilter = scheduleFilter === null ? {} : scheduleFilter;
			setScheduleFilter({
				...baseFilter,
				...(goingDepartureHourRange != null && {
					goingDepartureHourRange: goingDepartureHourRange,
				}),
			});
		}
	};

	const handleChangeGoingArrivalSchedule = (goingArrivalHourRange: IDurationRange | null) => {
		if (goingArrivalHourRange === null) {
			if (scheduleFilter !== null) {
				setScheduleFilter({ ...scheduleFilter, goingArrivalHourRange: undefined });
			}
		} else {
			const baseFilter = scheduleFilter === null ? {} : scheduleFilter;
			setScheduleFilter({
				...baseFilter,
				...(goingArrivalHourRange != null && { goingArrivalHourRange: goingArrivalHourRange }),
			});
		}
	};

	const handleChangeReturnDepartureSchedule = (returnDepartureHourRange: IDurationRange | null) => {
		if (returnDepartureHourRange === null) {
			if (scheduleFilter !== null) {
				setScheduleFilter({ ...scheduleFilter, returnDepartureHourRange: undefined });
			}
		} else {
			const baseFilter = scheduleFilter === null ? {} : scheduleFilter;
			setScheduleFilter({
				...baseFilter,
				...(returnDepartureHourRange != null && {
					returnDepartureHourRange: returnDepartureHourRange,
				}),
			});
		}
	};

	const handleChangeReturnArrivalSchedule = (returnArrivalHourRange: IDurationRange | null) => {
		if (returnArrivalHourRange === null) {
			if (scheduleFilter !== null) {
				setScheduleFilter({ ...scheduleFilter, returnArrivalHourRange: undefined });
			}
		} else {
			const baseFilter = scheduleFilter === null ? {} : scheduleFilter;
			setScheduleFilter({
				...baseFilter,
				...(returnArrivalHourRange != null && { returnArrivalHourRange: returnArrivalHourRange }),
			});
		}
	};

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	return (
		<Container sx={{ 'margin-bottom': '15px' }}>
			<Box sx={{ 'padding-left': '15px', backgroundColor: '#8167A3', height: '25px' }}>
				<FormLabel className={classes.title}>Horario</FormLabel>
			</Box>
			<Box className={classes.filterContent}>
				<Tabs value={tabIndex} onChange={handleChangeTab}>
					<Tab label="Ida" {...a11yProps(0)} />
					<Tab label="Vuelta" {...a11yProps(1)} />
				</Tabs>
				<TabPanel value={tabIndex} index={0}>
					<div>
						<FlightExplorerScheduleItemFilter
							handleChangeFilter={handleChangeGoingDepartureSchedule}
							title={'Salida'}
							key={'goingSchedule-going'}
						/>
						<FlightExplorerScheduleItemFilter
							handleChangeFilter={handleChangeGoingArrivalSchedule}
							title={'Llegada'}
							key={'goingSchedule-return'}
						/>
					</div>
				</TabPanel>
				<TabPanel value={tabIndex} index={1}>
					<div>
						<FlightExplorerScheduleItemFilter
							handleChangeFilter={handleChangeReturnDepartureSchedule}
							title={'Salida'}
							key={'returnSchedule-going'}
						/>
						<FlightExplorerScheduleItemFilter
							handleChangeFilter={handleChangeReturnArrivalSchedule}
							title={'Llegada'}
							key={'returnSchedule-return'}
						/>
					</div>
				</TabPanel>
			</Box>
		</Container>
	);
};
