import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RootState } from 'store';
import { setMarkupQuotationOnlyFlights } from '../../sliceOnlyFlights';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
}));

export const QuotationOnlyFlightsMarkup = (): JSX.Element => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const quotation = useSelector((state: RootState) => state.quotationOnlyFlights);
	const { markup } = quotation;
	const handleMarkupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setMarkupQuotationOnlyFlights(e.target.value));
	};

	return (
		<Container component="main" maxWidth="md">
			<div className={classes.paper}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<TextField
							value={markup}
							variant="standard"
							label="Markup"
							size="small"
							onChange={handleMarkupChange}
							fullWidth
						/>
					</Grid>
				</Grid>
			</div>
		</Container>
	);
};
