import React from 'react';
import { HotelOptionDetailDestinationMultiple, QuotationDestinationMultipleSummaryPriceCombination } from '../../types';
import { Grid } from '@mui/material';
import { RoomPricesMultiDestinationPreviewCard } from '../RoomPricesMultiDestinationPreviewCard';
import { GetPAXTotalsResponse } from '../../helpers';

interface Props {
	combination: QuotationDestinationMultipleSummaryPriceCombination;
	hotels: HotelOptionDetailDestinationMultiple[];
	paxTotals: GetPAXTotalsResponse;
	showUtility: boolean;
	markup: number | null;
	localCurrency: string;
	exchangeRate: number;
	showLocalCurrency?: boolean;
}

export const RoomPricesMultiDestinationPreviewList = ({
	combination,
	paxTotals,
	showUtility,
	markup,
	hotels,
	localCurrency,
	exchangeRate,
	showLocalCurrency,
}: Props): JSX.Element => {
	const keyStringCombination = combination.keys
		.map((x) => x.destinationIndex + '-' + x.destinationCode + '-' + x.hotelId + '-' + x.hotelProvider + x.boardsKey)
		.join('-');

	return (
		<Grid p={1} gap={1} wrap="nowrap" container>
			{combination.roomsSummaryPrice.length > 0 &&
				combination.roomsSummaryPrice.map((roomSummaryPrice, indexRoom) => (
					<Grid key={keyStringCombination + '-' + indexRoom} sx={{ display: 'flex', alignItems: 'stretch' }} item>
						<RoomPricesMultiDestinationPreviewCard
							localCurrency={localCurrency}
							exchangeRate={exchangeRate}
							showLocalCurrency={showLocalCurrency}
							roomsSummaryPrice={roomSummaryPrice}
							hotels={hotels}
							paxTotals={paxTotals}
							indexRoom={indexRoom}
							showUtility={showUtility}
							markup={markup}
						/>
					</Grid>
				))}
		</Grid>
	);
};
