import React, { useEffect, useState } from 'react';
import useQuery from '../../../common/hooks/useQuery';
import { Alert, Backdrop, CircularProgress, Container, Grid, Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ShowAlertState } from '../../../common/types';
import { IPackageExplorerDestination, IPackageExplorerDestinationFilterReq } from '../../types';
import { getPackageExplorerDestinationFiltered } from '../../services';
import { extractErrorMessage } from '../../../quotation/helpers';
import { PackageListCard } from '../PackageListCard';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	helperTextDanger: {
		color: 'red',
	},
	fillBackground: {
		backgroundColor: theme.palette.primary.main,
	},
}));
export const PackageExplorerDestinationDetail = (): JSX.Element => {
	const query = useQuery();
	const theme = useTheme();
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [destinationResult, setDestinationResult] = useState<IPackageExplorerDestination | null>(null);
	const destinationCode = query.get('destinationCode');
	const regionIdValue = Number(query.get('regionId'));
	const tripSeasonIdValue = Number(query.get('tripSeasonId'));
	const tripStyleIdValue = Number(query.get('tripStyleId'));
	const searchId = query.get('searchId');

	const filterDestinationReq = {
		searchId: searchId,
		...(!isNaN(regionIdValue) && regionIdValue > 0 && { regionId: regionIdValue }),
		...(!isNaN(tripSeasonIdValue) && tripSeasonIdValue > 0 && { tripSeasonId: tripSeasonIdValue }),
		...(!isNaN(tripStyleIdValue) && tripStyleIdValue > 0 && { tripStyleId: tripStyleIdValue }),
	} as IPackageExplorerDestinationFilterReq;

	useEffect(() => {
		const fetchApi = async () => {
			if (destinationCode) {
				try {
					setLoading(true);
					const data = await getPackageExplorerDestinationFiltered(filterDestinationReq, destinationCode);
					setDestinationResult(data.data);
					setLoading(false);
				} catch (error: any) {
					setAlert({
						show: true,
						severity: 'error',
						message: extractErrorMessage(error, 'Ocurrió un error al pedir el detalle del destino'),
					});
					setLoading(false);
				}
			} else {
				setAlert({
					show: true,
					severity: 'info',
					message: 'Código de destino incorrecto',
				});
			}
		};
		fetchApi();
	}, []);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Container component="main" maxWidth={'lg'} sx={{ 'padding-top': '15px', marginBottom: '15px' }}>
				{destinationResult && (
					<>
						<Typography component="h1" fontWeight={'bold'} fontSize={'20px'} color={theme.palette.primary.main}>
							Explorando el destino: {destinationResult.name}, {destinationResult.countryName}
						</Typography>
						<Grid container spacing={2} pt={2}>
							{destinationResult.packages.map((packageItem) => (
								<Grid item xs={4} md={4} key={`package-explorer-destination-package-detail-${packageItem.keyword}`}>
									<PackageListCard packageItem={packageItem} />
								</Grid>
							))}
						</Grid>
					</>
				)}
			</Container>

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
