import React from 'react';
import { Alert, AlertColor, Button, Card, Grid, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IRouteInfoForPackageCompositeDetailPower, origenTariffTypes, OriginTariffTypeEnum } from '../../types';
import Typography from '@mui/material/Typography';
import { formatDateTimeToShowUser, formatDateToShowUser } from '../../../common/helpers';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AutomaticRouteTariffSegmentModal } from '../AutomaticRouteStats/AutomaticRouteTariffsSegmentModal';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	headerTitle: {
		fontSize: '13px',
		fontWeight: 'bold',
		display: 'inline',
		color: theme.palette.primary.main,
	},
	contentDescription: {
		fontSize: '13px',
		display: 'inline',
		marginLeft: '10px',
		color: theme.palette.primary.main,
	},
}));

export interface PackageCompositeRouteDetailProps {
	route: IRouteInfoForPackageCompositeDetailPower;
}

export const PackageCompositeRouteDetail = ({ route }: PackageCompositeRouteDetailProps): JSX.Element => {
	const classes = useStyles();
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [openModalSegments, setOpenModalSegments] = React.useState<boolean>(false);

	const handleCopyAmadeusCommand = async (commandToCopy: string) => {
		try {
			await navigator.clipboard.writeText(commandToCopy);
		} catch (error: any) {
			setAlert({ show: true, severity: 'error', message: 'No se pudo copiar al clipboard' });
		}
	};

	return (
		<>
			<Card variant="elevation" sx={{ padding: '10px', 'padding-top': '0px', 'margin-top': '10px' }}>
				<Typography color="primary" variant={'h6'} fontWeight="bold" py={1} px={1}>
					Ruta asociada
				</Typography>
				<Grid container marginTop={1}>
					<Grid item xs={12}>
						<Typography className={classes.headerTitle}>Nombre:</Typography>
						<Typography className={classes.contentDescription}>{route.name}</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography className={classes.headerTitle}>Precio:</Typography>
						<Typography className={classes.contentDescription}>USD {route.price}</Typography>
					</Grid>

					{route?.lastUpdatePrices && (
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Última actualización de precios:</Typography>
							<Typography className={classes.contentDescription}>
								{formatDateTimeToShowUser(route.lastUpdatePrices)}
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<Typography className={classes.headerTitle}>Tipo de ruta:</Typography>
						<Typography className={classes.contentDescription}>
							{route.isAutomaticRoute ? 'Automática' : 'Manual'}
						</Typography>
					</Grid>

					{route?.goingFlightTravelMaxDuration && (
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Tiempo máximo a la ida:</Typography>
							<Typography className={classes.contentDescription}>{route?.goingFlightTravelMaxDuration} hs</Typography>
						</Grid>
					)}
					{route?.returnFlightTravelMaxDuration && (
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Tiempo máximo a la vuelta:</Typography>
							<Typography className={classes.contentDescription}>{route?.returnFlightTravelMaxDuration} hs</Typography>
						</Grid>
					)}
					{route?.isOriginal != null && (
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Es original:</Typography>
							<Typography className={classes.contentDescription}>{route?.isOriginal ? 'Si' : 'No'}</Typography>
						</Grid>
					)}

					{route?.related && (
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Fecha de la tarifa relacionada:</Typography>
							<Typography className={classes.contentDescription}>{formatDateToShowUser(route.related)}</Typography>
						</Grid>
					)}

					{route?.originTariffType && (
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Origen de la tarifa:</Typography>
							<Typography className={classes.contentDescription}>
								{origenTariffTypes.find((item) => item.id === route?.originTariffType)?.name}
							</Typography>
						</Grid>
					)}

					{route?.observations && (
						<Grid item xs={12}>
							<Typography className={classes.headerTitle}>Observaciones:</Typography>
							<Typography className={classes.contentDescription}>{route.observations}</Typography>
						</Grid>
					)}
				</Grid>

				{route?.originTariffType == OriginTariffTypeEnum.TRACK && route?.containerSegments && (
					<Grid container spacing={2} marginLeft={0} paddingLeft={0}>
						<Grid item xs={2} md={2}>
							<Button
								title={'Ver segmentos'}
								startIcon={<VisibilityIcon />}
								sx={{ height: 40, ml: 0, pl: 0 }}
								onClick={() => setOpenModalSegments(true)}
							>
								Ver segmentos
							</Button>
						</Grid>
						<Grid item xs={2} md={2}>
							<Button
								title={'Copiar FXD'}
								startIcon={<ContentCopyIcon />}
								sx={{ height: 40, ml: 1 }}
								onClick={() => handleCopyAmadeusCommand(route?.amadeusCommandInfo?.fxd || '')}
							>
								Copiar FXD
							</Button>
						</Grid>
						<Grid item xs={2} md={2}>
							<Button
								title={'Copiar SN Ida'}
								startIcon={<ContentCopyIcon />}
								sx={{ height: 40, ml: 1 }}
								onClick={() => handleCopyAmadeusCommand(route?.amadeusCommandInfo?.snGoing || '')}
							>
								Copiar SN Ida
							</Button>
						</Grid>
						<Grid item xs={3} md={3}>
							<Button
								title={'Copiar SN Vuelta'}
								startIcon={<ContentCopyIcon />}
								sx={{ height: 40, ml: 1 }}
								onClick={() => handleCopyAmadeusCommand(route?.amadeusCommandInfo?.snReturn || '')}
							>
								Copiar SN Vuelta
							</Button>
						</Grid>
					</Grid>
				)}
			</Card>
			{openModalSegments && route && route.containerSegments && (
				<AutomaticRouteTariffSegmentModal
					open={openModalSegments}
					data={route.containerSegments}
					onCancel={() => setOpenModalSegments(false)}
				/>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
