import React from 'react';
import {
	Dialog,
	DialogContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IAutomaticRouteTariffPriceContainerSegment } from '../../../types';
import { formatDateToShowUser } from '../../../../common/helpers';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';

interface IAutomaticRouteTariffSegmentModal {
	open: boolean;
	data: IAutomaticRouteTariffPriceContainerSegment;
	onCancel: () => void;
}

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
	closeButton: {
		cursor: 'pointer',
		display: 'inline',
		float: 'right',
	},
}));

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

export const AutomaticRouteTariffSegmentModal = ({
	open,
	data,
	onCancel,
}: IAutomaticRouteTariffSegmentModal): JSX.Element => {
	const classes = useStyles();

	const headerCells = [
		{ headerName: 'Número de vuelo' },
		{ headerName: 'Fecha' },
		{ headerName: 'Hora' },
		{ headerName: 'Orígen' },
		{ headerName: 'Fecha' },
		{ headerName: 'Hora' },
		{ headerName: 'Destino' },
		{ headerName: 'Cabin Class' },
		{ headerName: 'Fare Family' },
	];

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="xl"
			disableEscapeKeyDown={true}
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
		>
			<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
				<Typography variant="h6" component={'span'}>
					Segmentos{' '}
				</Typography>{' '}
				<CloseIcon className={classes.closeButton} onClick={onCancel}></CloseIcon>
			</DialogTitle>
			<DialogContent>
				<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: '600px' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableHeader} />
									{headerCells.map((cell, index) => {
										return (
											<TableCell className={classes.tableHeader} key={`${cell.headerName}-${index}`}>
												{cell.headerName}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{data.goingTravelSegments.map((item, index) => (
									<TableRow
										key={`${item.flightNumber}-going-${index}`}
										hover
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										{index == 0 && (
											<TableCell component="p" rowSpan={data.goingTravelSegments.length}>
												Ida
											</TableCell>
										)}
										{index > 0 && <TableCell component="p" rowSpan={data.goingTravelSegments.length}></TableCell>}
										<TableCell component="p">{item.flightNumber}</TableCell>
										<TableCell component="p">{formatDateToShowUser(item.flightDepartureDate)}</TableCell>
										<TableCell component="p">{item.flightDepartureTime.substring(0, 5)}</TableCell>
										<TableCell component="p">{item.airportDepartureCode}</TableCell>
										<TableCell component="p">{formatDateToShowUser(item.flightArrivalDate)}</TableCell>
										<TableCell component="p">{item.flightArrivalTime.substring(0, 5)}</TableCell>
										<TableCell component="p">{item.airportArrivalCode}</TableCell>
										<TableCell component="p">{item.cabinClass}</TableCell>
										<TableCell component="p">{item.fareFamily}</TableCell>
									</TableRow>
								))}

								{data.returnTravelSegments.map((item, index) => (
									<TableRow
										key={`${item.flightNumber}-return-${index}`}
										hover
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										{index == 0 && (
											<TableCell component="p" rowSpan={data.goingTravelSegments.length}>
												Vuelta
											</TableCell>
										)}
										{index > 0 && <TableCell component="p" rowSpan={data.goingTravelSegments.length}></TableCell>}
										<TableCell component="p">{item.flightNumber}</TableCell>
										<TableCell component="p">{formatDateToShowUser(item.flightDepartureDate)}</TableCell>
										<TableCell component="p">{item.flightDepartureTime.substring(0, 5)}</TableCell>
										<TableCell component="p">{item.airportDepartureCode}</TableCell>
										<TableCell component="p">{formatDateToShowUser(item.flightArrivalDate)}</TableCell>
										<TableCell component="p">{item.flightArrivalTime.substring(0, 5)}</TableCell>
										<TableCell component="p">{item.airportArrivalCode}</TableCell>
										<TableCell component="p">{item.cabinClass}</TableCell>
										<TableCell component="p">{item.fareFamily}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};
