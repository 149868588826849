import { IAirportFE, ShowAlertState } from '../../common/types';
import { useCallback, useState } from 'react';
import { getAirports } from '../../common/services';

interface UseAirportsParams {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setAlert: React.Dispatch<React.SetStateAction<ShowAlertState>>;
}

interface UseAirportsReturn {
	airports: IAirportFE[];
	loadAirports: () => void;
}

const useAirports = ({ setLoading, setAlert }: UseAirportsParams): UseAirportsReturn => {
	const [airports, setAirports] = useState<IAirportFE[]>([]);

	const loadAirports = useCallback(async () => {
		try {
			setLoading(true);
			const response = await getAirports();
			setAirports(
				response.data.map((airport: IAirportFE) => ({ ...airport, name: `${airport.code}-${airport.name}` })),
			);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: 'Ocurrió un error al cargar los aeropuertos',
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, [setLoading, setAlert]);

	return { airports: airports, loadAirports: loadAirports };
};

export default useAirports;
