import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Layout } from 'features/common/components/Layout';
import { useParams } from 'react-router-dom';
import { FlightIssueRuleContainerForm } from '../../features/flightIssueRule/components/FlightIssueRuleContainerForm';
import { isToCreate, isToCreateDefaultRule, maybeIdToEdit } from '../../features/flightIssueRule/helpers';

export const FlightIssueRuleUpsertPage = (): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	return (
		<Layout>
			<Grid container paddingY={5} justifyContent={'center'}>
				<Grid item xs={12} display="flex" justifyContent={'center'}>
					<Typography variant="h6">Reglas de emisión de vuelos</Typography>
				</Grid>
				<Grid paddingY={5} item xs={9}>
					<FlightIssueRuleContainerForm
						isToCreate={isToCreate(id)}
						isToCreateDefault={isToCreateDefaultRule(id)}
						idToEdit={maybeIdToEdit(id)}
					/>
				</Grid>
			</Grid>
		</Layout>
	);
};
