import React, { useEffect, useState } from 'react';

import { Box, Checkbox, Container, FormLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IAirlineFEWithTotal } from '../../types';
import Typography from '@mui/material/Typography';
import { AirLineLogo } from '../AirlineLogo';

interface Props {
	data: IAirlineFEWithTotal[];
	handleChangeFilter: (data: string[] | null) => void;
}

const useStyles = makeStyles(() => ({
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		color: 'white',
	},
	filterContent: {
		backgroundColor: 'rgb(241, 242, 241)',
		borderBottomRightRadius: '14px',
		borderBottomLeftRadius: '14px',
		padding: '0px',
		transition: 'max-height 0.26s ease 0s, padding 0.26s ease 0s',
		paddingLeft: '15px',
	},
	totalInfo: {
		justifyContent: 'center',
		alignItems: 'center',
		width: 'auto',
		display: 'inline-flex',
		fontSize: '14px',
		fontWeight: '500',
		height: '15px',
		marginTop: '10px',
		textAlign: 'center',
		borderRadius: '14px',
		padding: '4px 8px',
		boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 3px 0px',
		backgroundColor: 'rgb(255, 103, 0)',
		color: 'rgb(255, 255, 255)',
	},
}));

export const FlightExplorerAirlinesFilter = ({ data, handleChangeFilter }: Props): JSX.Element => {
	const classes = useStyles();
	const [airlineCodesSelected, setAirlineCodesSelected] = useState<string[]>([]);
	const handleChangeAirlineCheckBox = (event: React.ChangeEvent<HTMLInputElement>, airlineCode: string) => {
		if (event.target.checked) {
			setAirlineCodesSelected([...airlineCodesSelected, airlineCode]);
		} else {
			setAirlineCodesSelected([
				...airlineCodesSelected.filter((airlineCodeSelected) => airlineCodeSelected !== airlineCode),
			]);
		}
	};

	useEffect(() => {
		setTimeout(() => handleChangeFilter(airlineCodesSelected.length > 0 ? airlineCodesSelected : null), 10);
	}, [airlineCodesSelected]);

	return (
		<Container sx={{ 'margin-bottom': '15px' }}>
			<Box sx={{ 'padding-left': '15px', backgroundColor: '#8167A3', height: '25px' }}>
				<FormLabel className={classes.title}>Aerolíneas</FormLabel>
			</Box>
			<Box className={classes.filterContent}>
				{data.map((airlineWithCount, index) => (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							paddingTop: '5px',
							paddingRight: '10px',
							paddingBottom: '5px',
						}}
						key={'airline-filter-' + index}
					>
						<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'left' }}>
							<Checkbox
								checked={airlineCodesSelected.some((x) => x == airlineWithCount.airline.code)}
								onChange={(event) => handleChangeAirlineCheckBox(event, airlineWithCount.airline.code)}
								color="secondary"
								sx={{ paddingRight: '10px', paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
							/>
							<AirLineLogo code={airlineWithCount.airline.code} name={airlineWithCount.airline.name} showName={true} />
						</div>

						<Typography variant="subtitle1" component="span" className={`${classes.totalInfo}`}>
							{' '}
							{airlineWithCount.total}
						</Typography>
					</div>
				))}
			</Box>
		</Container>
	);
};
