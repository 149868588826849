import { AxiosResponse } from 'axios';
import endpoints from 'constants/endpoints';
import { formatSortingFields } from 'features/common/helpers';
import { IEnvelopeList, ISort } from 'features/common/types';
import { ApiService } from 'services';
import {
	IAuthorizationDetails,
	IAuthorizationList,
	IEticket,
	IEticketUnbindInfoAllowed,
	IRefundAuthorization,
	IUnbindEticket,
} from './types';

export const getAuthorizationDetails = (id: number): Promise<AxiosResponse<IAuthorizationDetails>> =>
	ApiService.get(`api/quotations/authorizations/${id}`);
export const getUnbindEticketsAllowed = (id: number): Promise<AxiosResponse<IEticketUnbindInfoAllowed[]>> =>
	ApiService.get(`api/quotations/authorizations/${id}/eticketsallowunbind`);
export const unbindCrossEticket = (refundFormData: IUnbindEticket): Promise<AxiosResponse> =>
	ApiService.post(endpoints.unbindCrossEticket, refundFormData);
export const postETicket = (eTicketFormData: IEticket): Promise<AxiosResponse> =>
	ApiService.post(endpoints.newETicket, eTicketFormData);

export const registerAuthorization = (authorizationId: number): Promise<AxiosResponse> =>
	ApiService.patch(endpoints.authorizations + `/${authorizationId}`);

export const getAuthorizationList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IAuthorizationList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.authorizations, {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getAuthorizationListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<IEnvelopeList<IAuthorizationList>>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiService.get(endpoints.authorizations, {
		params: {
			page: -1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const refundAuthorization = (
	authorizationId: number,
	refundData: IRefundAuthorization,
): Promise<AxiosResponse> => ApiService.patch(endpoints.authorizations + `/${authorizationId}/refund`, refundData);
