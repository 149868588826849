import { IAuthorizationLine } from 'features/transactions/types';

export interface ICrossLine {
	id: number;
	validatingCarrierName: string;
	amount: number;
	coinCode: string;
	dateCross: string;
	eticket: string;
	operator: string;
	state: number;
	createdAt: string;
}

export interface IEticket {
	authorizationId: number;
	amount: string;
	eticket: string;
	operator: string;
}

export interface IUnbindEticket {
	authorizationId: number;
	amount: number;
	eticket: string;
}

export interface IEticketUnbindInfoAllowed {
	eticket: string;
	operator: string;
	maxToRefund: number;
}

export interface IAuthorizationDetails {
	authorizationData: IAuthorizationLine;
	crossesData: ICrossLine[];
}

export interface IAuthorizationList {
	transactionId: number;
	id: number;
	crmTicket: number;
	file?: string;
	chargedAgainstOptionName: string;
	amountAuthorized: number;
	coinCode: string;
	authorizationCode: string;
	paymentDate: string;
	paymentDueDate: string;
	pendingToCross: string;
	state: number;
	isRegistered: boolean;
	createdAt: string;
	cardLastFourDigit: string;
	cardExpiration: string;
}

export enum CrossStateEnum {
	CROSS = 1,
	UNBIND,
}

export interface CrossStateInterface {
	id: CrossStateEnum;
	name: string;
}

export const crossStateList: CrossStateInterface[] = [
	{
		id: CrossStateEnum.CROSS,
		name: 'Cruzado',
	},
	{
		id: CrossStateEnum.UNBIND,
		name: 'Desvinculado',
	},
];

export interface IRefundAuthorizationForm {
	authorizationId: number;
	amountToRefund: number;
}

export interface IRefundAuthorization {
	amountToRefund: number;
}
