import { makeStyles } from '@mui/styles';

export const balanceStyle = (amount: number | undefined, departureDate: string | undefined): React.CSSProperties => {
	if (amount === undefined || departureDate === undefined) {
		return { color: 'inherit' };
	}

	const today = new Date();
	const departureDateObj = new Date(departureDate);
	const diffTime = Math.abs(departureDateObj.getTime() - today.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	if (amount > 0 && diffDays < 30) {
		return { color: 'red', fontWeight: 'bold' };
	} else if (amount > 0 && diffDays >= 30 && diffDays <= 45) {
		return { color: 'red' };
	} else if (amount < 0) {
		return { color: 'orange' };
	}
	return { color: 'inherit' };
};

export const saleOrderStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	tableHeader: {
		backgroundColor: theme.palette.grey.A400,
	},
	paginationHolder: {
		marginTop: theme.spacing(1),
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	btnContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		maxWidth: '90px',
	},
	expandedRow: {
		backgroundColor: '#FFE2D3',
	},
	icon: {
		color: '#AB47BC',
	},
	container: {
		paddingBottom: 20,
		borderBottom: '1px solid #00000040',
		width: '85%',
	},
	containerButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	tableSortLabel: {
		color: theme.palette.grey.A700,
		'&:hover': {
			color: theme.palette.common.black,
		},
	},
}));
