import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInfimiaWireTransferTransactionItem, OrderDataToClient, PaymentState } from './types';

const initialState: PaymentState = {
	orderDataToClient: null,
	wireTransferCheckResult: null,
};

const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		setOrderDataToClient: (state, { payload }: PayloadAction<OrderDataToClient | null>) => {
			state.orderDataToClient = payload;
		},
		setWireTransferCheckResult: (state, { payload }: PayloadAction<IInfimiaWireTransferTransactionItem[] | null>) => {
			state.wireTransferCheckResult = payload;
		},
	},
});

export const { setOrderDataToClient, setWireTransferCheckResult } = paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;
