import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid } from '@mui/material';
import NumberFormat from 'react-number-format';
import { IFlightItinerary } from '../../types';
import { FlightItineraryItem } from '../FlightItineraryItem';

const useStyles = makeStyles(() => ({
	textHeaderInfo: {
		color: 'white',
		fontSize: '14px',
		fontWeight: 'normal',
	},
}));

interface Props {
	item: IFlightItinerary;
}

export const FlightItineraryCard = ({ item }: Props): JSX.Element => {
	const theme = useTheme();
	const classes = useStyles();

	return (
		<Card sx={{ mb: 2 }}>
			<Grid container spacing={1}>
				<Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column' }}>
					<FlightItineraryItem item={item.goingFlightTravel} itemName={'IDA'} isReturning={false} />

					<FlightItineraryItem item={item.returnFlightTravel} itemName={'VUELTA'} isReturning={true} />
				</Grid>
				<Grid item xs={3}>
					<div
						style={{
							backgroundColor: '#8167A3',
							padding: '5px',
							minWidth: '200px',
							display: 'flex',
							textTransform: 'uppercase',
							height: '52px',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="subtitle1" component="p" className={`${classes.textHeaderInfo}`}>
							{' '}
							Precios
						</Typography>
					</div>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'column',
							alignItems: 'center',
							pl: 1,
							pb: 1,
						}}
					>
						<Typography
							variant="subtitle1"
							component="p"
							fontSize={20}
							color={theme.palette.secondary.main}
							fontWeight={'bold'}
						>
							{item.price.priceForOneAdult.currency} {item.price.priceForOneAdult.amount}
						</Typography>
						{item.localPrice && (
							<Typography
								variant="subtitle1"
								component="p"
								fontSize={13}
								color={theme.palette.secondary.main}
								fontWeight={'bold'}
							>
								<NumberFormat
									value={item.localPrice.priceForOneAdult.amount}
									displayType={'text'}
									decimalSeparator={','}
									thousandSeparator={'.'}
									prefix={item.localPrice.priceForOneAdult.currency + ' '}
								/>
							</Typography>
						)}
						<Typography
							variant="subtitle1"
							component="p"
							fontSize={13}
							color={theme.palette.secondary.main}
							fontWeight={'normal'}
						>
							{' '}
							Precio por adulto
						</Typography>

						<Typography
							variant="subtitle1"
							component="p"
							fontSize={13}
							color={theme.palette.secondary.main}
							fontWeight={'bold'}
						>
							{item.price.priceForOneChild.currency} {item.price.priceForOneChild.amount}{' '}
							{item.localPrice && (
								<NumberFormat
									value={item.localPrice.priceForOneChild.amount}
									displayType={'text'}
									decimalSeparator={','}
									thousandSeparator={'.'}
									prefix={item.localPrice.priceForOneChild.currency + ' '}
								/>
							)}
						</Typography>

						<Typography
							variant="subtitle1"
							component="p"
							fontSize={13}
							color={theme.palette.secondary.main}
							fontWeight={'normal'}
						>
							{' '}
							Precio por niño
						</Typography>

						<Typography
							variant="subtitle1"
							component="p"
							fontSize={13}
							color={theme.palette.secondary.main}
							fontWeight={'bold'}
						>
							{item.price.priceForOneInfant.currency} {item.price.priceForOneChild.amount}{' '}
							{item.localPrice && (
								<NumberFormat
									value={item.localPrice.priceForOneInfant.amount}
									displayType={'text'}
									decimalSeparator={','}
									thousandSeparator={'.'}
									prefix={item.localPrice.priceForOneInfant.currency + ' '}
								/>
							)}
						</Typography>

						<Typography
							variant="subtitle1"
							component="p"
							fontSize={13}
							color={theme.palette.secondary.main}
							fontWeight={'normal'}
						>
							{' '}
							Precio por bebé
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Card>
	);
};
